import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import inputTextarea from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { InvitationService } from '../../service/InvitationService';
import EnseignantService from '../../service/EnseignantService';
import { ParcoursService } from '../../../lmd/service/ParcoursService'
import { options } from 'preact';

export default function InvitationEnseignantEntrant(){

    const tokenDecoded = localStorage.getItem('tokenDecoded');
    const token = JSON.parse(tokenDecoded);

    const [selectedEntites, setSelectedEntites] = useState(null)

    const [listeInvitation, setListeInvitation] = useState([])
    const [informationInvitation, setInformationInvitation] = useState({})

    const [submitted, setSubmitted] = useState(false)
    const [globalFilter, setGlobalFilter] = useState(null)
    const [loading, setLoading] = useState(false)
    const [spin,setSpin] = useState(false)
    const [idDepartement, setIdDepartement] = useState(4)
    const [detailsInvitationDialog, setDetailsInvitationDialog] = useState(false)
    const [confirmerEnvoiInvitationDialog, setConfirmerEnvoiInvitationDialog] = useState(false)
    const [rejetInvitation, setRejetInvitation] = useState(false)
    const [messageRejet, setMessageRejet] = useState('')

    const notify = new Notify()
    const spinner = new Spinner()

    const invitationService = new InvitationService()


    useEffect(() => {
            setLoading(true)
            invitationService.getListeInvitationEntrantDepartement(idDepartement).then(data => {
                setListeInvitation(data)
                setLoading(false)

                //console.log(data)
            }).catch(error => {
                console.log(error)
                notify.echec(error.response.data.message)
                setLoading(false)
            })

        }, []
    );


    const detailsInvitation = (data) => {
        setInformationInvitation(data)
        setDetailsInvitationDialog(true)
    }


    const hideDetailInvitation = () => {
        setDetailsInvitationDialog(false)
        setRejetInvitation(false)
        setSpin(false)
        setSubmitted(false)
    }

    const validerInvitationEnseignant = (informationInvitation) => {
        setInformationInvitation(informationInvitation)
        setConfirmerEnvoiInvitationDialog(true)
    }

    const hideConfirmerInvitationDialog = () => {
        setConfirmerEnvoiInvitationDialog(false)
        setSpin(false)
    }

    const rejetrInvitation = (informationInvitation) => {
        setInformationInvitation(informationInvitation)
        setRejetInvitation(true)
        setDetailsInvitationDialog(true)
        setMessageRejet(informationInvitation.justificatif)
        //console.log(informationInvitation)
    }



    const confirmerValidationInvitation = () => {
        setSpin(true)

        let invitationObject = informationInvitation

        invitationObject['posDemande'] = "ENSEIGNANT"
        invitationObject['etatActuel'] = "Receptionner"

        invitationService.updateInvitationEnseignant(informationInvitation.idInvitationEnseignant, invitationObject).then(data => {

            setSpin(false)
            setLoading(true)
            notify.success()

            setDetailsInvitationDialog(false)
            setInformationInvitation({})
            setConfirmerEnvoiInvitationDialog(false)

            invitationService.getListeInvitationEntrantDepartement(idDepartement).then(data => {
                setListeInvitation(data)
                setLoading(false)
            }).catch(error => {
                console.log(error)
                notify.echec(error.response.data.message)
                setLoading(false)
            })

        }).catch(error => {
            console.log(error)
            notify.echec(error.response.data.message)
            setSpin(false)
            setLoading(false)
        })

    }

    const causeRejetEdit = (event) => {
        setMessageRejet(event.target.value)
    }

    const confirmerRejetrInvitation = (invitationEnseignant) => {
       
        setSubmitted(true)
        invitationEnseignant['justificatif'] = messageRejet
        invitationEnseignant['etatActuel'] = "Rejeter"
        invitationEnseignant['posDemande'] = "IESR DONNATEUR"

        if(messageRejet.length>0){

            invitationService.updateInvitationEnseignant(invitationEnseignant.idInvitationEnseignant, invitationEnseignant).then(data => {

                setSpin(false)
                setLoading(true)
                notify.success()
    
                setDetailsInvitationDialog(false)
                setInformationInvitation({})
                setConfirmerEnvoiInvitationDialog(false)
                setMessageRejet('')
                setRejetInvitation(false)
    
                invitationService.getListeInvitationEntrantDepartement(idDepartement).then(data => {
                    setListeInvitation(data)
                    setLoading(false)
                }).catch(error => {
                    console.log(error)
                    notify.echec(error.response.data.message)
                    setLoading(false)
                })
    
            }).catch(error => {
                console.log(error)
                notify.echec(error.response.data.message)
                setSpin(false)
                setLoading(false)
            })
        }

    }



    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-info" className="p-button-warning p-mr-2" title="Détails" style={{marginRight: '.5em'}} onClick={() => detailsInvitation(rowData)} />
                <Button icon="pi pi-check-circle" className="p-button-info p-mr-2" title="Valider et transmetre" style={{marginRight: '.5em'}} onClick={() => validerInvitationEnseignant(rowData)} />
                <Button icon="pi pi-times" className="p-button-danger p-mr-2" title="Rejeter invitation" style={{marginRight: '.5em'}} onClick={() => rejetrInvitation(rowData)} />
            </React.Fragment>
        );
    }

    const header = (
        <div className="table-header p-col-12">
            <span className="p-input-icon-left">
                <InputText style={{marginLeft : 0,width:"300px"}} type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
            </span>
        </div>
    );


    const detailsInvitationFooter = (

        rejetInvitation? 
        (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDetailInvitation} />
                <Button label="Confirmer le rejet" icon="pi pi-check-circle" className="p-button-text danger" onClick={() => confirmerRejetrInvitation(informationInvitation)} />
            </React.Fragment>
        )
        :
        (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDetailInvitation} />
                <Button label="Valider & envoyer" icon="pi pi-check-circle" className="p-button-text" onClick={() => validerInvitationEnseignant(informationInvitation)} />
                <Button label="Rejeter" icon="pi pi-times" className="p-button-text danger" onClick={() => rejetrInvitation(informationInvitation)} />
            </React.Fragment>
        )
    )


    const confirmerEnvoiInvitationFooter = (
        <React.Fragment>
            <Button label="Non" disabled={spin?true:false}  icon={spin?"pi pi-spin pi-spinner":"pi pi-ban"} className="p-button-text" onClick={hideConfirmerInvitationDialog} />
            <Button label="Oui" disabled={spin?true:false} icon="pi pi-check" className="p-button-text" onClick={confirmerValidationInvitation} />
        </React.Fragment>
    );
    
    

    const actionHeader = <Button type="button" icon="pi pi-cog"/>;

    return (
        <div className="datatable-crud-demo">
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="card">
                
                {header}
                {loading ?
                <div className="p-grid">
                    <div className="p-col-6" ></div>
                    <div className="p-col-6" >
                        {spinner.loadingSpinner("spin", "black")}
                    </div>
                </div>
                :
                <DataTable value={listeInvitation} selection={selectedEntites} onSelectionChange={(e) => setSelectedEntites(e.value)}
                    dataKey="id" paginator rows={10} 
                    globalFilter={globalFilter}
                    header="Liste des invitations d'enseignants du département">
                    <Column field="enseignant.nomPrenomEnseignant" header="Enseignant" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="enseignant.nomIes" header="Université" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="dateDebutCours" header="Date de debut du cours" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="dateFinCours" header="Date de fin du cours" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="etatActuel" header="Etat de l'invitation" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="posDemande" header="Position de l'invitation" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column header={actionHeader} body={actionBodyTemplate} style={{textAlign:'center', width: '160px'}}></Column>
                </DataTable>
                }
            </div>

            <Dialog visible={detailsInvitationDialog} style={{width: '80%'}} header= {rejetInvitation?"Interface de rejet de l'invitation":"Détails de l'invitation"} modal footer={detailsInvitationFooter} onHide={hideDetailInvitation}>
                <hr/>
                {informationInvitation.enseignant && (
                    <React.Fragment>
                        <div className='p-grid'>
                    <div className='p-col-6'>

                        <div className='card'>
                            <div className='card-header'>
                                Détails sur l'enseignant concermé
                            </div>
                            <div className='card-body'  style={{ flex: '1 1 auto',paddingTop: '1.25rem', paddingRight: '0rem', paddingLeft: '0rem', paddingBottom: '0rem'}}>

                                <table className='table table-hover table-bordered table-striped'>
                                    <tbody>
                                        <tr>
                                            <td>Nom et prenom de l'enseignant</td>
                                            <td>{informationInvitation.enseignant.nomPrenomEnseignant}</td>
                                        </tr>
                                        <tr>
                                            <td>Grade enseignant</td>
                                            <td>{informationInvitation.enseignant.grade.libelleGrade}</td>
                                        </tr>
                                        <tr>
                                            <td>Université d'origine</td>
                                            <td>{informationInvitation.enseignant.nomIes}</td>
                                        </tr>
                                        <tr>
                                            <td>UFR</td>
                                            <td>{informationInvitation.enseignant.nomEtablissement}</td>
                                        </tr>
                                        <tr>
                                            <td>Département</td>
                                            <td>{informationInvitation.enseignant.lbDepartementEtab}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>

                    <div className='p-col-6'>

                        <div className='card'>
                            <div className='card-header'>
                                Détails sur le cours d'invitation concerné
                            </div>
                            <div className='card-body'  style={{ flex: '1 1 auto',paddingTop: '1.25rem', paddingRight: '0rem', paddingLeft: '0rem', paddingBottom: '0rem'}}>
                                <table className='table table-hover table-bordered table-striped'>
                                    <tbody>
                                        <tr>
                                            <td>Parcours concerné par le cours</td>
                                            <td>{informationInvitation.libelleParcours}</td>
                                        </tr>
                                        <tr>
                                            <td>Niveau</td>
                                            <td>{informationInvitation.libelleNiveau}</td>
                                        </tr>
                                        <tr>
                                            <td>Element constitutif</td>
                                            <td>{informationInvitation.libelleElementConstitutif}</td>
                                        </tr>
                                        <tr>
                                            <td>Date de debut du cours</td>
                                            <td>{informationInvitation.dateDebutCours}</td>
                                        </tr>
                                        <tr>
                                            <td>Date de fin du cours</td>
                                            <td>{informationInvitation.dateFinCours}</td>
                                        </tr>
                                        <tr>
                                            <td>Evolution de l'invitation</td>
                                            <td>{informationInvitation.posDemande}</td>
                                        </tr>
                                        <tr>
                                            <td>Etat de l'invitation</td>
                                            <td>{informationInvitation.etatActuel}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>


                    {rejetInvitation &&
                        <div className='p-col-12'>
                            <div className="p-grid">
                                <div className="p-col-12" >
                                    <div className="p-field">
                                        <label htmlFor="causeRejet">Indiquez la raison du rejet de cette demande d'invitation</label>
                                        <hr/>
                                        <textarea value={messageRejet} rows={5} onChange={(e) => {causeRejetEdit(e)}} required style={{width: '100%'}} />
                                        {submitted && !messageRejet && <small className="p-invalid" style={{color : "red"}}>Le motif du rejet est obligatoire dans le cas d'un rejet</small>}     
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    


                </div>
                    </React.Fragment>
                )
                }
                <hr/>
            </Dialog>


            <Dialog visible={confirmerEnvoiInvitationDialog} style={{ width: '450px' }} header="Confirmmation d'envoi invitation" modal footer={confirmerEnvoiInvitationFooter} onHide={hideConfirmerInvitationDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-info-circle p-mr-3" style={{ fontSize: '2rem'}} />
                    <center>
                        {informationInvitation && <span>Etes vous sûr de vouloir confirmer l'envoi de cette invitation ? </span>}
                    </center>
                </div>
            </Dialog>

        </div>
    )

}