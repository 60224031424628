import React, { Component } from "react";

import "primeflex/primeflex.css";
import "primereact/resources/primereact.min.css";
import "./App.css";
import Connexion from "./components/Connexion";
import VolumeHoraire from "./components/VolumeHoraire";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: "",
            verife: false,
        };
    }

    componentDidMount = () => {
        /*const accessToken = localStorage.getItem('accessToken')
		const token = JSON.parse(accessToken)
        console.log(token)
		this.setState({token : token})*/
        localStorage.setItem("connectepasse", JSON.stringify(false));
    };

    render() {
        return <div className="App">{<Connexion />}</div>;
    }
}

export default App;
