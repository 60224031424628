import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import './parametres/index.css';

import React, { useState, useEffect} from 'react';
import {FullCalendar} from 'primereact/fullcalendar';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import fr from '@fullcalendar/core/locales/fr';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useForm,Controller } from 'react-hook-form'; 
import { Dropdown } from 'primereact/dropdown';
import {Calendar} from 'primereact/calendar';
import { Notify } from '../../components/Notify';
import { ToastContainer } from 'react-toastify';
import { Spinner } from '../../components/Spinner';
import ProgrammationService from '../service/ProgrammationService';
import {NiveauService} from '../../lmd/service/NiveauService';
import {AnneeService} from '../../lmd/service/AnneeService'
import { CohorteServices } from '../service/CohorteServices';
import { SemestreService } from '../../lmd/service/SemestreService';
import AffectationService from '../service/AffectationService';
import { InfrastructureServices } from '../service/InfrastructureServices';
import EnseignantService from '../service/EnseignantService';
import {TypeActiviteService} from '../../lmd/service/TypeActiviteService';
import {MultiSelect} from 'primereact/multiselect';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { ParcoursUEService } from '../../lmd/service/ParcoursUEService';
import {Accordion,AccordionTab } from 'primereact/accordion';
import { DepartementService } from '../../lmd/service/DepartementService';
import EmploiDuTempsService from '../service/EmploiDuTempsService';
import { RadioButton } from 'primereact/radiobutton';

export default function Programmation() {


    const tokenDecoded = localStorage.getItem('tokenDecoded')
    const token = JSON.parse(tokenDecoded);

    const defaultValues = {
        enseignant:'',
        parcours:'',
        departement:'',
        niveau:'',
        anneeAcademique:'',
        semestre:'',
        typeActivite:'',
        dateProgrammation:'',
        heureDebutSeanceProg:'',
        heureFinSeanceProg:'',
        infrastructure:'',
        cohorte:[],
        idSeanceProg:'',
        fixe:false,
        elementConstitutif:'',
        dateDebutSemaine:'',
        dateFinSemaine:'',
        title:""
    }

    const defaultMessage={
        messageDialog:false,
        messageInfrastructure:false,
        dataForm:{},
        indexList:'',
        cohorte:{libelleCohorte:'',indexCohorte:'',idCohorte:''},
        infrastructure:{},
        toSave:{}
    }

    const evenementsTemplates = {
        id:"",
        title:"",
        start:"",
        end:"",
        data:{}
    }

    const defaultDialog = {
        add:false,
        modification:false,
        delete:false
    }

    const detailDefaul = {
        detail:false
    }


    const [loading,setLoading] = useState(false);
    const [dialog,setDialog] = useState(defaultDialog);
    const [saveList,setSaveList] = useState([]);
    const [evenements,setEvenements] = useState([evenementsTemplates]);
    const [listParcours,setListParcours] = useState([]);
    const [parcours, setParcours] = useState();
    const [listAnneeAcademique,setListAnneeAcademique] = useState([]);
    const [anneeAcademique, setAnneeAcademique] = useState();
    const [listSemestre,setListSemestre] = useState([]);
    const [semestre, setSemestre] = useState();
    const [listNiveau,setListNiveau] = useState([]);
    const [niveau, setNiveau] = useState();
    const [listCohorteNiveau,setListCohorteNiveau] = useState([]);
    const [listElementConstitutif,setListElementConstitutif] = useState([]) // la variables d'état contenant la liste des élements constitutifs
    const [listEnseignant,setListEnseignant] = useState([]) // la variables d'état contenant la liste des enseignants
    const [listTypeActivite,setListTypeActivite] = useState([]) // la variables d'état contenant la listes des type d'activité
    const [spin,setSpin] = useState(false);
    const [cancelAndReset,setCancelAndReset] = useState(false);
    const [listProgrammation,setListProgrammation] = useState([]);
    let [listEnseignantFilter,setListEnseignantFilter] = useState([]);
    const [message,setMessage] = useState(defaultMessage);
    let [listNiveauFilter,setListNiveauFilter] = useState([]);
    const [listInfrastructure,setListInfrastructure] = useState([]);
    const [listInfrastructureByDepartement,setListInfrastructureDepartement] = useState([]);
    const [detailDialog,setDetailDialog] = useState(detailDefaul);
    const [infrastructure,setInfrastructure] = useState([])
    const [programmationDelete,setProgrammationDelete] = useState();
    const [cohortes,setCohortes] = useState([]);
    const [cohorte, setCohorte] = useState();
    const [erreurs,setErreurs] = useState(false);
    const [listSemestreSearch,setListSemestreSearch] = useState([]);
    const [next,setNext] = useState({loading:false,move:false});
    const [listUtiliteInfrastructure,setListUtiliteInfrastructure] = useState([]);
    const {control,handleSubmit,reset,setValue,getValues,formState:{errors}} = useForm({defaultValues});
    const {control:searchControl,setValue:searchSetValue, getValues:searchGetValue} = useForm({defaultValues:{semestre:'',parcours:'',cohorte:''}});
    const {control:affectationControl,reset:affectationReset,setValue:affectationSetValue, getValues:affectationGetValues, setError:affectationSetError} = useForm({defaultValues:{semestre:'',enseignant:'', parcours:''}});
    const [selectedInfrastructure, setSelectedInfrastructure] = useState({});
    const [dateErrors,setDateErrors] = useState(false);
    const [checkInfrastructure,setCheckInfrastructure] = useState({check:false,popup:false});
    const [listProgrammationByParcours,setListProgrammationByParcours] = useState([]);
    const [listCohorteSearch,setListCohorteSearch] = useState([]);
    const [programmationFilter,setProgrammationFilter] = useState([]);
    const [listDepartementByIesr,setListDepartementByIesr] = useState([]);
    const [iesr,setIesr] = useState({});
    const [libelleDepartementSelect,setLibelleDepartementSelect] = useState("");
    const [infrastructureReserver,setInfrastructureReserver] = useState([]);
    const [refresh,setRefresh] = useState(false);
    const [limitHeure,setLimiteHeure] = useState({element:{},popup:false,sup:0});
    const [listAffectation, setListAffectation] = useState([]);
    const [globalFilter, setGlobalFilter] = useState();
    const [selectionAffectation, setSelectionAffectation] = useState([]);
    const [spinInfras, setSpinInfras] = useState(false);
    const [heureErrors, setHeureErrors] = useState(false);
    const [semestreError, setSemestreError] = useState(false);
    const [typeStructure, setTypeStructure] = useState("");
    const [listeEtablissement, setListeEtablissement] = useState([])

    
    const addLocale ={
        firstDayOfWeek: 1,
        dayNames: ['dimanche','lundi','mardi','mercredi','jeudi','vendredi','samedi'],
        dayNamesShort: ['dim','lun','mar','mer','jeu','ven','sam'],
        dayNamesMin: ['D','L','M','Me','J','V','S'],
        monthNames: ['Janvier','Fevrier','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Decembre'],
        monthNamesShort: "",
        today: "aujourd'hui",
        clear: "effacer",
    }

    
    const notify = new Notify();
    const spinner = new Spinner();
    const programmationService = new ProgrammationService();
    const niveauService = new NiveauService();
    const anneeService = new AnneeService();
    const cohorteServices = new CohorteServices();
    const semestreService = new SemestreService();
    const affectationService = new AffectationService();
    const infrastructureService = new InfrastructureServices();
    const enseignantService = new EnseignantService();
    const typeActiviteService = new TypeActiviteService();
    const departementService = new DepartementService();
    const employeDuTempsService = new EmploiDuTempsService();
    const cohorteService = new CohorteServices();
   

    // variables declaration En DialogdBloc

    //console.log(token)
      
    let code_departement = token.code_departement;


    useEffect(() => {
        
        programmationService.getProgrammationDepartement(code_departement)
        .then(response=>{
            setLoading(true);
            if(response){
                setListProgrammation(response);
                programmationGet(response);
            }
            

            //console.log('Ok Ok Ok')
        })
        .catch(errors=>{
            notify.echec(errors.response.data.message);
            console.log(errors)
        })
        .finally(()=>{
            setLoading(false);
        }); 

        cohorteServices.getListeParcoursDepartement(code_departement).then(response=>{/*console.log(response);*/setListParcours(response)});
        anneeService.getAll().then(response=>{
            setListAnneeAcademique (response)

            //console.log(response)
        });

        

        niveauService.getAll().then(response=>{setListNiveau(response)});
        typeActiviteService.getAll().then(response=>setListTypeActivite(response));
        infrastructureService.getListeUtiliteInfrastructure().then(response=>{setListUtiliteInfrastructure(response)});

        programmationService.getIersByIdDepartement(code_departement).then(response=>{

            setIesr(response)
            //console.log(response)


            cohorteServices.getListeEtabUniv(response.idIes).then(data => {
                //console.log(data)
                setListeEtablissement(data)
            }).catch(errors => console.log(errors))



        }).catch(errors=>console.log(errors))
    }, []);

    


    const messageHandler = ()=>{
        let listCohorte = saveList[message.indexList].cohorte;
        saveList[message.indexList].cohorte = listCohorte.filter((cohr=>cohr.idCohorte !== message.idCohorte));
        setSaveList([...saveList,message.dataForm]);
        reset();
        setMessage({...message,messageDialog:false});
    } 
    const messageInfrastructureHandler = ()=>{
        saveList.splice(message.indexList,1);
        setSaveList([...saveList,message.dataForm]);
        reset();
        setMessage({...message,messageInfrastructure:false});
    } 

    const onHide = ()=>{
       
        if(dialog.add){
            setDialog(defaultDialog);
            setListElementConstitutif([]);
            setListEnseignantFilter([]);
            setListNiveauFilter([]);
            setListSemestre([]);
            setListInfrastructure([]);
            setSelectedInfrastructure({})
            setSaveList([]);
            setListAffectation([]);
            setSelectionAffectation([]);
            setNext({...next,move:false});
            setSpin(false);
            reset();
            setHeureErrors(false);
            affectationReset();
        } else if(dialog.modification){
            setDialog(defaultDialog);
            setListElementConstitutif([]);
            setListEnseignantFilter([]);
            setListNiveauFilter([]);
            setListSemestre([]);
            // modifyReset();
        }
    }

    const programmationGet=(response=[])=>{
        let events = response.map(programme=>{
            let heureDebut = programme.heureDebutSeanceProg.split(":");
            let heureDebutSeanceProg=new Date(programme.dateProgrammation);
            heureDebutSeanceProg.setHours(+heureDebut[0],+heureDebut[1],heureDebut[2]);

            let heureFin = programme.heureFinSeanceProg.split(":");
            let heureFinSeanceProg=new Date(programme.dateProgrammation);
            heureFinSeanceProg.setHours(+heureFin[0],+heureFin[1],heureFin[2]);
            let libelleCohorte = "";
            programme.cohorte.forEach(cohort=>{
                libelleCohorte +=cohort.libelleCohorte.substring(0,9)+",";;
            })

            return {
                "title":`${programme.libelleElementConstitutif}(${programme.libelleTypeActivite}) ${libelleCohorte}`,
                "id":programme.idSeanceProg,
                "start":heureDebutSeanceProg,
                "end":heureFinSeanceProg,
                "data":programme
            }
        });
        //console.log(events)
        setEvenements(events)
    }

    const detailFooter=(
        <React.Fragment>
             <div className="p-grid w-full">
                <Button type="button" label="Supprimer" icon="pi pi-trash" disabled={spin}  className="p-button-warning p-mr-2" 
                    style={{marginRight:"15px",color:"white"}}
                    onClick={()=>handleProgrammationDeletion(getValues("idSeanceProg"))}
                />
               
                <Button type="button" label="Quitter" disabled={spin} className="p-button-danger" icon="pi pi-times" 
                    onClick={()=>setDetailDialog({...detailDialog,detail:false})}
                />
            </div>
        </React.Fragment>
    )

    const handleProgrammationDeletion = (dataToDelete=null)=>{

        if(cancelAndReset){
            setSpin(true);
            setTimeout(()=>{
                setSaveList([]); // reinitialisation de la liste des grades 
                setCancelAndReset(false);
                setDialog(false);
                setSpin(false);
                reset();
                setSelectedInfrastructure({});
                setCheckInfrastructure({check:false,popup:false})
                setTypeStructure("")
                setNext({loading:false,move:false})
            }
            ,2000)
        }else if(dialog.delete){
            setSpin(true);
            programmationService.delete(programmationDelete)
                .then(()=>{
                    programmationService.getProgrammationDepartement(code_departement).then(response=>{setListProgrammation(response);programmationGet(response)});
                    setDetailDialog({...detailDialog,detail:false});
                    notify.success();
                    setDialog({...dialog,delete:false});
                })
                .catch((errors)=>{
                    notify.echec(errors.response.data.message);
                    console.log(errors);
                })
                .finally(()=>{
                    setSpin(false);
                })
        }else{
            setProgrammationDelete(dataToDelete);
            setDialog({delete:true});
        }
    }

    const anneeChange = (annee) => {
        setAnneeAcademique(annee)
    }
    const parcoursChange = (parcours) => {
        let semestre = searchGetValue("semestre");
        let cohorte = searchGetValue("cohorte");
        setLoading(true);
        searchSetValue("niveau","");
        programmationService.getProgrammationByParcours(code_departement,parcours.idParcours)
        .then(response=>{
            setListProgrammationByParcours(response);
            programmationGet(response);

            if(cohorte) handleCohorteSearch(cohorte);
            if(semestre) handleSemestreSearch(semestre);
        })
        .finally(()=>setLoading(false));

        setParcours(parcours)
        let _listeNiveau = []
            semestreService.get(parcours.idParcours).then(data => {
                let _semestre = data;
                
                niveauService.getAll().then(response=>{
                    response.map(niv => {
                        _semestre.map(se => {
                            if(niv.idNiveau === se.idNiveau)
                            _listeNiveau.push(niv);
                        })
                    })
                    let collectionIdNiveau = []
                    _listeNiveau.map(nive => {
                        collectionIdNiveau.push(nive.idNiveau);
                    })
                    let _listNiveau = []
                    _listeNiveau.map( (nivo, index) => {
                        for(let i=index+1;i<collectionIdNiveau.length; i++){
                            if(nivo.idNiveau === collectionIdNiveau[i]){
                                _listNiveau.push(nivo);
                            }
                        }
                    }) 
                    setListNiveau(_listNiveau);
                })
            })
    }

    const niveauChange = (niveau) => {
        setNiveau(niveau)
    
        cohorteServices.getCohorteParcoursNiveau(parcours.idParcoursFiliere, niveau.idNiveau, anneeAcademique.idAnneeAcademique).then(data => {
            //console.log(data)
            setListCohorteNiveau(data);
         })
         .catch(error => {
             console.log(error);
             notify.echec(error.response.data.message)
         })
    }
    const cohorteChange = (_cohorte) => {
        setCohorte(_cohorte)
        employeDuTempsService.getSemestre(_cohorte.idNiveau).then(data => {
            //console.log(data)
            setListSemestre(data.semestreDtoList)
        })
        .catch(error => {
            console.log(error)
            notify.echec(error.response.data.message)
        })
    }
    const semestreChange = (_semestre) => {
        setSemestre(_semestre)
        let result = listProgrammationByParcours.filter(programmation=>programmation.idSemestre === semestre.idSemestre);
        
            setProgrammationFilter(result);
            //programmationGet(result);

            let results =[] ;
    
            result.forEach(programmation=>{
            let data = programmation.cohorte.find(programmation=>programmation.idCohorte === cohorte.idCohorte)
            
            if(data){
                results.push(programmation);
            }

            // for(let element of programmation.cohorte){
            //     if(element.idCohorte === cohorte.idCohorte){
            //         result.push(programmation);
            //     }
            // }
        });
            programmationGet(results);

            /*let newListCohorte = [];
            let listCohorte =[];
            result.forEach(prog=>{
                prog.cohorte.forEach(cohor=>{
                    listCohorte.push(cohor);
                })
            })
            let cohortesID=new Set();
            for(let element of listCohorte){
                cohortesID.add(element.idCohorte);
            }
            for(let index of cohortesID ){
                newListCohorte.push(listCohorte.find(cohorte=>cohorte.idCohorte === index));
            }*/
            //let cohorte = searchGetValue("cohorte");
            //if(cohorte) handleCohorteSearch(cohorte)
            //setListCohorteSearch([...newListCohorte]);
    }


    const handleParcoursSearch = (parcours)=>{
        let semestre = searchGetValue("semestre");
        let cohorte = searchGetValue("cohorte");
        setLoading(true);
        searchSetValue("niveau","");
        setListSemestreSearch([]);
        setListCohorteSearch([]);
        semestreService.get(parcours.idParcours).then(response=>{
            setListSemestreSearch(response);
        })
        programmationService.getProgrammationByParcours(code_departement,parcours.idParcours)
        .then(response=>{
            setListProgrammationByParcours(response);
            programmationGet(response);

            if(cohorte) handleCohorteSearch(cohorte);
            if(semestre) handleSemestreSearch(semestre);
        })
        .finally(()=>setLoading(false));
    }


    const handleSemestreSearch=semestre=>{
        let result = listProgrammationByParcours.filter(programmation=>programmation.idSemestre === semestre.idSemestre);
        
            setProgrammationFilter(result);
            programmationGet(result);
            let newListCohorte = [];
            let listCohorte =[];
            result.forEach(prog=>{
                prog.cohorte.forEach(cohor=>{
                    listCohorte.push(cohor);
                })
            })
            let cohortesID=new Set();
            for(let element of listCohorte){
                cohortesID.add(element.idCohorte);
            }
            for(let index of cohortesID ){
                newListCohorte.push(listCohorte.find(cohorte=>cohorte.idCohorte === index));
            }
            let cohorte = searchGetValue("cohorte");
            if(cohorte) handleCohorteSearch(cohorte)
            setListCohorteSearch([...newListCohorte]);
        
    }


    const handleCohorteSearch = cohorte=>{
        let result =[] ;
        //console.log(cohorte);
        programmationFilter.forEach(programmation=>{
            let data = programmation.cohorte.find(programmation=>programmation.idCohorte === cohorte.idCohorte)
            
            if(data){
                result.push(programmation);
            }

            // for(let element of programmation.cohorte){
            //     if(element.idCohorte === cohorte.idCohorte){
            //         result.push(programmation);
            //     }
            // }
        });
        programmationGet(result);
    }



    const options = {
        plugins:[dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialDate:new Date(),
        initialView:'timeGridWeek',
        locales:[fr],
        headerToolbar:{ 
            left: 'prev,next', 
            center:'title', 
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        slotMinTime:"06:00:00",
        slotMaxTime:"23:00:00",
        eventContent: function(arg) {
            let {data} = arg.event.extendedProps;
            let division = document.createElement('div')
            let cohorteJsx = "";
             data.cohorte.forEach(cor=>{
                cohorteJsx +=`<span>${cor.libelleCohorte.substring(0,9)}</span>,`
             })
            const jsx = `
            <div>
                <span>${arg.timeText}</span><br />
                <span>${data.libelleElementConstitutif}</span>&nbsp;&nbsp; / <span>${data.libelleTypeActivite}</span><br />
                <p>${cohorteJsx} </p>
            </div>`
            division.innerHTML = jsx;
            let arrayOfDomNodes = [ division ]
            return { domNodes: arrayOfDomNodes }
          },
        editable: true,
        dateClick:(e)=>{
            let today = new Date();
            today.setHours(6,0,0);
            const date = new Date(e.date);
            if(date.valueOf() >= today.valueOf()){
                setDialog({...dialog,add:true});
                let regexp = /\d+/g;
                let date_array = new Date(e.view.currentEnd).toLocaleDateString().match(regexp);
                
                if(+date_array[0]<10){
                    date_array[0] = `0${+date_array[0]-1}`;
                }else{
                    date_array[0] = `${+date_array[0]-1}`;
                }
                let reversedDateEnd = date_array.reverse();
                let reversedDateStart = new Date(e.view.currentStart).toLocaleDateString().match(regexp).reverse();
                
                let currentDate = new Date(e.date).toLocaleDateString().match(regexp).reverse().join("-");
                let currentEnd = reversedDateEnd.join("-");
                let currentStart = reversedDateStart.join("-");
                // let title = e.view.title;
                setValue("dateDebutSemaine",currentStart);
                setValue("dateFinSemaine",currentEnd);
                setValue("dateProgrammation",currentDate);

                enseignantService.getAll().then(response=>setListEnseignant(response));
                
            
            }else{
                notify.info("La date choisie est invalide , Réessayer !");
            }
        },
        eventClick:e=>{
            const content = e.event.extendedProps.data;
            setValue("anneeAcademique",`${content.anneeDebut}-${content.anneeFin}`);
            let {cohorte} = content;
            cohorte.forEach(element=>{
                element.libelleNiveau = content.libelleNiveau;
                element.libelleAnnee = `${content.anneeDebut}-${content.anneeFin}`;
                element.libelleParcours = content.libelleParcours;
                element.libelleTypeActivite = content.libelleTypeActivite;
            });
            setValue("heureDebutSeanceProg",content.heureDebutSeanceProg);
            setValue("heureFinSeanceProg",content.heureFinSeanceProg);
            setCohortes(content.cohorte)
            setValue("dateProgrammation",new Date(content.dateProgrammation));
            setInfrastructure([content.infrastructure]);
            setValue("niveau",content.libelleNiveau);
            setValue("typeActivite",content.libelleTypeActivite);
            setValue("elementConstitutif",content.libelleElementConstitutif);
            setValue("semestre",content.libelleSemestre);
            setValue("parcours",content.libelleParcours);
            setValue("enseignant",`${content.matriculeEnseignant} ${content.nomEnseignant} ${content.prenomEnseignant}`);
            setValue("idSeanceProg",content.idSeanceProg);
            setDetailDialog({...detailDialog,detail:true})
        }
    };

    const cohorteBody = rowData=>{
        return(
            <span title={rowData.libelleCohorte}>{rowData.libelleCohorte.substring(0,9)} ...</span>
        )
    }

    const handleSemestreSelect = (semestre)=>{
       let enseignant = affectationGetValues("enseignant");
       let anneeAcademique = affectationGetValues("anneeAcademique");
       if(enseignant && anneeAcademique)handleFilter(anneeAcademique);
       setSemestreError(false); 
    }

    const handleQuitter = ()=>{
        if(cancelAndReset){
            setSpin(true);
            setTimeout(()=>{
            onHide();
            setSpin(false);
            setCancelAndReset(false);
        }, 2000)
        }else{
            onHide();
        }
    }

    const itemEnseignantTemplate = rowData=>{
        if(listEnseignant.length!==0){
            return listEnseignant.map(enseignant=>{
                if(enseignant.idEnseignant === rowData.idEnseignant){
                    return (
                        <div key={enseignant.matriculeEnseignant}>
                            <span>{enseignant.matriculeEnseignant} </span>
                            <span>{enseignant.nomEnseignant} </span>
                            <span>{enseignant.prenomEnseignant} </span>
                        </div>
                    );
                }
                return null
            })
        }
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">*{errors[name].message}</small>
    };












    const header = (
        <div className="table-header p-col-12">
            <form className="p-fluid" style={{margin:"0px auto", width:"60%"}}>
                <div className="p-grid" style={{display:"flex", justifyContent:"space-between",alignItems:"center"}}>                                            
                    <div className="p-field" style={{width:250}}>
                        <label htmlFor="parcours" >Parcours</label>
                        <Controller 
                            name="parcours" control={affectationControl}
                            render={({field}) =>{
                                return  (
                                    <Dropdown
                                        filter
                                        options={listParcours} optionLabel="libelleParcours"
                                        name={field.name} id={field.name} value={field.value}
                                        onChange={(e) => {field.onChange(e.value); handleParcours(e.value)}}
                                    />
                                )
                        }} />
                    </div>
                    
                    <div className="p-field" style={{width:250}}>
                        <label htmlFor="semestre">Semestre </label>{semestreError && <span className='p-error'>* champ vide. </span>}
                        <Controller 
                            name="semestre" control={affectationControl}
                            rules={{ required: 'champ obligatoire.'}}
                            render={({field}) => (
                                <Dropdown
                                    options={listSemestre} optionLabel="libelleSemestre"
                                    name={field.name} id={field.name} value={field.value}
                                    onChange={(e) => {field.onChange(e.value); handleSemestreSelect(e.value);}}
                                />
                            )} />                            
                    </div> 
                    <div className="p-field" style={{width:250}}>
                        <label htmlFor="enseignant" >Enseignant</label>
                        <Controller 
                            name="enseignant" control={affectationControl}
                            render={({field}) =>{
                                return  (
                                    <Dropdown
                                        filter
                                        options={listEnseignant} itemTemplate={itemEnseignantTemplate} optionLabel="nomPrenomEnseignant"
                                        name={field.name} id={field.name} value={field.value}
                                        onChange={(e) => {field.onChange(e.value)}}
                                    />
                                )
                        }} />
                    </div>





                    <div className="p-field" style={{width:250}}>
                        <label htmlFor="anneeAcademique" >Année academique</label>
                        <Controller 
                            name="anneeAcademique" control={affectationControl}
                            render={({field}) =>{
                                return  (
                                    <Dropdown
                                        filter
                                        options={listAnneeAcademique} optionLabel="libelleAnnee"
                                        name={field.name} id={field.name} value={field.value}
                                        onChange={(e) => {field.onChange(e.value); handleFilter(e.value)}}
                                    />
                                )
                        }} />
                    </div>




                </div>
                
            </form>
        </div>
    );











    const messageFooter = (
        <React.Fragment>
            <Button label="Anuller" icon="pi pi-times" className="p-button-text" onClick={()=>setMessage({...message,messageDialog:false})} />
            <Button label="Continuer" icon={spin?"pi pi-spin pi-spinner":"pi pi-check"} className="p-button-danger" onClick={messageHandler} />
        </React.Fragment>
    )


    const messageInfrastructureFooter = (
        <React.Fragment>
            <Button label="Anuller" icon="pi pi-times" className="p-button-text" onClick={()=>setMessage({...message,messageInfrastructure:false})} />
            <Button label="Continuer" icon={spin?"pi pi-spin pi-spinner":"pi pi-check"} className="p-button-danger" onClick={messageInfrastructureHandler} />
        </React.Fragment>
    )


    const deleteProgrammationFooter =(

        <React.Fragment>
            <Button label="NON" disabled={spin?true:false} icon="pi pi-times" className="p-button-success" onClick={onHide} />
            <Button label="OUI" disabled={spin?true:false} icon={spin?"pi pi-spin pi-spinner":"pi pi-check"} className="p-button-danger" onClick={handleProgrammationDeletion} />
        </React.Fragment>

    )

   
   

    const removeItem =(key)=>{
        saveList.splice(key,1);
        setSaveList([...saveList]);
        
    }

    const handleDepartement = (_departement) => {
        let dateProgrammation=getValues("dateProgrammation");
        let heureDebut = getValues("heureDebutSeanceProg");
        let heureFin = getValues("heureFinSeanceProg");
        
        if(heureDebut.split(":").length<2 || heureFin.split(":").length<2){
            setHeureErrors(true);
        }else{
            setSpinInfras(true);
            infrastructureService.getInfrastructureLibreDepartement(_departement.idDepartementEtab,dateProgrammation,heureDebut,heureFin)
            .then(response=>{
                //console.log(response)
                //setListInfrastructure(response);
                setListInfrastructureDepartement(response)
            })
            .catch(error=>console.log(error))
            .finally(()=>setSpinInfras(false));
        }
    }


    const handleEtablissement = (_etablissement) => {
        let dateProgrammation=getValues("dateProgrammation");
        let heureDebut = getValues("heureDebutSeanceProg");
        let heureFin = getValues("heureFinSeanceProg");
        
        if(heureDebut.split(":").length<2 || heureFin.split(":").length<2){
            setHeureErrors(true);
        }else{
            setSpinInfras(true);
            infrastructureService.getInfrastructureLibreEtablissement(_etablissement.idEtablissement,dateProgrammation,heureDebut,heureFin)
            .then(response=>{
                //console.log(response)
                //setListInfrastructure(response);
                setListInfrastructureDepartement(response)
            })
            .catch(error=>console.log(error))
            .finally(()=>setSpinInfras(false));
        }
    }
    
    const handleInfrastructure = ()=>{
        //console.log(code_departement)
        let dateProgrammation=getValues("dateProgrammation");
        let heureDebut = getValues("heureDebutSeanceProg");
        let heureFin = getValues("heureFinSeanceProg");
        //console.log(heureDebut)
        //console.log(heureFin)
        if(heureDebut.split(":").length<2 || heureFin.split(":").length<2){
            setHeureErrors(true);
        }else{
            setSpinInfras(true);
            infrastructureService.getInfrastructureLibre(code_departement,dateProgrammation,heureDebut,heureFin)
            .then(response=>{
                //console.log(response)
                setListInfrastructure(response);
            })
            .catch(error=>console.log(error))
            .finally(()=>setSpinInfras(false));
        }
        
    }
   

    const handleSelectionInfrastructure = infrastructure=>{
        if(checkInfrastructure.popup){
            setListInfrastructure(infrastructure);
        }
        if(infrastructure.length===0){
            setSelectedInfrastructure({});
        }else{
            setSelectedInfrastructure(infrastructure);
        }
        setValue("infrastructure",infrastructure);
    }


    const handleAjouter = ()=>{
        let dateProgrammation = getValues("dateProgrammation");
        let heureDebutSeanceProg = getValues("heureDebutSeanceProg");
        let heureFinSeanceProg = getValues("heureFinSeanceProg");
        let cohortes = selectionAffectation.map((affectation)=>{return {idCohorte : affectation.idCohorte, libelleCohorte:affectation.libelleCohorte}})
        setSaveList([...saveList,{heureFinSeanceProg,heureDebutSeanceProg,dateProgrammation,cohortes, selectedInfrastructure, selectionAffectation}]);
    }

   const handleParcours = (parcours)=>{
        semestreService.get(parcours.idParcours).then(response=>{
            setListSemestre(response);
        })
        .catch(errors=>console.log(errors));
   }



   const handleFilter = (anneeAcademique)=>{
        let semestre = affectationGetValues("semestre");
        let enseignant = affectationGetValues("enseignant")
        if(semestre){
            let request = {
                code_departement,
                idEnseignant:enseignant.idEnseignant,
                idSemestre:semestre.idSemestre,
                idAnneeAcademique:anneeAcademique.idAnneeAcademique
            };

            //console.log(request)

            affectationService.getAffectationEnseignantSemestre(request)
            .then(response=>{
                setListAffectation(response);
            })
            .catch(errors=>console.log(errors));
        }else{
            setSemestreError(true);
        }
        
   }

    const handleSave = ()=>{
        let programmationSeanceDtos = saveList.map(data=>{
            return {
                    heureDebutSeanceProg : data.heureDebutSeanceProg,
                    heureFinSeanceProg : data.heureFinSeanceProg,
                    dateProgrammation : data.dateProgrammation,
                    idAnneeAcademique : data.selectionAffectation[0].idAnneeAcademique,
                    idElementConstitutif : data.selectionAffectation[0].idElementConstitutif,
                    libelleElementConstitutif: data.selectionAffectation[0].libelleElementConstitutif,
                    idEnseignant:data.selectionAffectation[0].idEnseignant,
                    idParcours : data.selectionAffectation[0].idParcours,
                    libelleParcours: data.selectionAffectation[0].libelleParcours,
                    idSemestre : data.selectionAffectation[0].idSemestre,
                    libelleSemestre : data.selectionAffectation[0].libelleSemestre,
                    idTypeActivite : data.selectionAffectation[0].idTypeActivite,
                    libelleTypeActivite: data.selectionAffectation[0].libelleTypeActivite,
                    idDepartementEtab : code_departement,
                    idInfrastructure : data.selectedInfrastructure.idInfrastructure,
                    libelleInfrastructure: data.selectedInfrastructure.libelleInfrastructure,
                    cohorte:data.cohortes,
                };
        })

        //console.log(programmationSeanceDtos)
        
        let semaineProgrammationDto = {
            dateDebutSemaine:getValues("dateDebutSemaine"),
            dateFinSemaine:getValues("dateFinSemaine")
        }
        /*
        console.log(programmationSeanceDtos)
        console.log(semaineProgrammationDto)
        console.log(code_departement)*/

        //console.log({programmationSeanceDtos,semaineProgrammationDto})

        
        setSpin(true);
        programmationService.create(code_departement,{programmationSeanceDtos,semaineProgrammationDto})
        .then(()=>{
            setLoading(true);
            programmationService.getProgrammationDepartement(code_departement).then(response=>{setListProgrammation(response);programmationGet(response)});
            reset();
            affectationReset();
            setSelectedInfrastructure({});
            setSelectionAffectation([]);
            setListInfrastructure([]);
            setDialog({...dialog,add:false});
            setSaveList([]);
            setListAffectation([]);
            notify.success();
        })
        .catch((errors)=>{
            
            notify.echec(errors.response.data.message);
            console.log(errors);
        })
        .finally(()=>{
            setSpin(false);
            setLoading(false);
        });
        
    }

    const handleReservation = ()=>{
        
        //console.log(iesr.idIes)
        departementService.getListeDepartement(iesr.idIes).then(response=>{

            //console.log(response)

            setListDepartementByIesr(response.filter( departement => departement.idDepartementEtab !== code_departement ));
            setCheckInfrastructure({...checkInfrastructure,popup:true})
            setInfrastructureReserver([])
            setSelectedInfrastructure({});

        })
        .catch(errors=>console.log(errors));
    }


    const setTypeStructureInfrastructure = (value) => {
        setTypeStructure(value)
        setListInfrastructureDepartement([])

        if(value === "universite"){

            //console.log(iesr.idIes)

            let dateProgrammation=getValues("dateProgrammation");
            let heureDebut = getValues("heureDebutSeanceProg");
            let heureFin = getValues("heureFinSeanceProg");
            
            if(heureDebut.split(":").length<2 || heureFin.split(":").length<2){
                setHeureErrors(true);
            }else{
                setSpinInfras(true);
                infrastructureService.getInfrastructureLibreDepartement(iesr.idIes,dateProgrammation,heureDebut,heureFin)
                .then(response=>{
                    //console.log(response)
                    //setListInfrastructure(response);
                    setListInfrastructureDepartement(response)
                })
                .catch(error=>console.log(error))
                .finally(()=>setSpinInfras(false));
            }

        }

        
        else if(value === "etablissement" && getValues("curentEtablissement")){
            let curentEtab = getValues("curentEtablissement")
            handleEtablissement(curentEtab)
        }

        else if(value === "departement" && getValues("curentDepartement")){
            let curentDep = getValues("curentDepartement")
            handleDepartement(curentDep)
        }
        
        
    }

    const handleSelectionReservation = infrastructure=>{
        
        setSelectedInfrastructure([infrastructure])
        setInfrastructureReserver([infrastructure])
        setListInfrastructure([...listInfrastructure,infrastructure])
        
        setValue("infrastructure",infrastructure);
        setCheckInfrastructure({...checkInfrastructure,popup:false})
        setTypeStructure("")
    }


    const handleRowSelected = rowData=>{
        setSelectionAffectation([...selectionAffectation, rowData.data]);
    }
    const handleRowUnselected = rowData=>{
        let newSelection = selectionAffectation.filter(affect=>affect.idElementConstitutifHoraire !== rowData.data.idElementConstitutifHoraire);
        setSelectionAffectation(newSelection);
    }

    const handleDateDebut=()=>{
        setErreurs(false);
        setHeureErrors(false);
        setSelectedInfrastructure({})
        let dateProgrammation = getValues("dateProgrammation");
        let heureDebutSeanceProg = `${dateProgrammation} ${getValues("heureDebutSeanceProg")}`
        let heureFinSeanceProg = `${dateProgrammation} ${getValues("heureFinSeanceProg")}`
        
        if(heureDebutSeanceProg > heureFinSeanceProg && `${getValues("heureFinSeanceProg")}`){
            setErreurs(true);
        }

    }   

    
    const cancelAndResetFooter =(
        <React.Fragment>
            <Button label="NON" type="button" disabled={spin} icon="pi pi-times" className="p-button-success" onClick={()=>setCancelAndReset(false)} />
            <Button label="OUI" type="button" disabled={spin} icon={spin?"pi pi-spin pi-spinner":"pi pi-check"} className="p-button-danger" 
                onClick={handleQuitter} 
            />
        </React.Fragment>
    )


   


    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="card">
                {
                loading ?
                    <div className="p-grid">
                        <div className="p-col-6" ></div>
                        <div className="p-col-6" >
                            {spinner.loadingSpinner("spin", "black")}
                        </div>
                    </div>
                :
                
                   <div>
                        <div style={{backgroundColor:"Background",padding:10}} className="table-header p-grid justify-content-center mb-2 mt-3">
                            
                        </div>
                        <div>
                            <FullCalendar 
                                options={options} events={evenements} 
                                editable selectable selectMirror dayMaxEvents 
                            />
                        </div>
                   </div>
                }
            </div>
            
            
            {/* Add programmation Bloc */}
            <Dialog className="card" visible={dialog.add} style={{width:"100%", height:"90%"}} onHide={onHide}>
                <div id='data-table'> 
                    {header}
                    <DataTable 
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                        rows={5} 
                        onRowSelect={handleRowSelected}
                        onRowUnselect={handleRowUnselected}
                        selection={selectionAffectation}
                        selectionMode="multiple" filterMatchMode="contains"
                        paginator={true} responsive={true}
                        value={listAffectation}
                        globalFilter={globalFilter}
                        header="Liste des ECUE Affectées Par Département"
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '5em' }} />
                        <Column field="libelleSemestre" header="Semestre" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search nom"  sortable filter/>
                        <Column field="libelleParcours" header="Parcours" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search nom"  sortable filter/>
                        <Column body={itemEnseignantTemplate} header="Enseignant" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search nom"  sortable filter/>
                        <Column body={cohorteBody} header="Cohorte" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search nom"  sortable filter/>
                        <Column field="libelleElementConstitutif" header="EC" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search nom"  sortable filter/>
                        <Column field="libelleTypeActivite" header="Type Activité" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search nom"  sortable filter/>
                        <Column field="nbHeure" header="Nombre d'heures" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search nom"  sortable filter/>
                    </DataTable>
                    
                </div>
                <div className="p-grid" id='programmation' >
                    <div style={{width:"20vw", marginRight:10}} className='card p-md-12 p-sm-12'>
                        <h2 style={{textAlign:"center"}}>Horaire de programmation</h2>
                        <hr />
                        <form className="p-fluid speciale" >
                            <div>
                                <div className="p-field" >
                                    <div>
                                        <label htmlFor="dateProgrammation" >Date de debut  {getFormErrorMessage('dateProgrammation')}</label>
                                        <Controller 
                                            name="dateProgrammation" control={control}
                                            rules={{ required: ' champ obligatoire.'}}
                                            render={({field}) =>{
                                                return  (
                                                    <InputText type="date" id={field.name} name={field.name} value={field.value} {...field} 
                                                    // onChange={(e)=>{field.onChange(e.target.value);handleDateProgrammation(e.target.value)}} 

                                                    />
                                                )
                                        }} />
                                    </div>
                                    {dateErrors && <p style={{backgroundColor:"red",color:"white", fontSize:18,marginTop:8,textAlign:"center"}}>Hors de la semaine</p>}
                                        
                                </div>
                                <div>
                                    <div className="p-grid ">                                            
                                        <div className="p-field p-col-6" >
                                            <label htmlFor="heureDebutSeanceProg" >heure debut  {getFormErrorMessage('heureDebutSeanceProg')}</label>
                                            <Controller 
                                                name="heureDebutSeanceProg" control={control}
                                                rules={{ required: ' champ obligatoire.'}}
                                                render={({field}) =>{
                                                    return  (
                                                        <InputText type="time" id={field.name} name={field.name} value={field.value} {...field} onChange={(e)=>{field.onChange(e.target.value);handleDateDebut()}} />
                                                    )
                                            }} />
                                        </div>
                                        <div className="p-col-6 p-field" >
                                            <label htmlFor="heureFinSeanceProg">Heure fin {getFormErrorMessage('heureFinSeanceProg')}</label>
                                            <Controller 
                                                name="heureFinSeanceProg" control={control}
                                                rules={{ required: ' champ obligatoire.'}}
                                                render={({field}) => (
                                                    <InputText type="time" id={field.id} name={field.name} value={field.value} {...field} onChange={(e)=>{field.onChange(e.target.value);handleDateDebut()}}/>
                                                )} />
                                                {erreurs && <span style={{color:"red"}}>L'heure saisie est incorrectre !</span>}
                                            
                                        </div> 
                                    </div>
                                    {
                                        heureErrors && (
                                            <small style={{color:"red"}}><span>Formats des heures incorrecte.</span></small>
                                        )
                                    }
                                    
                                </div>
                            </div>
                            <div className='mb-2' style={{width:200, marginTop:15}}>
                                <Button type="button" label="Choisir Infrastructure" 
                                    disabled={spin?true:false} className="p-button-info" 
                                    iconPos='right' icon={spinInfras?"pi pi-spin pi-spinner":"pi pi-arrow-right"} 
                                    onClick={handleInfrastructure} 
                                />
                            </div>
                        </form>
                    </div>
                    
                    <div className='card p-md-12 p-sm-12 speciale' style={{width:"30vw"}} >
                        
                        <div>
                            <h2 style={{textAlign:"center"}}>Infrastructure</h2>
                            <hr />
                            <div style={{minHeight:225, display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                                <DataTable 
                                    value={listInfrastructure} 
                                    selection={[selectedInfrastructure]} onSelectionChange={(e)=>handleSelectionInfrastructure(e.value)}
                                    selectionMode="single"
                                    paginator rows={5} 
                                    // header={`Liste des infrastructures ${listInfrastructure[0].libelleTypeStructureRattachement}`}
                                >
                                    <Column field="libelleInfrastructure" header="Libellé" filterMatchMode="contains" sortable={true} filter={true}></Column>
                                    <Column field="nombrePlace" header="Capacité" filterMatchMode="contains" sortable={true} filter={true}></Column>
                                    <Column selectionMode="simple" headerStyle={{ width: '5em' }} />
                                </DataTable>
                                <div style={{width:"100px",display:"flex",marginTop:15}}>
                                    <Button type="button"
                                        // disabled={selectedInfrastructure.length===0?true:false} 
                                        className="p-button-success" label="Ajouter" 
                                        icon={refresh?"pi pi-spinner pi-spin":"pi pi-plus"} 
                                        onClick={handleAjouter}
                                        style={{marginRight:"15px"}} />
                                    <Button type='button' onClick={handleReservation} className="p-button-info" label='Réservation' />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div style={{width:"40vw"}} className="card p-md-12 p-sm-12"  >
                        <h2 style={{textAlign:"center"}}>Nouvellement ajouté </h2>
                        <hr />
                        <div className="speciale p-fieldset">
                            <table className="table">
                                <thead>
                                    <tr className="p-grid">
                                        <th className="p-col">EC</th>
                                        <th className="p-col">Type Actvité</th>
                                        <th className="p-col">Infrastructure</th>
                                        <th className="p-col" >Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        saveList.map((spec,index) =>
                                            <tr className="p-grid" key={index+1}>
                                                 <td className="p-col">{spec.selectionAffectation[0].libelleElementConstitutif}</td>
                                                <td className="p-col">{spec.selectionAffectation[0].libelleTypeActivite}</td>
                                                <td className="p-col">{spec.selectedInfrastructure.libelleInfrastructure}</td>
                                                <td><Button icon="pi pi-trash" className=" p-button-danger p-col-4" 
                                                onClick={() => {removeItem(index)}}  
                                                /></td>
                                            </tr>
                                        )
                                    }
                                </tbody>      
                            </table>
                            <div>
                            <Button label='Enregistrer' style={{width:120}} className='pi p-button-success mr-3' disabled={spin} icon={spin?"pi pi-spin pi-spinner":'pi pi-save'} onClick={handleSave} />
                            <Button label='Quitter' style={{width:120}} className='pi p-button-danger' icon='pi pi-times' disabled={spin} onClick={()=>saveList.length>0 ? setCancelAndReset(true):handleQuitter()} />
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>

            {/* Add programmation EndBloc */}
          

            {/* detail programmation Bloc */}
                <Dialog className="card" showHeader={false} visible={detailDialog.detail} style={{width:"100vw",height:"100VH",maxHeight:"100vh"}} footer={detailFooter} onHide={()=>setDetailDialog({...detailDialog,detail:false})}>
                    <div className="p-grid">
                        <div className="p-col-6 p-lg-6 p-md-12 p-sm-12">
                            <div className="p-d-flex p-jc-center mr-2">
                                <h5 className="text-header">Détail sur l'activité Académique </h5>
                                <form className="p-fluid">
                                    <div>
                                        <div>
                                            <div className="p-field" >
                                                <label htmlFor="anneeAcademique" >Année Académique</label>
                                                <Controller
                                                    name="anneeAcademique" control={control}
                                                    defaultValue={getValues("anneeAcademique")}
                                                    render={({field}) => (
                                                        <InputText
                                                        readOnly
                                                            name={field.name} value={field.value} id={field.name}
                                                            {...field}
                                                        />
                                                    )} 

                                                />
                                            </div>
                                            
                                            <div className="p-grid">
                                                <div className="p-col-7 p-field" >
                                                    <label htmlFor="parcours" >Parcours</label>
                                                    <Controller
                                                        name="parcours" control={control}
                                                        defaultValue={getValues("parcours")}
                                                        render={({field}) => (
                                                            <InputText
                                                                readOnly
                                                                name={field.name} value={field.value} id={field.name}
                                                                {...field}
                                                            />
                                                    )} 

                                                />
                                                </div>
                                                <div className="p-col-5 p-field" >
                                                    <label htmlFor="niveau" >Niveau</label>
                                                    <Controller
                                                        name="niveau" control={control}
                                                        defaultValue={getValues("niveau")}
                                                        render={({field}) => (
                                                            <InputText
                                                                readOnly
                                                                name={field.name} value={field.value} id={field.name}
                                                                {...field}
                                                            />
                                                        )} 

                                                    />
                                                </div>
                                            </div>
                                            <div className="p-field" >
                                                <label htmlFor="semestre" >Semestre</label>
                                                <Controller
                                                    name="semestre" control={control}
                                                    defaultValue={getValues("semestre")}
                                                    render={({field}) => (
                                                        <InputText
                                                        readOnly
                                                            name={field.name} value={field.value} id={field.name}
                                                            {...field}
                                                        />
                                                    )} 

                                            />
                                            </div>
                                            <div className="p-field" >
                                                <label htmlFor="enseignant" >Enseignant</label>
                                                <Controller
                                                    name="enseignant" control={control}
                                                    defaultValue={getValues("enseignant")}
                                                    render={({field}) => (
                                                    <InputText
                                                        readOnly
                                                        name={field.name} value={field.value} id={field.name}
                                                        {...field}
                                                    />
                                                )} /> 
                                                   
                                                </div>
                                                <div className="p-grid">
                                                <div className="p-col-5 p-field" >
                                                    <label htmlFor="elementConstitutif" >Elément Constitutif</label>
                                                    <Controller
                                                        name="elementConstitutif" control={control}
                                                        defaultValue={getValues("elementConstitutif")}
                                                        render={({field}) => (
                                                            <InputText
                                                                readOnly
                                                                name={field.name} value={field.value} id={field.name}
                                                                {...field}
                                                            />
                                                    )} />
                                                </div>
                                                <div className="p-col-7 p-field" >
                                                    <label htmlFor="typeActivite" >Type Activité</label>
                                                    <Controller
                                                        name="typeActivite" control={control}
                                                        defaultValue={getValues("typeActivite")}
                                                        render={({field}) => (
                                                            <InputText
                                                                readOnly
                                                                name={field.name} value={field.value} id={field.name}
                                                                {...field}
                                                            />
                                                    )} />
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <hr/>
                                        <div>
                                            <div className="p-grid">
                                                <div className="p-grid">
                                                <div className="p-col-4 p-field" >
                                                    <label htmlFor="dateProgrammation" >Date de début</label>
                                                    <Controller
                                                        name="dateProgrammation" control={control}
                                                        defaultValue={getValues("dateProgrammation")}
                                                        render={({field}) => {
                                                            return (
                                                                <Calendar 
                                                                    locale={addLocale} dateFormat="dd/mm/yy" showOnFocus={false} 
                                                                    id={field.name} name={field.name} value={field.value}
                                                                    {...field}
                                                                />
                                                            )
                                                        }} />
                                                </div>
                                                <div className="p-col-4 p-field" >
                                                    <label htmlFor="heureDebutSeanceProg" >heure début</label>
                                                    <Controller 
                                                        defaultValue={getValues("heureDebutSeanceProg")}
                                                        name="heureDebutSeanceProg" control={control}
                                                        render={({field}) =>{
                                                            return (
                                                                    <InputText readOnly name={field.name} id={field.name} value={field.value} {...field}/>
                                                            )
                                                    }} />
                                                </div>
                                                <div className="p-col-4 p-field" >
                                                    <label htmlFor="heureFinSeanceProg">Heure fin</label>
                                                    <Controller 
                                                    defaultValue={getValues("heureFinSeanceProg")} name="heureFinSeanceProg" control={control}
                                                    render={({field}) => (
                                                 
                                                        <InputText readOnly name={field.name} id={field.name} value={field.value} {...field}/>)
                                                    } />
                                                </div> 
                                            </div>
                                            </div> 
                                            
                                        </div>
                                        
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="p-col-6 p-lg-6 p-md-12 p-sm-12">
                            <div className="p-grid p-dir-col p-justify-between" style={{height:"465px"}}>
                                <div>
                                    <DataTable 
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                                        selectionMode="single" 
                                        filterMatchMode="contains"
                                        paginator={false} 
                                        responsive={true}
                                        value={infrastructure}
                                        header="Les informations sur l'infrastructure"
                                    >
                                        <Column field="sigleInfrastructure" header="Sigle" headerStyle={{textTransform:"capitalize"}}/>
                                        <Column field="libelleInfrastructure" header="libellé" headerStyle={{textTransform:"capitalize"}}/>
                                        <Column field="localite" header="localité" headerStyle={{textTransform:"capitalize"}}/>
                                        <Column field="libelleEtatInfrastructure" header="etat" headerStyle={{textTransform:"capitalize"}}/>
                                        <Column field="libelleTypeInfrastructure" header="type" headerStyle={{textTransform:"capitalize"}}/>
                                        <Column field="libelleTypeStructureRattachement" header="departement" headerStyle={{textTransform:"capitalize"}}/>
                                        <Column field="nombrePlace" header="nombre de place" headerStyle={{textTransform:"capitalize"}}/>
                                    </DataTable>
                                </div>
                                <div>
                                    <DataTable 
                                            selectionMode="single" filterMatchMode="contains"
                                             responsive={true}
                                            value={cohortes}
                                            header={`Panel des cohortes de l'année académique `}
                                        >
                                            <Column field="libelleParcours" header="parcours" headerStyle={{textTransform:"capitalize"}}/>
                                            {/* <Column field="libelleSemestre" header="Semestre" headerStyle={{textTransform:"capitalize"}}/> */}
                                            <Column field="libelleCohorte" header="Libelle" headerStyle={{textTransform:"capitalize"}}/>
                                            <Column field="nbEtudiantCohorte" header="nombre d'étudiants" headerStyle={{textTransform:"capitalize"}}/>
                                            
                                        </DataTable>
                                </div>
                            </div>

                        </div>
                       
                    </div>
                </Dialog>
            {/* detail programmation EndBloc */}



            <Dialog  visible={checkInfrastructure.popup} style={{width:"60vw"}} onHide={()=>setCheckInfrastructure({...checkInfrastructure,popup:false,typeStructure:"",setListInfrastructureByDepartement:[]})}>

                <div className="p-grid ">                                            
                    <div className="p-field p-col-2" >
                        <div className="flex align-items-center">
                            <RadioButton inputId="universite" name="typeStructure" value="universite" onChange={(e) => setTypeStructureInfrastructure(e.value)} checked={typeStructure === 'universite'} />
                            <label htmlFor="universite" className="ml-2">Université</label>
                        </div>
                    </div>

                    <div className="p-field p-col-2" >
                        <div className="flex align-items-center">
                            <RadioButton inputId="etablissement" name="typeStructure" value="etablissement" onChange={(e) => setTypeStructureInfrastructure(e.value)} checked={typeStructure === 'etablissement'} />
                            <label htmlFor="etablissement" className="ml-2">Etablissement</label>
                        </div>
                    </div>

                    <div className="p-field p-col-2" >
                        <div className="flex align-items-center">
                            <RadioButton inputId="departement" name="typeStructure" value="departement" onChange={(e) => setTypeStructureInfrastructure(e.value)} checked={typeStructure === 'departement'} />
                            <label htmlFor="departement" className="ml-2">Département</label>
                        </div>
                    </div>
                </div>

                {typeStructure==="departement" && 

                <div className="p-field mb-3 mt-2" >
                    <label htmlFor="departement"></label>
                    
                    <Controller name="curentDepartement" control={control} 
                        render={({ field, fieldState }) => (
                            <Dropdown name={field.name} id={field.name} value={field.value}
                                {...field}
                                options={listDepartementByIesr} optionLabel="lbDepartementEtab"
                                onChange={(e)=>{field.onChange(e.value);handleDepartement(e.value)}}
                                placeholder="selectionner un departement"
                            />
                        )} 
                    />
                </div>}


                {typeStructure==="etablissement" && 

                <div className="p-field mb-3 mt-2" >
                    <label htmlFor="etablissement"></label>
                    
                    
                    <Controller name="curentEtablissement" control={control} 
                        render={({ field, fieldState }) => (
                            <Dropdown name={field.name} id={field.name} value={field.value}
                                {...field}
                                options={listeEtablissement} optionLabel="sigleEtablissement"
                                onChange={(e)=>{field.onChange(e.value);handleEtablissement(e.value)}}
                                placeholder="selectionner un établissement"
                            />
                        )} 
                    />
                </div>}

                <DataTable 
                    value={listInfrastructureByDepartement} 
                    selection={infrastructureReserver} onSelectionChange={(e)=>handleSelectionReservation(e.value)}
                    selectionMode="single"
                    paginator rows={5} 
                    header={`Liste des infrastructures du departement ${libelleDepartementSelect}`}
                >
                    <Column field="libelleInfrastructure" header="Libellé" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="nombrePlace" header="Capacité" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column selectionMode="simple" headerStyle={{ width: '5em' }} />
                </DataTable>
                
            </Dialog>

            <Dialog visible={message.messageDialog} style={{ width: '500px' }} header="Confirmez" modal footer={messageFooter} onHide={()=>setMessage({...message,messageDialog:false})}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    <span>La cohorte 
                    {/* <strong>{message.cohorte.libelleCohorte}</strong>  */}
                    a été déjà programmée pour une autre séance d'activité dans cette plage horaire </span>
                    <span>En continuant seule la nouvelle programmation sera prise en compte !</span>
                </div>
            </Dialog>
            <Dialog visible={message.messageInfrastructure} style={{ width: '500px' }} header="Confirmez" modal footer={messageInfrastructureFooter} onHide={()=>setMessage({...message,messageInfrastructure:false})}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    <span>L'infrastructure <strong>{message.infrastructure.libelleInfrastructure}</strong> a été déjà programmée pour une autre séance d'activité dans cette plage horaire </span>
                    <span>En continuant seule la nouvelle programmation sera prise en compte !</span>
                </div>
            </Dialog>

            <Dialog visible={cancelAndReset} style={{ width: '450px' }} header="Confirmez" modal footer={cancelAndResetFooter} onHide={()=>setCancelAndReset(false)}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    <span>Etes-vous sûr de quitter ?</span><br /><br />
                    <span>Les informations saisies seront perdues</span>
                </div>
            </Dialog>

            <Dialog visible={limitHeure.popup} style={{ width: '450px' }} header="Confirmez" modal onHide={()=>setLimiteHeure({...limitHeure,popup:false})}>
                <div className="confirmation-content">
                    <span>La programmation de la cohorte {limitHeure.element.libelleCohorte} ne doit pas excéder le nombre d'heure attribuer à l'enseignant durant l'affectation</span>
                    <span> Vous devez reduire les heures de {limitHeure.sup}</span>
                </div>
            </Dialog>

            <Dialog visible={dialog.delete} style={{ width: '450px' }} header="Confirmez" modal footer={deleteProgrammationFooter} onHide={()=>setDialog({...dialog,delete:false})}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {"'this.state.deletionId'" && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                </div>
            </Dialog>
          
        </>
    );
}