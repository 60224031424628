import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Messages } from "primereact/messages";
import { Message } from "primereact/message";
import { InputText } from "primereact/inputtext";
import { Notify } from "../../components/Notify";
import { ToastContainer } from "react-toastify";
import { Spinner } from "../../components/Spinner";
import { ExamenService } from "../service/ExamenService";
import { SessionExamenService } from "../service/SessionExamenService";
import { NotationService } from "../service/NotationService";
import { EnseignantService } from "../service/EnseignantService";
import { Dropdown } from "primereact/dropdown";
import classNames from "classnames";
import { AnneeService } from "../service/AnneeService";
import { SemestreService } from "../service/SemestreService";
import { IesrService } from "../service/IesrService";
import { DepartementService } from "../service/DepartementService";
import { CohorteServices } from "../../volumeHoraire/service/CohorteServices";

export class ValidationNotes extends Component {
    emptyImportNote = {
        examen: null,
        fichier: null,
        nomFichier: "",
    };

    constructor(props) {
        super(props);
        this.notify = new Notify();
        this.spinner = new Spinner();

        this.examenService = new ExamenService();
        this.sessionService = new SessionExamenService();
        this.notationService = new NotationService();
        this.enseignantService = new EnseignantService();
        this.anneeService = new AnneeService();
        this.semestreService = new SemestreService();
        this.iesrService = new IesrService();
        this.departementService = new DepartementService();
        this.cohorteServices = new CohorteServices();

        this.state = {
            listeExamen: [],
            listeSession: [],
            listeIesr: [],
            listeDepartement: [],
            listeAnnee: [],
            listeSemestre: [],
            annee: null,
            iesr: null,
            semestre: null,
            departement: null,
            examen: null,
            fichier: null,
            nomFichier: "",
            importNote: this.emptyImportNote,
            submitted: false,
            redirect: false,
            loading: false,
            loadingExamen: false,
            loadingNotation: false,
            bool: false,
            curentExamen: {},
            notationList: [],
            globalFilter: null,
            viewCreateNotationDialog: false,
            allowSave: false,
            allowValide: false,
            allowSendMail: false,
            globalFilterNotation: null,
            globalFilterListeExamen: null,
            globalFilterSession: null,
            listeExamenDialog: false,
            saisieNoteDialog: false,
            viewHistorique: false,
            loadingHistorique: false,
            listeHistorique: [],
            curentNotation: null,
            titreExamen: "",
            token: null,
            verifeEnseignant: false,
            verifeDeptEtab: false,
            verifeDepartement: false,
            listeEcExamenDialog: false,
            loadingEcExamen: false,
            listeEcExamen: [],
            viewAvertissement: false,
            listeEc: [],

            isDGESup: false,
            isVp: false,
            isEnseignant: false,
            isEtudiant: false,
            isDepartement: false,
            isDirecteur: false,
            isDA: false,
        };
    }

    componentDidMount = () => {
        const tokenDecoded = localStorage.getItem("tokenDecoded");
        const token = JSON.parse(tokenDecoded);
        const campusDGESup = localStorage.getItem("campusDGESup");
        const campusVpEip = localStorage.getItem("campusVpEip");
        const campusEnseignant = localStorage.getItem("campusEnseignant");
        const campusEtudiant = localStorage.getItem("campusEtudiant");
        const campusDirecteur = localStorage.getItem("campusDirecteur");
        const campusDa = localStorage.getItem("campusDa");
        const campusDepartement = localStorage.getItem("campusDepartement");
        const isDGESup = parseInt(JSON.parse(campusDGESup));
        const isVp = parseInt(JSON.parse(campusVpEip));
        const isEnseignant = parseInt(JSON.parse(campusEnseignant));
        const isEtudiant = parseInt(JSON.parse(campusEtudiant));
        const isDepartement = parseInt(JSON.parse(campusDepartement));
        const isDirecteur = parseInt(JSON.parse(campusDirecteur));
        const isDA = parseInt(JSON.parse(campusDa));

        this.setState({
            loading: true,
            token: token,
            isDepartement: isDepartement,
            isDA: isDA,
            isDirecteur: isDirecteur,
            isVp: isVp,
        });
        if (isDA || isDirecteur) {
            this.setState({ verifeDeptEtab: true });
            this.cohorteServices
                .getListeDepartementEtab(token.code_etablissement)
                .then((data) => {
                    //console.log(data)
                    this.setState({ listeDepartement: data, loading: false });
                });
        } else if (isDepartement) {
            this.setState({ verifeDeptEtab: true });
            this.sessionService
                .getSessionDepartement(token.code_departement)
                .then((data) => {
                    //On filtre uniquement les sessions qui sont ouverts
                    let listeSessionOuvert = data.data.filter(
                        (curentSession) => {
                            return curentSession.etatSession === "OUVERT";
                        }
                    );

                    this.setState({
                        listeSession: listeSessionOuvert,
                        loading: false,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.notify.echec(error.response.data.message);
                    this.setState({ loading: false });
                });
        } else if (isEnseignant && !isDepartement) {
            this.anneeService
                .getAll()
                .then((data) => {
                    this.setState({ listeAnnee: data });
                })
                .catch((error) => {
                    console.log(error.response.data.message);
                });
            this.semestreService.getAll().then((data) => {
                this.setState({ listeSemestre: data });
            });
            this.iesrService.getAll().then((data) => {
                this.setState({ listeIesr: data });
            });
            this.setState({ loading: false, verifeEnseignant: true });
        } else {
            //this.setState({loading: false, verifeEnseignant : true})
        }
    };

    listeExamen = (session) => {
        let idSession = session.idSession;
        this.setState({
            listeExamenDialog: true,
            session: session,
            loadingExamen: true,
            titreExamen: session.libelleSession,
        });

        this.examenService
            .examenBySession(idSession)
            .then((data) => {
                this.setState({
                    listeExamen: data,
                    loadingExamen: false,
                });
            })
            .catch((error) => {
                console.log(error);
                this.notify.echec(error.response.data.message);
            });
    };

    listeEcExamen = (Ec) => {
        let idEc = Ec.idEc;
        let idAnnee = this.state.annee.idAnneeAcademique;
        this.setState({
            listeEcExamenDialog: true,
            loadingEcExamen: true,
        });
        this.examenService
            .examenByEc(idEc,idAnnee)
            .then((data) => {
                //console.log(data)
                const n = data.length;
                //console.log(n)
                if(n>0){
                    this.setState({
                        session: data[n - 1].session,
                        listeEcExamen: data,
                        loadingEcExamen: false,
                    });
                }
                else {
                    this.setState({
                        loadingEcExamen: false,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                this.notify.echec(error.response.data.message);
            });
    };

    saisieNote = (examen) => {
        //On recupère l'id de l'examen pour la recuperation de tout le necessaire

        console.log(examen);
        this.setState({
            loadingNotation: true,
            saisieNoteDialog: true,
        });
        this.notationService
            .getListNotation(parseInt(examen.idExamen))
            .then((dataNotation) => {
                console.log(dataNotation);

                let i = 0;
                dataNotation.map((notation) => {
                    //Ajout d'un index pour la modification de la note
                    notation["index"] = i;
                    i++;
                });

                //let curentExamen = dataNotation[0].examen
                this.setState({
                    notationList: dataNotation,
                    curentExamen: examen,
                });

                //On regarde côté back si le bouton validé doit être affiché
                if (dataNotation.length > 0) {
                    this.notationService
                        .verifierValiditerNotations(
                            dataNotation[0].examen.idExamen
                        )
                        .then((statutNotation) => {
                            this.setState({
                                allowValide: statutNotation ? false : true,
                                allowSendMail: statutNotation ? true : false,
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                            this.notify.echec(error.response.data.message);
                        });
                }

                this.setState({
                    loadingNotation: false,
                });
            })
            .catch((error) => {
                this.setState({ notationList: null });
                console.log(error);
                this.notify.echec(error.response.data.message);
            });

        this.setState({
            examen: examen,
        });
    };

    historiqueNotation = (notation) => {
        this.setState({
            loadingHistorique: true,
        });

        this.notationService
            .getHistoriqueNotation(notation.idNotation)
            .then((data) => {
                if (data.length > 0) {
                    this.setState({
                        viewHistorique: true,
                        curentNotation: notation,
                        listeHistorique: data,
                        loadingHistorique: false,
                    });
                } else {
                    alert(
                        "Pas d'historique pour cette notation pour le moment !!!"
                    );
                    this.setState({
                        loadingHistorique: false,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                this.notify.echec(error.response.data.message);

                this.setState({
                    loadingHistorique: false,
                    listeHistorique: [],
                    curentNotation: null,
                });
            });
    };

    hideHistoriqueNoteDialog = () => {
        this.setState({
            viewHistorique: false,
            curentNotation: null,
        });
    };

    notificationValidationNote = () => {
        this.setState({
            viewAvertissement: true,
        });
    };

    hideConfirmationDialog = () => {
        this.setState({ viewAvertissement: false });
    };

    //Gestion de l'enregistrement de la notation
    saveNotation = () => {
        this.setState({
            spin: true,
        });

        //On parcours toutes les notes pour voir si y'a des notes qui n'ont pas été omis
        let curentListeNote = this.state.notationList;
        curentListeNote.map((note) => {
            note.fgValide = true;
        });

        //Nous sommes dans le principe de la validation de note
        this.notationService
            .validationNotations(curentListeNote)
            .then(() => {
                this.notify.success();
                this.setState({
                    spin: false,
                });

                //On regarde côté back si le bouton validé doit être affiché
                if (curentListeNote.length > 0) {
                    this.notationService
                        .verifierValiditerNotations(
                            curentListeNote[0].examen.idExamen
                        )
                        .then((statutNotation) => {
                            console.log(statutNotation);

                            this.setState({
                                allowValide: statutNotation ? false : true,
                                allowSendMail: statutNotation ? true : false,
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                            this.notify.echec(error.response.data.message);
                        });
                }
            })
            .catch((error) => {
                this.notify.echec(error.response.data.message);
                console.log(error);
                this.setState({
                    spin: false,
                });
            });
    };

    //Gestion de l'envoi des mail
    sendNotificationEmail = () => {
        this.setState({
            spin: true,
        });

        let curentListeNote = this.state.notationList;
        //Activation envoi de mail
        this.notationService
            .sendNotificationNotation(curentListeNote)
            .then(() => {
                this.notify.success();
                this.setState({
                    spin: false,
                    viewAvertissement: false,
                });
            })
            .catch((error) => {
                console.log(error);
                this.notify.echec(error.response.data.message);
                this.setState({
                    viewAvertissement: false,
                    spin: false,
                });
            });
    };

    onNoteChange = (value, count) => {
        let curentNotations = this.state.notationList;
        if (
            !isNaN(value) &&
            parseFloat(value) >= 0 &&
            parseFloat(value) <= 20
        ) {
            curentNotations[count].note = parseFloat(value);
            curentNotations[count].fgValide = false;
        } else if (value === "") {
            curentNotations[count].note = 0;
        }
        curentNotations[count].absentEvaluation = false;

        this.setState({
            notationList: curentNotations,
        });
    };
    onObservationChange = (value, count) => {
        let curentNotations = this.state.notationList;
        curentNotations[count].observationNotation = value;

        this.setState({
            notationList: curentNotations,
        });
    };
    inputNoteEditor = (props) => {
        return (
            <input
                type="number"
                value={props.rowData.note}
                onChange={(e) =>
                    this.onNoteChange(e.target.value, props.rowData.index)
                }
                placeholder="Note sur 20"
                className="p-inputtext p-component"
                step="0.01"
                max="20"
                min="0"
            />
        );
    };

    editNote = (props) => {
        return this.inputNoteEditor(props);
    };

    inputObservationEditor = (props) => {
        return (
            <InputText
                value={props.rowData.observationNotation}
                onChange={(e) =>
                    this.onObservationChange(
                        e.target.value,
                        props.rowData.index
                    )
                }
                placeholder="Observation"
                className="p-inputtext p-component"
            />
        );
    };

    editObservation = (props) => {
        return this.inputObservationEditor(props);
    };

    hideDialog = () => {
        this.setState({
            submitted: false,
            examenDialog: false,
        });
    };
    hideSessionDialog = () => {
        this.setState({
            sessionDialog: false,
        });
    };
    hideListeExamenDialog = () => {
        this.setState({
            listeExamenDialog: false,
        });
    };
    hideListeEcExamenDialog = () => {
        this.setState({
            listeEcExamenDialog: false,
        });
    };

    hideSaisieNoteDialog = () => {
        this.setState({
            saisieNoteDialog: false,
        });
    };

    actionBodyTemplateListeExamen = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-list"
                    className="p-button-success"
                    style={{ marginRight: ".5em" }}
                    title="Importer des données"
                    onClick={() => this.saisieNote(rowData)}
                />
            </React.Fragment>
        );
    };

    actionBodyTemplateListeNotation = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-calendar-minus"
                    className="p-button-success"
                    style={{ marginRight: ".5em" }}
                    title="Historique des notations"
                    onClick={() => this.historiqueNotation(rowData)}
                />
            </React.Fragment>
        );
    };

    actionBodyTemplateSession = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-list"
                    className="p-button-warning"
                    style={{ marginRight: ".5em" }}
                    title="Liste des examens"
                    onClick={() => this.listeExamen(rowData)}
                />
            </React.Fragment>
        );
    };
    actionBodyTemplateEc = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-list"
                    className="p-button-warning"
                    style={{ marginRight: ".5em" }}
                    title="Liste des examens"
                    onClick={() => this.listeEcExamen(rowData)}
                />
            </React.Fragment>
        );
    };

    ListeEcEnseignant = () => {
        this.setState({ submitted: true });
        if (
            this.state.token &&
            this.state.departement &&
            this.state.annee &&
            this.state.semestre
        ) {
            let matricule = this.state.token.matricule;
            let idDepartement = this.state.departement.idDepartementEtab;
            let idAnnee = this.state.annee.idAnneeAcademique;
            let idSemestre = this.state.semestre.idSemestre;

            this.setState({ loading: true });
            this.enseignantService
                .getEnseignant(matricule)
                .then((ens) => {
                    console.log(ens);
                    this.enseignantService
                        .getEcEnseignant(
                            ens.idEnseignant,
                            idDepartement,
                            idAnnee,
                            idSemestre
                        )
                        .then((data) => {
                            this.setState({ listeEc: data, loading: false });
                        })
                        .catch((error) => {
                            this.setState({ loading: false });
                            console.log(error);
                            this.notify.echec(error.response.data.message);
                        });
                })
                .catch((error) => {
                    this.setState({ loading: false });
                    console.log(error);
                });
            /*this.enseignantService.getEcEnseignant(idEnseignant, idDepartement, idAnnee, idSemestre).then(data => {
                this.setState({listeEc : data, loading: false})

                console.log(data)
            })
            .catch(error => {
                this.setState({loading : false})
                console.log(error)
                this.notify.echec(error.response.data.message)
            })*/
        }
    };
    onValueChange = (e, field) => {
        const val = (e.target && e.target.value) || "";
        if (field === "iesr") {
            let iesr = this.state.iesr;
            iesr = val;
            this.setState({ iesr });
            this.loadDepartement(iesr.idIesr);
        } else if (field === "annee") {
            let annee = this.state.annee;
            annee = val;
            this.setState({ annee });
        } else if (field === "semestre") {
            let semestre = this.state.semestre;
            semestre = val;
            this.setState({ semestre });
        } else if (field === "departement") {
            let departement = this.state.departement;
            departement = val;
            this.setState({ departement });
        } else {
            console.log("griffe de gredey !!!");
        }
    };
    loadDepartement = (idIesr) => {
        this.departementService
            .getListeDepartement(idIesr)
            .then((data) => {
                this.setState({ listeDepartement: data });
            })
            .catch((error) => {
                console.log(error);
                this.notify.echec(error.response.data.message);
            });
    };

    render() {
        let actionHeader = <Button type="button" icon="pi pi-cog" />;
        let titreDatatable = this.state.curentExamen.idExamen
            ? `Liste de notation de l'examen ${this.state.curentExamen.libelleExamen} - (${this.state.notationList.length} étudiants)`
            : "Aucune notation disponible !";
        let titreDatatableDialog = this.state.curentExamen.idExamen
            ? `Notation de l'examen ${this.state.curentExamen.libelleExamen} - (${this.state.notationList.length} étudiants)`
            : "Notation examen";
        let allowValide = this.state.allowValide ? (
            <Button
                label="Valider"
                icon={this.state.spin ? "pi pi-spin pi-spinner" : "pi pi-check"}
                className="p-button-text"
                onClick={() => this.saveNotation()}
                disabled={this.state.spin ? true : false}
            />
        ) : (
            ""
        );

        let allowSendMail = this.state.allowSendMail ? (
            <Button
                label="Envoyer des notifications aux étudiants"
                icon={
                    this.state.spin ? "pi pi-spin pi-spinner" : "pi pi-envelope"
                }
                className="p-button-text"
                onClick={() => this.notificationValidationNote()}
                disabled={this.state.spin ? true : false}
            />
        ) : (
            ""
        );

        const headerSession = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText
                        type="search"
                        onInput={(e) =>
                            this.setState({
                                globalFilterSession: e.target.value,
                            })
                        }
                        placeholder="Recherche globale..."
                    />
                </span>
            </div>
        );
        const headerEc = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText
                        type="search"
                        onInput={(e) =>
                            this.setState({
                                globalFilterSession: e.target.value,
                            })
                        }
                        placeholder="Recherche globale..."
                    />
                </span>
            </div>
        );

        const headerExamen = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText
                        type="search"
                        onInput={(e) =>
                            this.setState({
                                globalFilterListeExamen: e.target.value,
                            })
                        }
                        placeholder="Recherche globale..."
                    />
                </span>
            </div>
        );

        const confirmFooter = (
            <React.Fragment>
                <Button
                    label="NON"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideConfirmationDialog}
                />
                <Button
                    label="OUI"
                    icon="pi pi-check"
                    className="p-button-text"
                    onClick={() =>
                        this.confirmDeleteExamen(this.state.deletionId)
                    }
                />
            </React.Fragment>
        );

        const confirmValidation = (
            <React.Fragment>
                <Button
                    label="Annuler"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={() => this.hideConfirmationDialog()}
                />
                <Button
                    label="Valider"
                    icon="pi pi-check"
                    className="p-button-text"
                    onClick={() => this.sendNotificationEmail()}
                />
            </React.Fragment>
        );

        const listeExamenDialogFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={
                        this.state.listeExamenDialog
                            ? this.hideListeExamenDialog
                            : this.hideListeEcExamenDialog
                    }
                />
            </React.Fragment>
        );

        const historiqueNotationDialogFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideHistoriqueNoteDialog}
                />
            </React.Fragment>
        );

        const saisieNoteDialogFooter = (
            <React.Fragment>
                {/*this.state.session && !this.state.session.estDelibere && <>
                    {allowValide}
        </>*/}

                {allowValide}
                {allowSendMail}

                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideSaisieNoteDialog}
                    disabled={this.state.spin ? true : false}
                />
            </React.Fragment>
        );

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        {
                            <ToastContainer
                                position="top-right"
                                autoClose={2000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                        }

                        {this.state.loading ? (
                            <div className="p-grid">
                                <div className="p-col-6">
                                    {this.spinner.loadingSpinner(
                                        "spin",
                                        "black"
                                    )}
                                </div>
                            </div>
                        ) : this.state.verifeEnseignant ? (
                            <div className="">
                                <div className="p-grid p-col-12">
                                    <div className="p-col-3">
                                        <Dropdown
                                            optionLabel="libelleIesr"
                                            optionValue="idIesr"
                                            value={this.state.iesr}
                                            options={this.state.listeIesr}
                                            onChange={(e) => {
                                                this.onValueChange(e, "iesr");
                                            }}
                                            required
                                            autoFocus
                                            className={classNames({
                                                "p-invalid":
                                                    this.state.submitted,
                                            })}
                                            placeholder="Selectionner l'université"
                                        />
                                        {this.state.submitted &&
                                            !this.state.iesr && (
                                                <small
                                                    className="p-invalid"
                                                    style={{ color: "red" }}
                                                >
                                                    Ce champs est obligatoire.
                                                </small>
                                            )}
                                    </div>
                                    <div className="p-col-3">
                                        <Dropdown
                                            optionLabel="lbDepartementEtab"
                                            optionValue="idDepartementEtab"
                                            value={this.state.departement}
                                            options={
                                                this.state.listeDepartement
                                            }
                                            onChange={(e) => {
                                                this.onValueChange(
                                                    e,
                                                    "departement"
                                                );
                                            }}
                                            required
                                            autoFocus
                                            className={classNames({
                                                "p-invalid":
                                                    this.state.submitted,
                                            })}
                                            placeholder="Selectionner le département"
                                        />
                                        {this.state.submitted &&
                                            !this.state.departement && (
                                                <small
                                                    className="p-invalid"
                                                    style={{ color: "red" }}
                                                >
                                                    Ce champs est obligatoire.
                                                </small>
                                            )}
                                    </div>
                                    <div className="p-col-2">
                                        <Dropdown
                                            optionLabel="libelleAnnee"
                                            optionValue="idAnneeAcademique"
                                            value={this.state.annee}
                                            options={this.state.listeAnnee}
                                            onChange={(e) => {
                                                this.onValueChange(e, "annee");
                                            }}
                                            required
                                            autoFocus
                                            className={classNames({
                                                "p-invalid":
                                                    this.state.submitted,
                                            })}
                                            placeholder="Année académique"
                                        />
                                        {this.state.submitted &&
                                            !this.state.annee && (
                                                <small
                                                    className="p-invalid"
                                                    style={{ color: "red" }}
                                                >
                                                    Ce champs est obligatoire
                                                </small>
                                            )}
                                    </div>
                                    <div className="p-col-2">
                                        <Dropdown
                                            optionLabel="libelleSemestre"
                                            optionValue="idSemestre"
                                            value={this.state.semestre}
                                            options={this.state.listeSemestre}
                                            onChange={(e) => {
                                                this.onValueChange(
                                                    e,
                                                    "semestre"
                                                );
                                            }}
                                            required
                                            autoFocus
                                            className={classNames({
                                                "p-invalid":
                                                    this.state.submitted,
                                            })}
                                            placeholder="Selectionner un semestre"
                                        />
                                        {this.state.submitted &&
                                            !this.state.semestre && (
                                                <small
                                                    className="p-invalid"
                                                    style={{ color: "red" }}
                                                >
                                                    Ce champs est obligatoire.
                                                </small>
                                            )}
                                    </div>
                                    <div className="p-col-2">
                                        <Button
                                            label="Liste EC"
                                            onClick={() => {
                                                this.ListeEcEnseignant();
                                            }}
                                            className="p-button-text"
                                        />
                                    </div>
                                </div>
                                {headerEc}
                                <DataTable
                                    ref={(el) => (this.dt = el)}
                                    value={this.state.listeEc}
                                    selectionMode="single"
                                    header={`Liste des élements constitutifs (${this.state.listeEc.length})`}
                                    paginator={true}
                                    rows={10}
                                    globalFilter={this.state.globalFilter}
                                    filterMatchMode="contains"
                                    responsive={true}
                                    selection={this.state.dataTableSelection1}
                                    onSelectionChange={(event) =>
                                        this.setState({
                                            dataTableSelection1: event.value,
                                        })
                                    }
                                >
                                    <Column
                                        field="codeEc"
                                        header="Code EC"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Rechercher code EC"
                                    />
                                    <Column
                                        field="libelleEc"
                                        header="Libelle EC"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="rechercher libellé EC"
                                    />
                                    <Column
                                        field="creditEc"
                                        header="Crédit EC"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="rechercher un crédit EC"
                                    />
                                    <Column
                                        header={actionHeader}
                                        body={this.actionBodyTemplateEc}
                                        style={{
                                            textAlign: "center",
                                            width: "8em",
                                        }}
                                    />
                                </DataTable>
                            </div>
                        ) : this.state.verifeDeptEtab ? (
                            <>
                                {headerSession}
                                <DataTable
                                    ref={(el) => (this.dt = el)}
                                    value={this.state.listeSession}
                                    selectionMode="single"
                                    header={`Liste des sessions (${this.state.listeSession.length})`}
                                    paginator={true}
                                    rows={10}
                                    globalFilter={
                                        this.state.globalFilterSession
                                    }
                                    filterMatchMode="contains"
                                    responsive={true}
                                    selection={this.state.dataTableSelection1}
                                    onSelectionChange={(event) =>
                                        this.setState({
                                            dataTableSelection1: event.value,
                                        })
                                    }
                                >
                                    <Column
                                        field="parcoursFiliere.libelleParcours"
                                        header="Parcours"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Rechercher un parcours"
                                    />
                                    <Column
                                        field="anneeSemestre.libelleAnneeAcademique"
                                        header="Année accadémique"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="rechercher une année"
                                    />
                                    <Column
                                        field="anneeSemestre.libelleSemestre"
                                        header="Semestre"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="rechercher un semestre"
                                    />
                                    <Column
                                        field="typeSession.libelleTypeSession"
                                        header="Session"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="recherhcher une session"
                                    />
                                    <Column
                                        field="etatSession"
                                        header="Etat de la session"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="recherhcher un état"
                                    />
                                    <Column
                                        header={actionHeader}
                                        body={this.actionBodyTemplateSession}
                                        style={{
                                            textAlign: "center",
                                            width: "8em",
                                        }}
                                    />
                                </DataTable>
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                </div>

                <Dialog
                    visible={this.state.listeExamenDialog}
                    style={{ width: "80%" }}
                    header={`Liste des examens: ${this.state.titreExamen}`}
                    modal
                    className="p-fluid"
                    footer={listeExamenDialogFooter}
                    onHide={this.hideListeExamenDialog}
                >
                    {this.state.loadingExamen ? (
                        <div className="p-grid">
                            <div className="p-col-6">
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                    ) : (
                        <React.Fragment>
                            {headerExamen}
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.state.listeExamen}
                                selectionMode="single"
                                header={`Liste des examens (${this.state.listeExamen.length} examens)`}
                                paginator={true}
                                rows={5}
                                globalFilter={
                                    this.state.globalFilterListeExamen
                                }
                                filterMatchMode="contains"
                                responsive={true}
                                selection={this.state.dataTableSelection1}
                                onSelectionChange={(event) =>
                                    this.setState({
                                        dataTableSelection1: event.value,
                                    })
                                }
                            >
                                <Column
                                    field="elementConstitutif.libelleElementConstitutif"
                                    header="Element constitutif"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="Rechercher un parcours"
                                />
                                <Column
                                    field="dateExamen"
                                    header="Date de composition"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="rechercher une date"
                                />
                                <Column
                                    field="heureExamen"
                                    header="Heure de composition"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="rechercher une heure"
                                />
                                <Column
                                    field="heureFinExamen"
                                    header="Heure fin examen"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="rechercher une heure fin"
                                />
                                <Column
                                    field="observationExamen"
                                    header="Observation"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="recherhcher une observation"
                                />
                                <Column
                                    field="paramNote.valParam"
                                    header="Taux de notation"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="recherhcher un lieu"
                                />
                                <Column
                                    header={actionHeader}
                                    body={this.actionBodyTemplateListeExamen}
                                    style={{
                                        textAlign: "center",
                                        width: "8em",
                                    }}
                                />
                            </DataTable>
                        </React.Fragment>
                    )}
                </Dialog>

                <Dialog
                    visible={this.state.saisieNoteDialog}
                    style={{ width: "80%" }}
                    header={titreDatatableDialog}
                    modal
                    className="p-fluid"
                    footer={saisieNoteDialogFooter}
                    onHide={this.hideSaisieNoteDialog}
                >
                    {this.state.loadingNotation ? (
                        <div className="p-grid">
                            <div className="p-col-6">
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                    ) : (
                        <div className="p-grid">
                            <div className="p-col-12">
                                <DataTable
                                    ref={(el) => (this.dt = el)}
                                    value={this.state.notationList}
                                    selectionMode="single"
                                    header={titreDatatable}
                                    paginator={true}
                                    rows={10000}
                                    globalFilter={
                                        this.state.globalFilterNotation
                                    }
                                    filterMatchMode="contains"
                                    responsive={true}
                                    selection={this.state.dataTableSelection1}
                                    onSelectionChange={(event) =>
                                        this.setState({
                                            dataTableSelection1: event.value,
                                        })
                                    }
                                >
                                    <Column
                                        field="etudiant.ine"
                                        header="INE Etudiant"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="INE Etudiant"
                                    />
                                    <Column
                                        field="etudiant.nom"
                                        header="Nom étudiant"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Nom étudiant"
                                    />
                                    <Column
                                        field="etudiant.prenom"
                                        header="Prénom(s) étudiant"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Prénom(s) étudiant"
                                    />
                                    <Column
                                        field="note"
                                        header="Note obtenue"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Note obtenue"
                                    />
                                    <Column
                                        field="observationNotation"
                                        header="Observation note"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="observation note"
                                    />
                                    <Column
                                        header={actionHeader}
                                        body={
                                            this.actionBodyTemplateListeNotation
                                        }
                                        style={{
                                            textAlign: "center",
                                            width: "8em",
                                        }}
                                    />
                                </DataTable>
                            </div>
                            <div className="p-col-8"></div>
                        </div>
                    )}
                </Dialog>

                <Dialog
                    visible={this.state.viewConfirmAbsence}
                    style={{ width: "450px" }}
                    header="Confirmez"
                    modal
                    footer={confirmFooter}
                    onHide={this.hideDeleteExamenDialog}
                >
                    <div className="confirmation-content">
                        <i
                            className="pi pi-exclamation-triangle p-mr-3"
                            style={{ fontSize: "2rem" }}
                        />
                        {this.state.deletionId && (
                            <span>
                                Etes-vous sûr de supprimer cet élément ?
                            </span>
                        )}
                    </div>
                </Dialog>

                <Dialog
                    visible={this.state.viewHistorique}
                    style={{ width: "60%" }}
                    header="Historique des notations"
                    modal
                    className="p-fluid"
                    footer={historiqueNotationDialogFooter}
                    onHide={this.hideHistoriqueNoteDialog}
                >
                    {this.state.loadingHistorique ? (
                        <div className="p-grid">
                            <div className="p-col-6">
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                    ) : (
                        <React.Fragment>
                            {this.state.curentNotation && (
                                <table className="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <td>Etudiant</td>
                                            <td>EC</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {
                                                    this.state.curentNotation
                                                        .etudiant.ine
                                                }{" "}
                                                {
                                                    this.state.curentNotation
                                                        .etudiant.nom
                                                }{" "}
                                                {
                                                    this.state.curentNotation
                                                        .etudiant.prenom
                                                }
                                            </td>
                                            <td>
                                                {
                                                    this.state.curentNotation
                                                        .examen
                                                        .elementConstitutif
                                                        .libelleElementConstitutif
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.state.listeHistorique}
                                selectionMode="single"
                                header="Historique de la notation"
                                paginator={true}
                                rows={5}
                                globalFilter={
                                    this.state.globalFilterListeExamen
                                }
                                filterMatchMode="contains"
                                responsive={true}
                                selection={this.state.dataTableSelection1}
                                onSelectionChange={(event) =>
                                    this.setState({
                                        dataTableSelection1: event.value,
                                    })
                                }
                            >
                                <Column
                                    field="note"
                                    header="Note"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={false}
                                    filterPlaceholder="Rechercher une note"
                                />
                                <Column
                                    field="createdDate"
                                    header="Date de saisie"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={false}
                                    filterPlaceholder="rechercher une date"
                                />
                                <Column
                                    field="lastModifiedBy"
                                    header="Utilisateur ayant saisi"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={false}
                                    filterPlaceholder="rechercher un utilisateur"
                                />
                            </DataTable>
                        </React.Fragment>
                    )}
                </Dialog>
                <Dialog
                    visible={this.state.listeEcExamenDialog}
                    style={{ width: "80%" }}
                    header={`Liste des examens: ${this.state.titreExamen}`}
                    modal
                    className="p-fluid"
                    footer={listeExamenDialogFooter}
                    onHide={this.hideListeEcExamenDialog}
                >
                    {this.state.loadingEcExamen ? (
                        <div className="p-grid">
                            <div className="p-col-6">
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                    ) : (
                        <React.Fragment>
                            {headerExamen}
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.state.listeEcExamen}
                                selectionMode="single"
                                header={`Liste des examens(${this.state.listeEcExamen.length} examen(s ) `}
                                paginator={true}
                                rows={5}
                                globalFilter={this.state.globalFilter}
                                filterMatchMode="contains"
                                responsive={true}
                                selection={this.state.dataTableSelection1}
                                onSelectionChange={(event) =>
                                    this.setState({
                                        dataTableSelection1: event.value,
                                    })
                                }
                            >
                                <Column
                                    field="session.libelleSession"
                                    header="Session"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="rechercher une session"
                                />
                                <Column
                                    field="elementConstitutif.libelleElementConstitutif"
                                    header="Libellé EC"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="Rechercher un Ec"
                                />
                                <Column
                                    field="typeActivite.libelleTypeActivite"
                                    header="Type activité"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="rechercher type activité"
                                />
                                <Column
                                    field="dateExamen"
                                    header="Date de composition"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="rechercher une date"
                                />
                                <Column
                                    field="heureExamen"
                                    header="Heure de composition"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="rechercher une heure"
                                />
                                <Column
                                    field="dureeExamen"
                                    header="Durée de l'examen"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="rechercher une durée"
                                />
                                <Column
                                    field="observationExamen"
                                    header="Observation"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="recherhcher un lieu"
                                />
                                <Column
                                    header={actionHeader}
                                    body={this.actionBodyTemplateListeExamen}
                                    style={{
                                        textAlign: "center",
                                        width: "8em",
                                    }}
                                />
                            </DataTable>
                        </React.Fragment>
                    )}
                </Dialog>

                <Dialog
                    visible={this.state.viewAvertissement}
                    style={{ width: "450px" }}
                    header="Confirmez"
                    modal
                    footer={confirmValidation}
                    onHide={this.hideConfirmationDialog}
                >
                    <div className="confirmation-content">
                        <center>
                            <i
                                className="pi pi-exclamation-triangle p-mr-3"
                                style={{ fontSize: "2rem" }}
                            />
                        </center>
                        <center>
                            La validation envera des mails aux étudiants pour
                            les informer de la présence de nouvelles notes ou de
                            mise à jour de notes
                        </center>
                    </div>
                </Dialog>
            </div>
        );
    }
}

export default ValidationNotes;
