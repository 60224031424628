import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class SemestreService {

    constructor(){
        this.adresse = new AdresseIP()
    }


   getAll() {
       
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/semestres"
       return axios.get(url).then(res => res.data) 
   }
   

   create(newSemestre) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/semestres"
       return axios.post(url,newSemestre)
                   .then(res => res.data);
   }
   
   update(idSemestre ,newSemestre) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/semestres/"
       return axios.put(url+idSemestre, newSemestre)
                   .then(res => res.data);
   }

   delete(idSemestre) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/semestres/"
       return axios.patch(url+idSemestre)
                   .then(res => res.data)
   }
   get(idParcours) {
    const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/semestres?idParcours="+idParcours
    return axios.get(url)
                .then(res => res.data)
    }
    getSemestreNiveau(idNiveau) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/semestres-vh/?idNiveau="+idNiveau
    return axios.get(url)
                .then(res => res.data)
    }
    

}