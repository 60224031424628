import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Notify } from "../../components/Notify";
import { ToastContainer } from "react-toastify";
import { Spinner } from "../../components/Spinner";
import { CohorteServices } from "../../volumeHoraire/service/CohorteServices";
import { ReleveNoteService } from "../../service/ReleveNoteService";
import { SessionExamenService } from "../service/SessionExamenService";

export class ReleveNoteEtudiant extends Component {
    emptyReleveNote = {
        reportFormat: "PDF",
        reportType: "RELEVE_NOTE",
        numeroExercice: null,
        ine: "",
        idSession: null,
        page: null,
    };

    constructor(props) {
        super(props);

        this.notify = new Notify();
        this.spinner = new Spinner();

        this.cohorteServices = new CohorteServices();
        this.sessionService = new SessionExamenService();
        this.releveNoteService = new ReleveNoteService();

        /*const campusScolarite = localStorage.getItem('campusScolarite')
        const campusDepartement = localStorage.getItem('campusDepartement')
        this.isScolarite = parseInt(JSON.parse(campusScolarite))
        this.isDepartement = parseInt(JSON.parse(campusDepartement))*/

        this.state = {
            listeSession: [],
            listeDepartement: [],
            releveNote: this.emptyReleveNote,
            session: null,
            etudiant: null,
            departement: null,
            token: null,
            titre: "",
            submitted: false,
            redirect: false,
            loadingEtudiant: false,
            bool: false,
            listeEtudiants: [],
            loading: false,
            spin: false,
            spinOnly: false,
            spinDialog: false,
            globalFilter: null,
            globalFilterNotation: null,
            globalFilterSession: null,
            listeEtudiantDialog: false,
        };
    }
    componentDidMount = () => {
        const tokenDecoded = localStorage.getItem("tokenDecoded");
        const token = JSON.parse(tokenDecoded);

        const campusDGESup = localStorage.getItem("campusDGESup");
        const campusVpEip = localStorage.getItem("campusVpEip");
        const campusEnseignant = localStorage.getItem("campusEnseignant");
        const campusEtudiant = localStorage.getItem("campusEtudiant");
        const campusDirecteur = localStorage.getItem("campusDirecteur");
        const campusDa = localStorage.getItem("campusDa");
        const campusScolarite = localStorage.getItem("campusScolarite");
        const campusDepartement = localStorage.getItem("campusDepartement");
        const isDGESup = parseInt(JSON.parse(campusDGESup));
        const isVp = parseInt(JSON.parse(campusVpEip));
        const isEnseignant = parseInt(JSON.parse(campusEnseignant));
        const isEtudiant = parseInt(JSON.parse(campusEtudiant));
        const isDepartement = parseInt(JSON.parse(campusDepartement));
        const isDirecteur = parseInt(JSON.parse(campusDirecteur));
        const isDA = parseInt(JSON.parse(campusDa));
        const isScolarite = parseInt(JSON.parse(campusScolarite));

        this.setState({
            loading: true,
            token: token,

            isDepartement: isDepartement,
            isDA: isDA,
            isDirecteur: isDirecteur,
            isVp: isVp,
        });
        if (isDepartement) {
            this.setState({ verifeDept: true });
            this.sessionService
                .getSessionDepartement(token.code_departement)
                .then((data) => {
                    this.setState({
                        listeSession: data.data,
                        loading: false,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.notify.echec(error.response.data.message);
                    this.setState({ loading: false });
                });
        } else if (isDA || isDirecteur || isScolarite) {
            this.setState({ verifeEtab: true });
            this.cohorteServices
                .getListeDepartementEtab(token.code_etablissement)
                .then((data) => {
                    const depts = data.departementEtabDtos;
                    this.setState({ listeDepartement: depts, loading: false });
                })
                .catch((error) => {
                    this.notify.echec(error.response.data.message);
                    console.log(error);
                    this.setState({
                        selectSessionDialog: false,
                        loading: false,
                    });
                });
        }
    };
    listeEtudiant = (session) => {
        //console.log(session)
        this.setState({ session: session });
        let titre = this.titre(session);
        //console.log(titre)
        this.setState({
            listeEtudiantDialog: true,
            loadingEtudiant: true,
            titre: titre,
        });
        this.releveNoteService
            .getListeEtudiant(session.idSession)
            .then((data) => {
                //console.log(data.elements)
                this.setState({
                    listeEtudiants: data.elements,
                    loadingEtudiant: false,
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loadingEtudiant: false });
            });
    };
    hideListeEtudiantDialog = () => {
        this.setState({
            listeEtudiantDialog: false,
        });
    };
    titre = (session) => {
        //console.log(session)
        let titre = session
            ? `Liste des étudiants ${session.parcoursFiliere.libelleParcours} - ${session.libelleSession} `
            : "Aucun étudiant !";
        return titre;
    };
    releveNoteGlobale = () => {
        this.setState({ spin: true, spinDialog: true });
        let listeIne = this.state.listeEtudiants.map((etudiant) => {
            return etudiant.ine;
        });

        const releve = {
            reportFormat: "PDF",
            reportType: this.state.session.parcoursFiliere.parcours.specialite
                .fgDeliberationCoefficient
                ? "RELEVE_NOTE_COEF"
                : "RELEVE_NOTE",
            idSession: parseInt(this.state.session.idSession),
            ines: listeIne,
            idDepartement: this.state.token.code_departement,
        };
        console.log(releve);
        this.releveNoteService
            .genererReleveNoteEtudiant(releve)
            .then((res) => {
                var blob = new Blob([res.data], { type: "application/pdf" });
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                var fileName = `releveNote_${this.state.session.idSession}`;
                link.download = fileName;
                link.click();
                this.setState({ spin: false, spinDialog: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ spin: false, spinDialog: false });
            });
    };
    releveNoteEtudiant = (etudiant) => {
        //console.log(this.state.session.parcoursFiliere.parcours.specialite.fgDeliberationCoefficient)
        this.setState({ etudiant: etudiant, spinDialog: true, spinOnly: true });
        const releve = {
            reportFormat: "PDF",
            reportType: this.state.session.parcoursFiliere.parcours.specialite
                .fgDeliberationCoefficient
                ? "RELEVE_NOTE_COEF"
                : "RELEVE_NOTE",
            idSession: parseInt(this.state.session.idSession),
            ine: etudiant.ine,
            idDepartement: this.state.token.code_departement,
        };
        //console.log("👌")
        console.log(releve);
        this.releveNoteService
            .genererReleveNoteEtudiant(releve)
            .then((res) => {
                console.log(res)
                var blob = new Blob([res.data], { type: "application/pdf" });
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                var fileName = `releveNote_${this.state.etudiant.ine}`;
                link.download = fileName;
                link.click();
                this.setState({ spinOnly: false, spinDialog: false });
            })
            .catch((error) => {
                console.log(error);
                this.notify.echec(error.response.data.message);
                this.setState({ spinOnly: false, spinDialog: false });
            });
    };

    actionBodyTemplateSession = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-list"
                    className="p-button-warning"
                    style={{ marginRight: ".5em" }}
                    title="Liste des étudiants"
                    onClick={() => this.listeEtudiant(rowData)}
                />
            </React.Fragment>
        );
    };
    actionBodyTemplateReleveNote = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-list"
                    className="p-button-success"
                    style={{ marginRight: ".5em" }}
                    title="Relevé de note"
                    onClick={() => this.releveNoteEtudiant(rowData)}
                />
            </React.Fragment>
        );
    };
    onDepartementChange = (e) => {
        const val = (e.target && e.target.value) || "";
        this.setState({ departement: val });
        this.setState({ loading: true });
        this.sessionService
            .getSessionDepartement(val.idDepartementEtab)
            .then((data) => {
                //On filtre uniquement les sessions qui sont ouvert
                let listeSessionOuvert = data.data.filter((curentSession) => {
                    return curentSession.etatSession === "OUVERT";
                });
                this.setState({
                    listeSession: listeSessionOuvert,
                    loading: false,
                });
            })
            .catch((error) => {
                console.log(error);
                this.notify.echec(error.response.data.message);
                this.setState({ loading: false });
            });
    };
    popPupSpinner = () => {
        this.setState({ spinDialog: true });
    };
    hideTelechargementReleveDialog = () => {
        this.setState({ spinDialog: false });
    };
    render() {
        let actionHeader = <Button type="button" icon="pi pi-cog" />;
        const headerSession = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText
                        type="search"
                        onInput={(e) =>
                            this.setState({
                                globalFilterSession: e.target.value,
                            })
                        }
                        placeholder="Recherche globale..."
                    />
                </span>
            </div>
        );
        const listeEtudiantDialogFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideListeEtudiantDialog}
                />
            </React.Fragment>
        );
        const telechargementReleveFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideTelechargementReleveDialog}
                />
            </React.Fragment>
        );
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        {
                            <ToastContainer
                                position="top-right"
                                autoClose={2000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                        }

                        {headerSession}
                        {this.state.loading ? (
                            <div className="p-grid">
                                <div className="p-col-6">
                                    {this.spinner.loadingSpinner(
                                        "spin",
                                        "black"
                                    )}
                                </div>
                            </div>
                        ) : this.state.verifeDept ? (
                            <>
                                <DataTable
                                    ref={(el) => (this.dt = el)}
                                    value={this.state.listeSession}
                                    selectionMode="single"
                                    header="Liste des sessions"
                                    paginator={true}
                                    rows={10}
                                    globalFilter={
                                        this.state.globalFilterSession
                                    }
                                    filterMatchMode="contains"
                                    responsive={true}
                                    selection={this.state.dataTableSelection1}
                                    onSelectionChange={(event) =>
                                        this.setState({
                                            dataTableSelection1: event.value,
                                        })
                                    }
                                >
                                    <Column
                                        field="parcoursFiliere.libelleParcours"
                                        header="Parcours"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Rechercher un parcours"
                                    />
                                    <Column
                                        field="anneeSemestre.libelleAnneeAcademique"
                                        header="Année accadémique"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="rechercher une année"
                                    />
                                    <Column
                                        field="anneeSemestre.libelleSemestre"
                                        header="Semestre"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="rechercher un semestre"
                                    />
                                    <Column
                                        field="typeSession.libelleTypeSession"
                                        header="Session"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="recherhcher une session"
                                    />
                                    <Column
                                        field="etatSession"
                                        header="Etat de la session"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="recherhcher un état"
                                    />
                                    <Column
                                        header={actionHeader}
                                        body={this.actionBodyTemplateSession}
                                        style={{
                                            textAlign: "center",
                                            width: "8em",
                                        }}
                                    />
                                </DataTable>
                            </>
                        ) : this.state.verifeEtab ? (
                            <div className="p-lg-12" style={{ marginTop: 10 }}>
                                <div className="form-grid">
                                    <div className="p-col-3">
                                        <Dropdown
                                            optionLabel="lbDepartementEtab"
                                            value={this.state.departement}
                                            options={
                                                this.state.listeDepartement
                                            }
                                            onChange={(e) => {
                                                this.onDepartementChange(e);
                                            }}
                                            required
                                            autoFocus
                                            placeholder="Selectionner un departement"
                                        />
                                    </div>
                                </div>
                                <DataTable
                                    ref={(el) => (this.dt = el)}
                                    value={this.state.listeSession}
                                    selectionMode="single"
                                    header="Liste des sessions"
                                    paginator={true}
                                    rows={10}
                                    globalFilter={
                                        this.state.globalFilterSession
                                    }
                                    filterMatchMode="contains"
                                    responsive={true}
                                    selection={this.state.dataTableSelection1}
                                    onSelectionChange={(event) =>
                                        this.setState({
                                            dataTableSelection1: event.value,
                                        })
                                    }
                                >
                                    <Column
                                        field="parcoursFiliere.libelleParcours"
                                        header="Parcours"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Rechercher un parcours"
                                    />
                                    <Column
                                        field="anneeSemestre.libelleAnneeAcademique"
                                        header="Année accadémique"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="rechercher une année"
                                    />
                                    <Column
                                        field="anneeSemestre.libelleSemestre"
                                        header="Semestre"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="rechercher un semestre"
                                    />
                                    <Column
                                        field="typeSession.libelleTypeSession"
                                        header="Session"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="recherhcher une session"
                                    />
                                    <Column
                                        field="etatSession"
                                        header="Etat de la session"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="recherhcher un état"
                                    />
                                    <Column
                                        header={actionHeader}
                                        body={this.actionBodyTemplateSession}
                                        style={{
                                            textAlign: "center",
                                            width: "8em",
                                        }}
                                    />
                                </DataTable>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                <Dialog
                    visible={this.state.listeEtudiantDialog}
                    style={{ width: "90%" }}
                    header={this.state.titre}
                    modal
                    className="p-fluid"
                    footer={listeEtudiantDialogFooter}
                    onHide={this.hideListeEtudiantDialog}
                >
                    {this.state.loadingEtudiant ? (
                        <div className="p-grid">
                            <div className="p-col-6">
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                    ) : (
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="p-col-3">
                                    <Button
                                        icon={
                                            this.state.spin
                                                ? "pi pi-spin pi-spinner"
                                                : "pi pi-list"
                                        }
                                        type="button"
                                        label="Generer Rélévé de note global"
                                        className="p-button-success"
                                        style={{ marginRight: ".5em" }}
                                        title="Relevé de note global"
                                        onClick={() => this.releveNoteGlobale()}
                                    />
                                </div>
                                <DataTable
                                    ref={(el) => (this.dt = el)}
                                    value={this.state.listeEtudiants}
                                    selectionMode="single"
                                    header={this.state.titre}
                                    paginator={true}
                                    rows={10000}
                                    globalFilter={
                                        this.state.globalFilterEtudiant
                                    }
                                    filterMatchMode="contains"
                                    responsive={true}
                                    selection={this.state.dataTableSelection1}
                                    onSelectionChange={(event) =>
                                        this.setState({
                                            dataTableSelection1: event.value,
                                        })
                                    }
                                >
                                    <Column
                                        field="ine"
                                        header="INE"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="INE"
                                    />
                                    <Column
                                        field="nom"
                                        header="Nom"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Nom"
                                    />
                                    <Column
                                        field="prenom"
                                        header="Prénom(s)"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Prénom(s)"
                                    />
                                    <Column
                                        field="sexe"
                                        header="Sexe"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Sexe"
                                    />
                                    <Column
                                        field="dateNaissance"
                                        header="Date naiss."
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Date naiss."
                                    />
                                    <Column
                                        field="lieuNaissance"
                                        header="Lieu naiss."
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Lieu naiss."
                                    />
                                    <Column
                                        field="totalCredits"
                                        header="Total crédits"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Crédit"
                                    />
                                    <Column
                                        field="moyenneGenerale"
                                        header="Moyenne"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Moyenne"
                                    />
                                    <Column
                                        header={actionHeader}
                                        body={this.actionBodyTemplateReleveNote}
                                        style={{
                                            textAlign: "center",
                                            width: "8em",
                                        }}
                                    />
                                </DataTable>
                            </div>
                            <div className="p-col-8"></div>
                        </div>
                    )}
                </Dialog>
                <Dialog
                    visible={this.state.spinDialog}
                    style={{ width: "30%" }}
                    header="Telechargement de releve de note"
                    modal
                    footer={telechargementReleveFooter}
                    onHide={this.hideTelechargementReleveDialog}
                >
                    {this.state.spinOnly
                        ? this.spinner.loadingSpinner("spin", "black")
                        : ""}
                    {this.state.spin
                        ? this.spinner.loadingSpinner("spin", "black")
                        : ""}
                </Dialog>
            </div>
        );
    }
}

export default ReleveNoteEtudiant;
