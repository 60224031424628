import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import classNames from 'classnames'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { Notify } from '../../components/Notify';
import { ToastContainer} from 'react-toastify';
import { Spinner } from '../../components/Spinner';
import { InscriptionService } from '../service/InscriptionService'

export class InscriptionAdministrative extends Component {

    constructor(props){
        super(props)

        this.notify = new Notify()
        this.spinner = new Spinner()
        this.ModelInscriptionService = new InscriptionService()
        this.idFranchises = React.createRef()
        this.idAccordPaiement = React.createRef()

        //On suppose que l'idEtudiant est 3368 (A recuperer à la long dans le token)
        let idEtudiant = 3367
        this.state = {
            etudiant: {},
            listeOption: [],
            globalFilter: null,
            payDialog: false,
            loading: false,
            inscriptionDialog: false,
            submitted: false,
            operateur: "",
            franchises: false,
            sessionChoix: {},
            idEtudiant: idEtudiant,
            suivantMoov: false,
            suivantCoris: false,
            conditionAccepte: false,
            objetPaiement: {
                numero: "",
                codeSecret: ""
            },
            montantInscription: "",
            messageErreur: ""

        }
    }


    componentDidMount(){

        const tokenDecoded = localStorage.getItem('tokenDecoded')
        const token = JSON.parse(tokenDecoded)

        this.setState({loading: true})
        this.ModelInscriptionService.testerExistanceSessionInscriptionAdministrative(this.state.idEtudiant).then(data => {

            //console.log(data)
            this.setState({
                listeOption: data,
                loading: false
            })
        }).catch(error => {
            console.log(error)
            this.setState({
                loading: false
            })
        })
        
    }


    onInputChange = (event, fieldName) => {

        let objetPaiement = this.state.objetPaiement
        objetPaiement[`${fieldName}`] = event.target.value
        
        this.setState({
            objetPaiement: objetPaiement
        })
    }


    optionInscription = (sessionInscription) => {

            //console.log(sessionInscription)
            let idEtudiant = null
            
            this.ModelInscriptionService.getInformationEtudiant(this.state.idEtudiant).then(data => {
                //console.log(data[0])
                idEtudiant = data[0].idEtudiant
                this.setState({
                    etudiant: data[0]
                })
            }).catch(error => {
                console.log(error)
            })

            this.ModelInscriptionService.getMontantInscription(sessionInscription.idParcours, sessionInscription.idNiveau, this.state.idEtudiant).then(montant => {


            this.setState({loading: true})
            this.ModelInscriptionService.testerExistanceSessionInscriptionAdministrative(this.state.idEtudiant).then(data => {

                //console.log(data)
                this.setState({
                    listeOption: data,
                    loading: false
                })
            }).catch(error => {
                console.log(error)
                this.setState({
                    loading: false
                })
            })


            this.setState({
                inscriptionDialog: true,
                sessionChoix: sessionInscription,
                montantInscription: montant
            })

        }).catch(error => {
            console.log(error)
            this.notify.echec("Problème lié à l'information du montant paiement !!!")
        })
        
    }


    hideDialog = () => {

        let quitter = true

        if(this.state.suivantCoris || this.state.suivantMoov){
            quitter = window.confirm("Le processus de paiement est en cours, si vous quittez, vous risquez de recommencer le processus. Voulez vous vraiment quitter? !")
        }

        if(quitter){

            this.setState({
                inscriptionDialog: false,
                operateur: "",
                franchises: false,
                sessionChoix: {},
                suivantMoov: false,
                suivantCoris: false,
                conditionAccepte: false,
                objetPaiement: {
                    numero: "",
                    codeSecret: ""
                },
                messageErreur: ""
            })
        }
        
    }

    inscriptionAdministratif = (operateur) => {

        //console.log(this.state.sessionChoix)
        let inscriptionObject = {
            idEtudiant: this.state.etudiant.idEtudiant,
            idSessionInscription: this.state.sessionChoix.idSessionInscription,
            operateur: operateur
        }

        //console.log(inscriptionObject)
        this.ModelInscriptionService.InscriptionAdministrative(inscriptionObject).then(data => {
            this.notify.success()

            this.setState({
                loading: true
            })

             /*
            this.ModelInscriptionService.testerExistanceSessionInscription(this.state.idEtudiant).then(data => {
                this.setState({
                    listeOption: data,
                    loading: false
                })
            }).catch(error => {
                console.log(error)
                this.setState({
                    loading: false
                })
            })
            */

            this.setState({
                inscriptionDialog: false,
                operateur: "",
                franchises: false,
                sessionChoix: {}
            })

        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })
    }

    inscriptionParOrange = () =>{

        //console.log(this.state.sessionChoix)
        let inscriptionObject = {
            idEtudiant: this.state.etudiant.idEtudiant,
            idSessionInscription: this.state.sessionChoix.idSessionInscription,
            operateur: "OM",
            numero: this.state.objetPaiement.numero,
            code: this.state.objetPaiement.codeSecret
        }
        
        this.ModelInscriptionService.paiementInscriptionAdministrative(inscriptionObject).then(data => {

            if(parseInt(data.status)===200){
                this.notify.success()
                this.setState({
                    inscriptionDialog: false,
                    operateur: "",
                    franchises: false,
                    sessionChoix: {},
                    listeOption: []
                })

            }else{
                this.setState({
                    messageErreur: data.message
                })
            }


        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })
    }

    inscriptionEtape1Moov = () => {

        console.log("Moov etape 1")

        //console.log(this.state.sessionChoix)
        let inscriptionObject = {
            idEtudiant: this.state.etudiant.idEtudiant,
            idSessionInscription: this.state.sessionChoix.idSessionInscription,
            operateur: "moov",
            numero: this.state.objetPaiement.numero,
            code: this.state.objetPaiement.codeSecret
        }
        
        this.ModelInscriptionService.paiementInscriptionAdministrative(inscriptionObject).then(data => {
             /*
            let objetPaiement = this.state.objetPaiement
            objetPaiement['codeSecret'] = ""
            this.setState({
                suivantMoov: true,
                franchises: false,
                objetPaiement: objetPaiement
            })

            if(parseInt(data.status)===200){
                this.notify.success()
                this.setState({
                    inscriptionDialog: false,
                    operateur: "",
                    franchises: false,
                    sessionChoix: {},
                    listeOption: []
                })

            }else{
                this.setState({
                    messageErreur: data.message
                })
            }
            */
            
        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })
    
        
    }

    inscriptionEtape2Moov = () => {
        console.log("Moov etape 2")
        //Traitement du paiement
    }

    inscriptionEtape1Coris = () => {
        console.log("Coris etape 1")

        let objetPaiement = this.state.objetPaiement
        objetPaiement['codeSecret'] = ""

        //Supposition que c'est bon pour gestion interface
        this.setState({
            suivantCoris: true,
            franchises: true,
            objetPaiement: objetPaiement
        })
    }

    inscriptionEtape2Coris = () => {
        console.log("Coris etape 2")
        //Traitement du paiement
    }

    onOperateurSelect = (operateur) => {

        let franchises = (operateur==="orange" || operateur==="moov")?true:false
        this.setState({
            operateur: operateur,
            franchises: franchises,
            objetPaiement: {
                numero: "",
                codeSecret: ""
            }
        })
    }

    confirmerConditionPaiement = () => {
        this.setState({
            conditionAccepte: (this.idAccordPaiement.current.checked && this.idFranchises.current.checked)
        })
    }

    actionBodyTemplateSession = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-check" className="p-button-success" style={{marginRight: '.5em'}} title="S'inscrire"  onClick = { () => this.optionInscription(rowData)}/>
            </React.Fragment>
        )
    }


    render() {

        let actionHeader = <Button className="enteteDatatable" type="button" icon="pi pi-cog"/>;
        
        const inscriptionFooter = (

            <React.Fragment>

                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />

                {(this.state.operateur==="orange" && this.state.conditionAccepte) &&
                    (
                        <Button label="Valider" icon="pi pi-check" className="p-button-text" onClick={this.inscriptionParOrange} />
                    )
                } 

                {(this.state.operateur==="orange" && !this.state.conditionAccepte) &&      
                    (
                        <Button label="Valider" icon="pi pi-check" className="p-button-text" onClick={this.inscriptionParOrange} disabled />
                    )
                }

                {(this.state.operateur==="moov" && !this.state.suivantMoov && this.state.conditionAccepte) &&
                    <Button label="Suivant" icon="pi pi-check" className="p-button-text" onClick={this.inscriptionEtape1Moov} />
                }

                {(this.state.operateur==="moov" && !this.state.suivantMoov && !this.state.conditionAccepte) &&
                    <Button label="Suivant" icon="pi pi-check" className="p-button-text" onClick={this.inscriptionEtape1Moov} disabled />
                }

                {(this.state.suivantMoov) &&
                    (
                        <Button label="Valider" icon="pi pi-check" className="p-button-text" onClick={this.inscriptionEtape2Moov} />
                    )
                }


                {this.state.operateur==="coris" && !this.state.suivantCoris && 
                    <Button label="Suivant" icon="pi pi-check" className="p-button-text" onClick={this.inscriptionEtape1Coris} />
                }

                {(this.state.suivantCoris && this.state.conditionAccepte) &&
                    (
                        <Button label="Valider" icon="pi pi-check" className="p-button-text" onClick={this.inscriptionEtape2Coris} />
                    )
                }

                {(this.state.suivantCoris && !this.state.conditionAccepte) &&
                    (
                        <Button label="Valider" icon="pi pi-check" className="p-button-text" onClick={this.inscriptionEtape2Coris} disabled/>
                    )
                }

            </React.Fragment>

        );

        return (

            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                    {<ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />}

                        {this.state.loading ? 
                            <div className="p-grid">
                                <div className="p-col-6" ></div>
                                <div className="p-col-6" >
                                    {this.spinner.loadingSpinner("spin", "black")}
                                </div>
                            </div>
                        :
                            (this.state.listeOption.length>0) ?
                                <DataTable ref={(el) => this.dt = el} value={this.state.listeOption} selectionMode="single" header="Liste des sessions d'inscription concordant avec votre profile" paginator={true} rows={10} globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.listeOption} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                                    <Column field="libelleParcours" header="Parcours" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="Rechercher un parcours"/>
                                    <Column field="libelleAnneeAcademique" header="Année académique" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="rechercher une année"/>
                                    <Column field="libelleNiveau" header="Niveau" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="rechercher un niveau"/>
                                    <Column field="dateOuverture" header="Date ouverture"  sortable={true} filter={true} filterPlaceholder="date ouverture"/>
                                    <Column field="dateFermeture" header="Date fermeture"  sortable={true} filter={true} filterPlaceholder="date fermeture"/>
                                    <Column field="etatSession" header="Etat de la session" filterMatchMode="contains"  sortable={true} filter={true} filterPlaceholder="recherhcher un état"/>
                                    <Column header={actionHeader} body={this.actionBodyTemplateSession} style={{textAlign:'center', width: '8em'}}/>
                                </DataTable>
                            :

                            <div className="alert alert-success d-flex align-items-center" role="alert">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                </svg>
                                <div>
                                    <h3>Aucune session d'inscription disponible pour votre profile</h3>
                                    {/*<button onClick={ () => {this.optionInscription()}}>Tester</button>*/}
                                </div>
                            </div>
                        }
                    </div>
                </div>

                {/* Construction interface */}
                <Dialog visible={this.state.inscriptionDialog} style={{ width: '98%' }} header="Détails inscription administrative" modal className="p-fluid" footer={inscriptionFooter} onHide={this.hideDialog}>
                    <hr/>
                    <div className="row">

                        <div className='p-col-12 p-md-12 p-lg-6'>
                            <div className='p-col-12 p-md-12 p-lg-12'>
                                <h6>Identité étudiant</h6>
                                <hr/>
                            </div>

                            <div className='p-col-12 p-md-12 p-lg-12'>

                                <div className="p-grid">
                                    <div className='p-col-6 p-md-6 p-lg-6'>
                                        <div className="p-field">
                                            <label htmlFor="nom">Nom</label>
                                            <InputText id="nom" value={this.state.etudiant.nom} readOnly/>
                                        </div>
                                    </div>
                                    <div className='p-col-6 p-md-6 p-lg-6'>
                                        <div className="p-field">
                                            <label htmlFor="prenoms">Prénom(s)</label>
                                            <InputText id="prenoms" value={this.state.etudiant.prenom} readOnly/>
                                        </div>
                                    </div>
                                </div>
                            

                                <div className="p-grid">
                                    <div className='p-col-6 p-md-6 p-lg-6'>
                                        <div className="p-field">
                                            <label htmlFor="ine">INE</label>
                                            <InputText id="ine" value={this.state.etudiant.ine} readOnly/>
                                        </div>
                                    </div>
                                    <div className='p-col-6 p-md-6 p-lg-6'>
                                        <div className="p-field">
                                            <label htmlFor="dateNaissance">Date de naissance</label>
                                            <InputText id="dateNaissance" value={this.state.etudiant.dateNaissance} readOnly/>
                                        </div>
                                    </div>
                                </div>


                                <div className="p-grid">
                                    <div className='p-col-6 p-md-6 p-lg-6'>
                                        <div className="p-field">
                                            <label htmlFor="nationalite">Nationalite</label>
                                            <InputText id="nationalite" value={this.state.etudiant.nomPays} readOnly/>
                                        </div>
                                    </div>
                                    <div className='p-col-6 p-md-6 p-lg-6'>
                                        <div className="p-field">
                                            <label htmlFor="telephone">Téléphone</label>
                                            <InputText id="telephone" value={this.state.etudiant.telephone} readOnly/>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                        <div className='p-col-12 p-md-12 p-lg-6'>
                            <div className='p-col-12 p-md-12 p-lg-12'>
                                <h6>Informations cursus</h6>
                                <hr/>
                            </div>

                            <div className='p-col-12 p-md-12 p-lg-12'>
                                <div className="p-grid">
                                    <div className='p-col-6 p-md-6 p-lg-6'>
                                        <div className="p-field">
                                            <label htmlFor="universite">Université</label>
                                            <InputText id="universite" value={this.state.sessionChoix.libelleIesr} readOnly/>
                                        </div>
                                    </div>
                                    <div className='p-col-6 p-md-6 p-lg-6'>
                                        <div className="p-field">
                                            <label htmlFor="ufr">UFR/Institut</label>
                                            <InputText id="ufr" value={this.state.sessionChoix.libelleDepartement} readOnly/>
                                        </div>
                                    </div>
                                </div>
                            

                                <div className="p-grid">
                                    <div className='p-col-6 p-md-6 p-lg-6'>
                                        <div className="p-field">
                                            <label htmlFor="parcours">Parcours</label>
                                            <InputText id="parcours" value={this.state.sessionChoix.libelleParcours} readOnly/>
                                        </div>
                                    </div>
                                    <div className='p-col-6 p-md-6 p-lg-6'>
                                        <div className="p-field">
                                            <label htmlFor="niveau">Niveau</label>
                                            <InputText id="niveau" value={this.state.sessionChoix.libelleNiveau} readOnly/>
                                        </div>
                                    </div>
                                </div>


                                <div className="p-grid">
                                    <div className='p-col-6 p-md-6 p-lg-6'>
                                        <div className="p-field">
                                            <label htmlFor="anneeAcademique">Année académique</label>
                                            <InputText id="anneeAcademique" value={this.state.sessionChoix.libelleAnneeAcademique} readOnly/>
                                        </div>
                                    </div>
                                    <div className='p-col-6 p-md-6 p-lg-6'>
                                        <div className="p-field">
                                            <label htmlFor="frais">Frais d'inscription</label>
                                            <InputText id="frais" value={this.state.montantInscription} readOnly/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr/>

                        <div className='p-col-12 p-md-12 p-lg-12'>
                            <div className='p-col-12 p-md-12 p-lg-12'>
                                <h6>Règlement des frais d'inscription</h6>
                                <hr/>
                            </div>

                            <div className='p-col-12 p-md-12 p-lg-12'>

                                {(!this.state.suivantCoris && !this.state.suivantMoov) &&
                                    (
                                        <div className="p-grid">
                                            <div className='p-col-12 p-md-12 p-lg-12'>
                                                <div className="p-field">
                                                    <label htmlFor="operateur">Sélection de l'opérateur</label>
                                                    <br/>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="operateur" id="orange" value="orange" onClick = { () => this.onOperateurSelect("orange")}/>
                                                        <label className="form-check-label" for="orange">Orange Money</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="operateur" id="moov" value="moov" onClick = { () => this.onOperateurSelect("moov")}/>
                                                        <label className="form-check-label" for="moov">Moov Money</label>
                                                    </div>

                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="operateur" id="coris" value="coris" onClick = { () => this.onOperateurSelect("coris")}/>
                                                        <label className="form-check-label" for="coris">Coris Money</label>
                                                    </div>
                                                </div>
                                                <hr/>
                                            </div>
                                        </div>
                                    )
                                }



                                {(this.state.operateur==="orange" && !this.state.suivantCoris && !this.state.suivantMoov)?
                                
                                    <div className="p-grid">
                                        {
                                            this.state.messageErreur && <div className='p-col-12 p-md-12 p-lg-12'>
                                                <div class="alert alert-danger">{this.state.messageErreur}</div>
                                            </div>
                                        }
                                        
                                        <div className='p-col-12 p-md-12 p-lg-6'>
                                            <div class="alert alert-warning">
                                                                                
                                                <div id="OM" className="text-center" style={{display: 'block'}}>
                                                    <span className="fa fa-bullhorn"></span> <strong>Instructions</strong> <br/>
                                                    Saisissez la syntaxe suivante à partir de votre téléphone ayant un compte Orange Money <br/>
                                                    <strong style={{color: 'red', fontSize: '17px'}}>*144*4*6*{this.state.montantInscription}#</strong>. <br/>
                                                    Vous allez recevoir un code OTP (6 chiffres) par SMS.
                                                    Saisissez le numéro de télephone ayant servi à la génération du code, ainsi que le code OTP reçu dans les champs
                                                    indiqués ci-dessous puis validez pour effectuer le paiement.
                                                </div>

                                            </div>
                                        </div>

                                        <div className='p-col-12 p-md-12 p-lg-6'>
                                            <div className="p-grid">
                                                <div className='p-col-6 p-md-12 p-lg-6'>
                                                    <div className="p-field">
                                                        <label htmlFor="compteOrange">Numéro de compte (Orange Money)</label>
                                                        <input id="compteOrange" type="number" value={this.state.objetPaiement.numero} placeholder="Saisir le numero de compte Orange Money" onChange={ (e) => this.onInputChange(e, 'numero') } className='p-inputtext p-component' />
                                                    </div>
                                                </div>
                                                <div className='p-col-6 p-md-6 p-lg-6'>
                                                    <div className="p-field">
                                                        <label htmlFor="codeOTP">Code OTP</label>
                                                        <InputText id="codeOTP" value={this.state.objetPaiement.codeSecret} placeholder="Saisir le code OTP" onChange={(e) => {this.onInputChange(e, 'codeSecret')}}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :

                                    (this.state.operateur==="coris" && !this.state.suivantCoris && !this.state.suivantMoov)?
                                    
                                    <div className="p-grid">

                                        <div className='p-col-12 p-md-12 p-lg-6'>
                                            <div class="alert alert-warning">

                                                <div className="text-center" style={{display: 'block'}}>
                                                    <span className="fa fa-bullhorn"></span> 
                                                    <strong>Instructions</strong><br/>
                                                    <strong style={{color: 'red', fontSize: '17px'}}>
                                                        Pour procéder au versement de votre scolarité, 
                                                        renseignez votre numéro de téléphone Coris Money ainsi que 
                                                        votre code secret puis validez. <br/>
                                                        Le montant à payer est : {this.state.montantInscription} FCFA
                                                    </strong>
                                                </div>

                                            </div>
                                        </div>

                                        <div className='p-col-12 p-md-12 p-lg-6'>
                                            <div className="p-grid">
                                                <div className='p-col-12 p-md-12 p-lg-6'>
                                                    <div className="p-field">
                                                        <label htmlFor="compteCoris">Numéro de compte (Coris Money)</label>
                                                        <InputText id="compteCoris" type="number" value={this.state.objetPaiement.numero} placeholder="Saisir le numero de compte Coris Money" className='p-inputtext p-component' onChange={ (e) => this.onInputChange(e, 'numero')}/>
                                                    </div>
                                                </div>
                                                <div className='p-col-6 p-md-6 p-lg-6'>
                                                    <div className="p-field">
                                                        <label htmlFor="codeCoris">Code sécret</label>
                                                        <InputText type="password" id="codeCoris" value={this.state.objetPaiement.codeSecret} placeholder="Saisir le code sécret" onChange={(e) => this.onInputChange(e, 'codeSecret')}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    :

                                    <div className="p-grid">

                                        {(this.state.operateur==="moov"  && !this.state.suivantCoris && !this.state.suivantMoov) 
                                            && 
                                        <React.Fragment>
                                            
                                            <div className='p-col-12 p-md-12 p-lg-6'>
                                                <div class="alert alert-warning">
                                                    <div className="text-center">
                                                        <span className="fa fa-bullhorn"></span> 
                                                        <strong>Instructions</strong><br/>
                                                        Pour payer votre scolarité, renseigner le numero Moov Money avec lequel vous voulez effectuer le paiement. 
                                                        Le montant à payer est :<br/> <strong style={{color: 'red', fontSize: '17px' }}>{this.state.montantInscription} FCFA</strong>. <br/>
                                                        Vous allez recevoir un SMS vous indiquant la procedure à suivre.<br/>
                                                        Une fois le paiement effectué, il faudra valider le paiement
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='p-col-12 p-md-12 p-lg-6'>
                                                <div className="p-grid">
                                                    <div className='p-col-12 p-md-12 p-lg-12'>
                                                        <div className="p-field">
                                                            <label htmlFor="compteMoov">Numéro de compte (Moov Money)</label>
                                                            <InputText id="compteMoov" type="number" value={this.state.objetPaiement.numero} placeholder="Saisir le numero de compte Moov Money" onChange={(e) => this.onInputChange(e, 'numero') }/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </React.Fragment>}
                                    </div>
                                }


                                    
                                        
                                {(this.state.suivantMoov) 
                                    && 
                                    <div className="p-grid">
                                    
                                        <div className='p-col-12 p-md-12 p-lg-6'>
                                            <div class="alert alert-warning">
                                                <div className="text-center">
                                                    <span className="fa fa-bullhorn"></span> 
                                                    <strong>Instructions Moov Money</strong><br/>
                                                    Veuillez suivre l'instruction reçu par SMS sur le numéro <br/><strong style={{color: 'red', fontSize: '17px' }}>{this.state.objetPaiement.numero}</strong><br/> puis cliquez sur le button <strong style={{color: 'red', fontSize: '17px' }}>« Valider le paiement Moov »</strong> pour valider le paiement sur Campusfaso.
                                                </div>
                                            </div>
                                        </div>

                                        <div className='p-col-12 p-md-12 p-lg-6'>
                                            <div className="p-grid">
                                                <div className='p-col-12 p-md-12 p-lg-12'>
                                                    <div className="p-field">
                                                        <Button label="Valider le paiement Moov" icon="pi pi-check" className="p-button-text" onClick={this.inscriptionEtape2Moov} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                }


                                {(this.state.suivantCoris) && 
                                    <div className="p-grid">
                                    
                                        <div className='p-col-12 p-md-12 p-lg-6'>
                                            <div class="alert alert-warning">
                                                <div className="text-center">
                                                    <span className="fa fa-bullhorn"></span> 
                                                    <strong>Instructions Coris Money</strong><br/>
                                                    Pour terminer l’opération de versement de votre scolarité, renseignez le code de confirmation que vous avez reçu par SMS sur votre numero <br/><strong style={{color: 'red', fontSize: '17px' }}>{this.state.objetPaiement.numero}</strong><br/> acceptez les conditions de paiement en cochant les cases à cocher puis validez.
                                                </div>
                                            </div>
                                        </div>

                                        <div className='p-col-12 p-md-12 p-lg-6'>
                                            <div className="p-grid">
                                                <div className='p-col-12 p-md-12 p-lg-12'>
                                                    <div className="p-field">
                                                        <label htmlFor="codeConfirmation">Code secret de la transaction</label>
                                                        <InputText id="codeConfirmation" value={this.state.objetPaiement.codeSecret} placeholder="Saisir le code sécret reçu par SMS" onChange = {(e) => this.onInputChange(e, 'codeSecret')}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                }
                            


                                {this.state.franchises && 
                                    <div className='p-col-12 p-md-12 p-lg-12'>
                                        
                                        <div className="p-field">
                                            <label htmlFor="operateur">Franchises universitaire</label>
                                            <hr/>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="confirmation" id="confirmation" onClick={this.confirmerConditionPaiement} ref={this.idFranchises}/>
                                                <label className="form-check-label" for="confirmation">
                                                    J'accepte et je certifie par ce paiement que mes informations sont correctes et me conformer aux franchises universitaires de mon établissement d'accueil
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="validation" id="validation" onClick={this.confirmerConditionPaiement} ref={this.idAccordPaiement}/>
                                                <label class="form-check-label" for="validation">
                                                    Le paiement des frais d’inscription constitue une preuve de la validation de mon programme d’étude et ne sont pas remboursables
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                }

                            </div>
                        </div>

                    </div>
                </Dialog>

            </div>
        )
    }
}

export default InscriptionAdministrative