import React from 'react'
import {Slide} from 'react-slideshow-image'
import img1 from './banner_1.jpeg'
import img2 from './banner_2.jpeg'
import img3 from './blank.gif'
import 'react-slideshow-image/dist/styles.css'
import '../css/acceuil.css'

const proprietes = {
    indicators : true
}

const SlideShow = () => {
    return(
        <div className="slide-container " >
            <Slide {...proprietes}  >
                <div className="each-slide">
                    <div className="sp-slide" style={{height : 500}}>
                        <img style={{width : 1500}}  className="sp-image" src={img1}/>
                        {/*<div class="sp-overlay"></div>*/}
                        
                    </div>
                </div> 
                {/*<div className="each-slide" style={{height : 350}}>
                    <div className="sp-slide">
                        <img style={{width : 1500}}  className="sp-image" src={img2} dataSrc={img2} />
                    </div>
                </div>*/}
            </Slide>
        </div>
    )

}

export default SlideShow
