import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
//import './index.css';

import { DataTable } from 'primereact/datatable';
import React, { useMemo, useState } from 'react';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Notify } from '../../components/Notify';
import { ToastContainer } from 'react-toastify';
import { Steps } from 'primereact/steps';
import { Calendar } from 'primereact/calendar';
import classNames from 'classnames';
import { RadioButton } from 'primereact/radiobutton';
import { Controller, useForm } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { DepartementService } from '../../lmd/service/DepartementService';
import { Spinner } from '../../components/Spinner';
import { IesrService } from '../../lmd/service/IesrService';
import ResponsabiliteService from '../service/ResponsabiliteService';
import SpecialiteService from '../service/SpecialiteService';
import GradeService from '../service/GradeService';
import DisciplineService from '../service/DisciplineService';
import StatutService from '../service/StatutService';
import EnseignantService from '../service/EnseignantService';
import RangService from '../service/RangService';
import { Checkbox } from 'primereact/checkbox';
import NominationService from '../service/NominationService';
import XLSX from 'xlsx'
import { ExportSheet } from 'react-xlsx-sheet'
import {jsPDF} from 'jspdf'

function ListeEnseignant() {
  
  const notify = new Notify();
  const [listeEnseignant, setListeEnseignant] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const spinner = new Spinner();
  const enseignantService = new EnseignantService();

  let cols = [
    { field: 'matriculeEnseignant', header: 'Matricule' },
    { field: 'nomEnseignant', header: 'Nom' },
    { field: 'prenomEnseignant', header: 'Prénom' },
    { field: 'sexe', header: 'Sexe' },
    { field: 'telEnseignant', header: 'Téléphone' },
    { field: 'libelleGrade', header: 'Grade' },
    //{ field: 'lbDepartementEtab', header: 'Département' },
    { field: 'sigleIes', header: 'IES' }, 
     
];
let exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field }));

  useEffect(() => {
    setLoading(true);
    enseignantService.getAll().then((data) => { 
        setListeEnseignant(data)
        let enseignantList = []
        //console.log(data)
        enseignantList = data.map(ens => {
            return {
                "matriculeEnseignant" : ens.matriculeEnseignant,
                "nomEnseignant" : ens.nomEnseignant,
                "prenomEnseignant" : ens.prenomEnseignant,
                "sexe" : ens.genre,
                "libelleGrade" : ens.grade.libelleGrade,
                //"libelleDiscipline" : ens.specialite.libelleDiscipline,
                //"libelleSpecialite" : ens.specialite.libelleSpecialite,
                //"emailEnseignant" : ens.emailEnseignant,
                "telEnseignant" : ens.telEnseignant,
                "sigleIes" : ens.sigleIes,
               // "lbDepartementEtab" : ens.lbDepartementEtab
            }
        })
        //console.log(enseignantList)
        setExportList(enseignantList)
    })
    .catch((errors) => { 
        console.log(errors); notify.echec(errors.response.data.message) 
    }).finally(() => setLoading(false));
  }, [])

  const header = (
    <div className="table-header p-col-12">
        <span className="p-input-icon-left">
            <InputText style={{ marginLeft: 20, width: "300px" }} type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
        </span>
    </div>
);
const columnBodyTemplate = (rowData) => {
    return (
        <div>
            <span>{rowData.nomEnseignant} </span>
            <span>{rowData.prenomEnseignant} </span>
        </div>
    )
}
const responsabiliteTemplate = (rowData) => {
    let responsabilite = rowData.enseignantResponsabilites.find(data => data.statutEnseignantResponsabilite && data.responsabilitePriseEnCompte)
    return (
        <div>
            {responsabilite ? <span>{responsabilite.libelleResponsabilite}</span> : <span>Néant</span>}
        </div>
    )
}
const actionBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            <Button type="button" title='editer' icon="pi pi-pencil" className="p-button-warning" style={{ marginRight: '.5em' }}  />
            <Button icon="pi pi-trash" title='supprimer' className="p-button-danger" />
        </React.Fragment>
    );
}
const actionHeader = <Button type="button" icon="pi pi-cog" />;

const exportExcel =()=> {
    import('xlsx').then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(listeEnseignant);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAsExcelFile(excelBuffer, "Liste des enseignants");
    });
}
const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then(module => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
}
const exportPdf = () => {
    import('jspdf').then(jsPDF => {
        import('jspdf-autotable').then(() => {
            const doc = new jsPDF.default("l");
            const image = new Image();
            //let logo = this.state.departement.sigleIes
            //image.src = '/assets/layout/images/logos/logo_'+logo.toLowerCase()+'.png';
            doc.setFontSize(12);
            doc.text("Ministère de l'Enseignement Supérieur,", 10, 10)
            doc.text("de la Recherche et de l'Innovation", 10, 15)
            doc.text("----------------------------------", 10, 20)
            doc.text("Secrétariat Général", 10, 25)
            doc.text("----------------------------------", 10, 30)
            //doc.text(this.state.departement.nomIes, 10, 35)
            //doc.text("----------------------------------", 10, 40)
            //doc.text(this.state.departement.nomEtablissement, 10, 45)
            //doc.text("----------------------------------", 10, 50)
            //doc.text(this.state.departement.lbDepartementEtab, 10, 55)
            //doc.addImage(image, 'PNG', 130, 10, 50, 56);
            
            doc.text("BURKINA - FASO", 230, 10)
            doc.setFontSize(10);
            doc.text("Unité - Progrès - Justice", 232, 16)
            doc.setFontSize(14);
            doc.text("Liste des enseignants", 10, 80)
            const nbr = listeEnseignant.length
            doc.autoTable(exportColumns, exportList, {
                
                startY: doc.autoTable({margin : {top : 82}}),
                /*margin: { horizontal: 10 },
                styles: { overflow: "linebreak" },
                bodyStyles: { valign: "top" },
                columnStyles: { email: { columnWidth: "wrap" } },
                theme: "striped",*/
                //showHead: "everyPage",

                didDrawPage: function (data) {

                    // Header
                    doc.setFontSize(10);
                    doc.setTextColor(40);
                
                    // Footer
                    const str = "Page " + doc.internal.getNumberOfPages();
                    doc.setFontSize(10);
                    
                    const pageCount = doc.getNumberOfPages()
                
                    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                    const pageSize = doc.internal.pageSize;
                    const pageHeight = pageSize.height
                      ? pageSize.height
                      : pageSize.getHeight();
                    
                    /*for(let i = 1; i<=pageCount; i++){
                        doc.setPage(i)
                        doc.text('' + String(i) + '/' + String(pageCount), 290,200,null,null,"right")
                    }*/
                    //doc.text(str, data.settings.margin.left, pageHeight - 10);

                    //doc.text("Arrêté la présente liste à "+nbr+" noms", pageHeight, pageHeight - 10);
                  }
            });
            paginationPage(doc)
            doc.save("Liste enseignants"+'.pdf');
            
        })
    })
}
const paginationPage = (doc) => {
    const pageCount = doc.getNumberOfPages()
    for(let i = 1; i<=pageCount; i++){
        doc.setPage(i)
        doc.text('' + String(i) + '/' + String(pageCount), 290,200,null,null,"right")
    }

}

  return (
    <div className="card">
                {header}
                {
                    loading ?
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :
                        <>
                            <div className="p-d-flex p-ai-center export-buttons" style={{marginLeft : 10}}>
                                <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success p-mr-2" data-pr-tooltip="XLS" />
                                <Button type="button" icon="pi pi-file-pdf" onClick={exportPdf} style={{marginLeft : 10}} className="p-button-warning p-mr-2" data-pr-tooltip="PDF"/>
                            </div>
                            <div style={{marginTop : 10}}>
                            <DataTable
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                            rows={5} selectionMode="single" filterMatchMode="contains"
                            paginator={true} responsive={true} value={listeEnseignant}
                            globalFilter={globalFilter}
                            header={`Liste des enseignants (${listeEnseignant.length} enseignant(s))`} >

                            <Column field="matriculeEnseignant" header="matricule" headerStyle={{ textTransform: "capitalize" }} filterPlaceholder="Search ..." sortable filter />
                            <Column field="nomEnseignant"  header="nom" headerStyle={{ textTransform: "capitalize" }} filterPlaceholder="Search ..." sortable filter />
                            <Column field="prenomEnseignant" header="prénom" headerStyle={{ textTransform: "capitalize" }} filterPlaceholder="Search ..." sortable filter />
                            <Column field='grade.libelleGrade' header="grade" headerStyle={{ textTransform: "capitalize" }} filterPlaceholder="Search ..." sortable filter />
                            <Column field='specialite.libelleDiscipline' header="discipline" headerStyle={{ textTransform: "capitalize" }} filterPlaceholder="Search ..." sortable filter />
                            <Column field='specialite.libelleSpecialite' header="spécialité" headerStyle={{ textTransform: "capitalize" }} filterPlaceholder="Search ..." sortable filter />
                            <Column body={responsabiliteTemplate} header="responsabilité" headerStyle={{ textTransform: "capitalize" }} filterPlaceholder="Search ..." sortable filter />
                            <Column field='statutEnseignant.libelleStatutEnseignant' header="Statut" headerStyle={{ textTransform: "capitalize" }} filterPlaceholder="Search ..." sortable filter />
                            <Column field="emailEnseignant" header="email" headerStyle={{ textTransform: "capitalize" }} filterPlaceholder="Search ..." sortable filter />
                            <Column field="telEnseignant" header="telephone" headerStyle={{ textTransform: "capitalize" }} filterPlaceholder="Search ..." sortable filter />
                            <Column field="nomIes" header="université" headerStyle={{ textTransform: "capitalize" }} filterPlaceholder="Search ..." sortable filter />
                            <Column field="lbDepartementEtab" header="département" headerStyle={{ textTransform: "capitalize" }} filterPlaceholder="Search ..." sortable filter />
                            {/*<Column header={actionHeader} body={actionBodyTemplate} style={{ width: "120px" }} />*/}

                        </DataTable>
                        </div>
                        </>
                }
            </div>
  )
}

export default ListeEnseignant