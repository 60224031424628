import React, { useState, useRef, useEffect } from 'react'
import classNames from 'classnames'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { AnneeService } from '../../lmd/service/AnneeService';
import { SemestreService } from '../../lmd/service/SemestreService';
import { ECService } from '../../lmd/service/ECService';
import EnseignantService from '../service/EnseignantService';
import { ParcoursFiliereService } from '../../lmd/service/ParcoursFiliereService';
import { ParcoursUEService } from '../../lmd/service/ParcoursUEService';
import NominationService from '../service/NominationService';
import { Notify } from '../../components/Notify';
import { ToastContainer } from 'react-toastify';
import { Spinner } from '../../components/Spinner';


function NominationVacataire() {

    let emptyNomination = {
        idNomination : null,
        annee : {
            idAnneeAcademique : null,
            libelleAnnee : ""
        },
        enseignant : {
            idEnseignant : null,
            nomEnseignant : "",
            prenomEnseignant : "",
            matriculeEnseignant : "",
            nomPrenomEnseignant : ""
        },
        fichier : {
            nomFichier : "",
            refFichier : "",
            fichierBase64 : ""
        }
    }

    const [nominations, setNominations] = useState([])
    const [annees, setAnnees] = useState([])
    const [enseignants, setEnseignants] = useState([])
    /*const [parcours, setParcours] = useState([])
    const [ues, setUes] = useState([])
    const [semestres, setSemestres] = useState([])
    const [ecs, setEcs] = useState([])
    const [choixValeur, setChoixValeur] = useState([])*/
    const [nomination, setNomination] = useState(emptyNomination)
   //const [fichier, setFichier] = useState({})
    //const [annee, setAnnee] = useState(null)
    //const [enseignant, setEnseignant] = useState(null)
    //const [parcour, setParcour] = useState(null)
    //const [ue, setUe] = useState([])
    //const [semestre, setSemestre] = useState(null)
    //const [ec, setEc] = useState(null)
    const [selectedNominations, setSelectedNominations] = useState(null)
    //const [selectedChoixEc, setSelectedChoixEc] = useState(null)
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);     
    const [nominationDialog, setNominationDialog] = useState(false)
    const [deleteNominationDialog, setDeleteNominationDialog] = useState(false)
    const [consultationDialog, setConsultationDialog] = useState(false)
    //const [hideDialog, setHideDialog] = useState(false)
    const [loading, setLoading] = useState(false)

    const anneeService = new AnneeService()
    const enseignantService = new EnseignantService()
    const nominationService = new NominationService()
    /*const parcoursService = new ParcoursFiliereService()
    const parcoursUeService = new ParcoursUEService()
    const semestreService = new SemestreService()
    const ecService = new ECService()*/

    const tokenDecoded = localStorage.getItem('tokenDecoded')
    const token = JSON.parse(tokenDecoded)
    const spinner = new Spinner();
    const notify = new Notify();
    const dt = useRef(null);


    useEffect(() => {

        setLoading(true)
        nominationService.getAllNominationVacataire().then(data => {
            setNominations(data)
        })
        .catch(error => {
            console.log(error);
            notify.echec(error.response.data.message)
        })
        .finally(()=>{setLoading(false);})
    
      }, []);

    const openNew = () => {
        setNomination(emptyNomination)
        setSubmitted(false)
        anneeService.getAll().then(data => {
          setAnnees(data)
        })
        .catch(error => {
          console.log(error);
          //notify.showError(toast, "Impossible d'avoir la liste des universités !");
        })

        enseignantService.getAll().then(data => {
            setEnseignants(data)
          })
          .catch(error => {
            console.log(error);
            //notify.showError(toast, "Impossible d'avoir la liste des universités !");
          })
          
        
        setNomination(emptyNomination);
        setSubmitted(false);
        setNominationDialog(true);
    }
      
    const hideDialog = () => {
        setSubmitted(false);
        setNominationDialog(false);
    }

    const editNomination = (nomination) => {
        anneeService.getAll().then(data => {
          setAnnees(data)
        })
        .catch(error => {
          console.log(error);
          //notify.showError(toast)
          
        })
        setNomination({...nomination});
        setNominationDialog(true);
    }

    const consulterFichier = (nominationBack) => {
        setConsultationDialog(true)
        nominationService.getFichier(nominationBack.idNomination).then(data => {
            const nominationFront = {
                idNomination : data.idNomination,
                annee : {
                    idAnneeAcademique : data.idAnnee,
                    libelleAnnee : data.libelleAnnee
                },
                enseignant : {
                    idEnseignant : data.idEnseignant,
                    nomEnseignant : "",
                    prenomEnseignant : "",
                    matriculeEnseignant : "",
                    nomPrenomEnseignant : data.nomEnseignant
                },
                fichier : {
                    nomFichier : data.nomFichier,
                    refFichier : data.referenceFichier,
                    fichierBase64 : data.fichier
                }
    
            }
            onFileDecode(nominationFront)
        })
    
    }
      
    const confirmDeleteNomination = (nominationBack) => {
        setDeleteNominationDialog(true);
        const nominationFront = {
            idNomination : nominationBack.idNomination,
            annee : {
                idAnneeAcademique : nominationBack.idAnnee,
                libelleAnnee : nominationBack.libelleAnnee
            },
            enseignant : {
                idEnseignant : nominationBack.idEnseignant,
                nomEnseignant : "",
                prenomEnseignant : "",
                matriculeEnseignant : "",
                nomPrenomEnseignant : nominationBack.nomEnseignant
            },
            fichier : {
                nomFichier : nominationBack.nomFichier,
                refFichier : nominationBack.referenceFichier,
                fichierBase64 : nominationBack.fichier
            }

        }
        setNomination(nominationFront)
        
    }

    const saveNomination = () => {
        setSubmitted(true)
        if(nomination.annee.idAnneeAcademique && nomination.enseignant.idEnseignant && nomination.fichier.nomFichier && nomination.fichier.refFichier && nomination.fichier.fichierBase64){
            const infoNomination = {
                idAnnee : nomination.annee.idAnneeAcademique,
                idEnseignant : nomination.enseignant.idEnseignant,
                nomFichier : nomination.fichier.nomFichier,
                referenceFichier : nomination.fichier.refFichier,
                fichier : nomination.fichier.fichierBase64

            }
            if(nomination.idNomination){

            }
            else {
                nominationService.createNominationVacataire(infoNomination).then(data => {
                    notify.success()
                    setLoading(true)
                    nominationService.getAllNominationVacataire().then(data => {
                        setNominations(data)
                    })
                    .catch(error => {
                        console.log(error);
                        notify.echec(error.response.data.message)
                    })
                    .finally(()=>{setLoading(false);})

                })
                .catch(error => {
                    console.log(error)
                    notify.echec(error.response.data.message)
                })
            }
        }
        setNominationDialog(false)
        
        
    }

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
      }

    /*const choixEc = () => {
        let id  = createId();
        if(id && ec.idUniteEnseignement){

            let choix = {
              idChoix : id,
              idElementConstitutif : ec.idElementConstitutif,
              codeElementConstitutif : ec.codeElementConstitutif,
              libelleElementConstitutif : ec.libelleElementConstitutif,
            }
            let listeChoix = [...choixValeur,choix]
            console.log(listeChoix)
            setChoixValeur(listeChoix)
          }
          else {
              //notify.showError(toast, "renseigner les champs obligatoire !");
              console.log("Veuillez renseigner les champs obligatoire !")
          }
    }*/

    const onChampsChange = (e, field) => {
        const val = (e.target && e.target.value);
        let _nomination = {...nomination};
       if(field === "fichier")  _nomination[`${field}`]["refFichier"] = val;
       else _nomination[`${field}`] = val;
        setNomination(_nomination);
        /*if(field === "parcour"){
            const val = (e.target && e.target.value);
            let _parcour = {...parcour};
            _parcour = val;
            setParcour(_parcour);
            semestreService.get(val.idParcours).then(data => {
                setSemestres(data)
            })
            .catch(error => {
            console.log(error)
            //notify.showError(toast, "Impossible d'avoir la liste des UFR !")
            })
        }*/
        /*else if(field === "semestre"){
            const val = (e.target && e.target.value);
            let _semestre = {...semestre};
            _semestre = val;
            setSemestre(_semestre);
            parcoursUeService.get(parcour.idParcours, val.idSemestre).then(data => {
                setUes(data)
            })
            .catch(error => {
            console.log(error)
            //notify.showError(toast, "Impossible d'avoir la liste des UFR !")
            })
        }*/
        /*else if(field === "ue"){
            const val = (e.target && e.target.value);
            let _ue = {...ue};
            _ue = val;
            setUe(_ue);
            ecService.getListeECUniteEnseignement(val.idUniteEnseignement).then(data => {
                setEcs(data)
            })
            .catch(error => {
            console.log(error)
            //notify.showError(toast, "Impossible d'avoir la liste des UFR !")
            })
        }*/
        /*else if(field === "ec"){
            const val = (e.target && e.target.value);
            let _ec = {...ec};
            _ec = val;
            setEc(_ec);
        }*/
        
        /*if(field === "annee"){
            const val = (e.target && e.target.value);
            let _annee = {...annee};
            _annee = val;
            setAnnee(_annee);
        }
        else if(field === "enseignant"){
            const val = (e.target && e.target.value);
            let _enseignant = {...enseignant};
            _enseignant = val;
            setEnseignant(_enseignant);
        }
        else if(field === "fichier"){
            const val = (e.target && e.target.value);
            let _fichier = {...fichier};
            _fichier["refFichier"] = val;
            setFichier(_fichier);
        }*/

    }

    const onFileChange = (e) => {
        let file = e.target.files[0]
        if(file){
            let extension = file.name.split('.').pop();
            let ext = extension.toUpperCase()
            if(ext === "PDF"){
                let _nomination = {...nomination};
                _nomination["fichier"]["nomFichier"] = file.name;
                setNomination(_nomination);
                const reader = new FileReader()
                reader.onload = readerEvt => {
                    let binaryString = readerEvt.target.result
                    _nomination["fichier"]["fichierBase64"] = btoa(binaryString)
                    setNomination(_nomination)
                }
                reader.readAsBinaryString(file) 
            }
            
        }  
    }

    const removeChoix = (choix) => {

    }

    const deleteNomination = () => {
        
        nominationService.deleteNominationVacataire(nomination.idNomination).then(data => {

            notify.success()
            setLoading(true)
            nominationService.getAllNominationVacataire().then(data => {
                setNominations(data)
            })
            .catch(error => {
                console.log(error);
                notify.echec(error.response.data.message)
            })
            .finally(()=>{setLoading(false);})

            setDeleteNominationDialog(false)
      
        }).catch(error => {
            console.log(error);
            notify.echec(error.response.data.message)
        })
    }

    const onFileDecode = (nomination) => { 
        let fichier = nomination.fichier.fichierBase64
        let nomFichier = nomination.fichier.nomFichier
        if(fichier){
            let extension = nomFichier.split('.').pop();
            if(extension === "pdf"){
                let _nomination = {...nomination}
                _nomination['fichier']['fichierBase64'] = "data:application/pdf;base64,"+fichier
                setNomination(_nomination)
            }
        }
        
    }

    const hideDeleteNominationDialog = () => {
        setNomination(emptyNomination)
        setDeleteNominationDialog(false)
    }

    const hideConsultationDialog = () => {
        setNomination(emptyNomination)
        setConsultationDialog(false)
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-eye" className="p-button-warning p-mr-2" onClick={() => consulterFichier(rowData)} />
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => confirmDeleteNomination(rowData)} style={{marginLeft : 5}} />
            </React.Fragment>
        );
    }

    const actionBodyTemplateChoix = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => removeChoix(rowData.idChoix)} style={{marginLeft : 5}} />
            </React.Fragment>
        );
      }

    const header = (
        <div className="table-header p-col-12">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
                <Button style={{marginLeft : 10}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
            </span>
        </div>
    );

    const nominationDialogFooter = (
        <React.Fragment>
            <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={saveNomination} />
        </React.Fragment>
      );
    
    const deleteNominationDialogFooter = (
        <React.Fragment>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteNominationDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteNomination} />
        </React.Fragment>
    );

    const consultationDialogFooter = (
        <React.Fragment>
            <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideConsultationDialog} />
        </React.Fragment>
      );
    

    const actionHeader = <Button type="button" icon="pi pi-cog"/>;
    return (
    <div className="card">
        <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        {header}
        <hr/>
        {loading ?
            <div className="p-grid">
                <div className="p-col-6" ></div>
                <div className="p-col-6" >
                    {spinner.loadingSpinner("spin", "black")}
                </div>
            </div>
            :
            <DataTable ref={dt} value={nominations} selection={selectedNominations} onSelectionChange={(e) => selectedNominations(e.value)}
                dataKey="id" paginator rows={10} 
                globalFilter={globalFilter}
                header="Liste des nominations"
                emptyMessage="Rien trouvé !"
                >
                <Column field="nomEnseignant" header="Enseignant" filterMatchMode="contains" sortable={true} filter={true}></Column>
                <Column field="libelleAnnee" header="Année" filterMatchMode="contains" sortable={true} filter={true}></Column>
                <Column field="referenceFichier" header="Ref. nomination" filterMatchMode="contains" sortable={true} filter={true}></Column>
                <Column header={actionHeader} body={actionBodyTemplate} style={{textAlign:'center', width: '8em'}}></Column>
            </DataTable>
        }
        <Dialog visible={nominationDialog} style={{ width: '50%' }} header="Informations nomination vacataire" modal className="p-fluid" footer={nominationDialogFooter} onHide={hideDialog}>
            <div className="p-grid">
                <div className="field p-col-6">
                    <label htmlFor="idUfr">Année</label>
                    <Dropdown optionLabel="libelleAnnee" value={nomination.annee} options={annees} onChange={(e) => onChampsChange(e, 'annee')} className={classNames({ 'p-invalid': submitted && !nomination.annee.idAnneeAcademique })} />
                    {submitted && !nomination.annee.idAnneeAcademique && <small className="p-error">Ce champs est obligatoire.</small>}
                </div>
                <div className="field p-col-6">
                    <label htmlFor="nomEnseignant">Enseignant</label>
                    <Dropdown optionLabel="nomPrenomEnseignant" value={nomination.enseignant} options={enseignants} onChange={(e) => onChampsChange(e, 'enseignant')} required autoFocus className={classNames({ 'p-invalid': submitted && !nomination.enseignant.idEnseignant })} />
                    {submitted && !nomination.enseignant.idEnseignant && <small className="p-error">Ce champs est obligatoire.</small>}
                </div>
            </div>
            <div className="p-grid">
                <div className="field p-col-6">
                    <label htmlFor="refFichier">Réference nomination</label>
                    <InputText id="refFichier" value={nomination.fichier.refFichier} onChange={(e) => onChampsChange(e, 'fichier')} className={classNames({ 'p-invalid': submitted && !nomination.fichier.refFichier })} />
                    {submitted && !nomination.fichier.refFichier && <small className="p-error">Ce champs est obligatoire.</small>}
                </div>
                <div className="field p-col-6">
                    <label htmlFor="pv">Selectionner la décision de nomination</label>
                    <input type={"file"} onChange={(e)=>{onFileChange(e)}}/>
                </div>
            </div>
        </Dialog>
        <Dialog visible={deleteNominationDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteNominationDialogFooter} onHide={hideDeleteNominationDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {"'this.state.deletionId'" && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                </div>
        </Dialog>
        <Dialog visible={consultationDialog} style={{ width: '80%' }} header="Décision de nomination" modal className="p-fluid" footer={consultationDialogFooter} onHide={hideConsultationDialog}>
            <div className="p-grid">
                <iframe src={nomination.fichier.fichierBase64} style={{width:'100%', height:500}}></iframe>                
                            
            </div>
        </Dialog>
        {/*<Dialog visible={nominationDialog} style={{ width: '50%' }} header="Informations nomination vacataire" modal className="p-fluid" footer={nominationDialogFooter} onHide={hideDialog}>
            <div className="p-grid">
                <div className="field p-col-6">
                    <label htmlFor="idUfr">Année</label>
                    <Dropdown optionLabel="libelleAnnee" value={annee} options={annees} onChange={(e) => onChampsChange(e, 'annee')} className={classNames({ 'p-invalid': submitted && !annee.idAnnee })} />
                    {submitted && !annee.idAnnee && <small className="p-error">Ce champs est obligatoire.</small>}
                </div>
                <div className="field p-col-6">
                    <label htmlFor="nomEnseignant">Enseignant</label>
                    <Dropdown optionLabel="nomPrenomEnseignant" value={enseignant} options={enseignants} onChange={(e) => onChampsChange(e, 'enseignant')} required autoFocus className={classNames({ 'p-invalid': submitted && !enseignant.idEnseignant })} />
                    {submitted && !enseignant.idEnseignant && <small className="p-error">Ce champs est obligatoire.</small>}
                </div>
            </div>
            <div className="p-grid">
                <div className="field p-col-6">
                    <label htmlFor="refFichier">Réference nomination</label>
                    <InputText id="refFichier" value={fichier.refFichier} onChange={(e) => onChampsChange(e, 'fichier')} className={classNames({ 'p-invalid': submitted && !fichier.refFichier })} />
                    {submitted && !fichier.refFichier && <small className="p-error">Ce champs est obligatoire.</small>}
                </div>
                <div className="field p-col-6">
                    <label htmlFor="pv">Selectionner la décision de nomination</label>
                    <input type={"file"} onChange={(e)=>{onFileChange(e)}}/>
                </div>
            </div>
            <div className="p-grid">
                <div className="field p-col-6">
                    <label htmlFor="libelleParcours">Parcours</label>
                    <Dropdown optionLabel="libelleParcours" value={parcour} options={parcours} onChange={(e) => onChampsChange(e, 'parcour')} required autoFocus className={classNames({ 'p-invalid': submitted && !parcour.idParcours })} />
                    {submitted && !parcour.idParcours && <small className="p-error">Ce champs est obligatoire.</small>}
                </div>
                <div className="field p-col-6">
                    <label htmlFor="libelleSemestre">Semestre</label>
                    <Dropdown optionLabel="libelleSemestre" value={semestre} options={semestres} onChange={(e) => onChampsChange(e, 'semestre')} required autoFocus className={classNames({ 'p-invalid': submitted && !semestre.idSemestre })} />
                    {submitted && !semestre.idSemestre && <small className="p-error">Ce champs est obligatoire.</small>}
                </div>
            </div>
            <div className="p-grid">
                <div className="field p-col-6">
                    <label htmlFor="libelleUe">UE</label>
                    <Dropdown optionLabel="libelleUniteEnseignement" value={ue} options={ues} onChange={(e) => onChampsChange(e, 'ue')} required autoFocus className={classNames({ 'p-invalid': submitted && !ue.idUniteEnseignement })} />
                    {submitted && !ue.idUniteEnseignement && <small className="p-error">Ce champs est obligatoire.</small>}
                </div>
                <div className="field p-col-6">
                    <label htmlFor="libelleEc">EC</label>
                    <Dropdown optionLabel="libelleElementConstitutif" value={ec} options={ecs} onChange={(e) => onChampsChange(e, 'ec')} required autoFocus className={classNames({ 'p-invalid': submitted && !ec.idElementConstitutif })} />
                    {submitted && !ec.idElementConstitutif && <small className="p-error">Ce champs est obligatoire.</small>}
                </div>
            </div>
            <div className="p-grid">
                <div className="field col p-col-6"></div>
                <div className="field col p-col-6" style={{textAlign : "right"}}>
                <Button style={{width : 100}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={choixEc} />
                </div>
            </div>
            <hr/>
            <DataTable ref={dt} value={choixValeur} selection={selectedChoixEc} onSelectionChange={(e) => setSelectedChoixEc(e.value)}
                dataKey="id" paginator rows={10} 
                header="Liste des éléments constitutifs choisies"
                emptyMessage="Rien trouvé !"
                >
                <Column field="codeElementConstitutif" header="code EC" ></Column>
                <Column field="libelleElementConstitutif" header="Elément constitutif" ></Column>
                <Column header={actionHeader} body={actionBodyTemplateChoix} style={{textAlign:'center', width: '8em'}}></Column>
            </DataTable>
        </Dialog>*/}
    </div>
  )
}

export default NominationVacataire