import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class UEService {

    constructor(){
        this.adresse = new AdresseIP()
    }

   getAll(idDepartement = null) {
       
        let url = ""
        if(idDepartement){
            url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/unite-enseignement?idDepartement="+idDepartement
        }else{
            url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/unite-enseignement"
        }
       
       return axios.get(url).then(res => res.data)
   }


   getUEById(idUniteEnseignement) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/unite-enseignement?idUniteEnseignement="+idUniteEnseignement
        return axios.get(url).then(res => res.data)
   }

   getUeLibreParcours(idParcours, idDepartement = null){

        let url = null
        if(idDepartement){
            url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/unite-enseignement?idParcours="+idParcours+"&idDepartement="+idDepartement
        }else{
            url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/unite-enseignement?idParcours="+idParcours
        }

        //console.log(url)
        
        return axios.get(url).then(res => res.data)
   }

    getAllSansObjet() {
       
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/unite-enseignement-sans-objet"
        return axios.get(url).then(res => res.data)
    }
   
    update(idUniteEnseignement, newUE) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/unite-enseignement/"
       return axios.put(url+idUniteEnseignement,newUE)
                   .then(res => res.data);
   }

   create(newUE) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/unite-enseignement/"
       return axios.post(url, newUE)
                   .then(res => res.data);
   }

   delete(idUE) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/unite-enseignement/"
       return axios.patch(url+idUE)
                   .then(res => res.data)
   }

   getListeUEUser() {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/user-unite-enseignement"
        return axios.get(url)
            .then(res => res.data)
    }

    getListeEcLibreUe(idParcours, idSemestre){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/ec-libre?idParcours="+idParcours+"&idSemestre="+idSemestre
        return axios.get(url)
            .then(res => res.data)
    }



    rechercherECParCodeLibellle(objetRecherche){

        let url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/liste-element-constitutif"

        if(objetRecherche.codeElementConstitutif.trim() && objetRecherche.libelleElementConstitutif.trim()){
            url = url+"?codeEc="+objetRecherche.codeElementConstitutif.trim()+"&libelleEc="+objetRecherche.libelleElementConstitutif.trim()
        }else if (objetRecherche.codeElementConstitutif){
            url = url+"?codeEc="+objetRecherche.codeElementConstitutif
        }else{
            url = url+"?libelleEc="+objetRecherche.libelleElementConstitutif
        }

        //console.log(url)
        return axios.get(url)
            .then(res => res.data)
    }




   
}