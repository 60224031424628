import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class SignataireService {

    constructor(){
        this.adresse = new AdresseIP()
    }
    
   getAll(idEtablissement) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/signataire?idEtablissement="+idEtablissement
       return axios.get(url).then(res => res.data) 
   }
   
   create(signataire) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/signataire"
       return axios.post(url,signataire)
                   .then(res => res.data);
   }

   update(idSignataire, signataire) {
    const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/signataire/"+idSignataire
    return axios.put(url,signataire)
                .then(res => res.data);
}


}