import React, { Component } from 'react'
import Axios from 'axios'
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import Keycloak from 'keycloak-js';
import jwt_decode from "jwt-decode";
import MenuHome from '../home/MenuHome';
import { ToastContainer} from 'react-toastify';
import '../css/acceuil.css'
//import '../css/style_nouveau.css'
import SlideShow from './SlideImage';
import Compte from './Compte';
import {Button} from 'primereact/button'
import classNames from 'classnames'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { UserService } from '../service/UserService';
import { Spinner } from './Spinner';
import { Notify } from './Notify';
import AppBreadcrumb from '../AppBreadcrumb'
import MenuEtudiant from '../home/MenuEtudiant';
import MenuEnseignant from '../home/MenuEnseignant';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';



export class Connexion extends Component {

    emptyUser = {
        idEtudiant : null,
        ine : "",
        nomEtudiant : "",
        prenomEtudiant : "",
        sexeEtudiant : "",
        idIesr : null,
        universite : "",
        filiere : "",
        niveau : "",
        villeOrientation : "",
        emailEtudiant : "",
        telEtudiant : "",
        anneeBac : null,
        serieBac : "",
        moyenneBac : null,
        dateNaissance : "",
        lieuNaissance : "",
        idPays : null,
        libellePays : "",
        typePays : "",
        personnelBesoin : "",
        contact : null,
        engagementStatutReglement : null,
        engagementPersonnelCaution : null,
        luApprouve : null
    }
    emptyClient = {
        idClient : null,
        nom : "",
        prenom : "",
        sexe : null,
        jourNaiss : "",
        moisNaiss : "",
        anneeNaiss : "",
        dateNaissance: "",
        lieuNaissance: "",
        numeroTelephone : "",
        adresseEmail : "",
        passeClient : "",
        ville : ""
    }

    constructor(props){
        super(props)

        this.state = {
            keycloakState : null,
            authenticated : false,
            token : "",
            logout : "",
            bool : false,
            items : [],
            click : false,
            clickService : false,
            firstEnter : false,
            booleenMesServices : false,
            creerCompte : false,
            user : this.emptyUser,
            client : this.emptyClient,
            submitted : false,
            inscriptionDialog: false,
            inscriptionClientDialog: false,
            etudiantDialog : false,
            isEtudiant : false,
            sexes : [{idSexe : 1, libelleSexe : "MASCULIN"},{idSexe : 2, libelleSexe : "FEMININ"}],
            jourNaisss : [], moisNaisss : [], anneeNaisss : []
        }
       this.spinner = new Spinner() 
       this.notify = new Notify()
       this.userService = new UserService()

    }
    componentDidMount = () => {
        const connectepasse = localStorage.getItem('connectepasse')
		let connecte = JSON.parse(connectepasse)
        const accessToken = localStorage.getItem('accessToken')
		const token = JSON.parse(accessToken)
        
        const keyBr = localStorage.getItem('keycloak')
		const key = JSON.parse(keyBr)

        let listeJours = []
        let listeMois = []
        let listeAnnee = []
        let today = new Date();
        let year = today.getFullYear();
        let j, k=""
        let l, m=""
        for(let i = 0; i < 31; i++){
            if(i<9) {
                j = i+1
                k = "0"+j
                listeJours.push({idJourNaiss : i+1, libelleJourNaiss : k })
            }
            else {
                l = i+1
                m = ""+l
                listeJours.push({idJourNaiss : i+1, libelleJourNaiss : m })
            }
            
        }

        for(let i = 0; i < 12; i++){
            if(i<9) {
                j = i+1
                k = "0"+j
                listeMois.push({idMoisNaiss : i+1, libelleMoisNaiss : k })
            }
            else {
                l = i+1
                m = ""+l
                listeMois.push({idMoisNaiss : i+1, libelleMoisNaiss : m })
            }

        }

        //console.log(listeMois)
        
        for(let i = 0; i < 100; i++){
            listeAnnee.push({idAnneeNaiss : year-14-i, libelleAnneeNaiss : year-14-i })
        }
        this.setState({jourNaisss : listeJours})
        this.setState({moisNaisss : listeMois})
        this.setState({anneeNaisss : listeAnnee})
        
        
        /*if(key){
            console.log(key)
            //connecte = false
            this.setState({keycloakState : key})
        }
        //else connecte = true
        if(token){
            this.setState({token : token})
        } */
        
        if(connecte){
            
            const keycloak = Keycloak({
                realm: "campusFaso",
                //url: "http://192.168.43.22:8180/auth/",
                url: "https://login.campusfaso.bf/auth/",
                resource: "campus-faso",
                clientId : "campus-faso",
                credentials: {
                    secret: "secret"
                },
              });
            
            keycloak.init({onLoad: 'login-required'}).then(authenticated => {
                this.setState({bool : true , keycloakState: keycloak, token : keycloak.token, authenticated: authenticated })
                //console.log(keycloak.token)
                localStorage.setItem('keycloak', JSON.stringify(keycloak))
                //localStorage.setItem('logout', JSON.stringify(keycloak.logout()))
                localStorage.setItem('accessToken', JSON.stringify(keycloak.token))
                localStorage.setItem('authenticated', JSON.stringify(authenticated))
                document.location.hash="#services" ;
                
              })
        } 
    }

    decodeKeycloak = () => {
        let decoded = jwt_decode(this.state.token);
        localStorage.setItem('tokenDecoded', JSON.stringify(decoded))
    }

    clickConnexion = () => {
        this.setState({click : true})
        localStorage.setItem('connectepasse', JSON.stringify(true))
    }

    clickDeconnexion = () => {
        localStorage.clear()
        console.log(this.props.keycloakCon)
        if(this.props.keycloakCon)
            this.props.keycloakCon.logout()
        
    }
    loadMesServices = () => {
        //sconsole.log(this.props.keycloakCon)
        this.setState({booleenMesServices : true, clickService: false, keycloakState : this.props.keycloakCon})
    }

    loadCreerCompte = () => {
        this.setState({creerCompte : true})
    }

    hideDialogInscription = () => {
        this.setState({
            submitted: false,
            inscriptionDialog: false
        });
    }
    hideDialogInscriptionClient = () => {
        this.setState({
            submitted: false,
            inscriptionClientDialog: false
        });
    }

    hideDialogEtudiant = () => {
        this.setState({
            submitted: false,
            etudiantDialog: false
        });
    }

    onValueChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        let user = this.state.user
        user[`${field}`] = val
        this.setState({user})      
    }

    onValueChangeClient =(e, field) => {
        const val = (e.target && e.target.value) || '';
        let client = this.state.client
        client[`${field}`] = val
        if(field === "anneeNaiss"){
            
            if(this.state.client.jourNaiss && this.state.client.moisNaiss && val){
                const dateNaiss = this.state.client.jourNaiss.libelleJourNaiss+'-'+this.state.client.moisNaiss.libelleMoisNaiss+'-'+val.libelleAnneeNaiss
                client['dateNaissance'] = dateNaiss
            }
            else {
                alert("Veuillez bien renseigner la date de naissance !")
            }
        }

        this.setState({client})
    }

    openNewInscription = () => {
        this.setState({
            user: this.emptyUser,
            submitted: false,
            inscriptionDialog: true
        });
    } 
    openNewInscriptionClient = () => {
        this.setState({
            client: this.emptyClient,
            submitted: false,
            inscriptionClientDialog: true
        });
    } 
    openDetailEtudiant = () => {
        //aller chercher les infos de l'etudiant à faire ici
        this.setState({
            inscriptionDialog : false,
            //user: this.emptyUser,
            submitted: false,
            etudiantDialog: true
        });
    } 

    verifeUser = () => {
        //aller chercher les infos de l'etudiant à faire ici
        this.userService.getUser(this.state.user.ine).then(data => {
            //console.log(data)
            this.setState({
                inscriptionDialog : false,
                user: data,
                submitted: false,
                etudiantDialog: true
            });
        })
        .catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })
       
    }

    creerCompte = () => {
        this.setState({submitted : true})
        this.userService.creerCompte(this.state.user).then(data => {
            this.notify.success()
            this.setState({etudiantDialog: false})
        })
        .catch(error => {
            this.notify.echec(error.response.data.message)
            console.log(error)
            
        })
    }
    inscriptionClient = () => {
        //console.log(this.state.client)
        const client = {
            idClient : this.state.client.idClient,
            nom : this.state.client.nom,
            prenom : this.state.client.prenom,
            sexe : this.state.client.sexe.libelleSexe,
            dateNaissance : this.state.client.dateNaissance,
            lieuNaissance : this.state.client.lieuNaissance,
            numeroTelephone : this.state.client.numeroTelephone,
            adresseEmail : this.state.client.adresseEmail,
            passeClient : this.state.client.passeClient,
            ville : this.state.client.ville

        }
        console.log(client)
        this.setState({submitted : true})
        if(client.nom, client.prenom, client.sexe, client.dateNaissance, client.lieuNaissance, client.adresseEmail, client.numeroTelephone, client.passeClient, client.ville){
            this.userService.creeCompteClient(client).then(data => {
                this.notify.success()
                this.setState({inscriptionClientDialog : false, submitted:false})
                console.log(data)
            })
            .catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error)
            })
        }
        else {
            //alert("Veuillez remplir les champs obligatoire !")
        }
        //this.setState({submitted : false})
    }
    
    render() {
        const inscriptionDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialogInscription} />
                <Button label="S'inscrire" icon="pi pi-check" className="p-button-text" onClick={this.verifeUser} />
            </React.Fragment>
        );
        const inscriptionClientDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialogInscriptionClient} />
                <Button label="S'inscrire" icon="pi pi-check" className="p-button-text" onClick={this.inscriptionClient} />
            </React.Fragment>
        );
        const etudiantDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialogEtudiant} />
                <Button label="Créer compte" icon="pi pi-check" className="p-button-text" onClick={this.creerCompte} />
            </React.Fragment>
        );
        if(this.state.click){
            this.componentDidMount()
        }
        if(this.state.bool){
            this.decodeKeycloak()
            const token = this.state.keycloakState.token
            
            Axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
        }
        else {
            //let refreshToken = this.state.keycloakState.refreshToken
            //Axios.defaults.headers.common['Authorization'] = 'Bearer ' + refreshToken
        }

        
        if(this.state.authenticated || this.state.booleenMesServices){
            const tokenDecoded = localStorage.getItem('tokenDecoded')
            const tokenD = JSON.parse(tokenDecoded)
            let roles = null
            if(tokenD.realm_access.roles) roles = tokenD.realm_access.roles
            let campusMinistere = 0, campusPresident = 0, campusVpEip = 0, campusDaoi = 0, campusDdip = 0;
            let campusDirecteur = 0, campusDa = 0, campusScolarite = 0, campusPresidentJury = 0;
            let campusSecretaireJury = 0, campusMemebreJury = 0, campusChefDepartement = 0;
            let campusEnseignant = 0, campusDepartement = 0, campusDGESup = 0, campusEtudiant = 0;
    
            const profiles = roles.filter(role => {
               
                if(role === "campus-president") {
                    campusPresident = 1
                    localStorage.setItem('campusPresident', JSON.stringify(campusPresident))
                }   
                else if(role === "campus-vp-eip"){
                    campusVpEip = 1
                    localStorage.setItem('campusVpEip', JSON.stringify(campusVpEip))
                }
                else if(role === "campus-daoi"){
                    campusDaoi = 1
                    localStorage.setItem('campusDaoi', JSON.stringify(campusDaoi))
                } 
                else if(role === "campus-ddip"){
                    campusDdip = 1
                    localStorage.setItem('campusDdip', JSON.stringify(campusDdip))
                } 
                else if(role === "campus-directeur"){
                    campusDirecteur = 1
                    localStorage.setItem('campusDirecteur', JSON.stringify(campusDirecteur))
                }  
                else if(role === "campus-da"){
                    campusDa = 1
                    localStorage.setItem('campusDa', JSON.stringify(campusDa))
                } 
                else if(role === "campus-scolarite"){
                    campusScolarite = 1
                    localStorage.setItem('campusScolarite', JSON.stringify(campusScolarite))
                } 
                else if(role === "campus-president-jury"){
                    campusPresidentJury = 1
                    localStorage.setItem('campusPresidentJury', JSON.stringify(campusPresidentJury))
                } 
                else if(role === "campus-secretaire-jury"){
                    campusSecretaireJury = 1
                    localStorage.setItem('campusSecretaireJury', JSON.stringify(campusSecretaireJury))
                } 
                else if(role === "campus-membre-jury"){
                    campusMemebreJury = 1
                    localStorage.setItem('campusMemebreJury', JSON.stringify(campusMemebreJury))
                }
                else if(role === "campus-chef-departement"){
                    campusChefDepartement = 1
                    localStorage.setItem('campusChefDepartement', JSON.stringify(campusChefDepartement))
                }
                else if(role === "campus-admin-departement"){
                    campusDepartement = 1
                    localStorage.setItem('campusDepartement', JSON.stringify(campusDepartement))
                }
                else if(role === "campus-admin"){
                    campusDGESup = 1
                    localStorage.setItem('campusDGESup', JSON.stringify(campusDGESup))
                }
                else if(role === "campus-enseignant"){
                    campusEnseignant = 1
                    localStorage.setItem('campusEnseignant', JSON.stringify(campusEnseignant))
                }
                else if(role === "campus-etudiant"){
                    campusEtudiant = 1
                    localStorage.setItem('campusEtudiant', JSON.stringify(campusEtudiant))
                }
                else if(role === "campus-ministere"){
                    campusMinistere = 1
                    localStorage.setItem('campusMinistere', JSON.stringify(campusMinistere))
                }
                else {
                    
                }
                return role
            })
            
            return (
                <div>
                    <MenuHome clickService = {this.state.clickService} keycloak = {this.state.keycloakState} /> 
                </div> 
            )
            
            /*if(campusEtudiant){
                return (
                    <div>
                    { <MenuEtudiant keycloak = {this.state.keycloakState} /> }
                    </div>
                )
            }
            else if(campusEnseignant){
                return(
                    <div>
                        {<MenuEnseignant keycloak = {this.state.keycloakState}/>}
                    </div>
                )
            }
            else {
                return (
                    <div>
                        { <MenuHome clickService = {this.state.clickService} keycloak = {this.state.keycloakState} /> }
                    </div> 
                )
            }*/
             
            
        }
        else {
           
            return (
                
                            <div className="bodys">
                                <ToastContainer
                                    position="top-right"
                                    autoClose={2000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                />
                                <ToastContainer />
                                <Dialog visible={this.state.inscriptionDialog} style={{ width: '30%' }} header="Création de compte" modal className="p-fluid" footer={inscriptionDialogFooter} onHide={this.hideDialogInscription}>
                                    <div className="p-grid">
                                        <div className='p-col-12 p-md-12 p-lg-12'>
                                            <div className="p-field">
                                                <label htmlFor="libelleUniteEnseignement">Entrer votre INE</label>
                                                <InputText value={this.state.user.ine} onChange={(e) => {this.onValueChange(e, 'ine')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                                {this.state.submitted && !this.state.user.ine && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                                            </div>
                                        </div>
                                    </div>
                                </Dialog>
                                <Dialog visible={this.state.inscriptionClientDialog} style={{ width: '60%' }} header="Création de compte" modal className="p-fluid" footer={inscriptionClientDialogFooter} onHide={this.hideDialogInscriptionClient}>
                                    <div className='p-form-grid'> 
                                        <div className='p-grid'>
                                            <div className="p-field col-6">
                                                <label htmlFor="nom">Nom</label>
                                                <InputText value={this.state.client.nom} onChange={(e) => {this.onValueChangeClient(e, 'nom')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                                {this.state.submitted && !this.state.client.nom && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                                            </div>
                                            <div className="p-field col-6">
                                                <label htmlFor="prenom">Prénom</label>
                                                <InputText value={this.state.client.prenom} onChange={(e) => {this.onValueChangeClient(e, 'prenom')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                                {this.state.submitted && !this.state.client.prenom && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                                            </div>
                                        </div>
                                        <div className='p-grid'>
                                            <div className="p-field col-6">
                                                <label htmlFor="sexe">Sexe</label>
                                                {<Dropdown value={this.state.client.sexe} onChange={(e) => {this.onValueChangeClient(e, 'sexe')}} options={this.state.sexes} optionLabel="libelleSexe" />}
                                                {/*<InputText value={this.state.client.sexe} onChange={(e) => {this.onValueChangeClient(e, 'sexe')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />*/}
                                                {this.state.submitted && !this.state.client.sexe && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                                            </div>
                                            <div className="p-field col-6">
                                                <label htmlFor="nationalite">Nationalité</label>
                                                <InputText value={this.state.client.nationalite} onChange={(e) => {this.onValueChangeClient(e, 'nationalite')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                                {this.state.submitted && !this.state.client.nationalite && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                                            </div>
                                        </div>
                                        <div className='p-grid'>
                                            <div className="p-field col-6">
                                                <label htmlFor="dateNaiss">Date de naissance</label>
                                                {<div className='p-grid'>
                                                    <div className='p-col-4'>
                                                        <Dropdown value={this.state.client.jourNaiss} onChange={(e) => {this.onValueChangeClient(e, 'jourNaiss')}} options={this.state.jourNaisss} optionLabel="libelleJourNaiss" placeholder='Jour' />
                                                        {this.state.submitted && !this.state.client.jourNaiss && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                                                    </div>
                                                    <div className='p-col-4'>
                                                        <Dropdown value={this.state.client.moisNaiss} onChange={(e) => {this.onValueChangeClient(e, 'moisNaiss')}} options={this.state.moisNaisss} optionLabel="libelleMoisNaiss"  placeholder='Mois' />
                                                        {this.state.submitted && !this.state.client.moisNaiss && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                                                    </div>
                                                    <div className='p-col-4'>
                                                        <Dropdown value={this.state.client.anneeNaiss} onChange={(e) => {this.onValueChangeClient(e, 'anneeNaiss')}} options={this.state.anneeNaisss} optionLabel="libelleAnneeNaiss" placeholder='Année' />
                                                        {this.state.submitted && !this.state.client.anneeNaiss && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                                                    </div>
                                                    
                                                </div>}
                                                
                                                {/*<InputText type='text' value={this.state.client.dateNaissance} onChange={(e) => {this.onValueChangeClient(e, 'dateNaissance')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />*/}
                                                {/*<Calendar value={this.state.client.dateNaissance} onChange={(e) => {this.onValueChangeClient(e, 'dateNaissance')}} dateFormat="dd/mm/yy" />*/}
                                                {/*this.state.submitted && !this.state.client.dateNaissance && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>*/}
                                            </div>
                                            <div className="p-field col-6">
                                                <label htmlFor="lieuNaissance">Lieu de naissance</label>
                                                <InputText value={this.state.client.lieuNaissance} onChange={(e) => {this.onValueChangeClient(e, 'lieuNaissance')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                                {this.state.submitted && !this.state.client.lieuNaissance && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                                            </div>
                                        </div>
                                        <div className='p-grid'>
                                            <div className="p-field col-6">
                                                <label htmlFor="telClient">Téléphone</label>
                                                <InputText value={this.state.client.numeroTelephone} onChange={(e) => {this.onValueChangeClient(e, 'numeroTelephone')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                                {this.state.submitted && !this.state.client.numeroTelephone && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                                            </div>
                                            <div className="p-field col-6">
                                                <label htmlFor="email">Email</label>
                                                <InputText value={this.state.client.adresseEmail} onChange={(e) => {this.onValueChangeClient(e, 'adresseEmail')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                                {this.state.submitted && !this.state.client.adresseEmail && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                                            </div>
                                        </div>
                                        <div className='p-grid'>
                                            <div className="p-field col-12">
                                                <label htmlFor="ville">Ville</label>
                                                <InputText value={this.state.client.ville} onChange={(e) => {this.onValueChangeClient(e, 'ville')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                                {this.state.submitted && !this.state.client.ville && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                                            </div>
                                        </div>
                                        <div className='p-grid'>
                                            <div className="p-field col-6">
                                                <label htmlFor="identifiant">Identifiant</label>
                                                <InputText readOnly value={this.state.client.adresseEmail} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                                {this.state.submitted && !this.state.client.adresseEmail && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                                            </div>
                                            <div className="p-field col-6">
                                                <label htmlFor="password">Mot de passe</label>
                                                <InputText type="password" value={this.state.client.passeClient} onChange={(e) => {this.onValueChangeClient(e, 'passeClient')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                                {this.state.submitted && !this.state.client.passeClient && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire !</small>}
                                            </div>
                                        </div>
                                    </div>
                                        
                                </Dialog>

                                <Dialog visible={this.state.etudiantDialog} style={{ width: '40%' }} header="Création de compte" modal className="p-fluid" footer={etudiantDialogFooter} onHide={this.hideDialogEtudiant}>
                                    <div className="p-grid">
                                        <div className='p-col-12 p-md-12 p-lg-12'>
                                            <div className="p-field">
                                                <label htmlFor="ine">Votre INE</label>
                                                <InputText value={this.state.user.ine}  required autoFocus  />  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <div className="p-field">
                                                <label htmlFor="nom">Nom</label>
                                                <InputText value={this.state.user.nomEtudiant}  required autoFocus  />  
                                            </div>
                                        </div>
                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <div className="p-field">
                                                <label htmlFor="prenom">Prénom</label>
                                                <InputText value={this.state.user.prenomEtudiant}  required autoFocus  />  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        
                                        <div className='p-col-12 p-md-12 p-lg-12'>
                                            <div className="p-field">
                                                <label htmlFor="sexe">Sexe</label>
                                                <InputText value={this.state.user.sexeEtudiant}  required autoFocus  /> 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        
                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <div className="p-field">
                                                <label htmlFor="dateNaiss">Date de naissance</label>
                                                <InputText value={this.state.user.dateNaissance}  required autoFocus  /> 
                                            </div>
                                        </div>
                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <div className="p-field">
                                                <label htmlFor="lieuNaiss">Lieu de naissance</label>
                                                <InputText value={this.state.user.lieuNaissance}  required autoFocus  />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <div className="p-field">
                                                <label htmlFor="email">Email <span style={{color : "red"}}>(modifiable)</span></label>
                                                <InputText value={this.state.user.emailEtudiant} onChange={(e) => {this.onValueChange(e, 'emailEtudiant')}}  required autoFocus  />
                                            </div>
                                        </div>
                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <div className="p-field">
                                                <label htmlFor="tel">Téléphone <span style={{color : "red"}}>(modifiable)</span></label>
                                                <InputText value={this.state.user.telEtudiant} onChange={(e) => {this.onValueChange(e, 'telEtudiant')}}   required autoFocus  />  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <div className="p-field">
                                                <label htmlFor="université">Université</label>
                                                <InputText value={this.state.user.universite}  required autoFocus />  
                                            </div>
                                        </div>
                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <div className="p-field">
                                                <label htmlFor="filiere">Filière</label>
                                                <InputText value={this.state.user.filiere}  required autoFocus />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        
                                        <div className='p-col-12 p-md-12 p-lg-12'>
                                            <div className="p-field">
                                                <label htmlFor="sexe">Niveau</label>
                                                <InputText value={this.state.user.niveau}  required autoFocus  /> 
                                            </div>
                                        </div>
                                    </div>
                                </Dialog>
                                {this.state.token ?
                                <nav style={{marginTop : 0}} className="navbar navbar-light navbar-expand-lg p-0 w-auto" id="main-nav">
                                    <div className="logo">
                                        <a className="navbar-brand link-menu scroll-to-target current"
                                            href="/">
                                            <img className="logo" src="/assets/layout/images/CampusFaso_Logo.png" alt="CampusFaso"/>
                                        </a>
                                    </div>
                                    
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="navbar-collapse collapse justify-content-md-end nav-menu ml-5 mb-4 mb-lg-0 ml-lg-0" id="navbarContent">
                                <ul className="navbar-nav">
                                   
                                    <li className="nav-item  align-self-lg-center" onClick={this.loadMesServices} style={{cursor: "pointer"}}>
                                        <div className="nav-link">
                                            Mes services
                                        </div>
                                    </li>

                                    <li className="nav-item  align-self-lg-center" id="paiement" >
                                        <div className="nav-link" >
                                            Autorisation d'enseigner
                                        </div>
                                    </li>
                        
                                        
                                    <li className="nav-item  align-self-lg-center" id="formations">
                                        <a className="nav-link" >
                                            Demande de création d'IPES
                                        </a>
                                    </li>
                        
                                    <li className="nav-item align-self-lg-center" id="communiques">
                                        <a className="nav-link" >
                                            Bourse doctorat
                                        </a>
                                    </li>
                        
                                    <li className="nav-item  align-self-lg-center" id="connexion" onClick={this.clickDeconnexion} style={{cursor: "pointer"}}>
                                        <div className="nav-link" >
                                            Deconnexion
                                            <img src="/assets/layout/images/unknown_avantar.png" width="" alt="Avatar" className="img-fluid"/>
                                        </div>
                                    </li>
                                </ul> 
                            </div>
                        </nav>
                        :
                        <nav style={{marginTop : 0}} className=" navbar navbar-light navbar-expand-lg p-0 w-auto" id="main-nav">
                                    <div className="logo">
                                        <a className="navbar-brand link-menu scroll-to-target current"
                                            href="/">
                                            <img className="logo" src="/assets/layout/images/CampusFaso_Logo.png" alt="CampusFaso"/>
                                        </a>
                                    </div>
        
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="navbar-collapse collapse justify-content-md-end nav-menu ml-5 mb-4 mb-lg-0 ml-lg-0" id="navbarContent">
                                <ul className="navbar-nav">            
                        
                                    <li className="nav-item align-self-lg-center" id="communiques" onClick={this.openNewInscriptionClient} style={{cursor: "pointer"}}>
                                        <div className="nav-link" >
                                            Auto-Enseigner/Equiv-diplome
                                        </div>
                                    </li>
                        
                                        
                                    <li className="nav-item  align-self-lg-center" id="formations">
                                        <a className="nav-link" >
                                            Demande de création d'IPES
                                        </a>
                                    </li>
                        
                                    <li className="nav-item align-self-lg-center" id="communiques">
                                        <a className="nav-link" >
                                            Bourse doctorat
                                        </a>
                                    </li>
                                    <li className="nav-item align-self-lg-center" id="communiques" onClick={this.openNewInscription} style={{cursor: "pointer"}}>
                                        <div className="nav-link" >
                                            Créer compte étudiant
                                        </div>
                                    </li>
                                    {/*<li className="nav-item align-self-lg-center" id="communiques" onClick={this.openNewInscriptionClient} style={{cursor: "pointer"}}>
                                        <div className="nav-link" >
                                            Auto-Enseigner/Equiv-diplome
                                        </div>
                                    </li>*/}
                                    <li className="nav-item  align-self-lg-center" id="connexion" onClick={this.clickConnexion} style={{cursor: "pointer"}}>
                                        <div className="nav-link" >
                                            Connexion
                                            <img src="/assets/layout/images/unknown_avantar.png" width="" alt="Avatar" className="img-fluid"/>
                                        </div>
                                    </li>
                                </ul> 
                            </div>
                        </nav>
                        }
                        
    <div className="slider">
        <div className="sp-slides" id="">
        {<SlideShow/>}
        </div>
    </div>

    <div className="section planning" style={{overflow:"hidden"}}>
    <div className="row">
        
            <div className="col-md-4 p-0 separation">
                <div className="rrl rr-left active">
                    <div className="p-4">
                        <h4 className="w-75">200 bourses pour des études doctorales</h4>
                        <p className="w-75">Appel à candidature pour le programme d’octroi de deux cent (200) bourses du 12 Decembre 2021 au 03 Janvier 2022</p>
                    </div>
                </div>
            </div>
        
        
            <div className="col-md-4 separation" style={{padding: "0px 2px 0px 2px"}}>
                <div className="rrc rr-center">
                    <div className="p-3">
                        <h4 className="couleur-titre-flash">Candidatures CEFORGRIS</h4>
                        <p className="w-75">
                            Recrutement d'etudiants en première année de Masters professionnels au Centre d'Excellence CEFORGRIS à UJKZ du 06 au 31 Decembre 2021, 
                            les details <a href="https://web.facebook.com/campusfaso" target="__blink">https://web.facebook.com/campusfaso</a>
                            
                        </p>
                        <br/>
                    </div>
                </div>
            </div>
        
            <div className="col-md-4 p-0">
                <div className="rrr rr-right">
                    <div className="p-3">
                        <h4 className="couleur-titre-flash">Réinscriptions en cours</h4>
                        <p className="w-75">
                            Des sessions de réinscription dans les universités publiques et privées sont actuellement en cours sur la plateforme.
                        </p>
                        
                    </div>
                </div>
            </div>
        
    </div>
</div>

<div className="sections" style={{overflow:"hidden"}}>
    <div className="row">
        <div className="col-md-8">
            <div className="notification notification-error" >
                
                Communique d’appel à candidature pour le programme d’octroi de deux cent (200) bourses pour des études doctorales au titre de l’année académique 2021-2022 dans les universités publiques du Burkina Faso.
                <br/>
                <strong style={{color: "#fbd400"}}>
                    
                    Les dossiers sont reçus via le menu « Candidature » du 12 decembre 2021 au 03 janvier 2022 
                    <br/>
                </strong>
            </div>

           
            <div className="row" style={{padding: "0 15px"}} >
                <div className="col-sm-12">
                    <div className="row" style={{padding: "0 15px"}}>
                        
                        
                        
                        <div className="col-md-12 actu-content">
                            <img src="/assets/layout/images/no_thumb.jpeg" alt="" align="left" style={{border: "solid 1px #00a141", marginRight: "10px"}}/>

                            <strong style={{fontSize: "1.2rem"}}>
                                Communiqué relatif à l’appel à candidature pour le programme d’octroi de deux cent (200) bourses pour des études doctorales au titre de l’année académique 2021-2022 dans les universités publiques du Burkina Faso
                                ( <a href="/communique_candidature_doctorants.pdf" target="__blink">Télécharger le communiqué</a> )
                            </strong>
                            <br/>
                            <p> 
                                
                                
                            </p>
                            
                            <p>
                                Les canevas suivants sont à télécharger et utiliser comme modèle de présentation des documents concernés : 
                            </p>

                            <ul>
                                <li><a href="/annexes/1_Canevas_Candidature_Formulaire_PAES_Appel_Bourses_Doctorales_2021.docx">Formulaire de candidature</a></li>
                                <li><a href="/annexes/2_Canevas_CV_DoctorantCandidature_Annexe.docx">Curriculum vitae du doctorant</a></li>
                                <li><a href="/annexes/3_Canevas_CV_Equipe_encadrement_de_la_these_Candidature_Annexe_3b.docx">Curriculums vitae des membres de l'équipe de projet de recherche</a></li>
                                <li><a href="/annexes/4_Canevas_Chronogramme_activites_PAES_Appel_Bourses_doctorales_2021.xlsx">Chronogramme des activités</a></li>
                            </ul>
                            
                        </div>
                        
                        
                        <div className="notification notification-error">
                            
                            Communiqué portant recrutement au Centre d'Etudes, de Formation et de Recherche en Gestion des Risques Sociaux (CEFORGRIS) de l'Universite Joseph KI ZERBO, d'etudiants en Master professionnel
                            <br/>
                            <strong style={{color: "#fbd400"}}>
                                
                                Session de candidature du 06 au 31 Decembre 2021
                                <br/>
                            </strong>
                        </div>
                        
                        <div className="col-md-12 actu-content">
                            <img src="/assets/layout/images/no_thumb.jpeg" alt="" align="left" style={{border: "solid 1px #00a141", marginRight: "10px"}}/>

                            <strong style={{fontSize: "1.2rem"}}>
                                Communiqués portant recrutement au Centre d'Etudes, de Formation et de Recherche en Gestion des Risques Sociaux (CEFORGRIS) de l'Universite Joseph KI ZERBO, d'etudiants en Master professionnel
                            </strong>
                            <br/>
                            <br/>

                            <p> 
                                Les différents communiqués sont disponibles en téléchargement ci-bas :
                            <ul>
                                <li><a href="/ceforgris/spdd.pdf" target="__blink">Sociologie et Pratiques de Developpement Durable</a></li>
                                <li><a href="/ceforgris/dps.pdf" target="__blink">Developpement et Protection sociale</a></li>
                                <li><a href="/ceforgris/egris.pdf" target="__blink">Evaluation et Gestion des Risques et Impacts Sociaux</a></li>
                                <li><a href="/ceforgris/intermediation.pdf" target="__blink">Intermediation sociale et Intervention de developpement</a></li>
                                <li><a href="/ceforgris/rnsr.pdf" target="__blink">Ressources naturelles, Societes et Risques </a></li>
                            </ul>
                            </p>
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div className="col-md-4 no-padding">
            <div className="need-help">
                <h2>Besoin d'aide ?</h2>
                <div className="help-item clearfix">
                    <div className="help-icon d-block d-md-none d-lg-block">
                        <i className="fa fa-phone-square fa-3x"></i>
                    </div>
                    <div className="help-text">
                        (+226)  07 76 76 76 / 52 36 69 71 / 58 50 56 55 <br/>
                        <strong style={{fontSize: ".8rem", fontStyle: "italic"}}>Tous les jours ouvrables de 8h:00 à 18h:00</strong>
                    </div>
                </div>

                <div className="help-item clearfix">
                    <div className="help-icon  d-block d-md-none d-lg-block">
                        <i className="fa fa-at fa-3x"></i>
                    </div>
                    <div className="help-text">
                        orientation@campusfaso.bf <br/>
                        campusfaso@gmail.com
                    </div>
                </div>

               { /*<div className="help-item clearfix">
                    <div className="help-icon  d-block d-md-none d-lg-block">
                        <i className="fa fa-facebook-square fa-3x"></i>
                    </div>
                    <div className="help-text">
                        Sur les réseaux sociaux <a href="https://web.facebook.com/campusfaso" target="_blank">Facebook</a>, <a href="https://twitter.com/campusfaso?s=08" target="_blank">Twitter</a>, 
                        <a href="https://www.youtube.com/channel/UCssU0t-lqKv1qx7mmi0z_SQ" target="_blank">Youtube</a>, <a href="https://www.linkedin.com/in/campus-faso-536785219" target="_blank">LinkedIn</a> et
                        <a href="https://www.instagram.com/invites/contact/?i=1c5p8tffphtyp&utm_content=mguf3ox" target="_blank">Instagram</a>
                    </div>
                </div>*/ }
            </div>

            {/*<div className="row" style={{padding: "0px 20px 20px 0px"}}>
                <div className="col-md-12" style={{margin: "20px auto"}}>  
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/rZS0hkAhizM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>*/}
        </div>
    </div>
</div> 

<div className="sections">
    <div className="section-inner clearfix" style={{"marginBottom": 15}}>
        <div className="title">Procédures d'orientation</div>
        <div className="box-content">
            <ul className="box-items box-items__3-column">
                <li className="box-item box-shadow mb-3 mb-lg-0">
                    <a href="https://www.campusfaso.bf/candidat/acceder-compte">
                        <div className="box-item-top-border"></div>
                        <div className="box-item-content">
                            <img className="lazyloaded" data-src="#" alt="" width="75" src="/images/account_icon.png"/>
                            <h4>Orientation</h4>
                            <p>
                                Ouvrir ou créer un compte CampusFaso pour gérer votre dossier de candidature et suivre votre orientation.
                            </p>
                        </div>
                    </a>
                </li>
                <li  className="box-item box-shadow mb-3 mb-lg-0">
                    <a href="http://ordinateur.campusfaso.bf/">
                        <div className="box-item-top-border"></div>
                        <div className="box-item-content">
                            <img className="lazyloaded" data-src="#" alt="" width="75" src="/images/ordinateur.png"/>
                            <h4>Un étudiant un ordinateur</h4>
                        </div>
                    </a>
                </li>
                <li className="box-item box-shadow mb-3 mb-lg-0">
                    <a href="/candidat/faq"> 
                        <div className="box-item-top-border"></div>
                        <div className="box-item-content">
                            <img className="lazyloaded" data-src="#" alt="" width="75" src="/images/guide_icon.png"/>
                            <h4>Guide</h4>
                            <p>
                                Vous souhaitez candidater ? Notre guide vous conduira pas à pas.
                            </p>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
  
<div className="sections footers">
        <div className="section-inners clearfix">
            <div className="row">
                <div className="col-md-4 col-sm-6 box" >
                    <div className="footer-armoiries hidden-xs">
                        <img src="/images/burkinafaso.png" alt="burkina faso" height="70px" />
                    </div>
                    <div className="footer-ministeres">
                        <h4>Burkina Faso</h4>
                        <p>
                            Ministère de l'Enseignement Superieur de la Recherche Scientifique et de l'Innovation
                        </p>
                    </div>
                </div> 

                <div className="col-md-3 box" >
                    <h4 className="footer-box-title">Informations légales</h4>
                    <ul className="no-padding">
                        <li><a href="/candidat/mentions-legales" className="link-text">Mentions Légales</a></li>
                        <li><a href="/candidat/confidentialite" className="link-text">Engagement de Confidentialité</a></li>
                        <li><a href="/candidat/protection-donnees" className="link-text">Protection des donn&eacute;es &agrave; caract&egrave;re personnel</a></li>
                        <li><a href="/candidat/faq" className="link-text">FAQ</a></li>
                    </ul>
                </div>

                <div className="col-md-2 box">
                    <h4 className="footer-box-title">Liens Utiles</h4>
                    <ul style={{color:"#fff"}} className="no-padding">
                        <li><a href="http://www.mesrsi.gov.bf" target="_blank" className="link-text">mesrsi.gov.bf</a></li>
                        <li><a href="http://www.mena.gov.bf" target="_blank" className="link-text">mena.gouv.bf</a></li>
                        <li><a href="http://www.ciospb.bf/" target="_blank" className="link-text">ciospb.bf</a></li>
                        <li><a href="http://foner-bf.com" target="_blank" className="link-text">foner.bf</a></li>
                    </ul>
                </div>

                <div className="col-md-3 box" >
                    <h4 className="footer-box-title">Nous Contacter</h4>
                    <div className="small-text">
                        <strong>Téléphone : </strong> <br/>
                        (+226)  07 76 76 76 / 52 36 69 71 / 58 50 56 55 <br/>
                        <span className="small" style={{fontStyle: "italic"}}>Tous les jours ouvrables de 8h00 à 18h00</span>
                        <br/><br/>
                        <strong>Email : </strong> <br/>
                        <a href="#" className="link-text">orientation@campusfaso.bf</a> <br/>
                        <a href="#" className="link-text">campusfaso@gmail.com</a>
                        <br/><br/>
                        <ul className="footer-social list-inline link-text">
                            <li><a href="#"><i className="fa fa-youtube-play link-text" ></i></a></li>
                            <li><a href="#"><i className="fa fa-facebook-square link-text" ></i></a></li>
                            <li><a href="#"><i className="fa fa-twitter link-text" ></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="sections sub-footers">
        <div className="footer-inner">
            © 2018-2019 - CampusFaso - Ministère de l'Enseignement Supérieur, de la Recherche Scientitifique et de l'Innovation
        </div>
    </div>

    {/*!this.state.token ?                      
    <div className="app">
        <div className="app-inner">
            <header className="header">
                <div className="logo-mobile">
                    <a href="" className="header-logo"> 
                        <img style={{width : 200, height:45}}  className="logo"  src="/assets/layout/images/CampusFaso_Logo.png" alt="CampusFaso"/>
                    </a> 
                </div>
                <div className="header-desktop">
                    <div className="header-top">
                        <ul className="nav">
                            <li><a href="http://hebergement2.campusfaso.bf/">Orientations</a></li>
                            <li><a href="http://hebergement2.campusfaso.bf/" target="_blank">Hébergement</a></li>
                            <li><a href="http://ordinateur.campusfaso.bf/" target="_blank">1Etudiant1Ordinateur</a></li>
                            <li onClick={this.clickConnexion}><div style={{color:"white", fontWeight:"bold"}}>Connexion</div></li>
                        </ul>
                    </div>
                    <div className="header-main">
                        <a href="" className="header-logo"> 
                            <img style={{width : 200, height:45}}  className="logo"  src="/assets/layout/images/CampusFaso_Logo.png" alt="CampusFaso"/>
                        </a> 
                        <div className="header-main-menu">
                            <ul className="nav">
                                <li><a href="">Accueil</a></li>
                                <li><a href="">Candidatures</a></li>
                                <li><a href="">Communiqués</a></li>
                                <li><a href="">Trouver une filière</a></li>
                                <li><a href="">FAQ</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="header-space"></div>
                <div className="header-mobile">
                    <svg id="menu-open" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                      </svg>                      
                </div>
            </header>
            <div className="main">
                <div className="flash"></div> 
               
            </div> 
            <footer>
                <div className="footer-menu">
                    <div className="ft-menu-right">
                        <ul>
                            <li><a href="">Mentions légales</a></li>
                            <li><a href="">Engagement confidentialité</a></li>
                            <li><a href="">Protection des données</a></li>
                        </ul>
                    </div>
                    <div className="ft-menu-social">
                        <ul className="socials">
                            <li><a href="" title="Facebook"><i className="fa-brands fa-square-facebook"></i></a></li>
                            <li><a href="" title="Twitter"><i className="fa-brands fa-square-twitter"></i></a></li>
                            <li><a href="" title="Instagram"><i className="fa-brands fa-square-instagram"></i></a></li>
                            <li><a href="" title="Youtube"><i className="fa-brands fa-square-youtube"></i></a></li>
                            <li><a href="" title="TikTok"><i className="fa-brands fa-tiktok"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div className="footer-copy">
                    <div>
                        &copy; 2018 - 2024 - Gouvernement du Burkina Faso - CampusFaso. Tous droits réservés.
                    </div>
                </div>
            </footer>
        </div> 
    </div> 
    :
    <div className="app">
        <div className="app-inner">
            <header className="header">
                <div className="logo-mobile">
                    <a href="" className="header-logo"> 
                        <img style={{width : 200, height:45}}  className="logo"  src="/assets/layout/images/CampusFaso_Logo.png" alt="CampusFaso"/>
                    </a> 
                </div>
                <div className="header-desktop">
                    <div className="header-top">
                        <ul className="nav">
                            <li><a href="http://hebergement2.campusfaso.bf/">Orientations</a></li>
                            <li><a href="http://hebergement2.campusfaso.bf/" target="_blank">Hébergement</a></li>
                            <li><a href="http://ordinateur.campusfaso.bf/" target="_blank">1Etudiant1Ordinateur</a></li>
                            <li onClick={this.clickDeconnexion}><div className="nav-link" >Deconnexion</div></li>
                        </ul>
                    </div>
                    <div className="header-main">
                        <a href="" className="header-logo"> 
                            <img style={{width : 200, height:45}}  className="logo"  src="/assets/layout/images/CampusFaso_Logo.png" alt="CampusFaso"/>
                        </a> 
                        <div className="header-main-menu">
                            <ul className="nav">
                                <li><a href="">Accueil</a></li>
                                <li><a href="">Candidatures</a></li>
                                <li><a href="">Communiqués</a></li>
                                <li><a href="">Trouver une filière</a></li>
                                <li><a href="">FAQ</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="header-space"></div>
                <div className="header-mobile">
                    <svg id="menu-open" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                      </svg>                      
                </div>
            </header>
            <div className="main">
                <div className="flash"></div> 
               
            </div> 
            <footer>
                <div className="footer-menu">
                    <div className="ft-menu-right">
                        <ul>
                            <li><a href="">Mentions légales</a></li>
                            <li><a href="">Engagement confidentialité</a></li>
                            <li><a href="">Protection des données</a></li>
                        </ul>
                    </div>
                    <div className="ft-menu-social">
                        <ul className="socials">
                            <li><a href="" title="Facebook"><i className="fa-brands fa-square-facebook"></i></a></li>
                            <li><a href="" title="Twitter"><i className="fa-brands fa-square-twitter"></i></a></li>
                            <li><a href="" title="Instagram"><i className="fa-brands fa-square-instagram"></i></a></li>
                            <li><a href="" title="Youtube"><i className="fa-brands fa-square-youtube"></i></a></li>
                            <li><a href="" title="TikTok"><i className="fa-brands fa-tiktok"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div className="footer-copy">
                    <div>
                        &copy; 2018 - 2024 - Gouvernement du Burkina Faso - CampusFaso. Tous droits réservés.
                    </div>
                </div>
            </footer>
        </div> 
    </div> 
    */}
                       
</div>
            )
        }
        
    }
}
export default Connexion
