import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';


export class InvitationService {

    constructor(){
        this.adresse = new AdresseIP()
    }

    enregistrerInvitation(newInvitation){

        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/invitation-enseignant"
        return axios.post(url, newInvitation)
                .then(res => res.data)
    }

    getListeInvitationSortantDepartement(idDepartement){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/invitation-enseignant?idDepartement="+idDepartement
        return axios.get(url)
                .then(res => res.data)
    }

    getListeInvitationSortantDepartementVp(idIesr, username){

        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/invitation-enseignant?idIesr="+idIesr+"&username="+username
        return axios.get(url)
                .then(res => res.data)
    }

    deleteInvitation(idInvitation){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/invitation-enseignant/"+idInvitation
        return axios.patch(url)
                .then(res => res.data)
    }

    updateInvitationEnseignant(idInvitation, invitationObject){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/invitation-enseignant/"+idInvitation
        return axios.put(url, invitationObject)
                .then(res => res.data)
    }

    rejetInvitation(username, invitationObject){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/rejet-invitation?username="+username
        return axios.put(url, invitationObject)
                .then(res => res.data)
    }

    getListeBordereauVp(userName, etapeBe){

        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/be-entrant?posBe="+userName+"&etapeBe="+etapeBe
        return axios.get(url)
                .then(res => res.data)
    }

    getListeInvitationEntrantDepartement(idDepartement){

        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/invitation-enseignant-entrant?idDepartement="+idDepartement
        return axios.get(url)
                .then(res => res.data)
    }

    getListeInvitationEnseignant(idEnseignant){

        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/invitation-enseignant-entrant?idEnseignant="+idEnseignant
        return axios.get(url)
                .then(res => res.data)
    }

    getListeBordero(positionBordereau, etapeBe){

        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/be?posBe="+positionBordereau+"&etapeBe="+etapeBe
        return axios.get(url)
                .then(res => res.data)
    }


    getListeBorderoUniversite(positionBordereau){

        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/be?posBe="+positionBordereau
        return axios.get(url)
                .then(res => res.data)
    }



    getListeAutresIesr(idDepartement){

        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/iesr-autres?idDepartement="+idDepartement
        return axios.get(url)
                .then(res => res.data)
    }


    enregistrerBordereau(newBordereau){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/be"
        return axios.post(url, newBordereau)
                .then(res => res.data)
    }


    editionBordereau(idBordereau, bordereau){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/be/"+idBordereau
        return axios.put(url, bordereau)
                .then(res => res.data)
    }


    transmissionBe(username, bordereau){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/validation-be?username="+username
        return axios.put(url, bordereau)
                .then(res => res.data)
    }


    approbationBe(username, bordereau){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/validation-be?username="+username
        return axios.put(url, bordereau)
                .then(res => res.data)
    }


    listeDepartementParametreUtilisateur(idIesr){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/acteur?idIesr="+idIesr
        return axios.get(url)
                .then(res => res.data)
    }


    enregistrerHierarchieUtilisateur(hierarchie){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/acteur"
        return axios.post(url, hierarchie)
                .then(res => res.data)
    }



    editionActeur(idActeur, acteur){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/acteur/"+idActeur
        return axios.put(url, acteur)
                .then(res => res.data)
    }

    suppressionActeur(idActeur){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/acteur/"+idActeur
        return axios.patch(url)
                .then(res => res.data)
    }

    getSignataireIesr(idIesr){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/signataire?idIesr="+idIesr
        return axios.get(url)
                .then(res => res.data)
    }


    updateSignataire(idSignataire, signataire){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/signataire/"+idSignataire
        return axios.put(url, signataire)
                .then(res => res.data)
    }


    saveSignataire(signataire){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/signataire"
        return axios.post(url, signataire)
                .then(res => res.data)
    }


}