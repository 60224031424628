import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Notify } from "../../components/Notify";
import { ToastContainer } from "react-toastify";
import { Spinner } from "../../components/Spinner";
import { InscriptionService } from "../service/InscriptionService";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Infrastructures from "../../volumeHoraire/components/parametres/Infrastructures";

export class InscriptionPedagogique extends Component {
    constructor(props) {
        super(props);

        this.notify = new Notify();
        this.spinner = new Spinner();
        this.ModelInscriptionService = new InscriptionService();
        this.conformite = React.createRef();
        this.franchise = React.createRef();
        this.rfPhoto = React.createRef();

        //On suppose que l'idEtudiant est 3368 (A recuperer à la long dans le token)
        let idEtudiant = 3368;
        this.state = {
            etudiant: {},
            listeOption: [],
            globalFilter: null,
            payDialog: false,
            loading: true,
            inscriptionDialog: false,
            submitted: false,
            operateur: "",
            franchises: false,
            sessionChoix: {},
            idEtudiant: idEtudiant,
            listeECSemestre1: [],
            listeECSemestre2: [],
            listeECOptionnel1: [],
            listeECOptionnel2: [],
            listeECChoisi1: [],
            listeECChoisi2: [],
            nombreECChoix1: null,
            nombreECChoix2: null,
            listeSelection: null,
            existeOption: true,
            existeOption1: true,
            existeOption2: true,
            franchisesOk: false,
            informationEtudiant: {},
            photo: null,
            extension: null,
            errorFile: "",
            titreFenetre: "",
            valideMail: true,
            dejaInscrit: false,
            loadingDetailInscription: true,
            existeUEOptionS1: false,
            existeUEOptionS2: false,
            listeUEOptionS1: [],
            listeUEOptionS2: [],
            selectionUe1: null,
            listeEcUeOptionnel1: [],
            listeEcUeOptionnel2: [],
        };
    }

    componentDidMount() {
        const tokenDecoded = localStorage.getItem("tokenDecoded");
        const token = JSON.parse(tokenDecoded);

        this.setState({ loading: true });
        //console.log(this.state.loading)
        this.ModelInscriptionService.testerExistanceSessionInscriptionPedagogique(
            this.state.idEtudiant
        )
            .then((data) => {
                if (data) {
                    let inscription = [data];
                    this.setState({
                        listeOption: inscription,
                        loading: false,
                        loadingDetailInscription: false,
                    });
                } else {
                    //On fait appel aux informations concernant une eventuelle inscription déjà faite. Puis jouer sur la variable dejaInscrit
                    this.ModelInscriptionService.testerInscriptionPedagogiqueEtudiant(
                        this.state.idEtudiant
                    )
                        .then((data) => {
                            //console.log(data)

                            //console.log(this.state.loadingDetailInscription)
                            this.setState({
                                dejaInscrit: true,
                            });
                            let ecSemestre1 =
                                data.listeEcObligatoirePremierSemestre;
                            if (
                                data.listeEcOptionnelPremierSemestre.length > 0
                            ) {
                                ecSemestre1 = ecSemestre1.concat(
                                    data.listeEcOptionnelPremierSemestre
                                );
                            }

                            let ecSemestre2 =
                                data.listeEcObligatoireDeuxiemeSemestre;
                            if (
                                data.listeEcOptionnelDeuxiemeSemestre.length > 0
                            ) {
                                ecSemestre2 = ecSemestre2.concat(
                                    data.listeEcOptionnelDeuxiemeSemestre
                                );
                            }

                            this.setState({
                                listeECSemestre1: ecSemestre1,
                                listeECSemestre2: ecSemestre2,
                                photo: data.photo,
                                extension: data.extensionPhoto,
                                listeOption: [],
                                loading: false,
                                dejaInscrit: true,
                                loadingDetailInscription: false,
                                universite: data.nomIesr,
                                ufr: data.nomDepartement,
                                parcours: data.libelleParcours,
                                niveau: data.libelleNiveau,
                                anneeAcademique: data.libelleAnnee,
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                loading: false,
                                loadingDetailInscription: false,
                            });
                        });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    loadingDetailInscription: false,
                });
            });

        this.ModelInscriptionService.getInformationEtudiant(
            this.state.idEtudiant
        )
            .then((data) => {
                this.setState({
                    etudiant: data[0],
                    informationEtudiant: data[0],
                });
            })
            .catch((error) => {
                console.log(error);
            });

        this.setState({
            loading: false,
        });
    }

    optionInscription = (sessionInscription) => {
        this.ModelInscriptionService.getInformationInscriptionPedagogique(
            this.state.idEtudiant,
            sessionInscription.idSessionInscription
        )
            .then((data) => {
                console.log(data);

                this.setState({
                    listeECSemestre1: data.listeEcObligatoirePremierSemestre,
                    listeECSemestre2: data.listeEcObligatoireDeuxiemeSemestre,
                    listeECOptionnel1: data.listeEcOptionnelPremierSemestre,
                    listeECOptionnel2: data.listeEcOptionnelDeuxiemeSemestre,
                    existeOption:
                        data.listeEcOptionnelPremierSemestre.length > 0 ||
                        data.listeEcOptionnelDeuxiemeSemestre.length > 0,
                    existeOption1:
                        data.listeEcOptionnelPremierSemestre.length > 0,
                    existeOption2:
                        data.listeEcOptionnelDeuxiemeSemestre.length > 0,
                    inscriptionDialog: true,
                    sessionChoix: sessionInscription,
                    nombreECChoix1: data.nbEcOptionnelChoixPremierSemestre,
                    nombreECChoix2: data.nbEcOptionnelChoixDeuxiemeSemestre,
                    titreFenetre:
                        "Détails inscription pédagogique | " +
                        sessionInscription.libelleParcours +
                        " - " +
                        sessionInscription.libelleNiveau +
                        " - " +
                        sessionInscription.libelleAnneeAcademique +
                        " ( " +
                        sessionInscription.libelleIesr +
                        " )",
                    existeUEOptionS1:
                        data.listeUeOptionnelPremierSemestre.length > 0,
                    existeUEOptionS2:
                        data.listeUeOptionnelDeuxiemeSemestre.length > 0,
                    listeUEOptionS1: data.listeUeOptionnelPremierSemestre,
                    listeUEOptionS2: data.listeUeOptionnelDeuxiemeSemestre,
                });
            })
            .catch((error) => {
                console.log(error);
                this.notify.echec(error.response.data.message);
            });
    };

    hideDialog = () => {
        this.setState({
            inscriptionDialog: false,
            operateur: "",
            franchises: false,
            sessionChoix: {},
            nombreECChoix1: 0,
            nombreECChoix2: 0,
            listeECOptionnel1: [],
            listeECOptionnel2: [],
            listeECSemestre1: [],
            listeECSemestre2: [],
            existeOption: false,
            existeOption1: false,
            existeOption2: false,
            photo: null,
            extension: null,
            existeUEOptionS1: false,
            existeUEOptionS2: false,
            listeUEOptionS1: [],
            listeUEOptionS2: [],
            listeEcUeOptionnel1: [],
            listeEcUeOptionnel2: [],
        });
    };

    selectionChoixEC1 = (ecChoix) => {
        let listeECChoisi1 = this.state.listeECChoisi1;
        if (listeECChoisi1.length < this.state.nombreECChoix1) {
            let listeIdECChoix = listeECChoisi1.map((element) => {
                return element.idElementConstitutif;
            });

            if (listeIdECChoix.indexOf(ecChoix.idElementConstitutif) !== -1) {
                alert("Vous avez déjà choisi cet Element constitutif ");
            } else {
                listeECChoisi1.push(ecChoix);
            }

            this.setState({
                listeECChoisi1: listeECChoisi1,
            });
        } else {
            alert("Vous avez déjà atteint le nombre de choix réquis");
        }
    };

    selectionUEOptionS1 = (data) => {
        this.setState({
            listeEcUeOptionnel1: data.listeElementConstitutif,
        });
    };

    selectionUEOptionS2 = (data) => {
        this.setState({
            listeEcUeOptionnel2: data.listeElementConstitutif,
        });
    };

    selectionChoixEC2 = (ecChoix) => {
        let listeECChoisi2 = this.state.listeECChoisi2;
        if (listeECChoisi2.length < this.state.nombreECChoix2) {
            let listeIdECChoix = listeECChoisi2.map((element) => {
                return element.idElementConstitutif;
            });

            if (listeIdECChoix.indexOf(ecChoix.idElementConstitutif) !== -1) {
                alert("Vous avez déjà choisi cet' Element constitutif ");
            } else {
                listeECChoisi2.push(ecChoix);
            }

            this.setState({
                listeECChoisi2: listeECChoisi2,
            });
        } else {
            alert("Vous avez déjà atteint le nombre de choix réquis");
        }
    };

    annulationChoixEC1 = (ecChoix) => {
        let listeECChoisi1 = this.state.listeECChoisi1;

        let listeNewECChoisi1 = listeECChoisi1.filter((element) => {
            return (
                element.idElementConstitutif !== ecChoix.idElementConstitutif
            );
        });

        this.setState({
            listeECChoisi1: listeNewECChoisi1,
        });
    };

    annulationChoixEC2 = (ecChoix) => {
        let listeECChoisi2 = this.state.listeECChoisi2;

        let listeNewECChoisi2 = listeECChoisi2.filter((element) => {
            return (
                element.idElementConstitutif !== ecChoix.idElementConstitutif
            );
        });

        this.setState({
            listeECChoisi2: listeNewECChoisi2,
        });
    };

    inscriptionPedagogiqueEtudiant = () => {
        this.setState({
            submitted: true,
        });

        let valideEtudiant =
            this.state.valideMail &&
            this.state.informationEtudiant.telephone &&
            this.state.informationEtudiant.contactPersonnePrevenir &&
            this.state.informationEtudiant.personnePrevenir &&
            this.state.informationEtudiant.personnePrevenir.length >= 4
                ? true
                : false;

        let valideChoixEC =
            (!this.state.existeOption1 ||
                this.state.listeECChoisi1.length ===
                    this.state.nombreECChoix1) &&
            (!this.state.existeOption2 ||
                this.state.listeECChoisi2.length === this.state.nombreECChoix2)
                ? true
                : false;

        let valideChoixUEOptionnel =
            (!this.state.existeUEOptionS1 ||
                (this.state.existeUEOptionS1 &&
                    this.state.listeEcUeOptionnel1.length > 0)) &&
            (!this.state.existeUEOptionS2 ||
                (this.state.existeUEOptionS2 &&
                    this.state.listeEcUeOptionnel2.length > 0))
                ? true
                : false;

        let validitePhoto =
            (this.rfPhoto.current &&
                this.rfPhoto.current.naturalWidth === 99 &&
                this.rfPhoto.current.naturalHeight === 128) ||
            (this.rfPhoto.current &&
                this.rfPhoto.current.naturalWidth === 138 &&
                this.rfPhoto.current.naturalHeight === 177) ||
            (this.rfPhoto.current &&
                this.rfPhoto.current.naturalWidth === 207 &&
                this.rfPhoto.current.naturalHeight === 266)
                ? true
                : false;

        if (
            valideEtudiant &&
            valideChoixEC &&
            validitePhoto &&
            valideChoixUEOptionnel
        ) {
            /*Preparation de l'objet pour envoi*/
            let listeECInscriptionOblibatoire =
                this.state.listeECSemestre1.concat(this.state.listeECSemestre2);

            if (this.state.existeOption1) {
                listeECInscriptionOblibatoire =
                    listeECInscriptionOblibatoire.concat(
                        this.state.listeECChoisi1
                    );
            }

            if (this.state.existeOption2) {
                listeECInscriptionOblibatoire =
                    listeECInscriptionOblibatoire.concat(
                        this.state.listeECChoisi2
                    );
            }

            if (this.state.existeUEOptionS1) {
                listeECInscriptionOblibatoire =
                    listeECInscriptionOblibatoire.concat(
                        this.state.listeEcUeOptionnel1
                    );
            }

            if (this.state.existeUEOptionS2) {
                listeECInscriptionOblibatoire =
                    listeECInscriptionOblibatoire.concat(
                        this.state.listeEcUeOptionnel2
                    );
            }

            let objetReinscription = {
                idSessionInscription:
                    this.state.sessionChoix.idSessionInscription,
                etudiant: this.state.informationEtudiant,
                photo: this.state.photo,
                extensionPhoto: this.state.extension,
                listeElementConstitutif: listeECInscriptionOblibatoire,
            };

            //console.log(objetReinscription);

            this.ModelInscriptionService.inscrireEtudiant(objetReinscription)
                .then((data) => {
                    this.notify.success();

                    this.setState({ loading: true });
                    this.ModelInscriptionService.testerExistanceSessionInscriptionPedagogique(
                        this.state.idEtudiant
                    )
                        .then((data) => {
                            //console.log(data)

                            if (data) {
                                let inscription = [data];
                                this.setState({
                                    listeOption: inscription,
                                    loading: false,
                                });
                            } else {
                                //On fait appel aux informations concernant l'inscription déjà faite. Puis jouer sur la variable dejaInscrit
                                this.ModelInscriptionService.testerInscriptionPedagogiqueEtudiant(
                                    this.state.idEtudiant
                                )
                                    .then((data) => {
                                        //console.log(data)
                                        this.setState({
                                            dejaInscrit: true,
                                            listeOption: [],
                                        });

                                        let ecSemestre1 =
                                            data.listeEcObligatoirePremierSemestre;
                                        if (
                                            data.listeEcOptionnelPremierSemestre
                                                .length > 0
                                        ) {
                                            ecSemestre1 = ecSemestre1.concat(
                                                data.listeEcOptionnelPremierSemestre
                                            );
                                        }

                                        let ecSemestre2 =
                                            data.listeEcObligatoireDeuxiemeSemestre;
                                        if (
                                            data
                                                .listeEcOptionnelDeuxiemeSemestre
                                                .length > 0
                                        ) {
                                            ecSemestre2 = ecSemestre2.concat(
                                                data.listeEcOptionnelDeuxiemeSemestre
                                            );
                                        }

                                        this.setState({
                                            listeECSemestre1: ecSemestre1,
                                            listeECSemestre2: ecSemestre2,
                                            photo: data.photo,
                                            extension: data.extensionPhoto,
                                            listeOption: [],
                                            loading: false,
                                            dejaInscrit: true,
                                            universite: data.nomIesr,
                                            ufr: data.nomDepartement,
                                            parcours: data.libelleParcours,
                                            niveau: data.libelleNiveau,
                                            anneeAcademique: data.libelleAnnee,
                                        });
                                    })
                                    .catch((error) => {
                                        //Erreur lors de la recupération de l'inscription pédagogique
                                        console.log(error);
                                    });
                            }
                        })
                        .catch((error) => {
                            //Erreur lors de l'inscription
                            console.log(error);
                            this.setState({
                                loading: false,
                            });
                        });

                    this.setState({
                        inscriptionDialog: false,
                        operateur: "",
                        franchises: false,
                        sessionChoix: {},
                        nombreECChoix1: 0,
                        nombreECChoix2: 0,
                        listeECOptionnel1: [],
                        listeECOptionnel2: [],
                        listeECSemestre1: [],
                        listeECSemestre2: [],
                        existeOption: false,
                        existeOption1: false,
                        existeOption2: false,
                        photo: null,
                        extension: null,
                        existeUEOptionS1: false,
                        existeUEOptionS2: false,
                        listeUEOptionS1: [],
                        listeUEOptionS2: [],
                        listeEcUeOptionnel1: [],
                        listeEcUeOptionnel2: [],
                    });
                })
                .catch((error) => {
                    this.notify.echec(error.response.data.message);
                    console.log(error);
                });

            //console.log(objetReinscription)
        } else {
            if (!validitePhoto && this.state.photo) {
                this.setState({
                    errorFile:
                        "La taille de la photo ne correspond à aucune des tailles au choix: (99px*128px); (138px*177px); (207px*266px))",
                });
            } else if (!this.state.photo) {
                this.setState({
                    errorFile: "Charger obligatoirement une photo valide !",
                });
            }
        }
    };

    onInputChange = (event, fieldName) => {
        let informationEtudiant = this.state.informationEtudiant;
        informationEtudiant[`${fieldName}`] = event.target.value;

        this.setState({
            informationEtudiant: informationEtudiant,
        });
    };

    onPhoneChange = (value, fieldName) => {
        let informationEtudiant = this.state.informationEtudiant;
        informationEtudiant[`${fieldName}`] = value;
        this.setState({
            informationEtudiant: informationEtudiant,
        });
    };

    onMailChange = (event, fieldName) => {
        let informationEtudiant = this.state.informationEtudiant;
        informationEtudiant[`${fieldName}`] = event.target.value;

        let regEmail =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        //console.log(regEmail.test(event.target.value))
        this.setState({
            informationEtudiant: informationEtudiant,
            valideMail: regEmail.test(event.target.value),
        });
    };

    chargerPhoto = (event) => {
        let file = event.target.files[0];

        if (file && file.size <= 5457280) {
            let extension = file.name.split(".").pop();
            const reader = new FileReader();
            reader.onload = (readerEvt) => {
                let binaryString = readerEvt.target.result;
                let fichier = btoa(binaryString);
                this.setState({
                    photo: fichier,
                    extension: extension,
                    errorFile: "",
                });
            };
            reader.readAsBinaryString(file);

            //console.log(this.rfPhoto.current)
        } else {
            let errorFile = "";

            if (!file) {
                errorFile = "Charger obligatoirement une photo valide !";
            } else if (file.size > 5457280) {
                errorFile = "La photo est très lourde !";
            }

            this.setState({
                errorFile: errorFile,
            });
        }
    };

    telechargerAttestation = () => {
        let requestAttestation = {
            reportFormat: "PDF",
            reportType: "ATTESTATION_INSCRIPTION",
            idEtudiant: this.state.etudiant.idEtudiant,
        };

        this.ModelInscriptionService.getAttestationInscriptionPedagogique(
            requestAttestation
        )
            .then((response) => {
                let blob = new Blob([response], { type: "application/pdf" });
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                let fileName = "Attestation_inscription_pédagogique";
                link.download = fileName;
                link.click();
            })
            .catch((error) => {
                console.log(error);
                this.notify.echec(error.response.data.message);
            });
    };

    confirmerFranchise = () => {
        this.setState({
            franchisesOk:
                this.conformite.current.checked &&
                this.franchise.current.checked
                    ? true
                    : false,
        });
    };

    actionBodyTemplateSession = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-check"
                    className="p-button-success"
                    style={{ marginRight: ".5em" }}
                    title="S'inscrire"
                    onClick={() => this.optionInscription(rowData)}
                />
            </React.Fragment>
        );
    };

    actionBodyECOption1 = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-check"
                    className="p-button-success"
                    style={{ marginRight: ".5em" }}
                    title="Choisir l'EC"
                    onClick={() => this.selectionChoixEC1(rowData)}
                />
            </React.Fragment>
        );
    };

    actionBodyUEOption1 = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-check"
                    className="p-button-success"
                    style={{ marginRight: ".5em" }}
                    title="Choisir l'UE"
                    onClick={() => this.selectionUEOptionS1(rowData)}
                />
            </React.Fragment>
        );
    };

    actionBodyUEOption2 = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-check"
                    className="p-button-success"
                    style={{ marginRight: ".5em" }}
                    title="Choisir l'UE"
                    onClick={() => this.selectionUEOptionS2(rowData)}
                />
            </React.Fragment>
        );
    };

    actionBodyECOption2 = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-check"
                    className="p-button-success"
                    style={{ marginRight: ".5em" }}
                    title="Choisir l'EC"
                    onClick={() => this.selectionChoixEC2(rowData)}
                />
            </React.Fragment>
        );
    };

    actionBodyAnnulerChoix1 = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-trash"
                    className="p-button-danger"
                    style={{ marginRight: ".5em" }}
                    title="Annuler le choix"
                    onClick={() => this.annulationChoixEC1(rowData)}
                />
            </React.Fragment>
        );
    };

    actionBodyAnnulerChoix2 = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-trash"
                    className="p-button-danger"
                    style={{ marginRight: ".5em" }}
                    title="Annuler le choix"
                    onClick={() => this.annulationChoixEC2(rowData)}
                />
            </React.Fragment>
        );
    };

    render() {
        let actionHeader = (
            <Button
                className="enteteDatatable"
                type="button"
                icon="pi pi-cog"
            />
        );

        const inscriptionFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideDialog}
                />
                {this.state.franchisesOk ? (
                    <Button
                        label="Valider"
                        icon="pi pi-check"
                        className="p-button-text"
                        onClick={this.inscriptionPedagogiqueEtudiant}
                    />
                ) : (
                    <Button
                        label="Valider"
                        icon="pi pi-check"
                        className="p-button-text"
                        disabled
                        onClick={this.inscriptionPedagogiqueEtudiant}
                    />
                )}
            </React.Fragment>
        );

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        {
                            <ToastContainer
                                position="top-right"
                                autoClose={2000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                        }

                        {this.state.loading ? (
                            <div className="p-grid">
                                <div className="p-col-6"></div>
                                <div className="p-col-6">
                                    {this.spinner.loadingSpinner(
                                        "spin",
                                        "black"
                                    )}
                                </div>
                            </div>
                        ) : this.state.listeOption.length > 0 ? (
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.state.listeOption}
                                selectionMode="single"
                                header="Liste des sessions d'inscription pédagogique concordant avec votre profile"
                                paginator={true}
                                rows={10}
                                globalFilter={this.state.globalFilter}
                                filterMatchMode="contains"
                                responsive={true}
                                selection={this.state.listeOption}
                                onSelectionChange={(event) =>
                                    this.setState({
                                        dataTableSelection1: event.value,
                                    })
                                }
                            >
                                <Column
                                    field="libelleParcours"
                                    header="Parcours"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="Rechercher un parcours"
                                />
                                <Column
                                    field="libelleAnneeAcademique"
                                    header="Année académique"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="rechercher une année"
                                />
                                <Column
                                    field="libelleNiveau"
                                    header="Niveau"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="rechercher un niveau"
                                />
                                <Column
                                    field="dateOuverture"
                                    header="Date ouverture"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="date ouverture"
                                />
                                <Column
                                    field="dateFermeture"
                                    header="Date fermeture"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="date fermeture"
                                />
                                <Column
                                    field="etatSession"
                                    header="Etat de la session"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="recherhcher un état"
                                />
                                <Column
                                    header={actionHeader}
                                    body={this.actionBodyTemplateSession}
                                    style={{
                                        textAlign: "center",
                                        width: "8em",
                                    }}
                                />
                            </DataTable>
                        ) : this.state.dejaInscrit ? (
                            <div className="row">
                                <div className="p-col-12 p-md-12 p-lg-12">
                                    <div className="p-col-12 p-md-12 p-lg-12">
                                        <h2>
                                            Détails sur l'inscription
                                            pédagogique
                                        </h2>
                                        <table
                                            className="table table-hover table-bordered table-striped"
                                            style={{ padding: "0px" }}
                                        >
                                            <thead>
                                                <tr>
                                                    <th>Université</th>
                                                    <th>UFR</th>
                                                    <th>Parcours</th>
                                                    <th>Niveau</th>
                                                    <th>Année académique</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {this.state.universite}
                                                    </td>
                                                    <td>{this.state.ufr}</td>
                                                    <td>
                                                        {this.state.parcours}
                                                    </td>
                                                    <td>{this.state.niveau}</td>
                                                    <td>
                                                        {
                                                            this.state
                                                                .anneeAcademique
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="p-col-12 p-md-12 p-lg-6">
                                    <DataTable
                                        ref={(el) => (this.dt = el)}
                                        value={this.state.listeECSemestre1}
                                        selectionMode="single"
                                        header={`Liste des éléments constitutifs du premier semestre ( ${this.state.listeECSemestre1.length} ECs) `}
                                        paginator={true}
                                        rows={5}
                                        globalFilter={this.state.globalFilter}
                                        filterMatchMode="contains"
                                        responsive={true}
                                        selection={this.state.listeSelection}
                                        onSelectionChange={(event) =>
                                            this.setState({
                                                dataTableSelection1:
                                                    event.value,
                                            })
                                        }
                                    >
                                        <Column
                                            field="codeElementConstitutif"
                                            header="Code EC"
                                            filterMatchMode="contains"
                                            sortable={true}
                                            filter={true}
                                            filterPlaceholder="Code EC"
                                        />
                                        <Column
                                            field="libelleElementConstitutif"
                                            header="Libelle EC"
                                            filterMatchMode="contains"
                                            sortable={true}
                                            filter={true}
                                            filterPlaceholder="libelle EC"
                                        />
                                        <Column
                                            field="creditElementConstitutif"
                                            header="credit EC"
                                            filterMatchMode="contains"
                                            sortable={true}
                                            filter={true}
                                            filterPlaceholder="Crédit EC"
                                        />
                                    </DataTable>
                                    <br />

                                    <DataTable
                                        ref={(el) => (this.dt = el)}
                                        value={this.state.listeECSemestre2}
                                        selectionMode="single"
                                        header={`Liste des éléments constitutifs du deuxième semestre ( ${this.state.listeECSemestre2.length} ECs) `}
                                        paginator={true}
                                        rows={5}
                                        globalFilter={this.state.globalFilter}
                                        filterMatchMode="contains"
                                        responsive={true}
                                        selection={this.state.listeSelection}
                                        onSelectionChange={(event) =>
                                            this.setState({
                                                dataTableSelection1:
                                                    event.value,
                                            })
                                        }
                                    >
                                        <Column
                                            field="codeElementConstitutif"
                                            header="Code EC"
                                            filterMatchMode="contains"
                                            sortable={true}
                                            filter={true}
                                            filterPlaceholder="Code EC"
                                        />
                                        <Column
                                            field="libelleElementConstitutif"
                                            header="Libelle EC"
                                            filterMatchMode="contains"
                                            sortable={true}
                                            filter={true}
                                            filterPlaceholder="libelle EC"
                                        />
                                        <Column
                                            field="creditElementConstitutif"
                                            header="credit EC"
                                            filterMatchMode="contains"
                                            sortable={true}
                                            filter={true}
                                            filterPlaceholder="Crédit EC"
                                        />
                                    </DataTable>
                                </div>

                                <div
                                    className="p-col-12 p-md-12 p-lg-6"
                                    style={{ paddingTop: "6px" }}
                                >
                                    <div className="card">
                                        <div className="card-header">
                                            <h4>Mes informations</h4>
                                        </div>
                                        <div
                                            className="card-body"
                                            style={{ padding: "0px" }}
                                        >
                                            <div className="p-grid">
                                                <div className="p-col-12 p-md-12 p-lg-12">
                                                    <table
                                                        className="table table-hover table-bordered table-striped"
                                                        style={{
                                                            padding: "0px",
                                                        }}
                                                    >
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">
                                                                    INE
                                                                </th>
                                                                <td>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .etudiant
                                                                            .ine
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    Nom
                                                                </th>
                                                                <td>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .etudiant
                                                                            .nom
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    Prenom
                                                                </th>
                                                                <td>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .etudiant
                                                                            .prenom
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    Date de
                                                                    naissance
                                                                </th>
                                                                <td>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .etudiant
                                                                            .dateNaissance
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">
                                                                    Lieu de
                                                                    naissance
                                                                </th>
                                                                <td>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .etudiant
                                                                            .lieuNaissance
                                                                    }
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <th scope="row">
                                                                    Photo
                                                                    d'identité
                                                                </th>
                                                                <td>
                                                                    {this.state
                                                                        .photo && (
                                                                        <img
                                                                            className="card-img-top"
                                                                            src={`data:image/${this.state.extension};base64, ${this.state.photo}`}
                                                                            alt="Card image cap"
                                                                            ref={
                                                                                this
                                                                                    .rfPhoto
                                                                            }
                                                                            style={{
                                                                                width: "207px",
                                                                                height: "266px",
                                                                            }}
                                                                        />
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className="p-col-6 p-md-6 p-lg-6">
                                                    <div className="p-field">
                                                        <Button
                                                            label="Télecharger"
                                                            icon="pi pi-file-pdf"
                                                            className="p-button-info p-mr-2"
                                                            onClick={
                                                                this
                                                                    .telechargerAttestation
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : this.state.loadingDetailInscription ? (
                            <div className="p-grid">
                                <div className="p-col-6"></div>
                                <div className="p-col-12">
                                    {this.spinner.loadingSpinner(
                                        "spin",
                                        "black"
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div
                                className="alert alert-success d-flex align-items-center"
                                role="alert"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="currentColor"
                                    class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                                    viewBox="0 0 16 16"
                                    role="img"
                                    aria-label="Warning:"
                                >
                                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                </svg>
                                <div>
                                    <h3>
                                        Aucune session d'inscription disponible
                                        pour votre profile
                                    </h3>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/* Construction interface */}
                <Dialog
                    visible={this.state.inscriptionDialog}
                    style={{ width: "98%" }}
                    header={this.state.titreFenetre}
                    modal
                    className="p-fluid"
                    footer={inscriptionFooter}
                    onHide={this.hideDialog}
                >
                    <hr />
                    <div className="row">
                        {/* Les EC obligatoires du premier semestre */}
                        <div className="p-col-12 p-md-12 p-lg-6">
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        EC obligatoire du premier semestre ({" "}
                                        {this.state.listeECSemestre1.length} EC
                                        )
                                    </div>
                                    <div className="card-body">
                                        <DataTable
                                            ref={(el) => (this.dt = el)}
                                            value={this.state.listeECSemestre1}
                                            selectionMode="single"
                                            header="EC Ogligatoire Semestre 1"
                                            paginator={true}
                                            rows={5}
                                            globalFilter={
                                                this.state.globalFilter
                                            }
                                            filterMatchMode="contains"
                                            responsive={true}
                                            selection={
                                                this.state.listeSelection
                                            }
                                            onSelectionChange={(event) =>
                                                this.setState({
                                                    dataTableSelection1:
                                                        event.value,
                                                })
                                            }
                                        >
                                            <Column
                                                field="codeElementConstitutif"
                                                header="Code EC"
                                                filterMatchMode="contains"
                                                sortable={true}
                                                filter={false}
                                                filterPlaceholder="Code EC"
                                            />
                                            <Column
                                                field="libelleElementConstitutif"
                                                header="Libelle EC"
                                                filterMatchMode="contains"
                                                sortable={true}
                                                filter={false}
                                                filterPlaceholder="libelleEC"
                                            />
                                            <Column
                                                field="creditElementConstitutif"
                                                header="credit EC"
                                                filterMatchMode="contains"
                                                sortable={true}
                                                filter={false}
                                                filterPlaceholder="Crédit EC"
                                            />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Fin EC obligatoires du premier semestre */}

                        {/* Les EC obligatoires du deuxième semestre */}
                        <div className="p-col-12 p-md-12 p-lg-6">
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        EC obligatoire du deuxième semestre ({" "}
                                        {this.state.listeECSemestre2.length} EC
                                        )
                                    </div>
                                    <div className="card-body">
                                        <DataTable
                                            ref={(el) => (this.dt = el)}
                                            value={this.state.listeECSemestre2}
                                            selectionMode="single"
                                            header="EC Ogligatoire Semestre 2"
                                            paginator={true}
                                            rows={5}
                                            globalFilter={
                                                this.state.globalFilter
                                            }
                                            filterMatchMode="contains"
                                            responsive={true}
                                            selection={
                                                this.state.listeSelection
                                            }
                                            onSelectionChange={(event) =>
                                                this.setState({
                                                    dataTableSelection1:
                                                        event.value,
                                                })
                                            }
                                        >
                                            <Column
                                                field="codeElementConstitutif"
                                                header="Code EC"
                                                filterMatchMode="contains"
                                                sortable={true}
                                                filter={false}
                                                filterPlaceholder="Code EC"
                                            />
                                            <Column
                                                field="libelleElementConstitutif"
                                                header="Libelle EC"
                                                filterMatchMode="contains"
                                                sortable={true}
                                                filter={false}
                                                filterPlaceholder="libelleEC"
                                            />
                                            <Column
                                                field="creditElementConstitutif"
                                                header="credit EC"
                                                filterMatchMode="contains"
                                                sortable={true}
                                                filter={false}
                                                filterPlaceholder="Crédit EC"
                                            />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Fin EC obligatoires du deuxième semestre */}

                        {/* S'il existe des EC optionnels au choix */}
                        {this.state.existeOption && (
                            <React.Fragment>
                                <hr />
                                <div className="p-col-12 p-md-12 p-lg-12">
                                    <h6>Choix des EC Optionnels</h6>
                                    <hr />
                                </div>
                                {this.state.existeOption1 && (
                                    <React.Fragment>
                                        {/* Choix des EC du premier semestre */}
                                        <div className="p-col-12 p-md-12 p-lg-6">
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                <div className="card">
                                                    <div className="card-header">
                                                        Liste des EC Disponible
                                                        en option pour le
                                                        premier semestre (
                                                        {
                                                            this.state
                                                                .nombreECChoix1
                                                        }{" "}
                                                        choix à effectuer)
                                                    </div>
                                                    <div className="card-body">
                                                        <DataTable
                                                            ref={(el) =>
                                                                (this.dt = el)
                                                            }
                                                            value={
                                                                this.state
                                                                    .listeECOptionnel1
                                                            }
                                                            selectionMode="single"
                                                            header="EC Ogligatoire Semestre 2"
                                                            paginator={true}
                                                            rows={10}
                                                            globalFilter={
                                                                this.state
                                                                    .globalFilter
                                                            }
                                                            filterMatchMode="contains"
                                                            responsive={true}
                                                            selection={
                                                                this.state
                                                                    .listeSelection
                                                            }
                                                            onSelectionChange={(
                                                                event
                                                            ) =>
                                                                this.setState({
                                                                    dataTableSelection1:
                                                                        event.value,
                                                                })
                                                            }
                                                        >
                                                            <Column
                                                                field="codeElementConstitutif"
                                                                header="Code EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Code EC"
                                                            />
                                                            <Column
                                                                field="libelleElementConstitutif"
                                                                header="Libelle EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="libelleEC"
                                                            />
                                                            <Column
                                                                field="creditElementConstitutif"
                                                                header="credit EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Crédit EC"
                                                            />
                                                            <Column
                                                                header={
                                                                    actionHeader
                                                                }
                                                                body={
                                                                    this
                                                                        .actionBodyECOption1
                                                                }
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                    width: "8em",
                                                                }}
                                                            />
                                                        </DataTable>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-12 p-lg-6">
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                <div className="card">
                                                    <div className="card-header">
                                                        Liste des EC choisi pour
                                                        le premier semestre
                                                    </div>
                                                    <div className="card-body">
                                                        <DataTable
                                                            ref={(el) =>
                                                                (this.dt = el)
                                                            }
                                                            value={
                                                                this.state
                                                                    .listeECChoisi1
                                                            }
                                                            selectionMode="single"
                                                            header="EC Ogligatoire Semestre 2"
                                                            paginator={true}
                                                            rows={10}
                                                            globalFilter={
                                                                this.state
                                                                    .globalFilter
                                                            }
                                                            filterMatchMode="contains"
                                                            responsive={true}
                                                            selection={
                                                                this.state
                                                                    .listeSelection
                                                            }
                                                            onSelectionChange={(
                                                                event
                                                            ) =>
                                                                this.setState({
                                                                    dataTableSelection1:
                                                                        event.value,
                                                                })
                                                            }
                                                        >
                                                            <Column
                                                                field="codeElementConstitutif"
                                                                header="Code EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Code EC"
                                                            />
                                                            <Column
                                                                field="libelleElementConstitutif"
                                                                header="Libelle EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="libelleEC"
                                                            />
                                                            <Column
                                                                field="creditElementConstitutif"
                                                                header="credit EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Crédit EC"
                                                            />
                                                            <Column
                                                                header={
                                                                    actionHeader
                                                                }
                                                                body={
                                                                    this
                                                                        .actionBodyAnnulerChoix1
                                                                }
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                    width: "8em",
                                                                }}
                                                            />
                                                        </DataTable>
                                                    </div>
                                                </div>
                                                {this.state.submitted &&
                                                    this.state.listeECChoisi1
                                                        .length <
                                                        this.state
                                                            .nombreECChoix1 && (
                                                        <small
                                                            className="p-invalid"
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            Effectuer
                                                            obligatoirement le
                                                            nombre de choix
                                                            indiqué. ({" "}
                                                            {
                                                                this.state
                                                                    .nombreECChoix1
                                                            }{" "}
                                                            )
                                                        </small>
                                                    )}
                                            </div>
                                        </div>
                                        {/* Fin du choix des EC du premier semestre */}
                                    </React.Fragment>
                                )}
                                {this.state.existeOption2 && (
                                    <React.Fragment>
                                        {/* Choix si y'a des options au deuxième semestre */}
                                        <div className="p-col-12 p-md-12 p-lg-6">
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                <div className="card">
                                                    <div className="card-header">
                                                        Liste des EC Disponibles
                                                        en option pour le
                                                        deuxième semestre (
                                                        {
                                                            this.state
                                                                .nombreECChoix2
                                                        }{" "}
                                                        choix à effectuer)
                                                    </div>
                                                    <div className="card-body">
                                                        <DataTable
                                                            ref={(el) =>
                                                                (this.dt = el)
                                                            }
                                                            value={
                                                                this.state
                                                                    .listeECOptionnel2
                                                            }
                                                            selectionMode="single"
                                                            header="EC Ogligatoire Semestre 2"
                                                            paginator={true}
                                                            rows={10}
                                                            globalFilter={
                                                                this.state
                                                                    .globalFilter
                                                            }
                                                            filterMatchMode="contains"
                                                            responsive={true}
                                                            selection={
                                                                this.state
                                                                    .selectionUe1
                                                            }
                                                            onSelectionChange={(
                                                                event
                                                            ) =>
                                                                this.setState({
                                                                    dataTableSelection1:
                                                                        event.value,
                                                                })
                                                            }
                                                        >
                                                            <Column
                                                                field="codeElementConstitutif"
                                                                header="Code EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Code EC"
                                                            />
                                                            <Column
                                                                field="libelleElementConstitutif"
                                                                header="Libelle EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="libelleEC"
                                                            />
                                                            <Column
                                                                field="creditElementConstitutif"
                                                                header="credit EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Crédit EC"
                                                            />
                                                            <Column
                                                                header={
                                                                    actionHeader
                                                                }
                                                                body={
                                                                    this
                                                                        .actionBodyECOption2
                                                                }
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                    width: "8em",
                                                                }}
                                                            />
                                                        </DataTable>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-12 p-lg-6">
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                <div className="card">
                                                    <div className="card-header">
                                                        Liste des EC choisi pour
                                                        le premier semestre
                                                    </div>
                                                    <div className="card-body">
                                                        <DataTable
                                                            ref={(el) =>
                                                                (this.dt = el)
                                                            }
                                                            value={
                                                                this.state
                                                                    .listeECChoisi2
                                                            }
                                                            selectionMode="single"
                                                            header="EC Ogligatoire Semestre 2"
                                                            paginator={true}
                                                            rows={10}
                                                            globalFilter={
                                                                this.state
                                                                    .globalFilter
                                                            }
                                                            filterMatchMode="contains"
                                                            responsive={true}
                                                            selection={
                                                                this.state
                                                                    .listeSelection
                                                            }
                                                            onSelectionChange={(
                                                                event
                                                            ) =>
                                                                this.setState({
                                                                    dataTableSelection1:
                                                                        event.value,
                                                                })
                                                            }
                                                        >
                                                            <Column
                                                                field="codeElementConstitutif"
                                                                header="Code EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Code EC"
                                                            />
                                                            <Column
                                                                field="libelleElementConstitutif"
                                                                header="Libelle EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="libelleEC"
                                                            />
                                                            <Column
                                                                field="creditElementConstitutif"
                                                                header="credit EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Crédit EC"
                                                            />
                                                            <Column
                                                                header={
                                                                    actionHeader
                                                                }
                                                                body={
                                                                    this
                                                                        .actionBodyAnnulerChoix2
                                                                }
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                    width: "8em",
                                                                }}
                                                            />
                                                        </DataTable>
                                                    </div>
                                                </div>
                                                {this.state.submitted &&
                                                    this.state.listeECChoisi2
                                                        .length <
                                                        this.state
                                                            .nombreECChoix2 && (
                                                        <small
                                                            className="p-invalid"
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            Effectuer
                                                            obligatoirement le
                                                            nombre de choix
                                                            indiqué. ({" "}
                                                            {
                                                                this.state
                                                                    .nombreECChoix2
                                                            }{" "}
                                                            )
                                                        </small>
                                                    )}
                                            </div>
                                        </div>
                                        {/* Fin choix si y'a des options au deuxième semestre */}
                                    </React.Fragment>
                                )}
                                )
                            </React.Fragment>
                        )}
                        <hr />

                        {(this.state.existeUEOptionS1 ||
                            this.state.existeUEOptionS1) && (
                            <React.Fragment>
                                <hr />
                                <div className="p-col-12 p-md-12 p-lg-12">
                                    <h6>
                                        Choix de l'UE d'inscription parmis les
                                        options aux choix
                                    </h6>
                                    <hr />
                                </div>
                                {this.state.existeUEOptionS1 && (
                                    <React.Fragment>
                                        {/* Choix des EC du premier semestre */}
                                        <div className="p-col-12 p-md-12 p-lg-6">
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                <div className="card">
                                                    <div className="card-header">
                                                        Liste des UEs
                                                        disponibles au choix au
                                                        premier semestre
                                                    </div>
                                                    <div className="card-body">
                                                        <DataTable
                                                            ref={(el) =>
                                                                (this.dt = el)
                                                            }
                                                            value={
                                                                this.state
                                                                    .listeUEOptionS1
                                                            }
                                                            selectionMode="single"
                                                            header="UE Disponible en sellection"
                                                            paginator={true}
                                                            rows={10}
                                                            globalFilter={
                                                                this.state
                                                                    .globalFilter
                                                            }
                                                            filterMatchMode="contains"
                                                            responsive={true}
                                                            selection={
                                                                this.state
                                                                    .listeSelection
                                                            }
                                                            onSelectionChange={(
                                                                event
                                                            ) =>
                                                                this.setState({
                                                                    dataTableSelection1:
                                                                        event.value,
                                                                })
                                                            }
                                                        >
                                                            <Column
                                                                field="codeUniteEnseignement"
                                                                header="Code UE"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Code UE"
                                                            />
                                                            <Column
                                                                field="libelleUniteEnseignement"
                                                                header="Libelle UE"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="libelle UE"
                                                            />
                                                            <Column
                                                                field="creditUniteEnseignement"
                                                                header="credit UE"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Crédit UE"
                                                            />
                                                            <Column
                                                                header={
                                                                    actionHeader
                                                                }
                                                                body={
                                                                    this
                                                                        .actionBodyUEOption1
                                                                }
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                    width: "8em",
                                                                }}
                                                            />
                                                        </DataTable>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-col-12 p-md-12 p-lg-6">
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                <div className="card">
                                                    <div className="card-header">
                                                        Aperçu de la liste des
                                                        EC de l'UE choisi
                                                    </div>
                                                    <div className="card-body">
                                                        <DataTable
                                                            ref={(el) =>
                                                                (this.dt = el)
                                                            }
                                                            value={
                                                                this.state
                                                                    .listeEcUeOptionnel1
                                                            }
                                                            selectionMode="single"
                                                            header="EC Ogligatoire Semestre 2"
                                                            paginator={true}
                                                            rows={10}
                                                            globalFilter={
                                                                this.state
                                                                    .globalFilter
                                                            }
                                                            filterMatchMode="contains"
                                                            responsive={true}
                                                            selection={
                                                                this.state
                                                                    .listeSelection
                                                            }
                                                            onSelectionChange={(
                                                                event
                                                            ) =>
                                                                this.setState({
                                                                    dataTableSelection1:
                                                                        event.value,
                                                                })
                                                            }
                                                        >
                                                            <Column
                                                                field="codeElementConstitutif"
                                                                header="Code EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Code EC"
                                                            />
                                                            <Column
                                                                field="libelleElementConstitutif"
                                                                header="Libelle EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="libelleEC"
                                                            />
                                                            <Column
                                                                field="creditElementConstitutif"
                                                                header="credit EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Crédit EC"
                                                            />
                                                        </DataTable>
                                                    </div>
                                                </div>
                                                {this.state.submitted &&
                                                    this.state
                                                        .listeEcUeOptionnel1
                                                        .length === 0 && (
                                                        <small
                                                            className="p-invalid"
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            Effectuer
                                                            obligatoirement le
                                                            choix de l'UE
                                                            d'inscription
                                                        </small>
                                                    )}
                                            </div>
                                        </div>
                                        {/* Fin du choix des EC du premier semestre */}
                                    </React.Fragment>
                                )}
                                {this.state.existeUEOptionS2 && (
                                    <React.Fragment>
                                        {/* Choix si y'a des options au deuxième semestre */}
                                        <div className="p-col-12 p-md-12 p-lg-6">
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                <div className="card">
                                                    <div className="card-header">
                                                        Liste des UEs
                                                        disponibles au choix au
                                                        deuxième semestre
                                                    </div>
                                                    <div className="card-body">
                                                        <DataTable
                                                            ref={(el) =>
                                                                (this.dt = el)
                                                            }
                                                            value={
                                                                this.state
                                                                    .listeUEOptionS2
                                                            }
                                                            selectionMode="single"
                                                            header="EU disponibles en option"
                                                            paginator={true}
                                                            rows={10}
                                                            globalFilter={
                                                                this.state
                                                                    .globalFilter
                                                            }
                                                            filterMatchMode="contains"
                                                            responsive={true}
                                                            selection={
                                                                this.state
                                                                    .listeSelection
                                                            }
                                                            onSelectionChange={(
                                                                event
                                                            ) =>
                                                                this.setState({
                                                                    dataTableSelection1:
                                                                        event.value,
                                                                })
                                                            }
                                                        >
                                                            <Column
                                                                field="codeUniteEnseignement"
                                                                header="Code UE"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Code UE"
                                                            />
                                                            <Column
                                                                field="libelleUniteEnseignement"
                                                                header="Libelle UE"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="libelle UE"
                                                            />
                                                            <Column
                                                                field="creditUniteEnseignement"
                                                                header="credit UE"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Crédit UE"
                                                            />
                                                            <Column
                                                                header={
                                                                    actionHeader
                                                                }
                                                                body={
                                                                    this
                                                                        .actionBodyUEOption2
                                                                }
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                    width: "8em",
                                                                }}
                                                            />
                                                        </DataTable>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-12 p-lg-6">
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                <div className="card">
                                                    <div className="card-header">
                                                        Aperçu de la liste des
                                                        EC choisi pour le
                                                        premier semestre
                                                    </div>
                                                    <div className="card-body">
                                                        <DataTable
                                                            ref={(el) =>
                                                                (this.dt = el)
                                                            }
                                                            value={
                                                                this.state
                                                                    .listeEcUeOptionnel2
                                                            }
                                                            selectionMode="single"
                                                            header="EC Ogligatoire Semestre 2"
                                                            paginator={true}
                                                            rows={10}
                                                            globalFilter={
                                                                this.state
                                                                    .globalFilter
                                                            }
                                                            filterMatchMode="contains"
                                                            responsive={true}
                                                            selection={
                                                                this.state
                                                                    .listeSelection
                                                            }
                                                            onSelectionChange={(
                                                                event
                                                            ) =>
                                                                this.setState({
                                                                    dataTableSelection1:
                                                                        event.value,
                                                                })
                                                            }
                                                        >
                                                            <Column
                                                                field="codeElementConstitutif"
                                                                header="Code EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Code EC"
                                                            />
                                                            <Column
                                                                field="libelleElementConstitutif"
                                                                header="Libelle EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="libelleEC"
                                                            />
                                                            <Column
                                                                field="creditElementConstitutif"
                                                                header="credit EC"
                                                                filterMatchMode="contains"
                                                                sortable={true}
                                                                filter={false}
                                                                filterPlaceholder="Crédit EC"
                                                            />
                                                        </DataTable>
                                                    </div>
                                                </div>
                                                {this.state.submitted &&
                                                    this.state
                                                        .listeEcUeOptionnel2
                                                        .length === 0 && (
                                                        <small
                                                            className="p-invalid"
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            Effectuer
                                                            obligatoirement le
                                                            choix de l'UE
                                                            d'inscription
                                                        </small>
                                                    )}
                                            </div>
                                        </div>
                                        {/* Fin choix si y'a des options au deuxième semestre */}
                                    </React.Fragment>
                                )}
                                )
                            </React.Fragment>
                        )}

                        <hr />

                        <div className="p-col-12 p-md-12 p-lg-12">
                            <h6>
                                Informations d'identité et completion
                                d'informations
                            </h6>
                            <hr />
                        </div>

                        <div className="p-col-12 p-md-12 p-lg-6">
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        Informations d'identité
                                    </div>
                                    <div className="card-body">
                                        <div className="p-grid">
                                            <div className="p-col-6 p-md-6 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="nom">
                                                        Nom
                                                    </label>
                                                    <InputText
                                                        id="nom"
                                                        value={
                                                            this.state.etudiant
                                                                .nom
                                                        }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="p-col-6 p-md-6 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="prenoms">
                                                        Prénom(s)
                                                    </label>
                                                    <InputText
                                                        id="prenoms"
                                                        value={
                                                            this.state.etudiant
                                                                .prenom
                                                        }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-grid">
                                            <div className="p-col-6 p-md-6 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="ine">
                                                        INE
                                                    </label>
                                                    <InputText
                                                        id="ine"
                                                        value={
                                                            this.state.etudiant
                                                                .ine
                                                        }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="p-col-6 p-md-6 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="dateNaissance">
                                                        Date de naissance
                                                    </label>
                                                    <InputText
                                                        id="dateNaissance"
                                                        value={
                                                            this.state.etudiant
                                                                .dateNaissance
                                                        }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-grid">
                                            <div className="p-col-6 p-md-6 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="nationalite">
                                                        Nationalite
                                                    </label>
                                                    <InputText
                                                        id="nationalite"
                                                        value={
                                                            this.state.etudiant
                                                                .nomPays
                                                        }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="p-col-6 p-md-6 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="nationalite">
                                                        Téléphone
                                                    </label>
                                                    <InputText
                                                        id="nationalite"
                                                        value={
                                                            this.state.etudiant
                                                                .telephone
                                                        }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                <div className="p-field">
                                                    <label htmlFor="lieuNaissance">
                                                        Lieu de naissance
                                                    </label>
                                                    <InputText
                                                        id="lieuNaissance"
                                                        value={
                                                            this.state.etudiant
                                                                .lieuNaissance
                                                        }
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-md-12 p-lg-6">
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        Completion d'informations
                                    </div>
                                    <div className="card-body">
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                <div className="p-field">
                                                    <label htmlFor="telephone">
                                                        Votre numero de
                                                        téléphone actuel
                                                    </label>
                                                    <PhoneInput
                                                        id="telephone"
                                                        value={
                                                            this.state
                                                                .informationEtudiant
                                                                .telephone
                                                        }
                                                        defaultCountry="BF"
                                                        limitMaxLength="8"
                                                        onChange={(value) =>
                                                            this.onPhoneChange(
                                                                value,
                                                                "telephone"
                                                            )
                                                        }
                                                    />
                                                    {this.state.submitted &&
                                                        !this.state
                                                            .informationEtudiant
                                                            .telephone && (
                                                            <small
                                                                className="p-invalid"
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                Ce champs est
                                                                obligatoire et
                                                                doit être un
                                                                numero valide.
                                                            </small>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                <div className="p-field">
                                                    <label htmlFor="mailActuel">
                                                        Votre adresse mail
                                                        actuelle
                                                    </label>
                                                    <InputText
                                                        id="mailActuel"
                                                        value={
                                                            this.state
                                                                .informationEtudiant
                                                                .email
                                                        }
                                                        onChange={(e) =>
                                                            this.onMailChange(
                                                                e,
                                                                "email"
                                                            )
                                                        }
                                                    />
                                                    {this.state.submitted &&
                                                        !this.state
                                                            .informationEtudiant
                                                            .email && (
                                                            <small
                                                                className="p-invalid"
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                Ce champs est
                                                                obligatoire.
                                                            </small>
                                                        )}
                                                    {this.state.submitted &&
                                                        !this.state
                                                            .valideMail && (
                                                            <small
                                                                className="p-invalid"
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                Le mail est
                                                                invalide,
                                                                veuillez saisir
                                                                un mail valide.
                                                            </small>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                <div className="p-field">
                                                    <label htmlFor="personnePrevenir">
                                                        Personne à prevenir en
                                                        cas de besoin(Nom
                                                        prénoms)
                                                    </label>
                                                    <InputText
                                                        id="personnePrevenir"
                                                        value={
                                                            this.state
                                                                .informationEtudiant
                                                                .personnePrevenir
                                                        }
                                                        onChange={(e) =>
                                                            this.onInputChange(
                                                                e,
                                                                "personnePrevenir"
                                                            )
                                                        }
                                                    />
                                                    {this.state.submitted &&
                                                        (!this.state
                                                            .informationEtudiant
                                                            .personnePrevenir ||
                                                            this.state
                                                                .informationEtudiant
                                                                .personnePrevenir
                                                                .length >=
                                                                4) && (
                                                            <small
                                                                className="p-invalid"
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                Ce champs est
                                                                obligatoire et
                                                                doit compter au
                                                                moins 4
                                                                caractères.
                                                            </small>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                <div className="p-field">
                                                    <label htmlFor="contactPersonne">
                                                        Numero de téléphone de
                                                        la personne à prevenir
                                                        en cas de besoin
                                                    </label>
                                                    <PhoneInput
                                                        id="contactPersonne"
                                                        value={
                                                            this.state
                                                                .informationEtudiant
                                                                .contactPersonnePrevenir
                                                        }
                                                        defaultCountry="BF"
                                                        limitMaxLength="8"
                                                        onChange={(value) =>
                                                            this.onPhoneChange(
                                                                value,
                                                                "contactPersonnePrevenir"
                                                            )
                                                        }
                                                    />
                                                    {this.state.submitted &&
                                                        !this.state
                                                            .informationEtudiant
                                                            .contactPersonnePrevenir && (
                                                            <small
                                                                className="p-invalid"
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                Ce champs est
                                                                obligatoire et
                                                                doit être un
                                                                numero valide.
                                                            </small>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr />
                        <div className="p-col-12 p-md-12 p-lg-12">
                            <h6>Photo d'identité</h6>
                            <hr />
                        </div>

                        <div className="p-col-12 p-md-12 p-lg-6">
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        Photo d'identité | Tailles acceptées:{" "}
                                        <span
                                            style={{
                                                color: "green",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            (99px*128px); (138px*177px);
                                            (207px*266px)
                                        </span>
                                    </div>
                                    <div className="card-body">
                                        <div className="p-grid">
                                            <div className="p-col-6 p-md-6 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="photo">
                                                        Photo d'identité
                                                    </label>
                                                    <input
                                                        type="file"
                                                        id="photo"
                                                        name="photo"
                                                        accept="image/*"
                                                        capture
                                                        onChange={(e) =>
                                                            this.chargerPhoto(e)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-12 p-lg-6">
                                                <div className="p-field">
                                                    <label htmlFor="apercuPhoto">
                                                        Aperçu de la photo
                                                    </label>
                                                    <div className="card">
                                                        {this.state.photo && (
                                                            <img
                                                                className="card-img-top"
                                                                src={`data:image/${this.state.extension};base64, ${this.state.photo}`}
                                                                alt="Card image cap"
                                                                ref={
                                                                    this.rfPhoto
                                                                }
                                                                style={{
                                                                    width: "207px",
                                                                    height: "266px",
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                                {this.state.submitted &&
                                                    (!this.state.photo ||
                                                        this.state
                                                            .errorFile) && (
                                                        <small
                                                            className="p-invalid"
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            {
                                                                this.state
                                                                    .errorFile
                                                            }
                                                        </small>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 p-md-12 p-lg-12">
                            <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="p-field">
                                    <h6>Engagement de conformité</h6>
                                    <hr />
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="confirmation"
                                            id="confirmation"
                                            onClick={this.confirmerFranchise}
                                            ref={this.conformite}
                                        />
                                        <label
                                            className="form-check-label"
                                            for="confirmation"
                                        >
                                            J'accepte et je certifie par cette
                                            inscription que mes informations
                                            sont correctes.
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            value="validation"
                                            id="validation"
                                            onClick={this.confirmerFranchise}
                                            ref={this.franchise}
                                        />
                                        <label
                                            class="form-check-label"
                                            for="validation"
                                        >
                                            Je m'engage à me conformer aux
                                            franchises universitaires de mon
                                            établissement d'accueil.
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}

export default InscriptionPedagogique;
