import axios from "axios";
import { AdresseIP } from "../../components/AdresseIP";


export default class EmploiDuTempsService{
    constructor(){
        this.addresse = new AdresseIP();
        this.backendUrl="service/api/campusfaso-volume-horaire/campus-faso/semaines-programmations";
        this.backendAffectationUrl="service/api/campusfaso-volume-horaire/campus-faso/affectations-cours";
    }

    
    getAllPeriodeProgrammation(idDepartement, idCohorte,idSemestre){
        return axios.get(`${this.addresse.getAdresseVolumeHoraire()}/${this.backendUrl}?cohorte=${idCohorte}&semestre=${idSemestre}&idDepartement=${idDepartement} `).then(response=>{return response.data});
    }

    getSemestre(idNiveau){
        //console.log(idNiveau)
        const url = this.addresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/semestres-vh?idNiveau="+idNiveau
        return axios.get(url)
                .then(res => res.data)
    }
    genererEmploiDuTemps(idDepartement,idSemestre,idCohorte,idSemaine){
        const url = this.addresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/emplois-du-temps?idDepartement="+idDepartement+"&idSemestre="+idSemestre+"&idCohorte="+idCohorte+"&idSemaine="+idSemaine
        return axios.get(url)
                .then(res => res.data)
    }
    getEmploiDuTempsEnseignant(matricule){
        const url = this.addresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/emploi-du-temps-enseignant?matricule="+matricule
        return axios.get(url)
                .then(res => res.data)
    }
    
   
}  
