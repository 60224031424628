import axios from "axios";
import { AdresseIP } from "../../components/AdresseIP"

export default class DisciplineService{
   constructor(){
    this.addresseIP = new AdresseIP();
    this.backendUrl="service/api/campusfaso-volume-horaire/campus-faso/disciplines"
   }

    getAll(){
        return axios.get(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}`).then(response=>response.data);
    }

    create(discipline){
        return  axios.post(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}`,discipline);
        // return  axios.post("data/discipline-all.json",discipline);
    }


    update(discipline){
        return axios.put(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}/${discipline.idDiscipline}`,discipline);
    }


    delete(idDiscipline){
        return axios.patch(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}/${idDiscipline}`);
    }
}



