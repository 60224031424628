import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { InvitationService } from '../../service/InvitationService';
import EnseignantService from '../../service/EnseignantService';
import { ParcoursService } from '../../../lmd/service/ParcoursService'
import { ParcoursUEService } from '../../../lmd/service/ParcoursUEService';
import { SemestreService } from '../../../lmd/service/SemestreService';
import { options } from 'preact';

export default function BordereauSortantDepartement(){

    const tokenDecoded = localStorage.getItem('tokenDecoded');
    const token = JSON.parse(tokenDecoded);

    const [selectedEntites, setSelectedEntites] = useState(null)

    const [listeBordereau, setListeBordereau] = useState([])
    const [listeElementConstitutif, setListeElementConstitutif] = useState([])
    const [listeEnseignant, setListeEnseignant] = useState([])
    const [informationBordereau, setInformationBordereau] = useState({})

    const [informationInvitation, setInformationInvitation] = useState({})

    const [listeParcours, setListeParcours] = useState([])
    const [listeSemestre, setListeSemestre] = useState([])
    const [curentSemestre, setCurentSemestre] = useState({})
    const [listeUniteEnseignement, setListeUniteEnseignement] = useState([])
    const [username, setUsername] = useState(token.preferred_username)

    const [curentEnseignant, setCurentEnseignant] = useState({})
    const [curentParcours, setCurentParcours] = useState({})
    const [curentElementConstitutif, setCurentElementConstitutif] = useState({})
    const [curentUniteEnseignement, setCurentUniteEnseignement] = useState({})
    const [dateDebut, setDateDebut] = useState('')
    const [dateFin, setDateFin] = useState('')    
    const [listeInformationBordereau, setListeInformationBordereau] = useState([])
    const [editionIndex, setEditionIndex] = useState(null)
    const [detailsReponseBordereauDialog, setDetailsReponseBordereauDialog] = useState(false)

    const [detailsReponseInvitationDialog, setDetailsReponseInvitationDialog] = useState(false)

    const [listeInvitationEnseignant, setListeInvitationEnseignant] = useState([])
    
    const [submitted, setSubmitted] = useState(false)
    const [globalFilter, setGlobalFilter] = useState(null)
    const [loading, setLoading] = useState(false)
    const [invitationDialog,setInvitationDialog] = useState(false)
    const [spin,setSpin] = useState(false)
    const [idDepartement, setIdDepartement] = useState(token.code_departement)
    const [detailsInvitationDialog, setDetailsInvitationDialog] = useState(false)
    const [deleteEntiteDialog, setDeleteEntiteDialog] = useState(false)
    const [confirmerEnvoiInvitationDialog, setConfirmerEnvoiInvitationDialog] = useState(false)
    const [editInvitationDialog, setEditInvitationDialog] = useState(false)

    const notify = new Notify()
    const spinner = new Spinner()

    const invitationService = new InvitationService()
    const parcoursService = new ParcoursService()
    const parcoursUEService = new ParcoursUEService()
    const enseignantService = new EnseignantService()
    const semestreService = new SemestreService()
    //const typeActiviteService = new TypeActiviteService()

    useEffect(() => {

            setLoading(true)
            invitationService.getListeBordero(username, "Sortant").then(data => {

                //console.log(data);

                setListeBordereau(data)
                setLoading(false)
                
            }).catch(error => {
                console.log(error)
                notify.echec(error.response.data.message)
                setLoading(false)
            })


            //On positionne la liste des parcours du departement
            parcoursService.getListeParcoursDepartementByIdDepartement(idDepartement).then(data => {
                setListeParcours(data)
            }).catch(error => {
                console.log(error)
                notify.echec(error.response.data.message)
            })

        }, []
    );
    

    const saveEditionInvitation = () => {

        setSubmitted(true)

        //La liste actuellement
        let listeInvitationActuelle = listeInvitationEnseignant

        //L'element que nous venons de modifier
        let editableInvitation = listeInvitationEnseignant[editionIndex];

        if(dateDebut && dateFin && dateDebut<dateFin){
            editableInvitation['enseignant']= curentEnseignant
            editableInvitation['dateDebutCours'] = dateDebut
            editableInvitation['dateFinCours'] = dateFin
            editableInvitation['posDemande']  = username
            editableInvitation['etatActuel'] = "Initial"

            //On met a jour l'element concerne
            listeInvitationActuelle[editionIndex] = editableInvitation

            //On met a jour la liste des invitations
            setListeInvitationEnseignant(listeInvitationActuelle)

            //On peut fermer la fenetre d'edition
            hideEditionDialog()
            notify.success('Invitation edité avec succès')
            
        }
        
    }


    const hideEditionDialog = () => {
        setCurentEnseignant({})
        setCurentParcours({})
        setCurentElementConstitutif({})
        setListeSemestre([])
        setListeUniteEnseignement([])
        setDateDebut('')
        setDateFin('')
        setEditInvitationDialog(false)
        setSubmitted(false)
    }


    const onEnseignantChange = (event) => {
        setCurentEnseignant(event.target.value)
    }

    const onParcoursChange = (event) => {
        setCurentParcours(event.target.value)

        semestreService.get(event.target.value.idParcours).then(data => {
            setListeSemestre(data)
        })

        setListeUniteEnseignement([])
        setListeElementConstitutif([])
    }

    const onDateDebutChange = (event) => {
        setDateDebut(event.target.value)
    }

    const onDateFinChange = (event) => {
        setDateFin(event.target.value)
    }

    const onSemestreChange = (event) => {
        setCurentSemestre(event.target.value)

        parcoursUEService.get(curentParcours.idParcours, event.target.value.idSemestre).then(data => {
            setListeUniteEnseignement(data)
        })
        setListeElementConstitutif([])
    }

    const onUniteEnseignementChange = (event) => {
        setCurentUniteEnseignement(event.target.value)
        setListeElementConstitutif(event.target.value.uniteEnseignement.listeElementConstitutif)
        if((event.target.value.uniteEnseignement.listeElementConstitutif).length===1){
            setCurentElementConstitutif(event.target.value.uniteEnseignement.listeElementConstitutif[0])
        }
    }

    const onElementConstitutifChange = (event) => {
        setCurentElementConstitutif(event.target.value)
    }



    const detailsInvitation = (data) => {
        setInformationBordereau(data)
        if(data.idBeParent){
            setListeInformationBordereau([data])
            setListeInvitationEnseignant(data.beParent.listeInvitationEnseignant)
            setDetailsReponseBordereauDialog(true)
        }else{
            setListeInformationBordereau([data])
            setListeInvitationEnseignant(data.listeInvitationEnseignant)
            setDetailsInvitationDialog(true)
        }
    }



    const detailsReponseInvitation = (data) => {
        setInformationInvitation(data)
        setDetailsReponseInvitationDialog(true)
    }

    const hideDetailInvitation = () => {
        setInformationBordereau({})
        setListeInformationBordereau([])
        setListeInvitationEnseignant([])
        setDetailsInvitationDialog(false)
        setDetailsReponseBordereauDialog(false)
        setSpin(false)
    }



    const hideDetailsReponseInvitationDialog = () => {
        setDetailsReponseInvitationDialog(false)
    }



    const validerInvitationEnseignant = () => {
        setConfirmerEnvoiInvitationDialog(true)
    }

    const hideConfirmerInvitationDialog = () => {
        setConfirmerEnvoiInvitationDialog(false)
        setSpin(false)
    }


    const ajouterInvitationEnseignant = () => {

        let infosBordereau = informationBordereau
        
        if(infosBordereau.listeInvitationEnseignant.length===0){
            //Aucune invitation d'abord, cela signifi que l'on recupere les enseignants qui sont de l'Iesr de destination du bordereau
            enseignantService.getListeEnseignantIesrInvitation(infosBordereau.idIesrDestinataire).then(data => {
                setListeEnseignant(data)
            }).catch(error => {
                console.log(error)
                notify.echec(error.response.data.message)
            })
        }else{
            //Y'a deja une ou des invitations, donc on doits'assurer que tous les enseignants à inviter sont du même departement
            //Pour cela, on prend le premieux elements de la liste pour en deduire le departement concerné, la contrainte ayant été respecté à chaque étape d'ajout
            let departementEnseignantId = infosBordereau.listeInvitationEnseignant[0].enseignant.idDepartementEtab
            enseignantService.getListeEnseignantDepartementInvitation(departementEnseignantId).then(data => {
                setListeEnseignant(data)
            }).catch(error => {
                console.log(error)
                notify.echec(error.response.data.message)
            })
        }
        
        setInvitationDialog(true)
    }



    const enregistrerBordereau = () => {

        let bordereau = informationBordereau

        bordereau['idDepartementDestinataire'] = bordereau.listeInvitationEnseignant[0].enseignant.idDepartementEtab

        setSpin(true)
        invitationService.editionBordereau(bordereau.idBe, bordereau).then(data => {
            setSpin(false)

            //On recharge la liste des bordereau pour en recuperer les derniers enregistrés
            setLoading(true)
            invitationService.getListeBordero(username, "Sortant").then(data => {
                setListeBordereau(data)
                setLoading(false)
            }).catch(error => {
                console.log(error)
                notify.echec(error.response.data.message)
                setLoading(false)
            })

            //On reinitialise les parametres et on quitte la fenetre avec message de succes
            hideDialog()
            setDetailsInvitationDialog(false)
            notify.success()

        }).catch(error => {
            console.log(error)
            notify.echec(error.response.data.message)
        })
        
    }

    const hideDialog = () => {
        setCurentEnseignant({})
        setCurentParcours({})
        setCurentElementConstitutif({})
        setListeSemestre([])
        setListeUniteEnseignement([])
        setDateDebut('')
        setDateFin('')
        setListeEnseignant()
        setInvitationDialog(false)
        setSubmitted(false)
        setEditionIndex(null)
    }



    const addInvitation = () => {

        setSubmitted(true);
        if(curentEnseignant.idEnseignant && curentElementConstitutif.idElementConstitutif && dateDebut && dateFin && dateDebut<=dateFin){
            
            setSpin(true);
            let newInvitation = {
                enseignant: curentEnseignant,
                idElementConstitutif: curentElementConstitutif.idElementConstitutif,
                elementConstitutif: curentElementConstitutif,
                dateDebutCours: dateDebut,
                dateFinCours: dateFin,
                idParcours: curentParcours.idParcours,
                parcours: curentParcours,
                idSemestre: curentSemestre.idSemestre,
                semestre: curentSemestre,
                posDemande: username,
                etatActuel: 'Nouveau'
            }

            let listeInvitation = informationBordereau.listeInvitationEnseignant
            listeInvitation.push(newInvitation)

            setListeInvitationEnseignant(listeInvitation)

            informationBordereau['listeInvitationEnseignant'] = listeInvitation
            setInformationBordereau(informationBordereau)

            setSpin(false)
            setCurentEnseignant({})
            setCurentParcours({})
            setCurentElementConstitutif({})
            setListeSemestre([])
            setListeUniteEnseignement([])
            setDateDebut('')
            setDateFin('')
            setInvitationDialog(false)
            setSubmitted(false)

        }
    }


    const editionInvitationEnseignant = (invitationData, index) => {

        setEditionIndex(index.rowIndex)
        setCurentEnseignant(invitationData.enseignant)

        setDateDebut(invitationData.dateDebutCours)
        setDateFin(invitationData.dateFinCours)

        let idDepartement = invitationData.enseignant.idDepartementEtab

        enseignantService.getListeEnseignantDepartementInvitation(idDepartement).then(data => {
            setListeEnseignant(data)
        }).catch(error => {
            console.log(error)
            notify.echec(error.response.data.message)
        })

        setEditInvitationDialog(true)

    }


    const removeInvitation = (index) => {
        setEditionIndex(index.rowIndex)
        setDeleteEntiteDialog(true)
    }


    const hideDeleteEntiteDialog = () => {
        setEditionIndex(null)
        setDeleteEntiteDialog(false)
    }

    const deleteInvitation = () => {

        let listeInvitationEnseignantActuel = listeInvitationEnseignant
        let indexListe = 0

        let newListeInvitationEnseignant = listeInvitationEnseignantActuel.filter(element => {
            indexListe++
            return (parseInt(indexListe)-1)!==editionIndex
        })

        setListeInvitationEnseignant(newListeInvitationEnseignant)

        let bordereau = informationBordereau
        bordereau['listeInvitationEnseignant'] = newListeInvitationEnseignant
        setInformationBordereau(bordereau)
        hideDeleteEntiteDialog()
    }


    const confirmerValidationInvitation = () => {

        

        let invitationObject = informationBordereau
        
        setSpin(true)
        
        invitationService.transmissionBe(username, invitationObject).then(data => {
            setSpin(false)

            //On recharge la liste des bordereau pour en recuperer les derniers enregistrés
            setLoading(true)
            invitationService.getListeBordero(username, "Sortant").then(data => {
                setListeBordereau(data)
                setLoading(false)
            }).catch(error => {
                console.log(error)
                notify.echec(error.response.data.message)
                setLoading(false)
            })

            //On reinitialise les parametres et on quitte la fenetre avec message de succes
            hideDialog()
            hideConfirmerInvitationDialog(false)
            setDetailsInvitationDialog(false)
            hideDetailInvitation()
            notify.success()

        }).catch(error => {
            console.log(error)
            notify.echec(error.response.data.message)
        })
        

    }



    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-info" className="p-button-warning p-mr-2" title="Détails" style={{marginRight: '.5em'}} onClick={() => detailsInvitation(rowData)} />
            </React.Fragment>
        );
    }

    const invitationActionBodyTemplate = (rowData, index) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-warning p-mr-2" title="Editer" style={{marginRight: '.5em'}} onClick={() => editionInvitationEnseignant(rowData, index)} />
                <Button icon="pi pi-trash" className="p-button-danger p-mr-2" title="Supprimer" style={{marginRight: '.5em'}} onClick={() => removeInvitation(index)} />
            </React.Fragment>
        );
    }


    const reponseInvitationActionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-eye" className="p-button-warning p-mr-2" title="Editer" style={{marginRight: '.5em'}} onClick={() => detailsReponseInvitation(rowData)} />
            </React.Fragment>
        );
    }

    const detailsInvitationFooter = (
        <React.Fragment>
            <Button label="Ajouter une invitation" icon="pi pi-plus" className="p-button-text" onClick={()=>ajouterInvitationEnseignant()} />
            {listeInvitationEnseignant.length>0 && (
                <React.Fragment>
                    <Button label="Enregistrer" icon="pi pi-save" className="p-button-text" onClick={() => enregistrerBordereau()} />
                    <Button label="Valider & envoyer" icon="pi pi-check-circle" className="p-button-text" onClick={() => validerInvitationEnseignant()} />
                </React.Fragment>
            )}
            <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDetailInvitation} />
        </React.Fragment>
    )

    const detailsInformationReponseInvitationFooter = (
        <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDetailsReponseInvitationDialog} />
    )


    const detailsReponseInvitationFooter = (
        <React.Fragment>
            <Button label="Valider & envoyer" icon="pi pi-check-circle" className="p-button-text" onClick={() => validerInvitationEnseignant()} />
            <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDetailInvitation} />
        </React.Fragment>
    )

    
    const deleteEntiteDialogFooter = (
        <React.Fragment>
            <Button label="Non" disabled={spin?true:false}  icon={spin?"pi pi-spin pi-spinner":"pi pi-ban"} className="p-button-text" onClick={hideDeleteEntiteDialog} />
            <Button label="Oui" disabled={spin?true:false} icon="pi pi-check" className="p-button-text" onClick={deleteInvitation} />
        </React.Fragment>
    );


    const confirmerEnvoiInvitationFooter = (
        <React.Fragment>
            <Button label="Non" disabled={spin?true:false}  icon={spin?"pi pi-spin pi-spinner":"pi pi-ban"} className="p-button-text" onClick={hideConfirmerInvitationDialog} />
            <Button label="Oui" disabled={spin?true:false} icon="pi pi-check" className="p-button-text" onClick={confirmerValidationInvitation} />
        </React.Fragment>
    );
    
    

    const actionHeader = <Button type="button" icon="pi pi-cog"/>;

    return (
        <div className="datatable-crud-demo">
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="card">
                {loading ?
                <div className="p-grid">
                    <div className="p-col-6" ></div>
                    <div className="p-col-6" >
                        {spinner.loadingSpinner("spin", "black")}
                    </div>
                </div>
                :
                <DataTable value={listeBordereau} selection={selectedEntites} onSelectionChange={(e) => setSelectedEntites(e.value)}
                    dataKey="id" paginator rows={10} 
                    globalFilter={globalFilter}
                    header="Liste des bordereaux invitation enseignant sortant" selectionMode='one'>
                    <Column field="numeroBe" header="Numero bordereau" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="dateBe" header="Date bordereau" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="nomIesr" header="Universite de destination" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="etatBe" header="Etat du bordereau" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="posBe" header="Position du bordereau" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column header={actionHeader} body={actionBodyTemplate} style={{textAlign:'center', width: '160px'}}></Column>
                </DataTable>
                }
            </div>



            <Dialog visible={editInvitationDialog} style={{ width: '75%' }} header="Edition d'une invitation (Enseignant et période de la mission)" modal className="p-fluid" onHide={hideEditionDialog}>
                
                <hr />
                <div className="p-grid">


                    {curentEnseignant.nomPrenomEnseignant && 
                    (
                        <React.Fragment>

                            <div className="p-col-8">
                                <div className="p-field">
                                    <label htmlFor="enseignant">Sélectionner l'enseignant</label>
                                    <Dropdown optionLabel="nomPrenomEnseignant" optionValue={curentEnseignant.nomPrenomEnseignant} value={curentEnseignant}  options={listeEnseignant} onChange={(e) => {onEnseignantChange(e)}} required autoFocus className={classNames({ 'p-invalid': submitted })} />
                                    {submitted && !curentEnseignant.nomPrenomEnseignant && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>}              
                                </div>
                            </div>
                        
                            <div className="p-col-2" >
                                <div className="p-field">
                                    <label htmlFor="dateDebut">Date de debut de la mission</label>
                                    <InputText  type="date" id="dateDebut" value={dateDebut}  onChange={(e) => {onDateDebutChange(e)}} required autoFocus className={classNames({ 'p-invalid': submitted })} />
                                    {submitted && !dateDebut && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>}              
                                </div>
                            </div>

                            <div className="p-col-2" >
                                <div className="p-field">
                                    <label htmlFor="dateFin">Date de fin de la mission</label>
                                    <InputText type="date" id="dateFin" value={dateFin}  onChange={(e) => {onDateFinChange(e)}} required autoFocus className={classNames({ 'p-invalid': submitted })} />
                                    {submitted && !dateFin && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>}
                                    {submitted && dateFin && dateDebut && dateDebut>dateFin && <small className="p-invalid" style={{color : "red"}}>La date de fin du cours ne doit être inférieur à la date debut</small>}           
                                </div>
                            </div>

                        </React.Fragment>
                    )}

                </div>

                <hr/>

                {curentEnseignant.idEnseignant && 
                    (
                        <div  style={{width:"200px",display:"flex"}} className="mt-3">
                            <Button label="Quitter" icon="pi pi-times" className="p-button-danger mr-2" type='button' onClick={hideEditionDialog} />
                            <Button label='Valider' icon={spin?"pi pi-spin pi-spinner":"pi pi-save"} className='p-button-success mr-2' onClick={saveEditionInvitation}  disabled={spin?true:false} />
                        </div>
                    )
                }

            </Dialog>

 
            <Dialog visible={invitationDialog} style={{ width: '95%' }} header="Edition d'une invitation" modal className="p-fluid" onHide={hideDialog}>
                <hr />

                {informationBordereau.listeInvitationEnseignant && <React.Fragment>
                {informationBordereau.listeInvitationEnseignant.length===0? (
                    <div className="p-grid">
                        <div className="p-col-12" >
                            <div className="p-field">
                                <label htmlFor="iesr">Université/Institut de destination de l'invitation</label>
                                <InputText  id="iesr" value={informationBordereau.nomIesr} className={classNames({ 'p-invalid': submitted })} disabled />             
                            </div>
                        </div>
                    </div>
                ):(
                    <React.Fragment>
                        <div className="p-grid">
                            <div className="p-col-6" >
                                <div className="p-field">
                                    <label htmlFor="iesr">Université/Institut de destination de l'invitation</label>
                                    <InputText  id="iesr" value={informationBordereau.nomIesr} className={classNames({ 'p-invalid': submitted })} disabled />             
                                </div>
                            </div>
                            <div className="p-col-6" >
                                <div className="p-field">
                                    <label htmlFor="iesr">Departement de destination de l'invitation</label>
                                    <InputText  id="iesr" value={informationBordereau.listeInvitationEnseignant[0].enseignant.lbDepartementEtab} className={classNames({ 'p-invalid': submitted })} disabled />             
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}

                <hr/>

                <div className="p-grid">

                    <div className="p-col-8">
                        <div className="p-field">
                            <label htmlFor="enseignant">Sélectionner l'enseignant</label>
                            <Dropdown optionLabel="nomPrenomEnseignant" optionValue={curentEnseignant.nomPrenomEnseignant} filter filterBy="nomPrenomEnseignant" value={curentEnseignant}  options={listeEnseignant} onChange={(e) => {onEnseignantChange(e)}} required autoFocus className={classNames({ 'p-invalid': submitted })} />
                            {submitted && !curentEnseignant.nomPrenomEnseignant && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>}              
                        </div>
                    </div>

                    <div className="p-col-2" >
                        <div className="p-field">
                            <label htmlFor="dateDebut">Date de debut de la mission</label>
                            <InputText  type="date" id="dateDebut" value={dateDebut}  onChange={(e) => {onDateDebutChange(e)}} required autoFocus className={classNames({ 'p-invalid': submitted })} />
                            {submitted && !dateDebut && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>}              
                        </div>
                    </div>

                    <div className="p-col-2" >
                        <div className="p-field">
                            <label htmlFor="dateFin">Date de fin de la mission</label>
                            <InputText type="date" id="dateFin" value={dateFin}  onChange={(e) => {onDateFinChange(e)}} required autoFocus className={classNames({ 'p-invalid': submitted })} />
                            {submitted && !dateFin && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>}
                            {submitted && dateFin && dateDebut && dateDebut>dateFin && <small className="p-invalid" style={{color : "red"}}>La date de fin du cours ne doit être inférieur à la date debut</small>}           
                        </div>
                    </div>

                </div>

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="p-field">
                            <label htmlFor="libelleParcours">Selectionner le parcours beneficiaire de la mission</label>
                            <Dropdown optionLabel="libelleParcours" filter filterBy="libelleParcours" optionValue={curentParcours.libelleParcours} value={curentParcours}  options={listeParcours} onChange={(e) => {onParcoursChange(e)}} required autoFocus className={classNames({ 'p-invalid': submitted })} />
                            {submitted && !curentParcours.libelleParcours && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>}              
                        </div>
                    </div>
                </div>

                <div className="p-grid">
                    <div className="p-col-4" >
                        <div className="p-field">
                            <label htmlFor="libelleSemestre">Selectionner le semestre</label>
                            <Dropdown optionLabel="libelleSemestre" filter filterBy="libelleSemestre" value={curentSemestre}  onChange={(e) => {onSemestreChange(e)}} options={listeSemestre}  required autoFocus className={classNames({ 'p-invalid': submitted })} />
                            {submitted && !curentElementConstitutif.libelleElementConstitutif && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>}              
                        </div>
                    </div>

                    <div className="p-col-4" >
                        <div className="p-field">
                            <label htmlFor="libelleUniteEnseignement">Selectionner l'unite d'enseignement concernee</label>
                            <Dropdown  optionLabel="libelleUniteEnseignement" filter filterBy="libelleUniteEnseignement" value={curentUniteEnseignement} optionValue={curentUniteEnseignement.libelleUniteEnseignement} onChange={(e) => {onUniteEnseignementChange(e)}} required autoFocus className={classNames({ 'p-invalid': submitted })} options={listeUniteEnseignement} />
                            {submitted && !curentUniteEnseignement.libelleUniteEnseignement && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>}              
                        </div>
                    </div>

                    <div className="p-col-4" >
                        <div className="p-field">
                            <label htmlFor="libelleElementConstitutif">Selectionner l'element constitutif</label>
                            <Dropdown optionLabel="libelleElementConstitutif" filter filterBy="libelleElementConstitutif" value={curentElementConstitutif} optionValue={curentElementConstitutif.libelleElementConstitutif}  onChange={(e) => {onElementConstitutifChange(e)}} required autoFocus className={classNames({ 'p-invalid': submitted })} options={listeElementConstitutif} />
                            {submitted && !curentElementConstitutif.libelleElementConstitutif && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>}              
                        </div>
                    </div>
                </div>

                <hr/>
                <div  style={{width:"200px",display:"flex"}} className="mt-3">
                    <Button label="Quitter" icon="pi pi-times" className="p-button-danger mr-2" type='button' onClick={hideDialog} disabled={spin?true:false}  />
                    <Button label='Ajouter' icon={spin?"pi pi-spin pi-spinner":"pi pi-save"} className='p-button-success mr-2' onClick={addInvitation}  disabled={spin?true:false} />
                </div>

                </React.Fragment>
                }
            </Dialog>


            <Dialog visible={detailsInvitationDialog} style={{width: '80%'}} header="Détails du bordereau d'invitation" modal footer={detailsInvitationFooter} onHide={hideDetailInvitation}>
                <hr/>

                <React.Fragment>
                    <div className='p-grid'>

                        <div className='p-col-12'>
                            <div className='card'>
                                <div className='card-header'>
                                    Détails sur le bordereau
                                </div>
                                <div className='card-body'  style={{ flex: '1 1 auto',paddingTop: '1.25rem', paddingRight: '0rem', paddingLeft: '0rem', paddingBottom: '0rem'}}>
                                    <DataTable value={listeInformationBordereau}>
                                        <Column field="numeroBe" header="Numero bordereau" filterMatchMode="contains" sortable={false} filter={false}></Column>
                                        <Column field="dateBe" header="Date bordereau" filterMatchMode="contains" sortable={false} filter={false}></Column>
                                        <Column field="nomIesr" header="Universite de destination" filterMatchMode="contains" sortable={false} filter={false}></Column>
                                        <Column field="etatBe" header="Etat du bordereau" filterMatchMode="contains" sortable={false} filter={false}></Column>
                                        <Column field="posBe" header="Position du bordereau" filterMatchMode="contains" sortable={false} filter={false}></Column>
                                    </DataTable>
                                </div>
                            </div>
                        </div>


                        <div className='p-col-12'>    
                            <div className='card'>
                                <div className='card-header'>
                                    Liste des invitations enseignant concernée
                                </div>
                                <div className='card-body'  style={{ flex: '1 1 auto',paddingTop: '1.25rem', paddingRight: '0rem', paddingLeft: '0rem', paddingBottom: '0rem'}}>
                                <DataTable value={listeInvitationEnseignant} selection={selectedEntites} onSelectionChange={(e) => setSelectedEntites(e.value)} paginator rows={10} globalFilter={globalFilter} selectionMode='none'>
                                    <Column field="parcours.libelleParcours" header="Parcours d'invitation" filterMatchMode="contains" sortable={false} filter={true}></Column>
                                    <Column field="semestre.libelleSemestre" header="Semestre concerné" filterMatchMode="contains" sortable={false} filter={true}></Column>
                                    <Column field="elementConstitutif.libelleElementConstitutif" header="Element constitutif" filterMatchMode="contains" sortable={false} filter={true}></Column>
                                    <Column field="enseignant.nomPrenomEnseignant" header="Enseignant invité" filterMatchMode="contains" sortable={false} filter={true}></Column>
                                    <Column field="dateDebutCours" header="Date de début de la mission" filterMatchMode="contains" sortable={false} filter={true}></Column>
                                    <Column field="dateFinCours" header="Date de fin de la mission" filterMatchMode="contains" sortable={false} filter={true}></Column>
                                    <Column header={actionHeader} body={invitationActionBodyTemplate} style={{textAlign:'center', width: '160px'}}></Column>
                                </DataTable>

                                </div>
                            </div>
                        </div>


                        {informationBordereau.etatActuel==="Rejeter" &&

                            <div className='p-col-12'>
                                <div className="p-grid">
                                    <div className="p-col-12" >
                                        <div className="p-field">
                                            <label htmlFor="causeRejet">Motif de rejet de l'invitation</label>
                                            <hr/>
                                            {informationBordereau.justificatif}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        }

                    </div>
                </React.Fragment>
                
                
                <hr/>
            </Dialog>


            <Dialog visible={detailsReponseBordereauDialog} style={{width: '80%'}} header="Détails réponse bordereau d'invitation" modal footer={detailsReponseInvitationFooter} onHide={hideDetailInvitation}>
                <hr/>

                <React.Fragment>
                    <div className='p-grid'>

                        <div className='p-col-12'>
                            <div className='card'>
                                <div className='card-header'>
                                    Détails sur le bordereau
                                </div>
                                <div className='card-body'  style={{ flex: '1 1 auto',paddingTop: '1.25rem', paddingRight: '0rem', paddingLeft: '0rem', paddingBottom: '0rem'}}>
                                    <DataTable value={listeInformationBordereau}>
                                        <Column field="numeroBe" header="Numero bordereau" filterMatchMode="contains" sortable={false} filter={false}></Column>
                                        <Column field="dateBe" header="Date bordereau" filterMatchMode="contains" sortable={false} filter={false}></Column>
                                        <Column field="nomIesr" header="Universite de destination" filterMatchMode="contains" sortable={false} filter={false}></Column>
                                        <Column field="etatBe" header="Etat du bordereau" filterMatchMode="contains" sortable={false} filter={false}></Column>
                                        <Column field="posBe" header="Position du bordereau" filterMatchMode="contains" sortable={false} filter={false}></Column>
                                    </DataTable>
                                </div>
                            </div>
                        </div>


                        <div className='p-col-12'>    
                            <div className='card'>
                                <div className='card-header'>
                                    Liste des invitations enseignant concernée par la réponse
                                </div>
                                <div className='card-body'  style={{ flex: '1 1 auto',paddingTop: '1.25rem', paddingRight: '0rem', paddingLeft: '0rem', paddingBottom: '0rem'}}>
                                <DataTable value={listeInvitationEnseignant} selection={selectedEntites} onSelectionChange={(e) => setSelectedEntites(e.value)} paginator rows={10} globalFilter={globalFilter} selectionMode='none'>
                                    <Column field="parcours.libelleParcours" header="Parcours d'invitation" filterMatchMode="contains" sortable={false} filter={true}></Column>
                                    <Column field="semestre.libelleSemestre" header="Semestre concerné" filterMatchMode="contains" sortable={false} filter={true}></Column>
                                    <Column field="elementConstitutif.libelleElementConstitutif" header="Element constitutif" filterMatchMode="contains" sortable={false} filter={true}></Column>
                                    <Column field="enseignant.nomPrenomEnseignant" header="Enseignant invité" filterMatchMode="contains" sortable={false} filter={true}></Column>
                                    <Column field="dateDebutCours" header="Date de début de la mission" filterMatchMode="contains" sortable={false} filter={true}></Column>
                                    <Column field="dateFinCours" header="Date de fin de la mission" filterMatchMode="contains" sortable={false} filter={true}></Column>
                                    <Column field="etatActuel" header="Etat de l'invitation" filterMatchMode="contains" sortable={false} filter={true}></Column>
                                    <Column header={actionHeader} body={reponseInvitationActionBodyTemplate} style={{textAlign:'center', width: '160px'}}></Column>
                                </DataTable>

                                </div>
                            </div>
                        </div>

                        {informationBordereau.etatActuel==="Rejeter" &&

                            <div className='p-col-12'>
                                <div className="p-grid">
                                    <div className="p-col-12" >
                                        <div className="p-field">
                                            <label htmlFor="causeRejet">Motif de rejet de l'invitation</label>
                                            <hr/>
                                            {informationBordereau.justificatif}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        }

                    </div>
                </React.Fragment>
            
                <hr/>
            </Dialog>


            <Dialog visible={deleteEntiteDialog} style={{ width: '450px' }} header="Confirmer" modal footer={deleteEntiteDialogFooter} onHide={hideDeleteEntiteDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {informationBordereau && <span>Êtes vous sûr de vouloir supprimer cette invitation ? </span>}
                </div>
            </Dialog>


            <Dialog visible={confirmerEnvoiInvitationDialog} style={{ width: '450px' }} header="Confirmmation d'envoi invitation" modal footer={confirmerEnvoiInvitationFooter} onHide={hideConfirmerInvitationDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-info-circle p-mr-3" style={{ fontSize: '2rem'}} />
                    <center>
                        {informationBordereau && <span>Êtes vous sûr de vouloir confirmer votre accord pour cette invitation ? </span>}
                    </center>
                </div>
            </Dialog>


            <Dialog visible={detailsReponseInvitationDialog} style={{width: '80%'}} header= "Détails de l'invitation" modal footer={detailsInformationReponseInvitationFooter} onHide={hideDetailsReponseInvitationDialog}>
                <hr/>
                {informationInvitation.enseignant && (
                    <React.Fragment>
                        <div className='p-grid'>
                    <div className='p-col-6'>

                        <div className='card'>
                            <div className='card-header'>
                                Détails sur l'enseignant concermé
                            </div>
                            <div className='card-body'  style={{ flex: '1 1 auto',paddingTop: '1.25rem', paddingRight: '0rem', paddingLeft: '0rem', paddingBottom: '0rem'}}>

                                <table className='table table-hover table-bordered table-striped'>
                                    <tbody>
                                        <tr>
                                            <td>Nom et prenom de l'enseignant</td>
                                            <td>{informationInvitation.enseignant.nomPrenomEnseignant}</td>
                                        </tr>
                                        <tr>
                                            <td>Grade enseignant</td>
                                            <td>{informationInvitation.enseignant.grade.libelleGrade}</td>
                                        </tr>
                                        <tr>
                                            <td>Université d'origine</td>
                                            <td>{informationInvitation.enseignant.nomIes}</td>
                                        </tr>
                                        <tr>
                                            <td>UFR</td>
                                            <td>{informationInvitation.enseignant.nomEtablissement}</td>
                                        </tr>
                                        <tr>
                                            <td>Département</td>
                                            <td>{informationInvitation.enseignant.lbDepartementEtab}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>

                    <div className='p-col-6'>

                        <div className='card'>
                            <div className='card-header'>
                                Détails sur le cours d'invitation concerné
                            </div>
                            <div className='card-body'  style={{ flex: '1 1 auto',paddingTop: '1.25rem', paddingRight: '0rem', paddingLeft: '0rem', paddingBottom: '0rem'}}>
                                <table className='table table-hover table-bordered table-striped'>
                                    <tbody>
                                        <tr>
                                            <td>Parcours concerné par le cours</td>
                                            <td>{informationInvitation.parcours.libelleParcours}</td>
                                        </tr>
                                        <tr>
                                            <td>Semestre</td>
                                            <td>{informationInvitation.semestre.libelleSemestre}</td>
                                        </tr>
                                        <tr>
                                            <td>Element constitutif</td>
                                            <td>{informationInvitation.elementConstitutif.libelleElementConstitutif}</td>
                                        </tr>
                                        <tr>
                                            <td>Date de debut du cours</td>
                                            <td>{informationInvitation.dateDebutCours}</td>
                                        </tr>
                                        <tr>
                                            <td>Date de fin du cours</td>
                                            <td>{informationInvitation.dateFinCours}</td>
                                        </tr>
                                        <tr>
                                            <td>Evolution de l'invitation</td>
                                            <td>{informationInvitation.posDemande}</td>
                                        </tr>
                                        <tr>
                                            <td>Etat de l'invitation</td>
                                            <td>{informationInvitation.etatActuel}</td>
                                        </tr>
                                        {informationInvitation.etatActuel==="Rejeter" && (
                                            <tr>
                                                <td>Motif du rejet</td>
                                                <td>{informationInvitation.motifRejet}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                    
                </div>
                    </React.Fragment>
                )
                }
                <hr/>
            </Dialog>

        </div>
    )

}