import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import classNames from 'classnames'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Notify } from '../../components/Notify'
import { Spinner } from '../../components/Spinner'
import { ToastContainer} from 'react-toastify'
import { Dropdown } from 'primereact/dropdown'
import { StructureService } from '../service/StructureService'
import { DemandeService } from '../service/DemandeService'
import { FileUpload } from 'primereact/fileupload';
import { Editor } from 'primereact/editor';
import { SessionService } from '../service/SessionService'


export class Depot extends Component {


    constructor() {

        super();
        this.state = {
            session : null,
            demandeDialog: false,
            deleteDemandeDialog: false,
            demande: {},
            listeDemande: [],
            listeSession: [],
            submitted: false,
            loading : false,
            globalFilter: null,
            file: null,
            structure : {},
            informationDiplome: {
                intituleDiplome: "",
                anneeObtention: "",
                etablissementObtention: "",
                scanDiplome: null
            },
            listeDiplomes: [],
            isDemandeur : 0,
            isStructure : 0,
            idClient: null,
            ajoutDisciplineDialog: false,
            newDiscipline: {
                libelleMatiere: ""
            },
            ajoutDisciplineSubmitted: false,
            ajoutDiplomeDialog: false,
            newDiplome: {
                intituleDiplome: "",
                anneeObtention: "",
                etablissementObtention: "",
                scanDiplome: null
            },
            ajoutDiplomeSubmited: false,
            typeOperation: "AUTORISATION_ENSEIGNER"
        }

        this.spinner = new Spinner()
        this.notify = new Notify()
        this.structureService = new StructureService()
        this.demandeService = new DemandeService()
        this.sessionService = new SessionService()
    }

    componentDidMount = () => {

        const tokenDecoded = localStorage.getItem('tokenDecoded')
        const token = JSON.parse(tokenDecoded)

        console.log(token.email)

        this.setState({
            idClient: token.email,
            nomPrenom: token.name
        })

        const campusStructure = localStorage.getItem('campusStructure')
        const campusDemandeur = localStorage.getItem('campusDemandeur')
        const isStructure = parseInt(JSON.parse(campusStructure))
        const isDemandeur = parseInt(JSON.parse(campusDemandeur))

        const structure = localStorage.getItem('structure')
        const struct = JSON.parse(structure)
        console.log(struct)
        this.setState({structure : struct});

        let today = new Date();
        let year = today.getFullYear();

        this.setState({loading : true})
        if(isDemandeur){
            this.setState({isDemandeur : 1})
            
            this.sessionService.get(year,385, "NIVEAU_III" ).then(data => {
                console.log(data)
                this.setState({listeSession : data})
                this.setState({loading :false})
            })
            .catch(error => {
                console.log(error)
                this.setState({loading :false})
            })
            /*this.demandeService.getDemandeByTypeOperationIdClient(this.state.typeOperation, token.email).then(data => {
                this.setState({listeDemande : data, loading : false})
            })
            .catch(error => {
                //console.log(error)
                this.setState({loading :false})
            })*/
            this.setState({loading :false})
        }
        else if(isStructure){
            this.setState({isStructure : 1})
            this.sessionService.get(year, struct.idStructure, struct.niveau).then(donnee => {
                console.log(donnee);
                this.setState({listeSession : donnee, loading : false});
            })
            .catch(error => {
                console.log(error);
                this.notify.echec(error.response.data.message);
            })
            /*this.demandeService.getAll().then(data => {
                this.setState({listeDemande : [data], loading : false})
            })
            .catch(error => {
                //console.log(error)
                this.setState({loading :false})
            })*/
            
            //this.setState({loading :false})
        }
        else {
            console.log("bug")
        }
    }

    openNew = (session) => {

        //if(this.state.listeDemande.length===0){
            this.setState({
                session : session,
                demande: {
                    idDemande: null,
                    disciplinesEnseignement: [],
                    cv: null,
                    listeDiplomes: [],
                    idSession: session.idSession,
                    nomPrenom: this.state.nomPrenom,
                },
                submitted: false,
                demandeDialog: true
            })
        /*}else{
            alert("Vous avez déjà deposé un dossier")
        }*/
        

    }


    ajouterDiscipline = () => {
        this.setState({
            ajoutDisciplineDialog: true,
        })
    }

    hideDiscipline = () => {
        this.setState({
            newDiscipline: {
                libelleMatiere: ""
            },
            ajoutDisciplineDialog: false,
            ajoutDisciplineSubmitted: false
        })
    }


    ajoutDiscipline = () => {
        let listeMatiere = this.state.demande.disciplinesEnseignement
        let demande = this.state.demande

        this.setState({
            ajoutDisciplineSubmitted: true
        })

        if(this.state.newDiscipline.libelleMatiere && this.state.newDiscipline.libelleMatiere.trim()){
            listeMatiere.push(this.state.newDiscipline)
            demande['disciplinesEnseignement'] = listeMatiere

            this.setState({
                demande
            })

            console.log(demande)
            this.hideDiscipline()
        }
    }


    hideDialog = () => {
        this.setState({
            submitted: false,
            demandeDialog: false,
            demande: {
                idDemande: null,
                disciplinesEnseignement: [],
                cv: null,
                listeDiplomes: []
            },
            listeDiplomes: []
        });
    }

    onValueChange = (e, field) => {

        const val = (e.target && e.target.value) || '';
        let demande = this.state.demande; 
        demande[`${field}`] = val;
        this.setState({demande})     
    }


    onMatiereValueChange = (e, field) => {

        const val = (e.target && e.target.value) || '';
        let newDiscipline = this.state.newDiscipline; 
        newDiscipline[`${field}`] = val;
        this.setState({newDiscipline})     
    }

    onDiplomeValueChange = (e, field) => {

        const val = (e.target && e.target.value) || '';
        let informationDiplome = this.state.informationDiplome; 
        informationDiplome[`${field}`] = val;
        this.setState({informationDiplome})     
    }


    saveDemande = () => {

        this.setState({
            submitted: true
        })

        let curentDemande = this.state.demande

        curentDemande['idClient'] = this.state.idClient
        curentDemande['typeDemande'] = "AUTORISATION_ENSEIGNER"

        console.log(curentDemande)
        
        if (
                curentDemande.cv 
                && curentDemande.resumeCV.trim() 
                && 
                curentDemande.listeDiplomes 
                && 
                curentDemande.listeDiplomes.length>0 
                && 
                curentDemande.disciplinesEnseignement 
                && 
                curentDemande.disciplinesEnseignement.length>0
            ) {

                this.demandeService.create(curentDemande).then(data => {
                    this.notify.success()
                    this.setState({loading : true})

                    this.demandeService.getDemandeByTypeOperationIdClient(this.state.typeOperation, this.state.idClient).then(data => 
                        this.setState({listeDemande: data, loading : false})
                        )
                        .catch(error => {
                            this.notify.echec(error.response.data.message)  
                            console.log(error)
                            this.setState({loading : false})
                        })
                })
                .catch(error => {
                    this.notify.echec(error.response.data.message)
                    console.log(error);
                })

            }

        this.hideDialog()
    }


    editDemande = (demande) => {
        this.setState({
            listeDiplomes: demande.listeDiplomes,
            demande: demande,
            demandeDialog: true

        })
    }

    detailDemande = (demande) => {
        this.setState({
            listeDiplomes: demande.listeDiplomes,
            demande: demande,
            demandeDialog: true

        })
    }

    

    confirmDeleteDemande = (demande) => {
        this.setState({
            demande,
            deleteDemandeDialog: true
        });
    }


    hideDeleteDemandeDialog = () => {
        this.setState({ deleteDemandeDialog: false });
    }


    deleteDemande = () => {
        let demande = this.state.demande

        this.demandeService.delete(demande.idDemande).then(data => {
            this.notify.success()
            this.setState({loading : true})

            this.demandeService.getDemande(this.state.idClient).then(data => 
                this.setState({listeDemande:data, loading : false})
            ).catch(error => {
                this.notify.echec(error.response.data.message)  
                console.log(error)
                this.setState({loading : false})
            })
        })
        .catch(error => {
            this.notify.echec(error.response.data.message)
            console.log(error);
        })
        
        this.hideDeleteDemandeDialog()
        
    }


    cvUploadHandler = ({files}) => {

       let demande = this.state.demande
        const [file] = files;
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            demande['cv'] = e.target.result

           this.setState({ demande })

           console.log(demande)
        };
        fileReader.readAsDataURL(file);
      };



      fileUploadHandler = ({files}) => {

       let informationDiplome = this.state.informationDiplome
        const [file] = files;
        const fileReader = new FileReader();
        fileReader.onload = (e) => {

            informationDiplome['scanDiplome'] = e.target.result
           this.setState({ informationDiplome})
        };
        fileReader.readAsDataURL(file);
      };


      viewDiplome = (diplomeInformation) => {
        this.setState({
            curentFile: diplomeInformation.scanDiplome,
            viewDiplomeDialog: true
        })
      }

      hideDiplome = () => {
        this.setState({
            curentFile: null,
            viewDiplomeDialog: false
        })
      }


      confirmDeleteDiplome = (diplomeData) => {

        let curentListeDiplome = this.state.demande.listeDiplomes
        let demande = this.state.demande

        let newListeDiplome = curentListeDiplome.filter((data)=>{
            return data.intituleDiplome !== diplomeData.intituleDiplome
        })

        demande['listeDiplomes'] = newListeDiplome

        this.setState({
            listeDiplomes: newListeDiplome
        })
      }

      confirmDeleteDiscipline = (discipline) => {
        console.log(discipline)

        let listeDiscipline = this.state.demande.disciplinesEnseignement
        let demande = this.state.demande

        let newListeDiscipline = listeDiscipline.filter(curentDiscipline => {

            return curentDiscipline.libelleMatiere!==discipline.libelleMatiere
        })

        demande['disciplinesEnseignement'] = newListeDiscipline

        this.setState({
            demande
        })
      }


      hideAddDiplome = () => {

        this.setState({
            ajoutDiplomeDialog: false,
            ajoutDiplomeSubmited: false,
            newDiplome: {
                intituleDiplome: "",
                anneeObtention: "",
                etablissementObtention: "",
                scanDiplome: null
            }
            
        })
      }


      ajoutDiplome = () => {
        this.setState({
            ajoutDiplomeSubmited: true
        })

        if(
            this.state.informationDiplome.intituleDiplome 
            && 
            this.state.informationDiplome.intituleDiplome.trim() 
            && 
            this.state.informationDiplome.scanDiplome 
            &&
            this.state.informationDiplome.anneeObtention 
            && 
            this.state.informationDiplome.anneeObtention.trim()
            &&
            this.state.informationDiplome.etablissementObtention 
            && 
            this.state.informationDiplome.etablissementObtention.trim()
            ){
            
            let listeDiplomes = this.state.demande.listeDiplomes
            let demande = this.state.demande

            listeDiplomes.push(this.state.informationDiplome);

            demande['listeDiplomes'] = listeDiplomes

            this.setState({
                demande,
                informationDiplome: {
                    intituleDiplome: "",
                    anneeObtention: "",
                    etablissementObtention: "",
                    scanDiplome: null
                }
            })

            this.hideAddDiplome()

        }
      }


      ajouterNouveauDiplome = () => {
        this.setState({
            ajoutDiplomeDialog: true
        })
      }


      setResumeCV = (texteValue) => {
        let demande = this.state.demande
        demande['resumeCV'] = texteValue

        this.setState({
            demande
        })
      }
  
      listeDemandeSession = (session) => {

      }
      


    actionBodyTemplate = (rowData) => {
        return (
            this.state.isDemandeur ?
                <React.Fragment>
                    <Button type="button" label="Déposer" icon="" className="p-button-success" onClick={() => this.openNew(rowData)} style={{marginRight: '.5em'}}/>
                    {/*<Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteDemande(rowData)}/>*/}
                </React.Fragment>
                :
                <React.Fragment>
                    <Button type="button" icon="pi pi-list" className="p-button-success" onClick={() => this.listeDemandeSession(rowData)} style={{marginRight: '.5em'}}/>
                
                </React.Fragment>
        );
    }


    diplomeActionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteDiplome(rowData)}/>
            </React.Fragment>
        );
    }



    disciplineActionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteDiscipline(rowData)}/>
            </React.Fragment>
        );
    }

    visualisationButton = (rowData) => {
        return ( <Button label="Visualiser" severity="success" rounded onClick={() => this.viewDiplome(rowData)} 
        />)
    }

    
    render(){

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;

        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );

        const demandeDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveDemande} />
            </React.Fragment>
        );


        const ajoutDisciplineDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDiscipline} />
                <Button label="Ajouter" icon="pi pi-check" className="p-button-text" onClick={this.ajoutDiscipline} />
            </React.Fragment>
        );


        const ajoutDiplomeDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideAddDiplome} />
                <Button label="Ajouter" icon="pi pi-check" className="p-button-text" onClick={this.ajoutDiplome} />
            </React.Fragment>
        );

        const diplomeDialogFooter = (
            <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDiplome} />
        )

        const deleteDemandeDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteDemandeDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteDemande() } />
            </React.Fragment>
        );
        
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    {/*
                        this.state.isDemandeur  ?
                        <div className="p-grid" style={{marginLeft : 20}}>
                            <div className="p-col-2">
                                <Button label="Deposer mon dossier" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                            </div>
                        </div>
                        :
                        ""
                    */}
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeSession} selectionMode="single" header={"Liste des sessions ouvertes"} paginator={true} rows={10}
                            globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="libelleSession" header="Session"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column field="anneeSession" header="Année"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column field="libelleTypeDemande" header="Type de demande"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column field="libelleTypeSession" header="Type de session"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }
                    </div>
                </div>



                <Dialog visible={this.state.demandeDialog} style={{ width: '75%' }} header="Formulaire de demande d'autorisation à enseigner" modal className="p-fluid" footer={demandeDialogFooter} onHide={this.hideDialog}>
   
                    {/*
                    <div className="p-grid">
                        <div className="p-field p-col-4">
                            <label htmlFor="nomPrenom">Nom et prenom(s) *</label>
                            <InputText id="nomPrenom" value={this.state.demande.nomPrenom}  onChange={(e) => this.onValueChange(e, 'nomPrenom')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder='Nom et prenom'/>
                            {this.state.submitted && !this.state.demande.nomPrenom && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                        
                        <div className="p-field p-col-4">
                            <label htmlFor="dateNaissance">Date de naissance *</label>
                            <InputText id="dateNaissance" type="date" value={this.state.demande.dateDeNaissance}  onChange={(e) => this.onValueChange(e, 'dateDeNaissance')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder='Date de naissance'/>
                            {this.state.submitted && !this.state.demande.dateDeNaissance && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                        
                        <div className="p-field p-col-4">
                            <label htmlFor="lieuNaissance">Lieu de naissance</label>
                            <InputText id="lieuNaissance" value={this.state.demande.lieuDeNaissance}  onChange={(e) => this.onValueChange(e, 'lieuDeNaissance')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder='Lieu de naissance'/>
                            {this.state.submitted && !this.state.demande.lieuDeNaissance && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                    </div>
                    */}

                    <hr/>
                    <div className="p-field p-col-12">

                        <DataTable  ref={(el) => this.dt = el} value={this.state.demande.disciplinesEnseignement}  header="Liste des matières à enseigner voulue" paginator={true} rows={10}
                            globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true}>
                            <Column field="libelleMatiere" autoFocus header="Intitulé de la matière"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par matière'/>
                            <Column header={actionHeader} body={this.disciplineActionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>

                        {this.state.submitted && (!this.state.demande.disciplinesEnseignement || this.state.demande.disciplinesEnseignement.length===0) && <small className="p-invalid" style={{color : "red"}}>Choisir au moins une discipline.</small>}
                    </div>

                    <div className="p-grid">
                        <div className="p-col-4">
                            <Button label="Ajouter une matière à enseigner" icon="pi pi-plus" onClick={this.ajouterDiscipline} className='p-col-4' />
                        </div>
                    </div>


                    <hr/>
                    <div className="p-field p-col-12">

                        <DataTable  ref={(el) => this.dt = el} value={this.state.demande.listeDiplomes} header="Liste des diplomes" paginator={true} rows={10}
                            globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true}>
                            <Column field="intituleDiplome" header="Intitulé diplome"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par intitulé'/>
                            <Column field="etablissementObtention" header="Lieu d'obtention"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par lieu'/>
                            <Column field="anneeObtention" header="Anné d'obtention"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par année'/>
                            <Column body={this.visualisationButton}  header="Aperçu du diplome"  filterMatchMode="contains" sortable={false} filter={false}/>
                            <Column header={actionHeader} body={this.diplomeActionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>

                        {this.state.submitted && (!this.state.demande.listeDiplomes || this.state.demande.listeDiplomes.length===0) && <small className="p-invalid" style={{color : "red"}}>Charger au moins un diplome.</small>}
                    </div>

                    <div className="p-grid">
                        <div className="p-col-4">
                            <Button label="Ajouter un diplome" icon="pi pi-plus" onClick={this.ajouterNouveauDiplome} className='p-col-4' />
                        </div>
                    </div>


                    <hr/>
                    <div className="p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="diplome">Charger votre CV</label>
                            <FileUpload id="diplome" customUpload={true} uploadHandler={this.cvUploadHandler} auto={true} multiple={false} accept=".pdf" maxFileSize={1500000} emptyTemplate={<p className="m-0">Glisser déposer ici pour charger.</p>} chooseLabel="Choisir le diplome" />
                            {this.state.submitted && !this.state.demande.cv && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                    </div>

                    <div className="p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="resumeCV">Rédiger un resumé de votre CV</label>

                            {/*<Editor value={this.state.demande.resumeCV} onTextChange={(e) => this.setResumeCV(e.htmlValue)} style={{ height: '320px' }} />*/}
                            <InputTextarea id="resumeCV" value={this.state.demande.resumeCV}  onChange={(e) => this.onValueChange(e, 'resumeCV')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder='Résumé du CV' rows={8}/>
                            {this.state.submitted && !this.state.demande.resumeCV && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}

                        </div>
                    </div>

                </Dialog>


                <Dialog visible={this.state.ajoutDiplomeDialog} style={{ width: '75%' }} header="Formulaire d'ajout des diplômes" modal className="p-fluid" footer={ajoutDiplomeDialogFooter} onHide={this.hideAddDiplome}>
        
                    <div className="p-grid">

                        <div className="p-field p-col-8">
                            <label htmlFor="intituleDiplome">Preciser le diplome</label>
                            <InputText id="intituleDiplome" autoFocus value={this.state.informationDiplome.intituleDiplome}  onChange={(e) => this.onDiplomeValueChange(e, 'intituleDiplome')} required className={classNames({ 'p-invalid': this.state.submitted })} placeholder='Precision diplome'/>
                            {this.state.ajoutDiplomeSubmited && !this.state.informationDiplome.intituleDiplome && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>


                        <div className="p-field p-col-4">
                            <label htmlFor="anneeObtention">Preciser l'anné d'obtention</label>
                            <InputText id="anneeObtention" value={this.state.informationDiplome.anneeObtention}  onChange={(e) => this.onDiplomeValueChange(e, 'anneeObtention')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Precision l'année d'obtention"/>
                            {this.state.ajoutDiplomeSubmited && !this.state.informationDiplome.anneeObtention && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>

                        <div className="p-field p-col-12">
                            <label htmlFor="etablissementObtention">Préciser l'établissement d'obtention</label>
                            <InputText id="etablissementObtention" value={this.state.informationDiplome.etablissementObtention}  onChange={(e) => this.onDiplomeValueChange(e, 'etablissementObtention')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Lieu obtention diplôme"/>
                            {this.state.ajoutDiplomeSubmited && !this.state.informationDiplome.etablissementObtention && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>

                        <div className="p-field p-col-12">
                            <label htmlFor="diplome">Charger le diplome</label>
                            <FileUpload id="diplome" customUpload={true} uploadHandler={this.fileUploadHandler} clearHandler={this.clearFile} auto={true} accept=".pdf" multiple={false} maxFileSize={1500000} emptyTemplate={<p className="m-0">Glisser déposer ici pour charger.</p>} chooseLabel="Choisir le diplome" />
                            {this.state.ajoutDiplomeSubmited && !this.state.informationDiplome.scanDiplome && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                        </div>
                    </div>

                </Dialog>


                <Dialog visible={this.state.viewDiplomeDialog} style={{ width: '75%' }} header="Visualisation du diplome" modal className="p-fluid" footer={diplomeDialogFooter} onHide={this.hideDiplome}>
                    <iframe title="Visualisation de diplome" width="100%" height="700" src={this.state.curentFile}></iframe>
                </Dialog>
   
                



                <Dialog visible={this.state.deleteDemandeDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteDemandeDialogFooter} onHide={this.hideDeleteDemandeDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.demande && <span>Etes-vous sûr devouloir supprimer cet élément ?</span>}
                    </div>
                </Dialog>


                <Dialog visible={this.state.ajoutDisciplineDialog} style={{ width: '50%' }} header="Formulaire d'ajout de matière à enseigner" modal className="p-fluid" footer={ajoutDisciplineDialogFooter} onHide={this.hideDiscipline}>
        
                <div className="p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="libelleMatiere">Intitulé de la matière à enseigner *</label>
                        <InputText id="libelleMatiere" autoFocus value={this.state.newDiscipline.libelleMatiere}  onChange={(e) => this.onMatiereValueChange(e, 'libelleMatiere')} required className={classNames({ 'p-invalid': this.state.submitted })} placeholder='intitulé de la matiere a enseigner'/>
                        {this.state.ajoutDisciplineSubmitted && !this.state.newDiscipline.libelleMatiere && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    </div>
                </Dialog>

                        


            </div> 
        )
    }
}

export default Depot

