import React, {useEffect, useState, useRef } from 'react'
import { Button } from "primereact/button";
import { Chart } from "primereact/chart";
import { Panel } from 'primereact/panel';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea'
import { InputText } from 'primereact/inputtext'
import { RadioButton } from 'primereact/radiobutton';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import { ToastContainer} from 'react-toastify'
import { Spinner } from '../../components/Spinner';
import { DemandeService } from '../service/DemandeService'
import { Notify } from '../../components/Notify';
import md5 from 'md5';

function DashbordClient() {
  const [loading, setLoading] = useState(false);
  const [loadSpin, setLoadSpin] = useState(false);
  const [listeDemande, setListeDemande] = useState([]);
  const [demande, setDemande] = useState({})
  const [globalFilter, setGlobalFilter] = useState(null)
  const [viewListeDemandeDialog, setViewListeDemandeDialog] = useState(false)
  const [viewDetailsDemandeDialog, setViewDetailsDemandeDialog] = useState(false)
  const [viewDiplomeDialog, setViewDiplomeDialog] = useState(false)
  const [deleteDemandeDialog, setDeleteDemandeDialog] = useState(false)
  const [currentFile, setCurrentFile] = useState(null)
  const [selectedDemandes, setSelectedDemandes] = useState(null);
  const [mailClient, setMailClient] = useState(null)
  const [payerAutorisationDialog, setPayerAutorisationDialog] = useState(false)
  const [payerReconnaissanceDialog, setPayerReconnaissanceDialog] = useState(false)
  const [informationDemande, setInformationDemande] = useState({})
  const [messageError, setMessageError] = useState(null)
  
  const dt = useRef(null);

  const demandeService = new DemandeService()

  const spinner = new Spinner()
  const notify = new Notify()

  useEffect(() => {
    
    const tokenDecoded = localStorage.getItem('tokenDecoded')
    const token = JSON.parse(tokenDecoded)

    setMailClient(token.email)

    setLoading(true)
    demandeService.getDemandesClient(token.email).then(data => {
      //console.log(data)
      setListeDemande(data)
      setLoading(false)
    })
    .catch(error => {
      console.log(error)
      setLoading(false)
    })
}, []);

const detailDemande = (demandeClient) => {
  //console.log(demandeClient)
  setDemande(demandeClient)
  if(demandeClient.typeDemande === "AUTORISATION_ENSEIGNER"){
    setViewListeDemandeDialog(true)

  }
  else if(demandeClient.typeDemande === "RECONNAISSANCE_DIPLOME") {
    setViewDetailsDemandeDialog(true)
  }
  else alert("Ce type de demande est incconu !")
}



const deleteDemande = () => {
  //console.log(demande)
  setLoadSpin(true)
  demandeService.delete(demande.idDemande).then(data => {
      notify.success()
      setLoading(true)
      setLoadSpin(false)
      hideDeleteDemandeDialog()
      hideListeDemandeDialog()

        demandeService.getDemandesClient(mailClient).then(data => {
        //console.log(data)
        setListeDemande(data)
        setLoading(false)
        })
        .catch(error => {
        console.log(error)
        setLoading(false)
        })
  })
  .catch(error => {
      notify.echec(error.response.data.message)
      console.log(error);
      setLoadSpin(false)
  })
}


const confirmDeleteDemande = (demande) => {
  setDemande(demande)
  setDeleteDemandeDialog(true)
}


const hideListeDemandeDialog = () => {
  setDemande({})
  setViewListeDemandeDialog(false)
  
}

const payerDossier = () => {

    //On recupere les details avec les informations du paiement
    demandeService.getInformationPaiementDemande(demande.idDemande).then(data => {
      //console.log(data)
      setInformationDemande(data)
    }).catch(error => {
      console.log(error)
      notify.echec(error.response.data.message)
    })

    if(demande.typeDemande==="RECONNAISSANCE_DIPLOME" && demande.statutDemande==="IMPAYE"){
      setPayerReconnaissanceDialog(true)
    }else if(demande.typeDemande==="AUTORISATION_ENSEIGNER" && demande.statutDemande==="IMPAYE"){
      setPayerAutorisationDialog(true)
    }
    
}


const viewDocumentByUrl = (urlDocument) => {

  const documentDto = {
      id: demande.idDemande,
      urlFile: urlDocument
  }

  //console.log(documentDto)

  demandeService.getDocumentsDemande(documentDto).then(data => {
      //console.log(data)
      setCurrentFile(data.value)
      setViewDiplomeDialog(true)
  }).catch(error => {
      console.log(error)
      this.notify.echec(error.response.data.message)
  })

}


const hideDiplome = () => {
  setCurrentFile(null)
  setViewDiplomeDialog(false)
}
const hideDetailDemande = () => {
  setDemande({})
  setViewDetailsDemandeDialog(false)
  setMessageError(null)
}

const hideDeleteDemandeDialog = () => {
  setDeleteDemandeDialog(false)
}


const initierPaiementLigdicash = () => {
  setMessageError(null)
  let curentDate = Date()
        let structureData = {
            commande: {
                invoice: {
                    items: [
                        {
                            name: "Paiement des frais de dossier autorisation d'enseigner",
                            description: "Il s'agit du paiement des frais de dossier autorisation d'enseigner par LigdiCach",
                            quantity: 1,
                            unit_price: informationDemande.fraisDepot.montant,
                            total_price: informationDemande.fraisDepot.montant
                        }
                    ],
                    total_amount: informationDemande.fraisDepot.montant,
                    devise: "XOF",
                    description: "Paiement des frais de dossier autorisation d'enseigner",
                    customer: "",
                    customer_firstname: informationDemande.nomPrenom,
                    customer_lastname: informationDemande.nomPrenom,
                    customer_email: informationDemande.idClient
                },
                store: {
                    name: "Plateforme integrée de l'enseignement superieur",
                    website_url: "https://services.campusfaso.bf"
                },
                actions: {
                    cancel_url: "https://services.campusfaso.bf/#/lmd/parametres/test-paiement?dossierId=12result=filed",
                    return_url: "https://services.campusfaso.bf/#/lmd/parametres/test-paiement?dossierId=12&result=success",
                    callback_url: "https://services.campusfaso.bf/lmd/parametres/test-paiement"
                },
                custom_data: {
                    transaction_id: md5(informationDemande.idDemande+curentDate),
                    idDemandeAutorisation: informationDemande.idDemande
                }
            }
        }

        let headerData = {
            headers: {
                Apikey: informationDemande.proParametreLigdiCash.apiKey,
                Authorization: "Bearer " + informationDemande.proParametreLigdiCash.apiAuthorisation,
                Accept: "application/json",
                'Content-Type': "application/json"
            }
        }

        //console.log(headerData);

        demandeService.effectuerPaiement(structureData, headerData).then(data => {
          //console.log(data)


          if(data.response_code==="00"){
            hideDialogPaiementAuto()
            hideDialogPaiementReconnaissance()
            hideDetailDemande()

            let dtoInitiationPaiement = {
              token: data.token,
              transactionId: md5(informationDemande.idDemande+curentDate),
              ProDemande: demande,
              idDemande: demande.idDemande
            }

            //console.log(dtoInitiationPaiement)

            demandeService.initierPaiementLigdicash(dtoInitiationPaiement).then(data => {
              window.location.replace(data.response_text);
            }).catch(error => {
              console.log(error)
              notify.echec(error.response.data.message)
            })

            //
          }else{
            setMessageError(data.message)
            notify.echec(data.message)
          }
        }).catch(error => {
          console.log(error)
          notify.echec(error.response.data.message)
        })
        
    }

const hideDialogPaiementAuto = () => {
  setPayerAutorisationDialog(false)
  setInformationDemande({})
  setMessageError(null)
}

const hideDialogPaiementReconnaissance = () => {
  setPayerReconnaissanceDialog(false)
  setInformationDemande({})
  setMessageError(null)
}


let actionHeader = <Button type="button" icon="pi pi-cog"/>;

const actionBodyTemplate = (rowData) => {
  return (
      
          <React.Fragment>
              <Button type="button" icon="pi pi-eye" className="p-button-success" onClick={() => detailDemande(rowData)} style={{marginRight: '.5em'}}/>
              <Button type="button" icon="pi pi-trash" className="p-button-danger" onClick={() => confirmDeleteDemande(rowData)} style={{marginRight: '.5em'}}/>
          
          </React.Fragment>
  );
}

const viewDemandeDialogFooter = (
  <React.Fragment>
      {demande.statutDemande==="IMPAYE" && <Button label="Payer les frais de dossier" icon="pi pi-money-bill" className="p-button-text" onClick={payerDossier} />}
      <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideListeDemandeDialog} /> 
  </React.Fragment>
);

const buttonVisualisationDocument = (rowData) => {
  return ( <Button label="Visualiser" severity="success" rounded onClick={() => viewDocumentByUrl(rowData.scanDiplome)} 
  />)
}
const diplomeDialogFooter = (
  <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDiplome} />
)

const detailsDemandeDialogFooter = (
  <React.Fragment>
    {demande.statutDemande==="IMPAYE" && <Button label="Payer les frais de dossier" icon="pi pi-money-bill" className="p-button-text" onClick={payerDossier} /> }
    <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDetailDemande} />
  </React.Fragment>
);
const deleteDemandeDialogFooter = (
  <React.Fragment>
      <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDemandeDialog} disabled={loadSpin} />
      <Button label="OUI" icon={loadSpin?"pi pi-spin pi-spinner":"pi pi-check"}  className="p-button-text" onClick={()=> deleteDemande() } disabled={loadSpin}/>
  </React.Fragment>
);


//setPayerAutorisationDialog(false)
const paiementAutorisationDialogFooter = (
  <React.Fragment>
    <Button label="Payer" icon="pi pi-money-bill" className="p-button-text" onClick={initierPaiementLigdicash} />
    <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDialogPaiementAuto} /> 
  </React.Fragment>
);


const paiementReconnaissanceDialogFooter = (
  <React.Fragment>
    <Button label="Payer" icon="pi pi-money-bill" className="p-button-text" onClick={initierPaiementLigdicash} />
    <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDialogPaiementReconnaissance} /> 
  </React.Fragment>
);


  return (
    <div className="p-grid" style={{marginTop : 20}}>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
      <ToastContainer />
      <div className="p-col-12 p-grid">
      {loading ?
            <div className="p-grid">
                <div className="p-col-6" ></div>
                <div className="p-col-6" >
                    {spinner.loadingSpinner("spin", "black")}
                </div>
            </div>
            :
      <DataTable  value={listeDemande} header="Liste des dépôts"  paginator={true} rows={5}
        filterMatchMode="contains" responsive={true}  >
        <Column field="nomPrenom" header="Prénom et nom"/>
        <Column field="idClient" header="Email" />
        <Column field="typeDemande" header="Nature du dossier" />
        <Column header={actionHeader} body={actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
      </DataTable>
      }
      </div>
      <Dialog visible={viewListeDemandeDialog} style={{ width: '75%' }} header="Informations de la demande" modal className="p-fluid" footer={viewDemandeDialogFooter} onHide={hideListeDemandeDialog}>
                <hr/>
                {demande.statutDemande==="PAYE" && (
                    <div className="alert alert-success" role="alert">
                      Paiement des frais de dossier déjà effectué
                    </div>)
                }


                    <div className="p-field p-col-12">

                        <DataTable  
                          ref={dt} selection={selectedDemandes} onSelectionChange={(e) => setSelectedDemandes(e.value)}
                          dataKey="id" paginator rows={10} 
                          globalFilter={globalFilter}
                          value={demande.disciplinesEnseignement}  
                          header="Liste des matières à enseigner voulue" 
                          filterMatchMode="contains" responsive={true}>
                            <Column field="libelleMatiere" autoFocus header="Intitulé de la matière"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par matière'/>
                        </DataTable>
                    </div>



                    <hr/>
                    <div className="p-field p-col-12">

                        <DataTable   value={demande.listeDiplomes} header="Liste des diplomes" paginator={true} rows={10}
                            globalFilter={globalFilter} filterMatchMode="contains" responsive={true}>
                            <Column field="intituleDiplome" header="Intitulé diplome/ document"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par intitulé'/>
                            <Column field="etablissementObtention" header="Lieu d'obtention"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par lieu'/>
                            <Column field="origineDiplome" header="Lieu d'obtention"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par lieu'/>
                            <Column field="anneeObtention" header="Anné d'obtention"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par année'/>
                            <Column body={buttonVisualisationDocument}  header="Aperçu du diplome"  filterMatchMode="contains" sortable={false} filter={false}/>
                        </DataTable>
                    </div>


                    <hr/>
                    <div className="p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="diplome">CV</label>
                            <Button label="Visualiser le CV" severity="success" rounded onClick={() => viewDocumentByUrl(demande.cv)} />
                        </div>
                    </div>

                    <div className="p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="resumeCV">Resumé du CV</label>
                            <InputTextarea id="resumeCV" value={demande.resumeCV} required  readOnly placeholder='Résumé du CV' rows={8}/>
                        </div>
                    </div>

                </Dialog>
                <Dialog visible={viewDiplomeDialog} style={{ width: '75%' }} header="Visualisation du diplome" modal className="p-fluid" footer={diplomeDialogFooter} onHide={hideDiplome}>
                    <iframe title="Visualisation de diplome" width="100%" height="700" src={currentFile}></iframe>
                </Dialog>

                <Dialog visible={viewDetailsDemandeDialog} style={{ width: '75%' }} header="Détails de la demande d'équivalence de diplôme" modal className="p-fluid" footer={detailsDemandeDialogFooter} onHide={hideDetailDemande}>
   


                {demande.statutDemande==="PAYE" && (
                  
                    <div className="alert alert-success" role="alert">
                      Paiement des frais de dossier déjà effectué
                    
                </div>)
                }
                
                <div className="p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="nomEtablissement">Etablissement d'obtention du diplôme</label>
                        <InputText id="nomEtablissement" readOnly autoFocus value={demande.nomEtablissement}  />
                    </div>
                </div>

                <hr/>

                <div className="p-field p-col-12">
                    <label>Type d'établissement</label>
                    <hr/>
                    <div className="p-grid">
                        <div className="p-field p-col-3">
                            <RadioButton inputId="etablissementPublic" name="etablissementPublic" readOnly value={true} checked={demande.etablissementPublic} />
                            <label htmlFor="etablissementPublic" className="ml-2">Etablissement public</label>
                        </div>
                        <div className="p-field p-col-3">
                            <RadioButton inputId="etablissementPrive" name="etablissementPublic" readOnly value={false}  checked={!demande.etablissementPublic} />
                            <label htmlFor="etablissementPrive" className="ml-2">Etablissement privé</label>
                        </div> 
                    </div>
                </div>

                <hr/>
                <div className="p-field p-col-12">
                    <label>Diplome obtenu sous le statut:</label>
                    <hr/>
                    <div className="p-grid">
                        <div className="p-field p-col-3">
                            <RadioButton inputId="estBoursier" name="estBoursier" readOnly value={true}  checked={demande.estBoursier} />
                            <label htmlFor="estBoursier" className="ml-2">Boursier</label>
                        </div>
                        <div className="p-field p-col-3">
                            <RadioButton inputId="nonBoursier" name="estBoursier" readOnly value={false} checked={!demande.estBoursier} />
                            <label htmlFor="nonBoursier" className="ml-2">Non boursier</label>
                        </div> 
                    </div>
                </div>

                {demande.estBoursier &&
                    
                    <div className="p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="justificationBoursier">Fichier justifiant le statut de boursier</label>
                            <Button label="Visualiser le justificatif du statut boursier" severity="success" rounded onClick={() => viewDocumentByUrl(demande.justificationBoursier)} />
                        </div>
                    </div>
                }

                <hr/>
                <div className="p-field p-col-12">
                    <label>Diplome obtenu :</label>
                    <hr/>
                    <div className="p-grid">
                        <div className="p-field p-col-3">
                            <RadioButton inputId="sousRegion" name="universiteSousRegion" readOnly value={true}  checked={demande.universiteSousRegion} />
                            <label htmlFor="sousRegion" className="ml-2">Dans la sous région</label>
                        </div>
                        <div className="p-field p-col-3">
                            <RadioButton inputId="international" name="universiteSousRegion" readOnly value={false}  checked={!demande.universiteSousRegion} />
                            <label htmlFor="international" className="ml-2">A l'international</label>
                        </div> 
                    </div>
                </div>

                <hr/>
                <div className="p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="siteWebEtablissement">Adresse du site web de l'établissement d'obtention</label>
                        <InputText id="siteWebEtablissement" readOnly value={demande.siteWebEtablissement}  />
                    </div>
                </div>

                <hr/>
                <div className="p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="mailEtablissement">Adresse mail de l'établissement d'obtention</label>
                        <InputText id="mailEtablissement" readOnly value={demande.mailEtablissement}  />
                    </div>
                </div>

                <hr/>
                <div className="p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="telephoneUniversite">Téléphone de l'établissement d'obtention</label>
                        <InputText id="telephoneUniversite" readOnly value={demande.telephoneUniversite}  />
                    </div>
                </div>

                <hr/>
                <div className="p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="diplomeConcerne">Diplome concerné par la demande</label>
                        <Button label="Visualiser le diplôme" severity="success" rounded onClick={() => viewDocumentByUrl(demande.diplomeConcerne)} />
                        
                    </div>
                </div>

                <hr/>
                <div className="p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="diplomeAcces">Diplôme d'accès à la formation du diplôme </label>
                        <InputText id="diplomeAcces" readOnly value={demande.diplomeAcces}  />
                    </div>
                </div>
                <hr/>


                <hr/>
                <div className="p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="nombreAnneeFormation">Nombre d'années de la formation </label>
                        <InputText id="nombreAnneeFormation" readOnly type="number" min={1} value={demande.nombreAnneeFormation}  />
                    </div>
                </div>
                <hr/>

                <div className="p-field p-col-12">
                    <label>Type de document produit pour l'obtention du diplôme:</label>
                    <hr/>
                    <div className="p-grid">
                        <div className="p-field p-col-3">
                            <RadioButton inputId="Rapport" readOnly name="typeDocumentProduit" value="RAPPORT" checked={demande.typeDocumentProduit === "RAPPORT"} />
                            <label htmlFor="Rapport" className="ml-2">Rapport</label>
                        </div>
                        <div className="p-field p-col-3">
                            <RadioButton inputId="Memoire" readOnly name="typeDocumentProduit" value="MEMOIRE" checked={demande.typeDocumentProduit === "MEMOIRE"} />
                            <label htmlFor="Memoire" className="ml-2">Mémoire</label>
                        </div> 
                    </div>
                </div>

                <hr/>

                <div className="p-grid">
                    <div className="p-field p-col-12">
                        <label htmlFor="themeDocumentProduit">Thème du document produit </label>
                        <InputText id="themeDocumentProduit" readOnly value={demande.themeDocumentProduit}  />
                        
                    </div>
                </div>

                <hr/>
                <div className="p-field p-col-12">

                    <DataTable
                      ref={dt} selection={selectedDemandes} onSelectionChange={(e) => setSelectedDemandes(e.value)}
                      dataKey="id" paginator rows={10} value={demande.listeDiplomes} header="Liste des diplomes"
                        globalFilter={globalFilter} filterMatchMode="contains" responsive={true}
                        >
                        <Column field="intituleDiplome" header="Intitulé diplome/ document"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par intitulé'/>
                        <Column field="etablissementObtention" header="Etablissement d'obtention"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par établissement'/>
                        <Column field="origineDiplome" header="Lieu d'obtention"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par lieu'/>
                        <Column field="anneeObtention" header="Anné d'obtention"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par année'/>
                        <Column body={buttonVisualisationDocument}  header="Aperçu du diplome"  filterMatchMode="contains" sortable={false} filter={false}/>
                    </DataTable>
                    
                </div>

            </Dialog>
            <Dialog visible={deleteDemandeDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteDemandeDialogFooter} onHide={hideDeleteDemandeDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {demande && <span>Etes-vous sûr devouloir supprimer le dossier ?</span>}
                    </div>
            </Dialog>



            <Dialog visible={payerAutorisationDialog} style={{ width: '85%' }} header="Informations du paiement dossier autorisation d'enseigner" modal className="p-fluid" footer={paiementAutorisationDialogFooter} onHide={hideDialogPaiementAuto}>
              
              <div className='row'>


              {messageError && (
                <div className='p-col-12 p-md-12 p-lg-12'> 
                  <div className="alert alert-danger" role="alert">
                    {messageError}
                  </div>
                </div>)
              }

                <div className='p-col-12 p-md-12 p-lg-5'> 


                  <div className="card">
                      <div className="card-header">
                          Informations d'identité et montant à payer
                      </div>
                      <div className="card-body">
                          <table className='table table-striped table-hover table-bordered'>
                            <thead className='thead-dark'>
                              <th>Intitulé</th>
                              <th>Valeur</th>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Nom & prénom:</td>
                                <td>{informationDemande.nomPrenom}</td>
                              </tr>
                              <tr>
                                <td>Adresse mail:</td>
                                <td>{informationDemande.idClient}</td>
                              </tr>
                              
                              
                            <tr>
                                <td>Montant des frais:</td>
                                <td>{informationDemande.fraisDepot && informationDemande.fraisDepot.montant && (informationDemande.fraisDepot.montant)} FCFA</td>
                              </tr>
             
                              
                            </tbody>
                          </table>
                      </div>
                  </div>  


                  <div className="card">
                      <div className="card-header">
                          Informations des matières à enseigner
                      </div>
                      <div className="card-body">
                          

                        <DataTable  
                          ref={dt} 
                          dataKey="id" paginator rows={10} 
                          globalFilter={globalFilter}
                          value={informationDemande.disciplinesEnseignement}  
                          header="Liste des matières à enseigner voulue" 
                          filterMatchMode="contains" responsive={true}>
                            <Column field="libelleMatiere" autoFocus header="Intitulé de la matière"  filterMatchMode="contains" sortable={true} filter={false} filterPlaceholder='recherche par matière'/>
                        </DataTable>

                      </div>
                  </div> 

                </div>



                <div className='p-col-12 p-md-12 p-lg-7'>     
                  <div className="card">
                      <div className="card-header">
                          Informations de vos diplomes
                      </div>
                      <div className="card-body">
                          
                          
                        <DataTable
                          ref={dt} 
                          dataKey="id" paginator rows={10} value={informationDemande.listeDiplomes} header="Liste des diplomes"
                            globalFilter={globalFilter} filterMatchMode="contains" responsive={true}
                            >
                            <Column field="intituleDiplome" header="Intitulé diplome/ document"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par intitulé'/>
                            <Column field="etablissementObtention" header="Etablissement d'obtention"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par établissement'/>
                            <Column field="origineDiplome" header="Lieu d'obtention"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par lieu'/>
                            <Column field="anneeObtention" header="Anné d'obtention"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par année'/>
                        </DataTable>

                      </div>
                  </div>     
                </div>

              </div>
              
            </Dialog>



            <Dialog visible={payerReconnaissanceDialog} style={{ width: '85%' }} header="Informations du paiement dossier reconnaissance de diplome" modal className="p-fluid" footer={paiementReconnaissanceDialogFooter} onHide={hideDialogPaiementReconnaissance}>
              
              <div className='row'>

              {messageError && (
                <div className='p-col-12 p-md-12 p-lg-12'> 
                  <div className="alert alert-danger" role="alert">
                    {messageError}
                  </div>
                </div>)
              }

                <div className='p-col-12 p-md-12 p-lg-6'> 
                  <div className="card">
                      <div className="card-header">
                          Informations d'identité et montant à payer
                      </div>
                      <div className="card-body">
                          <table className='table table-striped table-hover table-bordered'>
                            <thead className='thead-dark'>
                              <th>Intitulé</th>
                              <th>Valeur</th>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Nom & prénom:</td>
                                <td>{informationDemande.nomPrenom}</td>
                              </tr>
                              <tr>
                                <td>Adresse mail:</td>
                                <td>{informationDemande.idClient}</td>
                              </tr>
                              <tr>
                                <td>Etablissement d'obtention du diplome:</td>
                                <td>{informationDemande.nomEtablissement}</td>
                              </tr>
                              <tr>
                                <td>Montant des frais:</td>
                                <td>{informationDemande.fraisDepot && informationDemande.fraisDepot.montant && (informationDemande.fraisDepot.montant)} FCFA</td>
                              </tr>
                            </tbody>
                          </table>
                      </div>
                  </div>  
                </div>



                <div className='p-col-12 p-md-12 p-lg-6'>     
                  <div className="card">
                      <div className="card-header">
                          Informations de vos diplomes
                      </div>
                      <div className="card-body">
                        <DataTable
                          ref={dt} 
                          dataKey="id" paginator rows={10} value={informationDemande.listeDiplomes} header="Liste des diplomes"
                            globalFilter={globalFilter} filterMatchMode="contains" responsive={true}
                            >
                            <Column field="intituleDiplome" header="Intitulé diplome/ document"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par intitulé'/>
                            <Column field="etablissementObtention" header="Etablissement d'obtention"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par établissement'/>
                            <Column field="origineDiplome" header="Lieu d'obtention"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par lieu'/>
                            <Column field="anneeObtention" header="Anné d'obtention"  filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder='recherche par année'/>
                        </DataTable>
                      </div>
                  </div>     
                </div>

              </div>
            </Dialog>
    </div>
  )
}

export default DashbordClient