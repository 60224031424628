import axios from 'axios';
import { AdresseIP } from '../components/AdresseIP';


export class ReleveNoteService {

    constructor(){
        this.adresse = new AdresseIP()
    }
   
   getListeEtudiant(idSession) {
    const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/etudiants-releves?session="+idSession
    return axios.get(url)
                .then(res => res.data)
    }
    genererReleveNoteEtudiant(releve) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/reporting"

        console.log(url)
        return axios.post(url,releve,{responseType: "arraybuffer"})
                    .then(res => res);
    }

}