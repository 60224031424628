import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class ParcoursFiliereService {

    constructor(){
        this.adresse = new AdresseIP()
    }
    
   getAll() {
       
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/parcours-filiere"
       return axios.get(url).then(res => res.data) 
   }
   
   create(idParcoursFilier, newParcoursFilier) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/parcours-filiere/"
       return axios.put(url+idParcoursFilier,newParcoursFilier)
                   .then(res => res.data);
   }

   update(idParcoursFilier ,newParcoursFiliere) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/parcours-filiere/"
       return axios.put(url+idParcoursFilier, newParcoursFiliere)
                   .then(res => res.data);
   }

   delete(parcoursId) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/parcours-filiere/"
       return axios.patch(url + parcoursId)
                   .then(res => res.data)
   }

   getListeParcoursDepartement(idDepartement) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/parcours-filiere?idDepartement="
        return axios.get(url+idDepartement).then(res => res.data) 
    }


    getListeDepartementEtablissement(idEtablissement){
        const url = this.adresse.getAdresseIneFaso() +"/service/api/inefaso/campus-faso/departement-etablissement?codeEtablissement="
        return axios.get(url+idEtablissement).then(res => res.data) 
    }

}