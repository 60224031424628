import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class DeliberationService {
     
    constructor(){
         this.adresse = new AdresseIP()
     }


    getAll() {
        
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/deliberation"
        return axios.get(url).then(res => res.data) 
    }

    getDeliberationBySession(idSession) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/deliberation?idSession="+idSession
        return axios.get(url).then(res => res.data) 
    }
    

    
    create(newDomaine) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/deliberation"
        return axios.post(url,newDomaine)
                    .then(res => res.data);
    }

    
    update(idDomaine ,newDomaine) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/deliberation/"
        return axios.put(url+idDomaine, newDomaine)
                    .then(res => res.data);
    }

    delete(idDomaine) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/deliberation/"
        return axios.patch(url+idDomaine)
                    .then(res => res.data)
    }

    doDeliberation(idDeliberation) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/deliberation?idSession="+idDeliberation
        return axios.post(url)
                    .then(res => res.data);
    }

    getPVDeliberation(idSession, ordre) {

        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/proces-verbal?idSession="+idSession+"&critereOrdre="+ordre

        console.log(url)
        return axios.get(url)
                    .then(res => res.data)
    }

    getListeEudiantRachat(idDeliberation, moyRachat) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/liste-etudiant-rachat?idDeliberation="+idDeliberation+"&moyenneRachat="+moyRachat
        return axios.get(url)
                    .then(res => res.data)
    }
    getListeEcRachat(idDeliberation, idEtudiant) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/rachat?idDeliberation="+idDeliberation+"&idEtudiant="+idEtudiant
        return axios.get(url)
                    .then(res => res.data)
    }



    validationRachat(rachat){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/validation_rachat"
        return axios.post(url, rachat)
                    .then(res => res.data);
    }



    validationRachatManuel(listeRachat){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/validation-rachat-manuel"
        return axios.post(url, listeRachat)
                    .then(res => res.data);
    }


    activerEdition(idSession){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/session-deja-delibere/"+idSession
        return axios.patch(url)
                    .then(res => res.data);
    }

    getPVPDFFromBackend(idSession, idDepartement, ordrePV){

        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/pv-deliberation?idSession="+idSession+"&idDepartement="+idDepartement+"&critereOrdre="+ordrePV

        console.log(url)
        return axios.get(url,{responseType: "arraybuffer"})
                    .then(res => res);
    }
    
}