import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';



export class DashboardService {
     constructor(){
         this.adresse = new AdresseIP()
     }


     getListeEtudiantUniv(idAnnee) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/iesr-statistique?idAnnee="
        return axios.get(url+idAnnee).then(res => res.data)
    }

    getListeEtudiantEtab(idIesr, idAnnee) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/etablissement-statistique?idIes="+idIesr+"&idAnnee="+idAnnee
        return axios.get(url).then(res => res.data)
    }

    getListeEtudiantDept(idEtab, idAnnee) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/departement-statistique?idEtablissement="+idEtab+"&idAnnee="+idAnnee
        return axios.get(url).then(res => res.data)
    }

    getListeEtudiantParcours(idDepartement, idParcours, idAnnee) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/parcours-statistique?idDepartement="+idDepartement+"&idParcours="+idParcours+"&idAnnee="+idAnnee
        return axios.get(url).then(res => res.data)
    }

    getAll(){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/departement-lmd"
        return axios.get(url).then(res => res.data) 
    }


    getStatistiqueGenre(idAnnee){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/tableau-statistique?idAnnee="+idAnnee
        return axios.get(url).then(res => res.data)
    }
 
}