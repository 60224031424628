import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';


export class InfrastructureServices {

    constructor(){
        this.adresse = new AdresseIP()
    }

    getListeDepartementUniv (idDepartement){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/departement-ine?idDepartement="+idDepartement

        //console.log(url)
        return axios.get(url)
                .then(res => res.data)
    }
   
    getListeUtiliteInfrastructure() {
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/utilites-infrastructures"
        return axios.get(url)
                .then(res => res.data)
    }

    getListeUtiliteDeInfrastructure(idInfrastructure) {
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/infrastructures-utilites?idInfrastructure="
        return axios.get(url+idInfrastructure)
                .then(res => res.data)
    }

    update(idUtiliteInfrastructure, entity){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/utilites-infrastructures/"
        return axios.put(url+idUtiliteInfrastructure, entity)
                   .then(res => res.data);  
    }


    create(entity){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/utilites-infrastructures"
        return axios.post(url, entity)
                   .then(res => res.data);  
    }


    delete(idUtiliteInfrastructure){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/utilites-infrastructures/"
        return axios.patch(url+idUtiliteInfrastructure)
                   .then(res => res.data);  
    }

    deleteInfrastructure(idInfrastructure){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/infrastructures/"
        return axios.patch(url+idInfrastructure)
                   .then(res => res.data);
    }

    getListeInfrastructures() {
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/infrastructures"
        return axios.get(url)
                .then(res => res.data)
    }


    getListeInfrastructuresByTypeStructureIdStructure(typeStructure, idStructure) {
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/infrastructures?idTypeStructure="+typeStructure+"&idStructureRatachement="+idStructure
        //console.log(url)
        return axios.get(url)
                .then(res => res.data)
    }

    getListeEtatInfrastructure(){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/etats-infrastructures"
        return axios.get(url)
                .then(res => res.data)
    }


    updateEtatInfrastructure(idEtatInfrastructure, etatInfrastructure){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/etats-infrastructures/"
        return axios.put(url+idEtatInfrastructure, etatInfrastructure)
                   .then(res => res.data);  
    }


    createEtatInfrastructure(etatInfrastructure){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/etats-infrastructures"
        return axios.post(url, etatInfrastructure)
                   .then(res => res.data);  
    }

    deleteEtatInfrastructure(idEtatInfrastructure){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/etats-infrastructures/"
        return axios.patch(url+idEtatInfrastructure)
                   .then(res => res.data);  
    }

    getListeTypesInfrastructures(){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/types-infrastructures"
        return axios.get(url)
                .then(res => res.data)
    }


    //Appel pour obtenir la liste des departements de l'université (A revoir après avec le bon endpoint)
    getListeDepartementUniversite (idUniversite) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/departement-lmd?idIsr="
        return axios.get(url+idUniversite)
                .then(res => res.data)
    }


    getDepartementById (idDepartement) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/departement-lmd?idDepartement="
        return axios.get(url+idDepartement)
                .then(res => res.data)
    }

    updateInfrastructure(idInfrastructure, infrastructure){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/infrastructures/"
        return axios.put(url+idInfrastructure, infrastructure)
                   .then(res => res.data);  
    }

    createInfrastructure(infrastructure){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/infrastructures"
        return axios.post(url, infrastructure)
                   .then(res => res.data);  
    }


    enregistrerUtilitesInfrastructure(listeUtilitesInfrastructure) {
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/infrastructures-utilites"
        return axios.post(url, listeUtilitesInfrastructure)
                .then(res => res.data)
    }

    getlisteReservationInfrastructure(idDepartement){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/infrastructures-reserves?idDepartement="+idDepartement
        return axios.get(url)
                .then(res => res.data)
    }

    getResponsableInfrastructure(idDepartement){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/responsable-infrastructure?idDepartement="+idDepartement
        return axios.get(url)
                .then(res => res.data)
    }

    saveResponsableInfrastructure(responsable){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/responsable-infrastructure"
        return axios.post(url, responsable)
                .then(res => res.data)
    }

    editResponsableInfrastructure(idResponsableInfrastructure, responsableInfrastructure){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/responsable-infrastructure/"
        return axios.put(url+idResponsableInfrastructure, responsableInfrastructure)
                .then(res => res.data)
    }

    gestionReservationInfrastructure(idProgrammation, idStatut){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/infrastructures-reserves?idProgrammationSeance="+idProgrammation+"&idStatutProgrammation="+idStatut
        return axios.put(url)
                .then(res => res.data)
    }

    getlisteSuiviReservation(idDepartement){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/suivi-reservations?idDepartement="+idDepartement
        return axios.get(url)
                .then(res => res.data)
    }
    
    /**
     * 
     * @param {*} idDepartement 
     * @param {*} dateProgrammation 
     * @param {*} debutProgrammation 
     * @param {*} finProgrammation 
     * @returns la liste des infrastructure libre à cette periode d'un departement
     * 
     */
    getInfrastructureLibre(idDepartement,dateProgrammation,debutProgrammation,finProgrammation){
        //console.log(`${this.adresse.getAdresseVolumeHoraire()}/service/api/campusfaso-volume-horaire/campus-faso/infrastructures-ies/${idDepartement}?date=${dateProgrammation}&heureDeb=${debutProgrammation}&heureFin=${finProgrammation}`)
        return axios.get(`${this.adresse.getAdresseVolumeHoraire()}/service/api/campusfaso-volume-horaire/campus-faso/infrastructures-ies/${idDepartement}?date=${dateProgrammation}&heureDeb=${debutProgrammation}&heureFin=${finProgrammation}`).then(response=>response.data)
    }

    getInfrastructureLibreDepartement(idDepartement,dateProg,heureDeb,heureFin){
        //console.log(`${this.adresse.getAdresseVolumeHoraire()}/service/api/campusfaso-volume-horaire/campus-faso/infrastructures-departement?dept=${idDepartement}&date=${dateProg}&heureDeb=${heureDeb}&heureFin=${heureFin}`)
        return axios.get(`${this.adresse.getAdresseVolumeHoraire()}/service/api/campusfaso-volume-horaire/campus-faso/infrastructures-departement?dept=${idDepartement}&date=${dateProg}&heureDeb=${heureDeb}&heureFin=${heureFin}`).then(response=>response.data)
    }

    getInfrastructureLibreUniversite(idIesr,dateProg,heureDeb,heureFin){
        return axios.get(`${this.adresse.getAdresseVolumeHoraire()}/service/api/campusfaso-volume-horaire/campus-faso/infrastructures-departement?idIesr=${idIesr}&date=${dateProg}&heureDeb=${heureDeb}&heureFin=${heureFin}`).then(response=>response.data)
    }

    getInfrastructureLibreEtablissement(idEtablissement,dateProg,heureDeb,heureFin){

        //console.log(`${this.adresse.getAdresseVolumeHoraire()}/service/api/campusfaso-volume-horaire/campus-faso/infrastructures-departement?idEtablissement=${idEtablissement}&date=${dateProg}&heureDeb=${heureDeb}&heureFin=${heureFin}`)
        return axios.get(`${this.adresse.getAdresseVolumeHoraire()}/service/api/campusfaso-volume-horaire/campus-faso/infrastructures-departement?idEtablissement=${idEtablissement}&date=${dateProg}&heureDeb=${heureDeb}&heureFin=${heureFin}`).then(response=>response.data)
    }

}