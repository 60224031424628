import React,{useEffect,useState} from "react";
import { DataTable } from "primereact/datatable";
import '../components/parametres/index.css';
import { Column } from "primereact/column";
import { ToastContainer } from "react-toastify";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Spinner } from "../../components/Spinner";
import { Dialog } from "primereact/dialog";
import FichePedagogiqueService from "../service/FichePedagogiqueService";
import { AnneeService } from "../../lmd/service/AnneeService";
import EnseignantService from "../service/EnseignantService";
import { Notify } from "../../components/Notify";
import { Controller, useForm } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { DepartementService } from "../../lmd/service/DepartementService";


export default function FichePedagogique(){
    const tokenDecoded = localStorage.getItem('tokenDecoded')
    const token = JSON.parse(tokenDecoded);

    const [globalFilter,setGlobalFilter] = useState();
    const [loading,setLoading] = useState(false);
    const [listEnseignant,setListEnseignant] = useState([]);
    const [dialog,setDialog] = useState({add:false,modify:false});
    const [enseignantDetail,setEnseignantDetail] = useState(null);
    const {control,handleSubmit, reset,getValues,setValue,formState:{errors}} = useForm({defaultValues:{anneeAcademique:""}});
    const [listAnnee, setlistAnnee] = useState([]);
    const [departementActuelle, setdepartementActuelle] = useState({});
    const [spin, setspin] = useState(false);
   
    const spinner = new Spinner();
    const fichePedagogiqueService = new FichePedagogiqueService();
    const anneeService = new AnneeService();
    const enseignantService = new EnseignantService();
    const departementService = new DepartementService();
    const notify = new Notify();
    let code_departement = token.code_departement;


    useEffect(()=>{

        
        anneeService.getAll().then(response=>{

            setlistAnnee(response);
            let anneeEnCours = response.find(data=>data.isEnCours)
            setValue("anneeAcademique",anneeEnCours)
            setLoading(true)

            enseignantService.getEnseignantProgrammeByAnneeDepartement(token.code_departement, anneeEnCours.idAnneeAcademique).then((response=[])=>{
            
                setListEnseignant(response);
                //console.log(response)
            })
            .catch(errors=>{
                notify.echec(errors.response.data.message);
                console.log(errors);
            })
            .finally(()=>setLoading(false));

        }).catch(errors => {
            notify.echec(errors.response.data.message)
            console.log(errors);
        })

        departementService.getAll().then(response=>{
            let departement = response.find(data=>data.idDepartementEtab === code_departement)
            if(!departement) departement = {};
            setdepartementActuelle(departement);

        });
    },[]);


    const handleAnneeSelect = (anneeAcademique) => {
        setLoading(true)

            enseignantService.getEnseignantProgrammeByAnneeDepartement(token.code_departement, anneeAcademique.idAnneeAcademique).then((response=[])=>{
            
                setListEnseignant(response);
                //console.log(response)
            })
            .catch(errors=>{
                notify.echec(errors.response.data.message);
                console.log(errors);
            })
            .finally(()=>setLoading(false));
    }

    const handleDetail = (enseignant)=>{
        setEnseignantDetail(enseignant);
        setDialog({...dialog,add:true});
    }

    


    const handleFicheProgrammatique = (enseignant)=>{
        let anneeAcademique = getValues("anneeAcademique")
        let data = {
            reportType: "FICHE_PROGRAMMATIQUE_DEPARTEMENT",
            annee:anneeAcademique.idAnneeAcademique,
            idEnseignant:enseignant.idEnseignant,
            idDepartement : code_departement
        };

        //console.log(data);
        

        fichePedagogiqueService.getFicheProgrammatique(data).then(async response=>{
            let anneeAcademique = getValues("anneeAcademique")
            let  blob = new Blob([response], {type: "application/pdf"});
            let link = document.createElement('a');
            // link.href = window.URL.createObjectURL(blob);
            window.open(window.URL.createObjectURL(blob),"_blank")
            setDialog({...dialog,modify:false});
            // let fileName = `fiche_programmatique_${anneeAcademique.libelleAnnee}`;
            // link.download = fileName;
            link.click();
        })
        

    }




    const handleFicheProgrammatiqueGlobal = ()=>{
        let anneeAcademique = getValues("anneeAcademique")
        let data = {
            reportType: "FICHE_PROGRAMMATIQUE_DEPARTEMENT",
            annee:anneeAcademique.idAnneeAcademique,
            idDepartement : code_departement
        };

        //console.log(data);
        

        fichePedagogiqueService.getFicheProgrammatique(data).then(async response=>{
            let anneeAcademique = getValues("anneeAcademique")
            let  blob = new Blob([response], {type: "application/pdf"});
            let link = document.createElement('a');
            // link.href = window.URL.createObjectURL(blob);
            window.open(window.URL.createObjectURL(blob),"_blank")
            setDialog({...dialog,modify:false});
            // let fileName = `fiche_programmatique_${anneeAcademique.libelleAnnee}`;
            // link.download = fileName;
            link.click();
        }).catch(errors => {
            console.log(errors)
            notify.echec(errors.response.data.message)
        })
        

    }






    const columnBodyTemplate = (rowData)=>{
        return (
            <div>
                <span>{rowData.nomEnseignant} </span>
                <span>{rowData.prenomEnseignant} </span>
            </div>
        )
    }


    const actionHeader = <Button type="button" icon="pi pi-cog"/>;
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-file-pdf" onClick={()=>handleFicheProgrammatique(rowData)} title="fiche programmatique" className="p-button-info" style={{marginRight: '.5em'}}/>
            </React.Fragment>
        );
    }

    const header = (
        <div className="table-header p-col-12" style={{display:"flex",backgroundColor:"Background",alignItems:"center",marginBottom:10}}>
            <span className="p-input-icon-left">
                <Button style={{marginLeft : 10}} label="Telecharger la fiche globale" icon="pi pi-download" className="p-button-success p-mr-2"  onClick={()=>handleFicheProgrammatiqueGlobal()} disabled={!getValues("anneeAcademique").idAnneeAcademique || listEnseignant.length === 0 }/>
                {/* <InputText style={{marginLeft : 20,width:"300px"}} type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." /> */}
            </span>
            <div className="p-d-flex p-jc-center mt-2 mb-2" style={{width:160,margin:"10px auto"}}>
                <form className="p-fluid">
                    <div className="p-field" >
                        <Controller
                            name="anneeAcademique" control={control}
                            render={({field}) => (
                                <Dropdown
                                    placeholder="année académique"
                                    optionLabel="libelleAnnee" options={listAnnee}
                                    name={field.name} value={field.value} id={field.name}
                                    onChange={(e) => {field.onChange(e.value); handleAnneeSelect(e.value);}}
                                />
                            )} 

                        />
                    </div>
                </form>
            </div>
        </div>
    )

    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="card">
                    {header}
                   
                {
                    loading?
                    <div className="p-grid">
                        <div className="p-col-6" ></div>
                        <div className="p-col-6" >
                            {spinner.loadingSpinner("spin", "black")}
                        </div>
                    </div>
                    :
                    <DataTable 
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                        rows={5} selectionMode="single" filterMatchMode="contains"
                        paginator={true} responsive={true} value={listEnseignant}
                        globalFilter={globalFilter}
                        header={`Liste des enseignants du departement ${departementActuelle.lbDepartementEtab}`} >
                        
                        <Column field="matriculeEnseignant" header="Matricule" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search ..." sortable filter/>
                        <Column field="emailEnseignant" header="Email" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search ..." sortable filter/>
                        <Column body={columnBodyTemplate} header="nom" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search ..." sortable filter/>
                        <Column field="telEnseignant" header="Telephone" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search ..." sortable filter/>
                        <Column field="specialite.libelleSpecialite" header="Specialité" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search ..." sortable filter/>
                        <Column field="grade.libelleGrade" header="grade" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search ..." sortable filter/>
                        <Column header={actionHeader} body={actionBodyTemplate} style={{width:"60px"}} />
                    </DataTable>
                }
            </div>
            <Dialog visible={dialog.add} onHide={()=>setDialog({...dialog,add:false})} style={{width:"70vw",height:"95vh"}}>
                {
                    enseignantDetail?
                    <div>
                        <div>
                            <h3 className="detail-header">Fiche programmatique</h3>
                        </div>
                        <div>
                            <div className="p-grid">
                                <p className="p-col-6"><span className="detail-label">Matricule</span><span className="detail-point">:</span> <span className="detail-value">{enseignantDetail.matriculeEnseignant} </span></p>
                                <p className="p-col-6"><span className="detail-label"> Nom & Prénom</span><span className="detail-point">:</span> <span className="detail-value">{enseignantDetail.nomEnseignant} {enseignantDetail.prenomEnseignant}</span></p>
                            </div>
                            <div>
                                <p><span className="detail-label">Genre</span><span className="detail-point">:</span> <span className="detail-value">{enseignantDetail.genre}</span></p>
                                <p><span className="detail-label">Telephone</span><span className="detail-point">:</span><span className="detail-value">{enseignantDetail.telEnseignant}</span></p>
                                <p> <span className="detail-label">Email</span><span className="detail-point">:</span> <span className="detail-value">{enseignantDetail.emailEnseignant}</span></p>
                                <p><span className="detail-label">Date de Naissance</span><span className="detail-point">:</span><span className="detail-value">{new Date(enseignantDetail.anneeNaissance).toLocaleDateString()}</span></p>
                                <p><span className="detail-label">Lieu de Naissance</span><span className="detail-point">:</span><span className="detail-value">{enseignantDetail.lieuNaissance}</span></p>
                                {enseignantDetail.statutEnseignant && <p><span className="detail-label">Statut</span><span className="detail-point">:</span><span className="detail-value">{enseignantDetail.statutEnseignant.libelleStatutEnseignant}</span></p>}
                            </div>
                            <div className="p-grid">
                                {enseignantDetail.grade && <p className="p-col-6"><span className="detail-label">Grade</span><span className="detail-point">:</span> <span className="detail-value">{enseignantDetail.grade.libelleGrade}</span></p>}
                                {enseignantDetail.rang && <p className="p-col-6"><span className="detail-label">Rang</span><span className="detail-point">:</span> <span className="detail-value">{enseignantDetail.rang.libelleRang}</span></p>}
                            </div>
                                <p><span className="detail-label">Diplôme</span><span className="detail-point">:</span><span className="detail-value">{enseignantDetail.diplomeEnseignant}</span></p>
                            {enseignantDetail.specialite &&<div className="p-grid">
                                <p className="p-col-6"><span className="detail-label">Spécialité</span><span className="detail-point">:</span> <span className="detail-value">{enseignantDetail.specialite.libelleSpecialite}</span></p>
                                <p className="p-col-6"><span className="detail-label">Discipline</span><span className="detail-point">:</span> <span className="detail-value">{enseignantDetail.specialite.libelleDiscipline}</span></p>
                            </div>}
                            <p><span className="detail-label">Université d'appartenance</span><span className="detail-point">:</span><span>{enseignantDetail.nomIes}</span></p>
                            <p><span className="detail-label">Département d'appartenance</span><span className="detail-point">:</span><span>{enseignantDetail.lbDepartementEtab}</span></p>
                        </div>
                    </div>:""
                }
                
                <div>
                    <Button label="Télécharger" onClick={()=>handleFicheProgrammatique(enseignantDetail)} className="p-button-info" />
                </div>
            </Dialog>


            <Dialog visible={dialog.modify} onHide={()=>setDialog({...dialog,modify:false})} style={{width:"70vw",height:"95vh"}}>
               
            </Dialog>

        </div>
    )
}