
import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';


export class CohorteServices {

    constructor(){
        this.adresse = new AdresseIP()
    }

    getListeCohorteUniversite(idUniversite, idTypeActivite) {

        let url = "";
        if(idTypeActivite){
            url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/cohortes?idIesr="+idUniversite+"&idTypeActivite="+idTypeActivite
        }else{
            url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/cohortes?idIesr="+idUniversite+"&idTypeActivite"
        }
        
        return axios.get(url)
                .then(res => res.data)
    }

    getListeCohorteDepartement(idDepartement, idTypeActivite="", idCohorte) {

        //Pour le cas des cohorte TD, on a besoint de l'id du cohorte parent
        let url = "";
        if(idTypeActivite && !idCohorte){
            url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/cohortes?idDepartement="+idDepartement+"&idTypeActivite="+idTypeActivite
        }else if(!idTypeActivite && ! idCohorte){
            url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/cohortes?idDepartement="+idDepartement
        } else if(idTypeActivite && idCohorte){
            url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/cohortes?idDepartement="+idDepartement+"&idTypeActivite="+idTypeActivite+"&idCohorte="+idCohorte
        }else if(!idTypeActivite && idCohorte){
            url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/cohortes?idDepartement="+idDepartement+"&idCohorte="+idCohorte
        } 
        return axios.get(url)
                .then(res => res.data)
    }

    getListeCohorteEtudiant(ineEtudiant) {

        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/cohortes-etudiants?ine="+ineEtudiant
        return axios.get(url)
                .then(res => res.data)
    }

    getListeAnneeAcademique(){
        const url = this.adresse.getAdresseIneFaso() +"/service/api/inefaso/campus-faso/annee-academique-ine" 
        return axios.get(url).then(res => res.data) 
    }

    getListeParcoursUniversite(idUniversite){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/parcours?idIesr="
        return axios.get(url+idUniversite)
                .then(res => res.data)
    }

    getListeParcoursDepartement(idDepartement){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/parcours?idDepartement="
        return axios.get(url+idDepartement)
                .then(res => res.data)
    }

    getListeNiveau(){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/niveaux"
        return axios.get(url)
                .then(res => res.data)
    }


    getCohorteByTypeActivite(idParcoursFiliere, idNiveau, idAnneeAcademique, idTypeActivite){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/cohortes-parcours?idParcoursFiliere="+idParcoursFiliere+"&idNiveau="+idNiveau+"&idAnneeAcademique="+idAnneeAcademique+"&idTypeActivite="+idTypeActivite
        return axios.get(url)
                .then(res => res.data)
    }

    testerExistanceCohorte(idParcoursFiliere, idNiveau, idAnneeAcademique){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/cohortes-parcours?idParcoursFiliere="+idParcoursFiliere+"&idNiveau="+idNiveau+"&idAnneeAcademique="+idAnneeAcademique
        return axios.get(url)
                .then(res => res.data)
    }


    getCohorteParcoursNiveau(idParcoursFiliere, idNiveau, idAnneeAcademique){
        // const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/cohortes-parcours?idParcoursFiliere="+idParcoursFiliere+"&idNiveau="+idNiveau+"&idAnneeAcademique="+idAnneeAcademique+"&idTypeActivite="+idTypeActivite
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/cohortes-parcours?idParcoursFiliere="+idParcoursFiliere+"&idNiveau="+idNiveau+"&idAnneeAcademique="+idAnneeAcademique
        return axios.get(url)
                .then(res => res.data)
    }

    getNbEtudiantsInscrit(idParcoursFiliere, idNiveau, idAnneeAcademique){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/nombre-etudiants-vh?idParcoursFiliere="+idParcoursFiliere+"&idNiveau="+idNiveau+"&idAnneeAcademique="+idAnneeAcademique
        return axios.get(url)
                .then(res => res.data)
    }


    getNbEtudiantsInscritEc(idParcoursFiliere, idNiveau, idAnneeAcademique, idElementConstitutif){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/nombre-etudiants-vh?idParcoursFiliere="+idParcoursFiliere+"&idNiveau="+idNiveau+"&idAnneeAcademique="+idAnneeAcademique+"&idElementConstitutif="+idElementConstitutif
        return axios.get(url)
                .then(res => res.data)
    }

    createCohorte(cohorte){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/cohortes"
        return axios.post(url, cohorte)
                .then(res => res.data)
    }

    createCohorteTd(cohorte){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/cohortes-TD"
        return axios.post(url, cohorte)
                .then(res => res.data)
    }

    getListeEtudiantsCohorte(idCohorte){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/etudiants-cohorte?idCohorte="
        return axios.get(url+idCohorte)
                .then(res => res.data)
    }

    deleteCohorte(idCohorte){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/cohortes?idCohorte="+idCohorte
        return axios.patch(url)
    }
    getEtudiant(ineEtudiant){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/etudiant-ine?ine="+ineEtudiant
        return axios.get(url)
                .then(res => res.data)
    }

    getListeCohorteIesEns(idIes, idAnnee, matricule){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/cohortes-ies?matricule="+matricule+"&idAnnee="+idAnnee+"&idIes="+idIes

        //console.log(url)
        return axios.get(url)
                .then(res => res.data)
    }
    
    getListeEcCohorteEns(idCohorte, idAnnee, matricule){
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/element-constitutif-enseignant?matricule="+matricule+"&idAnnee="+idAnnee+"&idCohorte="+idCohorte
        //console.log(url)
        return axios.get(url)
                .then(res => res.data)
    }

    getListeDepartementEtab(idEtab) {
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/departement-etab/"
        return axios.get(url+idEtab).then(res => res.data)
    }

    
    getListeEtabUniv(idIesr) {
        const url = this.adresse.getAdresseVolumeHoraire() +"/service/api/campusfaso-volume-horaire/campus-faso/etablissement-ies/"
        return axios.get(url+idIesr).then(res => res.data)
    }

}