import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class SessionExamenService {

    constructor(){
        this.adresse = new AdresseIP()
    }

    getAll() {
        
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/session"
        return axios.get(url).then(res => res.data) 
    }
    
    create(newSession) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/session"
        return axios.post(url,newSession)
                    .then(res => res.data);
    }

    update(idSession ,newSession) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/session/"
        return axios.put(url+idSession, newSession)
                    .then(res => res.data);
    }

    cloturerSession(idSession) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/etat-session/"
        return axios.patch(url+idSession)
                    .then(res => res.data);
    }

    delete(idSession) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/session/"
        return axios.patch(url+idSession)
    }


    getInfosSession(dataSession){

        

        const url = this.adresse.getAdresseIP() 
        +"/service/api/campusfaso-lmd/campus-faso/information-session?idDepartementFiliere="
        +parseInt(dataSession.idDepartementFiliere)
        +"&idParcours="
        +parseInt(dataSession.idParcours)
        +"&idSemestre="
        +parseInt(dataSession.idSemestre)
        +"&idAnneeAcademique="
        +parseInt(dataSession.idAnneeAcademique)

        return axios.get(url)
                    .then(res => res.data);
    }


    getSession(dataSession){
        const url = this.adresse.getAdresseIP() 
        +"/service/api/campusfaso-lmd/campus-faso/session-parcours-filiere?idParcoursFiliere="
        +dataSession.idParcoursFiliere
        +"&idAnneeAcademique="
        +dataSession.idAnneeAcademique
        +"&idSemestre="
        +dataSession.idSemestre

        return axios.get(url)
                    .then(res => res.data);
       
    }


    getSessionById(idSession){

        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/session?idSession="+idSession
        return axios.get(url)
    }

    
    getSessionDepartement(idDepartement){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/session/"
        return axios.get(url+idDepartement)
    }

    getSessionEtablissement(idEtablissement){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/session-etablissement?idEtablissement="+idEtablissement
        //console.log(url)
        return axios.get(url)
    }

    getSessionInscriptionAdministrativeDepartement(idDepartement){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/session-administrative?idDepartement="
        return axios.get(url+idDepartement)
    }

    getSessionInscriptionPedagogiqueDepartement(idDepartement){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/session-pedagogique?idDepartement="
        return axios.get(url+idDepartement)
    }

    getSessionDeliberationEnseignant(idEnseignant){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/session-enseignant?idEnseignant="+idEnseignant
        return axios.get(url)
    }
    
    getListeEtudiantEcSession(idSession, idElementConstitutif){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/etudiants-ec?idSession="+idSession+"&idElementConstitutif="+idElementConstitutif
        return axios.get(url)
    }


    getListeTypeSession(){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/type-session"
        return axios.get(url)
    }

    createInscription(sessionInscription){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/session-inscription"
        return axios.post(url, sessionInscription).then(res => res.data);
    }

    deleteSessionInscription(idSession){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/session-inscription/"
        return axios.patch(url+ idSession);
    }
}