import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class NiveauService {
     constructor(){
         this.adresse = new AdresseIP()
     }
    getAll() {
        
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/niveaux"
        return axios.get(url).then(res => res.data) 
    }
    
    create(newNiveau) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/niveaux"
        return axios.post(url,newNiveau)
                    .then(res => res.data);
    }
    update(idNiveau ,newNiveau) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/niveaux/"
        return axios.put(url+idNiveau, newNiveau)
                    .then(res => res.data);
    }
    delete(idNiveau) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/niveaux/"
        return axios.patch(url+idNiveau)
                    .then(res => res.data)
    }


    getListeNiveauByIdParcours(idParcours){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/parcours-niveau/"+idParcours
        return axios.get(url).then(res => res.data) 
    }

}