import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import classNames from 'classnames'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Notify } from '../../../components/Notify'
import { Spinner } from '../../../components/Spinner'
import { ToastContainer} from 'react-toastify'
import { SemestreService } from '../../service/SemestreService'
import { NiveauService } from '../../service/NiveauService'
import { Dropdown } from 'primereact/dropdown'

export class Semestre extends Component {
    emptySemestre = {
        idSemestre: null,
        libelleSemestre: "",
        codeSemestre : "",
        ordreSemestre: "",
        niveau: null
    }

    constructor() {
        super();
        this.state = {
            listeSemestre : [],
            semestreDialog: false,
            deleteSemestreDialog: false,
            semestre: this.emptySemestre,
            submitted: false,
            redirect : false,
            loading : false,
            globalFilter: null,
            listeNiveau: []
        }
        this.semestreService = new SemestreService()
        this.ModelNiveau = new NiveauService()
        this.spinner = new Spinner()
        this.notify = new Notify()

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveSemestre = this.saveSemestre.bind(this);
        this.editSemestre = this.editSemestre.bind(this);
        this.confirmDeleteSemestre = this.confirmDeleteSemestre.bind(this);
        this.deleteSemestre = this.deleteSemestre.bind(this);
        this.onLibelleSemestreChange = this.onLibelleSemestreChange.bind(this);
        this.onOrdreSemestreChange = this.onOrdreSemestreChange.bind(this);
        this.hideDeleteSemestreDialog = this.hideDeleteSemestreDialog.bind(this);
        this.onNiveauChange = this.onNiveauChange.bind(this)
    }


    componentDidMount() {
        this.setState({loading : true})
        this.semestreService.getAll().then(data => {
            console.log(data)
            this.setState({listeSemestre: data, loading : false})
            
        }
        )
        .catch(error => {
            this.notify.echec(error.response.data.message)
            console.log(error)
            this.setState({loading : false})
        })

            this.ModelNiveau.getAll().then(data => 
                    this.setState({listeNiveau: data})
                )
                .catch(error => {
                    this.notify.echec(error.response.data.message)
                    console.log(error)
                })
    }
    openNew() {
        this.setState({
            semestre: this.emptySemestre,
            submitted: false,
            semestreDialog: true
        })
    }
    hideDialog() {
        this.setState({
            submitted: false,
            semestreDialog: false
        });
    }
    hideDeleteSemestreDialog() {
        this.setState({ deleteSemestreDialog: false });
    }
    saveSemestre() {
        let state = { submitted: true };

        if (this.state.semestre.codeSemestre && this.state.semestre.libelleSemestre.trim() && this.state.semestre.ordreSemestre && this.state.semestre.niveau) {

            const newSemestre = {
                idSemestre : parseInt(this.state.semestre.idSemestre) ,
                libelleSemestre : this.state.semestre.libelleSemestre,
                codeSemestre : this.state.semestre.codeSemestre,
                ordreSemestre: parseInt(this.state.semestre.ordreSemestre),
                niveau: this.state.semestre.niveau
            }

            if (this.state.semestre.idSemestre) {
                this.semestreService.update(parseInt(this.state.semestre.idSemestre), newSemestre)
                                .then(data => {
                                    this.notify.success()
                                    console.log(data)
                                    this.setState({redirect : true})
                                    this.semestreService.getAll().then(data => 
                                        this.setState({listeSemestre: data, loading : false})
                                        )
                                        .catch(error => {
                                            this.notify.echec(error.response.data.message)
                                            console.log(error)
                                            this.setState({loading : false})
                                        })
                                })
                                .catch(error => {
                                    this.notify.echec(error.response.data.message)
                                    console.log(error);
                                })
            }
            else {
                this.semestreService.create(newSemestre).then(data => {
                    this.notify.success()
                    //console.log(data)
                    this.setState({redirect : true})
                    this.semestreService.getAll().then(data => 
                        this.setState({listeSemestre: data, loading : false})
                        )
                        .catch(error => {
                            this.notify.echec(error.response.data.message)
                            console.log(error)
                            this.setState({loading : false})
                        })
                })
                .catch(error => {
                    this.notify.echec(error.response.data.message)
                    console.log(error);
                })
                
            }

            state = {
                ...state,
                semestreDialog: false,
                semestre: this.emptySemestre
            };
        }

        this.setState(state);
    }
    editSemestre(semestre) {
        this.setState({
            semestre,
            semestreDialog: true
        });
    }
    confirmDeleteSemestre(semestre) {
        this.setState({
            semestre,
            deleteSemestreDialog: true
        });
    }
    deleteSemestre(idSemestre) {
        this.semestreService.delete(parseInt(idSemestre)).then(data => {
            this.notify.success()
            this.setState({
                deleteSemestreDialog: false,
                semestre: this.emptySemestre,
                redirect : true
            });
            this.semestreService.getAll().then(data => 
                this.setState({listeSemestre: data, loading : false})
                )
                .catch(error => {
                    this.notify.echec(error.response.data.message)
                    console.log(error)
                    this.setState({loading : false})
                })
        })
    }
    onLibelleSemestreChange(e, libelleSemestre) {
        const val = (e.target && e.target.value) || '';
        let semestre = this.state.semestre;
        semestre[`${libelleSemestre}`] = val;

        this.setState({semestre });
    }
    onCodeSemestreChange(e, codeSemestre) {
        const val = (e.target && e.target.value) || '';
        let semestre = this.state.semestre;
        semestre[`${codeSemestre}`] = val;

        this.setState({semestre });
    }

    onNiveauChange(e, niveau){

        const val = (e.target && e.target.value) || '';
        let semestre = this.state.semestre;
        semestre[`${niveau}`] = val;

        this.setState({semestre });
    }

    onOrdreSemestreChange (e, ordreSemestre){

        let val = (e.target && e.target.value) || '';

        val = (!isNaN(val))? val:''

        let semestre = this.state.semestre;
        semestre[`${ordreSemestre}`] = val;

        this.setState({semestre });
    }


    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editSemestre(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteSemestre(rowData)} />
            </React.Fragment>
        );
    }
    render() {
        console.log(this.state.listeSemestre)
        if(this.state.redirect) {
            //window.location.reload(false);
        }
        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const semestreDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveSemestre} />
            </React.Fragment>
        );
        const deleteSemestreDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideSemestreDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteSemestre(this.state.semestre.idSemestre) } />
            </React.Fragment>
        );
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid" style={{marginLeft : 20}}>
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                    </div>
    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeSemestre} selectionMode="single" header={`Liste des semestres (${this.state.listeSemestre.length})`} paginator={true} rows={10}
                              globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="libelleSemestre" header="Semestre"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column field="codeSemestre" header="Code semestre"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column field="ordreSemestre" header="Ordre semestre"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column field="niveau.libelleNiveau" header="Niveau semestre"  filterMatchMode="contains" sortable={true} filter={true} />
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }
                    </div>
                </div>

                <Dialog visible={this.state.semestreDialog} style={{ width: '450px' }} header="Informations semestre" modal className="p-fluid" footer={semestreDialogFooter} onHide={this.hideDialog}>
                    <div className="p-field">
                        <label htmlFor="libelleSemestre">Semestre *</label>
                        <InputText id="libelleSemestre" value={this.state.semestre.libelleSemestre}  onChange={(e) => this.onLibelleSemestreChange(e, 'libelleSemestre')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder='Libelle du semestre'/>
                        {this.state.submitted && !this.state.semestre.libelleSemestre && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <hr />
                    <div className="p-field">
                        <label htmlFor="codeSemestre">Code semestre *</label>
                        <InputText id="codeSemestre" value={this.state.semestre.codeSemestre}  onChange={(e) => this.onCodeSemestreChange(e, 'codeSemestre')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder='Code du semestre'/>
                        {this.state.submitted && !this.state.semestre.codeSemestre && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <hr/>
                    <div className="p-field">
                        <label htmlFor="libelleNiveau">Niveau </label>
                        <Dropdown optionLabel="libelleNiveau" optionValue="idNiveau" value={this.state.semestre.niveau} options={this.state.listeNiveau} onChange={(e) => {this.onNiveauChange(e, 'niveau')}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })}  placeholder="Selectionner le niveau"/>
                        {this.state.submitted && !this.state.semestre.niveau && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div>
                    <hr />

                    <div className="p-field">
                        <label htmlFor="ordreSemestre">Ordre semestre (valeur numérique)*</label>
                        <InputText id="ordreSemestre" value={this.state.semestre.ordreSemestre}  onChange={(e) => this.onOrdreSemestreChange(e, 'ordreSemestre')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder='Ordre du semestre'/>
                        {this.state.submitted && !this.state.semestre.ordreSemestre && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire et de type numerique.</small>}

                    </div>
                </Dialog>
                <Dialog visible={this.state.deleteSemestreDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteSemestreDialogFooter} onHide={this.hideDeleteSemestreDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.semestre && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>
            </div> 
        )
    }
}

export default Semestre
