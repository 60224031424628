import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import classNames from 'classnames'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Notify } from '../../../components/Notify'
import { Spinner } from '../../../components/Spinner'
import { ToastContainer} from 'react-toastify'
import { Dropdown } from 'primereact/dropdown'
import { ParcoursFiliereService } from '../../service/ParcoursFiliereService'
import { FiliereService} from '../../service/FiliereService'
import { ParcoursService } from '../../service/ParcoursService'

import { PickList } from 'primereact/picklist';
import { AnneeService } from '../../service/AnneeService'

export class ParcoursFiliere extends Component {

    //Model de l'objet ParcoursFiliere
    emptyParcoursFiliere = {
        departementFiliere: null,
        description: null
    }

    constructor(props){
        super(props);
        this.notify = new Notify();
        this.spinner = new Spinner()

        this.ModelFiliere = new FiliereService();
        this.ModelParcours = new ParcoursService();
        this.ModelParcoursFiliere = new ParcoursFiliereService ();
        this.anneeService = new AnneeService();

        this.state = {
            curentParcoursFiliere: this.emptyParcoursFiliere,
            listeParcoursFiliere: [],
            listeParcours: [],
            listeDepartementFiliere : [],
            listeParcoursChoix: [],
            listeParcoursDisponible: [],
            listeAnnee : [],
            annee : {},
            selectionParcours : [],
            selectionChoixParcours : [],
            redirect : false,
            loading : false,
            globalFilter: null,
            mouvement : false,
            confirmDialog : false,
            viewCreateParcoursFiliereDialog: false,
            allowSave: false,
            viewDeleteParcoursFiliereDialog: false,
            departementFiliereDeletionId: null,
            parcoursDeletionId: null,
            listeSousParcours: [],
            viewListeParcoursDialog: false,
            departementId: null,
            departement: null,
            roleParDep : 0
        }
    }

    componentDidMount(){
        const tokenDecoded = localStorage.getItem('tokenDecoded')
        const departement = localStorage.getItem('departement')
        const token = JSON.parse(tokenDecoded)
        const depart = JSON.parse(departement)

        const campusDdip = localStorage.getItem('campusDdip')
        const ddip = JSON.parse(campusDdip)
        const campusDirecteur = localStorage.getItem('campusDirecteur')
        const directeur = JSON.parse(campusDirecteur)
        const campusChefDepartement = localStorage.getItem('campusChefDepartement')
        const chefDepartement = JSON.parse(campusChefDepartement)
        let roleParDep = 0
        this.setState({departementId : token.code_departement})
        if(ddip === 1 || directeur === 1 || chefDepartement === 1) {
            roleParDep = 1
            this.setState({roleParDep : roleParDep})
        }
        else {
            roleParDep = 0
            this.setState({roleParDep : roleParDep})
        }
        

        /*this.setState({loading : true})
        this.ModelParcoursFiliere.getAll().then(data => {
            this.setState({
                listeParcoursFiliere: data,
                loading: false,
                departementId: token.code_departement,
                departement: depart[0]
            })
            }
        )
        .catch(error => {
            this.notify.echec()
            console.log(error)
            this.setState({loading : false})
        })*/

        this.anneeService.getAll().then(data => {
            this.setState({listeAnnee : data});
        })
        //On recupère la liste des filieres
        this.ModelFiliere.getAll().then(data => {
            this.setState({
                listeDepartementFiliere: data
            })
        })
        .catch(error => {
            console.log(error)
        })

        this.ModelParcours.getAll().then(data => {
                this.setState({
                    listeParcours: data
                })
            }
        )
        .catch(error => {
            console.log(error)
        })
        this.setState({loading : true})
        this.ModelParcoursFiliere.getListeParcoursDepartement(parseInt(token.code_departement)).then(data => {
            this.setState(
                {
                    listeSousParcours: data,
                    loading : false
                }
            )
        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
            this.setState({loading : false})
        })
    }

    editParcoursFiliere = (rowData) => {
        this.setState({
            curentParcoursFiliere: rowData,
            viewCreateParcoursFiliereDialog: true
        })
    }

    confirmDeleteParcoursFiliere = (rowData) => {

        this.setState({
            departementFiliereDeletionId: this.state.departementId,
            parcoursDeletionId: rowData.idParcoursFiliere,
            viewDeleteParcoursFiliereDialog: true
        })
    }

    hideDeleteParcoursDialog = () => {
        this.setState({
            viewDeleteParcoursFiliereDialog: false
        })
    }

    deleteParcoursFiliere = () => {

        let parcoursId = this.state.parcoursDeletionId
        let departementFiliereId = this.state.departementId

        if(parcoursId && departementFiliereId){

            this.ModelParcoursFiliere.delete(parseInt(parcoursId)).then(data => {
                this.notify.success()

                //On met à jour la liste des parcours
                let idDepartementFiliere = this.state.departementId
                this.ModelParcoursFiliere.getListeParcoursDepartement(parseInt(idDepartementFiliere)).then(data => {
                    this.setState(
                        {
                            listeSousParcours: data,
                            parcoursDeletionId: null,
                            departementFiliereDeletionId: null,
                            viewDeleteParcoursFiliereDialog: false,
                        }
                    )
                }).catch(error => {
                    console.log(error)
                    this.notify.echec(error.response.data.message)
                })

                }).catch(error => {
                    this.notify.echec(error.response.data.message)
                    console.log(error)
                    this.setState({
                        viewDeleteParcoursFiliereDialog: false,
                        deletionId: null
                    })
            })
    }
}

    hideDialog = () => {
       
        if(this.state.mouvement){
            this.setState({confirmDialog : true})
        }
        else {
            this.setState({
                curentParcoursFiliere: {
                    departementFiliere: null,
                    description: null
                },
                viewCreateParcoursFiliereDialog: false
            })
        }
        /*this.setState({
            curentParcoursFiliere: {
                departementFiliere: null,
                description: null
            },
            viewCreateParcoursFiliereDialog: false
        })*/
    }

    //Gestion de l'ouverture du formulaire
    openNew = () => {

        
        let collectionIdParcour = []
        this.state.listeSousParcours.map(parcours => {
            collectionIdParcour.push(parcours.idParcours)
        })

        //console.log(this.state.listeSousParcours)
        //console.log(this.state.listeParcours)


        let choixDisponible = []
        choixDisponible = this.state.listeParcours.filter(parcours => {
            return (collectionIdParcour.indexOf(parcours.idParcours)===-1)
        })

        //console.log(choixDisponible)

        this.setState(
            {
                viewCreateParcoursFiliereDialog: true,
                curentParcoursFiliere: {
                    departementFiliere: null,
                    description: null
                },
                listeParcoursDisponible: choixDisponible,
                listeParcoursChoix: this.state.listeSousParcours
            }
        )
    }

    onInputChange = (event, field) => {

        let curentParcoursFiliere = this.state.curentParcoursFiliere
        curentParcoursFiliere[`${field}`] = event.target.value
        
        this.setState({
            curentParcoursFiliere
        })
    }

    onAnneeChange = (event) => {
        this.setState({
            annee : event.target.value
        })
    }


   hideDeleteParcoursFiliereDialog = () => {
        this.setState(
            {
                viewDeleteParcoursFiliereDialog: false,
                deletionId: null
            }
        )
    }


    onParcoursSelectionChange = (e) => {

        if(this.state.selectionParcours.length>0){

            //On insert les élément selectionnés dans les choix d'UE
            let listeParcoursChoix = this.state.listeParcoursChoix

            //On recupère la liste globale des UE disponibles
            let listeParcours = this.state.listeParcours

            //On recupère aussi les id UE pour les retirer de la selection
            let listeIdParcours = []

            this.state.selectionParcours.map(parcours => {
                listeParcoursChoix.push(parcours)
                listeIdParcours.push(parcours.idParcours)
            })

            let newListeParcoursDisponible = listeParcours.filter(parcours => {
                return (listeIdParcours.indexOf(parcours.idParcours)===-1)
            })

            //On positionne les élements selectionnés
            this.setState({
                listeParcoursChoix: listeParcoursChoix,
                selectionParcours: [],
                listeParcours: newListeParcoursDisponible
            })
        }
    }

    onParcoursDelete = (e) => {
        
        if(this.state.selectionChoixParcours.length>0){

            //Liste des UE disponibles
            let listeParcours = this.state.listeParcours

            //Liste des UE choisi
            let listeParcoursChoix = this.state.listeParcoursChoix
            
            //On filtre pour ne prendre en compte ce qui n'as pas été selectionné pour suppression
            let listeIdParcoursASupprimer = []

            this.state.selectionChoixParcours.map(parcours => {
                listeIdParcoursASupprimer.push(parcours.idParcours)
                listeParcours.push(parcours)
            })

            //Liste des UE choisi restant
            let listeParcoursRestant = listeParcoursChoix.filter(parcours => {
                return (listeIdParcoursASupprimer.indexOf(parcours.idParcours)===-1)
            })
            //console.log(listeParcoursRestant)
            this.setState({
                selectionChoixParcours: [],
                listeParcours: listeParcours,
                listeParcoursChoix: listeParcoursRestant
            })

        }
        
    }

    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-list" className="p-button-warning" onClick={() => this.listeParcours(rowData)} style={{marginRight: '.5em'}} title='Liste parcours'/>
            </React.Fragment>
        );
    }

    actionSubBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-trash" className="p-button-warning" onClick={() => this.confirmDeleteParcoursFiliere(rowData)} style={{marginRight: '.5em'}} disabled={true} title='Liste parcours'/>
            </React.Fragment>
        );
    }


    itemTemplate = (item) => {
        return (
            <div className="">
                <div className="">
                    <h6 className="p-mb-2">{item.libelleParcours}</h6>
                </div>
            </div>
        );
    }

    onSelectionParcours = (event) => {
        
        this.setState({
            listeParcoursDisponible: event.source,
            listeParcoursChoix: event.target,
            mouvement : true
        })
    }


    saveParcoursFiliere = () => {
        
        
        if(this.state.listeParcoursChoix.length>0){
            console.log("yes !")
            //Id du departement selectionné
            let idDepartementFiliere = this.state.departementId
            //console.log(idDepartementFiliere)
            //Constitution de la liste à envoyer
            let parcoursFiliere = []

            this.state.listeParcoursChoix.map(parcours =>{
                return parcoursFiliere.push(
                    {
                        idAnneeAcademique : this.state.annee.idAnneeAcademique,
                        idDepartementFiliere: idDepartementFiliere,
                        idParcours: parcours.idParcours,
                        description: this.state.curentParcoursFiliere.description
                    }
                )
            })
            
            //On peut proceder à la mise à jour ou à l'ajout de la spécialité
            //console.log(parcoursFiliere)
            this.ModelParcoursFiliere.create(parseInt(idDepartementFiliere), parcoursFiliere).then(data => {
                
                this.notify.success()
                this.setState({ 
                    viewCreateParcoursFiliereDialog: false,
                    mouvement : false
                })

                this.setState({
                    loading: true
                })

                this.ModelParcoursFiliere.getListeParcoursDepartement(parseInt(this.state.departementId)).then(data => {
                    this.setState(
                        {
                            listeSousParcours: data,
                            loading: false
                        }
                    )
                }).catch(error => {
                    console.log(error)
                    this.setState({
                        loading: false
                    })
                })

            }).catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error)
            })
        }
    }

    hideParcoursDialog = () => {
        this.setState({
            viewListeParcoursDialog: false
        })
    }
    hideConfirmDialog = () => {
        this.setState({confirmDialog : false})
    }
    hideConfirmQuitterDialog = () => {
        this.setState({confirmDialog : false, viewCreateParcoursFiliereDialog : false, mouvement : false})
    }
    
    render() {
        //console.log(this.state.listeSousParcours)
        //Formulaire de recherche superieur
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );

       const deleteParcoursDialogFooter = (
        <React.Fragment>
            <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteParcoursFiliereDialog} />
            <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteParcoursFiliere() } />
        </React.Fragment>
        )

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        let titreDepartement = (this.state.departement)? (`Liste des parcours du département: ${this.state.departement.lbDepartementEtab} - ${this.state.departement.nomIes}`): (``)
        
        const parcoursDialogFooter = (
            <React.Fragment>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveParcoursFiliere} />
            </React.Fragment>
        );
        const confirmDialogFooter = (
            <React.Fragment>
                <Button label="OUI" icon="pi pi-times" className="p-button-text" onClick={this.hideConfirmQuitterDialog} />
                <Button label="NON" icon="pi pi-check" className="p-button-text" onClick={this.hideConfirmDialog} />
            </React.Fragment>
        );

        return (
                <div className="p-grid">
                    <div className="p-col-12">
                        
                        <div className="p-grid" style={{marginLeft : 20}}>
                            <div className="p-col-2">
                                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                            </div>
                        </div>
                        
                        <div className="card card-w-title datatable-demo">
                        <ToastContainer
                            position="top-right"
                            autoClose={2000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />

                            {header}
                            {this.state.loading ? 
                            <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                            :
                            <div className="p-field">
                                <DataTable  ref={(el) => this.dt = el} value={this.state.listeSousParcours} selectionMode="none" header={titreDepartement}  paginator={true} rows={10}
                                    globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                                   
                                        <Column field="libelleParcours" header="Libellé parcours"  sortable={true} filter={true}/>
                                        
                                        <Column header={actionHeader} body={this.actionSubBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                                        
                                </DataTable>
                            </div>
                            }
                        </div>
                    </div>

                <Dialog visible={this.state.viewCreateParcoursFiliereDialog} style={{ width: '95%' }} header="Sélection des parcours d'un département" modal className="p-fluid" footer={parcoursDialogFooter} onHide={this.hideDialog}>
                    <hr />
                    {/* 
                    <div className="p-field">
                        <Dropdown optionLabel="libelleAnnee" optionValue="idAnneeAcademique" value={this.state.annee} options={this.state.listeAnnee} onChange={(e) => {this.onAnneeChange(e)}} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} placeholder="Année académique"/>
                        {this.state.submitted && !this.state.annee && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                    </div> 
                    */}

                    <div className="p-grid">
                        <div className="p-field p-col-5">
                            <DataTable  ref={(el) => this.dt = el} value={this.state.listeParcours} header="Liste des Parcours disponibles" paginator={true} rows={5}
                                globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.selectionParcours} onSelectionChange={event => this.setState({selectionParcours: event.value})}>
                                <Column field="libelleParcours" header="Parcours" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher Parcours"/>
                                <Column selectionMode="multiple" headerStyle={{ width: '5em' }}></Column>
                            </DataTable>
                            <Button label="Choisir la sélection" icon="pi pi-check" className="p-button-text" onClick={e => this.onParcoursSelectionChange(e)} />
                        </div>
                        
                        
                        <div className="p-field p-col-2" style={{marginTop : 40}} >
                            <div className='p-field p-col-12'>
                                <Button label="Choisir -->" onClick={e => this.onParcoursSelectionChange(e)} />
                            </div>
                            <div className='p-field p-col-12'>
                                <Button label="<-- Retirer" onClick={e => this.onParcoursDelete(e)} />
                            </div>
                        </div>
                        
                        <div className="p-field p-col-5">
                            <DataTable  ref={(el) => this.dt = el} value={this.state.listeParcoursChoix} header="Liste des Parcours choisis" paginator={true} rows={5}
                                globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.selectionChoixParcours} onSelectionChange={event => this.setState({selectionChoixParcours: event.value})}>
                                <Column field="libelleParcours" header="Parcours" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher Parcours"/>
                                <Column selectionMode="multiple" headerStyle={{ width: '5em' }}></Column>
                            </DataTable>
                            <Button label="Retirer la sélection" icon="pi pi-trash" className="p-button-text p-danger" onClick={e => this.onParcoursDelete(e)} />
                        </div>
                    </div>


                    <hr />
                    <div className="p-field">
                        <label htmlFor="description">Description</label>
                        <textarea id="description" value={this.state.curentParcoursFiliere.description} onChange={(e) => this.onInputChange(e, 'description')}  placeholder="Description" className='p-inputtext p-component'/>
                    </div>
                </Dialog>
                
                <Dialog visible={this.state.viewDeleteParcoursFiliereDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteParcoursDialogFooter} onHide={this.hideDeleteParcoursDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.departementFiliereDeletionId && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>

                <Dialog visible={this.state.confirmDialog} style={{ width: '450px' }} header="Confirmez" modal footer={confirmDialogFooter} onHide={this.hideConfirmDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        <span>Voulez - vous quitter sans enregistrer les nouvelles modifications ?</span>
                    </div>
                </Dialog>

            </div> 
        )
    }
}
export default ParcoursFiliere