import React from "react";
import './parametres/index.css';
import avatartMan from '../../images/avatar_man.png'


export default function Notification(){

    return (
        <React.Fragment>
            <div className="card tchat-container">
                <div className="left-container">
                    <div style={{display:"flex",alignContent:"center",height:50,marginTop:10,width:"100%"}}>
                        <div className="avatar-container">
                            <img className="avatar" src={avatartMan} alt="avatar man" />
                        </div>
                        <div style={{marginTop:"15px"}}>Ouedraogo salam</div>
                    </div>
                    <div style={{display:"flex",alignContent:"center",height:50,marginTop:10,width:"100%"}}>
                        <div className="avatar-container">
                            <img className="avatar" src={avatartMan} alt="avatar man" />
                        </div>
                        <div style={{marginTop:"15px"}}>Ouedraogo salam</div>
                    </div>
                    <div style={{display:"flex",alignContent:"center",height:50,marginTop:10,width:"100%"}}>
                        <div className="avatar-container">
                            <img className="avatar" src={avatartMan} alt="avatar man" />
                        </div>
                        <div style={{marginTop:"15px"}}>Ouedraogo salam</div>
                    </div>
                    <div style={{display:"flex",alignContent:"center",height:50,marginTop:10,width:"100%"}}>
                        <div className="avatar-container">
                            <img className="avatar" src={avatartMan} alt="avatar man" />
                        </div>
                        <div style={{marginTop:"15px"}}>Ouedraogo salam</div>
                    </div>
                    <div style={{display:"flex",alignContent:"center",height:50,marginTop:10,width:"100%"}}>
                        <div className="avatar-container">
                            <img className="avatar" src={avatartMan} alt="avatar man" />
                        </div>
                        <div style={{marginTop:"15px"}}>Ouedraogo salam</div>
                    </div>
                </div>
                <div className="rigth-container">
                    <div className="header-message">
                        <div className="periode">
                            <p className="date">30 dec 2022</p>
                            <p className="heure">10:30</p>
                        </div>
                        <div style={{display:"flex",alignContent:"center"}}>
                            <div className="avatar-container">
                                <img className="avatar" src={avatartMan} alt="avatar man" />
                            </div>
                            <div className="infos-perso">
                                <div>
                                    <span>Ouedraogo Salam</span><br />
                                    <small>salamouedraogo245@gmail.com</small>
                                </div>
                                <p>Université Nazi Boni/Departement</p>
                            </div>
                        </div>
                    </div>
                    <div className="content-message">
                        <div className="title" style={{textAlign:"center"}}>
                            Non validation
                        </div>
                        <div className="content">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa officia et asperiores, reiciendis cupiditate animi possimus ipsam sed nobis totam perspiciatis unde, dignissimos numquam. Odit exercitationem error aspernatur pariatur iure!
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa officia et asperiores, reiciendis cupiditate animi possimus ipsam sed nobis totam perspiciatis unde, dignissimos numquam. Odit exercitationem error aspernatur pariatur iure!
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa officia et asperiores, reiciendis cupiditate animi possimus ipsam sed nobis totam perspiciatis unde, dignissimos numquam. Odit exercitationem error aspernatur pariatur iure!
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa officia et asperiores, reiciendis cupiditate animi possimus ipsam sed nobis totam perspiciatis unde, dignissimos numquam. Odit exercitationem error aspernatur pariatur iure!
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa officia et asperiores, reiciendis cupiditate animi possimus ipsam sed nobis totam perspiciatis unde, dignissimos numquam. Odit exercitationem error aspernatur pariatur iure!
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa officia et asperiores, reiciendis cupiditate animi possimus ipsam sed nobis totam perspiciatis unde, dignissimos numquam. Odit exercitationem error aspernatur pariatur iure!
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa officia et asperiores, reiciendis cupiditate animi possimus ipsam sed nobis totam perspiciatis unde, dignissimos numquam. Odit exercitationem error aspernatur pariatur iure!
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa officia et asperiores, reiciendis cupiditate animi possimus ipsam sed nobis totam perspiciatis unde, dignissimos numquam. Odit exercitationem error aspernatur pariatur iure!
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}