import React, { useState, useEffect } from 'react';
import classNames from 'classnames'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { InvitationService } from '../../service/InvitationService';

export default function BorderoInvitation(){

    const tokenDecoded = localStorage.getItem('tokenDecoded');
    const token = JSON.parse(tokenDecoded);

    const [selectedEntites, setSelectedEntites] = useState(null)
    const [listeBordero, setListeBordero] = useState([])
    const [informationBordereau, setInformationBordereau] = useState({})

 
    
    const [submitted, setSubmitted] = useState(false)
    const [globalFilter, setGlobalFilter] = useState(null)
    const [loading, setLoading] = useState(false)
    const [invitationDialog,setInvitationDialog] = useState(false)
    const [spin,setSpin] = useState(false)
    const [idDepartement, setIdDepartement] = useState(token.code_departement)
    const [confirmerEnvoiInvitationDialog, setConfirmerEnvoiInvitationDialog] = useState(false)

    const [dateBordereau, setDateBordereau] = useState('')
    const [numeroBordereau, setNumeroBordereau] = useState('')
    const [iesrDestination, setIesrDestination] = useState({})
    const [listeIesr, setlisteIesr] = useState([])
    const [username, setUsername] = useState(token.preferred_username)

    const notify = new Notify()
    const spinner = new Spinner()

    const invitationService = new InvitationService()


    useEffect(() => {

        setLoading(true)
        invitationService.getListeBordero(username, "Sortant").then(data => {
            setListeBordero(data)
            setLoading(false)
        }).catch(error => {
            console.log(error)
            notify.echec(error.response.data.message)
            setLoading(false)
        })


        invitationService.getListeAutresIesr(idDepartement).then(data => {
            setlisteIesr(data)
        }).catch(error => {
            console.log(error.response.data.message)
            console.log(error)
        })


        }, []
    );


    
    const saveBordereau = () => {

        setSubmitted(true);

        let curentBordereau = informationBordereau

        if(iesrDestination.idIes && numeroBordereau && dateBordereau){
            setSpin(true);


            let newBordereau = {
                idIesrDestinataire: iesrDestination.idIes,
                dateBe: dateBordereau,
                numeroBe: numeroBordereau,
                idDepartementOrigine: idDepartement,
                posBe: username,
                etatBe: 'NOUVEAU',
                etapeBe: 'Sortant'
            }


            if(curentBordereau.idBe){

                invitationService.editionBordereau(curentBordereau.idBe, newBordereau).then(data => {
                    notify.success()
                    setSpin(false)
                    setNumeroBordereau('')
                    setIesrDestination({})
                    setDateBordereau('')
                    setInvitationDialog(false)
    
                    setLoading(true)
                    invitationService.getListeBordero(username, "Sortant").then(data => {
                        setListeBordero(data)
                        setLoading(false)
                    }).catch(error => {
                        console.log(error)
                        notify.echec(error.response.data.message)
                        setLoading(false)
                    })
    
                }).catch(error => {
                    console.log(error)
                    setSpin(false)
                    notify.echec(error.response.data.message)
                })


            }else{

                invitationService.enregistrerBordereau(newBordereau).then(data => {
                    notify.success()
                    setSpin(false)
                    setNumeroBordereau('')
                    setIesrDestination({})
                    setDateBordereau('')
                    setInvitationDialog(false)
    
                    setLoading(true)
                    invitationService.getListeBordero(username, "Sortant").then(data => {
                        setListeBordero(data)
                        setLoading(false)
                    }).catch(error => {
                        console.log(error)
                        notify.echec(error.response.data.message)
                        setLoading(false)
                    })
    
                }).catch(error => {
                    console.log(error)
                    setSpin(false)
                    notify.echec(error.response.data.message)
                })

            }

            setSubmitted(false)
        }
    }
    

    const hideDialog = () => {
        setNumeroBordereau('')
        setDateBordereau('')
        setIesrDestination({})
        setInformationBordereau({})
        setInvitationDialog(false)
        setSubmitted(false)
    }


    const onNumeroBordereauChange = (event) => {
        setNumeroBordereau(event.target.value)
    }


    const onDateBordereauChange = (event) => {
        setDateBordereau(event.target.value)
    }


    const editerBordereauInvitation = (informationBordereau) => {
        setInformationBordereau(informationBordereau)
        setNumeroBordereau(informationBordereau.numeroBe)
        setDateBordereau(informationBordereau.dateBe)
        setIesrDestination(informationBordereau.iesDto)
        setInvitationDialog(true)
    }




    const onIesrChange = (event) => {
        setIesrDestination(event.target.value)
    }


    const validerInvitationEnseignant = (informationInvitation) => {
        setInformationBordereau(informationInvitation)
        setConfirmerEnvoiInvitationDialog(true)
    }

    const hideConfirmerInvitationDialog = () => {
        setConfirmerEnvoiInvitationDialog(false)
        setInformationBordereau({})
        setSpin(false)
    }


    const confirmerValidationInvitation = () => {
        
        setSpin(true)

        let invitationObject = informationBordereau

        invitationObject['etatBe'] = "Receptionner"

        invitationService.transmissionBe(username, invitationObject).then(data => {
            notify.success()
            setSpin(false)
            setNumeroBordereau('')
            setIesrDestination({})
            setDateBordereau('')
            setConfirmerEnvoiInvitationDialog(false)

            setLoading(true)
            invitationService.getListeBordero(username, "Sortant").then(data => {
                setListeBordero(data)
                setLoading(false)
            }).catch(error => {
                console.log(error)
                notify.echec(error.response.data.message)
                setLoading(false)
            })

        }).catch(error => {
            console.log(error)
            setSpin(false)
            notify.echec(error.response.data.message)
        })

    }


    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>

                {!rowData.idBeParent && (<Button icon="pi pi-pencil" className="p-button-warning p-mr-2" title="Détails" style={{marginRight: '.5em'}} onClick={() => editerBordereauInvitation(rowData)} />) }
                <Button icon="pi pi-check-circle" className="p-button-info p-mr-2" title="Valider et transmetre" style={{marginRight: '.5em'}} onClick={() => validerInvitationEnseignant(rowData)} />
            </React.Fragment>
        );
    }

    const header = (
        <div className="table-header p-col-12">
            <span className="p-input-icon-left">
                <Button style={{marginLeft : 10}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={()=>setInvitationDialog(true)} />
                <InputText style={{marginLeft : 20,width:"300px"}} type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
            </span>
        </div>
    );


    const confirmerEnvoiInvitationFooter = (
        <React.Fragment>
            <Button label="Non" disabled={spin?true:false}  icon={spin?"pi pi-spin pi-spinner":"pi pi-ban"} className="p-button-text" onClick={hideConfirmerInvitationDialog} />
            <Button label="Oui" disabled={spin?true:false} icon="pi pi-check" className="p-button-text" onClick={confirmerValidationInvitation} />
        </React.Fragment>
    );
    

    const actionHeader = <Button type="button" icon="pi pi-cog"/>;

    return (
        <div className="datatable-crud-demo">
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="card">
                
                {header}
                {loading ?
                <div className="p-grid">
                    <div className="p-col-6" ></div>
                    <div className="p-col-6" >
                        {spinner.loadingSpinner("spin", "black")}
                    </div>
                </div>
                :
                <DataTable value={listeBordero} selection={selectedEntites} onSelectionChange={(e) => setSelectedEntites(e.value)}
                    dataKey="id" paginator rows={10} 
                    globalFilter={globalFilter}
                    header="Liste des borderaux d'invitation">
                    <Column field="numeroBe" header="Numéro bordereau" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="dateBe" header="Date du bordereau" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="etatBe" header="Etat bordereau" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="posBe" header="Position du bordereau" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="nomIesr" header="Université de destination" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column header={actionHeader} body={actionBodyTemplate} style={{textAlign:'center', width: '160px'}}></Column>
                </DataTable>
                }
            </div>


 
            <Dialog visible={invitationDialog} style={{ width: '95%' }} header="Edition d'une invitation" modal className="p-fluid" onHide={hideDialog}>
            <hr />
                <div className="p-grid">

                    <div className="p-col-6" >
                            <div className="p-field">
                                <label htmlFor="numeroBordereau">Numero bordereau</label>
                                <InputText id="numeroBordereau" value={numeroBordereau}  onChange={(e) => {onNumeroBordereauChange(e)}} required autoFocus className={classNames({ 'p-invalid': submitted })} />
                                {submitted && !numeroBordereau && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>}              
                            </div>
                    </div>

                    <div className="p-col-6" >
                        <div className="p-field">
                            <label htmlFor="dateBordereau">Date bordereau</label>
                            <InputText type="date" id="dateBordereau" value={dateBordereau}  onChange={(e) => {onDateBordereauChange(e)}} required autoFocus className={classNames({ 'p-invalid': submitted })} />
                            {submitted && !dateBordereau && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>}
                        </div>
                    </div>
                </div>

                <hr/>

                <div className="p-grid">
                    <div className="p-col-12" >
                            <div className="p-field">
                                <label htmlFor="numeroBordereau">Université de destination de l'invitation</label>
                                <Dropdown optionLabel="nomIes" optionValue={iesrDestination.nomIes} value={iesrDestination} filter filterBy={iesrDestination.nomIes}  options={listeIesr} onChange={(e) => {onIesrChange(e)}} required autoFocus className={classNames({ 'p-invalid': submitted })} />
                                {submitted && !iesrDestination.nomIes && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>}            
                        </div>
                    </div>
                </div>
            
                <hr/>
                <div  style={{width:"200px",display:"flex"}} className="mt-3">
                    <Button label="Quitter" icon="pi pi-times" className="p-button-danger mr-2" type='button' onClick={hideDialog}  disabled={spin?true:false} />
                    <Button label='Enregistrer' icon={spin?"pi pi-spin pi-spinner":"pi pi-save"} className='p-button-success mr-2' onClick={saveBordereau}  disabled={spin?true:false} />
                </div>

            </Dialog>


            <Dialog visible={confirmerEnvoiInvitationDialog} style={{ width: '450px' }} header="Confirmmation d'envoi bordereau" modal footer={confirmerEnvoiInvitationFooter} onHide={hideConfirmerInvitationDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-info-circle p-mr-3" style={{ fontSize: '2rem'}} />
                    <center>
                        {informationBordereau && <span>Êtes vous sûr de vouloir confirmer l'envoi de ce bordereau au chef de département ? </span>}
                    </center>
                </div>
            </Dialog>

        </div>
    )
}