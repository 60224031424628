import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { Controller, useForm } from "react-hook-form";
import classNames from "classnames";
import { Notify } from "../../components/Notify";
import { ToastContainer } from "react-toastify";
import { Spinner } from "../../components/Spinner";
import { Dropdown } from "primereact/dropdown";
import { AnneeService } from "../service/AnneeService";
import { CohorteServices } from "../../volumeHoraire/service/CohorteServices";
import { ParcoursFiliereService } from "../service/ParcoursFiliereService";
import { SemestreService } from "../service/SemestreService";
import { NiveauService } from "../service/NiveauService";
import { SessionExamenService } from "../service/SessionExamenService";
import { ReclamationService } from "../service/ReclamationService";
import { InscriptionService } from "../service/InscriptionService";
import { ExamenService } from "../service/ExamenService";

function Reclamation() {
    const spinner = new Spinner();
    const notify = new Notify();
    const actionHeader = <Button type="button" icon="pi pi-cog" />;

    const anneeService = new AnneeService();
    const cohorteServices = new CohorteServices();
    const parcoursDepartementService = new ParcoursFiliereService();
    const semestreService = new SemestreService();
    const niveauService = new NiveauService();
    const sessionService = new SessionExamenService();
    const reclamationService = new ReclamationService();
    const inscriptionService = new InscriptionService();
    const examenService = new ExamenService();

    const defaultValues = {
        idSessionReclamation: null,
        libelleSession: null,
        dateOuverture: null,
        dateFermeture: null,
        annee: null,
        departement: null,
        parcour: null,
        niveau: null,
        semestre: null,
        session: null,
    };
    const reclamationEmpty = {
        idDemandeReclamation: null,
        sessionReclamation: null,
        etudiant: null,
        examen: null,
        description: null,
        etatDemande: null,
    };
    const [reclamation, setReclamation] = useState(reclamationEmpty);
    const [sessionReclamation, setSessionReclamation] = useState(defaultValues);
    const [sessionReclamations, setSessionReclamations] = useState([]);
    const [departement, setDepartement] = useState();
    const [departements, setDepartements] = useState([]);
    const [parcour, setParcour] = useState();
    const [parcours, setParcours] = useState([]);
    const [niveau, setNiveau] = useState();
    const [niveaus, setNiveaus] = useState([]);
    const [semestre, setSemestre] = useState();
    const [semestres, setSemestres] = useState([]);
    const [session, setSession] = useState();
    const [typeSessions, setTypeSessions] = useState([]);
    const [typeSession, setTypeSession] = useState();
    const [sessions, setSessions] = useState([]);
    const [annee, setAnnee] = useState();
    const [annees, setAnnees] = useState([]);
    const [dateDebut, setDateDebut] = useState(null);
    const [dateFin, setDateFin] = useState(null);
    const [token, setToken] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [loadin, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [deleteSessionDialog, setDeleteSessionDialog] = useState(false);
    const [dialogReceptionReclammation, setDialogReceptionReclammation] =
        useState(false);
    const [listeReclamationSession, setListeReclamationSession] = useState([]);
    const [dialogeDepotReclamation, setDialogeDepotReclamation] =
        useState(false);
    const [examen, setExamen] = useState(null);
    const [examens, setExamens] = useState([]);
    const [motif, setMotif] = useState(null);
    const [etudiant, setEtudiant] = useState(null);
    const [selectionExamen, setSelectionExamen] = useState(null);
    const [demandeReclamations, setDemandeReclamations] = useState([]);
    const [mesReclamations, setMesReclamations] = useState([]);
    const [dialogMesReclamation, setDialogMesReclamation] = useState(false);

    const campusScolarite = localStorage.getItem("campusScolarite");
    const campusEtudiant = localStorage.getItem("campusEtudiant");
    const isScolarite = parseInt(JSON.parse(campusScolarite));
    const isEtudiant = parseInt(JSON.parse(campusEtudiant));

    useEffect(() => {
        const tokenDecoded = localStorage.getItem("tokenDecoded");
        const token = JSON.parse(tokenDecoded);
        setToken(token);
        if (isEtudiant) {
            const ine = `${token.preferred_username[0].toUpperCase()}${token.preferred_username.slice(
                1
            )}`;
            cohorteServices.getEtudiant(ine).then((data) => {
                //console.log(data)
                setEtudiant(data);
                setSemestres(data.listeSemetreDto);
            });
        } else if (isScolarite) {
            anneeService
                .getAll()
                .then((response) => {
                    setAnnees(response);
                })
                .catch((errors) => {
                    console.log(errors);
                });
        }

        /*reclamationService.getAll().then(data => {
            setSessionReclamations(data)
            console.log(data)
        })*/
    }, []);

    const AjoutReclamation = () => {
        let _demandeReclamations = [...demandeReclamations];
        if (reclamation.examen && reclamation.description) {
            const newDemande = {
                idDemandeReclamation: reclamation.idDemandeReclamation,
                sessionReclamation: reclamation.sessionReclamation,
                examen: reclamation.examen,
                etudiant: reclamation.etudiant,
                //idNotation : reclamation.examen.notationDto.idNotation,
                description: reclamation.description,
            };
            //console.log(newDemande)
            _demandeReclamations.push(newDemande);
            setDemandeReclamations(_demandeReclamations);
        }
    };

    const saveReclamation = () => {
        setSubmitted(true);

        if (demandeReclamations.length > 0) {
            //console.log(demandeReclamations)
            reclamationService
                .createReclamation(demandeReclamations)
                .then((data) => {
                    //console.log(data)
                    notify.success();
                })
                .catch((error) => {
                    console.log(error);
                    notify.echec(error.response.data.message);
                });
        }
    };

    const saveSessionReclamation = () => {
        setSubmitted(true);
        if (
            sessionReclamation.session.idSession &&
            sessionReclamation.libelleSession &&
            sessionReclamation.dateOuverture &&
            sessionReclamation.dateFermeture
        ) {
            const newSessionReclamation = {
                idSessionReclamation: sessionReclamation.idSessionReclamation,
                idSession: sessionReclamation.session.idSession,
                libelleSession: sessionReclamation.libelleSession,
                dateOuverture: sessionReclamation.dateOuverture,
                dateFermeture: sessionReclamation.dateFermeture,
            };
            //console.log(newSessionReclamation)
            if (sessionReclamation.idSessionReclamation) {
                reclamationService
                    .update(
                        sessionReclamation.idSessionReclamation,
                        newSessionReclamation
                    )
                    .then((data) => {
                        //reclamationService.getAll().then(data => setSessionReclamations(data));
                        notify.success();
                    })
                    .catch((error) => {
                        console.log(error);
                        notify.echec(error.response.data.message);
                    });
            } else {
                reclamationService
                    .create(newSessionReclamation)
                    .then((data) => {
                        console.log(data);
                        /*reclamationService.getAll().then(dataAll => {
                            setSessionReclamations(dataAll)
                    });*/
                        notify.success();
                    })
                    .catch((error) => {
                        console.log(error);
                        notify.echec(error.response.data.message);
                    });
            }
        }
        setSessionReclamation(defaultValues);
        setOpenDialog(false);
    };

    const onChangeDepotReclamation = (e, field) => {
        let val;
        if (field !== "examen") val = (e.target && e.target.value) || "";
        else val = e.value;
        let _reclamation = { ...reclamation };
        _reclamation[`${field}`] = val;
        console.log(_reclamation);
        setReclamation(_reclamation);
    };

    const onChangeListeReclamation = (val) => {
        console.log(val);
        let _reclamation = { ...reclamation };
        _reclamation = val;
        setReclamation(_reclamation);
    };

    const onChangeReclamation = (e, field) => {
        let val = (e.target && e.target.value) || "";
        let _sessionReclamation = { ...sessionReclamation };
        _sessionReclamation[`${field}`] = val;

        if (field == "annee") {
            cohorteServices
                .getListeDepartementEtab(token.code_etablissement)
                .then((data) => {
                    const listDepartement = data.departementEtabDtos;
                    setDepartements(listDepartement);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        if (field == "departement") {
            parcoursDepartementService
                .getListeParcoursDepartement(val.idDepartementEtab)
                .then((data) => {
                    setParcours(data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        if (field == "parcour") {
            let _niveau = [];
            semestreService.get(val.idParcours).then((data) => {
                let _semestre = data;
                setSemestres(data);

                niveauService.getAll().then((response) => {
                    response.map((niv) => {
                        _semestre.map((se) => {
                            if (niv.idNiveau === se.idNiveau) _niveau.push(niv);
                        });
                    });
                    let collectionIdNiveau = [];
                    _niveau.map((nive) => {
                        collectionIdNiveau.push(nive.idNiveau);
                    });

                    let listeNiveau = [];
                    _niveau.map((nivo, index) => {
                        for (
                            let i = index + 1;
                            i < collectionIdNiveau.length;
                            i++
                        ) {
                            if (nivo.idNiveau === collectionIdNiveau[i]) {
                                listeNiveau.push(nivo);
                            }
                        }
                    });
                    //console.log(listeNiveau)
                    setNiveaus(listeNiveau);
                });
            });
        }
        if (field == "niveau") {
            semestreService.getSemestreNiveau(val.idNiveau).then((data) => {
                setSemestres(data.semestreDtoList);
            });
        }
        if (field == "semestre") {
            const obj = {
                idParcoursFiliere: sessionReclamation.parcour.idParcoursFiliere,
                idSemestre: val.idSemestre,
                idAnneeAcademique: sessionReclamation.annee.idAnneeAcademique,
            };
            sessionService.getSession(obj).then((data) => {
                setSessions(data);
                let typeS = data.map((se) => {
                    return se.typeSession;
                });
                setTypeSessions(typeS);
            });
        }
        if (field == "session") {
            console.log(val);
            const libelleSession = `Session de réclamation-${sessionReclamation.parcour.libelleParcours}-${val.typeSession.libelleTypeSession}-${sessionReclamation.semestre.libelleSemestre}-${sessionReclamation.annee.libelleAnnee}`;
            _sessionReclamation["libelleSession"] = libelleSession;
        }
        setSessionReclamation(_sessionReclamation);
    };
    const onChangeAnnee = (e) => {
        let val = (e.target && e.target.value) || "";
        setAnnee(val);
        cohorteServices
            .getListeDepartementEtab(token.code_etablissement)
            .then((data) => {
                const listDepartement = data.departementEtabDtos;
                setDepartements(listDepartement);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const onChangeDepartement = (e) => {
        const _departement = (e.target && e.target.value) || "";
        setDepartement(_departement);
        parcoursDepartementService
            .getListeParcoursDepartement(_departement.idDepartementEtab)
            .then((data) => {
                setParcours(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const onChangeParcours = (e) => {
        let val = (e.target && e.target.value) || "";
        setParcour(val);
        let _niveau = [];
        semestreService.get(val.idParcours).then((data) => {
            let _semestre = data;
            setSemestres(data);

            niveauService.getAll().then((response) => {
                response.map((niv) => {
                    _semestre.map((se) => {
                        if (niv.idNiveau === se.idNiveau) _niveau.push(niv);
                    });
                });
                let collectionIdNiveau = [];
                _niveau.map((nive) => {
                    collectionIdNiveau.push(nive.idNiveau);
                });

                let listeNiveau = [];
                _niveau.map((nivo, index) => {
                    for (
                        let i = index + 1;
                        i < collectionIdNiveau.length;
                        i++
                    ) {
                        if (nivo.idNiveau === collectionIdNiveau[i]) {
                            listeNiveau.push(nivo);
                        }
                    }
                });

                setNiveaus(listeNiveau);
            });
        });
    };

    const onChangeNiveau = (e) => {
        let val = (e.target && e.target.value) || "";
        setNiveau(val);
        semestreService.getSemestreNiveau(val.idNiveau).then((data) => {
            setSemestres(data.semestreDtoList);
        });
    };
    const onChangeSemestre = (e) => {
        let val = (e.target && e.target.value) || "";
        setSemestre(val);
        let obj;
        if (isEtudiant) {
            obj = {
                idParcoursFiliere: etudiant.idParcoursFiliere,
                idSemestre: val.idSemestre,
                idAnneeAcademique: etudiant.idAnneeAcademique,
            };
        } else if (isScolarite) {
            obj = {
                idParcoursFiliere: parcour.idParcoursFiliere,
                idSemestre: val.idSemestre,
                idAnneeAcademique: annee.idAnneeAcademique,
            };
        }
        sessionService.getSession(obj).then((data) => {
            setSessions(data);
            let typeS = data.map((se) => {
                return se.typeSession;
            });
            setTypeSessions(typeS);
        });
    };

    const onChangeTypeSession = (e) => {
        let val = (e.target && e.target.value) || "";
        setTypeSession(val);
    };
    const onChangeExamen = (e) => {
        let val = (e.target && e.target.value) || "";
        setExamen(val);
    };
    const onChangeMotif = (e) => {
        let val = (e.target && e.target.value) || "";
        setMotif(val);
    };
    const listSessionReclamation = () => {
        let idParcoursFiliere;
        let idAnneeAcademique;
        let idSession = typeSession.idTypeSession;
        let idSemestre = semestre.idSemestre;
        if (isEtudiant) {
            idParcoursFiliere = etudiant.idParcoursFiliere;
            idAnneeAcademique = etudiant.idAnneeAcademique;
        } else if (isScolarite) {
            idParcoursFiliere = parcour.idParcoursFiliere;
            idAnneeAcademique = annee.idAnneeAcademique;
        } else {
            idSession = null;
            idSemestre = null;
        }

        reclamationService
            .getListSession(
                idSession,
                idParcoursFiliere,
                idAnneeAcademique,
                idSemestre
            )
            .then((data) => {
                console.log(data);
                setSessionReclamations(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const openNewSessionReclamation = () => {
        setOpenDialog(true);
        anneeService
            .getAll()
            .then((response) => {
                setAnnees(response);
            })
            .catch((errors) => {
                console.log(errors);
            });
    };
    const hideDialog = () => {
        setOpenDialog(false);
        setSessionReclamation(defaultValues);
        setAnnees([]);
        setDepartements([]);
        setParcours([]);
        setNiveaus([]);
        setSemestres([]);
        setAnnee(null);
        setDepartement(null);
        setParcour(null);
        setNiveau(null);
        setSemestre(null);
    };
    const confirmDeleteSessionReclamation = (sessionReclamation) => {
        setSessionReclamation(sessionReclamation);
        setDeleteSessionDialog(true);
    };
    const editSessionReclamation = (sessionReclamation) => {
        console.log(sessionReclamation);
        setSessionReclamation(sessionReclamation);
        setOpenDialog(true);
    };
    const deleteSessionReclamation = () => {
        reclamationService
            .delete(sessionReclamation.idSessionReclamation)
            .then((data) => {
                console.log(data);
                //reclamationService.getAll().then(data => setSessionReclamations(data));
                notify.success();
            })
            .catch((error) => {
                console.log(error);
                notify.echec(error.response.data.message);
            });
        setOpenDialog(false);
    };
    const listeEtudiantReclamation = (sessionR) => {
        //console.log(sessionR)
        setDialogReceptionReclammation(true);
        reclamationService
            .getListeDemandeReclamationSession(sessionR.idSessionReclamation)
            .then((data) => {
                //console.log(data)
                setListeReclamationSession(data);
            });
    };
    const deposerReclamation = (sessionR) => {
        //console.log(sessionR)
        //console.log(etudiant)
        let _reclamation = { ...reclamation };
        _reclamation["sessionReclamation"] = sessionR;
        _reclamation["etudiant"] = etudiant;
        setReclamation(_reclamation);
        setDialogeDepotReclamation(true);
        /*examenService.examenBySession(sessionR.idSession).then(data => {
            console.log(data)
            setExamens(data)
        })*/
        reclamationService
            .getExamenEtudiant(sessionR.idSession, etudiant.idEtudiant)
            .then((data) => {
                console.log(data);
                setExamens(data);
            });
    };
    const mesReclamation = (sessionR) => {
        console.log(sessionR);
        setDialogMesReclamation(true);
        reclamationService
            .getListeReclamationSessionEtudiant(
                etudiant.idEtudiant,
                sessionR.idSessionReclamation
            )
            .then((data) => {
                console.log(data);
                setMesReclamations(data);
            });
    };
    const deleteReclamationEtudiant = (sessionR) => {};
    const hideReceptionDossierDialog = () => {
        setDialogReceptionReclammation(false);
    };
    const hideDepotReclamationDialog = () => {
        setDialogeDepotReclamation(false);
    };
    const hideMesReclamationDialog = () => {
        setDialogMesReclamation(false);
    };
    const hideDeleteSessionDialog = () => {
        setDeleteSessionDialog(false);
    };
    const header = (
        <div className="table-header p-col-2">
            <span className="p-input-icon-left">
                <InputText
                    type="search"
                    onInput={""}
                    placeholder="Recherche globale..."
                />
            </span>
        </div>
    );
    const sessionReclamationDialogFooter = (
        <React.Fragment>
            <Button
                label="Quitter"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideDialog}
            />
            <Button
                label="Enregistrer"
                icon="pi pi-times"
                className="p-button-text"
                onClick={saveSessionReclamation}
            />
        </React.Fragment>
    );
    const depotReclamationDialogFooter = (
        <React.Fragment>
            <Button
                label="Quitter"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideDepotReclamationDialog}
            />
            <Button
                label="Enregistrer"
                icon="pi pi-times"
                className="p-button-text"
                onClick={saveReclamation}
            />
        </React.Fragment>
    );
    const mesReclamationDialogFooter = (
        <React.Fragment>
            <Button
                label="Quitter"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideMesReclamationDialog}
            />
        </React.Fragment>
    );
    const actionBodyTemplate = (rowData) => {
        return isScolarite ? (
            <React.Fragment>
                <Button
                    icon="pi pi-pencil"
                    className="p-button-info p-mr-2"
                    onClick={() => listeEtudiantReclamation(rowData)}
                />
                <Button
                    icon="pi pi-pencil"
                    className="p-button-warning p-mr-2"
                    onClick={() => editSessionReclamation(rowData)}
                />
                <Button
                    icon="pi pi-trash"
                    className="p-button-danger"
                    onClick={() => confirmDeleteSessionReclamation(rowData)}
                    style={{ marginLeft: 5 }}
                />
            </React.Fragment>
        ) : isEtudiant ? (
            <React.Fragment>
                <Button
                    icon="pi pi-plus"
                    className="p-button-success p-mr-2"
                    onClick={() => deposerReclamation(rowData)}
                />
                <Button
                    icon="pi pi-list"
                    className="p-button-warning p-mr-2"
                    onClick={() => mesReclamation(rowData)}
                    style={{ marginLeft: 5 }}
                />
            </React.Fragment>
        ) : (
            ""
        );
    };
    const actionBodyTemplateRe = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-trash"
                    className="p-button-danger p-mr-2"
                    onClick={() => deleteReclamationEtudiant(rowData)}
                />
            </React.Fragment>
        );
    };

    const receptionDossierDialogFooter = (
        <React.Fragment>
            <Button
                label="Quitter"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideReceptionDossierDialog}
            />
        </React.Fragment>
    );

    const deleteSessionDialogFooter = (
        <React.Fragment>
            <Button
                label="Non"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideDeleteSessionDialog}
            />
            <Button
                label="Oui"
                icon="pi pi-check"
                className="p-button-text"
                onClick={deleteSessionReclamation}
            />
        </React.Fragment>
    );

    return (
        <div className="p-grid">
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <ToastContainer />
            <div className="p-col-12">
                {isScolarite ? (
                    <div className="p-grid" style={{ marginLeft: 20 }}>
                        <div className="p-col-2">
                            <Button
                                label="Ajouter"
                                icon="pi pi-plus"
                                className="p-button-success p-mr-2"
                                onClick={openNewSessionReclamation}
                            />
                        </div>
                    </div>
                ) : (
                    ""
                )}

                <div className="card card-w-title datatable-demo">
                    {header}
                    {isScolarite ? (
                        <>
                            <div className="p-grid p-lg-12">
                                <div className="p-field">
                                    <Dropdown
                                        optionLabel="libelleAnnee"
                                        value={annee}
                                        options={annees}
                                        onChange={(e) => {
                                            onChangeAnnee(e);
                                        }}
                                        required
                                        className={classNames({
                                            "p-invalid": "this.state.submitted",
                                        })}
                                        placeholder="Selectionner une année"
                                    />
                                    {}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="lbDepartementEtab"
                                        value={departement}
                                        options={departements}
                                        onChange={(e) => {
                                            onChangeDepartement(e);
                                        }}
                                        required
                                        autoFocus
                                        className={classNames({
                                            "p-invalid": "",
                                        })}
                                        placeholder="Selectionner un département"
                                    />
                                    {}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleParcours"
                                        value={parcour}
                                        options={parcours}
                                        onChange={(e) => {
                                            onChangeParcours(e);
                                        }}
                                        required
                                        className={classNames({
                                            "p-invalid": "this.state.submitted",
                                        })}
                                        placeholder="Selectionner un parcours"
                                    />
                                    {}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleSemestre"
                                        value={semestre}
                                        options={semestres}
                                        onChange={(e) => {
                                            onChangeSemestre(e);
                                        }}
                                        required
                                        className={classNames({
                                            "p-invalid": "this.state.submitted",
                                        })}
                                        placeholder="Selectionner un semestre"
                                    />
                                    {}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleTypeSession"
                                        value={typeSession}
                                        options={typeSessions}
                                        onChange={(e) => {
                                            onChangeTypeSession(e);
                                        }}
                                        required
                                        className={classNames({
                                            "p-invalid": "this.state.submitted",
                                        })}
                                        placeholder="Selectionner une session"
                                    />
                                    {}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Button
                                        style={{ marginLeft: "0%" }}
                                        label="Afficher"
                                        onClick={listSessionReclamation}
                                    />
                                </div>
                            </div>
                            <DataTable
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                                rows={5}
                                selectionMode="single"
                                filterMatchMode="contains"
                                paginator
                                responsive
                                value={sessionReclamations}
                                header="Liste des Sessions de reclamation"
                            >
                                <Column
                                    field="libelleSession"
                                    header="Session de reclamation"
                                    headerStyle={{
                                        textTransform: "capitalize",
                                    }}
                                    filterPlaceholder="Search ..."
                                    sortable
                                    filter
                                />
                                <Column
                                    field="dateOuverture"
                                    header="Date de debut"
                                    headerStyle={{
                                        textTransform: "capitalize",
                                    }}
                                    filterPlaceholder="Search ..."
                                    sortable
                                    filter
                                />
                                <Column
                                    field="dateFermeture"
                                    header="Date de fin"
                                    headerStyle={{
                                        textTransform: "capitalize",
                                    }}
                                    filterPlaceholder="Search ..."
                                    sortable
                                    filter
                                />
                                <Column
                                    header={actionHeader}
                                    body={actionBodyTemplate}
                                    style={{
                                        textAlign: "center",
                                        width: "8em",
                                    }}
                                />
                            </DataTable>
                        </>
                    ) : (
                        <>
                            <div className="p-grid p-lg-12">
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleSemestre"
                                        value={semestre}
                                        options={semestres}
                                        onChange={(e) => {
                                            onChangeSemestre(e);
                                        }}
                                        required
                                        className={classNames({
                                            "p-invalid": "this.state.submitted",
                                        })}
                                        placeholder="Selectionner un semestre"
                                    />
                                    {}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Dropdown
                                        optionLabel="libelleTypeSession"
                                        value={typeSession}
                                        options={typeSessions}
                                        onChange={(e) => {
                                            onChangeTypeSession(e);
                                        }}
                                        required
                                        className={classNames({
                                            "p-invalid": "this.state.submitted",
                                        })}
                                        placeholder="Selectionner une session"
                                    />
                                    {}
                                </div>
                                <div
                                    className="p-field"
                                    style={{ marginLeft: 10 }}
                                >
                                    <Button
                                        style={{ marginLeft: "0%" }}
                                        label="Afficher"
                                        onClick={listSessionReclamation}
                                    />
                                </div>
                            </div>
                            <DataTable
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                                rows={5}
                                selectionMode="single"
                                filterMatchMode="contains"
                                paginator
                                responsive
                                value={sessionReclamations}
                                header="Liste des Sessions de reclamation"
                            >
                                <Column
                                    field="libelleSession"
                                    header="Session de reclamation"
                                    headerStyle={{
                                        textTransform: "capitalize",
                                    }}
                                    filterPlaceholder="Search ..."
                                    sortable
                                    filter
                                />
                                <Column
                                    field="dateOuverture"
                                    header="Date de debut"
                                    headerStyle={{
                                        textTransform: "capitalize",
                                    }}
                                    filterPlaceholder="Search ..."
                                    sortable
                                    filter
                                />
                                <Column
                                    field="dateFermeture"
                                    header="Date de fin"
                                    headerStyle={{
                                        textTransform: "capitalize",
                                    }}
                                    filterPlaceholder="Search ..."
                                    sortable
                                    filter
                                />
                                <Column
                                    header={actionHeader}
                                    body={actionBodyTemplate}
                                    style={{
                                        textAlign: "center",
                                        width: "8em",
                                    }}
                                />
                            </DataTable>
                        </>
                    )}
                </div>
                <Dialog
                    visible={openDialog}
                    style={{ width: "45%" }}
                    header="Informations session de réclamation"
                    modal
                    className="p-fluid"
                    footer={sessionReclamationDialogFooter}
                    onHide={hideDialog}
                >
                    <div className="p-grid">
                        <div className="p-col-12 p-md-12 p-lg-12">
                            <div className="p-field">
                                <label htmlFor="annee">Année académique</label>
                                <Dropdown
                                    optionLabel="libelleAnnee"
                                    value={sessionReclamation.annee}
                                    options={annees}
                                    onChange={(e) => {
                                        onChangeReclamation(e, "annee");
                                    }}
                                    required
                                    autoFocus
                                    className={classNames({
                                        "p-invalid": annee,
                                    })}
                                    placeholder="Selectionner l'année"
                                />
                                {}
                            </div>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-6 p-md-6 p-lg-6">
                            <div className="p-field">
                                <label htmlFor="departement">Département</label>
                                <Dropdown
                                    optionLabel="lbDepartementEtab"
                                    value={sessionReclamation.departement}
                                    options={departements}
                                    onChange={(e) => {
                                        onChangeReclamation(e, "departement");
                                    }}
                                    required
                                    className={classNames({
                                        "p-invalid": departement,
                                    })}
                                    placeholder="Selectionner le département"
                                />
                                {}
                            </div>
                        </div>
                        <div className="p-col-6 p-md-6 p-lg-6">
                            <div className="p-field">
                                <label htmlFor="parcours">Parcours</label>
                                <Dropdown
                                    optionLabel="libelleParcours"
                                    value={sessionReclamation.parcour}
                                    options={parcours}
                                    onChange={(e) => {
                                        onChangeReclamation(e, "parcour");
                                    }}
                                    required
                                    className={classNames({
                                        "p-invalid": parcour,
                                    })}
                                    placeholder="Selectionner le parcours"
                                />
                                {}
                            </div>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-6 p-md-6 p-lg-6">
                            <div className="p-field">
                                <label htmlFor="niveau">Niveau</label>
                                <Dropdown
                                    optionLabel="libelleNiveau"
                                    value={sessionReclamation.niveau}
                                    options={niveaus}
                                    onChange={(e) => {
                                        onChangeReclamation(e, "niveau");
                                    }}
                                    required
                                    className={classNames({
                                        "p-invalid": niveau,
                                    })}
                                    placeholder="Selectionner le niveau"
                                />
                                {}
                            </div>
                        </div>
                        <div className="p-col-6 p-md-6 p-lg-6">
                            <div className="p-field">
                                <label htmlFor="annee">Semestre</label>
                                <Dropdown
                                    optionLabel="libelleSemestre"
                                    value={sessionReclamation.semestre}
                                    options={semestres}
                                    onChange={(e) => {
                                        onChangeReclamation(e, "semestre");
                                    }}
                                    required
                                    className={classNames({
                                        "p-invalid": semestre,
                                    })}
                                    placeholder="Selectionner le semestre"
                                />
                                {}
                            </div>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-12 p-lg-12">
                            <div className="p-field">
                                <label htmlFor="session">Session</label>
                                <Dropdown
                                    optionLabel="typeSession.libelleTypeSession"
                                    value={sessionReclamation.session}
                                    options={sessions}
                                    onChange={(e) => {
                                        onChangeReclamation(e, "session");
                                    }}
                                    required
                                    className={classNames({
                                        "p-invalid": session,
                                    })}
                                    placeholder="Selectionner la session"
                                />
                                {}
                            </div>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-12 p-lg-12">
                            <label htmlFor="sessionReclamation">
                                Libelle session de réclamation
                            </label>
                            <input
                                type="text"
                                readOnly
                                value={sessionReclamation.libelleSession}
                                onChange={(e) => {
                                    onChangeReclamation(e, "libelleSession");
                                }}
                                placeholder="Session de réclamation"
                                className="p-inputtext p-component"
                            />
                            {}
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-6 p-md-6 p-lg-6">
                            <label htmlFor="dateOuvertureSession">
                                Date debut
                            </label>
                            <input
                                type="date"
                                value={sessionReclamation.dateOuverture}
                                onChange={(e) => {
                                    onChangeReclamation(e, "dateOuverture");
                                }}
                                readOnlyInput
                                placeholder="Entrer la date d'ouverture"
                                className="p-inputtext p-component"
                            />
                            {}
                        </div>

                        <div className="p-col-6 p-md-6 p-lg-6">
                            <label htmlFor="dateFermetureSession">
                                Date fermeture{" "}
                            </label>
                            <input
                                type="date"
                                value={sessionReclamation.dateFermeture}
                                onChange={(e) => {
                                    onChangeReclamation(e, "dateFermeture");
                                }}
                                readOnlyInput
                                placeholder="Entrer la date fermetutre"
                                className="p-inputtext p-component"
                            />
                            {}
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    visible={deleteSessionDialog}
                    style={{ width: "450px" }}
                    header="Confirmez"
                    modal
                    footer={deleteSessionDialogFooter}
                    onHide={hideDeleteSessionDialog}
                >
                    <div className="confirmation-content">
                        <i
                            className="pi pi-exclamation-triangle p-mr-3"
                            style={{ fontSize: "2rem" }}
                        />
                        {sessionReclamation && (
                            <span>
                                Etes-vous sûr de supprimer cet élément ?
                            </span>
                        )}
                    </div>
                </Dialog>
                <Dialog
                    visible={dialogReceptionReclammation}
                    style={{ width: "80%" }}
                    header="Reception dossiers"
                    modal
                    footer={receptionDossierDialogFooter}
                    onHide={hideReceptionDossierDialog}
                >
                    <div className="p-grid">
                        <DataTable
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                            rows={5}
                            filterMatchMode="contains"
                            paginator
                            responsive
                            value={listeReclamationSession}
                            header="Liste des réclamations"
                            selection={reclamation}
                            onSelectionChange={(e) => {
                                onChangeListeReclamation(e.value);
                            }}
                        >
                            <Column
                                field="examen.libelleExamen"
                                header="Examen"
                                headerStyle={{ textTransform: "capitalize" }}
                                filterPlaceholder="Search ..."
                                sortable
                                filter
                            />
                            <Column
                                field="etatDemande"
                                header="Etat démande"
                                headerStyle={{ textTransform: "capitalize" }}
                                filterPlaceholder="Search ..."
                                sortable
                                filter
                            />
                            <Column
                                field="description"
                                header="Motif étudiant"
                                headerStyle={{ textTransform: "capitalize" }}
                                filterPlaceholder="Search ..."
                                sortable
                                filter
                            />
                            <Column
                                field="examen.notationDto.note"
                                header="Note avant réclamation"
                                headerStyle={{ textTransform: "capitalize" }}
                                filterPlaceholder="Search ..."
                                sortable
                                filter
                            />
                            <Column
                                field=""
                                header="Note après reclamation"
                                headerStyle={{ textTransform: "capitalize" }}
                                filterPlaceholder="Search ..."
                                sortable
                                filter
                            />
                            <Column
                                field=""
                                header="Observation enseignant"
                                headerStyle={{ textTransform: "capitalize" }}
                                filterPlaceholder="Search ..."
                                sortable
                                filter
                            />
                            <Column
                                header="Reception démande étudiant"
                                selectionMode="single"
                                style={{ textAlign: "center", width: "8em" }}
                            />
                            <Column
                                header="Transmettre à l'enseignant"
                                selectionMode="multiple"
                                style={{ textAlign: "center", width: "8em" }}
                            />
                            <Column
                                header="Reception par l' enseignant"
                                selectionMode="multiple"
                                style={{ textAlign: "center", width: "8em" }}
                            />
                            <Column
                                header="Reception retour enseignant"
                                selectionMode="multiple"
                                style={{ textAlign: "center", width: "8em" }}
                            />
                        </DataTable>
                    </div>
                </Dialog>
                <Dialog
                    visible={dialogeDepotReclamation}
                    style={{ width: "100%" }}
                    header="Informations session de réclamation"
                    modal
                    className="p-fluid"
                    footer={depotReclamationDialogFooter}
                    onHide={hideDepotReclamationDialog}
                >
                    <div className="p-grid">
                        <div className="p-col-8 p-md-8 p-lg-8">
                            <div className="p-grid">
                                <DataTable
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                                    rows={5}
                                    filterMatchMode="contains"
                                    paginator
                                    responsive
                                    value={examens}
                                    header="Liste des examens composée par l'etudiant"
                                    selection={reclamation.examen}
                                    onSelectionChange={(e) => {
                                        onChangeDepotReclamation(e, "examen");
                                    }}
                                >
                                    <Column
                                        field="libelleExamen"
                                        header="Examen"
                                        headerStyle={{
                                            textTransform: "capitalize",
                                        }}
                                        filterPlaceholder="Search ..."
                                        sortable
                                        filter
                                    />
                                    <Column
                                        field="dateExamen"
                                        header="Date composition"
                                        headerStyle={{
                                            textTransform: "capitalize",
                                        }}
                                        filterPlaceholder="Search ..."
                                        sortable
                                        filter
                                    />
                                    <Column
                                        field="notationDto.note"
                                        header="Note actuelle"
                                        headerStyle={{
                                            textTransform: "capitalize",
                                        }}
                                        filterPlaceholder="Search ..."
                                        sortable
                                        filter
                                    />
                                    <Column
                                        field="paramNote.valeurParam"
                                        header="Taux examen en %"
                                        headerStyle={{
                                            textTransform: "capitalize",
                                        }}
                                        filterPlaceholder="Search ..."
                                        sortable
                                        filter
                                    />
                                    <Column
                                        header={actionHeader}
                                        selectionMode="single"
                                        style={{
                                            textAlign: "center",
                                            width: "8em",
                                        }}
                                    />
                                </DataTable>
                            </div>
                            <div className="p-grid" style={{ marginTop: 10 }}>
                                <label htmlFor="description">
                                    Motif réclamation (500 mots au maximum)
                                </label>
                                <InputTextarea
                                    id="description"
                                    value={reclamation.description}
                                    onChange={(e) =>
                                        onChangeDepotReclamation(
                                            e,
                                            "description"
                                        )
                                    }
                                    required
                                    autoFocus
                                    className={classNames({ "p-invalid": "" })}
                                />
                                {/*submitted && !dossierCompetition.descriptionResultat && <small className="p-error">Ce champs est obligatoire.</small>*/}
                            </div>
                            <div className="p-grid" style={{ marginTop: 10 }}>
                                <div className="p-col-4 p-lg-4 p-md-4">
                                    <Button
                                        label="Ajouter"
                                        icon="pi pi-times"
                                        className="p-button-text"
                                        onClick={AjoutReclamation}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className="p-col-4 p-md-4 p-lg-4"
                            style={{ marginTop: -20 }}
                        >
                            <div className="p-grid">
                                <DataTable
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                                    rows={5}
                                    filterMatchMode="contains"
                                    paginator
                                    responsive
                                    value={demandeReclamations}
                                    header="Liste des réclamations à enregister"
                                >
                                    <Column
                                        field="examen.libelleExamen"
                                        header="Examen"
                                        headerStyle={{
                                            textTransform: "capitalize",
                                        }}
                                        filterPlaceholder="Search ..."
                                        sortable
                                        filter
                                    />
                                    <Column
                                        field="description"
                                        header="Motif"
                                        headerStyle={{
                                            textTransform: "capitalize",
                                        }}
                                        filterPlaceholder="Search ..."
                                        sortable
                                        filter
                                    />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    visible={dialogMesReclamation}
                    style={{ width: "75%" }}
                    header="Liste de mes réclamations"
                    modal
                    className="p-fluid"
                    footer={mesReclamationDialogFooter}
                    onHide={hideMesReclamationDialog}
                >
                    <div className="p-grid">
                        <div className="p-col-12 p-md-12 p-lg-12">
                            <div className="p-grid">
                                <DataTable
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                                    rows={5}
                                    filterMatchMode="contains"
                                    paginator
                                    responsive
                                    value={mesReclamations}
                                    header="Liste des réclamations de l'etudiant"
                                >
                                    <Column
                                        field="examen.libelleExamen"
                                        header="Examen"
                                        headerStyle={{
                                            textTransform: "capitalize",
                                        }}
                                        filterPlaceholder="Search ..."
                                        sortable
                                        filter
                                    />
                                    <Column
                                        field="etatDemande"
                                        header="Etat démande"
                                        headerStyle={{
                                            textTransform: "capitalize",
                                        }}
                                        filterPlaceholder="Search ..."
                                        sortable
                                        filter
                                    />
                                    <Column
                                        field="description"
                                        header="Motif étudiant"
                                        headerStyle={{
                                            textTransform: "capitalize",
                                        }}
                                        filterPlaceholder="Search ..."
                                        sortable
                                        filter
                                    />
                                    <Column
                                        field="examen.notationDto.note"
                                        header="Note avant réclamation"
                                        headerStyle={{
                                            textTransform: "capitalize",
                                        }}
                                        filterPlaceholder="Search ..."
                                        sortable
                                        filter
                                    />
                                    <Column
                                        field=""
                                        header="Note après reclamation"
                                        headerStyle={{
                                            textTransform: "capitalize",
                                        }}
                                        filterPlaceholder="Search ..."
                                        sortable
                                        filter
                                    />
                                    <Column
                                        field=""
                                        header="Observation enseignant"
                                        headerStyle={{
                                            textTransform: "capitalize",
                                        }}
                                        filterPlaceholder="Search ..."
                                        sortable
                                        filter
                                    />
                                    <Column
                                        header={actionHeader}
                                        body={actionBodyTemplateRe}
                                        style={{
                                            textAlign: "center",
                                            width: "8em",
                                        }}
                                    />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    );
}

export default Reclamation;
