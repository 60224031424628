import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { InvitationService } from '../../service/InvitationService';


export default function BordereauEntrantDepartement(){

    const tokenDecoded = localStorage.getItem('tokenDecoded');
    const token = JSON.parse(tokenDecoded);

    const [selectedEntites, setSelectedEntites] = useState(null)

    const [listeBordereau, setListeBordereau] = useState([])
    const [informationBordereau, setInformationBordereau] = useState({})

    const [username, setUsername] = useState(token.preferred_username)

    const [invalidationInvitation, setInvalidationInvitation] = useState(false)
    const [validationInvitation, setValidationInvitation] = useState(false)

    const [detailsInvitationEnseignantDialog, setDetailsInvitationEnseignantDialog] = useState(false)

    const [informationInvitation, setInformationInvitation] = useState({})
    const [messageRejet, setMessageRejet] = useState("")

    const [listeInformationBordereau, setListeInformationBordereau] = useState([])

    const [listeInvitationEnseignant, setListeInvitationEnseignant] = useState([])
    
    const [submitted, setSubmitted] = useState(false)
    const [globalFilter, setGlobalFilter] = useState(null)
    const [loading, setLoading] = useState(false)

    const [spin,setSpin] = useState(false)
    const [detailsInvitationDialog, setDetailsInvitationDialog] = useState(false)
    const [confirmerEnvoiInvitationDialog, setConfirmerEnvoiInvitationDialog] = useState(false)

    const [detailsReponseBordereauDialog, setDetailsReponseBordereauDialog] = useState(false)
    const [detailsReponseInvitationDialog, setDetailsReponseInvitationDialog] = useState(false)

    const notify = new Notify()
    const spinner = new Spinner()

    const invitationService = new InvitationService()

    useEffect(() => {

            setLoading(true)
            invitationService.getListeBordero(username, "Entrant").then(data => {

                setListeBordereau(data)
                setLoading(false)
            }).catch(error => {
                console.log(error)
                notify.echec(error.response.data.message)
                setLoading(false)
            })

        }, []
    );

    const detailsInvitation = (data) => {

        setInformationBordereau(data)
        setListeInformationBordereau([data])

        if(data.idBeParent){
            setListeInvitationEnseignant(data.beParent.listeInvitationEnseignant)
            setDetailsReponseBordereauDialog(true)
        }else{
            setListeInvitationEnseignant(data.listeInvitationEnseignant)
            setDetailsInvitationDialog(true)
        }

    }

    const validerInvitationEnseignant = () => {
        setConfirmerEnvoiInvitationDialog(true)
    }

    const hideConfirmerInvitationDialog = () => {
        setConfirmerEnvoiInvitationDialog(false)
        setSpin(false)
    }


    const detailsInvitationEnseignant = (invitationData, typeOperation) => {
        
        if(typeOperation==="Rejeter"){
            setInvalidationInvitation(true)
        }else if(typeOperation==="Receptionner"){
            setValidationInvitation(true)
        }
        setInformationInvitation(invitationData)
        setDetailsInvitationEnseignantDialog(true)
    }

    const editCauseRejet = (e) => {
        setMessageRejet(e.target.value)
    }


    const hideDetailInvitationEnseignant = () => {
        setDetailsInvitationEnseignantDialog(false)
        setInvalidationInvitation(false)
        setValidationInvitation(false)
        setSpin(false)
        setMessageRejet("")
        setInvalidationInvitation(false)
        setValidationInvitation(false)
    }


    const editionEtatInvitation = (etatInvitation) => {
       
        setSubmitted(true)
        let invitation = informationInvitation
        
        invitation['motifRejet'] = messageRejet
        invitation['etatActuel'] = etatInvitation

        if(etatInvitation!=="Rejeter" || (etatInvitation==="Rejeter" && messageRejet!=="")){

            invitationService.rejetInvitation(username, invitation).then(data => {
                
                setSpin(false)
                notify.success()
                setDetailsInvitationEnseignantDialog(false)
                setInvalidationInvitation(false)
                setValidationInvitation(false)
                setSpin(false)
                setMessageRejet("")
                setInvalidationInvitation(false)
                setValidationInvitation(false)
            }).catch(error => {
                console.log(error)
                notify.echec(error.response.data.message)
                setSpin(false)
            })
        }

    }


    const hideDetailInvitation = () => {
        setDetailsInvitationDialog(false)
        setDetailsReponseBordereauDialog(false)
        setSpin(false)
        setMessageRejet("")
        setInvalidationInvitation(false)
        setValidationInvitation(false)
    }


    const confirmerValidationInvitation = () => {
        let invitationObject = informationBordereau
        setSpin(true)
        invitationService.transmissionBe(username, invitationObject).then(data => {
            setSpin(false)

            //On recharge la liste des bordereau pour en recuperer les derniers enregistrés
            setLoading(true)
            hideDetailInvitation()
            
            invitationService.getListeBordero(username, "Entrant").then(data => {
                setListeBordereau(data)
                setLoading(false)
            }).catch(error => {
                console.log(error)
                notify.echec(error.response.data.message)
                setLoading(false)
            })

            //On reinitialise les parametres et on quitte la fenetre avec message de succes

            hideConfirmerInvitationDialog(false)
            setDetailsInvitationDialog(false)
            setDetailsReponseBordereauDialog(false)
            notify.success()

        }).catch(error => {
            console.log(error)
            notify.echec(error.response.data.message)
            setSpin(false)
        })
    
        
    }


    const hideDetailsReponseInvitationDialog = () => {
        setDetailsReponseInvitationDialog(false)
    }


    const detailsReponseInvitation = (data) => {
        setInformationInvitation(data)
        setDetailsReponseInvitationDialog(true)
    }


    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-info" className="p-button-warning p-mr-2" title="Détails" style={{marginRight: '.5em'}} onClick={() => detailsInvitation(rowData)} />
            </React.Fragment>
        );
    }

    const invitationActionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-eye" className="p-button-warning p-mr-2" title="Détails" style={{marginRight: '.5em'}} onClick={() => detailsInvitationEnseignant(rowData, "Visualiser")} />

                {rowData.etatActuel==="Rejeter"? (
                    <Button icon="pi pi-check-circle" className="p-button-success p-mr-2" title="Valider" style={{marginRight: '.5em'}} onClick={() => detailsInvitationEnseignant(rowData, "Receptionner")} />
                ):(
                    <Button icon="pi pi-ban" className="p-button-danger p-mr-2" title="Rejeter" style={{marginRight: '.5em'}} onClick={() => detailsInvitationEnseignant(rowData, "Rejeter")} />
                )}
            </React.Fragment>
        );
    }

    const detailsInvitationFooter = (
        <React.Fragment>
            <Button label="Transmettre au sécretariat" icon="pi pi-check-circle" className="p-button-text" onClick={() => validerInvitationEnseignant()} />
            <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDetailInvitation} />
        </React.Fragment>
    )

    const detailsInvitationEnseignantFooter = (
        <React.Fragment>
            {invalidationInvitation && <Button label="Confirmer le rejet" icon="pi pi-ban" className="p-button-text danger" onClick={() => editionEtatInvitation("Rejeter")} />}
            {validationInvitation && <Button label="Valider invitation" icon="pi pi-check-circle" className="p-button-text danger" onClick={() => editionEtatInvitation("Receptionner")} />}
            <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDetailInvitationEnseignant} />
        </React.Fragment>
    )


    const confirmerEnvoiInvitationFooter = (
        <React.Fragment>
            <Button label="Non" disabled={spin?true:false}  icon={spin?"pi pi-spin pi-spinner":"pi pi-ban"} className="p-button-text" onClick={hideConfirmerInvitationDialog} />
            <Button label="Oui" disabled={spin?true:false} icon="pi pi-check" className="p-button-text" onClick={confirmerValidationInvitation} />
        </React.Fragment>
    );

    const detailsReponseInvitationFooter = (
        <React.Fragment>
            <Button label="Valider & envoyer" icon="pi pi-check-circle" className="p-button-text" onClick={() => validerInvitationEnseignant()} />
            <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDetailInvitation} />
        </React.Fragment>
    )


    const reponseInvitationActionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-eye" className="p-button-warning p-mr-2" title="Editer" style={{marginRight: '.5em'}} onClick={() => detailsReponseInvitation(rowData)} />
            </React.Fragment>
        );
    }


    const detailsInformationReponseInvitationFooter = (
        <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDetailsReponseInvitationDialog} />
    )
    
    

    const actionHeader = <Button type="button" icon="pi pi-cog"/>;

    return (
        <div className="datatable-crud-demo">
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="card">
                {loading ?
                <div className="p-grid">
                    <div className="p-col-6" ></div>
                    <div className="p-col-6" >
                        {spinner.loadingSpinner("spin", "black")}
                    </div>
                </div>
                :
                <DataTable value={listeBordereau} selection={selectedEntites} onSelectionChange={(e) => setSelectedEntites(e.value)}
                    dataKey="id" paginator rows={10} 
                    globalFilter={globalFilter}
                    header="Liste des bordereaux invitation enseignant entrant" selectionMode='one'>
                    <Column field="numeroBe" header="Numero bordereau" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="dateBe" header="Date bordereau" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="nomIesr" header="Université d'origine" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="etatBe" header="Etat du bordereau" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="posBe" header="Position du bordereau" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column header={actionHeader} body={actionBodyTemplate} style={{textAlign:'center', width: '160px'}}></Column>
                </DataTable>
                }
            </div>



            <Dialog visible={detailsInvitationDialog} style={{width: '80%'}} header="Détails du bordereau d'invitation" modal footer={detailsInvitationFooter} onHide={hideDetailInvitation}>
                <hr/>

                <React.Fragment>
                    <div className='p-grid'>

                        <div className='p-col-12'>
                            <div className='card'>
                                <div className='card-header'>
                                    Détails sur le bordereau
                                </div>
                                <div className='card-body'  style={{ flex: '1 1 auto',paddingTop: '1.25rem', paddingRight: '0rem', paddingLeft: '0rem', paddingBottom: '0rem'}}>
                                    <DataTable value={listeInformationBordereau}>
                                        <Column field="numeroBe" header="Numero bordereau" filterMatchMode="contains" sortable={false} filter={false}></Column>
                                        <Column field="dateBe" header="Date bordereau" filterMatchMode="contains" sortable={false} filter={false}></Column>
                                        <Column field="nomIesr" header="Université d'origine" filterMatchMode="contains" sortable={false} filter={false}></Column>
                                        <Column field="etatBe" header="Etat du bordereau" filterMatchMode="contains" sortable={false} filter={false}></Column>
                                        <Column field="posBe" header="Position du bordereau" filterMatchMode="contains" sortable={false} filter={false}></Column>
                                    </DataTable>
                                </div>
                            </div>
                        </div>


                        <div className='p-col-12'>    
                            <div className='card'>
                                <div className='card-header'>
                                    Liste des invitations enseignant concernée
                                </div>
                                <div className='card-body'  style={{ flex: '1 1 auto',paddingTop: '1.25rem', paddingRight: '0rem', paddingLeft: '0rem', paddingBottom: '0rem'}}>
                                    <DataTable value={listeInvitationEnseignant} selection={selectedEntites} onSelectionChange={(e) => setSelectedEntites(e.value)} paginator rows={10} globalFilter={globalFilter} selectionMode='none'>
                                        <Column field="parcours.libelleParcours" header="Parcours d'invitation" filterMatchMode="contains" sortable={false} filter={true}></Column>
                                        <Column field="semestre.libelleSemestre" header="Semestre concerné" filterMatchMode="contains" sortable={false} filter={true}></Column>
                                        <Column field="elementConstitutif.libelleElementConstitutif" header="Element constitutif" filterMatchMode="contains" sortable={false} filter={true}></Column>
                                        <Column field="enseignant.nomPrenomEnseignant" header="Enseignant invité" filterMatchMode="contains" sortable={false} filter={true}></Column>
                                        <Column field="dateDebutCours" header="Date de début de la mission" filterMatchMode="contains" sortable={false} filter={true}></Column>
                                        <Column field="dateFinCours" header="Date de fin de la mission" filterMatchMode="contains" sortable={false} filter={true}></Column>
                                        <Column field="etatActuel" header="Etat invitation" filterMatchMode="contains" sortable={false} filter={true}></Column>
                                        <Column header={actionHeader} body={invitationActionBodyTemplate} style={{textAlign:'center', width: '160px'}}></Column>
                                    </DataTable>
                                </div>
                            </div>
                        </div>


                        {informationBordereau.etatActuel==="Rejeter" &&
                            <div className='p-col-12'>
                                <div className="p-grid">
                                    <div className="p-col-12" >
                                        <div className="p-field">
                                            <label htmlFor="causeRejet">Motif de rejet de l'invitation</label>
                                            <hr/>
                                            {informationBordereau.justificatif}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </React.Fragment>
                <hr/>
            </Dialog>



            <Dialog visible={detailsInvitationEnseignantDialog} style={{width: '80%'}} header= {invalidationInvitation? "Interface de rejet de l'invitation" : "Détails de l'invitation"} modal footer={detailsInvitationEnseignantFooter} onHide={hideDetailInvitationEnseignant}>
                <React.Fragment>
                
                    <hr/>
                    {informationInvitation.enseignant && (
                        <React.Fragment>
                            <div className='p-grid'>
                        <div className='p-col-6'>

                            <div className='card'>
                                <div className='card-header'>
                                    Détails sur l'enseignant concermé
                                </div>
                                <div className='card-body'  style={{ flex: '1 1 auto',paddingTop: '1.25rem', paddingRight: '0rem', paddingLeft: '0rem', paddingBottom: '0rem'}}>

                                    <table className='table table-hover table-bordered table-striped'>
                                        <tbody>
                                            <tr>
                                                <td>Nom et prenom de l'enseignant</td>
                                                <td>{informationInvitation.enseignant.nomPrenomEnseignant}</td>
                                            </tr>
                                            <tr>
                                                <td>Grade enseignant</td>
                                                <td>{informationInvitation.enseignant.grade.libelleGrade}</td>
                                            </tr>
                                            <tr>
                                                <td>Université d'origine</td>
                                                <td>{informationInvitation.enseignant.nomIes}</td>
                                            </tr>
                                            <tr>
                                                <td>UFR</td>
                                                <td>{informationInvitation.enseignant.nomEtablissement}</td>
                                            </tr>
                                            <tr>
                                                <td>Département</td>
                                                <td>{informationInvitation.enseignant.lbDepartementEtab}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>

                        <div className='p-col-6'>

                            <div className='card'>
                                <div className='card-header'>
                                    Détails sur le cours d'invitation concerné
                                </div>
                                <div className='card-body'  style={{ flex: '1 1 auto',paddingTop: '1.25rem', paddingRight: '0rem', paddingLeft: '0rem', paddingBottom: '0rem'}}>
                                    <table className='table table-hover table-bordered table-striped'>
                                        <tbody>
                                            <tr>
                                                <td>Parcours concerné par le cours</td>
                                                <td>{informationInvitation.parcours.libelleParcours}</td>
                                            </tr>
                                            <tr>
                                                <td>Semestre</td>
                                                <td>{informationInvitation.semestre.libelleSemestre}</td>
                                            </tr>
                                            <tr>
                                                <td>Element constitutif</td>
                                                <td>{informationInvitation.elementConstitutif.libelleElementConstitutif}</td>
                                            </tr>
                                            <tr>
                                                <td>Date de debut du cours</td>
                                                <td>{informationInvitation.dateDebutCours}</td>
                                            </tr>
                                            <tr>
                                                <td>Date de fin du cours</td>
                                                <td>{informationInvitation.dateFinCours}</td>
                                            </tr>
                                            <tr>
                                                <td>Evolution de l'invitation</td>
                                                <td>{informationInvitation.posDemande}</td>
                                            </tr>
                                            <tr>
                                                <td>Etat de l'invitation</td>
                                                <td>{informationInvitation.etatActuel}</td>
                                            </tr>
                                            {informationInvitation.etatActuel==="Rejeter" && (
                                                <tr>
                                                    <td>Motif du rejet</td>
                                                    <td>{informationInvitation.motifRejet}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>


                        {invalidationInvitation &&
                            <div className='p-col-12'>
                                <div className="p-grid">
                                    <div className="p-col-12" >
                                        <div className="p-field">
                                            <label htmlFor="causeRejet">Indiquez la raison du rejet de cette demande d'invitation</label>
                                            <hr/>
                                            <textarea value={messageRejet} rows={5} onChange={(e) => {editCauseRejet(e)}} required style={{width: '100%'}} />
                                            {submitted && !messageRejet && <small className="p-invalid" style={{color : "red"}}>Le motif du rejet est obligatoire dans le cas d'un rejet</small>}     
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        
                    </div>
                        </React.Fragment>
                    )
                    }
                    <hr/>
                
                </React.Fragment>
                
            </Dialog>


            <Dialog visible={confirmerEnvoiInvitationDialog} style={{ width: '450px' }} header="Confirmmation d'envoi invitation" modal footer={confirmerEnvoiInvitationFooter} onHide={hideConfirmerInvitationDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-info-circle p-mr-3" style={{ fontSize: '2rem'}} />
                    <center>
                        {informationBordereau && <span>Êtes vous sûr de vouloir confirmer votre accord pour cette invitation ? </span>}
                    </center>
                </div>
            </Dialog>





            <Dialog visible={detailsReponseBordereauDialog} style={{width: '80%'}} header="Détails réponse bordereau d'invitation" modal footer={detailsReponseInvitationFooter} onHide={hideDetailInvitation}>
                <hr/>

                <React.Fragment>

                    <div className='p-grid'>

                        <div className='p-col-12'>
                            <div className='card'>
                                <div className='card-header'>
                                    Détails sur le bordereau
                                </div>
                                <div className='card-body'  style={{ flex: '1 1 auto',paddingTop: '1.25rem', paddingRight: '0rem', paddingLeft: '0rem', paddingBottom: '0rem'}}>
                                    <DataTable value={listeInformationBordereau}>
                                        <Column field="numeroBe" header="Numero bordereau" filterMatchMode="contains" sortable={false} filter={false}></Column>
                                        <Column field="dateBe" header="Date bordereau" filterMatchMode="contains" sortable={false} filter={false}></Column>
                                        <Column field="nomIesr" header="Université d'origine" filterMatchMode="contains" sortable={false} filter={false}></Column>
                                        <Column field="etatBe" header="Etat du bordereau" filterMatchMode="contains" sortable={false} filter={false}></Column>
                                        <Column field="posBe" header="Position du bordereau" filterMatchMode="contains" sortable={false} filter={false}></Column>
                                    </DataTable>
                                </div>
                            </div>
                        </div>


                        <div className='p-col-12'>    
                            <div className='card'>
                                <div className='card-header'>
                                    Liste des invitations enseignant concernée par la réponse
                                </div>
                                <div className='card-body'  style={{ flex: '1 1 auto',paddingTop: '1.25rem', paddingRight: '0rem', paddingLeft: '0rem', paddingBottom: '0rem'}}>
                                <DataTable value={listeInvitationEnseignant} selection={selectedEntites} onSelectionChange={(e) => setSelectedEntites(e.value)} paginator rows={10} globalFilter={globalFilter} selectionMode='none'>
                                    <Column field="parcours.libelleParcours" header="Parcours d'invitation" filterMatchMode="contains" sortable={false} filter={true}></Column>
                                    <Column field="semestre.libelleSemestre" header="Semestre concerné" filterMatchMode="contains" sortable={false} filter={true}></Column>
                                    <Column field="elementConstitutif.libelleElementConstitutif" header="Element constitutif" filterMatchMode="contains" sortable={false} filter={true}></Column>
                                    <Column field="enseignant.nomPrenomEnseignant" header="Enseignant invité" filterMatchMode="contains" sortable={false} filter={true}></Column>
                                    <Column field="dateDebutCours" header="Date de début de la mission" filterMatchMode="contains" sortable={false} filter={true}></Column>
                                    <Column field="dateFinCours" header="Date de fin de la mission" filterMatchMode="contains" sortable={false} filter={true}></Column>
                                    <Column field="etatActuel" header="Etat de l'invitation" filterMatchMode="contains" sortable={false} filter={true}></Column>
                                    <Column header={actionHeader} body={reponseInvitationActionBodyTemplate} style={{textAlign:'center', width: '160px'}}></Column>
                                </DataTable>

                                </div>
                            </div>
                        </div>


                        {informationBordereau.etatActuel==="Rejeter" &&

                            <div className='p-col-12'>
                                <div className="p-grid">
                                    <div className="p-col-12" >
                                        <div className="p-field">
                                            <label htmlFor="causeRejet">Motif de rejet de l'invitation</label>
                                            <hr/>
                                            {informationBordereau.justificatif}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        }

                    </div>
                </React.Fragment>
            
                <hr/>
            </Dialog>

            <Dialog visible={detailsReponseInvitationDialog} style={{width: '80%'}} header= "Détails de l'invitation" modal footer={detailsInformationReponseInvitationFooter} onHide={hideDetailsReponseInvitationDialog}>
                <hr/>
                {informationInvitation.enseignant && (
                    <React.Fragment>
                        <div className='p-grid'>
                    <div className='p-col-6'>

                        <div className='card'>
                            <div className='card-header'>
                                Détails sur l'enseignant concermé
                            </div>
                            <div className='card-body'  style={{ flex: '1 1 auto',paddingTop: '1.25rem', paddingRight: '0rem', paddingLeft: '0rem', paddingBottom: '0rem'}}>

                                <table className='table table-hover table-bordered table-striped'>
                                    <tbody>
                                        <tr>
                                            <td>Nom et prenom de l'enseignant</td>
                                            <td>{informationInvitation.enseignant.nomPrenomEnseignant}</td>
                                        </tr>
                                        <tr>
                                            <td>Grade enseignant</td>
                                            <td>{informationInvitation.enseignant.grade.libelleGrade}</td>
                                        </tr>
                                        <tr>
                                            <td>Université d'origine</td>
                                            <td>{informationInvitation.enseignant.nomIes}</td>
                                        </tr>
                                        <tr>
                                            <td>UFR</td>
                                            <td>{informationInvitation.enseignant.nomEtablissement}</td>
                                        </tr>
                                        <tr>
                                            <td>Département</td>
                                            <td>{informationInvitation.enseignant.lbDepartementEtab}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>

                    <div className='p-col-6'>

                        <div className='card'>
                            <div className='card-header'>
                                Détails sur le cours d'invitation concerné
                            </div>
                            <div className='card-body'  style={{ flex: '1 1 auto',paddingTop: '1.25rem', paddingRight: '0rem', paddingLeft: '0rem', paddingBottom: '0rem'}}>
                                <table className='table table-hover table-bordered table-striped'>
                                    <tbody>
                                        <tr>
                                            <td>Parcours concerné par le cours</td>
                                            <td>{informationInvitation.parcours.libelleParcours}</td>
                                        </tr>
                                        <tr>
                                            <td>Semestre</td>
                                            <td>{informationInvitation.semestre.libelleSemestre}</td>
                                        </tr>
                                        <tr>
                                            <td>Element constitutif</td>
                                            <td>{informationInvitation.elementConstitutif.libelleElementConstitutif}</td>
                                        </tr>
                                        <tr>
                                            <td>Date de debut du cours</td>
                                            <td>{informationInvitation.dateDebutCours}</td>
                                        </tr>
                                        <tr>
                                            <td>Date de fin du cours</td>
                                            <td>{informationInvitation.dateFinCours}</td>
                                        </tr>
                                        <tr>
                                            <td>Evolution de l'invitation</td>
                                            <td>{informationInvitation.posDemande}</td>
                                        </tr>
                                        <tr>
                                            <td>Etat de l'invitation</td>
                                            <td>{informationInvitation.etatActuel}</td>
                                        </tr>
                                        {informationInvitation.etatActuel==="Rejeter" && (
                                            <tr>
                                                <td>Motif du rejet</td>
                                                <td>{informationInvitation.motifRejet}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                    
                </div>
                    </React.Fragment>
                )
                }
                <hr/>
            </Dialog>

        </div>
    )

}