import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class ImportInscruptionService {

    constructor(){
        this.adresse = new AdresseIP()
    }
    
    chargementFichier(donneeFichier){
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/inscription/uploads"
        return axios.post(url, donneeFichier)
                    .then(res => res.data);
    }

}