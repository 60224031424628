import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column'
import {Button} from 'primereact/button';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { Dropdown } from 'primereact/dropdown'
import { SurveillantService } from '../../service/SurveillantService';

export class Surveillants extends Component {

    emptySurveillant = {
        idSurveillant: null,
        matriculeSurveillant: "",
        nomSurveillant: "",
        prenomSurveillant: "",
        telSurveillant : "",
        emailSurveillant : ""
    }


    constructor() {
        super();
        this.state = {
            listeSurveillant : [],
            surveillantDialog: false,
            deleteSurveillantDialog: false,
            surveillant: this.emptySurveillant,
            submitted: false,
            loading : false,
            globalFilter: null,
            idEtablissement: null,
            idDepartement: null
        }
        this.surveillantService = new SurveillantService()
        this.spinner = new Spinner()
        this.notify = new Notify()

        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    }


    componentDidMount() {

        this.setState({loading : true})

        const tokenDecoded = localStorage.getItem('tokenDecoded')
        const token = JSON.parse(tokenDecoded)


        if(token.code_etablissement){
            this.setState({
                idEtablissement: token.code_etablissement
            })


            this.surveillantService.getAllEtablissement(token.code_etablissement).then(data => {

                this.setState({listeSurveillant: data, loading : false})
            }).catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error)
                this.setState({loading : false})
            })


        }else if(token.code_departement){

            this.setState({
                idDepartement: token.code_departement
            })

            this.surveillantService.getAllDepartement(token.code_departement).then(data => {
                this.setState({listeSurveillant: data, loading : false})
            }).catch(error => {
                this.notify.echec(error.response.data.message)
                console.log(error)
                this.setState({loading : false})
            })
        }

    }


    openNew = () => {
        this.setState({
            surveillant: {
                idSurveillant: null,
                matriculeSurveillant: "",
                nomSurveillant: "",
                prenomSurveillant: "",
                telSurveillant : "",
                emailSurveillant : ""
            },
            submitted: false,
            surveillantDialog: true
        })
    }

    hideDialog = () => {
        this.setState({
            submitted: false,
            surveillantDialog: false,
            surveillant: {
                idSurveillant: null,
                matriculeSurveillant: "",
                nomSurveillant: "",
                prenomSurveillant: "",
                telSurveillant : "",
                emailSurveillant : ""
            }
        });
    }

    hideDeleteSurveillantDialo = () => {
        this.setState({ deleteSurveillantDialog: false });
    }

    saveSurveillant = () => {

        let state = { submitted: true };

        if (this.state.surveillant.matriculeSurveillant!=null && this.state.surveillant.nomSurveillant.trim() && this.state.surveillant.prenomSurveillant.trim() && this.state.surveillant.telSurveillant.trim() && this.state.surveillant.emailSurveillant.trim()) {

            const newSurveillant = {
                idSurveillant : parseInt(this.state.surveillant.idSurveillant) ,
                matriculeSurveillant: this.state.surveillant.matriculeSurveillant,
                nomSurveillant : this.state.surveillant.nomSurveillant,
                prenomSurveillant : this.state.surveillant.prenomSurveillant,
                telSurveillant : this.state.surveillant.telSurveillant,
                emailSurveillant : this.state.surveillant.emailSurveillant
            }

            if(this.state.idDepartement){
                newSurveillant['idDepartement'] = this.state.idDepartement
            }else if(this.state.idEtablissement){
                newSurveillant['idEtablissement'] = this.state.idEtablissement
            }

            //console.log(newSurveillant)

            if (this.state.surveillant.idSurveillant) {
                this.surveillantService.update(parseInt(this.state.surveillant.idSurveillant), newSurveillant).then(data => {
                    this.notify.success()
                    this.setState({loading : true})


                    if(this.state.idDepartement){

                        this.surveillantService.getAllDepartement(this.state.idDepartement).then(data => 
                            this.setState({listeSurveillant: data, loading : false})
                        ).catch(error => {
                            this.notify.echec(error.response.data.message)
                            console.log(error)
                            this.setState({loading : false})
                        })

                    }else if(this.state.idEtablissement){

                        this.surveillantService.getAllEtablissement(this.state.idEtablissement).then(data => 
                            this.setState({listeSurveillant: data, loading : false})
                        ).catch(error => {
                            this.notify.echec(error.response.data.message)
                            console.log(error)
                            this.setState({loading : false})
                        })
                    }


                    




                })
                .catch(error => {
                    this.notify.echec(error.response.data.message)
                    console.log(error);
                })
            }
            else {
                this.surveillantService.create(newSurveillant).then(data => {
                    this.notify.success()
                    this.setState({loading : true})


                    if(this.state.idDepartement){

                        this.surveillantService.getAllDepartement(this.state.idDepartement).then(data => 
                            this.setState({listeSurveillant: data, loading : false})
                        ).catch(error => {
                            this.notify.echec(error.response.data.message)
                            console.log(error)
                            this.setState({loading : false})
                        })

                    }else if(this.state.idEtablissement){

                        this.surveillantService.getAllEtablissement(this.state.idEtablissement).then(data => 
                            this.setState({listeSurveillant: data, loading : false})
                        ).catch(error => {
                            this.notify.echec(error.response.data.message)
                            console.log(error)
                            this.setState({loading : false})
                        })
                    }



                })
                .catch(error => {
                    this.notify.echec(error.response.data.message)
                    console.log(error);
                })
                
            }

            state = {
                ...state,
                surveillantDialog: false,
                surveillant: this.emptySurveillant
            };
        }

        this.setState(state);
    }





    editSurveillant(surveillant) {
        this.setState({
            surveillant,
            surveillantDialog: true
        });
    }




    confirmDeleteSurveillant = (surveillant) => {
        this.setState({
            surveillant,
            deleteSurveillantDialog: true
        });
    }


    hideDeleteSurveillantDialog = () =>{
        this.setState({
            surveillantDialog: false,
            surveillant: this.emptySurveillant,
            deleteSurveillantDialog: false
        })
    }



    deleteSurveillant(idSurveillant) {

        this.surveillantService.delete(parseInt(idSurveillant)).then(data => {

            this.notify.success()
            this.setState({
                surveillantDialog: false,
                surveillant: this.emptySurveillant,
                deleteSurveillantDialog: false
            });

            this.setState({loading : true})

            if(this.state.idDepartement){

                this.surveillantService.getAllDepartement(this.state.idDepartement).then(data => 
                    this.setState({listeSurveillant: data, loading : false})
                ).catch(error => {
                    this.notify.echec(error.response.data.message)
                    console.log(error)
                    this.setState({loading : false})
                })

            }else if(this.state.idEtablissement){

                this.surveillantService.getAllEtablissement(this.state.idEtablissement).then(data => 
                    this.setState({listeSurveillant: data, loading : false})
                ).catch(error => {
                    this.notify.echec(error.response.data.message)
                    console.log(error)
                    this.setState({loading : false})
                })
            }

        })
    }




    onNomSurveillantChange = (e, nomSurveillant) => {
        const val = (e.target && e.target.value) || '';
        let surveillant = this.state.surveillant;
        surveillant[`${nomSurveillant}`] = val;

        this.setState({surveillant});
    }



    onPrenomSurveillantChange(e, prenomSurveillant) {
        const val = (e.target && e.target.value) || '';
        let surveillant = this.state.surveillant;
        surveillant[`${prenomSurveillant}`] = val;

        this.setState({surveillant });
    }


    onMatriculeSurveillantChange = (e, matriculeSurveillant) => {
        const val = (e.target && e.target.value) || '';
        let surveillant = this.state.surveillant;
        surveillant[`${matriculeSurveillant}`] = val;
        this.setState({surveillant });
    }


    onTelSurveillantChange(e, telSurveillant) {
        const val = (e.target && e.target.value) || '';
        let surveillant = this.state.surveillant;
        surveillant[`${telSurveillant}`] = val;
        this.setState({surveillant });
    }




    onEmailSurveillantChange(e, emailSurveillant) {
        const val = (e.target && e.target.value) || '';
        let surveillant = this.state.surveillant;
        surveillant[`${emailSurveillant}`] = val;
        this.setState({surveillant });
    }



    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }



    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-pencil" className="p-button-warning" onClick={() => this.editSurveillant(rowData)} style={{marginRight: '.5em'}}/>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => this.confirmDeleteSurveillant(rowData)} />
            </React.Fragment>
        );
    }



    render() {

        let actionHeader = <Button type="button" icon="pi pi-cog"/>;
        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Recherche globale..." />
                </span>
            </div>
        );
        const surveillantDialogFooter = (
            <React.Fragment>
                <hr/>
                <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Enregistrer" icon="pi pi-check" className="p-button-text" onClick={this.saveSurveillant} />
            </React.Fragment>
        );
        const deleteSurveillantDialogFooter = (
            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteSurveillantDialog} />
                <Button label="OUI" icon="pi pi-check" className="p-button-text" onClick={()=> this.deleteSurveillant(this.state.surveillant.idSurveillant) } />
            </React.Fragment>
        );
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid" style={{marginLeft : 20}}>
                        <div className="p-col-2">
                            <Button label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                        </div>
                    </div>
    
                    <div className="card card-w-title datatable-demo">
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <ToastContainer />
                        {header}
                        {this.state.loading ? 
                        <div className="p-grid">
                            <div className="p-col-6" ></div>
                            <div className="p-col-6" >
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                        :
                        <DataTable  ref={(el) => this.dt = el} value={this.state.listeSurveillant} selectionMode="single" header={`Liste des surveillants (${this.state.listeSurveillant.length} surveillant(s))`} paginator={true} rows={5}
                            globalFilter={this.state.globalFilter} filterMatchMode="contains" responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}>
                            <Column field="matriculeSurveillant" header="Matricule" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher le matricule"/>
                            <Column field="nomSurveillant" header="Nom" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher nom"/>
                            <Column field="prenomSurveillant" header="Prénom" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher prénom"/>
                            <Column field="telSurveillant" header="Téléphone" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher téléphone"/>
                            <Column field="emailSurveillant" header="Email" filterMatchMode="contains" sortable={true} filter={true} filterPlaceholder="rechercher email"/>
                            <Column header={actionHeader} body={this.actionBodyTemplate} style={{textAlign:'center', width: '8em'}}/>
                        </DataTable>
                        }
                    </div>
                </div>


                <Dialog visible={this.state.surveillantDialog} style={{ width: '80%' }} header="Informations surveillant" modal className="p-fluid" footer={surveillantDialogFooter} onHide={this.hideDialog}>
                    <hr/>
                    <div className="p-grid">
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="matriculeSurveillant"><span style={{color:'red'}}>*</span> Matricule</label>
                                <InputText id="matriculeSurveillant" value={this.state.surveillant.matriculeSurveillant || ""}  onChange={(e) => this.onMatriculeSurveillantChange(e, 'matriculeSurveillant')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                {this.state.submitted && !this.state.surveillant.matriculeSurveillant && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                        </div>
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="nomSurveillant"><span style={{color:'red'}}>*</span> Nom</label>
                                <InputText id="nomSurveillant" value={this.state.surveillant.nomSurveillant || ""}  onChange={(e) => this.onNomSurveillantChange(e, 'nomSurveillant')} required className={classNames({ 'p-invalid': this.state.submitted })} />
                                {this.state.submitted && !this.state.surveillant.nomSurveillant && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className='p-col-12 p-md-12 p-lg-12'>
                            <div className="p-field">
                                <label htmlFor="prenomSurveillant"><span style={{color:'red'}}>*</span> Prénom</label>
                                <InputText id="prenomSurveillant" value={this.state.surveillant.prenomSurveillant || ""}  onChange={(e) => this.onPrenomSurveillantChange(e, 'prenomSurveillant')} required className={classNames({ 'p-invalid': this.state.submitted })} />
                                {this.state.submitted && !this.state.surveillant.prenomSurveillant && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                        </div>
                    </div>
                    <div className="p-grid">
                    <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="telSurveillant"><span style={{color:'red'}}>*</span> Téléphone</label>
                                <InputText id="telSurveillant" value={this.state.surveillant.telSurveillant || ""}  onChange={(e) => this.onTelSurveillantChange(e, 'telSurveillant')} required className={classNames({ 'p-invalid': this.state.submitted })} />
                                {this.state.submitted && !this.state.surveillant.telSurveillant && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                        </div>
                        <div className='p-col-6 p-md-6 p-lg-6'>
                            <div className="p-field">
                                <label htmlFor="emailSurveillant"><span style={{color:'red'}}>*</span> Email</label>
                                <InputText id="emailSurveillant" value={this.state.surveillant.emailSurveillant || ""}  onChange={(e) => this.onEmailSurveillantChange(e, 'emailSurveillant')} required autoFocus className={classNames({ 'p-invalid': this.state.submitted })} />
                                {this.state.submitted && !this.state.surveillant.emailSurveillant && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire.</small>}
                            </div>
                        </div>
                    </div>

                </Dialog>

                <Dialog visible={this.state.deleteSurveillantDialog} style={{ width: '450px' }} header="Confirmez" modal footer={deleteSurveillantDialogFooter} onHide={this.hideDeleteSurveillantDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.surveillant && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                    </div>
                </Dialog>

            </div>
        )
    }
}



export default Surveillants
