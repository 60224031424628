import axios from "axios";
import { AdresseIP } from "../../components/AdresseIP"


export default class FichePedagogiqueService{
    constructor(){
        this.addresseIP = new AdresseIP();
        this.backendUrl="service/api/campusfaso-volume-horaire/campus-faso"
        // http://localhost:8088/service/api/campusfaso-volume-horaire/campus-faso/reporting
    }
    
    /**
     * 
     * @param {*} idDepartement 
     * @returns La liste des enseignants appartennant à un département
     */
    getEnseignantDepartement(idDepartement){
        return axios.get(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}/enseignant/${idDepartement}`).then(response=>response.data);
    }

    getFicheProgrammatique(data){

        //console.log(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}/reporting`)
        //console.log(data)
        return axios.post(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}/reporting`,data,{responseType: "arraybuffer"}).then(response=>response.data);
    }
}