import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import './index.css';
import { DataTable } from 'primereact/datatable';
import React,{useState}  from 'react';
import { Column } from 'primereact/column';
import {Button} from 'primereact/button';
import { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Controller,useForm } from 'react-hook-form';
import classNames from 'classnames';  
import { Notify } from '../../../components/Notify';
import { ToastContainer } from 'react-toastify';
import { Spinner } from '../../../components/Spinner';
import { Dropdown } from 'primereact/dropdown';
import GradeService from '../../service/GradeService';
import RangService from '../../service/RangService';

export default function Grade(){

    const spinner = new Spinner();
    const notify = new Notify();
    const gradeService = new GradeService();
    const randService = new RangService()

    // Default value bloc
    // les valeurs par defaut des champs du formulaire
    const defaultValues = {
        libelleGrade: '',
        volumeStatutaire:'',
        coutCM:'',
        coutTD:'',
        heureMax:'',
        montantPrime:'',
        montantVE:'',
        baseAttribution:'',
        idGrade:'',
        rang:''
    };
    const dialogOpenHandler = {
        add:false,
        modify:false,
        delete:false,
        
    }
    const baseAttribution = [
        {name:"Cours Magistral",value:"Cours Magistral"},
        {name:"Travaux Dirigés",value:"Travaux Dirigés"},
        {name:"Travaux Pratiques",value:"Travaux Pratiques"}
    ]


    // variables declaration Bloc

    const [listOfGrade,setListOfGrade] = useState();            // variable d'etat contenant la liste des grades de la base de données
    const [gradeDelete,setGradeDelete]=useState();
    const [saveList,setSaveList] = useState([]);                // variables d'etat qui stock les grades temporairement pour la sauvegarde
    const [cancelAndReset,setCancelAndReset]=useState(false);   
    const [loading,setLoading] = useState(false);
    const [dialog,setDialog]=useState(dialogOpenHandler);
    const { control, formState: { errors }, handleSubmit, reset,setValue,getValues } = useForm({defaultValues});    // hook form qui gere le rendu du formulaire
    const [spin,setSpin] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [listRang, setlistRang] = useState([]);
    const [errorMessage, setErrorMessage] = useState({data:{}, error:false});


    // variables declaration EndBloc
    useEffect(() => {
        setLoading(true);
        gradeService.getAll()
        .then(response=>{
            setListOfGrade(response);
        })
        .catch(errors=>{
            notify.echec(errors.response.data.message);
            console.log(errors);
        })
        .finally(()=>{setLoading(false);})
        
        randService.getAll().then(response=>setlistRang(response));
    }, [])

    const handleModification = (dataToModify)=>{
        
        /**
         * 
         * definition des valeurs des champs du formulaire
         * setValue est une methode exporte de useForm lui meme une methode de hook form
         * ces variables doivent etre recupere depuis le getvalues exporte de hook from(useForm())
         * 
         */
        setValue("libelleGrade",dataToModify.libelleGrade);
        setValue("idGrade",dataToModify.idGrade);
        setValue("coutCM",dataToModify.coutCM);
        setValue("coutTD",dataToModify.coutTD);
        setValue("montantPrime",dataToModify.montantPrime);
        setValue("montantVE",dataToModify.montantVE);
        setValue("volumeStatutaire",dataToModify.volumeStatutaire);
        setValue("heureMax",dataToModify.heureMax);
        setValue("baseAttribution",{name:dataToModify.baseAttribution,value:dataToModify.baseAttribution});
        setValue("rang",dataToModify.rang);
        
        setDialog({modify:true}); // declanche la boite de dialog pour la modification
    }


    /**
     * 
     * @param {*} data un object recupere depuis le formulaire gerer par hook-form
     * cette fonction cree une liste pour stock les grades temporairement
     * puis seront enregistrer dans la base de données
     */
    const handleGradeSubmit = (data) => {
       
        data.baseAttribution=data.baseAttribution.value;
        setSaveList([...saveList,data]);
        reset(); //reinitialisation des champs du formulaire

    };


    /**
     * 
     * @param {*} dataForm un object recupere depuis le formulaire gerer par hook-form
     * dans cette fonction deux traitement sont possible
     * l'ajout d'une liste de grade dans la base de données
     * la modification des données d'une grade
     * 
     */
    const handleSave=(dataForm=null)=>{
        setSpin(true);
        if(dialog.add && saveList.length!==0){

            setLoading(true);
            gradeService.create(saveList)
            .then(statut=>{
                gradeService.getAll().then(response=>setListOfGrade(response)); // mise à jour de la liste des grades si la sauvegarde marche bien
                notify.success(); // notification de success du traitement
                reset(); //reinitialisation des champs du formulaire 
                setDialog(false);
            })
            .catch((error)=>{
                setErrorMessage({data:error.response.data, error:true});
                notify.echec(error.response.data.message); // notification d'echec du traitement

            })
            .finally(()=>{
                setSpin(false);
                setLoading(false);
            })
        }else
        if(dialog.modify){
            
            dataForm.baseAttribution=dataForm.baseAttribution.value;
            gradeService.update(dataForm)
            .then(()=>{
                notify.success(); // notification de success du traitement
                setLoading(true);
                gradeService.getAll().then((data)=>{setListOfGrade(data)});
                setDialog(false);
                reset(); //reinitialisation des champs du formulaire
            })
            .catch((error)=>{notify.echec(error.response.data.message)})
            .finally(()=>{
                setSpin(false);
                setLoading(false);
            })
        }
        
    }

    /**
     * 
     * @param {*} key indice du tableau contenant l'object à supprimer
     * suppression d'une grade de la liste des grades
     * 
     */
    const removeItem =(key)=>{
        saveList.splice(key,1);
        setSaveList([...saveList]); // mise à jour de la liste des grades appres suppression
        reset(); //reinitialisation des champs du formulaire
    }


   /**
    * 
    * @param {*} dataToDelete 
    * suppression d'une grade dans la base de données
    * 
    */
    const handleStatutDeletion = (dataToDelete=null)=>{

        if(cancelAndReset){
            setSpin(true);
            setTimeout(()=>{
                setSaveList([]); // reinitialisation de la liste des grades 
                setCancelAndReset(false);
                setDialog(false);
                setSpin(false);
            }
            
            ,2000)
        }else if(dialog.delete){
            setSpin(true);
            gradeService.delete(gradeDelete.idGrade)
            .then(()=>{
                notify.success(); // notification de success du traitement
                setLoading(true);
                gradeService.getAll().then(response=>{setListOfGrade(response)}) // mise à jour de la liste des grades appres suppression
                setDialog({delete:false})
            })
            .catch((error)=>{
                notify.echec(error.response.data.message); // notification d'echec
            })
            .finally(()=>{
                setSpin(false);
                setLoading(false);
            })

        }else{
            setGradeDelete(dataToDelete)
            setDialog({delete:true})
        }
    }
    


    // hide and lifecycle functions management
    const onHide = ()=>{
        if(cancelAndReset && dialog.add){
            setCancelAndReset(false);
        }else if(dialog.add || dialog.delete || dialog.modify){
            setDialog(dialogOpenHandler);
            reset();
        }

        setSaveList([]); // reinitialisation de la liste des grades 
    }

   


    // JSX function management
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const actionHeader = <Button type="button" icon="pi pi-cog"/>;

    const actionBodyTemplate = (rowData) => {
            return (
                <React.Fragment>
                    <Button type="button" icon="pi pi-pencil"  className="p-button-warning" style={{marginRight: '.5em'}} onClick={()=>handleModification(rowData)} />
                    <Button icon="pi pi-trash" className="p-button-danger" onClick={()=>handleStatutDeletion(rowData)} />
                </React.Fragment>
            );
        }

        
        const saveGradeFooter=(
            <React.Fragment>
                 <div className=" p-grid p-justify-end" style={{width:"100%"}}>
                    <Button type="submit" className="p-button-success"  disabled={dialog.add &&(spin || saveList.length===0)} label="Enregistrer" icon={spin?"pi pi-spin pi-spinner":"pi pi-save"} style={{marginRight:"15px"}} onClick={handleSave} />
                    <Button type="button" label="Quitter" className="p-button-danger" icon="pi pi-times" onClick={()=>{ if(saveList.length !==0){setCancelAndReset(true)}else{onHide()}}} />
                </div>
            </React.Fragment>
        )
        const deleteGradeFooter =(

            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-success" onClick={onHide} />
                <Button label="OUI" icon={spin?"pi pi-spin pi-spinner":"pi pi-check"} className="p-button-danger" onClick={handleStatutDeletion} />
            </React.Fragment>
            
        )
        const cancelGradeFooter =(

            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-success" onClick={()=>setCancelAndReset(false)} />
                <Button label="OUI" icon={spin?"pi pi-spin pi-spinner":"pi pi-check"} className="p-button-danger" onClick={handleStatutDeletion} />
            </React.Fragment>
            
        )

        const header = (
        <div className="table-header p-col-12">
            <span className="p-input-icon-left">
                <Button style={{marginLeft : 10}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={()=>setDialog({...dialog,add:true})} />
                <InputText style={{marginLeft : 20,width:"300px"}} type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
                
            </span>
        </div>
    );

    // function handler EndBloc

    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="card">

                {header}
                {
                loading ?
                    <div className="p-grid">
                        <div className="p-col-6" ></div>
                        <div className="p-col-6" >
                            {spinner.loadingSpinner("spin", "black")}
                        </div>
                    </div>
                :
                <DataTable 
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                    rows={5}
                    selectionMode="single" filterMatchMode="contains"
                    paginator responsive
                    value={listOfGrade}
                    header={listOfGrade?`Liste des Grades (${listOfGrade.length} grande(s))`:`Liste des Grades (0 grade)`}
                >
                    <Column field="rang.libelleRang" header="rang" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search ..."  sortable filter/>
                    <Column field="libelleGrade" header="libelle du grade" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search ..."  sortable filter/>
                    <Column field="baseAttribution" header="Base Attribution" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search ..." sortable filter/>
                    <Column field="volumeStatutaire" header="volume Horaire" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search ..." sortable filter/>
                    <Column field="heureMax" header="Heure Sup Autorisé"  headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search ..." sortable filter/>
                    <Column field="coutCM" header="Coûts Magistral"  headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search ..." sortable filter/>
                    <Column field="coutTD" header="Coûts TD/TP"  headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search ..." sortable filter/>
                    <Column field="montantPrime" header="Montant Prime"  headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search ..." sortable filter/>
                    <Column field="montantVE" header="Montant Voyage Etude"  headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search ..." sortable filter/>
                    <Column header={actionHeader} body={actionBodyTemplate} style={{width:"120px"}} />
                                     
                </DataTable>
                
                }
                
            </div>

            {/* Add Grade Bloc */}
                <div >
                <Dialog visible={dialog.add} style={{width:"98vw", height:"100vh"}} footer={saveGradeFooter} onHide={onHide}>
                    <div className="p-grid">
                        <div className="p-col-6 p-lg-6 p-md-12 p-sm-12">
                            <div className="p-d-flex p-jc-center">
                                <h5 className="text-header">Ajouter un grade enseignant</h5>
                                <hr />
                                <form onSubmit={handleSubmit(handleGradeSubmit)} className="p-fluid">
                                    
                                <div className="p-field">
                                    <span>
                                        <label htmlFor="rang"  style={{marginRight:"15px"}} className={classNames({ 'p-error': errors.name })}>Rang</label> {getFormErrorMessage('rang')}
                                        <Controller name="rang" control={control} rules={{ required: 'champ obligatoire.' }} render={({ field, fieldState }) => (
                                            <Dropdown id={field.name} name={field.name} value={field.value}
                                                {...field}
                                                onChange={(e)=>field.onChange(e.value)} options={listRang} optionLabel="libelleRang"
                                                autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                                        )} />
                                    </span>
                                </div>
                                <div className="p-grid">
                                    <div className="p-field p-col-6">
                                        <span>
                                            <label htmlFor="libelleGrade" style={{marginRight:"15px"}} className={classNames({ 'p-error': errors.name })}>Libelle</label>{getFormErrorMessage('libelleGrade')}
                                            <Controller name="libelleGrade" control={control} rules={{ required: 'champ obligatoire.'}} render={({ field, fieldState }) => (
                                                <InputText type="text" id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                                            )} />
                                        </span>
                                       
                                    </div>
                                    <div className="p-field p-col-6">
                                    <span>
                                        <label htmlFor="volumeStatutaire" style={{marginRight:"15px"}} className={classNames({ 'p-error': errors.name })}>Volume Horaire</label> {getFormErrorMessage('volumeStatutaire')}
                                        <Controller name="volumeStatutaire" control={control} rules={{ required: 'champ obligatoire.' }} render={({ field, fieldState }) => (
                                            <InputText keyfilter="num" type="text" id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                                        )} />
                                    </span>
                                    
                                    </div>
                                </div>
                                <div className="p-field">
                                    <span>
                                        <label htmlFor="baseAttribution"  style={{marginRight:"15px"}} className={classNames({ 'p-error': errors.name })}>Base Attribution</label> {getFormErrorMessage('baseAttribution')}
                                        <Controller name="baseAttribution" control={control} rules={{ required: 'champ obligatoire.' }} render={({ field, fieldState }) => (
                                            <Dropdown id={field.name} name={field.name} value={field.value}
                                                {...field}
                                                onChange={(e)=>field.onChange(e.value)} options={baseAttribution} optionLabel="name"
                                                autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                                        )} />
                                    </span>
                                </div>
                                <div className="p-grid">
                                    <div className="p-field p-col-6">
                                        <span>
                                            <label htmlFor="coutCM"  style={{marginRight:"15px"}} className={classNames({ 'p-error': errors.name })}>Coût CM</label> {getFormErrorMessage('coutCM')}
                                            <Controller name="coutCM" control={control} rules={{ required: 'champ obligatoire.' }} render={({ field, fieldState }) => (
                                                <InputText keyfilter="num" id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                                            )} />
                                        </span>
                                    </div>
                                    
                                    <div className="p-field p-col-6">
                                        <span>
                                            <label htmlFor="coutTD" style={{marginRight:"15px"}} className={classNames({ 'p-error': errors.name })}>Coût TD</label> {getFormErrorMessage('coutTD')}
                                            <Controller name="coutTD" control={control} rules={{ required: 'champ obligatoire.' }} render={({ field, fieldState }) => (
                                                <InputText keyfilter="num" id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                                            )} />
                                        </span>
                                        
                                    </div>
                                </div>
                                <div className="p-field">
                                    <span>
                                        <label htmlFor="heureMax" style={{marginRight:"15px"}} className={classNames({ 'p-error': errors.name })}>Heure Sup Autorise</label>{getFormErrorMessage('heureMax')}
                                        <Controller name="heureMax" control={control} rules={{ required: 'champ obligatoire.' }} render={({ field, fieldState }) => (
                                            <InputText keyfilter="num" id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                                        )} />
                                    </span>
                                    
                                </div>
                                <div className="p-grid">
                                    <div className="p-field p-col-6">
                                        <span>
                                            <label htmlFor="montantPrime"  style={{marginRight:"15px"}} className={classNames({ 'p-error': errors.name })}>Montant Prime</label>
                                            <Controller name="montantPrime" control={control}  render={({ field, fieldState }) => (
                                                <InputText keyfilter="num" id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                                            )} />
                                        </span>
                                        
                                    </div>
                                    <div className="p-field p-col-6">
                                        <span>
                                            <label htmlFor="montantVE" className={classNames({ 'p-error': errors.name })}>Montant Voyage d'Etude Autorise</label>
                                            <Controller  name="montantVE" control={control}  render={({ field, fieldState }) => (
                                                <InputText keyfilter="num" id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                                            )} />
                                        </span>
                                        
                                    </div>
                                </div>
                                
                                    <div style={{width:"100px",marginTop:"20px"}}>
                                        <Button type="submit" className="p-button-info" label="ajouter" icon="pi pi-plus" style={{marginRight:"15px"}} />
                                    </div>
                                    
                                </form>
                            </div>
                        </div>

                        <div className="p-col-6 p-lg-6 p-md-12 p-sm-12">
                            <h5 className="text-header">Nouvellement ajouté </h5>

                            <div className=" p-fieldset">
                                <table className="table" style={{minHeight:"300px"}}>
                                    <thead>
                                        <tr className="p-grid">
                                            <th className="p-col-3">Libelle</th>
                                            <th className="p-col-3">Volume Horaire</th>
                                            <th className="p-col-3">Rang</th>
                                            <th className="p-col-3" >Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            saveList.map((grade,index) =>
                                                <tr className="p-grid" key={index +1}>
                                                    <td className="p-col-3">{grade.libelleGrade}</td>
                                                    <td className="p-col-3">{grade.volumeStatutaire}</td>
                                                    <td className="p-col-3">{grade.rang.libelleRang}</td>
                                                    <td><Button icon="pi pi-trash" className="p-button-danger p-col-3" onClick={() => {removeItem(index)}} 
                                                    /></td>
                                                </tr>
                                            )
                                        }
                                    </tbody>      
                                </table>
                            </div>
                        </div>
                    </div>
                </Dialog>
                </div>
            {/* Add Grade EndBloc */}

            {/* Modify Grade Bloc */}
                <Dialog visible={dialog.modify} style={{width:"40vw"}} onHide={onHide}>
                    <div className="p-d-flex p-jc-center" style={{height:"600px"}}>
                        <h5 className="text-header">Modification</h5>
                        <form className="p-fluid" onSubmit={handleSubmit(handleSave)}>
                            <div  style={{height:"500px"}}>
                                <div className="p-field">
                                    <span>
                                        <label htmlFor="rang"  style={{marginRight:"15px"}} className={classNames({ 'p-error': errors.name })}>Rang</label> {getFormErrorMessage('rang')}
                                        <Controller name="rang" control={control} rules={{ required: 'champ obligatoire.' }} render={({ field, fieldState }) => (
                                            <Dropdown id={field.name} name={field.name} value={field.value}
                                                {...field}
                                                onChange={(e)=>field.onChange(e.value)} options={listRang} optionLabel="libelleRang"
                                                autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                                        )} />
                                    </span>
                                </div>
                                <div className="p-field">
                                    <label htmlFor="libelleGrade" className={classNames({ 'p-error': errors.name })}>Libelle</label>
                                    <Controller 
                                        name="libelleGrade" defaultValue={getValues("libelleGrade")}
                                        control={control} rules={{ required: 'champ obligatoire.'}}
                                        render={({field})=>(
                                            <InputText id={field.name} name={field.name} value={field.value} {...field}/> )}
                                    />
                                </div>
                                <div className="p-field">
                                    <label htmlFor="volumeStatutaire">Volume Horaire</label>
                                    <Controller 
                                        control={control} rules={{ required: 'champ obligatoire.'}}
                                        name="volumeStatutaire" defaultValue={getValues("volumeStatutaire")}
                                        render={({field})=>(
                                            <InputText keyfilter="num" id={field.name} name={field.name} value={field.value}  {...field}/>
                                        )}
                                    />
                                </div>

                                <div className="p-field">
                                        <label htmlFor="baseAttribution">Base Attribution</label>
                                        <Controller 
                                            control={control} rules={{ required: 'champ obligatoire.'}}
                                            name="baseAttribution" defaultValue={getValues("baseAttribution")}
                                            render={({field})=>(
                                                <Dropdown
                                                    value={field.value} name={field.name} id={field.name}  
                                                    options={baseAttribution} optionLabel="name"
                                                    onChange={e=>field.onChange(e.value)}
                                                    {...field}
                                                />
                                                
                                            )}
                                        />
                                </div>
                                <div className="p-grid">
                                    <div className="p-field p-col-6">
                                            <label htmlFor="coutCM">Coût CM</label>
                                            <Controller  
                                                control={control} rules={{ required: 'champ obligatoire.'}}
                                                name="coutCM" defaultValue={getValues("coutCM")}
                                                render={({field})=>(
                                                    <InputText keyfilter="num" id={field.name} name={field.name} value={field.value} {...field}/>
                                                )}
                                            />
                                    </div>
                                    <div className="p-field p-col-6">
                                        <label htmlFor="coutTD">Coût TD</label>
                                        <Controller 
                                            control={control} rules={{ required: 'champ obligatoire.'}}
                                            name="coutTD" defaultValue={getValues("coutTD")}
                                            render={({field})=>(
                                                <InputText keyfilter="num" id={field.name} name={field.name} value={field.value} {...field} />
                                            )}
                                        />
                                            
                                            
                                    </div>
                                </div>
                                
                                <div className="p-field">
                                    <label htmlFor="heureMax">Heure Sup Autorise</label>
                                    <Controller 
                                        control={control} rules={{ required: 'champ obligatoire.'}}
                                        name="heureMax" defaultValue={getValues("heureMax")}
                                        render={({field})=>(
                                            <InputText keyfilter="num" id={field.name} name={field.name} value={field.value} {...field}/>
                                        )}
                                    />
                                    
                                </div>
                                <div className="p-grid">
                                    <div className="p-field p-col-6">
                                        <label htmlFor="montantPrime">Montant Prime</label>
                                        <Controller 
                                            control={control}
                                            name="montantPrime" defaultValue={getValues("montantPrime")}
                                            render={({field})=>(
                                                <InputText keyfilter="num" id={field.name} name={field.name} value={field.value} {...field} />
                                            )}
                                        />
                                    </div>
                                    <div className="p-field p-col-6">
                                        <label htmlFor="montantVE">Montant Voyage d'Etude Autorise</label>
                                        <Controller 
                                            control={control}
                                            name="montantVE" defaultValue={getValues("montantVE")}
                                            render = {({field})=>(
                                                <InputText keyfilter="num" id={field.name} name={field.name} value={field.value} {...field} />
                                            )}
                                        />
                                    </div>
                                </div>
                                
                            </div>
                            <div style={{display:"flex"}}>
                                <Button type="submit" className="p-button-success"  disabled={spin} label="Enregistrer" icon={spin?"pi pi-spin pi-spinner":"pi pi-save"} style={{marginRight:"15px"}} />
                                <Button type="button" disabled={spin} label="Quitter" className="p-button-danger" icon="pi pi-times" onClick={()=>setDialog({...dialog,modify:false})} />
                            </div>
                        </form>
                    </div>
                </Dialog>
            {/* modification Grade EndBloc */}

            <Dialog visible={dialog.delete} style={{ width: '450px' }} header="Confirmez" modal footer={deleteGradeFooter} onHide={onHide}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {"'this.state.deletionId'" && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                </div>
            </Dialog>
            <Dialog visible={cancelAndReset} style={{ width: '450px' }} header="Confirmez" modal footer={cancelGradeFooter} onHide={()=>setCancelAndReset(false)}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    <span>Etes-vous sûr de quitter ?</span><br /><br />
                    <span>Les informations saisies seront perdues</span>
                </div>
            </Dialog>

            <Dialog visible={errorMessage.error} style={{ width: '450px', height:"25%" }} header="Confirmez" modal onHide={()=>setErrorMessage({data:{}, error:false})}>
                <div className="httperrors confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    <span>{errorMessage.data.message} </span>
                </div>
            </Dialog>



        </div>
    )
}
