
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import './index.css';

  

import { DataTable } from 'primereact/datatable';
import React,{useState}  from 'react';
import { Column } from 'primereact/column';
import {Button} from 'primereact/button';
import { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Controller,useForm } from 'react-hook-form';  
import classNames from 'classnames';
import { Notify } from '../../../components/Notify';
import { ToastContainer } from 'react-toastify';
import { Spinner } from '../../../components/Spinner';
import TypeInfrastructureService from '../../service/TypeInfrastructureService';



export default function TypeInfrastructure(){

    const spinner = new Spinner();
    const notify = new Notify();
    const typeInfrastructureService = new TypeInfrastructureService();
    // Default value bloc

    const defaultValues = {
        libelleTypeInfrastructure: '',
        idTypeInfrastructure:''
    };
    const dialogOpenHandler = {
        add:false,
        modify:false,
        delete:false,
        
    } 


    // variables declaration Bloc

    const [listTypeInfrastructure,setListTypeInfrastructure] = useState([]); // la variable d'etat contenant la liste complete des statuts enseignant à afficher/modifier
    const [typeInfrastructureDelete,setTypeInfrastructureDelete]=useState();
    const [saveList,setSaveList] = useState([]);
    const [cancelAndReset,setCancelAndReset]=useState(false);
    const [loading,setLoading] = useState(false);
    const [dialog,setDialog]=useState(dialogOpenHandler);
    const {control, formState: { errors }, handleSubmit, reset,setValue,getValues } = useForm({defaultValues});
    const [spin,setSpin] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    // variables declaration EndBloc

    // functions handler Bloc
    const handleTypeInfrastructureSubmit = (data) => {
        setSaveList([...saveList,data]);
        reset();
    };

    /**
     * 
     * @param {*} dataToModify 
     * @returns undefined
     * permet d'initialiser les données dans le controller du hook-form
     * pour la modification
     * 
     */

    const handleModification = (dataToModify)=>{
        setValue("libelleTypeInfrastructure",dataToModify.libelleTypeInfrastructure);
        setValue("idTypeInfrastructure",dataToModify.idTypeInfrastructure);
        setDialog({...dialog,modify:true});
    }

    // add and modify user information management
    const handleSave=(dataForm=null)=>{
        
        if(dialog.add && saveList.length!==0){
            setSpin(true);
            typeInfrastructureService.create(saveList)
            .then(()=>{
                notify.success()
                setLoading(true);
                 typeInfrastructureService.getAll().then(response=>setListTypeInfrastructure(response));
                setDialog(dialogOpenHandler);
                reset(); // reinistialisation des champs du formulaire.
            })
            .catch((error)=>{
                notify.echec(error.response.data.message); // notification d'echec;
            })
            .finally(()=>{
                setLoading(false);
                setSpin(false);
            })
        }else  if(dialog.modify){
            setSpin(true)
           
            typeInfrastructureService.update(dataForm)
            .then(()=>{
                setLoading(true);
                typeInfrastructureService.getAll().then((data)=>{setListTypeInfrastructure(data)});
                setDialog(false);
                notify.success(); // notification de success
                reset(); // reinistialisation des champs du formulaire.
            })
            .catch((error)=>{
                notify.echec(error.response.data.message); // notification d'echec
            })
            .finally(()=>{
                setLoading(false);
                setSpin(false);
            })
        }
        
    }

    const removeItem =(key)=>{
        saveList.splice(key,1);
        setSaveList([...saveList]);
        reset();
    }

    // delete user information management
    const handleTypeInfrastructureDelete = (dataToDelete=null)=>{
        if(cancelAndReset){
            setSpin(true);
            
            setTimeout(()=>{
                setSaveList([]); // reinistialisation de la liste contenant les statut à enregistrer
                setSpin(false);
                setCancelAndReset(false);
                setDialog(false);
            },2000);

        }else if(dialog.delete){
            setSpin(true)
            typeInfrastructureService.delete(typeInfrastructureDelete.idTypeInfrastructure)
            .then(()=>{
                setLoading(true);
                typeInfrastructureService.getAll().then(response=>{
                    setListTypeInfrastructure(response)
                });
                setDialog({delete:false})
                notify.success(); // notification de success
            })
            .catch((error)=>{
                notify.echec(error.response.data.message); // notification d'echec
            })
            .finally(()=>{
                setSpin(false);
                setLoading(false);
                setTypeInfrastructureDelete(null) // reinitialise la variable d'etat contenant le statut à effacer
            })

        }else{
            setTypeInfrastructureDelete(dataToDelete)
            setDialog({delete:true})
        }
    }
    


    // hide and lifecycle functions management
    const onHide = ()=>{
        if(cancelAndReset && dialog.add){
            setCancelAndReset(false);
        }else if(dialog.add || dialog.delete || dialog.modify){
            setDialog(dialogOpenHandler);
            reset(); // reinistialisation des champs du formulaire.
        }
        setSaveList([]); // reinistialisation de la liste contenant les statut à enregistrer
    }

    useEffect(() => {
        setLoading(true);
        typeInfrastructureService.getAll().then(response=>{ setListTypeInfrastructure(response)}).catch(errors=>{ console.log(errors)}).finally(()=>{setLoading(false);})
    }, [])


    // JSX function management
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const actionHeader = <Button type="button" icon="pi pi-cog"/>;

    const actionBodyTemplate = (rowData) => {
            return (
                <React.Fragment>
                    <Button type="button" icon="pi pi-pencil"  className="p-button-warning" style={{marginRight: '.5em'}} onClick={()=>handleModification(rowData)} />
                    <Button icon="pi pi-trash" className="p-button-danger" onClick={()=>handleTypeInfrastructureDelete(rowData)} />
                </React.Fragment>
            );
        }

        
        const saveTypeFooter=(
            <React.Fragment>
                 <div className="p-grid p-justify-end" style={{width:"100%"}}>
                    <Button type="submit" disabled={dialog.add && (spin||saveList.length===0)} className="p-button-success" label="Enregistrer" icon={spin?"pi pi-spin pi-spinner":"pi pi-save" } style={{marginRight:"15px"}} onClick={handleSave} />
                    <Button type="button" label="Quitter" disabled={spin?true:false} className="p-button-danger" icon="pi pi-times" onClick={()=>{ if(saveList.length !==0){setCancelAndReset(true)}else{onHide()}}} />
                </div>
            </React.Fragment>
        )
        const deleteTypeFooter =(

            <React.Fragment>
                <Button label="NON" disabled={spin?true:false} icon="pi pi-times" className="p-button-success" onClick={onHide} />
                <Button label="OUI" disabled={spin?true:false} icon={spin?"pi pi-spin pi-spinner":"pi pi-check"} className="p-button-danger" onClick={handleTypeInfrastructureDelete} />
            </React.Fragment>
            
        )
        const cancelTypeFooter =(

            <React.Fragment>
                <Button label="NON" icon="pi pi-times" className="p-button-success" onClick={()=>setCancelAndReset(false)} />
                <Button label="OUI" icon={spin?"pi pi-spin pi-spinner":"pi pi-check"} className="p-button-danger" onClick={handleTypeInfrastructureDelete} />
            </React.Fragment>
            
        )

        const header = (
            <div className="table-header p-col-12">
                <span className="p-input-icon-left">
                    <Button style={{marginLeft : 10}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={()=>setDialog({...dialog,add:true})} />
                    <InputText style={{marginLeft : 20,width:"300px"}} type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
                    
                </span>
            </div>
        );

    // function handler EndBloc

    return (
        <div>
          
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="card">
            <div style={{width:"100%",textAlign:"center"}}>{header}</div>
                {
                loading ?
                    <div className="p-grid">
                        <div className="p-col-6" ></div>
                        <div className="p-col-6" >
                            {spinner.loadingSpinner("spin", "black")}
                        </div>
                    </div>
                :
                <DataTable 
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                    rows={5}
                    selectionMode="single" filterMatchMode="contains"
                    paginator={true} speconsive={true}
                    globalFilter={globalFilter}
                    value={listTypeInfrastructure}
                    header="Panel des types d'infrastructure"
                >
                    <Column field="libelleTypeInfrastructure" header="Libelle" headerStyle={{textTransform:"capitalize"}} filterPlaceholder="Search nom"  sortable filter/>
                    <Column header={actionHeader} body={actionBodyTemplate} style={{width:"120px"}} />
                        
                </DataTable>
                
                }
                
            </div>

            {/* Add type  Bloc */}
                <Dialog className="card" visible={dialog.add} style={{width:"60vw"}} footer={saveTypeFooter} onHide={onHide}>

                    <div className="p-grid">
                        <div className="p-col-6 p-lg-6 p-md-12 p-sm-12">
                            <div className="p-d-flex p-jc-center">
                                <h5 className="text-header">Ajouter un type d'infrastructure</h5>
                                <form onSubmit={handleSubmit(handleTypeInfrastructureSubmit)} className="p-fluid">
                                    <div className="p-field" style={{height:"100px"}}>
                                        
                                        <label htmlFor="libelleTypeInfrastructure" className={classNames({ 'p-error': errors.name })}>type infrastructure {getFormErrorMessage('libelleTypeInfrastructure')}</label>
                                        <Controller name="libelleTypeInfrastructure" control={control} rules={{ required: 'champ obligatoire.'}} render={({ field, fieldState }) => (
                                            <InputText type="text" id={field.name} value={field.value} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                                        )} />
                                    </div>
                                   
                                    <div style={{width:"100px"}}>
                                        <Button type="submit" className="p-button-info" label="ajouter" icon="pi pi-plus" style={{marginRight:"15px"}} />
                                    </div>
                                    
                                </form>
                        </div>
                    </div>

                        <div className="p-col-6 p-lg-6 p-md-12 p-sm-12">
                            <h5 className="text-header">Nouvellement ajouté </h5>

                            <div className=" p-fieldset">
                                <table className="table" style={{minHeight:"300px"}}>
                                    <thead>
                                        <tr className="p-grid">
                                            <th className="p-col-8">Libelle</th>
                                            <th className="p-col-4" >Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            saveList.map((spec,index) =>
                                                <tr className="p-grid" key={index+1}>
                                                    <td className="p-col-8">{spec.libelleTypeInfrastructure}</td>
                                                    <td><Button icon="pi pi-trash" className="p-button-danger p-col-4"
                                                     onClick={() => {removeItem(index)}} 
                                                    /></td>
                                                </tr>
                                            )
                                        }
                                    </tbody>      
                                </table>
                            </div>
                        </div>
                    </div>
                </Dialog>
            {/* Add type EndBloc */}


            {/* Modify type Bloc */}
                <Dialog className="card" visible={dialog.modify} 
                style={{width:"30vw"}}
                 onHide={onHide}>

                    <div className="p-d-flex p-jc-center">
                            <h5 className="text-header">Modification</h5>
                            <form onSubmit={handleSubmit(handleSave)} className="p-fluid">
                                <div className="p-field">
                                    <span className="p-float-label">
                                        <span >Libelle</span>
                                        <Controller 
                                            control={control}
                                            name="libelleTypeInfrastructure"
                                            defaultValue={getValues("libelleTypeInfrastructure")}
                                            render = {({field})=>(
                                                <InputText id="libelleTypeInfrastructure" type="text" value={field.value} {...field}/>
                                            )}
                                        />
                                    </span>
                                </div>
                                <div className='mt-4' style={{display:"flex"}} >
                                    <Button  type="submit" className="p-button-success" disabled={spin?true:false} label="Enregistrer" icon={spin?"pi pi-spin pi-spinner":"pi pi-save"} style={{marginRight:"15px"}} />
                                    <Button className="p-button-danger"  disabled={spin?true:false} type="button" label="Quitter"  icon="pi pi-times"  onClick={onHide}/>
                                </div>
                            </form>
                    </div>

                </Dialog>
            {/* Modify type EndBloc */}


            <Dialog visible={dialog.delete} style={{ width: '450px' }} header="Confirmez" modal footer={deleteTypeFooter} onHide={onHide}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {"'this.state.deletionId'" && <span>Etes-vous sûr de supprimer cet élément ?</span>}
                </div>
            </Dialog>
            <Dialog visible={cancelAndReset} style={{ width: '450px' }} header="Confirmez" modal footer={cancelTypeFooter} onHide={()=>setCancelAndReset(false)}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    <span>Etes-vous sûr de quitter ?</span><br /><br />
                    <span>Les informations saisies seront perdues</span>
                </div>
            </Dialog>



        </div>
    )
}