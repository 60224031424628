import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';



export default class ProgrammationService {
    constructor(){
        this.adresseIP = new AdresseIP();
        this.backendUrl="service/api/campusfaso-volume-horaire/campus-faso/programmations-seances"
        // this.urlParcoursNiveau = 
    }


    // http://localhost:8088/service/api/campusfaso-volume-horaire/campus-faso/programmations-seances-parcours-niveau/9/100?idDepartement=4

    /**
     * 
     * @param {*} idDepartement l'identifiant du departement
     * @param {*} idParcours l'identifiant du parcours
     * @returns la liste des programmations du séance de cours du parcours en fonction du departement
     * 
     */
    getProgrammationByParcours(idDepartement,idParcours){
        return axios.get(`${this.adresseIP.getAdresseVolumeHoraire()}/service/api/campusfaso-volume-horaire/campus-faso/programmations-seances-parcours-niveau/${idParcours}?idDepartement=${idDepartement}`).then(response=>response.data);
    }

    /**
     * 
     * @param {*} idDepartementEtab 
     * @param {*} IdIes 
     * @returns List
     * retourne la listes des programmations en fonctions de l'université et du departement
     * 
     */
    getProgrammationDepartement(idDepartementEtab){
        return axios.get(`${this.adresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}?idDepartement=${idDepartementEtab}`).then(response=>response.data);
    }


    /**
     * 
     * @param {*} idDepartementEtab 
     * @param {*} listProgrammation 
     * @returns void
     * enregistre les séances de cours programmer
     */
    create(idDepartementEtab,listProgrammation){
        return axios.post(`${this.adresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}`,listProgrammation);
    }
    

    /**
     * 
     * @param {*} programmation un object de type programmation seance
     * @returns void
     * 
     * mise à jour des informations de la programmation passé en paramétres
     * 
     */

    update(idSeanceProg,programmation){
        return axios.put(`${this.adresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}/${idSeanceProg}`,programmation);
    }

    /**
     * 
     * @param {*} idSeanceProg 
     * @returns l'object suppression
     * 
     * suppression logique de la programmation dont l'identifiant est passé en paramétre
     * 
     */

    delete(idSeanceProg){
        return axios.patch(`${this.adresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}/${idSeanceProg}`);
    }

    /**
     * 
     * @param {*} idDepartementEtab 
     * @param {*} programmation 
     * @returns 409 (BAD REQUEST) si la cohorte à déja été programmée pour une autre activité dans la période contenue dans l'object programmation
     * @returns 400 (CONFLITS) si l'infrastructure à déjà été programmée pour une autre activité dans la période contenue dans l'object programmation
     * 
     * verifie l'existance d'une occurence de la programmation en se basant sur les heures de programmations
     * 
     */
    verificationExistance(idDepartementEtab,programmation){
        return axios.post(`${this.adresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}/?idDepartementEtab=${idDepartementEtab}`,programmation)
    }

    /**
     * 
     * @param {*} idDepartement 
     * @returns une liste des infrastructures dont l'identifiant du département est passé en paramétre
     * 
     */

    getInfrastructureByDepartement(idDepartement){
        return axios.get(`${this.adresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}/infrastructure/?idDepartement=${idDepartement}`).then(response=>response.data);
    }


    getIersByIdDepartement(idDepartement){
        //console.log(`${this.adresseIP.getAdresseVolumeHoraire()}/service/api/campusfaso-volume-horaire/campus-faso/ies-departement?idDepartement=${idDepartement}`)
        return axios.get(`${this.adresseIP.getAdresseVolumeHoraire()}/service/api/campusfaso-volume-horaire/campus-faso/ies-departement?idDepartement=${idDepartement}`).then(response=>response.data)
    }


    getProgrammationByIdDelegue(delegueIne){
        return axios.get(`${this.adresseIP.getAdresseVolumeHoraire()}/service/api/campusfaso-volume-horaire/campus-faso/programmations-parcours-niveau-chef?ine=${delegueIne}`).then(response=>response.data)
    }

          
} 