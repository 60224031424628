import axios from "axios";
import { AdresseIP } from "../../components/AdresseIP";


export default class SeanceEffectuerService{

    constructor(){
        this.addresse = new AdresseIP();
        this.backendUrl="service/api/campusfaso-volume-horaire/campus-faso/programmations-seances";
    }

   
    getAllProgrammationEnseignant(matricule){

        return axios.get(`${this.addresse.getAdresseVolumeHoraire()}/${this.backendUrl}-enseignant/?matricule=${matricule}`)
        .then(response=>response.data);

    }

    // http://localhost:8088/service/api/campusfaso-volume-horaire/campus-faso/programmations-seances?idDepartement=3&matricule=45966
    getSeanceEffectuerDepartement(idDepartement){
        return axios.get(`${this.addresse.getAdresseVolumeHoraire()}/${this.backendUrl}-enregistrees/?idDepartement=${idDepartement}`).then(response=>response.data);
    }

    update(seanceEffectuer){
        return axios.put(`${this.addresse.getAdresseVolumeHoraire()}/${this.backendUrl}-effectuee/${seanceEffectuer.idSeanceProg}`,seanceEffectuer);
    }

}  
