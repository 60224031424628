import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class InscriptionService {

     constructor(){
         this.adresse = new AdresseIP()
     }


     testerExistanceSessionInscription(idEtudiant){
        const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/inscription?idEtudiant="
        return axios.get(url+idEtudiant).then(res => res.data)
     }

     testerExistanceSessionInscriptionAdministrative(idEtudiant){
        const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/etudiant-inscription-administrative?idEtudiant="
        return axios.get(url+idEtudiant).then(res => res.data)
     }


     testerExistanceSessionInscriptionPedagogique(idEtudiant){
        const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/etudiant-inscription-pedagogique?idEtudiant="
        return axios.get(url+idEtudiant).then(res => res.data)
     }


     getInformationEtudiant(idEtudiant){
        const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/etudiant?idEtudiant="
        return axios.get(url+idEtudiant).then(res => res.data)
     }


   getInformationInscriptionPedagogique(idEtudiant, idSession){
      const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/etudiant-inscription-ec?idEtudiant="+idEtudiant+"&idSession="+idSession
      return axios.get(url).then(res => res.data)
   }

   getMontantInscription(idParcours, idNiveau, idEtudiant){
      const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/montant-paiement?idParcours="+idParcours+"&idNiveau="+idNiveau+"&idEtudiant="+idEtudiant
      return axios.get(url).then(res => res.data)
   }

   inscrireEtudiant(inscriptionPedagogique){
      const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/inscription-pedagogique"
      return axios.post(url, inscriptionPedagogique).then(res => res.data)
   }

   testerInscriptionPedagogiqueEtudiant(idEtudiant){
      const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/inscription-pedagogique?idEtudiant="+idEtudiant
      return axios.get(url).then(res => res.data)
   }


   InscriptionAdministrative(inscriptionAdministrative){
      const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/inscription-administrative/"
      return axios.post(url, inscriptionAdministrative).then(res => res.data)
   }

   paiementInscriptionAdministrative(inscriptionAdministrative){
      const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/paiement-operateur/"
      return axios.post(url, inscriptionAdministrative).then(res => res.data)
   }

   getAttestationInscriptionPedagogique(attestationRequest){
      const url = this.adresse.getAdresseIP() + "/service/api/campusfaso-lmd/campus-faso/reporting/"
      return axios.post(url, attestationRequest, {responseType: "arraybuffer"}).then(res => res.data)
   }  

     getListeEtudiantInscriptionEc(idDepartement, idParcours, idAnneeAcademique, idNiveau, idSemestre){
      const url = this.adresse.getAdresseIP() 
      +"/service/api/campusfaso-lmd/campus-faso/inscription-ec?idDepartement="
      +parseInt(idDepartement)
      +"&idParcours="
      +parseInt(idParcours)
      +"&idAnneeAcademique="
      +parseInt(idAnneeAcademique)
      +"&idNiveau="
      +parseInt(idNiveau)
      +"&idSemestre="
      +parseInt(idSemestre)
      

      return axios.get(url)
                  .then(res => res.data);
     }

     getListeInscriptionEc(idDepartement, idParcours, idAnneeAcademique, idNiveau, idSemestre, listeEc){
      const url = this.adresse.getAdresseIP() 
      +"/service/api/campusfaso-lmd/campus-faso/inscription-ec?idDepartement="
      +parseInt(idDepartement)
      +"&idParcours="
      +parseInt(idParcours)
      +"&idAnneeAcademique="
      +parseInt(idAnneeAcademique)
      +"&idNiveau="
      +parseInt(idNiveau)
      +"&idSemestre="
      +parseInt(idSemestre)
      

      return axios.post(url, listeEc)
                  .then(res => res.data);
     }

     updateEtudiant(idEtudiant ,etudiant) {
      const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/etudiant/"
      return axios.put(url+idEtudiant, etudiant)
                  .then(res => res.data);
  }

  deleteAllStudents(idAnnee, idParcours, idNiveau){
      const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/inscription-delete-all?idAnnee="+idAnnee+"&idParcours="+idParcours+"&idNiveau="+idNiveau

      console.log(url)
      return axios.patch(url)
               .then(res => res.data);
  }

  deleteStudent(idAnnee, idParcours, idNiveau, idEtudiant){
   const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/inscription-delete-etudiant?idAnnee="+idAnnee+"&idParcours="+idParcours+"&idNiveau="+idNiveau+"&idEtudiant="+idEtudiant
   return axios.patch(url)
            .then(res => res.data);
  }
     
    /*
    getAll() {
        
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/examen"
        return axios.get(url).then(res => res.data)
    }
    
    create(newExamen) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/examen"
        return axios.post(url,newExamen)
                    .then(res => res.data);
    }

    update(idExamen ,newExamen) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/examen/"
        return axios.put(url+idExamen, newExamen)
                    .then(res => res.data);
    }

    delete(idExamen) {
        const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/examen/"
        return axios.patch(url+idExamen)
                    .then(res => res.data)
    }
    */

}