import axios from "axios";
import { AdresseIP } from "../../components/AdresseIP";


export default class AffectationService{
    constructor(){
        this.addresse = new AdresseIP();
        // this.backendUrl="service/api/campusfaso-volume-horaire/campus-faso/elements-constitutifs-horaire";
        this.backendUrl="service/api/campusfaso-volume-horaire/campus-faso";
        // this.backendAffectationUrl="service/api/campusfaso-volume-horaire/campus-faso/affectations-cours";
        // this.backendAffectationCohorteUrl="service/api/campusfaso-volume-horaire/campus-faso/affectation-semestre-cohorte";
    }

    /**
     * 
     * @param {*} idDepartementEtab le id du departement
     * @returns une liste des affectations des activites de ce departement
     */
    getAllAffectationDepartement(idDepartementEtab){
        return axios.get(`${this.addresse.getAdresseVolumeHoraire()}/${this.backendUrl}/elements-constitutifs-horaire/?idDepartement=${idDepartementEtab}`).then(response=>{return response.data});
    }


    getAllAffectationIESR(idIesr){
        return axios.get(`${this.addresse.getAdresseVolumeHoraire()}/${this.backendUrl}/?idIesr=${idIesr}`)
    }
   

    /**
     * 
     * @param {*} idDepartementEtab 
     * @param {*} listAffectation 
     * @returns void
     * enregistre une liste de repartition des cours en selon que la personne est connecté avec un compte Departementale
     * 
     */
    createAffectionDepartement(idDepartementEtab,listAffectation){

        console.log(`${this.addresse.getAdresseVolumeHoraire()}/${this.backendUrl}/elements-constitutifs-horaire/?idDepartementEtab=${idDepartementEtab}`)
        return axios.post(`${this.addresse.getAdresseVolumeHoraire()}/${this.backendUrl}/elements-constitutifs-horaire/?idDepartementEtab=${idDepartementEtab}`,listAffectation)
    }
    /**
     * 
     * @param {*} idIes 
     * @param {*} listAffectation 
     * @returns void
     * enregistre une liste de repartition des cours en selon que la personne est connecté avec un compte Universitaire
     * 
     */
    createAffectionIESR(idIes,listAffectation){
        return axios.post(`${this.addresse.getAdresseVolumeHoraire()}/${this.backendUrl}/?idIes=${idIes}`,listAffectation)
    }

    update(affectation){
        //console.log(`${this.addresse.getAdresseVolumeHoraire()}/${this.backendUrl}/elements-constitutifs-horaire/${affectation.idElementConstitutifHoraire}?idDepartement=${affectation.idDepartementEtab}`)
        return axios.put(`${this.addresse.getAdresseVolumeHoraire()}/${this.backendUrl}/elements-constitutifs-horaire/${affectation.idElementConstitutifHoraire}?idDepartement=${affectation.idDepartementEtab}`,affectation);
    }


    delete(idElementConstitutifHoraire){
        return axios.patch(`${this.addresse.getAdresseVolumeHoraire()}/${this.backendUrl}/elements-constitutifs-horaire/${idElementConstitutifHoraire}`)
    }



    getAffectationParcours(idParcours,idSemestre,idDepartement){
        return axios.get(`${this.addresse.getAdresseVolumeHoraire()}/${this.backendUrl}/affectations-cours/${idParcours}/${idSemestre}?idDepartement=${idDepartement}`)
        .then(response=>response.data);
    }

    getAllAffectationEnseignant(idEnseignant){
        return axios.get(`${this.addresse.getAdresseVolumeHoraire()}/${this.backendUrl}/affectations-cours/idEnseignant=${idEnseignant}`)
        .then(response=>response.data);
    }

    getAffectationCohorteSemestre(idDepartement, idCohorte, idSemestre){
        return axios.get(`${this.addresse.getAdresseVolumeHoraire()}/${this.backendUrl}/affectation-semestre-cohorte?idDepartement=${idDepartement}&idSemestre=${idSemestre}&idCohorte=${idCohorte}`)
        .then(response=>response.data);
    }

    // http://localhost:8088/service/api/campusfaso-volume-horaire/campus-faso/affectation-enseignant-semestre?idDepartement=3&idEnseignant=129&idSemestre= 1000

    getAffectationEnseignantSemestre(request){
        return axios(`${this.addresse.getAdresseVolumeHoraire()}/${this.backendUrl}/affectation-enseignant-semestre?idDepartement=${request.code_departement}&idEnseignant=${request.idEnseignant}&idSemestre=${request.idSemestre}&idAnneeAcademique=${request.idAnneeAcademique}`).then(response=>response.data);
    }
}  
