import axios from "axios";
import {AdresseIP} from '../../components/AdresseIP';

export default class RangService{
    constructor(){
        this.addresseIP = new AdresseIP();
        this.backendUrl="service/api/campusfaso-volume-horaire/campus-faso/rangs"
    }

    /**
     * 
     * @returns la liste complete des rangs enseignant
     * 
     */

    getAll(){ 
        return axios.get(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}`).then(response=>response.data);
    }
   

    /**
     * 
     * @param {*} listRangs liste d'objects de type statut
     * @returns undefined
     * enregistre la liste des statuts des enseignants dans la base de données
     */
    create(listRangs){
        return  axios.post(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}`,listRangs).then(response=>response.status)
    }

    /**
     * 
     * @param {*} rang 
     * @returns undefined
     * mise à jour des informations concernants un rangs pour enseignant
     */
    update(rang){
        return axios.put(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}/${rang.idRang}`,rang).then(response=>response.status);
        
    }

    /**
     * 
     * @param {*} rangId 
     * @returns undefined
     * suppression logique du statut dont l'identifiant est passé en paramètres
     */
    delete(rangId){
        return axios.patch(`${this.addresseIP.getAdresseVolumeHoraire()}/${this.backendUrl}/${rangId}`).then(response=>response.status);
    }
}
