import React, { useState, useEffect } from 'react'
import { Menubar } from 'primereact/menubar';
import logoCampusFaso from '../images/campusfaso_logo_wb.png'
import { InputText } from 'primereact/inputtext';
import { Route } from 'react-router-dom';

import Structure from '../depotEnLigne/components/parametres/Structure';
import Condition from '../depotEnLigne/components/parametres/Condition';
import Entite from '../depotEnLigne/components/parametres/Entite';
import Parametre from '../depotEnLigne/components/parametres/Parametre';
import EntiteParametre from '../depotEnLigne/components/parametres/EntiteParametre';
import TypeSession from '../depotEnLigne/components/parametres/TypeSession';
import TypeDemande from '../depotEnLigne/components/parametres/TypeDemande';
import Session from '../depotEnLigne/components/parametres/Session';
import CategorieDemande from '../depotEnLigne/components/parametres/CategorieDemande';
import TypeCritere from '../depotEnLigne/components/parametres/TypeCritere';
import Critere from '../depotEnLigne/components/parametres/Critere';
import { StructureService } from '../depotEnLigne/service/StructureService';
import Depot from '../depotEnLigne/components/Depot';
import DemandeAutorisation from '../depotEnLigne/components/parametres/DemandeAutorisation';
import DemandeEquivalence from '../depotEnLigne/components/parametres/DemandeEquivalence';
import Dashbord from '../depotEnLigne/components/Dashbord';
import DashbordClient from '../depotEnLigne/components/DashbordClient';

const DepotEnLigne = (keycloak) => {

    const tokenDecoded = localStorage.getItem('tokenDecoded')
	const token = JSON.parse(tokenDecoded)
    const [structure, setStructure] = useState({});
    const structureService = new StructureService();
    
    let roles = null
    if(token.realm_access.roles) roles = token.realm_access.roles
    
    let campusStructure = 0, campusDemandeur = 0;

    const profiles = roles.filter(role => {
        
        if(role === "procedure-user"){
            campusDemandeur = 1
            localStorage.setItem('campusDemandeur', JSON.stringify(campusDemandeur))
        }
        /*else if(role === "campus-admin-departement"){
            campusStructure = 1
            localStorage.setItem('campusStructure', JSON.stringify(campusStructure))
        }*/
        else {
            campusStructure = 1
            localStorage.setItem('campusStructure', JSON.stringify(campusStructure))
        }
        return role
    })

    useEffect(() => {

        /*structureService.get(token.code_departement).then(data => {
            setStructure(data);

            //console.log(data)
            
        })*/
        if(campusDemandeur){
            window.location.hash = "/procedure/dashbord-demandeur"
        }else if(campusStructure){
            window.location.hash = "/procedure/dashbord"
        }
        else{
            //window.location.hash = "/lmd/dashboard"
        }

    }, [])
    
    

    /*const itemsDGESup = [
        
        {
            label: 'Tableau de bord',
            icon: 'pi pi-fw pi-power-offs'
        },
        
        {
            label: 'Traitement de demande',
            icon: 'pi pi-fw pi-pencils',
            items: [
                {
                    label: 'Enseigner',
                    icon: 'pi pi-fw pi-align-left'
                },
                {
                    label: "Création d'IPES ",
                    icon: 'pi pi-fw pi-align-right'
                } 

            ]
        },
        {
            label: 'Paramètres',
            icon: 'pi pi-fw pi-pencils',
            items: [
                {
                    label: 'Structure',
                    icon: 'pi pi-fw pi-align-right',
                    command:()=>{window.location.hash = "/depotEnLigne/parametres/structure"}
                },
                {
                    label: 'Type Session',
                    icon: 'pi pi-fw pi-align-right',
                    command:()=>{window.location.hash = "/depotEnLigne/parametres/typeSession"}
                },
                
                {
                    label: 'Entité',
                    icon: 'pi pi-fw pi-align-right',
                    command:()=>{window.location.hash = "/depotEnLigne/parametres/entite"}
                },
                {
                    label: 'Paramètres',
                    icon: 'pi pi-fw pi-align-right',
                    command:()=>{window.location.hash = "/depotEnLigne/parametres/parametre"}
                },
                {
                    label: 'Paramètres/entité',
                    icon: 'pi pi-fw pi-align-right',
                    command:()=>{window.location.hash = "/depotEnLigne/parametres/parametre-entite"}
                }
                    
            ]
        }
        
    ];*/


    const itemsStructure = [
        
        {
            label: 'Tableau de bord',
            icon: 'pi pi-fw pi-power-offs',
            command:()=>{window.location.hash = "/procedure/dashbord"}
        },
        {
            label: 'Demandes',
            icon: 'pi pi-fw pi-pencils',
            //command:()=>{window.location.hash = "/procedure/demandes"}
            items: [
                {
                    label: "Autorisation d'enseigner",
                    icon: 'pi pi-fw pi-align-left',
                    command:()=>{window.location.hash = "/procedure/autorisation-enseigner"}
                },
                {
                    label: "Equivalence de diplôme ",
                    icon: 'pi pi-fw pi-align-right',
                    command:()=>{window.location.hash = "/procedure/equivalence-diplome"}
                } 

            ]
        },
        
        /*{
            label: 'Traitement de demande',
            icon: 'pi pi-fw pi-pencils',
            items: [
                {
                    label: 'Enseigner',
                    icon: 'pi pi-fw pi-align-left'
                },
                {
                    label: "Création d'IPES ",
                    icon: 'pi pi-fw pi-align-right'
                } 

            ]
        },*/
        {
            label: 'Paramètres',
            icon: 'pi pi-fw pi-pencils',
            items: [
                {
                    label: 'Categorie demande',
                    icon: 'pi pi-fw pi-align-left',
                    command:()=>{window.location.hash = "/procedure/parametres/categorie-demande"}

                },
                {
                    label: 'Type demande',
                    icon: 'pi pi-fw pi-align-right',
                    command:()=>{window.location.hash = "/procedure/parametres/type-demande"}
                },
                {
                    label: 'Session',
                    icon: 'pi pi-fw pi-align-left',
                    command:()=>{window.location.hash = "/procedure/parametres/session"}
                },
                {
                    label: 'Condition',
                    icon: 'pi pi-fw pi-align-right',
                    command:()=>{window.location.hash = "/procedure/parametres/condition"}
                },
                {
                    label: 'Type critère',
                    icon: 'pi pi-fw pi-align-right',
                    command:()=>{window.location.hash = "/procedure/parametres/type-critere"}
                },
                {
                    label: 'Critère',
                    icon: 'pi pi-fw pi-align-right',
                    command:()=>{window.location.hash = "/procedure/parametres/critere"}
                },
                {
                    label: 'Paramètres',
                    icon: 'pi pi-fw pi-align-right',
                    command:()=>{window.location.hash = "/procedure/parametres/parametre"}
                },
                {
                    label: 'Paramètres/entité',
                    icon: 'pi pi-fw pi-align-right',
                    command:()=>{window.location.hash = "/procedure/parametres/parametre-entite"}
                }
            ]
        }
        
    ];




    const itemsDemandeur = [
        {
            label: 'Tableau de bord',
            icon: 'pi pi-fw pi-power-offs',
            command:()=>{window.location.hash = "/procedure/dashbord-demandeur"}
        },
        
        {
            label: 'Dépot de dossier',
            icon: 'pi pi-fw pi-power-offs',
            //command:()=>{window.location.hash = "/procedure/depot"}
            items: [
                {
                    label: "Autorisation d'enseigner",
                    icon: 'pi pi-fw pi-align-left',
                    command:()=>{window.location.hash = "/procedure/autorisation-enseigner"}
                },
                {
                    label: "Equivalence et reconnaissance de diplôme ",
                    icon: 'pi pi-fw pi-align-right',
                    command:()=>{window.location.hash = "/procedure/equivalence-diplome"}
                } 

            ]

        }
    ];

    return (
        <div className="html">
            <div className="body">
                {token ?
                <div className="grid-container">
                    <header className="header">
                        <div className="header-text">
                            <i className="ti-user"></i>
                            <span>Bienvenue <span style={{color : "green"}}>{token.name} </span>{""}</span>
                                |  
                            <a href="#" onClick={()=>{localStorage.clear(); keycloak.keycloak.logout()}}> 
                                <i className="ti-close"></i> 
                                Déconnexion
                            </a> |
                            <a href="#" className="btn" onClick={()=> {window.location.reload()}} >
                                <i className="ti-home"></i> 
                                Accueil
                            </a>
                        </div> 
                    </header>
                    <aside className="sidenav">
                        <div className="logo">
                            <img src={logoCampusFaso} alt=""/>
                            <div className="txt">
                                Plateforme intégrée de <br/>
                                l’enseignement supérieur <br/>
                                et de la recherche
                            </div>
                        </div>
                    </aside>  

                    <main className="main">
                        <div className="cards titles" >
                            {//campusStructure ?
                                /*<Menubar model={itemsStructure} />
                                :
                                client ?
                                <Menubar model={itemsClient} />*/
                                campusDemandeur ?
                                <Menubar model={itemsDemandeur} />
                                //<Menubar model={itemsAdminDepartement} />
                                :
                                <Menubar model={itemsStructure} />
                            }
                        </div>

                        <div>
                    
                            <Route path="/procedure/dashbord" component={Dashbord}/>
                            <Route path="/procedure/dashbord-demandeur" component={DashbordClient}/>
                            
                            <Route path="/procedure/parametres/structure" component={Structure}/> 
                            <Route path="/procedure/parametres/entite" component={Entite}/>
                            <Route path="/procedure/parametres/parametre" component={Parametre}/>
                            <Route path="/procedure/parametres/parametre-entite" component={EntiteParametre}/>  
                            <Route path="/procedure/parametres/condition" component={Condition}/>     
                            <Route path="/procedure/parametres/type-session" component={TypeSession}/>
                            <Route path="/procedure/parametres/type-demande" component={TypeDemande}/>
                            <Route path="/procedure/parametres/session" component={Session}/>
                            <Route path="/procedure/parametres/categorie-demande" component={CategorieDemande}/>
                            <Route path="/procedure/parametres/type-critere" component={TypeCritere}/>
                            <Route path="/procedure/parametres/critere" component={Critere}/>
                            <Route path="/procedure/depot" component={Depot}/>
                            <Route path="/procedure/demandes" component={Depot}/>
                            <Route path="/procedure/autorisation-enseigner" component={DemandeAutorisation}/> 
                            <Route path="/procedure/equivalence-diplome" component={DemandeEquivalence}/> 
                            
                            {/*<Route path="/depotEnLigne/demandeAutorisationEnseigner" component={DemandeAutorisation}/> 
                            <Route path="/depotEnLigne/demandeReconnaissance" component={DemandeEquivalence}/> 
                            <Route path="/depotEnLigne/dashbord" component={Dashbord}/>
                            <Route path="/depotEnLigne/dashbord-demandeur" component={DashbordClient}/>
                            
                            <Route path="/depotEnLigne/parametres/structure" component={Structure}/> 
                            <Route path="/depotEnLigne/parametres/entite" component={Entite}/>
                            <Route path="/depotEnLigne/parametres/parametre" component={Parametre}/>
                            <Route path="/depotEnLigne/parametres/parametre-entite" component={EntiteParametre}/>  
                            <Route path="/depotEnLigne/parametres/condition" component={Condition}/>     
                            <Route path="/depotEnLigne/parametres/typeSession" component={TypeSession}/>
                            <Route path="/depotEnLigne/parametres/type-demande" component={TypeDemande}/>
                            <Route path="/depotEnLigne/parametres/session" component={Session}/>
                            <Route path="/depotEnLigne/parametres/categorieDemande" component={CategorieDemande}/>
                            <Route path="/depotEnLigne/parametres/type-critere" component={TypeCritere}/>
                            <Route path="/depotEnLigne/parametres/critere" component={Critere}/>
                            <Route path="/depotEnLigne/depot" component={Depot}/>*/}

                        </div>  
                    </main>
                    <div className="footer">
                        <ul className="liens">
                            <li><a href="">CGU</a></li>
                            <li><a href="">Politique de confidentialité</a></li>
                            <li><a href="">FAQ</a></li>
                        </ul>
                        <p>
                            &copy; 2021 &middot; 
                            MESRSI
                            &middot;Tous droits réservés
                        </p>
                    </div>
                   
                </div>
                 :
                 ""
                 }
            </div>
        </div> 
    )
}
export default DepotEnLigne
