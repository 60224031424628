import { Button } from "primereact/button";
import React, { Component, Fragment, ReactDOM } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Notify } from "../../components/Notify";
import { Spinner } from "../../components/Spinner";
import { ToastContainer } from "react-toastify";
import { JuryService } from "../service/JuryService";
import { SessionExamenService } from "../service/SessionExamenService";
import { EnseignantService } from "../service/EnseignantService";
import { EnseignantJuryService } from "../service/EnseignantJuryService";
import { ExamenService } from "../service/ExamenService";
import { NotationService } from "../service/NotationService";
import { DeliberationService } from "../service/DeliberationService";
import { CursusEtudiantService } from "../service/CursusEtudiantService";
import { CohorteServices } from "../../volumeHoraire/service/CohorteServices";
import { IesrService } from "../service/IesrService";
import { AnneeService } from "../service/AnneeService";
import XLSX from "xlsx";
import { ExportSheet } from "react-xlsx-sheet";
import { jsPDF } from "jspdf";
import { DepartementService } from "../../service/DepartementService";

//const ref = React.createRef();

export class Deliberation extends Component {
    constructor() {
        super();

        this.state = {
            nbAdmis: null,
            sessionList: [],
            curentSession: {},
            examenList: [],
            curentExamen: {},
            notationList: [],
            structure: [],
            curentDeliberation: {
                idDeliberation: null,
                dateDeliberation: null,
                refPV: null,
                datePV: null,
            },
            listeDepartement: [],
            listeEtablissement: [],
            listeIesr: [],
            departement: null,
            etablissement:null,
            iesr: null,
            selectSessionDialog: false,
            notationListDialog: false,
            afficheRachatDialog: false,
            rachatDialog: false,
            moyRachat: null,
            loading: false,
            loadingListe: false,
            loadingPV: false,
            loadingNotation: false,
            loadingSession: false,
            globalFilter: null,
            isValidable: false,
            deliberate: false,
            bilanDeliberation: null,
            consultListDialog: false,
            loadingListeEtudiant: false,
            listeEnConsultation: [],
            listeDonneeExcel: [],
            listePV: [],
            listeEtudiantRachat: [],
            listeEcRachat: [],
            listeTitle: "",
            curenTypeListe: "",
            pvDialog: false,
            table: null,
            telecharger: false,
            structureEntete: {},
            structureEntete: {
                nb_ec: 0,
                liste_ue: [],
            },
            listeMembrePV: [],
            listeSecretaire: [],
            presidentJury: null,

            isDGESup: false,
            isVp: false,
            isEnseignant: false,
            isEtudiant: false,
            isDepartement: false,
            isDirecteur: false,
            isDA: false,
            isMinistere : false,
            verifeDept: false,
            verifeEtab: false,
            verifeMinistere : false,
            loadingListeRachat: false,
            rachatMulticritereDialog: false,
            informationEtudiant: [],
            listeECMulticritere: [],
            pvPDF: null
        };

        this.exportPdf = this.exportPdf.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.cols = [
            { field: "ine", header: "Ine" },
            { field: "nom", header: "Nom" },
            { field: "prenom", header: "Prénom(s)" },
            { field: "sexe", header: "Sexe" },
            { field: "dateNaiss", header: "Date de naiss." },
            { field: "lieuNaissance", header: "Lieu de naiss." },
            { field: 'moyenne', header: 'Moyenne' }
        ];

        this.exportColumns = this.cols.map((col) => ({
            title: col.header,
            dataKey: col.field,
        }));

        this.spinner = new Spinner();
        this.notify = new Notify();
    }

    componentDidMount() {
        const tokenDecoded = localStorage.getItem("tokenDecoded");
        const token = JSON.parse(tokenDecoded);
        const structure = localStorage.getItem("structure");
        let struct = JSON.parse(structure);

        const campusDGESup = localStorage.getItem("campusDGESup");
        const campusVpEip = localStorage.getItem("campusVpEip");
        const campusEnseignant = localStorage.getItem("campusEnseignant");
        const campusEtudiant = localStorage.getItem("campusEtudiant");
        const campusDirecteur = localStorage.getItem("campusDirecteur");
        const campusDa = localStorage.getItem("campusDa");
        const campusMinistere = localStorage.getItem("campusMinistere");
        const campusDepartement = localStorage.getItem("campusDepartement");
        const isDGESup = parseInt(JSON.parse(campusDGESup));
        const isVp = parseInt(JSON.parse(campusVpEip));
        const isEnseignant = parseInt(JSON.parse(campusEnseignant));
        const isEtudiant = parseInt(JSON.parse(campusEtudiant));
        const isDepartement = parseInt(JSON.parse(campusDepartement));
        const isDirecteur = parseInt(JSON.parse(campusDirecteur));
        const isDA = parseInt(JSON.parse(campusDa));
        const isMinistere = parseInt(JSON.parse(campusMinistere));

        this.juryService = new JuryService();
        this.sessionService = new SessionExamenService();
        this.enseignantService = new EnseignantService();
        this.enseignantJuryService = new EnseignantJuryService();
        this.examenService = new ExamenService();
        this.notationService = new NotationService();
        this.deliberationService = new DeliberationService();
        this.cursusEtudiantService = new CursusEtudiantService();
        this.cohorteServices = new CohorteServices();
        this.iesrService = new IesrService();
        this.departementService = new DepartementService();
        this.anneeService = new AnneeService();

        //code_departement
        this.setState({
            loadingSession: true,
            selectSessionDialog: true,
            structure: struct,

            token: token,
            isDepartement: isDepartement,
            isDA: isDA,
            isDirecteur: isDirecteur,
            isVp: isVp,
            isMinistere : isMinistere,
            drGradeId: [4, 3, 12, 5],
        });

        
        if(isMinistere){
            //console.log("yeah!!!")
            this.setState({ verifeMinistere: true });
            this.iesrService.getAll().then((data) => {
                this.setState({ listeIesr: data });
            });
            this.anneeService.getAll().then((data) => {
                this.setState({ listeAnnee: data });
            });
            this.setState({ loadingSession: false});
        }
      

        
        else if (isDepartement) {
            struct.logoSrc =
            "/assets/layout/images/logos/" +
            struct.logoIes;
            this.setState({structure : struct, verifeDept: true });
            this.sessionService
                .getSessionDepartement(token.code_departement)
                .then((data) => {
                    this.setState({
                        sessionList: data.data,
                        loadingSession: false,
                    });
                })
                .catch((error) => {
                    this.notify.echec(error.response.data.message);
                    console.log(error);
                    this.setState({
                        selectSessionDialog: false,
                        loadingSession: false,
                    });
                });

            this.departementService.get(token.code_departement).then((data) => {
                //console.log(data[0])
                this.setState({ departement: data[0], structure: data[0] });
            });
        } else if (isDA || isDirecteur) {
            this.setState({ verifeEtab: true });
            this.cohorteServices
                .getListeDepartementEtab(token.code_etablissement)
                .then((data) => {
                    const depts = data.departementEtabDtos;
                    //console.log(depts)
                    this.setState({
                        listeDepartement: depts,
                        loadingSession: false,
                    });
                })
                .catch((error) => {
                    this.notify.echec(error.response.data.message);
                    console.log(error);
                    this.setState({
                        selectSessionDialog: false,
                        loadingSession: false,
                    });
                });
        }
    }

    openNew = () => {
        this.setState({
            selectSessionDialog: true,
        });
    };

    listeNoteExamen = (rowData) => {
        let idExamen = rowData.idExamen;

        this.setState({
            notationListDialog: true,
            loadingNotation: true,
        });

        this.notationService
            .getListNotation(parseInt(idExamen))
            .then((data) => {
                this.setState({
                    notationList: data,
                    curentExamen: rowData,
                    loadingNotation: false,
                });
            })
            .catch((error) => {
                console.log(error);
                this.notify.echec(error.response.data.message);

                this.setState({
                    loadingNotation: false,
                    notationListDialog: false,
                });
            });
    };

    selectSession = (rowData) => {
        let idSession = rowData.idSession;
        //On recupère la liste des évaluations de la session
        this.examenService
            .examenBySession(parseInt(idSession))
            .then((data) => {
                //console.log(data)
                let date;
                let dateFr;
                data.map((d) => {
                    date = new Date(d.dateExamen);
                    dateFr = date.toLocaleDateString("fr");
                    d.dateExamen = dateFr;
                    //console.log(d.dateExamen)
                });
                //console.log(examens)
                this.setState({
                    examenList: data,
                    selectSessionDialog: false,
                    curentSession: rowData,
                });
            })
            .catch((error) => {
                console.log(error);
            });

        //On verifie si ça déjà été délibéré
        this.deliberationService
            .getDeliberationBySession(parseInt(idSession))
            .then((data) => {
                let deliberate = data.length > 0 ? true : false;
                this.setState({
                    deliberate: deliberate,
                    curentDeliberation: data[0],
                });
            })
            .catch((error) => {
                console.log(error);
                this.notify.echec(error.response.data.message);
                this.setState({
                    deliberate: false,
                });
            });
    };
    /*convertImageToBase64(imgUrl) {
        const image = new Image();
        //image.crossOrigin='anonymous';
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.height = image.naturalHeight;
          canvas.width = image.naturalWidth;
          ctx.drawImage(image, 0, 0);
          const dataUrl = canvas.toDataURL();
          
        }
        image.src = imgUrl;
        return image
        
      }*/
    paginationPage = (doc) => {
        const pageCount = doc.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.text(
                "" + String(i) + "/" + String(pageCount),
                290,
                200,
                null,
                null,
                "right"
            );
        }
    };


    genererPdf = () => {
        /*
        const doc = new jsPDF("l", "pt", [6000, 3370.39]);
        let titre = this.state.listeTitle;
        doc.html(document.querySelector("#pvpdf"), {
            callback: function (pdf) {
                pdf.save(titre + ".pdf");
            },
        });*/

        var blob = new Blob([this.state.pvPDF], { type: "application/pdf" });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        var fileName = this.state.listeTitle;
        link.download = fileName;
        link.click();

    };



    exportPdf() {

        import("jspdf").then((jsPDF) => {
            import("jspdf-autotable").then(() => {
                const doc = new jsPDF.default("l");
                const image = new Image();
                let logo = this.state.departement.logoIes;   
                logo = logo.toLowerCase()          
                console.log(logo)
                image.src =
                    "/assets/layout/images/logos/" + logo;
                doc.setFontSize(12);
                doc.text("Ministère de l'Enseignement Supérieur,", 10, 10);
                doc.text("de la Recherche et de l'Innovation", 10, 15);
                doc.text("----------------------------------", 10, 20);
                doc.text("Secrétariat Général", 10, 25);
                doc.text("----------------------------------", 10, 30);
                doc.text(this.state.departement.nomIes, 10, 35);
                doc.text("----------------------------------", 10, 40);
                doc.text(this.state.departement.nomEtablissement, 10, 45);
                doc.text("----------------------------------", 10, 50);
                doc.text(this.state.departement.lbDepartementEtab, 10, 55);
                doc.addImage(image, "PNG", 130, 10, 50, 56);

                doc.text("BURKINA - FASO", 230, 10);
                doc.setFontSize(10);
                doc.text("Unité - Progrès - Justice", 232, 16);
                doc.setFontSize(14);
                doc.text(this.state.listeTitle, 10, 80);
                const nbr = this.state.listeEnConsultation.length;
                doc.autoTable(
                    this.exportColumns,
                    this.state.listeEnConsultation,
                    {
                        startY: doc.autoTable({ margin: { top: 82 } }),
                        /*margin: { horizontal: 10 },
                    styles: { overflow: "linebreak" },
                    bodyStyles: { valign: "top" },
                    columnStyles: { email: { columnWidth: "wrap" } },
                    theme: "striped",*/
                        //showHead: "everyPage",

                        didDrawPage: function (data) {
                            // Header
                            doc.setFontSize(10);
                            doc.setTextColor(40);

                            // Footer
                            const str =
                                "Page " + doc.internal.getNumberOfPages();
                            doc.setFontSize(10);

                            const pageCount = doc.getNumberOfPages();

                            // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                            const pageSize = doc.internal.pageSize;
                            const pageHeight = pageSize.height
                                ? pageSize.height
                                : pageSize.getHeight();

                            /*for(let i = 1; i<=pageCount; i++){
                            doc.setPage(i)
                            doc.text('' + String(i) + '/' + String(pageCount), 290,200,null,null,"right")
                        }*/
                            //doc.text(str, data.settings.margin.left, pageHeight - 10);

                            doc.text(
                                "Arrêté la présente liste à " + nbr + " noms",
                                pageHeight,
                                pageHeight - 10
                            );
                        },
                    }
                );
                this.paginationPage(doc);
                doc.save(this.state.listeTitle + ".pdf");
            });
        });
    }

    exportExcel() {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(
                this.state.listeDonneeExcel
            );
            const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ["data"],
            };
            const excelBuffer = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            this.saveAsExcelFile(excelBuffer, this.state.listeTitle);
        });
    }
    saveAsExcelFile(buffer, fileName) {
        import("file-saver").then((module) => {
            let EXCEL_TYPE =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            let EXCEL_EXTENSION = ".xlsx";
            const data = new Blob([buffer], {
                type: EXCEL_TYPE,
            });
            module.default.saveAs(
                data,
                fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
            );
        });
    }

    actionSelectSessionTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-check"
                    className="p-button-success"
                    onClick={() => this.selectSession(rowData)}
                    style={{ marginRight: ".5em" }}
                    title="Selectioner la session"
                />
            </React.Fragment>
        );
    };

    actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-list"
                    className="p-button-warning"
                    style={{ marginRight: ".5em" }}
                    title="Liste des notes"
                    onClick={() => this.listeNoteExamen(rowData)}
                />
            </React.Fragment>
        );
    };
    actionSelectEtudiantTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-check"
                    className="p-button-success"
                    onClick={() => this.selectEtudiant(rowData)}
                    style={{ marginRight: ".5em" }}
                    title="Selectioner l'etudiant"
                />
            </React.Fragment>
        );
    };
    actionValiderRachatTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-check"
                    className="p-button-success"
                    onClick={() => this.validerRachat(rowData)}
                    style={{ marginRight: ".5em" }}
                    title="Valider rachat"
                />
            </React.Fragment>
        );
    };

    hideSelectSessionDialog = () => {
        this.setState({
            selectSessionDialog: false,
            pvPDF: null
        });
    };

    hideListNotationDialog = () => {
        this.setState({
            notationListDialog: false,
        });
    };

    hideListEtudiantDialog = () => {
        this.setState({
            consultListDialog: false,
        });
    };

    hideListPvDialog = () => {
        this.setState({
            pvDialog: false,
            
        });
    };

    startDeliberation = (idSession) => {
        this.setState({
            loading: true,
        });
        this.deliberationService
            .doDeliberation(parseInt(idSession))
            .then((data) => {
                this.setState({
                    deliberate: true,
                    curentDeliberation: data,
                    loading: false,
                });
            })
            .catch((error) => {
                console.log(error)
                this.notify.echec(error.response.data.message);
                this.setState({
                    loading: false,
                });
            });
    };

    activerReclamation = (idSession) => {
        this.deliberationService
            .activerEdition(idSession)
            .then((data) => {
                this.notify.success("Edition des notes activée avec succès !");
            })
            .catch((error) => {
                console.log(error);
                this.notify.echec(error.response.data.message);
            });
    };

    listePV = () => {
        let title = `PV de délibération: ${this.state.curentSession.libelleSession}`;
        this.setState({
            loadingPV: true,
            pvDialog: true,
            listeTitle: title,
        });


        
        this.deliberationService.getPVPDFFromBackend(this.state.curentSession.idSession, this.state.departement.idDepartementEtab, 1).then(data => {
            this.setState({
                pvPDF: data.data
            })
        }).catch(error => {
            console.log(error)
            this.notify.echec(error.response.data.message)
        })
        
        
        //On recupère les informations necessaires a construire l'entête du PV
        this.deliberationService
            .getPVDeliberation(this.state.curentSession.idSession, 1)
            .then((data) => {
                //On recupère le premier élement vue que l'ensemble est ordonnées coté backend suivant le meme principe
                let etudiantPV = data.listeEtudiantPv[0];

                //console.log(etudiantPV)

                let listeMembrePV = data.listeMembre;
                let listeSecretaire = data.listeSecretaire;
                let presidentJury = data.presidentJury;

                this.setState({
                    listeMembrePV: listeMembrePV,
                    listeSecretaire: listeSecretaire,
                    presidentJury: presidentJury,
                });

                //On defini une entête pour recuillir les informations de la structure de l'entete
                let struct_ent = {};

                struct_ent["nom"] = "Nom";
                struct_ent["prenom"] = "Prenom(s)";
                struct_ent["dateNaissance"] = "Date de naissance";
                struct_ent["lieuNaissance"] = "Lieu de naissance";
                struct_ent["matricule"] = "Matricule";

                //Element permettant de definir l'arborescence de UEs et ECs
                let nb_ue = 0;
                let listeEntUE = [];

                etudiantPV.listeUe.map((ue) => {
                    let structureEnteteUE = {};

                    structureEnteteUE["code_ue"] =
                        ue.libelleUniteEnseignement +
                        " (" +
                        ue.codeUniteEnseignement +
                        ")";
                    structureEnteteUE["id_ue"] = ue.idUniteEnseignement;
                    structureEnteteUE["credit_ue"] =
                        "Moyenne UE (crédit:" +
                        ue.creditUniteEnseignement +
                        ")";
                    structureEnteteUE["moyenne_ue"] = ue.moyenneUe;
                    structureEnteteUE["moyenne_pondere"] = "Moyenne Pondéré";
                    structureEnteteUE["nb_ec"] = ue.listeEc.length;

                    let listeECEnt = [];
                    if (ue.listeEc.length !== 0) {
                        let nb_ec = 0;
                        ue.listeEc.map((ec) => {
                            let structureEntEC = {};
                            structureEntEC["id_ec"] = ec.idElementConstitutif;
                            structureEntEC["code_ec"] =
                                ec.codeElementConstitutif;
                            structureEntEC["libelle_ec"] =
                                ec.libelleElementConstitutif;
                            listeECEnt[nb_ec] = structureEntEC;
                            nb_ec++;
                        });
                    }
                    structureEnteteUE["liste_ec"] = listeECEnt;
                    listeEntUE[nb_ue] = structureEnteteUE;
                    nb_ue++;
                });
                struct_ent["liste_ue"] = listeEntUE;

                //Structuration des données pour être en conformité avec l'entête
                let donnees = [];
                let nb_data = 0;
                data.listeEtudiantPv.map((etudiantPV) => {
                    let struct_data = {};

                    struct_data["nom"] = etudiantPV.nom;
                    struct_data["prenom"] = etudiantPV.prenom;
                    struct_data["dateNaissance"] = etudiantPV.dateNaissance;
                    struct_data["lieuNaissance"] = etudiantPV.lieuNaissance;
                    struct_data["matricule"] = etudiantPV.ine;

                    //Compteur des moyennes ajoutés dans la structure de données
                    let nb_moyenne = 0;

                    //Tableau devant contenir la liste des moyennes
                    let listeMoyenne = [];
                    etudiantPV.listeUe.map((ue) => {
                        //Le traitement n'est fait que quand il y'a des EC dans l'UE
                        if (ue.listeEc.length !== 0) {
                            //On place les moyennes des EC dans l'ordre dans lequel ils sont definis au niveau de l'entête
                            ue.listeEc.map((ec) => {
                                listeMoyenne[nb_moyenne] = {
                                    moyenne: ec.moyenneEc,
                                };
                                nb_moyenne++;
                            });

                            //A la suite des EC, on fait preceder directement le pondere et la moyenne de l'UE
                            listeMoyenne[nb_moyenne] = {
                                moyenne: ue.moyenneUe,
                            };
                            nb_moyenne++;
                            listeMoyenne[nb_moyenne] = {
                                moyenne: ue.pondereUe,
                            };
                            nb_moyenne++;
                        }
                    });

                    //A la fin de tous, on fait passer le total pondere
                    listeMoyenne[nb_moyenne] = {
                        moyenne: etudiantPV.cursusEtudiant.pondereTotal,
                    };
                    nb_moyenne++;
                    //On fait passer la moyenne obtenue par l'étudiant
                    listeMoyenne[nb_moyenne] = {
                        moyenne: etudiantPV.cursusEtudiant.moyenne,
                    };
                    nb_moyenne++;

                    //Total des credits acquis si à afficher
                    listeMoyenne[nb_moyenne] = {
                        moyenne: etudiantPV.cursusEtudiant.creditTotalAcquis,
                    };
                    nb_moyenne++;

                    listeMoyenne[nb_moyenne] = {
                        moyenne: etudiantPV.cursusEtudiant.cote,
                    };
                    //La decision Admis ou Ajourné, pour une harmonisation il s'intitulera moyenne aussi
                    listeMoyenne[nb_moyenne + 1] = {
                        moyenne:
                            etudiantPV.cursusEtudiant.resultat === 1
                                ? "Validé"
                                : "Non validé",
                    };

                    //On ajoute les moyennes à la structure de données contenant déjà les autres informations (nom, prenom etc)
                    struct_data["listeMoyenne"] = listeMoyenne;

                    //On insert le tous dans la structure de données
                    donnees[nb_data] = struct_data;
                    nb_data++;
                });

                this.setState({
                    listePV: donnees,
                    structureEntete: struct_ent,
                    loadingPV: false,
                });
            })
            .catch((error) => {
                console.log(error);
                this.notify.echec(error.response.data.message);

                this.setState({
                    pvDialog: false,
                    loadingPV: false,
                });
            });
    };

    listeDeliberation = (idDeliberation, typeOperation) => {
        this.cursusEtudiantService
            .getAllStudent(parseInt(idDeliberation), typeOperation)
            .then((data) => {
                let title =
                    typeOperation === "admis"
                        ? `Liste des admis de la session: ${this.state.curentSession.libelleSession} (${data.length} admis)  `
                        : `Liste des ajournés de la session : ${this.state.curentSession.libelleSession} (${data.length} ajournés)`;
                this.setState({
                    consultListDialog: true,
                    listeTitle: title,
                    loadingListe: true,
                });
                let donneeExentiel = data.map((d) => ({
                    Ine: d.ine,
                    Nom: d.nom,
                    "Prénom(s)": d.prenom,
                    Sexe: d.sexe,
                    "Date de naiss.": d.dateNaiss,
                    "Lieu de naiss.": d.lieuNaissance,
                    "Moyenne": d.moyenne
                }));
                console.log(data)
                //console.log(donneeExentiel)
                this.setState({
                    listeEnConsultation: data,
                    nbAdmis: data.length,
                    listeDonneeExcel: donneeExentiel,
                    curenTypeListe: typeOperation,
                    loadingListe: false,
                });
            })
            .catch((error) => {
                console.log(error);
                this.notify.echec(error.response.data.message);
                this.setState({
                    loadingListe: false,
                    consultListDialog: false,
                });
            });
    };

    cloturerSession = () => {
        this.sessionService
            .cloturerSession(this.state.curentSession.idSession)
            .then((result) => {
                this.notify.success();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    dowloadListe = () => {
        this.setState({
            table: document.querySelector("#sheetjs"),
            telecharger: true,
        });
    };
    rachat = () => {
        this.setState({ afficheRachatDialog: true });
    };

    hideRachatDialog = () => {
        this.setState({
            afficheRachatDialog: false,
            loadingListeEtudiant: false,
            moyRachat: null,
        });
    };
    onValueChange = (e, field) => {
        const val = (e.target && e.target.value) || "";
        let moyRachat = this.state.moyRachat;
        moyRachat = val;
        this.setState({ moyRachat });
    };
    loadListeEtudiant = (idDeliberation, moyRachat) => {
        this.setState({ loadingRachat: true });
        this.deliberationService
            .getListeEudiantRachat(idDeliberation, moyRachat)
            .then((data) => {
                this.setState({
                    loadingListeEtudiant: true,
                    listeEtudiantRachat: data,
                    loadingRachat: false,
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loadingRachat: false });
            });
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////
    selectEtudiant = (etudiant) => {
        let idDeliberation = this.state.curentDeliberation.idDeliberation;
        let idEtudiant = etudiant.idEtudiant;

        this.setState({
            rachatDialog: true,
            loadingListeRachat: true,
            informationEtudiant: [etudiant],
        });

        this.deliberationService
            .getListeEcRachat(idDeliberation, idEtudiant)
            .then((data) => {
                this.setState({
                    listeEcRachat: data,
                    loadingListeRachat: false,
                });
            })
            .catch((error) => {
                console.log(error);
                this.notify.echec(error.response.data.message);
            });
    };

    racatECMultiple = () => {
        this.setState({
            rachatMulticritereDialog: true,
        });
    };

    hideRachatEtudiantDialog = () => {
        this.setState({
            rachatDialog: false,
            informationEtudiant: [],
        });
    };

    hideRachatMulticritereDialog = () => {
        this.setState({
            rachatMulticritereDialog: false,
            listeECMulticritere: [],
        });
    };

    editNote = (props) => {
        return this.inputNoteEditor(props);
    };

    inputNoteEditor = (props) => {
        return (
            <input
                type="number"
                value={props.rowData.noteRachatManuel}
                onChange={(e) =>
                    this.onNoteChange(e.target.value, props.rowIndex)
                }
                placeholder="Note sur 20"
                className="p-inputtext p-component"
                step="0.01"
                max="20"
                min="0"
            />
        );
    };

    onNoteChange = (value, count) => {
        let curentNotations = this.state.listeEcRachat;

        if (
            !isNaN(value) &&
            parseFloat(value) >= 0 &&
            parseFloat(value) <= 20
        ) {
            curentNotations[count].noteRachatManuel = parseFloat(value);
        } else if (value === "") {
            curentNotations[count].noteRachatManuel = 0;
        }

        this.setState({
            listeEcRachat: curentNotations,
        });
    };

    validerRachat = (rachat) => {
        //console.log(rachat)
        this.deliberationService
            .validationRachat(rachat)
            .then((data) => {
                this.setState({
                    rachatDialog: false,
                    informationEtudiant: [],
                });
                this.deliberationService
                    .getListeEudiantRachat(
                        this.state.curentDeliberation.idDeliberation,
                        this.state.moyRachat
                    )
                    .then((data) => {
                        this.setState({
                            loadingListeEtudiant: true,
                            listeEtudiantRachat: data,
                            loadingRachat: false,
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({ loadingRachat: false });
                    });
                this.notify.success();
            })
            .catch((error) => {
                this.notify.echec(error.response.data.message);
                console.log(error);
            });
    };

    validationRachatMulticritere = () => {
        let curentNotations = this.state.listeEcRachat;

        this.deliberationService
            .validationRachatManuel(curentNotations)
            .then((data) => {
                this.hideRachatMulticritereDialog();
                this.hideRachatEtudiantDialog();
                this.setState({
                    loadingRachat: true,
                });

                this.deliberationService
                    .getListeEudiantRachat(
                        this.state.curentDeliberation.idDeliberation,
                        this.state.moyRachat
                    )
                    .then((data) => {
                        this.setState({
                            loadingListeEtudiant: true,
                            listeEtudiantRachat: data,
                            loadingRachat: false,
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({ loadingRachat: false });
                    });
                this.notify.success();
            })
            .catch((error) => {
                this.notify.echec(error.response.data.message);
                console.log(error);
            });
    };

    onValueChangeMinistere = (e, field) => {
        const val = (e.target && e.target.value) || "";
        if (field === "iesr") {
            let iesr = this.state.iesr;
            iesr = val;
            this.setState({ iesr });
            this.loadEtabMinistere(iesr.idIesr);
        } else if(field === 'ufr'){
            let etablissement = this.state.etablissement;
            etablissement = val;
            this.setState({ etablissement });
            this.loadDepMinistere(etablissement.idEtablissement);
        }
        else if (field === "annee") {
            let annee = this.state.annee;
            annee = val;
            this.setState({ annee });
        } else if (field === "semestre") {
            let semestre = this.state.semestre;
            semestre = val;
            this.setState({ semestre });
        } else if (field === "departement") {
            let departement = this.state.departement;
            departement = val;
            let struct = val;
            struct.logoSrc =
            "/assets/layout/images/logos/" +
            struct.logoIes;

            this.setState({ departement : departement, structure : struct });
        } else {
            console.log("marque de success !!!");
        }
    }

    onDepartementChange = (e) => {
        const val = (e.target && e.target.value) || "";
        this.setState({ departement: val });
        this.setState({ loading: true });
        let struct = val;
        struct.logoSrc =
            "/assets/layout/images/logos/" +
            struct.logoIes;
        this.sessionService
            .getSessionDepartement(val.idDepartementEtab)
            .then((data) => {
                //On filtre uniquement les sessions qui sont ouvert
                let listeSessionOuvert = data.data.filter((curentSession) => {
                    return curentSession.etatSession === "OUVERT";
                });
                this.setState({
                    sessionList: listeSessionOuvert,
                    structure : struct,
                    loading: false,
                });
            })
            .catch((error) => {
                console.log(error);
                this.notify.echec(error.response.data.message);
                this.setState({ loading: false });
            });
    };

    loadEtabMinistere = (idIesr) => {
        this.cohorteServices.getListeEtabUniv(idIesr).then(data => {
            //console.log(data)
            this.setState({listeEtablissement : data})
        })
    }
    loadDepMinistere = (idEtab) => {
        this.cohorteServices.getListeDepartementEtab(idEtab).then(data => {
            //console.log(data)
            this.setState({listeDepartement : data.departementEtabDtos})
        })
    }

    listeSessionMinistere = () => {
        this.setState({loadingSession : true})
        this.sessionService.getSessionDepartement(this.state.departement.idDepartementEtab).then(data => {
            let listeSessionOuvert = data.data.filter((curentSession) => {
                return curentSession.etatSession === "OUVERT";
            });
            this.setState({
                sessionList: listeSessionOuvert,
                loadingSession: false,
            });
        })
        .catch((error) => {
            console.log(error);
            this.notify.echec(error.response.data.message);
            this.setState({ loadingSession: false });
        });
        
    }

    render() {
        let actionHeader = <Button type="button" icon="pi pi-cog" />;

        const header = (
            <div className="table-header p-col-2">
                <span className="p-input-icon-left">
                    <InputText
                        type="search"
                        onInput={(e) =>
                            this.setState({ globalFilter: e.target.value })
                        }
                        placeholder="Recherche globale..."
                    />
                </span>
            </div>
        );

        const selectSessionFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideSelectSessionDialog}
                />
            </React.Fragment>
        );

        const listNotationFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideListNotationDialog}
                />
            </React.Fragment>
        );

        const listEtudiantFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideListEtudiantDialog}
                />
            </React.Fragment>
        );

        const listPvFooter = (
            <React.Fragment>
                <hr />
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideListPvDialog}
                />
            </React.Fragment>
        );
        const rachatFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideRachatDialog}
                />
            </React.Fragment>
        );
        const rachatDialogFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideRachatEtudiantDialog}
                />
            </React.Fragment>
        );

        const rachatMulticritereDialogFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideRachatMulticritereDialog}
                />
                <Button
                    label="Valider"
                    icon="pi pi-check"
                    className="p-button-text"
                    onClick={this.validationRachatMulticritere}
                />
            </React.Fragment>
        );

        const titreDatatable =
            this.state.examenList.length > 0 &&
            this.state.curentSession.idSession
                ? `Liste des examens de: ${this.state.curentSession.libelleSession}`
                : "Aucun examen à afficher";
        const titreNotation = this.state.curentExamen.idExamen
            ? `Liste des notes : ${this.state.curentExamen.libelleExamen} | ( ${this.state.notationList.length} etudiants)`
            : "Aucune note à afficher";
        const titreNotationDialog = this.state.curentExamen.idExamen
            ? `Liste des notes : ${this.state.curentExamen.libelleExamen} | ( ${this.state.notationList.length} etudiants)`
            : "Liste de notation";

        let actionDeliberation =
            !this.state.deliberate && this.state.curentSession.idSession ? (
                <div className="p-col-2">
                    <Button
                        label="Lancer la délibération"
                        icon="pi pi-check-circle"
                        className="p-button-success p-mr-2"
                        onClick={() =>
                            this.startDeliberation(
                                this.state.curentSession.idSession
                            )
                        }
                    />
                </div>
            ) : (
                this.state.curentSession.idSession &&
                this.state.deliberate && (
                    <React.Fragment>
                        <div className="row" style={{ marginTop: 8 }}>
                            <Button
                                className="p-col-3 p-button-success p-mr-2"
                                style={{ marginLeft: 5, marginBottom: 5 }}
                                label="PV"
                                icon="pi pi-list"
                                onClick={() => this.listePV()}
                            />

                            <Button
                                style={{ marginLeft: 5, marginBottom: 5 }}
                                className="p-col-4 p-button-success p-mr-2"
                                label="Admis"
                                icon="pi pi-list"
                                onClick={() =>
                                    this.listeDeliberation(
                                        this.state.curentDeliberation
                                            .idDeliberation,
                                        "admis"
                                    )
                                }
                            />

                            <Button
                                style={{ marginLeft: 5, marginBottom: 5 }}
                                className="p-col-4 p-button-success p-mr-2"
                                label="Ajournées"
                                icon="pi pi-list"
                                onClick={() =>
                                    this.listeDeliberation(
                                        this.state.curentDeliberation
                                            .idDeliberation,
                                        "ajourne"
                                    )
                                }
                            />
                            {this.state.verifeDept ? (
                                <>
                                    <Button
                                        style={{ marginLeft: 5 }}
                                        className="p-col-4 p-button-warning p-mr-2"
                                        label="Gestion du rachat"
                                        icon="pi pi-pencil"
                                        onClick={() => this.rachat()}
                                    />
                                    <Button
                                        style={{ marginLeft: 5 }}
                                        label="Relancer la délibération"
                                        icon="pi pi-check-circle"
                                        className="p-button-success p-mr-2"
                                        onClick={() =>
                                            this.startDeliberation(
                                                this.state.curentSession
                                                    .idSession
                                            )
                                        }
                                    />
                                    <Button
                                        style={{ marginLeft: 5 }}
                                        label="Activer reclammation"
                                        icon="pi pi-check-circle"
                                        className="p-button-success p-mr-2"
                                        onClick={() =>
                                            this.activerReclamation(
                                                this.state.curentSession
                                                    .idSession
                                            )
                                        }
                                    />
                                </>
                            ) : (
                                ""
                            )}

                            {/*<Button style={{marginLeft : 5}} className="p-col-4" label="Cloturer session" icon="pi pi-times-circle" className="p-button-warning p-mr-2" onClick={() => this.cloturerSession()} /> */}
                        </div>
                    </React.Fragment>
                )
            );

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid ">
                        <div className="p-col-2" style={{ marginLeft: 20 }}>
                            <Button
                                label="Autre session"
                                icon="pi pi-plus"
                                className="p-button-success"
                                onClick={this.openNew}
                            />
                        </div>

                        {this.state.verifeDept ? actionDeliberation : ""}
                        {this.state.verifeEtab ? actionDeliberation : ""}
                        {this.state.verifeMinistere ? actionDeliberation : ""}
                    </div>

                    <div className="card card-w-title datatable-demo">
                        <ToastContainer
                            position="top-right"
                            autoClose={2000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                        <ToastContainer />

                        {this.state.loading ? (
                            <div className="p-grid">
                                <div className="p-col-6"></div>
                                <div className="p-col-6">
                                    {this.spinner.loadingSpinner(
                                        "spin",
                                        "black"
                                    )}
                                </div>
                            </div>
                        ) : (
                            <React.Fragment>
                                {this.state.deliberate ? (
                                    <React.Fragment>
                                        <h2>
                                            Bilan de la délibération{" "}
                                            {`${this.state.curentSession.libelleSession}`}
                                        </h2>
                                        <table className="table table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">
                                                        Nombre d'étudiant{" "}
                                                    </th>
                                                    <th scope="col">
                                                        Nombre d'admis
                                                    </th>
                                                    <th scope="col">
                                                        Nombre d'ajournés
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {
                                                            this.state
                                                                .curentDeliberation
                                                                .effectifTotal
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            this.state
                                                                .curentDeliberation
                                                                .effectifReussite
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            this.state
                                                                .curentDeliberation
                                                                .effectifEchec
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <hr />
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <h2 style={{ color: "red" }}>
                                            En attente de déliberation pour la
                                            session :{" "}
                                            {`${
                                                this.state.curentSession
                                                    .libelleSession || ""
                                            }`}
                                        </h2>
                                        <hr />
                                    </React.Fragment>
                                )}

                                {header}
                                <DataTable
                                    ref={(el) => (this.dt = el)}
                                    value={this.state.examenList}
                                    selectionMode="single"
                                    header={titreDatatable}
                                    paginator={true}
                                    rows={10}
                                    globalFilter={this.state.globalFilter}
                                    responsive={true}
                                    selection={this.state.dataTableSelection1}
                                    onSelectionChange={(event) =>
                                        this.setState({
                                            dataTableSelection1: event.value,
                                        })
                                    }
                                >
                                    <Column
                                        field="elementConstitutif.libelleElementConstitutif"
                                        header="Element constitutif"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="intitulé de l'élement constitutif"
                                    />
                                    <Column
                                        field="libelleExamen"
                                        header="Libellé examen"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="rechercher libellé"
                                    />
                                    <Column
                                        field={"dateExamen"}
                                        header="Date examen"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="date examen"
                                    />
                                    <Column
                                        field="observationExamen"
                                        header="Observation"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="observation"
                                    />
                                    <Column
                                        header={actionHeader}
                                        body={this.actionBodyTemplate}
                                        style={{
                                            textAlign: "center",
                                            width: "8em",
                                        }}
                                    />
                                </DataTable>
                            </React.Fragment>
                        )}
                    </div>
                </div>

                <Dialog
                    visible={this.state.selectSessionDialog}
                    style={{ width: "95%" }}
                    modal
                    className="p-fluid"
                    footer={selectSessionFooter}
                    onHide={this.hideSelectSessionDialog}
                >
                    {this.state.loadingSession ? (
                        <div className="p-grid">
                            <div className="p-col-6"></div>
                            <div className="p-col-6">
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                    ) : this.state.verifeDept ? (
                        <>
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.state.sessionList}
                                selectionMode="single"
                                header="Session disponible à la délibération"
                                paginator={true}
                                rows={5}
                                globalFilter={this.state.globalFilter}
                                responsive={true}
                                selection={this.state.dataTableSelection1}
                                onSelectionChange={(event) =>
                                    this.setState({
                                        dataTableSelection1: event.value,
                                    })
                                }
                            >
                                <Column
                                    field="parcoursFiliere.parcours.libelleParcours"
                                    header="Parcours"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="rechercher par parcours"
                                />
                                <Column
                                    field="anneeSemestre.libelleSemestre"
                                    header="Semestre"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="recherche par semestre"
                                />
                                <Column
                                    field="anneeSemestre.libelleAnneeAcademique"
                                    header="Année académique"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="recherche par année académique"
                                />
                                <Column
                                    field="typeSession.libelleTypeSession"
                                    header="Type session"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="recherche par type session"
                                />
                                <Column
                                    header={actionHeader}
                                    body={this.actionSelectSessionTemplate}
                                    style={{
                                        textAlign: "center",
                                        width: "8em",
                                    }}
                                />
                            </DataTable>
                        </>
                    ) : this.state.verifeEtab ? (
                        <>
                            <div className="form-grid">
                                <div className="p-col-3">
                                    <Dropdown
                                        optionLabel="lbDepartementEtab"
                                        value={this.state.departement}
                                        options={this.state.listeDepartement}
                                        onChange={(e) => {
                                            this.onDepartementChange(e);
                                        }}
                                        required
                                        autoFocus
                                        placeholder="Selectionner un departement"
                                    />
                                </div>
                            </div>
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.state.sessionList}
                                selectionMode="single"
                                header="Session disponible à la délibération"
                                paginator={true}
                                rows={5}
                                globalFilter={this.state.globalFilter}
                                responsive={true}
                                selection={this.state.dataTableSelection1}
                                onSelectionChange={(event) =>
                                    this.setState({
                                        dataTableSelection1: event.value,
                                    })
                                }
                            >
                                <Column
                                    field="parcoursFiliere.parcours.libelleParcours"
                                    header="Parcours"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="rechercher par parcours"
                                />
                                <Column
                                    field="anneeSemestre.libelleSemestre"
                                    header="Semestre"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="recherche par semestre"
                                />
                                <Column
                                    field="anneeSemestre.libelleAnneeAcademique"
                                    header="Année académique"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="recherche par année académique"
                                />
                                <Column
                                    field="typeSession.libelleTypeSession"
                                    header="Type session"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="recherche par type session"
                                />
                                <Column
                                    header={actionHeader}
                                    body={this.actionSelectSessionTemplate}
                                    style={{
                                        textAlign: "center",
                                        width: "8em",
                                    }}
                                />
                            </DataTable>
                        </>
                    ) : this.state.verifeMinistere ? (
                        <>
                            <div className="p-grid p-col-12">
                                    <div className="p-col-2">
                                        <Dropdown
                                            optionLabel="libelleIesr"
                                            value={this.state.iesr}
                                            options={
                                                this.state.listeIesr
                                            }
                                            onChange={(e) => {
                                                this.onValueChangeMinistere(e, 'iesr');
                                            }}
                                            required
                                            autoFocus
                                            placeholder="Selectionner un IESR"
                                        />
                                    </div>
                                    <div className="p-col-2">
                                        <Dropdown
                                            optionLabel="nom"
                                            value={this.state.etablissement}
                                            options={
                                                this.state.listeEtablissement
                                            }
                                            onChange={(e) => {
                                                this.onValueChangeMinistere(e, 'ufr');
                                            }}
                                            required
                                            autoFocus
                                            placeholder="Selectionner un établissement"
                                        />
                                    </div>
                                    <div className="p-col-3">
                                        <Dropdown
                                            optionLabel="lbDepartementEtab"
                                            value={this.state.departement}
                                            options={
                                                this.state.listeDepartement
                                            }
                                            onChange={(e) => {
                                                this.onValueChangeMinistere(e, 'departement');
                                            }}
                                            required
                                            autoFocus
                                            placeholder="Selectionner un departement"
                                        />
                                    </div>
                                    <div className="p-col-2">
                                        <Dropdown
                                            optionLabel="libelleAnnee"
                                            optionValue="idAnneeAcademique"
                                            value={this.state.annee}
                                            options={this.state.listeAnnee}
                                            onChange={(e) => {
                                                this.onValueChangeMinistere(e, "annee");
                                            }}
                                            required
                                            autoFocus
                                            className={classNames({
                                                "p-invalid":
                                                    this.state.submitted,
                                            })}
                                            placeholder="Année académique"
                                        />
                                        
                                    </div>
                                    <div className="p-col-2">
                                        <Button
                                            label="Liste session"
                                            onClick={() => {
                                                this.listeSessionMinistere();
                                            }}
                                            className="p-button-text"
                                        />
                                    </div>
                                </div>
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.state.sessionList}
                                selectionMode="single"
                                header="Session disponible à la délibération"
                                paginator={true}
                                rows={5}
                                globalFilter={this.state.globalFilter}
                                responsive={true}
                                selection={this.state.dataTableSelection1}
                                onSelectionChange={(event) =>
                                    this.setState({
                                        dataTableSelection1: event.value,
                                    })
                                }
                            >
                                <Column
                                    field="parcoursFiliere.parcours.libelleParcours"
                                    header="Parcours"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="rechercher par parcours"
                                />
                                <Column
                                    field="anneeSemestre.libelleSemestre"
                                    header="Semestre"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="recherche par semestre"
                                />
                                <Column
                                    field="anneeSemestre.libelleAnneeAcademique"
                                    header="Année académique"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="recherche par année académique"
                                />
                                <Column
                                    field="typeSession.libelleTypeSession"
                                    header="Type session"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="recherche par type session"
                                />
                                <Column
                                    header={actionHeader}
                                    body={this.actionSelectSessionTemplate}
                                    style={{
                                        textAlign: "center",
                                        width: "8em",
                                    }}
                                />
                            </DataTable>
                        </>
                    ) :
                    
                    (
                        ""
                    )}
                </Dialog>

                <Dialog
                    visible={this.state.notationListDialog}
                    style={{ width: "95%" }}
                    modal
                    className="p-fluid"
                    header={titreNotationDialog}
                    footer={listNotationFooter}
                    onHide={this.hideListNotationDialog}
                >
                    {this.state.loadingNotation ? (
                        <div className="p-grid">
                            <div className="p-col-6"></div>
                            <div className="p-col-6">
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                    ) : (
                        <DataTable
                            ref={(el) => (this.dt = el)}
                            value={this.state.notationList}
                            selectionMode="single"
                            header={titreNotation}
                            paginator={true}
                            rows={10000}
                            globalFilter={this.state.globalFilter}
                            responsive={true}
                            selection={this.state.dataTableSelection1}
                            onSelectionChange={(event) =>
                                this.setState({
                                    dataTableSelection1: event.value,
                                })
                            }
                        >
                            <Column
                                field="etudiant.ine"
                                header="INE Etudiant"
                                sortable={true}
                                filter={true}
                                filterPlaceholder="INE Etudiant"
                            />
                            <Column
                                field="etudiant.nom"
                                header="Nom étudiant"
                                sortable={true}
                                filter={true}
                                filterPlaceholder="Nom étudiant"
                            />
                            <Column
                                field="etudiant.prenom"
                                header="Prénom(s) étudiant"
                                sortable={true}
                                filter={true}
                                filterPlaceholder="Prénom(s) étudiant"
                            />
                            <Column
                                field="note"
                                header="Note obtenue"
                                sortable={true}
                                filter={true}
                                filterPlaceholder="Note obtenue"
                            />
                            <Column
                                field="observationNotation"
                                header="Observation note"
                                sortable={true}
                                filter={true}
                                filterPlaceholder="observation note"
                            />
                        </DataTable>
                    )}
                </Dialog>

                <Dialog
                    visible={this.state.consultListDialog}
                    style={{ width: "95%" }}
                    header={this.state.listeTitle}
                    modal
                    className="p-fluid"
                    footer={listEtudiantFooter}
                    onHide={this.hideListEtudiantDialog}
                >
                    {this.state.loadingListe ? (
                        <div className="p-grid">
                            <div className="p-col-6"></div>
                            <div className="p-col-6">
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                    ) : (
                        <React.Fragment>
                            <div
                                className="p-d-flex p-ai-center export-buttons"
                                style={{ marginLeft: 10 }}
                            >
                                <Button
                                    type="button"
                                    icon="pi pi-file-excel"
                                    onClick={this.exportExcel}
                                    className="p-button-success p-mr-2"
                                    data-pr-tooltip="XLS"
                                />

                                <Button
                                    type="button"
                                    icon="pi pi-file-pdf"
                                    onClick={this.exportPdf}
                                    style={{ marginLeft: 10 }}
                                    className="p-button-warning p-mr-2"
                                    data-pr-tooltip="PDF"
                                />
                            </div>
                            <div className="p-col-12">
                                {
                                    <DataTable
                                        ref={(el) => (this.dt = el)}
                                        value={this.state.listeEnConsultation}
                                        selectionMode="single"
                                        header={this.state.listeTitle}
                                        paginator={true}
                                        rows={10000}
                                        globalFilter={this.state.globalFilter}
                                        responsive={true}
                                        selection={
                                            this.state.dataTableSelection1
                                        }
                                        onSelectionChange={(event) =>
                                            this.setState({
                                                dataTableSelection1:
                                                    event.value,
                                            })
                                        }
                                    >
                                        <Column
                                            field="ine"
                                            header="INE"
                                            sortable={true}
                                            filter={true}
                                            filterPlaceholder="INE"
                                        />
                                        <Column
                                            field="nom"
                                            header="Nom"
                                            sortable={true}
                                            filter={true}
                                            filterPlaceholder="Nom"
                                        />
                                        <Column
                                            field="prenom"
                                            header="Prénom(s)"
                                            sortable={true}
                                            filter={true}
                                            filterPlaceholder="Prénom(s)"
                                        />
                                        <Column
                                            field="dateNaiss"
                                            header="Date de Naiss."
                                            sortable={true}
                                            filter={true}
                                            filterPlaceholder="Date de naiss."
                                        />
                                        <Column
                                            field="lieuNaissance"
                                            header="Lieu de Naiss."
                                            sortable={true}
                                            filter={true}
                                            filterPlaceholder="Lieu de naiss."
                                        />
                                        {
                                            <Column
                                                field="moyenne"
                                                header="Moyenne obtenue"
                                                sortable={true}
                                                filter={true}
                                                filterPlaceholder="moyenne obtenue"
                                            />
                                        }
                                    </DataTable>
                                }
                            </div>
                        </React.Fragment>
                    )}
                </Dialog>
                <Dialog
                    visible={this.state.pvDialog}
                    style={{ width: "98%" }}
                    modal
                    header={this.state.listeTitle}
                    className="p-fluid"
                    footer={listPvFooter}
                    onHide={this.hideListPvDialog}
                >
                    {this.state.loadingPV ? (
                        <div className="p-grid">
                            <div className="p-col-6"></div>
                            <div className="p-col-6">
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                    ) : (
                        <React.Fragment>
                            <hr />

                            {this.state.telecharger ? (
                                <>
                                    <ExportSheet
                                        dataType="Table-Node-Element"
                                        fileName={`pv`}
                                        tableElement={this.state.table}
                                        xlsx={XLSX}
                                    >
                                        <div className="p-col-2">
                                            <Button
                                                label="Télécharger le PV"
                                                icon="pi pi-file-excel"
                                                className="p-button-success"
                                            />
                                        </div>
                                    </ExportSheet>
                                </>
                            ) : (
                                <div className="p-grid p-col-4">
                                    <div className="p-col-5">
                                        <Button
                                            label="Export pdf"
                                            title="expoter en pdf"
                                            className="p-button-warning"
                                            type="button"
                                            icon="pi pi-file-pdf"
                                            onClick={this.genererPdf}
                                            data-pr-tooltip="PDF"
                                        />
                                    </div>
                                    <div className="p-col-2"></div>
                                    <div className="p-col-5">
                                        <Button
                                            label="Export excel"
                                            title="exporter en excel"
                                            className="p-button-success"
                                            data-pr-tooltip="EXCEL"
                                            icon="pi pi-file-excel"
                                            onClick={() => {
                                                this.dowloadListe();
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                            {/*exportEcel*/}

                            <div id="pvpdf">
                                <React.Fragment>
                                    <table
                                        id="sheetjs"
                                        style={{
                                            fontSize: 10,
                                            textAlign: "left",
                                        }}
                                        className="table"
                                    >
                                        <thead>
                                            <tr>
                                                <th colSpan={8}>
                                                    Ministère de l'Enseignement
                                                    Supérieur de la Recherche et
                                                    de l'Innovation
                                                    <br />
                                                    ----------------------------------------
                                                    <br />
                                                    Secrétariat Général
                                                    <br />
                                                    ----------------------------------------
                                                    <br />
                                                    {this.state.structure &&
                                                        this.state.structure
                                                            .nomIes}
                                                    <br />
                                                    ----------------------------------------
                                                    <br />
                                                    {this.state.structure &&
                                                        this.state.structure
                                                            .nomEtablissement}
                                                    <br />
                                                    ----------------------------------------
                                                    <br />
                                                    {this.state.structure &&
                                                        this.state.structure
                                                            .lbDepartementEtab}
                                                    <br />
                                                </th>
                                                <th colSpan={10}>
                                                    {this.state.structure ? (
                                                        <img
                                                            style={{
                                                                marginLeft: 200,
                                                            }}
                                                            src={
                                                                "/assets/layout/images/logos/" +
                                                                this.state
                                                                    .structure
                                                                    .logoIes
                                                            }
                                                            width={100}
                                                            height={100}
                                                            alt="logo iesr"
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                </th>
                                                <th colSpan={4}>
                                                    {/*console.log(this.state.structure)*/}
                                                </th>
                                            </tr>

                                            <tr>
                                                <th colSpan={18}>
                                                    {
                                                        this.state.curentSession
                                                            .libelleSession
                                                    }
                                                </th>
                                            </tr>

                                            <tr
                                                style={{
                                                    border: "1px solid black",
                                                }}
                                            >
                                                <th
                                                    style={{
                                                        border: "1px solid black",
                                                    }}
                                                    rowSpan="2"
                                                >
                                                    {
                                                        this.state
                                                            .structureEntete.nom
                                                    }
                                                </th>
                                                <th
                                                    style={{
                                                        border: "1px solid black",
                                                    }}
                                                    rowSpan="2"
                                                >
                                                    {
                                                        this.state
                                                            .structureEntete
                                                            .prenom
                                                    }
                                                </th>
                                                <th
                                                    style={{
                                                        border: "1px solid black",
                                                    }}
                                                    rowSpan="2"
                                                >
                                                    {
                                                        this.state
                                                            .structureEntete
                                                            .dateNaissance
                                                    }
                                                </th>
                                                <th
                                                    style={{
                                                        border: "1px solid black",
                                                    }}
                                                    rowSpan="2"
                                                >
                                                    {
                                                        this.state
                                                            .structureEntete
                                                            .lieuNaissance
                                                    }
                                                </th>
                                                <th
                                                    style={{
                                                        border: "1px solid black",
                                                    }}
                                                    rowSpan="2"
                                                >
                                                    {
                                                        this.state
                                                            .structureEntete
                                                            .matricule
                                                    }
                                                </th>
                                                {this.state.structureEntete.liste_ue.map(
                                                    (ue) => {
                                                        return (
                                                            <React.Fragment>
                                                                <th
                                                                    style={{
                                                                        border: "1px solid black",
                                                                    }}
                                                                    colSpan={
                                                                        ue.nb_ec +
                                                                        2
                                                                    }
                                                                >
                                                                    {ue.code_ue}
                                                                </th>
                                                            </React.Fragment>
                                                        );
                                                    }
                                                )}
                                                <th
                                                    style={{
                                                        border: "1px solid black",
                                                    }}
                                                    rowSpan="2"
                                                >
                                                    Total pondéré
                                                </th>
                                                <th
                                                    style={{
                                                        border: "1px solid black",
                                                    }}
                                                    rowSpan="2"
                                                >
                                                    Moyenne
                                                </th>
                                                {
                                                    <th
                                                        style={{
                                                            border: "1px solid black",
                                                        }}
                                                        rowSpan="2"
                                                    >
                                                        Total crédits acquis
                                                    </th>
                                                }
                                                <th
                                                    style={{
                                                        border: "1px solid black",
                                                    }}
                                                    rowSpan="2"
                                                >
                                                    Mention
                                                </th>
                                                <th
                                                    style={{
                                                        border: "1px solid black",
                                                    }}
                                                    rowSpan="2"
                                                >
                                                    Résultat
                                                </th>
                                            </tr>

                                            <tr
                                                style={{
                                                    border: "1px solid black",
                                                }}
                                            >
                                                {this.state.structureEntete.liste_ue.map(
                                                    function (ue) {
                                                        let listeElementConstitutif =
                                                            ue.liste_ec.map(
                                                                (ec) => {
                                                                    return (
                                                                        <th
                                                                            style={{
                                                                                border: "1px solid black" /*writingMode: "vertical-rl", textOrientation : "mixed"*/,
                                                                            }}
                                                                        >
                                                                            {
                                                                                ec.libelle_ec
                                                                            }
                                                                        </th>
                                                                    );
                                                                }
                                                            );

                                                        return (
                                                            <React.Fragment>
                                                                {
                                                                    listeElementConstitutif
                                                                }
                                                                <th
                                                                    style={{
                                                                        border: "1px solid black",
                                                                        textAlign:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    {
                                                                        ue.credit_ue
                                                                    }
                                                                </th>
                                                                <th
                                                                    style={{
                                                                        border: "1px solid black",
                                                                        textAlign:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    {
                                                                        ue.moyenne_pondere
                                                                    }
                                                                </th>
                                                            </React.Fragment>
                                                        );
                                                    }
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.listePV.map((data) => {
                                                let listeMoyenneEtudiant =
                                                    data.listeMoyenne.map(
                                                        (moyenne) => {
                                                            return (
                                                                <td
                                                                    style={{
                                                                        border: "1px solid black",
                                                                        textAlign:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    {
                                                                        moyenne.moyenne
                                                                    }
                                                                </td>
                                                            );
                                                        }
                                                    );

                                                return (
                                                    <tr
                                                        style={{
                                                            border: "1px solid black",
                                                        }}
                                                    >
                                                        <td
                                                            style={{
                                                                border: "1px solid black",
                                                            }}
                                                        >
                                                            {data.nom}
                                                        </td>
                                                        <td
                                                            style={{
                                                                border: "1px solid black",
                                                            }}
                                                        >
                                                            {data.prenom}
                                                        </td>
                                                        <td
                                                            style={{
                                                                border: "1px solid black",
                                                            }}
                                                        >
                                                            {data.dateNaissance}
                                                        </td>
                                                        <td
                                                            style={{
                                                                border: "1px solid black",
                                                            }}
                                                        >
                                                            {data.lieuNaissance}
                                                        </td>
                                                        <td
                                                            style={{
                                                                border: "1px solid black",
                                                            }}
                                                        >
                                                            {data.matricule}
                                                        </td>
                                                        {listeMoyenneEtudiant}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>

                                    {/*
                            <h2>Membres du jury</h2>

                            <br/>   

                            <div className="p-grid">
                                <div className='p-col-12 p-md-12 p-lg-12'>

                                {(this.state.presidentJury) && <>
                                    
                                        <hr/>
                                        Président
                                        <hr/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        {(this.state.presidentJury.enseignant.grade && this.state.presidentJury.enseignant.grade.sigleGrade)? this.state.presidentJury.enseignant.grade.sigleGrade : (this.state.presidentJury.enseignant.sexeEnseignant === 'M')? "Mr":"Mme"} {(this.state.presidentJury && this.state.presidentJury.enseignant ) ? this.state.presidentJury.enseignant.prenomEnseignant : ""} { this.state.presidentJury ? this.state.presidentJury.enseignant.nomEnseignant : ""}
                                        {(this.state.presidentJury.enseignant.grade && this.state.presidentJury.enseignant.grade.libelleGrade && this.state.presidentJury.enseignant.grade.sigleGrade) && <><br/> {this.state.presidentJury.enseignant.grade.libelleGrade} </>}
                                   
                                    
                                    </>}
                                    

                                </div>
                            </div>

                          
                            <br/>
                                ***
                            <br/>

                            <div className="p-grid">
                                <div className='p-col-12 p-md-12 p-lg-12'>
                                    <hr/>
                                    Sécretaire(s)
                                    <hr/>
                                </div>
        
                                {this.state.listeSecretaire.map(secretaire => {
                                return(
                                    <div className='p-col-6 p-md-6 p-lg-6'>
                                        <br/>
                                            <br/>
                                            <br/>
                                            <br/>
                                            <br/>
                                            {(secretaire.enseignant.grade && secretaire.enseignant.grade.sigleGrade)? secretaire.enseignant.grade.sigleGrade : (secretaire.enseignant.sexeEnseignant === 'M')? "Mr":"Mme"} {secretaire.enseignant.prenomEnseignant} {secretaire.enseignant.nomEnseignant}
                                            {(secretaire.enseignant.grade && secretaire.enseignant.grade.libelleGrade && secretaire.enseignant.grade.sigleGrade) && <> <br/>{secretaire.enseignant.grade.libelleGrade}</> }
                                    </div>
                                    )
                                })}
                                
                                
                            </div>

                            <br/>
                                ***
                            <br/>

                            <div className="p-grid">
                                <div className='p-col-12 p-md-12 p-lg-12'>
                                    <hr/>
                                    Membres
                                    <hr/>
                                </div>
                        
                                {this.state.listeMembrePV.map(membre => {
                                    return(

                                        
                                        <div className='p-col-6 p-md-6 p-lg-6'>
                                            <br/>
                                            <br/>
                                            <br/>
                                            <br/>
                                            {(membre.enseignant.grade && membre.enseignant.grade.sigleGrade)? membre.enseignant.grade.sigleGrade : (membre.enseignant.sexeEnseignant === 'M')? "Mr":"Mme"} {membre.enseignant.prenomEnseignant} {membre.enseignant.nomEnseignant}
                                            {(membre.enseignant.grade && membre.enseignant.grade.libelleGrade && membre.enseignant.grade.sigleGrade) && <> <br/>{membre.enseignant.grade.libelleGrade} </> }
                                        </div>
                                    )
                                })}
                            </div>
                            */}
                                </React.Fragment>
                            </div>
                        </React.Fragment>
                    )}
                </Dialog>

                <Dialog
                    visible={this.state.afficheRachatDialog}
                    style={{ width: "95%" }}
                    modal
                    className="p-fluid"
                    footer={rachatFooter}
                    onHide={this.hideRachatDialog}
                >
                    <div className="p-grid">
                        <div className="p-lg-4" style={{ marginTop: -15 }}>
                            <label htmlFor="moyRachat">
                                Moyenne à racheter :{" "}
                            </label>
                            <input
                                style={{ marginLeft: 10 }}
                                type="number"
                                id="moyRachat"
                                value={this.state.moyRachat}
                                onChange={(e) =>
                                    this.onValueChange(e, "moyRachat")
                                }
                                required
                                autoFocus
                                className={classNames({
                                    "p-invalid": this.state.submitted,
                                })}
                                step="0.01"
                                max="20"
                                min="0"
                            />
                            {this.state.submitted && !this.state.moyRachat && (
                                <small
                                    className="p-invalid"
                                    style={{ color: "red" }}
                                >
                                    Ce champs est obligatoire.
                                </small>
                            )}
                        </div>
                        <div className="p-lg-2">
                            <Button
                                label="Afficher la liste des étudiants"
                                className="p-button-success"
                                type="button"
                                onClick={() => {
                                    this.loadListeEtudiant(
                                        this.state.curentDeliberation
                                            .idDeliberation,
                                        this.state.moyRachat
                                    );
                                }}
                                style={{ marginLeft: 10 }}
                            />{" "}
                        </div>
                    </div>
                    {this.state.loadingListeEtudiant ? (
                        this.state.loadingRachat ? (
                            <div className="p-grid">
                                <div className="p-col-6"></div>
                                <div className="p-col-6">
                                    {this.spinner.loadingSpinner(
                                        "spin",
                                        "black"
                                    )}
                                </div>
                            </div>
                        ) : (
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.state.listeEtudiantRachat}
                                selectionMode="single"
                                header="Liste des étudiants à racheter"
                                paginator={true}
                                rows={100000}
                                globalFilter={this.state.globalFilter}
                                responsive={true}
                                selection={this.state.dataTableSelection1}
                                onSelectionChange={(event) =>
                                    this.setState({
                                        dataTableSelection1: event.value,
                                    })
                                }
                            >
                                <Column
                                    field="ine"
                                    header="INE"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="rechercher ine"
                                />
                                <Column
                                    field="nom"
                                    header="Nom"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="recherche nom"
                                />
                                <Column
                                    field="prenom"
                                    header="Prénom"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="recherche prénom"
                                />
                                <Column
                                    field="moyenne"
                                    header="Moy. obtenue"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="recherche moyenne"
                                />
                                <Column
                                    header={actionHeader}
                                    body={this.actionSelectEtudiantTemplate}
                                    style={{
                                        textAlign: "center",
                                        width: "8em",
                                    }}
                                />
                            </DataTable>
                        )
                    ) : (
                        ""
                    )}
                </Dialog>

                <Dialog
                    visible={this.state.rachatDialog}
                    style={{ width: "95%" }}
                    modal
                    className="p-fluid"
                    footer={rachatDialogFooter}
                    onHide={this.hideRachatEtudiantDialog}
                >
                    {this.state.loadingListeRachat ? (
                        <div className="p-grid">
                            <div className="p-col-6"></div>
                            <div className="p-col-6">
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="p-col-2">
                                <Button
                                    label="Rachat multi-critère"
                                    title="Racheter sur plusieurs EC"
                                    className="p-button-success"
                                    icon="pi pi-pencil"
                                    onClick={() => this.racatECMultiple()}
                                />
                            </div>
                            <hr />

                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.state.informationEtudiant}
                                selectionMode="single"
                                header="Détails sur l'étudiant concerné"
                                rows={1}
                                responsive={true}
                            >
                                <Column
                                    field="ine"
                                    header="INE"
                                    filterMatchMode="contains"
                                    sortable={false}
                                    filter={false}
                                    filterPlaceholder="rechercher ine"
                                />
                                <Column
                                    field="nom"
                                    header="Nom"
                                    filterMatchMode="contains"
                                    sortable={false}
                                    filter={false}
                                    filterPlaceholder="recherche nom"
                                />
                                <Column
                                    field="prenom"
                                    header="Prénom"
                                    filterMatchMode="contains"
                                    sortable={false}
                                    filter={false}
                                    filterPlaceholder="recherche prénom"
                                />
                                <Column
                                    field="moyenne"
                                    header="Moy. obtenue"
                                    filterMatchMode="contains"
                                    sortable={false}
                                    filter={false}
                                    filterPlaceholder="recherche moyenne"
                                />
                            </DataTable>

                            <hr />
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.state.listeEcRachat}
                                selectionMode="single"
                                header="Liste des EC non validés (Rachat par EC)"
                                paginator={true}
                                rows={100}
                                globalFilter={this.state.globalFilter}
                                responsive={true}
                                selection={this.state.dataTableSelection1}
                                onSelectionChange={(event) =>
                                    this.setState({
                                        dataTableSelection1: event.value,
                                    })
                                }
                            >
                                <Column
                                    field="examen.elementConstitutif.libelleElementConstitutif"
                                    header="Element constitutif"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="rechercher ine"
                                />
                                <Column
                                    field="noteInitiale"
                                    header="Note initiale"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="note initiale"
                                />
                                <Column
                                    field="noteRachat"
                                    header="Note rachat"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="note rachat"
                                />
                                <Column
                                    header={actionHeader}
                                    body={this.actionValiderRachatTemplate}
                                    style={{
                                        textAlign: "center",
                                        width: "8em",
                                    }}
                                />
                            </DataTable>
                        </>
                    )}
                </Dialog>

                <Dialog
                    visible={this.state.rachatMulticritereDialog}
                    style={{ width: "95%" }}
                    modal
                    className="p-fluid"
                    footer={rachatMulticritereDialogFooter}
                    onHide={this.hideRachatMulticritereDialog}
                >
                    <DataTable
                        ref={(el) => (this.dt = el)}
                        value={this.state.informationEtudiant}
                        selectionMode="single"
                        header="Détails sur l'étudiant concerné"
                        rows={1}
                        responsive={true}
                    >
                        <Column
                            field="ine"
                            header="INE"
                            filterMatchMode="contains"
                            sortable={false}
                            filter={false}
                            filterPlaceholder="rechercher ine"
                        />
                        <Column
                            field="nom"
                            header="Nom"
                            filterMatchMode="contains"
                            sortable={false}
                            filter={false}
                            filterPlaceholder="recherche nom"
                        />
                        <Column
                            field="prenom"
                            header="Prénom"
                            filterMatchMode="contains"
                            sortable={false}
                            filter={false}
                            filterPlaceholder="recherche prénom"
                        />
                        <Column
                            field="moyenne"
                            header="Moy. obtenue"
                            filterMatchMode="contains"
                            sortable={false}
                            filter={false}
                            filterPlaceholder="recherche moyenne"
                        />
                    </DataTable>

                    <hr />

                    <DataTable
                        ref={(el) => (this.dt = el)}
                        value={this.state.listeEcRachat}
                        selectionMode="single"
                        header="Liste des EC non validés"
                        paginator={true}
                        rows={100}
                        globalFilter={this.state.globalFilter}
                        responsive={true}
                        selection={this.state.dataTableSelection1}
                        onSelectionChange={(event) =>
                            this.setState({ dataTableSelection1: event.value })
                        }
                    >
                        <Column
                            field="examen.elementConstitutif.libelleElementConstitutif"
                            header="Element constitutif"
                            filterMatchMode="contains"
                            sortable={true}
                            filter={true}
                            filterPlaceholder="rechercher ine"
                        />
                        <Column
                            field="noteInitiale"
                            header="Note initiale"
                            filterMatchMode="contains"
                            sortable={true}
                            filter={true}
                            filterPlaceholder="note initiale"
                        />
                        <Column
                            field="noteRachatManuel"
                            header="Note rachat"
                            filterMatchMode="contains"
                            sortable={true}
                            filter={true}
                            filterPlaceholder="note rachat"
                            editor={(props) => this.editNote(props)}
                        />
                    </DataTable>
                </Dialog>
            </div>
        );
    }
}

export default Deliberation;
