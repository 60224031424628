import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "./index.css";

import { DataTable } from "primereact/datatable";
import React, { useMemo, useState } from "react";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Notify } from "../../../components/Notify";
import { ToastContainer } from "react-toastify";
import { Steps } from "primereact/steps";
import { Calendar } from "primereact/calendar";
import classNames from "classnames";
import { RadioButton } from "primereact/radiobutton";
import { Controller, useForm } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { DepartementService } from "../../../lmd/service/DepartementService";
import { Spinner } from "../../../components/Spinner";
import { IesrService } from "../../../lmd/service/IesrService";
import ResponsabiliteService from "../../service/ResponsabiliteService";
import SpecialiteService from "../../service/SpecialiteService";
import GradeService from "../../service/GradeService";
import DisciplineService from "../../service/DisciplineService";
import StatutService from "../../service/StatutService";
import EnseignantService from "../../service/EnseignantService";
import RangService from "../../service/RangService";
import { Checkbox } from "primereact/checkbox";
import NominationService from "../../service/NominationService";
import XLSX from "xlsx";
import { ExportSheet } from "react-xlsx-sheet";
import { jsPDF } from "jspdf";

export default function Enseignant() {
    const defaultValues = {
        matriculeEnseignant: "",
        nomEnseignant: "",
        prenomEnseignant: "",
        emailEnseignant: "",
        telEnseignant: "",
        diplomeEnseignant: "",
        anneeNaissance: "",
        genre: "",
        rang: "",
        nomPrenomEnseignant: "",
        lieuNaissance: "",
        idStatutEnseignant: "",
        statutEnseignant: "",
        departement: "",
        specialite: "",
        grade: "",
        discipline: "",
        universite: "",
        fgActif: true,
    };

    const defaultNominationValues = {
        dateDebutNomination: "",
        dateFinNomination: "",
        responsabilite: "",
        responsabilitePriseEnCompte: false,
        enseignant: "",
        statutEnseignantResponsabilite: "",
    };

    const defaultGradeValues = {
        libelleGrade: "",
        volumeStatutaire: "",
        coutCM: "",
        coutTD: "",
        heureMax: "",
        montantPrime: "",
        montantVE: "",
        baseAttribution: "",
        rang: "",
    };

    const defaultSpecialiteValues = {
        libelleSpecialite: "",
        discipline: "",
    };

    const baseAttribution = [
        { name: "Cours Magistral", value: "Cours Magistral" },
        { name: "Travaux Dirigés", value: "Travaux Dirigés" },
        { name: "Travaux Pratiques", value: "Travaux Pratiques" },
    ];
    const defaultDisciplineValues = {
        libelleDiscipline: "",
    };

    const defaultStatutValues = {
        libelleStatutEnseignant: "",
    };
    const defaultRangValues = {
        libelleRangEnseignant: "",
    };

    const addLocale = {
        firstDayOfWeek: 1,
        dayNames: [
            "dimanche",
            "lundi",
            "mardi",
            "mercredi",
            "jeudi",
            "vendredi",
            "samedi",
        ],
        dayNamesShort: ["dim", "lun", "mar", "mer", "jeu", "ven", "sam"],
        dayNamesMin: ["D", "L", "M", "Me", "J", "V", "S"],
        monthNames: [
            "Janvier",
            "Fevrier",
            "Mars",
            "Avril",
            "Mai",
            "Juin",
            "Juillet",
            "Août",
            "Septembre",
            "Octobre",
            "Novembre",
            "Decembre",
        ],
        monthNamesShort: "",
        today: "aujourd'hui",
        clear: "effacer",
    };

    let cols = [
        { field: "matriculeEnseignant", header: "Matricule" },
        { field: "nomEnseignant", header: "Nom" },
        { field: "prenomEnseignant", header: "Prénom" },
        { field: "sexe", header: "Sexe" },
        { field: "telEnseignant", header: "Téléphone" },
        { field: "libelleGrade", header: "Grade" },
        //{ field: 'lbDepartementEtab', header: 'Département' },
        { field: "sigleIes", header: "IES" },
    ];
    let exportColumns = cols.map((col) => ({
        title: col.header,
        dataKey: col.field,
    }));

    const [listOfEnseignant, setListOfEnseignant] = useState([]);
    const [enseignantDelete, setEnseignantDelete] = useState(defaultValues);
    const [listOfGrade, setListOfGrade] = useState();
    const [listOfResponsabilite, setListOfResponsabilite] = useState();
    const [listOfDepartement, setListOfDepartement] = useState([
        { idDepartementEtab: "", lbDepartementEtab: "" },
    ]);
    const [listOfSpecialite, setListOfSpecialite] = useState([]);
    const [listOfUniversite, setListOfUniversite] = useState([]);
    const [listOfDiscipline, setListOfDiscipline] = useState();
    const [listOfStatut, setListOfStatut] = useState([]);
    const [loading, setLoading] = useState(false);
    const [grade, setGrade] = useState(false);
    const [specialite, setSpecialite] = useState(false);
    const [statut, setStatut] = useState(false);
    const [discipline, setDiscipline] = useState(false);
    const [rang, setRang] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [newSpecialite, setNewSpecialite] = useState([]);
    const {
        reset,
        control,
        clearErrors,
        handleSubmit,
        formState: { errors, dirtyFields },
        setValue,
        getValues,
        trigger,
    } = useForm({ defaultValues });
    const {
        reset: resetGrade,
        control: controlGrade,
        handleSubmit: handleSubmitGrade,
        formState: { errors: errorsGrade },
    } = useForm({ defaultValues: defaultGradeValues });
    const {
        reset: resetStatut,
        control: controlStatut,
        handleSubmit: handleSubmitStatut,
        formState: { errors: errorsStatut },
    } = useForm({ defaultValues: defaultStatutValues });
    const {
        reset: resetDiscipline,
        control: controlDiscipline,
        handleSubmit: handleSubmitDiscipline,
        formState: { errors: errorsDiscipline },
    } = useForm({ defaultValues: defaultDisciplineValues });
    const {
        reset: resetSpecialite,
        control: controlSpecialite,
        handleSubmit: handleSubmitSpecialite,
        formState: { errors: errorsSpecialite },
    } = useForm({ defaultValues: defaultSpecialiteValues });
    const {
        reset: resetNomination,
        control: controlNomination,
        getValues: getValuesNomination,
        setValue: setValueNomination,
        handleSubmit: handleSubmitNomination,
        formState: { errors: errorsNomination },
    } = useForm({ defaultValues: defaultNominationValues });
    const {
        reset: resetRang,
        control: controlRang,
        handleSubmit: handleSubmitRang,
        formState: { errors: errorsRang },
    } = useForm({ defaultValues: defaultRangValues });
    const [spin, setSpin] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [nomination, setNomination] = useState({
        add: false,
        nominationDialog: false,
    });
    const [listRang, setListRang] = useState([]);
    const [exportList, setExportList] = useState([]);
    const dialogOpenHandler = {
        add: false,
        modify: false,
        delete: false,
    };

    const [dialog, setDialog] = useState(dialogOpenHandler);

    const notify = new Notify();
    const spinner = new Spinner();
    const iesrService = new IesrService();
    const departementService = new DepartementService();
    const date = new Date();
    const enseignantService = new EnseignantService();
    const responsabiliteService = new ResponsabiliteService();
    const specialiteService = new SpecialiteService();
    const statutService = new StatutService();
    const gradeService = new GradeService();
    const disciplineService = new DisciplineService();
    const nominationService = new NominationService();
    const rangService = new RangService();

    // variables declaration En DialogdBloc

    useEffect(() => {
        setLoading(true);
        enseignantService
            .getAll()
            .then((data) => {
                setListOfEnseignant(data);
                let enseignantList = [];

                enseignantList = data.map((ens) => {
                    return {
                        matriculeEnseignant: ens.matriculeEnseignant,
                        nomEnseignant: ens.nomEnseignant,
                        prenomEnseignant: ens.prenomEnseignant,
                        sexe: ens.genre,
                        libelleGrade: ens.grade.libelleGrade,
                        //"libelleDiscipline" : ens.specialite.libelleDiscipline,
                        //"libelleSpecialite" : ens.specialite.libelleSpecialite,
                        //"emailEnseignant" : ens.emailEnseignant,
                        telEnseignant: ens.telEnseignant,
                        sigleIes: ens.sigleIes,
                        // "lbDepartementEtab" : ens.lbDepartementEtab
                    };
                });
                //console.log(enseignantList)
                setExportList(enseignantList);
            })
            .catch((errors) => {
                console.log(errors);
                notify.echec(errors.response.data.message);
            })
            .finally(() => setLoading(false));
        gradeService.getAll().then((data) => setListOfGrade(data));
        responsabiliteService
            .getAll()
            .then((response) => setListOfResponsabilite(response)); // recuperation de la liste des responsabilites pour le dropdown
        specialiteService.getAll().then((data) => setListOfSpecialite(data));
        statutService.getAll().then((data) => setListOfStatut(data));
        disciplineService.getAll().then((data) => setListOfDiscipline(data));
        iesrService.getAll().then((response) => {
            setListOfUniversite(response);
        });
        rangService.getAll().then((response) => setListRang(response));
    }, []);

    // function handler Dialog Bloc
    const handleEnseignantSubmit = (dataForm) => {
        if (dialog.add) {
            const finalDTO = {
                anneeNaissance: dataForm.anneeNaissance,
                diplomeEnseignant: dataForm.diplomeEnseignant,
                emailEnseignant: dataForm.emailEnseignant,
                genre: dataForm.genre,
                grade: dataForm.grade,
                idDepartementEtab: dataForm.departement.idDepartementEtab,
                lieuNaissance: dataForm.lieuNaissance,
                nomPrenomEnseignant: `${dataForm.matriculeEnseignant} ${dataForm.nomEnseignant} ${dataForm.prenomEnseignant}`,
                matriculeEnseignant: dataForm.matriculeEnseignant,
                nomEnseignant: dataForm.nomEnseignant,
                prenomEnseignant: dataForm.prenomEnseignant,
                rang: listRang.find(
                    (rg) => rg.idRang === dataForm.grade.rang.idRang
                ),
                specialite: dataForm.specialite,
                statutEnseignant: dataForm.statutEnseignant,
                telEnseignant: dataForm.telEnseignant,
                idIes: dataForm.universite.idIesr,
                fgActif: dataForm.fgActif,
                discipline: dataForm.discipline,
            };

            enseignantService
                .create(finalDTO)
                .then((response) => {
                    setSpin(true);
                    setLoading(true);
                    setValueNomination("enseignant", response);
                    enseignantService.getAll().then((data) => {
                        setListOfEnseignant(data);
                    });

                    notify.successVH(nominationMessage(finalDTO), "top-right");
                    reset();
                    setDialog({ add: false });
                })
                .catch((errors) => {
                    notify.echec(errors.response.data.message);
                    console.log(errors);
                })
                .finally(() => {
                    setLoading(false);
                    setSpin(false);
                });
        } else if (dialog.modify) {
            const finalDTO = {
                anneeNaissance: dataForm.anneeNaissance,
                diplomeEnseignant: dataForm.diplomeEnseignant,
                emailEnseignant: dataForm.emailEnseignant,
                genre: dataForm.genre,
                grade: dataForm.grade,
                idDepartementEtab: dataForm.departement.idDepartementEtab,
                idEnseignant: dataForm.idEnseignant,
                specialite: dataForm.specialite,
                statutEnseignant: dataForm.statutEnseignant,
                lieuNaissance: dataForm.lieuNaissance,
                matriculeEnseignant: dataForm.matriculeEnseignant,
                nomEnseignant: dataForm.nomEnseignant,
                prenomEnseignant: dataForm.prenomEnseignant,
                telEnseignant: dataForm.telEnseignant,
                nomPrenomEnseignant: dataForm.nomPrenomEnseignant,
                idIes: dataForm.universite.idIesr,
                fgActif: dataForm.fgActif,
                discipline: dataForm.discipline,
            };

            //console.log(finalDTO)

            enseignantService
                .update(finalDTO)
                .then(() => {
                    setSpin(true);
                    setLoading(true);
                    notify.success();
                    enseignantService
                        .getAll()
                        .then((response) => setListOfEnseignant(response));
                    reset();
                    setDialog({ add: false });
                })
                .catch(() => {
                    notify.echec(errors.response.data.message);
                })
                .finally(() => {
                    setSpin(false);
                    setLoading(false);
                });
        }
    };

    const onHide = () => {
        setDialog(dialogOpenHandler);
        setActiveIndex(0);
        reset();
    };

    // cette function affiche le popup qui permet de completer les informations de la responsabilité

    const handleAddDialog = () => {
        setDialog({ ...dialog, add: true });
        // setErrorDispatch(false);
    };

    // cette fonction initialise les données de la modification dans un object nome modificationEnseignant
    // ces données seront afficher respectivement dans leur inputtext ou dropdown

    const handleModification = (dataToModify) => {
        let discipline;

        //console.log(dataToModify)

        if (dataToModify.specialite) {
            discipline = listOfDiscipline.find(
                (d) => d.idDiscipline === dataToModify.specialite.idDiscipline
            );
            let newList = listOfSpecialite.filter(
                (d) => d.idDiscipline === dataToModify.specialite.idDiscipline
            );
            setNewSpecialite(newList);
        }

        let universite = listOfUniversite.find(
            (d) => d.idIesr === dataToModify.idIes
        );

        departementService
            .getListeDepartement(dataToModify.idIes)
            .then((response) => {
                setListOfDepartement(response);
                const departement = response.find(
                    (d) =>
                        d.idDepartementEtab === dataToModify.idDepartementEtab
                );
                setValue("departement", departement);
            });

        setValue("universite", universite);
        setValue("anneeNaissance", new Date(dataToModify.anneeNaissance));
        setValue("diplomeEnseignant", dataToModify.diplomeEnseignant);
        setValue("discipline", discipline);
        setValue("emailEnseignant", dataToModify.emailEnseignant);
        setValue("genre", dataToModify.genre);
        setValue("grade", dataToModify.grade);
        setValue("idEnseignant", dataToModify.idEnseignant);
        setValue("lieuNaissance", dataToModify.lieuNaissance);
        setValue("matriculeEnseignant", dataToModify.matriculeEnseignant);
        setValue("nomEnseignant", dataToModify.nomEnseignant);
        setValue("nomPrenomEnseignant", dataToModify.nomPrenomEnseignant);
        setValue("prenomEnseignant", dataToModify.prenomEnseignant);
        setValue("rang", { name: dataToModify.rang, value: dataToModify.rang });
        setValue("specialite", dataToModify.specialite);
        setValue("statutEnseignant", dataToModify.statutEnseignant);
        setValue("telEnseignant", dataToModify.telEnseignant);
        setValue("rang", dataToModify.rang);
        setDialog({ ...dialog, modify: true });
    };

    const handleEnseignantDeletion = (dataToDelete = null) => {
        if (dialog.delete) {
            setSpin(true);
            enseignantService
                .delete(enseignantDelete.idEnseignant)
                .then(() => {
                    setLoading(true);
                    enseignantService
                        .getAll()
                        .then((response) => setListOfEnseignant(response));
                    notify.success();
                    setDialog({ delete: false });
                })
                .catch((error) => {
                    notify.echec(error.response.data.message);
                })
                .finally(() => {
                    setSpin(false);
                    setLoading(false);
                });
        } else {
            setEnseignantDelete(dataToDelete);
            setDialog({ delete: true });
        }
    };

    const handleGrade = (dataForm) => {
        dataForm.baseAttribution = dataForm.baseAttribution.value;
        gradeService
            .create([dataForm])
            .then(() => {
                gradeService.getAll().then((data) => {
                    setListOfGrade(data);
                });
                setGrade(false);
                resetGrade();
            })
            .catch((errors) => {
                notify.echec(errors.response.data.message);
                console.log(errors);
            });
    };

    const handleSpecialite = (dataForm) => {
        let discipline = getValues("discipline");
        specialiteService
            .create([
                {
                    idDiscipline: dataForm.discipline.idDiscipline,
                    libelleSpecialite: dataForm.libelleSpecialite,
                },
            ])
            .then(() => {
                specialiteService.getAll().then((data) => {
                    const lib = data.filter(
                        (d) => d.idDiscipline === discipline.idDiscipline
                    )[0].libelleDiscipline;
                    setNewSpecialite(
                        data.filter((dep) => dep.libelleDiscipline === lib)
                    );
                    setListOfSpecialite(data);
                });
                resetSpecialite();
                setSpecialite(false);
            })
            .catch((errors) => {
                console.log(errors);
                notify.echec(errors.response.data.message);
            });
    };

    const handleStatut = (dataForm) => {
        statutService
            .create([dataForm])
            .then(() => {
                statutService.getAll().then((data) => {
                    setListOfStatut(data);
                });
                resetStatut();
                setStatut(false);
            })
            .catch((errors) => {
                console.log(errors);
                notify.echec(errors.response.data.message);
            });
    };

    const handleDiscipline = (dataForm) => {
        disciplineService
            .create([dataForm])
            .then((statut) => {
                disciplineService
                    .getAll()
                    .then((response) => setListOfDiscipline(response));
                resetDiscipline();
                setDiscipline(false);
            })
            .catch((errors) => {
                console.log(errors);
                notify.echec(errors.response.data.message);
            });
    };
    const handleNominationSubmit = (nominationDataForm) => {
        let { enseignant } = nominationDataForm;
        let finalDTO = {
            dateDebutNomination: nominationDataForm.dateDebutNomination,
            dateFinNomination: nominationDataForm.dateFinNomination,
            idEnseignant: enseignant.idEnseignant,
            idResponsabilite:
                nominationDataForm.responsabilite.idResponsabilite,
            statutEnseignantResponsabilite: true,
            responsabilitePriseEnCompte:
                nominationDataForm.responsabilitePriseEnCompte,
        };

        nominationService.create([finalDTO]).then(() => {
            setNomination({ ...nomination, add: false });
            enseignantService
                .getAll()
                .then((response) => setListOfEnseignant(response));
            resetNomination();
        });
    };

    // const departementService= new DepartementService();

    const exportExcel = () => {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(listOfEnseignant);
            const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ["data"],
            };
            const excelBuffer = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            saveAsExcelFile(excelBuffer, "Liste des enseignants");
        });
    };
    const saveAsExcelFile = (buffer, fileName) => {
        import("file-saver").then((module) => {
            let EXCEL_TYPE =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            let EXCEL_EXTENSION = ".xlsx";
            const data = new Blob([buffer], {
                type: EXCEL_TYPE,
            });
            module.default.saveAs(
                data,
                fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
            );
        });
    };
    const exportPdf = () => {
        import("jspdf").then((jsPDF) => {
            import("jspdf-autotable").then(() => {
                const doc = new jsPDF.default("l");
                const image = new Image();
                //let logo = this.state.departement.sigleIes
                //image.src = '/assets/layout/images/logos/logo_'+logo.toLowerCase()+'.png';
                doc.setFontSize(12);
                doc.text("Ministère de l'Enseignement Supérieur,", 10, 10);
                doc.text("de la Recherche et de l'Innovation", 10, 15);
                doc.text("----------------------------------", 10, 20);
                doc.text("Secrétariat Général", 10, 25);
                doc.text("----------------------------------", 10, 30);
                //doc.text(this.state.departement.nomIes, 10, 35)
                //doc.text("----------------------------------", 10, 40)
                //doc.text(this.state.departement.nomEtablissement, 10, 45)
                //doc.text("----------------------------------", 10, 50)
                //doc.text(this.state.departement.lbDepartementEtab, 10, 55)
                //doc.addImage(image, 'PNG', 130, 10, 50, 56);

                doc.text("BURKINA - FASO", 230, 10);
                doc.setFontSize(10);
                doc.text("Unité - Progrès - Justice", 232, 16);
                doc.setFontSize(14);
                doc.text("Liste des enseignants", 10, 80);
                const nbr = listOfEnseignant.length;
                doc.autoTable(exportColumns, exportList, {
                    startY: doc.autoTable({ margin: { top: 82 } }),
                    /*margin: { horizontal: 10 },
                    styles: { overflow: "linebreak" },
                    bodyStyles: { valign: "top" },
                    columnStyles: { email: { columnWidth: "wrap" } },
                    theme: "striped",*/
                    //showHead: "everyPage",

                    didDrawPage: function (data) {
                        // Header
                        doc.setFontSize(10);
                        doc.setTextColor(40);

                        // Footer
                        const str = "Page " + doc.internal.getNumberOfPages();
                        doc.setFontSize(10);

                        const pageCount = doc.getNumberOfPages();

                        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                        const pageSize = doc.internal.pageSize;
                        const pageHeight = pageSize.height
                            ? pageSize.height
                            : pageSize.getHeight();

                        /*for(let i = 1; i<=pageCount; i++){
                            doc.setPage(i)
                            doc.text('' + String(i) + '/' + String(pageCount), 290,200,null,null,"right")
                        }*/
                        //doc.text(str, data.settings.margin.left, pageHeight - 10);

                        //doc.text("Arrêté la présente liste à "+nbr+" noms", pageHeight, pageHeight - 10);
                    },
                });
                paginationPage(doc);
                doc.save("Liste enseignants" + ".pdf");
            });
        });
    };
    const paginationPage = (doc) => {
        const pageCount = doc.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.text(
                "" + String(i) + "/" + String(pageCount),
                290,
                200,
                null,
                null,
                "right"
            );
        }
    };

    const actionHeader = <Button type="button" icon="pi pi-cog" />;
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    title="editer"
                    icon="pi pi-pencil"
                    className="p-button-warning"
                    style={{ marginRight: ".5em" }}
                    onClick={() => handleModification(rowData)}
                />
                <Button
                    icon="pi pi-trash"
                    title="supprimer"
                    className="p-button-danger"
                    onClick={() => handleEnseignantDeletion(rowData)}
                />
            </React.Fragment>
        );
    };

    const deleteEnseignantFooter = (
        <React.Fragment>
            <Button
                type="button"
                className="btn-tap p-button-success"
                label="NON"
                icon="pi pi-times"
                onClick={onHide}
            />
            <Button
                type="button"
                className="btn-tap p-button-danger"
                label="OUI"
                icon={spin ? "pi pi-spin pi-spinner" : "pi pi-check"}
                onClick={handleEnseignantDeletion}
            />
        </React.Fragment>
    );

    // fusion du prenom et du nom de l'enseignant pour affichage
    // recuperation de la listes des responsabilites des enseignants
    const columnBodyTemplate = (rowData) => {
        return (
            <div>
                <span>{rowData.nomEnseignant} </span>
                <span>{rowData.prenomEnseignant} </span>
            </div>
        );
    };

    // body de responsabilite
    // affichage selon la responsabilite la plus elevée
    const BodyResponsabiliteTemplate = (rowData) => {
        //
        /**
         *  recuperation de la responsabilite ayant la plus pour statut vraie et prise en compte dans le calcul du taux de rabattement
         *
         *  on recupere l'object responsabilite dans rowdata qui contient un enseignan avect toutes ces informations ainsi que ces responsabilite
         *
         *
         */
        let responsabilite = rowData.enseignantResponsabilites;
        let result = responsabilite.filter(
            (data) =>
                data.responsabilitePriseEnCompte &&
                data.statutEnseignantResponsabilite
        )[0];
        if (result) {
            return <span>{result.libelleResponsabilite} </span>;
        } else {
            return (
                <div style={{ textAlign: "center", width: "100%" }}>
                    <span> - </span>
                </div>
            );
        }
    };

    // function handler En DialogdBloc

    const items = [{}, {}, {}];

    const monthNavigatorTemplate = (e) => {
        return (
            <Dropdown
                value={e.value}
                options={e.options}
                onChange={(event) => {
                    return e.onChange(event.originalEvent, event.value);
                }}
                style={{ lineHeight: 1 }}
            />
        );
    };
    const yearNavigatorTemplate = (e) => {
        return (
            <Dropdown
                value={e.value}
                options={e.options}
                onChange={(event) =>
                    e.onChange(event.originalEvent, event.value)
                }
                className="p-ml-2"
                style={{ lineHeight: 1 }}
            />
        );
    };

    const getFormErrorMessage = (name) => {
        return (
            errors[name] && (
                <small className="p-error">{errors[name].message}</small>
            )
        );
    };
    const getFormErrorMessageNomination = (name) => {
        return (
            errorsNomination[name] && (
                <small className="p-error">
                    {errorsNomination[name].message}
                </small>
            )
        );
    };
    const getFormErrorMessageGrade = (name) => {
        return (
            errorsGrade[name] && (
                <small className="p-error">{errorsGrade[name].message}</small>
            )
        );
    };
    const getFormErrorMessageSpecialite = (name) => {
        return (
            errorsSpecialite[name] && (
                <small className="p-error">
                    {errorsSpecialite[name].message}
                </small>
            )
        );
    };
    const getFormErrorMessageStatut = (name) => {
        return (
            errorsStatut[name] && (
                <small className="p-error">{errorsStatut[name].message}</small>
            )
        );
    };
    const getFormErrorMessageRang = (name) => {
        return (
            errorsRang[name] && (
                <small className="p-error">{errorsRang[name].message}</small>
            )
        );
    };
    const getFormErrorMessageDiscipline = (name) => {
        return (
            errorsDiscipline[name] && (
                <small className="p-error">
                    {errorsDiscipline[name].message}
                </small>
            )
        );
    };

    const header = (
        <div className="table-header p-col-12">
            <span className="p-input-icon-left">
                <Button
                    style={{ marginLeft: 10 }}
                    label="Ajouter"
                    icon="pi pi-plus"
                    className="p-button-success p-mr-2"
                    onClick={handleAddDialog}
                />
                <InputText
                    style={{ marginLeft: 20, width: "300px" }}
                    type="search"
                    onInput={(e) => setGlobalFilter(e.target.value)}
                    placeholder="Rechercher..."
                />
            </span>
        </div>
    );

    const affecterResponsabilite = () => {
        setNomination({ ...nomination, add: true });
    };

    const nominationMessage = (data) => {
        return (
            <div className="p-d-flex p-flex-column" style={{ flex: "1" }}>
                <div style={{ textAlign: "center", fontSize: 14 }}>
                    <i className="pi pi-check" style={{ fontSize: "3rem" }}></i>
                    <p>
                        <span>Préciser une responsabilité de</span>
                        <br />
                        <span>
                            {data.nomEnseignant} {data.prenomEnseignant}
                        </span>
                    </p>
                </div>
                <div className="p-grid p-fluid mt-4">
                    <div className="p-col-6">
                        <Button
                            type="button"
                            label="Continuer"
                            className="p-button-success"
                            onClick={affecterResponsabilite}
                        />
                    </div>
                    <div className="p-col-6">
                        <Button
                            type="button"
                            label="Annuler"
                            className="p-button-secondary"
                        />
                    </div>
                </div>
            </div>
        );
    };

    const handleNextPrev = async (event) => {
        switch (event) {
            case "next":
                let {
                    anneeNaissance,
                    departement,
                    diplomeEnseignant,
                    discipline,
                    emailEnseignant,
                    genre,
                    grade,
                    lieuNaissance,
                    matriculeEnseignant,
                    nomEnseignant,
                    prenomEnseignant,
                    rang,
                    specialite,
                    telEnseignant,
                    statutEnseignant,
                    universite,
                } = dirtyFields;

                switch (activeIndex) {
                    case 0:
                        if (
                            (matriculeEnseignant &&
                                nomEnseignant &&
                                prenomEnseignant &&
                                emailEnseignant &&
                                genre) ||
                            dialog.modify
                        ) {
                            setActiveIndex((activeIndex) => activeIndex + 1);
                            clearErrors();
                        } else {
                            await trigger([
                                "matriculeEnseignant",
                                "nomEnseignant",
                                "prenomEnseignant",
                                "emailEnseignant",
                                "genre",
                            ]);
                        }

                        break;
                    case 1:
                        if (
                            (telEnseignant &&
                                anneeNaissance &&
                                lieuNaissance &&
                                diplomeEnseignant &&
                                statutEnseignant) ||
                            dialog.modify
                        ) {
                            setActiveIndex((activeIndex) => activeIndex + 1);
                            clearErrors();
                        } else {
                            await trigger([
                                "telEnseignant",
                                "anneeNaissance",
                                "lieuNaissance",
                                "diplomeEnseignant",
                                "statutEnseignant",
                            ]);
                        }

                        break;
                    default:
                        clearErrors();
                        break;
                }
                break;
            case "prev":
                setActiveIndex((activeIndex) => activeIndex - 1);
                break;
            default:
                break;
        }
    };

    const handleRangSubmit = (dataForm) => {
        rangService
            .create([dataForm])
            .then((response) => {
                rangService.getAll().then((response) => setListRang(response));
                resetRang();
            })
            .catch((errros) => console.log(errors));
    };

    const saveEnseignantFooter = (
        <React.Fragment>
            <div
                style={{
                    display: "flex",
                    marginTop: 40,
                    justifyContent: "space-between",
                }}
            >
                <div className="mr-2">
                    <Button
                        type="button"
                        disabled={activeIndex === 0}
                        className="p-button-info"
                        onClick={() => handleNextPrev("prev")}
                        label="Préc"
                        icon="pi pi-arrow-left"
                    />
                </div>
                {activeIndex === 2 && (
                    <div>
                        <Button
                            type="submit"
                            className="p-button-success"
                            disabled={dialog.add && spin}
                            label="Enregistrer"
                            icon={spin ? "pi pi-spin pi-spinner" : "pi pi-save"}
                        />
                    </div>
                )}

                <div className="mr-2">
                    <Button
                        type="button"
                        className="p-button-info"
                        onClick={() => handleNextPrev("next")}
                        label="Suiv"
                        iconPos="right"
                        icon="pi pi-arrow-right"
                    />
                </div>
            </div>
        </React.Fragment>
    );

    const responsabiliteTemplate = (rowData) => {
        let responsabilite = rowData.enseignantResponsabilites.find(
            (data) =>
                data.statutEnseignantResponsabilite &&
                data.responsabilitePriseEnCompte
        );
        return (
            <div>
                {responsabilite ? (
                    <span>{responsabilite.libelleResponsabilite}</span>
                ) : (
                    <span>Néant</span>
                )}
            </div>
        );
    };
    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="card">
                {header}
                {loading ? (
                    <div className="p-grid">
                        <div className="p-col-6"></div>
                        <div className="p-col-6">
                            {spinner.loadingSpinner("spin", "black")}
                        </div>
                    </div>
                ) : (
                    <>
                        <div
                            className="p-d-flex p-ai-center export-buttons"
                            style={{ marginLeft: 10 }}
                        >
                            <Button
                                type="button"
                                icon="pi pi-file-excel"
                                onClick={exportExcel}
                                className="p-button-success p-mr-2"
                                data-pr-tooltip="XLS"
                            />
                            <Button
                                type="button"
                                icon="pi pi-file-pdf"
                                onClick={exportPdf}
                                style={{ marginLeft: 10 }}
                                className="p-button-warning p-mr-2"
                                data-pr-tooltip="PDF"
                            />
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <DataTable
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                                rows={5}
                                selectionMode="single"
                                filterMatchMode="contains"
                                paginator={true}
                                responsive={true}
                                value={listOfEnseignant}
                                globalFilter={globalFilter}
                                header={`Liste des enseignants (${listOfEnseignant.length} enseignant(s))`}
                            >
                                <Column
                                    field="matriculeEnseignant"
                                    header="matricule"
                                    filterMatchMode="contains"
                                    headerStyle={{
                                        textTransform: "capitalize",
                                    }}
                                    filterPlaceholder="Search ..."
                                    sortable
                                    filter
                                />
                                <Column
                                    body={columnBodyTemplate}
                                    header="nom"
                                    filterMatchMode="contains"
                                    headerStyle={{
                                        textTransform: "capitalize",
                                    }}
                                    filterPlaceholder="Search ..."
                                    sortable
                                    filter
                                />
                                <Column
                                    field="emailEnseignant"
                                    header="email"
                                    filterMatchMode="contains"
                                    headerStyle={{
                                        textTransform: "capitalize",
                                    }}
                                    filterPlaceholder="Search ..."
                                    sortable
                                    filter
                                />
                                <Column
                                    field="telEnseignant"
                                    header="telephone"
                                    filterMatchMode="contains"
                                    headerStyle={{
                                        textTransform: "capitalize",
                                    }}
                                    filterPlaceholder="Search ..."
                                    sortable
                                    filter
                                />
                                <Column
                                    field="grade.libelleGrade"
                                    header="grade"
                                    filterMatchMode="contains"
                                    headerStyle={{
                                        textTransform: "capitalize",
                                    }}
                                    filterPlaceholder="Search ..."
                                    sortable
                                    filter
                                />
                                <Column
                                    field="statutEnseignant.libelleStatutEnseignant"
                                    filterMatchMode="contains"
                                    header="Statut"
                                    headerStyle={{
                                        textTransform: "capitalize",
                                    }}
                                    filterPlaceholder="Search ..."
                                    sortable
                                    filter
                                />
                                <Column
                                    body={responsabiliteTemplate}
                                    header="responsabilité"
                                    filterMatchMode="contains"
                                    headerStyle={{
                                        textTransform: "capitalize",
                                    }}
                                    filterPlaceholder="Search ..."
                                    sortable
                                    filter
                                />
                                <Column
                                    field="nomIes"
                                    header="université"
                                    filterMatchMode="contains"
                                    headerStyle={{
                                        textTransform: "capitalize",
                                    }}
                                    filterPlaceholder="Search ..."
                                    sortable
                                    filter
                                />
                                <Column
                                    field="lbDepartementEtab"
                                    header="département"
                                    filterMatchMode="contains"
                                    headerStyle={{
                                        textTransform: "capitalize",
                                    }}
                                    filterPlaceholder="Search ..."
                                    sortable
                                    filter
                                />
                                <Column
                                    header={actionHeader}
                                    body={actionBodyTemplate}
                                    style={{ width: "120px" }}
                                />
                            </DataTable>
                        </div>
                    </>
                )}
            </div>

            {/* Add enseignant Dialog Bloc */}
            <Dialog
                visible={dialog.add || dialog.modify}
                style={{ width: "35vw", height: "90vh" }}
                onHide={onHide}
            >
                <Steps
                    model={items}
                    activeIndex={activeIndex}
                    readOnly={false}
                />

                <form
                    className="p-fluid p-grid p-dir-col"
                    onSubmit={handleSubmit(handleEnseignantSubmit)}
                >
                    <div>
                        {activeIndex === 0 && (
                            <div style={{ marginTop: "10px", height: "350px" }}>
                                <div className="p-field">
                                    <label
                                        htmlFor="matriculeEnseignant"
                                        className={classNames({
                                            "p-error": errors.name,
                                        })}
                                    >
                                        Matricule{" "}
                                    </label>{" "}
                                    {getFormErrorMessage("matriculeEnseignant")}
                                    <Controller
                                        control={control}
                                        name="matriculeEnseignant"
                                        rules={{
                                            required: "champ obligatoire.",
                                        }}
                                        render={({ field }) => (
                                            <InputText
                                                name={field.name}
                                                id={field.name}
                                                type="text"
                                                value={field.value}
                                                {...field}
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.currentTarget.value
                                                    );
                                                    clearErrors(
                                                        "matriculeEnseignant"
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="p-field">
                                    <label htmlFor="nomEnseignant">Nom</label>{" "}
                                    {getFormErrorMessage("nomEnseignant")}
                                    <Controller
                                        control={control}
                                        rules={{
                                            required: "champ obligatoire.",
                                        }}
                                        name="nomEnseignant"
                                        render={({ field }) => (
                                            <InputText
                                                name={field.name}
                                                id={field.id}
                                                type="text"
                                                value={field.value}
                                                {...field}
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.currentTarget.value
                                                    );
                                                    clearErrors(
                                                        "nomEnseignant"
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="p-field">
                                    <label htmlFor="prenomEnseignant">
                                        {" "}
                                        Prenom
                                    </label>{" "}
                                    {getFormErrorMessage("prenomEnseignant")}
                                    <Controller
                                        control={control}
                                        rules={{
                                            required: "champ obligatoire.",
                                        }}
                                        name="prenomEnseignant"
                                        render={({ field }) => (
                                            <InputText
                                                name={field.name}
                                                type="text"
                                                id={field.name}
                                                value={field.value}
                                                {...field}
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.currentTarget.value
                                                    );
                                                    clearErrors(
                                                        "prenomEnseignant"
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="p-field">
                                    <label htmlFor="emailEnseignant">
                                        Email
                                    </label>{" "}
                                    {getFormErrorMessage("emailEnseignant")}
                                    <Controller
                                        control={control}
                                        rules={{
                                            required: "champ obligatoire.",
                                        }}
                                        name="emailEnseignant"
                                        render={({ field }) => (
                                            <InputText
                                                type="text"
                                                name={field.name}
                                                id={field.name}
                                                value={field.value}
                                                {...field}
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.currentTarget.value
                                                    );
                                                    clearErrors(
                                                        "emailEnseignant"
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="p-field">
                                    <div style={{ marginTop: 15 }}>
                                        <label htmlFor="genre">genre</label>{" "}
                                        {getFormErrorMessage("genre")}
                                        <br />
                                    </div>
                                    <Controller
                                        control={control}
                                        name="genre"
                                        rules={{
                                            required: "champ obligatoire.",
                                        }}
                                        render={({ field }) => {
                                            return (
                                                <div className="radio-button">
                                                    <div>
                                                        <label
                                                            htmlFor="masculin"
                                                            style={{
                                                                marginRight:
                                                                    "8px",
                                                            }}
                                                        >
                                                            Masculin
                                                        </label>
                                                        <RadioButton
                                                            name={field.name}
                                                            value="masculin"
                                                            id="masculin"
                                                            checked={
                                                                field.value ===
                                                                "masculin"
                                                            }
                                                            onChange={(e) => {
                                                                field.onChange(
                                                                    e.value
                                                                );
                                                                clearErrors(
                                                                    "genre"
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    <div>
                                                        <label
                                                            htmlFor="feminin"
                                                            style={{
                                                                marginRight:
                                                                    "8px",
                                                            }}
                                                        >
                                                            Feminin
                                                        </label>
                                                        <RadioButton
                                                            value="feminin"
                                                            id="feminin"
                                                            name={field.name}
                                                            checked={
                                                                field.value ===
                                                                "feminin"
                                                            }
                                                            onChange={(e) => {
                                                                field.onChange(
                                                                    e.value
                                                                );
                                                                clearErrors(
                                                                    "genre"
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        )}

                        {activeIndex === 1 && (
                            <div style={{ marginTop: "10px", height: "350px" }}>
                                <div className="p-field">
                                    <label htmlFor="telEnseignant">
                                        Telephone
                                    </label>{" "}
                                    {getFormErrorMessage("telEnseignant")}
                                    <Controller
                                        control={control}
                                        name="telEnseignant"
                                        rules={{
                                            required: "champ obligatoire.",
                                        }}
                                        render={({ field }) => (
                                            <InputText
                                                type="text"
                                                name={field.name}
                                                id={field.name}
                                                value={field.value}
                                                {...field}
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.currentTarget.value
                                                    );
                                                    clearErrors(
                                                        "telEnseignant"
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="p-field">
                                    <label htmlFor="anneeNaissance">
                                        {" "}
                                        date naissance
                                    </label>{" "}
                                    {getFormErrorMessage("anneeNaisssance")}
                                    <Controller
                                        name="anneeNaissance"
                                        rules={{
                                            required: "champ obligatoire.",
                                        }}
                                        control={control}
                                        render={({ field }) => (
                                            <Calendar
                                                showIcon
                                                locale={addLocale}
                                                value={field.value}
                                                name={field.name}
                                                id={field.name}
                                                monthNavigator
                                                yearNavigator
                                                yearRange={`1960:${
                                                    date.getFullYear() - 1
                                                }`}
                                                monthNavigatorTemplate={
                                                    monthNavigatorTemplate
                                                }
                                                yearNavigatorTemplate={
                                                    yearNavigatorTemplate
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="p-field">
                                    <label htmlFor="lieuNaissance">
                                        Lieu Naissance
                                    </label>{" "}
                                    {getFormErrorMessage("lieuNaissance")}
                                    <Controller
                                        control={control}
                                        name="lieuNaissance"
                                        rules={{
                                            required: "champ obligatoire.",
                                        }}
                                        render={({ field }) => (
                                            <InputText
                                                id={field.name}
                                                name={field.name}
                                                type="text"
                                                value={field.value}
                                                {...field}
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.currentTarget.value
                                                    );
                                                    clearErrors(
                                                        "lieuNaissance"
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="p-field">
                                    <label htmlFor="diplomeEnseignant">
                                        Diplome
                                    </label>{" "}
                                    {getFormErrorMessage("diplomeEnseignant")}
                                    <Controller
                                        control={control}
                                        name="diplomeEnseignant"
                                        rules={{
                                            required: "champ obligatoire.",
                                        }}
                                        render={({ field }) => (
                                            <InputText
                                                type="text"
                                                name={field.name}
                                                id={field.name}
                                                value={field.value}
                                                {...field}
                                                onChange={(e) => {
                                                    field.onChange(
                                                        e.currentTarget.value
                                                    );
                                                    clearErrors(
                                                        "diplomeEnseignant"
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </div>

                                <div className="p-field">
                                    <div className="p-float">
                                        <div>
                                            <label htmlFor="statutEnseignant">
                                                statut
                                            </label>{" "}
                                            {getFormErrorMessage(
                                                "statutEnseignant"
                                            )}
                                            <Controller
                                                name="statutEnseignant"
                                                rules={{
                                                    required:
                                                        "champ obligatoire.",
                                                }}
                                                control={control}
                                                render={({ field }) => (
                                                    <Dropdown
                                                        filter
                                                        name={field.name}
                                                        value={field.value}
                                                        id={field.name}
                                                        options={listOfStatut}
                                                        optionLabel="libelleStatutEnseignant"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(
                                                                e.value
                                                            );
                                                            clearErrors(
                                                                "statutEnseignant"
                                                            );
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div style={{ marginLeft: "10px" }}>
                                            <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                className="p-button-info"
                                                onClick={() =>
                                                    setStatut(!statut)
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {activeIndex === 2 && (
                            <div style={{ marginTop: "10px", height: "350px" }}>
                                <div>
                                    <div className="p-field">
                                        <div className="p-float">
                                            <div>
                                                <label htmlFor="grade">
                                                    grade
                                                </label>{" "}
                                                {getFormErrorMessage("grade")}
                                                <Controller
                                                    control={control}
                                                    name="grade"
                                                    render={({ field }) => (
                                                        <Dropdown
                                                            filter
                                                            name={field.name}
                                                            value={field.value}
                                                            id={field.name}
                                                            options={
                                                                listOfGrade
                                                            }
                                                            optionLabel="libelleGrade"
                                                            {...field}
                                                            onChange={(e) => {
                                                                field.onChange(
                                                                    e.value
                                                                );
                                                                clearErrors(
                                                                    "grade"
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div style={{ marginLeft: "10px" }}>
                                                <Button
                                                    type="button"
                                                    icon="pi pi-plus"
                                                    className="p-button-info"
                                                    onClick={() =>
                                                        setGrade(!grade)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-field">
                                        <div className="p-float">
                                            <div>
                                                <label htmlFor="discipline">
                                                    discipline
                                                </label>
                                                <Controller
                                                    control={control}
                                                    name="discipline"
                                                    render={({ field }) => (
                                                        <Dropdown
                                                            filter
                                                            name={field.name}
                                                            value={field.value}
                                                            id={field.name}
                                                            options={
                                                                listOfDiscipline
                                                            }
                                                            optionLabel="libelleDiscipline"
                                                            onChange={(e) => {
                                                                field.onChange(
                                                                    e.value
                                                                );
                                                                const lib =
                                                                    listOfDiscipline.filter(
                                                                        (d) =>
                                                                            d.idDiscipline ===
                                                                            e
                                                                                .value
                                                                                .idDiscipline
                                                                    )[0]
                                                                        .libelleDiscipline;
                                                                setNewSpecialite(
                                                                    listOfSpecialite.filter(
                                                                        (dep) =>
                                                                            dep.libelleDiscipline ===
                                                                            lib
                                                                    )
                                                                );
                                                                clearErrors(
                                                                    "discipline"
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div style={{ marginLeft: "10px" }}>
                                                <Button
                                                    type="button"
                                                    icon="pi pi-plus"
                                                    className="p-button-info"
                                                    onClick={() =>
                                                        setDiscipline(
                                                            !discipline
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-field">
                                        <div className="p-float">
                                            <div>
                                                <label htmlFor="specialite">
                                                    specialite
                                                </label>{" "}
                                                {getFormErrorMessage(
                                                    "specialite"
                                                )}
                                                <Controller
                                                    control={control}
                                                    name="specialite"
                                                    render={({ field }) => (
                                                        <Dropdown
                                                            filter
                                                            name={field.name}
                                                            id={field.name}
                                                            value={field.value}
                                                            options={
                                                                newSpecialite
                                                            }
                                                            optionLabel="libelleSpecialite"
                                                            {...field}
                                                            onChange={(e) => {
                                                                field.onChange(
                                                                    e.value
                                                                );
                                                                clearErrors(
                                                                    "specialite"
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div style={{ marginLeft: "10px" }}>
                                                <Button
                                                    type="button"
                                                    icon="pi pi-plus"
                                                    className="p-button-info"
                                                    onClick={() =>
                                                        setSpecialite(
                                                            !specialite
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-field">
                                        <label htmlFor="universite">
                                            universite
                                        </label>{" "}
                                        {getFormErrorMessage("universite")}
                                        <Controller
                                            control={control}
                                            name="universite"
                                            render={({ field }) => {
                                                return (
                                                    <Dropdown
                                                        filter
                                                        name={field.name}
                                                        value={field.value}
                                                        id={field.name}
                                                        options={
                                                            listOfUniversite
                                                        }
                                                        optionLabel="libelleIesr"
                                                        onChange={(e) => {
                                                            field.onChange(
                                                                e.value
                                                            );
                                                            clearErrors(
                                                                "universite"
                                                            );
                                                            departementService
                                                                .getListeDepartement(
                                                                    parseInt(
                                                                        e.value
                                                                            .idIesr
                                                                    )
                                                                )
                                                                .then(
                                                                    (
                                                                        response
                                                                    ) => {
                                                                        setListOfDepartement(
                                                                            response
                                                                        );
                                                                    }
                                                                )
                                                                .catch(
                                                                    (errors) =>
                                                                        console.log(
                                                                            errors
                                                                        )
                                                                );
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="p-field">
                                        <label htmlFor="departement">
                                            departement
                                        </label>{" "}
                                        {getFormErrorMessage("departement")}
                                        <Controller
                                            control={control}
                                            name="departement"
                                            render={({ field }) => (
                                                <Dropdown
                                                    filter
                                                    name={field.name}
                                                    value={field.value}
                                                    id={field.name}
                                                    options={listOfDepartement}
                                                    optionLabel="lbDepartementEtab"
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e.value);
                                                        clearErrors(
                                                            "departement"
                                                        );
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>

                                    <br />

                                    <div className="p-field">
                                        <label htmlFor="fgActif">
                                            Enseignant en activité
                                        </label>{" "}
                                        {getFormErrorMessage("fgActif")}
                                        <Controller
                                            defaultValue={true}
                                            control={control}
                                            name="fgActif"
                                            render={({ field }) => (
                                                <Checkbox
                                                    name={field.name}
                                                    id="fgActif"
                                                    value={true}
                                                    {...field}
                                                    checked={field.value}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {saveEnseignantFooter}
                </form>
            </Dialog>

            {/* Add enseignant En DialogdBloc */}

            <Dialog
                visible={dialog.delete}
                style={{ width: "450px" }}
                header="Confirmez"
                modal
                footer={deleteEnseignantFooter}
                onHide={onHide}
            >
                <div className="confirmation-content">
                    <i
                        className="pi pi-exclamation-triangle p-mr-3"
                        style={{ fontSize: "2rem" }}
                    />
                    {"'this.state.deletionId'" && (
                        <span>Etes-vous sûr de supprimer cet élément ?</span>
                    )}
                </div>
            </Dialog>

            {/* Add Grade Dialog Bloc */}
            <Dialog
                className="card"
                visible={grade}
                style={{ width: "40vw", height: "85vh" }}
                onHide={() => setGrade(false)}
            >
                <div className="p-d-flex p-jc-center">
                    <h5 className="text-header">Ajouter un grade enseignant</h5>
                    <form
                        className="p-fluid"
                        onSubmit={handleSubmitGrade(handleGrade)}
                        style={{ height: "475px" }}
                    >
                        <div style={{ height: "500px" }}>
                            <div className="p-field">
                                <span>
                                    <label
                                        htmlFor="rang"
                                        style={{ marginRight: "15px" }}
                                        className={classNames({
                                            "p-error": errors.name,
                                        })}
                                    >
                                        Rang
                                    </label>{" "}
                                    {getFormErrorMessage("rang")}
                                    <Controller
                                        name="rang"
                                        control={controlGrade}
                                        rules={{
                                            required: "champ obligatoire.",
                                        }}
                                        render={({ field, fieldState }) => (
                                            <Dropdown
                                                id={field.name}
                                                name={field.name}
                                                value={field.value}
                                                autoFocus
                                                options={listRang}
                                                optionLabel="libelleRang"
                                                {...field}
                                                // onChange={(e)=>field.onChange(e.value)}
                                            />
                                        )}
                                    />
                                </span>
                            </div>
                            <div className="p-field">
                                <label htmlFor="libelleGrade">
                                    Libelle grade
                                </label>{" "}
                                {getFormErrorMessageGrade("libelleGrade")}
                                <Controller
                                    name="libelleGrade"
                                    control={controlGrade}
                                    rules={{ required: "champ obligatoire." }}
                                    render={({ field }) => (
                                        <InputText
                                            type="text"
                                            name={field.name}
                                            id={field.name}
                                            value={field.value}
                                            {...field}
                                        />
                                    )}
                                />
                            </div>

                            <div className="p-field">
                                <label htmlFor="volumeStatutaire">
                                    Volume Horaire
                                </label>{" "}
                                {getFormErrorMessageGrade("volumeStatutaire")}
                                <Controller
                                    control={controlGrade}
                                    rules={{ required: "champ obligatoire." }}
                                    name="volumeStatutaire"
                                    render={({ field }) => (
                                        <InputText
                                            keyfilter="num"
                                            id={field.name}
                                            name={field.name}
                                            value={field.value}
                                            type="text"
                                            {...field}
                                        />
                                    )}
                                />
                            </div>

                            <div className="p-field">
                                <label htmlFor="baseAttribution">
                                    Base Attribution
                                </label>{" "}
                                {getFormErrorMessageGrade("baseAttribution")}
                                <Controller
                                    control={controlGrade}
                                    rules={{ required: "champ obligatoire." }}
                                    name="baseAttribution"
                                    render={({ field }) => (
                                        <Dropdown
                                            options={baseAttribution}
                                            optionLabel="name"
                                            id={field.name}
                                            name={field.name}
                                            value={field.value}
                                            type="text"
                                            onChange={(e) =>
                                                field.onChange(e.value)
                                            }
                                        />
                                    )}
                                />
                            </div>

                            <div className="p-grid">
                                <div className="p-field p-col-6">
                                    <label htmlFor="coutCM">Coût CM</label>{" "}
                                    {getFormErrorMessageGrade("coutCM")}
                                    <Controller
                                        control={controlGrade}
                                        rules={{
                                            required: "champ obligatoire.",
                                        }}
                                        name="coutCM"
                                        render={({ field }) => (
                                            <InputText
                                                keyfilter="num"
                                                id={field.name}
                                                name={field.name}
                                                value={field.value}
                                                {...field}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="p-field p-col-6">
                                    <label htmlFor="coutTD">Coût TD</label>{" "}
                                    {getFormErrorMessageGrade("coutTD")}
                                    <Controller
                                        control={controlGrade}
                                        rules={{
                                            required: "champ obligatoire.",
                                        }}
                                        name="coutTD"
                                        render={({ field }) => (
                                            <InputText
                                                keyfilter="num"
                                                id={field.name}
                                                name={field.name}
                                                value={field.value}
                                                {...field}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="p-field">
                                <label htmlFor="heureMax">
                                    Heure Sup Autorise
                                </label>{" "}
                                {getFormErrorMessageGrade("heureMax")}
                                <Controller
                                    control={controlGrade}
                                    rules={{ required: "champ obligatoire." }}
                                    name="heureMax"
                                    render={({ field }) => (
                                        <InputText
                                            keyfilter="num"
                                            id={field.name}
                                            name={field.name}
                                            value={field.value}
                                            {...field}
                                        />
                                    )}
                                />
                            </div>

                            <div className="p-grid">
                                <div className="p-field p-col-6">
                                    <label htmlFor="montantPrime">
                                        Montant Prime
                                    </label>
                                    <Controller
                                        control={controlGrade}
                                        defaultValue="0"
                                        name="montantPrime"
                                        render={({ field }) => (
                                            <InputText
                                                keyfilter="num"
                                                id={field.name}
                                                name={field.name}
                                                value={field.value}
                                                {...field}
                                            />
                                        )}
                                    />
                                </div>

                                <div className="p-field p-col-6">
                                    <label htmlFor="montantVE">
                                        Montant Voyage d'Etude Autorise
                                    </label>
                                    <Controller
                                        control={controlGrade}
                                        defaultValue="0"
                                        name="montantVE"
                                        render={({ field }) => (
                                            <InputText
                                                keyfilter="num"
                                                id={field.name}
                                                name={field.name}
                                                value={field.value}
                                                {...field}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>

                        <div style={{ width: "150px" }}>
                            <Button
                                type="submit"
                                className="p-button-info"
                                label="Ajouter"
                                icon="pi pi-plus"
                                style={{ marginRight: "15px" }}
                            />
                        </div>
                    </form>
                </div>
            </Dialog>
            {/* Add Grade En DialogdBloc */}

            {/* Add discipline Dialog Bloc */}
            <Dialog
                className="card"
                visible={discipline}
                style={{ width: "35vw" }}
                onHide={() => setDiscipline(false)}
            >
                <div className="p-d-flex p-jc-center">
                    <h5 className="text-header">
                        Ajouter une discipline enseignant
                    </h5>
                    <form
                        className="p-fluid"
                        onSubmit={handleSubmitDiscipline(handleDiscipline)}
                    >
                        <div
                            className="p-field"
                            style={{ marginTop: "20px", height: "80px" }}
                        >
                            <label htmlFor="libelleDiscipline">
                                libelle{" "}
                                {getFormErrorMessageDiscipline(
                                    "libelleDiscipline"
                                )}
                            </label>
                            <Controller
                                control={controlDiscipline}
                                rules={{ required: "champ obligatoire." }}
                                name="libelleDiscipline"
                                render={({ field }) => (
                                    <InputText
                                        type="text"
                                        id={field.name}
                                        name={field.name}
                                        value={field.value}
                                        {...field}
                                    />
                                )}
                            />
                        </div>

                        <div style={{ width: "100px", margin: "10px 15px" }}>
                            <Button
                                type="submit"
                                className="p-button-info"
                                label="ajouter"
                                icon="pi pi-plus"
                                style={{ marginRight: "15px" }}
                            />
                        </div>
                    </form>
                </div>
            </Dialog>
            {/* Add discipline En DialogdBloc */}

            {/* Add Specialite Dialog Bloc */}
            <Dialog
                className="card"
                visible={specialite}
                style={{ width: "35vw" }}
                onHide={() => setSpecialite(false)}
            >
                <div className="p-d-flex p-jc-center">
                    <h5 className="text-header">
                        Ajouter une specialite enseignant
                    </h5>
                    <form
                        className="p-fluid"
                        onSubmit={handleSubmitSpecialite(handleSpecialite)}
                    >
                        <div style={{ height: "150px" }}>
                            <div
                                className="p-field"
                                style={{ marginTop: "20px" }}
                            >
                                <label htmlFor="discipline">discipline</label>{" "}
                                {getFormErrorMessageSpecialite("discipline")}
                                <Controller
                                    control={controlSpecialite}
                                    rules={{ required: "champ obligatoire." }}
                                    name="discipline"
                                    render={({ field }) => (
                                        <Dropdown
                                            type="text"
                                            id={field.name}
                                            name={field.name}
                                            options={listOfDiscipline}
                                            optionLabel="libelleDiscipline"
                                            value={field.value}
                                            onChange={(e) =>
                                                field.onChange(e.value)
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </div>
                            <div className="p-field">
                                <label htmlFor="libelleSpecialite">
                                    specialite
                                </label>{" "}
                                {getFormErrorMessageSpecialite(
                                    "libelleSpecialite"
                                )}
                                <Controller
                                    control={controlSpecialite}
                                    name="libelleSpecialite"
                                    rules={{ required: "champ obligatoire." }}
                                    render={({ field }) => (
                                        <InputText
                                            type="text"
                                            id={field.name}
                                            name={field.name}
                                            {...field}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div style={{ width: "100px", marginLeft: "10px" }}>
                            <Button
                                className="p-button-info"
                                type="submit"
                                label="Ajouter"
                                icon="pi pi-plus"
                            />
                        </div>
                    </form>
                </div>
            </Dialog>
            {/* Add Specialite En DialogdBloc */}

            {/* Add statut Dialog Bloc */}
            <Dialog
                className="card"
                visible={statut}
                style={{ width: "35vw" }}
                onHide={() => setStatut(false)}
            >
                <div className="p-d-flex p-jc-center">
                    <h5 className="text-header">
                        Ajouter un statut enseignant
                    </h5>
                    <form
                        className="p-fluid"
                        onSubmit={handleSubmitStatut(handleStatut)}
                    >
                        <div
                            className="p-field"
                            style={{ marginTop: "20px", height: "80px" }}
                        >
                            <label htmlFor="libelleStatutEnseignant">
                                Libelle{" "}
                                {getFormErrorMessageStatut(
                                    "libelleStatutEnseignant"
                                )}
                            </label>
                            <Controller
                                control={controlStatut}
                                rules={{ required: "champ obligatoire." }}
                                name="libelleStatutEnseignant"
                                render={({ field }) => (
                                    <InputText
                                        type="text"
                                        id={field.name}
                                        name={field.name}
                                        value={field.value}
                                        {...field}
                                    />
                                )}
                            />
                        </div>
                        <div style={{ width: "100px", margin: "10px 15px" }}>
                            <Button
                                className="p-button-info"
                                type="submit"
                                label="Ajouter"
                                icon="pi pi-plus"
                            />
                        </div>
                    </form>
                </div>
            </Dialog>
            {/* Add Specialite En DialogdBloc */}

            {/* Add enseignant En DialogdBloc */}

            {/* Add Nomination Bloc */}
            <Dialog
                visible={nomination.add}
                //footer={nominationFooter}
                style={{ width: "40vw", height: "75vh" }}
                onHide={() => setNomination({ ...nomination, add: false })}
            >
                <div className="p-d-flex p-jc-center">
                    <form
                        className="p-fluid"
                        onSubmit={handleSubmitNomination(
                            handleNominationSubmit
                        )}
                    >
                        <div>
                            <div className="p-field">
                                <span>
                                    <label htmlFor="enseignant">
                                        Enseignant
                                    </label>{" "}
                                    {getFormErrorMessageNomination(
                                        "enseignant"
                                    )}
                                    <Controller
                                        name="enseignant"
                                        control={controlNomination}
                                        // rules={{ required:"champ obligatoire"}}
                                        render={({ field }) => {
                                            return (
                                                <Dropdown
                                                    disabled
                                                    style={{ height: "43px" }}
                                                    id={field.name}
                                                    name={field.name}
                                                    value={field.value}
                                                    options={[
                                                        getValuesNomination(
                                                            "enseignant"
                                                        ),
                                                    ]}
                                                    {...field}
                                                    onChange={(e) =>
                                                        field.onChange(e.value)
                                                    }
                                                    // itemTemplate={itemEnseignantTemplate}
                                                    optionLabel="nomPrenomEnseignant"
                                                />
                                            );
                                        }}
                                    />
                                </span>
                            </div>
                            <div className="p-field">
                                <span>
                                    <label htmlFor="responsabilite">
                                        Responsabilite
                                    </label>{" "}
                                    {getFormErrorMessageNomination(
                                        "responsabilite"
                                    )}
                                    <Controller
                                        name="responsabilite"
                                        control={controlNomination}
                                        // rules={{ required:"champ obligatoire"}}
                                        render={({ field }) => (
                                            <Dropdown
                                                style={{ height: "45px" }}
                                                id={field.name}
                                                name={field.name}
                                                value={field.value}
                                                options={listOfResponsabilite}
                                                optionLabel="libelleResponsabilite"
                                                {...field}
                                                onChange={(e) =>
                                                    field.onChange(e.value)
                                                }
                                            />
                                        )}
                                    />
                                </span>
                            </div>
                            <div className="p-grid">
                                <div className="p-field p-col-6">
                                    <label htmlFor="dateDebutNomination">
                                        {" "}
                                        Date du début
                                    </label>{" "}
                                    {getFormErrorMessageNomination(
                                        "dateDebutNomination"
                                    )}
                                    <Controller
                                        // rules={{ required:"champ obligatoire"}}
                                        name="dateDebutNomination"
                                        control={controlNomination}
                                        render={({ field }) => (
                                            <Calendar
                                                showIcon
                                                locale={addLocale}
                                                id={field.name}
                                                name={field.name}
                                                monthNavigator
                                                yearNavigator
                                                yearRange={`1960:${new Date().getFullYear()}`}
                                                monthNavigatorTemplate={
                                                    monthNavigatorTemplate
                                                }
                                                yearNavigatorTemplate={
                                                    yearNavigatorTemplate
                                                }
                                                {...field}
                                                onChange={(e) =>
                                                    field.onChange(e.value)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                <div className="p-field p-col-6">
                                    <label htmlFor="dateFinNomination">
                                        {" "}
                                        Date de fin
                                    </label>
                                    <Controller
                                        name="dateFinNomination"
                                        control={controlNomination}
                                        render={({ field }) => (
                                            <Calendar
                                                showIcon
                                                locale={addLocale}
                                                id={field.name}
                                                name={field.name}
                                                monthNavigator
                                                yearNavigator
                                                yearRange={`1960:${new Date().getFullYear()}`}
                                                monthNavigatorTemplate={
                                                    monthNavigatorTemplate
                                                }
                                                yearNavigatorTemplate={
                                                    yearNavigatorTemplate
                                                }
                                                {...field}
                                                onChange={(e) =>
                                                    field.onChange(e.value)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="p-field">
                                <div className="card">
                                    <span
                                        style={{
                                            color: "red",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        Cette nouvelle responsabilité
                                        devrait-elle être utilisé pour le calcul
                                        du volume horaire apprès Rabattement ?
                                    </span>
                                    <Controller
                                        defaultValue={false}
                                        control={controlNomination}
                                        name="responsabilitePriseEnCompte"
                                        render={({ field }) => (
                                            <Checkbox
                                                name={field.name}
                                                id={field.name}
                                                value={true}
                                                {...field}
                                                checked={field.value}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>

                        <div>
                            <Button
                                type="submit"
                                className="p-button-info"
                                label="ajouter"
                                icon="pi pi-plus"
                                style={{ width: "100px", marginRight: "15px" }}
                            />
                        </div>
                    </form>
                </div>
            </Dialog>
            {/* Add Nomination EndBloc */}

            {/* Add rang Bloc */}
            <Dialog
                className="card"
                visible={rang}
                style={{ width: "35vw" }}
                onHide={() => setRang(false)}
            >
                <div className="p-d-flex p-jc-center">
                    <h5 className="text-header">Ajouter un rang</h5>
                    <form
                        onSubmit={handleSubmitRang(handleRangSubmit)}
                        className="p-fluid"
                    >
                        <div className="p-field" style={{ height: "100px" }}>
                            <label
                                htmlFor="libelleRangEnseignant"
                                className={classNames({
                                    "p-error": errors.name,
                                })}
                            >
                                rang{" "}
                                {getFormErrorMessageRang(
                                    "libelleRangEnseignant"
                                )}
                            </label>
                            <Controller
                                name="libelleRangEnseignant"
                                control={controlRang}
                                rules={{ required: "champ obligatoire." }}
                                render={({ field, fieldState }) => (
                                    <InputText
                                        type="text"
                                        id={field.name}
                                        value={field.value}
                                        {...field}
                                        className={classNames({
                                            "p-invalid": fieldState.invalid,
                                        })}
                                    />
                                )}
                            />
                        </div>

                        <div style={{ width: "100px" }}>
                            <Button
                                type="submit"
                                className="p-button-info"
                                label="ajouter"
                                icon="pi pi-plus"
                                style={{ marginRight: "15px" }}
                            />
                        </div>
                    </form>
                </div>
            </Dialog>
            {/* Add rang EndBloc */}
        </div>
    );
}
