import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames'
import { DataTable } from 'primereact/datatable';
import { InputCalendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Notify } from '../../../components/Notify';
import { Spinner } from '../../../components/Spinner';
import { ToastContainer} from 'react-toastify';
import { InvitationService } from '../../service/InvitationService';
import EnseignantService from '../../service/EnseignantService';
import { ParcoursService } from '../../../lmd/service/ParcoursService'
import UniteEnseignementService from '../../../lmd/service/UEService'
import { ParcoursUEService } from '../../../lmd/service/ParcoursUEService';
import { Calendar } from '@fullcalendar/core';
import { SemestreService } from '../../../lmd/service/SemestreService';
import { options } from 'preact';
import { MultiSelect } from 'primereact/multiselect';
import { TypeActiviteService } from '../../../lmd/service/TypeActiviteService';

export default function InvitationEnseignantSortant(){

    const tokenDecoded = localStorage.getItem('tokenDecoded');
    const token = JSON.parse(tokenDecoded);

    //console.log(token)

    const [selectedEntites, setSelectedEntites] = useState(null)

    const [listeInvitation, setListeInvitation] = useState([])
    const [listeElementConstitutif, setListeElementConstitutif] = useState([])
    const [listeEnseignant, setListeEnseignant] = useState([])
    const [informationInvitation, setInformationInvitation] = useState({})
    const [listeParcours, setListeParcours] = useState([])
    const [listeSemestre, setListeSemestre] = useState([])
    const [curentSemestre, setCurentSemestre] = useState({})
    const [listeUniteEnseignement, setListeUniteEnseignement] = useState([])

    const [curentEnseignant, setCurentEnseignant] = useState({})
    const [curentParcours, setCurentParcours] = useState({})
    const [curentElementConstitutif, setCurentElementConstitutif] = useState({})
    const [curentUniteEnseignement, setCurentUniteEnseignement] = useState({})
    const [dateDebut, setDateDebut] = useState('')
    const [dateFin, setDateFin] = useState('')    
    
    const [submitted, setSubmitted] = useState(false)
    const [globalFilter, setGlobalFilter] = useState(null)
    const [loading, setLoading] = useState(false)
    const [invitationDialog,setInvitationDialog] = useState(false)
    const [spin,setSpin] = useState(false)
    const [idDepartement, setIdDepartement] = useState(token.code_departement)
    const [detailsInvitationDialog, setDetailsInvitationDialog] = useState(false)
    const [deleteEntiteDialog, setDeleteEntiteDialog] = useState(false)
    const [confirmerEnvoiInvitationDialog, setConfirmerEnvoiInvitationDialog] = useState(false)
    const [editInvitationDialog, setEditInvitationDialog] = useState(false)

    const notify = new Notify()
    const spinner = new Spinner()

    const invitationService = new InvitationService()
    const parcoursService = new ParcoursService()
    const parcoursUEService = new ParcoursUEService()
    const enseignantService = new EnseignantService()
    const semestreService = new SemestreService()
    //const typeActiviteService = new TypeActiviteService()

    useEffect(() => {

            setLoading(true)
            invitationService.getListeInvitationSortantDepartement(idDepartement).then(data => {

                //console.log(data)

                setListeInvitation(data)
                setLoading(false)
            }).catch(error => {
                console.log(error)
                notify.echec(error.response.data.message)
                setLoading(false)
            })


            //On positionne la liste des parcours du departement
            parcoursService.getListeParcoursDepartementByIdDepartement(idDepartement).then(data => {
                setListeParcours(data)
            }).catch(error => {
                console.log(error)
                notify.echec(error.response.data.message)
            })

            //On recupere les enseignants qui sont hors IESR
            enseignantService.getListeEnseignantHorsIesr(idDepartement).then(data => {
                //console.log(data)
                setListeEnseignant(data)
            }).catch(error => {
                console.log(error)
                notify.echec(error.response.data.message)
            })

        }, []
    );



    
    const saveInvitation = () => {

        setSubmitted(true);

        if(curentEnseignant.idEnseignant && curentElementConstitutif.idElementConstitutif && dateDebut && dateFin && dateDebut<=dateFin){
            setSpin(true);

            let newInvitation = {
                enseignant: curentEnseignant,
                idElementConstitutif: curentElementConstitutif.idElementConstitutif,
                dateDebutCours: dateDebut,
                dateFinCours: dateFin,
                idDepartement: idDepartement,
                idParcours: curentParcours.idParcours,
                idSemestre: curentSemestre.idSemestre
            }

            invitationService.enregistrerInvitation(newInvitation).then(data => {
                notify.success()
                setSpin(false)
                setCurentEnseignant({})
                setCurentParcours({})
                setCurentElementConstitutif({})
                setListeSemestre([])
                setListeUniteEnseignement([])
                setDateDebut('')
                setDateFin('')
                setInvitationDialog(false)

                setLoading(true)
                invitationService.getListeInvitationSortantDepartement(idDepartement).then(data => {
                    setListeInvitation(data)
                    setLoading(false)
                }).catch(error => {
                    console.log(error)
                    notify.echec(error.response.data.message)
                    setLoading(false)
                })

                //Appel ici de la liste des invitation
            }).catch(error => {
                console.log(error)
                setSpin(false)
                notify.echec(error.response.data.message)
            })

            setSubmitted(false)
        }
    }
    

    const saveEditionInvitation = () => {

        let newInvitation = informationInvitation

        if(dateDebut && dateFin && dateDebut<dateFin){
            newInvitation['enseignant']= curentEnseignant
            newInvitation['dateDebutCours'] = dateDebut
            newInvitation['dateFinCours'] = dateFin
            newInvitation['posDemande']  = "DEPARTEMENT DEMANDEUR"
            newInvitation['etatActuel'] = "Initial"
            newInvitation['justificatif'] = ""


            setSpin(true)
            invitationService.updateInvitationEnseignant(newInvitation.idInvitationEnseignant, newInvitation).then(data => {

                setSpin(false)
                notify.success()
                setLoading(true)
                setEditInvitationDialog(false)
                setCurentEnseignant({})
                setDateDebut('')
                setDateFin('')
                setInformationInvitation(newInvitation)
    
                invitationService.getListeInvitationSortantDepartement(idDepartement).then(data => {
                    setListeInvitation(data)
                    setLoading(false)
                }).catch(error => {
                    console.log(error)
                    notify.echec(error.response.data.message)
                    setLoading(false)
                })
    
            }).catch(error => {
                console.log(error)
                notify.echec(error.response.data.message)
                setSpin(false)
            })
        }
        
    }


    const hideDialog = () => {
        setCurentEnseignant({})
        setCurentParcours({})
        setCurentElementConstitutif({})
        setListeSemestre([])
        setListeUniteEnseignement([])
        setDateDebut('')
        setDateFin('')
        setInvitationDialog(false)
        setSubmitted(false)
    }

    const hideEditionDialog = () => {
        setCurentEnseignant({})
        setCurentParcours({})
        setCurentElementConstitutif({})
        setListeSemestre([])
        setListeUniteEnseignement([])
        setDateDebut('')
        setDateFin('')
        setEditInvitationDialog(false)
        setSubmitted(false)
    }


    const onEnseignantChange = (event) => {
        setCurentEnseignant(event.target.value)
    }

    const onParcoursChange = (event) => {
        setCurentParcours(event.target.value)

        semestreService.get(event.target.value.idParcours).then(data => {
            setListeSemestre(data)
        })

        setListeUniteEnseignement([])
        setListeElementConstitutif([])
    }

    const onDateDebutChange = (event) => {
        setDateDebut(event.target.value)
    }

    const onDateFinChange = (event) => {
        setDateFin(event.target.value)
    }

    const onSemestreChange = (event) => {
        setCurentSemestre(event.target.value)

        parcoursUEService.get(curentParcours.idParcours, event.target.value.idSemestre).then(data => {
            setListeUniteEnseignement(data)
        })
        setListeElementConstitutif([])

    }

    const onUniteEnseignementChange = (event) => {
        setCurentUniteEnseignement(event.target.value)
        setListeElementConstitutif(event.target.value.uniteEnseignement.listeElementConstitutif)
        if((event.target.value.uniteEnseignement.listeElementConstitutif).length===1){
            setCurentElementConstitutif(event.target.value.uniteEnseignement.listeElementConstitutif[0])
        }
    }

    const onElementConstitutifChange = (event) => {
        setCurentElementConstitutif(event.target.value)
    }

    const detailsInvitation = (data) => {
        setInformationInvitation(data)

        setDetailsInvitationDialog(true)

        //console.log(data)
    }

    const supprimerInvitation = (invitation) => {
        setInformationInvitation(invitation)
        setDeleteEntiteDialog(true)
    }

    const hideDeleteEntiteDialog = () => {
        setDeleteEntiteDialog(false)
        setSpin(false)
    }


    const hideDetailInvitation = () => {
        setDetailsInvitationDialog(false)
        setSpin(false)
    }

    const editerInvitationEnseignant = (invitationEnseignant) => {

        //console.log(invitationEnseignant)
        setCurentEnseignant(invitationEnseignant.enseignant)
        setDateDebut(invitationEnseignant.dateDebutCours)
        setDateFin(invitationEnseignant.dateFinCours)
        setEditInvitationDialog(true)
    }

    const deleteInvitation = () => {

        let curentInvitation = informationInvitation
        setSpin(true)
        invitationService.deleteInvitation(curentInvitation.idInvitationEnseignant).then(data => {

            setSpin(false)
            setLoading(true)
            notify.success()
            setDetailsInvitationDialog(false)
            setInformationInvitation({})
            setDeleteEntiteDialog(false)
            invitationService.getListeInvitationSortantDepartement(idDepartement).then(data => {
                setListeInvitation(data)
                setLoading(false)
            }).catch(error => {
                console.log(error)
                notify.echec(error.response.data.message)
                setLoading(false)
            })

        }).catch(error => {
            console.log(error)
            notify.echec(error.response.data.message)
            setSpin(false)
        })

    }


    const validerInvitationEnseignant = (informationInvitation) => {
        setInformationInvitation(informationInvitation)
        setConfirmerEnvoiInvitationDialog(true)
    }

    const hideConfirmerInvitationDialog = () => {
        setConfirmerEnvoiInvitationDialog(false)
        setSpin(false)
    }

    const confirmerValidationInvitation = () => {
        setSpin(true)

        let invitationObject = informationInvitation

        invitationObject['posDemande'] = "IESR DEMANDEUR"
        invitationObject['etatActuel'] = "Receptionner"

        invitationService.updateInvitationEnseignant(informationInvitation.idInvitationEnseignant, invitationObject).then(data => {

            setSpin(false)
            setLoading(true)
            notify.success()

            setDetailsInvitationDialog(false)
            setInformationInvitation({})
            setConfirmerEnvoiInvitationDialog(false)

            invitationService.getListeInvitationSortantDepartement(idDepartement).then(data => {
                setListeInvitation(data)
                setLoading(false)
            }).catch(error => {
                console.log(error)
                notify.echec(error.response.data.message)
                setLoading(false)
            })

        }).catch(error => {
            console.log(error)
            notify.echec(error.response.data.message)
            setSpin(false)
        })

    }



    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-info" className="p-button-warning p-mr-2" title="Détails" style={{marginRight: '.5em'}} onClick={() => detailsInvitation(rowData)} />
                <Button icon="pi pi-check-circle" className="p-button-info p-mr-2" title="Valider et transmetre" style={{marginRight: '.5em'}} onClick={() => validerInvitationEnseignant(rowData)} />
                <Button icon="pi pi-trash" className="p-button-danger p-mr-2" title="Supprimer l'invitation" style={{marginRight: '.5em'}} onClick={() => supprimerInvitation(rowData)} />
            </React.Fragment>
        );
    }

    const header = (
        <div className="table-header p-col-12">
            <span className="p-input-icon-left">
                <Button style={{marginLeft : 10}} label="Ajouter" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={()=>setInvitationDialog(true)} />
                <InputText style={{marginLeft : 20,width:"300px"}} type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
            </span>
        </div>
    );


    const detailsInvitationFooter = (
        <React.Fragment>
            <Button label="Editer invitation" icon="pi pi-pencil" className="p-button-text" onClick={() => editerInvitationEnseignant(informationInvitation)} />
            <Button label="Valider & envoyer" icon="pi pi-check-circle" className="p-button-text" onClick={() => validerInvitationEnseignant(informationInvitation)} />
            <Button label="Supprimer" icon="pi pi-trash" className="p-button-text" onClick={() => supprimerInvitation(informationInvitation)} />
            <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideDetailInvitation} />
        </React.Fragment>
    )

    
    const deleteEntiteDialogFooter = (
        <React.Fragment>
            <Button label="Non" disabled={spin?true:false}  icon={spin?"pi pi-spin pi-spinner":"pi pi-ban"} className="p-button-text" onClick={hideDeleteEntiteDialog} />
            <Button label="Oui" disabled={spin?true:false} icon="pi pi-check" className="p-button-text" onClick={deleteInvitation} />
        </React.Fragment>
    );


    const confirmerEnvoiInvitationFooter = (
        <React.Fragment>
            <Button label="Non" disabled={spin?true:false}  icon={spin?"pi pi-spin pi-spinner":"pi pi-ban"} className="p-button-text" onClick={hideConfirmerInvitationDialog} />
            <Button label="Oui" disabled={spin?true:false} icon="pi pi-check" className="p-button-text" onClick={confirmerValidationInvitation} />
        </React.Fragment>
    );
    
    

    const actionHeader = <Button type="button" icon="pi pi-cog"/>;

    return (
        <div className="datatable-crud-demo">
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="card">
                
                {header}
                {loading ?
                <div className="p-grid">
                    <div className="p-col-6" ></div>
                    <div className="p-col-6" >
                        {spinner.loadingSpinner("spin", "black")}
                    </div>
                </div>
                :
                <DataTable value={listeInvitation} selection={selectedEntites} onSelectionChange={(e) => setSelectedEntites(e.value)}
                    dataKey="id" paginator rows={10} 
                    globalFilter={globalFilter}
                    header="Liste des invitations d'enseignants pour le département">
                    <Column field="enseignant.nomPrenomEnseignant" header="Enseignant" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="enseignant.nomIes" header="Université" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="dateDebutCours" header="Date de debut du cours" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="dateFinCours" header="Date de fin du cours" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="etatActuel" header="Etat de l'invitation" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column field="posDemande" header="Position de l'invitation" filterMatchMode="contains" sortable={true} filter={true}></Column>
                    <Column header={actionHeader} body={actionBodyTemplate} style={{textAlign:'center', width: '160px'}}></Column>
                </DataTable>
                }
            </div>



            <Dialog visible={editInvitationDialog} style={{ width: '75%' }} header="Edition d'une invitation (Enseignant et période de la mission)" modal className="p-fluid" onHide={hideEditionDialog}>
                <hr />
                <div className="p-grid">

                    <div className="p-col-8">
                        <div className="p-field">
                            <label htmlFor="enseignant">Sélectionner l'enseignant</label>
                            <Dropdown optionLabel="nomPrenomEnseignant" optionValue={curentEnseignant.nomPrenomEnseignant} value={curentEnseignant}  options={listeEnseignant} onChange={(e) => {onEnseignantChange(e)}} required autoFocus className={classNames({ 'p-invalid': submitted })} />
                            {submitted && !curentEnseignant.nomPrenomEnseignant && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>}              
                        </div>
                    </div>

                    <div className="p-col-2" >
                        <div className="p-field">
                            <label htmlFor="dateDebut">Date de debut de la mission</label>
                            <InputText  type="date" id="dateDebut" value={dateDebut}  onChange={(e) => {onDateDebutChange(e)}} required autoFocus className={classNames({ 'p-invalid': submitted })} />
                            {submitted && !dateDebut && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>}              
                        </div>
                    </div>

                    <div className="p-col-2" >
                        <div className="p-field">
                            <label htmlFor="dateFin">Date de fin de la mission</label>
                            <InputText type="date" id="dateFin" value={dateFin}  onChange={(e) => {onDateFinChange(e)}} required autoFocus className={classNames({ 'p-invalid': submitted })} />
                            {submitted && !dateFin && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>}
                            {submitted && dateFin && dateDebut && dateDebut>dateFin && <small className="p-invalid" style={{color : "red"}}>La date de fin du cours ne doit être inférieur à la date debut</small>}           
                        </div>
                    </div>

                </div>

                <hr/>

                <div  style={{width:"200px",display:"flex"}} className="mt-3">
                    <Button label="Quitter" icon="pi pi-times" className="p-button-danger mr-2" type='button' onClick={hideEditionDialog} />
                    <Button label='Enregistrer' icon={spin?"pi pi-spin pi-spinner":"pi pi-save"} className='p-button-success mr-2' onClick={saveEditionInvitation}  disabled={spin?true:false} />
                </div>
            </Dialog>







 
            <Dialog visible={invitationDialog} style={{ width: '95%' }} header="Edition d'une invitation" modal className="p-fluid" onHide={hideDialog}>
                <hr />
                <div className="p-grid">

                    <div className="p-col-8">
                        <div className="p-field">
                            <label htmlFor="enseignant">Sélectionner l'enseignant</label>
                            <Dropdown optionLabel="nomPrenomEnseignant" optionValue={curentEnseignant.nomPrenomEnseignant} value={curentEnseignant}  options={listeEnseignant} onChange={(e) => {onEnseignantChange(e)}} required autoFocus className={classNames({ 'p-invalid': submitted })} />
                            {submitted && !curentEnseignant.nomPrenomEnseignant && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>}              
                        </div>
                    </div>

                    <div className="p-col-2" >
                        <div className="p-field">
                            <label htmlFor="dateDebut">Date de debut de la mission</label>
                            <InputText  type="date" id="dateDebut" value={dateDebut}  onChange={(e) => {onDateDebutChange(e)}} required autoFocus className={classNames({ 'p-invalid': submitted })} />
                            {submitted && !dateDebut && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>}              
                        </div>
                    </div>

                    <div className="p-col-2" >
                        <div className="p-field">
                            <label htmlFor="dateFin">Date de fin de la mission</label>
                            <InputText type="date" id="dateFin" value={dateFin}  onChange={(e) => {onDateFinChange(e)}} required autoFocus className={classNames({ 'p-invalid': submitted })} />
                            {submitted && !dateFin && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>}
                            {submitted && dateFin && dateDebut && dateDebut>dateFin && <small className="p-invalid" style={{color : "red"}}>La date de fin du cours ne doit être inférieur à la date debut</small>}           
                        </div>
                    </div>

                </div>

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="p-field">
                            <label htmlFor="libelleParcours">Selectionner le parcours beneficiaire de la mission</label>
                            <Dropdown optionLabel="libelleParcours" optionValue={curentParcours.libelleParcours} value={curentParcours}  options={listeParcours} onChange={(e) => {onParcoursChange(e)}} required autoFocus className={classNames({ 'p-invalid': submitted })} />
                            {submitted && !curentParcours.libelleParcours && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>}              
                        </div>
                    </div>
                </div>

                <div className="p-grid">
                    <div className="p-col-4" >
                        <div className="p-field">
                            <label htmlFor="libelleSemestre">Selectionner le semestre</label>
                            <Dropdown optionLabel="libelleSemestre" value={curentSemestre}  onChange={(e) => {onSemestreChange(e)}} options={listeSemestre}  required autoFocus className={classNames({ 'p-invalid': submitted })} />
                            {submitted && !curentElementConstitutif.libelleElementConstitutif && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>}              
                        </div>
                    </div>

                    <div className="p-col-4" >
                        <div className="p-field">
                            <label htmlFor="libelleUniteEnseignement">Selectionner l'unite d'enseignement concernee</label>
                            <Dropdown  optionLabel="libelleUniteEnseignement" value={curentUniteEnseignement} optionValue={curentUniteEnseignement.libelleUniteEnseignement} onChange={(e) => {onUniteEnseignementChange(e)}} required autoFocus className={classNames({ 'p-invalid': submitted })} options={listeUniteEnseignement} />
                            {submitted && !curentUniteEnseignement.libelleUniteEnseignement && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>}              
                        </div>
                    </div>

                    <div className="p-col-4" >
                        <div className="p-field">
                            <label htmlFor="libelleElementConstitutif">Selectionner l'element constitutif</label>
                            <Dropdown optionLabel="libelleElementConstitutif" value={curentElementConstitutif} optionValue={curentElementConstitutif.libelleElementConstitutif}  onChange={(e) => {onElementConstitutifChange(e)}} required autoFocus className={classNames({ 'p-invalid': submitted })} options={listeElementConstitutif} />
                            {submitted && !curentElementConstitutif.libelleElementConstitutif && <small className="p-invalid" style={{color : "red"}}>Ce champs est obligatoire</small>}              
                        </div>
                    </div>
                </div>

                <hr/>
                <div  style={{width:"200px",display:"flex"}} className="mt-3">
                    <Button label="Quitter" icon="pi pi-times" className="p-button-danger mr-2" type='button' onClick={hideDialog} />
                    <Button label='Enregistrer' icon={spin?"pi pi-spin pi-spinner":"pi pi-save"} className='p-button-success mr-2' onClick={saveInvitation}  disabled={spin?true:false} />
                </div>
            </Dialog>


            <Dialog visible={detailsInvitationDialog} style={{width: '80%'}} header="Détails de l'invitation" modal footer={detailsInvitationFooter} onHide={hideDetailInvitation}>
                <hr/>
                {informationInvitation.enseignant && (
                    <React.Fragment>
                        <div className='p-grid'>
                    <div className='p-col-6'>

                        <div className='card'>
                            <div className='card-header'>
                                Détails sur l'enseignant
                            </div>
                            <div className='card-body'  style={{ flex: '1 1 auto',paddingTop: '1.25rem', paddingRight: '0rem', paddingLeft: '0rem', paddingBottom: '0rem'}}>

                                <table className='table table-hover table-bordered table-striped'>
                                    <tbody>
                                        <tr>
                                            <td>Nom et prenom de l'enseignant</td>
                                            <td>{informationInvitation.enseignant.nomPrenomEnseignant}</td>
                                        </tr>
                                        <tr>
                                            <td>Grade enseignant</td>
                                            <td>{informationInvitation.enseignant.grade.libelleGrade}</td>
                                        </tr>
                                        <tr>
                                            <td>Université d'origine</td>
                                            <td>{informationInvitation.enseignant.nomIes}</td>
                                        </tr>
                                        <tr>
                                            <td>UFR</td>
                                            <td>{informationInvitation.enseignant.nomEtablissement}</td>
                                        </tr>
                                        <tr>
                                            <td>Département</td>
                                            <td>{informationInvitation.enseignant.lbDepartementEtab}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>

                    <div className='p-col-6'>


                        <div className='card'>
                            <div className='card-header'>
                                Détails sur le cours d'invitation
                            </div>
                            <div className='card-body'  style={{ flex: '1 1 auto',paddingTop: '1.25rem', paddingRight: '0rem', paddingLeft: '0rem', paddingBottom: '0rem'}}>
                                <table className='table table-hover table-bordered table-striped'>
                                    <tbody>
                                        <tr>
                                            <td>Parcours concerné par le cours</td>
                                            <td>{informationInvitation.libelleParcours}</td>
                                        </tr>
                                        <tr>
                                            <td>Niveau</td>
                                            <td>{informationInvitation.libelleNiveau}</td>
                                        </tr>
                                        <tr>
                                            <td>Element constitutif</td>
                                            <td>{informationInvitation.libelleElementConstitutif}</td>
                                        </tr>
                                        <tr>
                                            <td>Date de debut du cours</td>
                                            <td>{informationInvitation.dateDebutCours}</td>
                                        </tr>
                                        <tr>
                                            <td>Date de fin du cours</td>
                                            <td>{informationInvitation.dateFinCours}</td>
                                        </tr>
                                        <tr>
                                            <td>Evolution de l'invitation</td>
                                            <td>{informationInvitation.posDemande}</td>
                                        </tr>
                                        <tr>
                                            <td>Etat de l'invitation</td>
                                            <td>{informationInvitation.etatActuel}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>


                    </div>


                    {informationInvitation.etatActuel==="Rejeter" &&

                        <div className='p-col-12'>
                            <div className="p-grid">
                                <div className="p-col-12" >
                                    <div className="p-field">
                                        <label htmlFor="causeRejet">Motif de rejet de l'invitation</label>
                                        <hr/>
                                        {informationInvitation.justificatif}
                                    </div>
                                </div>
                            </div>
                        </div>

                    }


                </div>
                    </React.Fragment>
                )
                }
                <hr/>
            </Dialog>

            <Dialog visible={deleteEntiteDialog} style={{ width: '450px' }} header="Confirmer" modal footer={deleteEntiteDialogFooter} onHide={hideDeleteEntiteDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {informationInvitation && <span>Etes vous sûr de vouloir supprimer cette invitation ? </span>}
                </div>
            </Dialog>

            <Dialog visible={confirmerEnvoiInvitationDialog} style={{ width: '450px' }} header="Confirmmation d'envoi invitation" modal footer={confirmerEnvoiInvitationFooter} onHide={hideConfirmerInvitationDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-info-circle p-mr-3" style={{ fontSize: '2rem'}} />
                    <center>
                        {informationInvitation && <span>Etes vous sûr de vouloir confirmer votre accord pour cette invitation ? </span>}
                    </center>
                </div>
            </Dialog>

        </div>
    )

}