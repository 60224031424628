import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Chart } from "primereact/chart";
import { Panel } from 'primereact/panel';
import { Dialog } from 'primereact/dialog';
import { Controller, useForm } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { ToastContainer } from "react-toastify";
import ProgrammationService from "../service/ProgrammationService";
import { SemestreService } from "../../lmd/service/SemestreService";
import { CohorteServices } from "../service/CohorteServices";
import { Spinner } from "../../components/Spinner";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import { AnneeService } from "../../lmd/service/AnneeService";
import DashboardService from "../service/DashboardService";
import {IesrService} from "../../lmd/service/IesrService"

export default function DashbordDA(){

    const tokenDecoded = localStorage.getItem('tokenDecoded')
    const token = JSON.parse(tokenDecoded);
    const campusVpEip = localStorage.getItem('campusVpEip')
    const campusEnseignant = localStorage.getItem('campusEnseignant')
    const campusEtudiant = localStorage.getItem('campusEtudiant')
    const campusDirecteur = localStorage.getItem('campusDirecteur')
    const campusDa = localStorage.getItem('campusDa')
    const campusDepartement = localStorage.getItem('campusDepartement')
    const isVp = parseInt(JSON.parse(campusVpEip))
    const isEnseignant = parseInt(JSON.parse(campusEnseignant))
    const isEtudiant = parseInt(JSON.parse(campusEtudiant))
    const isDepartement = parseInt(JSON.parse(campusDepartement))
    const isDirecteur = parseInt(JSON.parse(campusDirecteur))
    const isDA = parseInt(JSON.parse(campusDa))

    const {control:searchControl,setValue:searchSetValue} = useForm({defaultValues:{semestre:'',parcours:'',cohorte:''}});
    const [programmation, setprogrammation] = useState({nbProgrammer:undefined,nbRealiser:undefined});
    const [listSemestreSearch, setListSemestreSearch] = useState([]);
    const [listeAnnee, setListeAnnee] = useState([]);
    const [annee, setAnnee] = useState([]);
    const [departements, setDepartements] = useState([]);
    const [departement, setDepartement] = useState({});
    const [etablissements, setEtablissements] = useState([]);
    const [etablissement, setEtablissement] = useState({});
    const [loading, setloading] = useState(false);
    const [listCohorteSearch, setlistCohorteSearch] = useState([]);
    const [programmationByParcours, setprogrammationByParcours] = useState([]);
    const [programmationFilter, setprogrammationFilter] = useState([]);
    const [listParcours, setlistParcours] = useState([]);
    const [listeElementsEffectues, setListeElementsEffectues] = useState([]);
    const [enseignant, setEnseignant] = useState(null);
    const [infoVhEns, setInfoVhEns] = useState(null);
    const [ficheSuiviDialog, SetFicheSuiviDialog] = useState(false);
    const [semestre, setSemestre] = useState(null);
    const [listeSemestre, setListeSetSemestre] = useState([]);
    const [cohorte, setCohorte] = useState(null);
    const [listeCohorte, setListeCohorte] = useState([]);
    const [listeActiviteDep, setListeActiviteDep] = useState([]);
    const [listeVarPie, setListeVarPie] = useState(null);
    const [isAffiche, setIsAffiche] = useState(false);
    const [listeIes, setListeIes] = useState([]);
    const [ies, setIes] = useState(null);
    const [listeEc, setListeEc] = useState([]);
    const [ec, setEc] = useState(null);
    const [chartData, setCharData] = useState({})
    const [lineData, setLineData] = useState({})
    const [ficheSuiviEcDialog, setFicheSuiviEcDialog] = useState(null)

    const programmationService = new ProgrammationService();
    const semestreService = new SemestreService();
    const cohorteServices = new CohorteServices();
    const spinner = new Spinner();
    const anneeService = new AnneeService();
    const dashboardService = new DashboardService();
    const iesrService = new IesrService();
    

    //let code_departement = !token.code_departement || 3;
    //let code_departement = token.code_departement;


    /*const chartData = {
        labels : ["Prommation","Réalisation"],
        
        datasets:[
            {
                data: [programmation.nbProgrammer,programmation.nbRealiser],
                
                backgroundColor: [
                    "#42A5F5",
                    "#66BB6A",
                ],
                hoverBackgroundColor: [
                    "#64B5F6",
                    "#81C784",
                ],
            }
        ]
    }


    const lightOptions = {
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            },
            title: {
                display: false,
                text: 'My Title',
                font: {
                    size: 16
                }
            }
        }
    };*/

        /*const [chartData] = useState({
            labels: ['executés', 'en cours', 'non executés'],
            datasets: [
                {
                    data: [],
                    backgroundColor: [
                        "#66BB6A",
                        "#FFA726",
                        "red",
                        
                       
                    ],
                    hoverBackgroundColor: [
                        "#81C784",
                        "#FFB74D",
                        "red",
                       
                        
                    ]
                }
            ]
        }); */
    

    const [lightOptions] = useState({
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        }
    });

    const chartDataCombo = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [{
            type: 'line',
            label: 'Dataset 1',
            borderColor: '#42A5F5',
            borderWidth: 2,
            fill: false,
            tension: .4,
            data: [
                50,
                25,
                12,
                48,
                56,
                76,
                42
            ]
        }, {
            type: 'bar',
            label: 'Dataset 2',
            backgroundColor: '#66BB6A',
            data: [
                21,
                84,
                24,
                75,
                37,
                65,
                34
            ],
            borderColor: 'white',
            borderWidth: 2
        }, {
            type: 'bar',
            label: 'Dataset 3',
            backgroundColor: '#FFA726',
            data: [
                41,
                52,
                24,
                74,
                23,
                21,
                32
            ]
        }]
    };

    const lightOptionsCombo = {
        maintainAspectRatio: false,
        aspectRatio: .6,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };
    

    useEffect(() => {
        /*setloading(true);
        programmationService.getProgrammationDepartement(code_departement).then((response=[])=>{
            let nbProgrammer = response.filter(data=>!data.valideChef && !data.valideEnseignant && !data.valideSP).length
            let nbRealiser = response.filter(data=>data.valideChef && data.valideEnseignant && data.valideSP).length;
            setprogrammation({...programmation,nbProgrammer,nbRealiser});
        })
        .finally(()=>setloading(false))*/

        //cohorteServices.getListeParcoursDepartement(code_departement).then(response=>{console.log(response);setlistParcours(response)})
        
        anneeService.getAll().then(data => {
            setListeAnnee(data);  
            
        })
        if(isEnseignant && !isDepartement){
            const matricule = token.matricule
            dashboardService.getEnseignant(matricule).then(dataEns => {
                setEnseignant(dataEns)
            })
        }
        
        else if(isDepartement && !isEnseignant){
            //console.log(token.code_departement)
            cohorteServices.getListeCohorteDepartement(token.code_departement).then(data => {
                //console.log(data)
                setListeCohorte(data)    
            })
            .catch(error => {
                console.log(error)
            })
        }
        else if(isEnseignant && isDepartement){
            const matricule = token.matricule
            dashboardService.getEnseignant(matricule).then(dataEns => {
                setEnseignant(dataEns)
            })

            cohorteServices.getListeCohorteDepartement(token.code_departement).then(data => {
                setListeCohorte(data)    
            })
            .catch(error => {
                console.log(error)
            })
        }
        else if(isDirecteur || isDA){
            cohorteServices.getListeDepartementEtab(token.code_etablissement).then(data => {
                setDepartements(data.departementEtabDtos)    
            })
            .catch(error => {
                console.log(error)
            })
        }
        else if(isVp){
            cohorteServices.getListeEtabUniv(token.code_iesr).then(data => {
                setEtablissements(data)
                //console.log(data)
            })
        }
        else if(isEtudiant){
            let ineEtudiant = token.matricule
            cohorteServices.getListeCohorteEtudiant(ineEtudiant).then(data => {
                setListeCohorte(data)
                //console.log(data)
            })
            .catch(error => {
                console.log(error)
            })
        }
        else {
            //console.log("bah.....")
        }

        semestreService.getAll().then(data => {
            setListeSetSemestre(data)
        })

    }, []);


    const handleParcoursSearch = (parcours)=>{
        setloading(true);
        searchSetValue("niveau","");
        setListSemestreSearch([]);
        setlistCohorteSearch([]);
        semestreService.get(parcours.idParcours).then(response=>{
            setListSemestreSearch(response);
        })
        programmationService.getProgrammationByParcours(token.code_departement,parcours.idParcours)
        .then((response=[])=>{
            setprogrammationByParcours(response);
            let nbProgrammer = response.filter(data=>!data.valideChef && !data.valideEnseignant && !data.valideSP).length
            let nbRealiser = response.filter(data=>data.valideChef && data.valideEnseignant && data.valideSP).length;
            setprogrammation({...programmation,nbProgrammer,nbRealiser});
        })
        .finally(()=>setloading(false));
    }


    const handleSemestreSearch=semestre=>{
        // setListCohorteSearch([]);
        let result = programmationByParcours.filter(programmation=>programmation.idSemestre === semestre.idSemestre);
        setprogrammationFilter(result);

        let nbProgrammer = result.filter(data=>!data.valideChef && !data.valideEnseignant && !data.valideSP).length
        let nbRealiser = result.filter(data=>data.valideChef && data.valideEnseignant && data.valideSP).length;
        setprogrammation({...programmation,nbProgrammer,nbRealiser});
        // programmationGet(result);
        let newListCohorte = [];
        let listCohorte =[];
        result.forEach(prog=>{
            prog.cohorte.forEach(cohor=>{
                listCohorte.push(cohor);
            })
        })
        let cohortesID=new Set();
        for(let element of listCohorte){
            cohortesID.add(element.idCohorte);
        }
        for(let index of cohortesID ){
            newListCohorte.push(listCohorte.find(cohorte=>cohorte.idCohorte === index));
        }
        setlistCohorteSearch([...newListCohorte]);
        
    }

    const onCohorteChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        setCohorte(val)
        if(isEnseignant){
            const matricule = token.matricule
            cohorteServices.getListeEcCohorteEns(val.idCohorte, annee.idAnneeAcademique, matricule).then(data => {
                setListeEc(data)
                //console.log(data)
            })
        }
        
    }
    
    const onDepartementChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        //console.log(val)
        setDepartement(val)
        cohorteServices.getListeCohorteDepartement(val.idDepartementEtab).then(data => {
            setListeCohorte(data)
            //console.log(data)
        })
    }

    const onEtablissementChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        //console.log(val)
        setEtablissement(val)
        cohorteServices.getListeDepartementEtab(val.idEtablissement).then(data => {
            setDepartements(data.departementEtabDtos)
            //console.log(data)
        })
    }

    const onAnneeChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        //console.log(val)
        setAnnee(val)
    }

    const onSemestreChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        setSemestre(val)
    }

    const onIesChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        setIes(val)
        const matricule = token.matricule
        cohorteServices.getListeCohorteIesEns(val.idIesr, annee.idAnneeAcademique, matricule).then(data => {
            setListeCohorte(data);
            //console.log(data)
        })
    }

    const onEcChange = (e, field) => {
        const val = (e.target && e.target.value) || '';
        setEc(val)
    }

    const listeActivite = () => {
        //console.log(annee)
        const matricule = token.matricule
        dashboardService.getInfoVhEns(matricule, annee.idAnneeAcademique).then(dataVh => {
            setInfoVhEns(dataVh)
            //console.log(dataVh)
        })
        dashboardService.getActivites(annee.idAnneeAcademique, matricule).then(data => {
            const elements = data.elementConstitutifTbDtoList
            let nbHeureRestant = null
            let listeElement = []
            let element = null
            elements.map(el => {
                nbHeureRestant = el.nombreHeureConf - el.nombreHeure
                element = {
                    dateSeanceEff: el.dateSeanceEff,
                    departement: el.departement,
                    idElementConstitutif: el.idElementConstitutif,
                    idIes: el.idIes,
                    idTypeActivite: el.idTypeActivite,
                    libelleElementConstitutif: el.libelleElementConstitutif,
                    libelleIes: el.libelleIes,
                    libelleTypeActivite: el.libelleTypeActivite,
                    libelleUniteEnseignement: el.libelleUniteEnseignement,
                    nombreHeure: el.nombreHeure,
                    nombreHeureConf: el.nombreHeureConf,
                    nombreHeureRestant : nbHeureRestant
                }
                listeElement.push(element)

            })
            setListeElementsEffectues(listeElement)
        })
    }

    const listeActDepartement = () => {
        let idDepartement = null
        if(isDepartement) {
            idDepartement = token.code_departement
        }
       else if(isDirecteur || isDA || isVp){
         idDepartement = departement.idDepartementEtab
       }
       else if(isEtudiant){
        idDepartement = cohorte.idDepartement
        
       }
        
        let idCohorte = cohorte.idCohorte
        let idAnnee = annee.idAnneeAcademique
        let idSemestre = semestre.idSemestre

        dashboardService.getActivitesDepartement(idDepartement, idCohorte, idAnnee, idSemestre).then(data => {
            //setListeActiviteDep(data.departementActivitesTbDtos)
            setIsAffiche(true)
            const elements = data.departementActivitesTbDtos
            let nbHeureRestant = null
            let listeElement = []
            let listeVarPie = []
            let element = null
            let execute = 0, encours = 0, nonexecute = 0;
            elements.map(el => {
                nbHeureRestant = el.nombreHeureConf - el.nombreHeure
                element = {
                    codeElementConstitutif: el.codeElementConstitutif,
                    flag: el.flag,
                    idCohorte: el.idCohorte,
                    idElementConstitutif: el.idElementConstitutif,
                    idEnseignant: el.idEnseignant,
                    idParcours: el.idParcours,
                    idSemestre: el.idSemestre,
                    idTypeActivite: el.idTypeActivite,
                    lbCohorte: el.lbCohorte,
                    lbElementConstitutif : el.lbElementConstitutif,
                    lbParcours : el.lbParcours,
                    lbSemestre : el.lbSemestre,
                    lbTypeActivite : el.lbTypeActivite,
                    nomEnseignant : el.nomEnseignant,
                    nombreHeure: el.nombreHeure,
                    nombreHeureConf: el.nombreHeureConf,
                    nombreHeureRestant : nbHeureRestant
                }
                
                if(el.flag === 0) nonexecute++;
                else if(el.flag === 1) encours++;
                else if(el.flag === 2) execute++;
                else;
                listeElement.push(element)

            })
            setListeActiviteDep(listeElement)
            let pieData = {
                labels: ['executés', 'en cours', 'non executés'],
                datasets: [
                    {
                        data: [execute, encours, nonexecute],
                        backgroundColor: [
                            "#66BB6A",
                            "#FFA726",
                            "red",
                            
                        
                        ],
                        hoverBackgroundColor: [
                            "#81C784",
                            "#FFB74D",
                            "red",
                        
                            
                        ]
                    }
                ]
            }
            setCharData(pieData)

        })
        dashboardService.getActiviteDepMonth(idDepartement, idAnnee).then(data => {
            //console.log(data);
            let donneeSeanceEff = [];
            let donneeSeanceProg = [];
            let mois = [];
            data.map(d => {
                    mois.push(d.lbMois)
                    donneeSeanceEff.push(d.nbSeanceEff)
                    donneeSeanceProg.push(d.nbSeanceProg)
            })
            const chartLineCombo = {
                labels: mois,
                datasets: [
                    {
                        type: 'line',
                        label: 'séances programmées',
                        borderColor: '#42A5F5',
                        borderWidth: 2,
                        fill: false,
                        tension: .4,
                        data: donneeSeanceProg  
                    }
                    ,
                    {
                    type: 'line',
                    label: 'séances effectuées',
                    borderColor: '#66BB6A',
                    borderWidth: 2,
                    fill: false,
                    tension: .4,
                    data: donneeSeanceEff
                }
                
            ]
            };
            setLineData(chartLineCombo)
            const lightOptionsCombo = {
                maintainAspectRatio: false,
                aspectRatio: .6,
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            };
        })
    }

    const ficheSuivi = () => {
        semestreService.getAll().then(data => {
            setListeSetSemestre(data)
        })

        SetFicheSuiviDialog(true)
    }

    const ficheSuiviEc = () => {

        iesrService.getAll().then(data => {
            setListeIes(data)
            //console.log(data)
        })

        anneeService.getAll().then(data => {
            setListeAnnee(data)
        })

        setFicheSuiviEcDialog(true)
    }

    const ficheSuiviPdf = () => {
        const matricule = token.matricule
        let fiche = {
            reportFormat: "PDF",
            reportType: "FICHE_SEANCE_EFFECTUEE",
            annee: parseInt(annee.idAnneeAcademique),
            matriculeEnseignant: matricule,
            idSemestre : semestre.idSemestre
        }
        //console.log("👌")
        //console.log(fiche)
        dashboardService.afficherFicheSuivi(fiche).then(res => {
            const matricule = token.matricule
            var blob = new Blob([res.data], {type: "application/pdf"});
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = `fiche_suivi_${matricule}`;
            link.download = fileName;
            link.click();
                  

        }).catch(error => {
            console.log(error)
        })
    }

    const ficheSuiviEcPdf = () => {
        const matricule = token.matricule
        let fiche = {
            reportFormat: "PDF",
            reportType: "FICHE_VACATION",
            annee: parseInt(annee.idAnneeAcademique),
            matriculeEnseignant: matricule,
            idCohorte : cohorte.idCohorte,
            idElementConstitutif : ec.idElementConstitutif
        }
        //console.log("👌")
        //console.log(fiche)
        dashboardService.afficherFicheSuiviEc(fiche).then(res => {
            const matricule = token.matricule
            var blob = new Blob([res.data], {type: "application/pdf"});
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = `fiche_suivi_${matricule}`;
            link.download = fileName;
            link.click();
                  

        }).catch(error => {
            console.log(error)
        })
    }

    const ficheSuiviDepartement = () => {
        
        let fiche = {
            reportFormat: "PDF",
            reportType: "FICHE_SUIVI_DEPART",
            idDepartement : token.code_departement,
            annee: parseInt(annee.idAnneeAcademique),
            idCohorte : parseInt(cohorte.idCohorte),
            idSemestre : parseInt(semestre.idSemestre)
        }
        //console.log("👌")
        //console.log(fiche)
        dashboardService.afficherFicheSuiviDepartement(fiche).then(res => {

            var blob = new Blob([res.data], {type: "application/pdf"});
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var fileName = `fiche_suivi_${token.code_departement}`;
            link.download = fileName;
            link.click();         

        }).catch(error => {
            console.log(error)
        })
    }

    const hideFicheSuiviDialog = () => {
        SetFicheSuiviDialog(false)
    }

    const hideFicheSuiviEcDialog = () => {
        setFicheSuiviEcDialog(false)
    }

    // const handleCohorteSearch = cohorte=>{
    //     let result =[] ;
    //     programmationFilter.forEach(programmation=>{
    //         programmation.cohorte.filter(programmation=>programmation.idCohorte === cohorte.idCohorte)
    //         for(let element of programmation.cohorte){
    //             if(element.idCohorte === cohorte.idCohorte){
    //                 result.push(programmation);
    //             }
    //         }
    //     });
    //     // programmationGet(result);
    // }

    const ficheSuiviDialogFooter = (
        <React.Fragment>
            <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideFicheSuiviDialog} />
            <Button label="Affichier la fiche" icon="pi pi-times" className="p-button-text" onClick={ficheSuiviPdf} />
        </React.Fragment>
    );

    const ficheSuiviEcDialogFooter = (
        <React.Fragment>
            <Button label="Quitter" icon="pi pi-times" className="p-button-text" onClick={hideFicheSuiviEcDialog} />
            <Button label="Affichier la fiche" icon="pi pi-times" className="p-button-text" onClick={ficheSuiviEcPdf} />
        </React.Fragment>
    );

    return(
        <React.Fragment>

            <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
            />
            <div className="card">
            {
                loading ?
                    <div className="p-grid">
                        <div className="p-col-6" ></div>
                        <div className="p-col-6" >
                            {spinner.loadingSpinner("spin", "black")}
                        </div>
                    </div>
                :
                isEnseignant && !isDepartement ?
                <div className="p-col-12 p-grid">
                    <div className="p-col-12" style={{marginTop : 10}}>
                        <Dropdown   optionLabel="libelleAnnee" optionValue={annee.value} value={annee} options={listeAnnee} placeholder="Choisir une année"
                        onChange={(e) => {onAnneeChange(e, "annee")}} required autoFocus/>
                        {/*<Dropdown style={{marginLeft : 10}} optionLabel="" optionValue=""  placeholder="Choisir un semestre"/>*/}
                        <Button style={{marginLeft : 10}} onClick={listeActivite} label="Lister" />
                    </div>
                    <div className="p-col-12 p-grid" style={{marginTop : 10}}>
                        <Panel header={"Mes informations"} className="p-col-5 card">
                        {enseignant ?
                        <table>
                        
                                <thead>
                                    <tr>
                                        <th style={{width : 300}}>Nom</th>
                                        <th>{enseignant.nomEnseignant}</th>
                                    </tr>
                                    <tr>
                                        <th>Prénom(s)</th>
                                        <th>{enseignant.prenomEnseignant}</th>
                                    </tr>
                                    <tr>
                                        <th>Email</th>
                                        <th>{enseignant.emailEnseignant}</th>
                                    </tr>
                                    <tr>
                                        <th>Grade</th>
                                        <th>{enseignant.gradeEnseignant}</th>
                                    </tr>
                                    <tr>
                                        <th>Type</th>
                                        <th>{enseignant.statutEnseignant}</th>
                                    </tr>
                                </thead>
                                
                            </table>
                            :
                            ""
                            }
                        </Panel>
                        <div className="p-col-2"></div>
                        <Panel header={"Synthèse horaire"} className="p-col-5">
                        {infoVhEns ?
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{width : 300}}>Désignation</th>
                                        <th>Volume horaire</th>
                                    </tr>
                                </thead>
                                
                                <tbody>
                                    
                                    <tr>
                                        <td>Volume Horaire CM</td>
                                        <td>{infoVhEns.volumeHoraireCmConfier}</td>
                                    </tr>
                                    <tr>
                                        <td>Volume Horaire TD</td>
                                        <td>{infoVhEns.volumeHoraireTdConfier}</td>
                                    </tr>
                                    <tr>
                                        <td>Volume Horaire TP</td>
                                        <td>{infoVhEns.volumeHoraireTpConfier}   </td>
                                    </tr>
                                    <tr>
                                        <td>Volume Horaire CM eff.</td>
                                        <td>{infoVhEns.volumeHoraireCmEff}</td>
                                    </tr>
                                    <tr>
                                        <td>Volume Horaire TD eff.</td>
                                        <td>{infoVhEns.volumeHoraireTdEff}</td>
                                    </tr>
                                    <tr>
                                        <td>Volume Horaire TP eff.</td>
                                        <td>{infoVhEns.volumeHoraireTpEff}</td>
                                    </tr>
                                   
                                </tbody>
                                
                            </table>
                            :
                            ""
                            }
                        </Panel>
                    </div>
                    <div className="p-col-12 p-grid">
                            <div className="p-grid" style={{marginTop : 10}}>
                                <Button style={{marginLeft : 10, marginBottom : 10}} onClick={ficheSuivi} label="Fiche de suivi" />
                                <Button style={{marginLeft : 10, marginBottom : 10}} onClick={ficheSuiviEc} label="Fiche de suivi/EC" />
                                <DataTable value={listeElementsEffectues}   header="Etat d'execution des cours" paginator={true} rows={5}
                                    filterMatchMode="contains" responsive={true}  >
                                    
                                    <Column field="libelleIes" header="Universtié"/>
                                    <Column field="departement" header="Département" />
                                    <Column field="libelleElementConstitutif" header="ECUE" />
                                    <Column field="libelleTypeActivite" header="Activité" />
                                    <Column style={{color : "green"}} field="nombreHeure" header="Nb.heure effectué" />
                                    <Column field="nombreHeureConf" header="Nb.heure confié" />
                                    <Column style={{color : "red"}} field="nombreHeureRestant" header="Nb.heure restant" />
                                </DataTable>
                            </div>
                    </div>
                    <Dialog visible={ficheSuiviDialog} style={{ width: '450px' }} header="Informations fiche suivi" modal className="p-fluid" footer={ficheSuiviDialogFooter} onHide={hideFicheSuiviDialog}>
                        <div className="p-field">
                            <label htmlFor="semestre">Semestre</label>
                            <Dropdown   optionLabel="libelleSemestre" optionValue={semestre} value={semestre} options={listeSemestre} placeholder="Choisir une semestre"
                            onChange={(e) => {onSemestreChange(e, "semestre")}} required autoFocus/>
                        </div>
                    </Dialog>
                    <Dialog visible={ficheSuiviEcDialog} style={{ width: '900px' }} header="Informations fiche suivi/EC" modal className="p-fluid" footer={ficheSuiviEcDialogFooter} onHide={hideFicheSuiviEcDialog}>
                        <div className="p-field">
                            <label htmlFor="libelleIesr">Universtié</label>
                            <Dropdown   style={{marginLeft : 10}} optionLabel="libelleIesr" optionValue={ies} value={ies} options={listeIes} placeholder="Choisir une université"
                            onChange={(e) => {onIesChange(e, "ies")}} required autoFocus/>
                            <label htmlFor="annee">Année</label>
                            <Dropdown style={{marginLeft : 10}} onChange={(e) => {onAnneeChange(e, "annee")}} required autoFocus optionLabel="libelleAnnee" optionValue={annee.value} value={annee} options={listeAnnee}  placeholder="Choisir une année"/>
                            <label htmlFor="libelleCohorte">Cohortes</label>
                            <Dropdown style={{marginLeft : 10}} onChange={(e) => {onCohorteChange(e, "cohorte")}} optionLabel="libelleCohorte" optionValue={cohorte} value={cohorte} options={listeCohorte}  placeholder="Choisir une cohorte"/>
                            <label htmlFor="libelleElementConstitutif">Element Constitutif</label>
                            <Dropdown   optionLabel="libelleElementConstitutif" optionValue={ec} value={ec} options={listeEc} placeholder="Choisir un EC"
                            onChange={(e) => {onEcChange(e, "ec")}} required autoFocus/>
                        </div>
                    </Dialog>
                  </div>
                :
                isDepartement && !isEnseignant?           
                <div className="p-col-12 p-grid">
                  <div className="p-col-12 p-grid ">
                    <Button  title="Affectation de cours"  className="p-col-1 mr-2" onClick={()=>window.location.hash = "/volume-horaire/Gestion-affectation"} label="Affectation" />
                    <Button  title="Programmation de cours" className="p-col-2 mr-2" onClick={()=>window.location.hash = "/volume-horaire/programmation-activite"} label="Programmation" />
                    <Button  title="Emplois du temps" className="p-col-2 mr-2" onClick={()=>window.location.hash = "/volume-horaire/emplois-du-temps"} label="Emplois du temps" />
                    <Button  title="Infrastructure" className="p-col-2 mr-2" onClick={()=>window.location.hash = "/volume-horaire/parametres/gestion-infrastructure/gererInfrastructures"} label="Infrastructure" />
                    <Button  title="Séances Effectuées" className="p-col-2 mr-2" onClick={()=>window.location.hash = "/volume-horaire/seance-effectuer"} label="Séances Effectuées" />
                    <Button  title="Fiche pédagogique" className="p-col-2 mr-2" onClick={()=>window.location.hash = "/volume-horaire/Gestion-fiches-pedagogique"} label="Fiche pédagogique" />  
                 </div>
                 <div className="p-col-12 p-grid" style={{marginTop : 20}}>
                    <div className="p-grid">
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onCohorteChange(e, "cohorte")}} optionLabel="libelleCohorte" optionValue={cohorte} value={cohorte} options={listeCohorte}  placeholder="Choisir une cohorte"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onAnneeChange(e, "annee")}} required autoFocus optionLabel="libelleAnnee" optionValue={annee.value} value={annee} options={listeAnnee}  placeholder="Choisir une année"/>
                        <Dropdown   optionLabel="libelleSemestre" optionValue={semestre} value={semestre} options={listeSemestre} placeholder="Choisir un semestre"
                            onChange={(e) => {onSemestreChange(e, "semestre")}} required autoFocus/>
                        <Button style={{marginLeft : 10}} label="Lister" onClick={listeActDepartement} />
                        <Button style={{marginLeft : 30}} label="Fiche suivi" onClick={ficheSuiviDepartement} />
                    </div>
                    <div className="p-grid" style={{marginTop : 10}}>
                        <DataTable  value={listeActiviteDep} header="Etat d'execution des cours"  paginator={true} rows={5}
                            filterMatchMode="contains" responsive={true}  >
                            <Column field="lbElementConstitutif" header="ECUE"/>
                            <Column field="lbTypeActivite" header="Activité" />
                            <Column style={{color : "green"}} field="nombreHeure" header="Nb.heure eff." />
                            <Column field="nombreHeureConf" header="Nb.heure confié" />
                            <Column style={{color : "red"}} field="nombreHeureRestant" header="Nb.heure restant" />
                            <Column field="nomEnseignant" header="Enseignant" />
                        </DataTable>
                    </div>
                 </div>
                 <div className="p-col-12 p-grid">
                    <div className="p-col-5" >
                        <h5>Etats d'avancement des cours</h5>
                        <Chart type="line" data={lineData} options={lightOptionsCombo} />
                    </div>
                    <div className="p-col-1"></div>
                    <div className="p-col-5 flex justify-content-center">
                        {isAffiche ?
                            <>
                            <h5>Etat d'execution des ECUE</h5>
                            <Chart type="pie" data={chartData} options={lightOptions} style={{ position: 'relative', width: '100%' }} />
                            </>
                            :
                            <>
                            <h5>Etat d'execution des ECUE</h5>
                            <Chart type="pie" data={chartData} options={lightOptions} style={{ position: 'relative', width: '100%' }} />
                            </>
                        }
                        
                    </div>
                 </div>
                    <div>
                        {/*<div className="table-header p-grid justify-content-center mb-2 mt-3">
                            <span className="p-input-icon-left">
                                <div className='p-grid justify-content-center'>
                                    <div className="p-field p-col">
                                        <Controller 
                                            name='parcours' control={searchControl}
                                            render={({field})=>(
                                                <Dropdown 
                                                    name={field.name} id={field.name} value={field.value}
                                                    options={listParcours} optionLabel='libelleParcours'  style={{paddingTop:5, marginLeft : 20,width:"300px",height:"45px"}} type="search" placeholder="Selectionner un parcours"
                                                    {...field}
                                                    onChange={(e)=>{field.onChange(e.value);handleParcoursSearch(e.value)}}
                                                />
                                            )}

                                        />
                                    </div>
                                    <div className="p-field p-col">
                                        <Controller 
                                            name='semestre' control={searchControl}
                                            render={({field})=>(
                                                <Dropdown 
                                                    name={field.name} id={field.name} value={field.value}
                                                    options={listSemestreSearch} optionLabel='libelleSemestre'  style={{paddingTop:5, marginLeft : 20,width:"300px",height:"45px"}} placeholder="Selectionner un semestre"
                                                    {...field}
                                                    onChange={(e)=>{field.onChange(e.value);handleSemestreSearch(e.value)}}
                                                />
                                            )}

                                        />
                                    </div>
                                    <div className="p-field p-col">
                                        <Controller 
                                            name='cohorte' control={searchControl}
                                            render={({field})=>(
                                                <Dropdown 
                                                    name={field.name} id={field.name} value={field.value}
                                                    options={listCohorteSearch} optionLabel='libelleCohorte'  style={{paddingTop:5, marginLeft : 20,width:"300px",height:"45px"}} type="search" placeholder="Selectionner une cohorte"
                                                    {...field}
                                                    // onChange={e=>{field.onChange(e.value);handleCohorteSearch(e.value)}}
                                                />
                                            )}

                                        />
                                    </div>
                                </div>
                            </span>
                        </div>
                        {/* <Chart type="pie" data={chartData} options={lightOptions}  /> */}
                    </div>
            
            </div>
            :
            isEtudiant ?           
                <div className="p-col-12 p-grid">
                  <div className="p-col-12 p-grid ">
                    <Button  title="Affectation de cours"  className="p-col-1 mr-2" onClick={()=>window.location.hash = "/volume-horaire/Gestion-affectation"} label="Affectation" />
                    <Button  title="Programmation de cours" className="p-col-2 mr-2" onClick={()=>window.location.hash = "/volume-horaire/programmation-activite"} label="Programmation" />
                    <Button  title="Emplois du temps" className="p-col-2 mr-2" onClick={()=>window.location.hash = "/volume-horaire/emplois-du-temps"} label="Emplois du temps" />
                    <Button  title="Séances Effectuées" className="p-col-2 mr-2" onClick={()=>window.location.hash = "/volume-horaire/seance-effectuer"} label="Séances Effectuées" />
                 </div>
                 <div className="p-col-12 p-grid" style={{marginTop : 20}}>
                    <div className="p-grid">
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onCohorteChange(e, "cohorte")}} optionLabel="libelleCohorte" optionValue={cohorte} value={cohorte} options={listeCohorte}  placeholder="Choisir une cohorte"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onAnneeChange(e, "annee")}} required autoFocus optionLabel="libelleAnnee" optionValue={annee.value} value={annee} options={listeAnnee}  placeholder="Choisir une année"/>
                        <Dropdown   optionLabel="libelleSemestre" optionValue={semestre} value={semestre} options={listeSemestre} placeholder="Choisir un semestre"
                            onChange={(e) => {onSemestreChange(e, "semestre")}} required autoFocus/>
                        <Button style={{marginLeft : 10}} label="Lister" onClick={listeActDepartement} />
                        {/*<Button style={{marginLeft : 30}} label="Fiche suivi" onClick={ficheSuiviDepartement} />*/}
                    </div>
                    <div className="p-grid" style={{marginTop : 10}}>
                        <DataTable  value={listeActiviteDep} header="Etat d'execution des cours"  paginator={true} rows={5}
                            filterMatchMode="contains" responsive={true}  >
                            <Column field="lbElementConstitutif" header="ECUE"/>
                            <Column field="lbTypeActivite" header="Activité" />
                            <Column style={{color : "green"}} field="nombreHeure" header="Nb.heure eff." />
                            <Column field="nombreHeureConf" header="Nb.heure confié" />
                            <Column style={{color : "red"}} field="nombreHeureRestant" header="Nb.heure restant" />
                            <Column field="nomEnseignant" header="Enseignant" />
                        </DataTable>
                    </div>
                 </div>
                 <div className="p-col-12 p-grid">
                    <div className="p-col-5" >
                        <h5>Etats d'avancement des cours</h5>
                        <Chart type="line" data={lineData} options={lightOptionsCombo} />
                    </div>
                    <div className="p-col-1"></div>
                    <div className="p-col-5 flex justify-content-center">
                        {isAffiche ?
                            <>
                            <h5>Etat d'execution des ECUE</h5>
                            <Chart type="pie" data={chartData} options={lightOptions} style={{ position: 'relative', width: '100%' }} />
                            </>
                            :
                            <>
                            <h5>Etat d'execution des ECUE</h5>
                            <Chart type="pie" data={chartData} options={lightOptions} style={{ position: 'relative', width: '100%' }} />
                            </>
                        }
                        
                    </div>
                 </div>
            </div>
            :
            isDirecteur ?           
                <div className="p-col-12 p-grid">
                  <div className="p-col-12 p-grid ">
                    <Button  title="Affectation de cours"  className="p-col-1 mr-2" onClick={()=>window.location.hash = "/volume-horaire/Gestion-affectation"} label="Affectation" />
                    <Button  title="Programmation de cours" className="p-col-2 mr-2" onClick={()=>window.location.hash = "/volume-horaire/programmation-activite"} label="Programmation" />
                    <Button  title="Emplois du temps" className="p-col-2 mr-2" onClick={()=>window.location.hash = "/volume-horaire/emplois-du-temps"} label="Emplois du temps" />
                    <Button  title="Infrastructure" className="p-col-2 mr-2" onClick={()=>window.location.hash = "/volume-horaire/parametres/gestion-infrastructure/gererInfrastructures"} label="Infrastructure" />
                    <Button  title="Séances Effectuées" className="p-col-2 mr-2" onClick={()=>window.location.hash = "/volume-horaire/seance-effectuer"} label="Séances Effectuées" />
                    <Button  title="Fiche pédagogique" className="p-col-2 mr-2" onClick={()=>window.location.hash = "/volume-horaire/Gestion-fiches-pedagogique"} label="Fiche pédagogique" />  
                 </div>
                 <div className="p-col-12 p-grid" style={{marginTop : 20}}>
                    <div className="p-grid">
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onDepartementChange(e, "departement")}} optionLabel="lbDepartementEtab" value={departement} options={departements}  placeholder="Choisir un departement"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onCohorteChange(e, "cohorte")}} optionLabel="libelleCohorte" optionValue={cohorte} value={cohorte} options={listeCohorte}  placeholder="Choisir une cohorte"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onAnneeChange(e, "annee")}} required autoFocus optionLabel="libelleAnnee" optionValue={annee.value} value={annee} options={listeAnnee}  placeholder="Choisir une année"/>
                        <Dropdown   optionLabel="libelleSemestre" optionValue={semestre} value={semestre} options={listeSemestre} placeholder="Choisir un semestre"
                            onChange={(e) => {onSemestreChange(e, "semestre")}} required autoFocus/>
                        <Button style={{marginLeft : 10}} label="Lister" onClick={listeActDepartement} />
                        <Button style={{marginLeft : 30}} label="Fiche suivi" onClick={ficheSuiviDepartement} />
                    </div>
                    <div className="p-grid" style={{marginTop : 10}}>
                        <DataTable  value={listeActiviteDep} header="Etat d'execution des cours"  paginator={true} rows={5}
                            filterMatchMode="contains" responsive={true}  >
                            <Column field="lbElementConstitutif" header="ECUE"/>
                            <Column field="lbTypeActivite" header="Activité" />
                            <Column style={{color : "green"}} field="nombreHeure" header="Nb.heure eff." />
                            <Column field="nombreHeureConf" header="Nb.heure confié" />
                            <Column style={{color : "red"}} field="nombreHeureRestant" header="Nb.heure restant" />
                            <Column field="nomEnseignant" header="Enseignant" />
                        </DataTable>
                    </div>
                 </div>
                 <div className="p-col-12 p-grid">
                    <div className="p-col-5" >
                        <h5>Etats d'avancement des cours</h5>
                        <Chart type="line" data={lineData} options={lightOptionsCombo} />
                    </div>
                    <div className="p-col-1"></div>
                    <div className="p-col-5 flex justify-content-center">
                        {isAffiche ?
                            <>
                            <h5>Etat d'execution des ECUE</h5>
                            <Chart type="pie" data={chartData} options={lightOptions} style={{ position: 'relative', width: '100%' }} />
                            </>
                            :
                            <>
                            <h5>Etat d'execution des ECUE</h5>
                            <Chart type="pie" data={chartData} options={lightOptions} style={{ position: 'relative', width: '100%' }} />
                            </>
                        }
                        
                    </div>
                 </div>
            </div>
            :
            isDA ?           
                <div className="p-col-12 p-grid">
                  <div className="p-col-12 p-grid ">
                    <Button  title="Affectation de cours"  className="p-col-1 mr-2" onClick={()=>window.location.hash = "/volume-horaire/Gestion-affectation"} label="Affectation" />
                    <Button  title="Programmation de cours" className="p-col-2 mr-2" onClick={()=>window.location.hash = "/volume-horaire/programmation-activite"} label="Programmation" />
                    <Button  title="Emplois du temps" className="p-col-2 mr-2" onClick={()=>window.location.hash = "/volume-horaire/emplois-du-temps"} label="Emplois du temps" />
                    <Button  title="Infrastructure" className="p-col-2 mr-2" onClick={()=>window.location.hash = "/volume-horaire/parametres/gestion-infrastructure/gererInfrastructures"} label="Infrastructure" />
                    <Button  title="Séances Effectuées" className="p-col-2 mr-2" onClick={()=>window.location.hash = "/volume-horaire/seance-effectuer"} label="Séances Effectuées" />
                    <Button  title="Fiche pédagogique" className="p-col-2 mr-2" onClick={()=>window.location.hash = "/volume-horaire/Gestion-fiches-pedagogique"} label="Fiche pédagogique" />  
                 </div>
                 <div className="p-col-12 p-grid" style={{marginTop : 20}}>
                    <div className="p-grid">
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onDepartementChange(e, "departement")}} optionLabel="lbDepartementEtab" value={departement} options={departements}  placeholder="Choisir un departement"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onCohorteChange(e, "cohorte")}} optionLabel="libelleCohorte" optionValue={cohorte} value={cohorte} options={listeCohorte}  placeholder="Choisir une cohorte"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onAnneeChange(e, "annee")}} required autoFocus optionLabel="libelleAnnee" optionValue={annee.value} value={annee} options={listeAnnee}  placeholder="Choisir une année"/>
                        <Dropdown   optionLabel="libelleSemestre" optionValue={semestre} value={semestre} options={listeSemestre} placeholder="Choisir un semestre"
                            onChange={(e) => {onSemestreChange(e, "semestre")}} required autoFocus/>
                        <Button style={{marginLeft : 10}} label="Lister" onClick={listeActDepartement} />
                        <Button style={{marginLeft : 30}} label="Fiche suivi" onClick={ficheSuiviDepartement} />
                    </div>
                    <div className="p-grid" style={{marginTop : 10}}>
                        <DataTable  value={listeActiviteDep} header="Etat d'execution des cours"  paginator={true} rows={5}
                            filterMatchMode="contains" responsive={true}  >
                            <Column field="lbElementConstitutif" header="ECUE"/>
                            <Column field="lbTypeActivite" header="Activité" />
                            <Column style={{color : "green"}} field="nombreHeure" header="Nb.heure eff." />
                            <Column field="nombreHeureConf" header="Nb.heure confié" />
                            <Column style={{color : "red"}} field="nombreHeureRestant" header="Nb.heure restant" />
                            <Column field="nomEnseignant" header="Enseignant" />
                        </DataTable>
                    </div>
                 </div>
                 <div className="p-col-12 p-grid">
                    <div className="p-col-5" >
                        <h5>Etats d'avancement des cours</h5>
                        <Chart type="line" data={lineData} options={lightOptionsCombo} />
                    </div>
                    <div className="p-col-1"></div>
                    <div className="p-col-5 flex justify-content-center">
                        {isAffiche ?
                            <>
                            <h5>Etat d'execution des ECUE</h5>
                            <Chart type="pie" data={chartData} options={lightOptions} style={{ position: 'relative', width: '100%' }} />
                            </>
                            :
                            <>
                            <h5>Etat d'execution des ECUE</h5>
                            <Chart type="pie" data={chartData} options={lightOptions} style={{ position: 'relative', width: '100%' }} />
                            </>
                        }
                        
                    </div>
                 </div>
            </div>
            :
            isVp ?           
                <div className="p-col-12 p-grid">
                  <div className="p-col-12 p-grid ">
                    <Button  title="Affectation de cours"  className="p-col-1 mr-2" onClick={()=>window.location.hash = "/volume-horaire/Gestion-affectation"} label="Affectation" />
                    <Button  title="Programmation de cours" className="p-col-2 mr-2" onClick={()=>window.location.hash = "/volume-horaire/programmation-activite"} label="Programmation" />
                    <Button  title="Emplois du temps" className="p-col-2 mr-2" onClick={()=>window.location.hash = "/volume-horaire/emplois-du-temps"} label="Emplois du temps" />
                    <Button  title="Infrastructure" className="p-col-2 mr-2" onClick={()=>window.location.hash = "/volume-horaire/parametres/gestion-infrastructure/gererInfrastructures"} label="Infrastructure" />
                    <Button  title="Séances Effectuées" className="p-col-2 mr-2" onClick={()=>window.location.hash = "/volume-horaire/seance-effectuer"} label="Séances Effectuées" />
                    <Button  title="Fiche pédagogique" className="p-col-2 mr-2" onClick={()=>window.location.hash = "/volume-horaire/Gestion-fiches-pedagogique"} label="Fiche pédagogique" />  
                 </div>
                 <div className="p-col-12 p-grid" style={{marginTop : 20}}>
                    <div className="p-grid">
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onEtablissementChange(e, "etablissement")}} optionLabel="sigleEtablissement" value={etablissement} options={etablissements}  placeholder="Choisir un établissement"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onDepartementChange(e, "departement")}} optionLabel="lbDepartementEtab" value={departement} options={departements}  placeholder="Choisir un departement"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onCohorteChange(e, "cohorte")}} optionLabel="libelleCohorte" optionValue={cohorte} value={cohorte} options={listeCohorte}  placeholder="Choisir une cohorte"/>
                        <Dropdown style={{marginLeft : 10}} onChange={(e) => {onAnneeChange(e, "annee")}} required autoFocus optionLabel="libelleAnnee" optionValue={annee.value} value={annee} options={listeAnnee}  placeholder="Choisir une année"/>
                        <Dropdown   optionLabel="libelleSemestre" optionValue={semestre} value={semestre} options={listeSemestre} placeholder="Choisir un semestre"
                            onChange={(e) => {onSemestreChange(e, "semestre")}} required autoFocus/>
                        <Button style={{marginLeft : 10}} label="Lister" onClick={listeActDepartement} />
                        <Button style={{marginLeft : 30}} label="Fiche suivi" onClick={ficheSuiviDepartement} />
                    </div>
                    <div className="p-grid" style={{marginTop : 10}}>
                        <DataTable  value={listeActiviteDep} header="Etat d'execution des cours"  paginator={true} rows={5}
                            filterMatchMode="contains" responsive={true}  >
                            <Column field="lbElementConstitutif" header="ECUE"/>
                            <Column field="lbTypeActivite" header="Activité" />
                            <Column style={{color : "green"}} field="nombreHeure" header="Nb.heure eff." />
                            <Column field="nombreHeureConf" header="Nb.heure confié" />
                            <Column style={{color : "red"}} field="nombreHeureRestant" header="Nb.heure restant" />
                            <Column field="nomEnseignant" header="Enseignant" />
                        </DataTable>
                    </div>
                 </div>
                 <div className="p-col-12 p-grid">
                    <div className="p-col-5" >
                        <h5>Etats d'avancement des cours</h5>
                        <Chart type="line" data={lineData} options={lightOptionsCombo} />
                    </div>
                    <div className="p-col-1"></div>
                    <div className="p-col-5 flex justify-content-center">
                        {isAffiche ?
                            <>
                            <h5>Etat d'execution des ECUE</h5>
                            <Chart type="pie" data={chartData} options={lightOptions} style={{ position: 'relative', width: '100%' }} />
                            </>
                            :
                            <>
                            <h5>Etat d'execution des ECUE</h5>
                            <Chart type="pie" data={chartData} options={lightOptions} style={{ position: 'relative', width: '100%' }} />
                            </>
                        }
                        
                    </div>
                 </div>
            </div>
            :
            ""
            }
            </div>
            
        </React.Fragment>
    )
}