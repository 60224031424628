import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import logoCsv from "../../images/icons/svg/fichier_csv.svg";
import { InputText } from "primereact/inputtext";
import { Notify } from "../../components/Notify";
import { ToastContainer } from "react-toastify";
import { Spinner } from "../../components/Spinner";
import { ExamenService } from "../service/ExamenService";
import { SessionExamenService } from "../service/SessionExamenService";
import { NotationService } from "../service/NotationService";
import { EnseignantService } from "../service/EnseignantService";
import { Dropdown } from "primereact/dropdown";
import classNames from "classnames";
import { AnneeService } from "../service/AnneeService";
import { SemestreService } from "../service/SemestreService";
import { IesrService } from "../service/IesrService";
import { DepartementService } from "../service/DepartementService";
import { CohorteServices } from "../../volumeHoraire/service/CohorteServices";
import { Buffer } from "buffer";

export class ImportNoteEnseignantResp extends Component {
    emptyImportNote = {
        examen: null,
        fichier: null,
        nomFichier: "",
    };

    constructor(props) {
        super(props);
        this.notify = new Notify();
        this.spinner = new Spinner();

        this.examenService = new ExamenService();
        this.sessionService = new SessionExamenService();
        this.notationService = new NotationService();
        this.enseignantService = new EnseignantService();
        this.anneeService = new AnneeService();
        this.semestreService = new SemestreService();
        this.iesrService = new IesrService();
        this.departementService = new DepartementService();
        this.cohorteServices = new CohorteServices();

        this.state = {
            token: null,
            listeExamen: [],
            listeSession: [],
            listeNotation: [],
            notationList: [],
            listeIesr: [],
            listeDepartement: [],
            listeAnnee: [],
            listeSemestre: [],
            listeEtablissement : [],
            annee: null,
            iesr: null,
            etablissement : null,
            semestre: null,
            departement: null,
            examen: null,
            curentExamen: null,
            allowValide: false,
            fichier: null,
            nomFichier: "",
            dateFichier: "",
            username: "",
            importNote: this.emptyImportNote,
            fichierNotation: [],
            submitted: false,
            submittedEns: false,
            redirect: false,
            loading: false,
            loadingNotationListe: false,
            notationDialog: false,
            loadingChargement: false,
            loadingNotation: false,
            notationListDialog: false,
            bool: false,
            globalFilter: null,
            globalFilterListeExamen: null,
            globalFilterSession: null,
            importNoteDialog: false,
            listeExamenDialog: false,
            dialogFichierListeEtudiant: false,
            loadingListeEtudiant: false,
            erreurRecupFichier: false,
            token: null,
            verifeEnseignant: false,
            verifeDept: false,
            verifeEtab: false,
            verifeMinistere : false,
            listeEcExamenDialog: false,
            loadingEcExamen: false,
            listeEcExamen: [],
            listeEc: [],

            isDGESup: false,
            isVp: false,
            isEnseignant: false,
            isEtudiant: false,
            isDepartement: false,
            isDirecteur: false,
            isDA: false,
            isMinistere: false
        };
    }
    componentDidMount = () => {
        const tokenDecoded = localStorage.getItem("tokenDecoded");
        const token = JSON.parse(tokenDecoded);
        const campusDGESup = localStorage.getItem("campusDGESup");
        const campusVpEip = localStorage.getItem("campusVpEip");
        const campusEnseignant = localStorage.getItem("campusEnseignant");
        const campusEtudiant = localStorage.getItem("campusEtudiant");
        const campusDirecteur = localStorage.getItem("campusDirecteur");
        const campusDa = localStorage.getItem("campusDa");
        const campusDepartement = localStorage.getItem("campusDepartement");
        const campusMinistere = localStorage.getItem("campusMinistere");
        const isDGESup = parseInt(JSON.parse(campusDGESup));
        const isVp = parseInt(JSON.parse(campusVpEip));
        const isEnseignant = parseInt(JSON.parse(campusEnseignant));
        const isEtudiant = parseInt(JSON.parse(campusEtudiant));
        const isDepartement = parseInt(JSON.parse(campusDepartement));
        const isDirecteur = parseInt(JSON.parse(campusDirecteur));
        const isDA = parseInt(JSON.parse(campusDa));
        const isMinistere = parseInt(JSON.parse(campusMinistere));
        this.setState({
            loading: true,
            token: token,
            isDepartement: isDepartement,
            isDA: isDA,
            isDirecteur: isDirecteur,
            isVp: isVp,
            isMinistere : isMinistere
        });
        //console.log(token)
         
            this.anneeService.getAll().then((data) => {
                this.setState({ listeAnnee: data });
            });
            this.semestreService.getAll().then((data) => {
                this.setState({ listeSemestre: data });
            });
            this.iesrService.getAll().then((data) => {
                this.setState({ listeIesr: data });
            });
            this.setState({ loading: false, verifeEnseignant: true });
    };
    listeExamen = (session) => {
        let idSession = session.idSession;
        this.setState({ listeExamenDialog: true, session: session });
        this.examenService.examenBySession(idSession).then((data) => {
            this.setState({ listeExamen: data });
        });
    };
    listeEcExamen = (Ec) => {
        let idAnnee = this.state.annee.idAnneeAcademique;
        let idEc = Ec.idEc;
        this.setState({
            listeEcExamenDialog: true,
            loadingEcExamen: true,
        });
        this.examenService
            .examenByEc(idEc, idAnnee)
            .then((data) => {
                const n = data.length;
                if(n>0){
                    this.setState({
                        session: data[n - 1].session,
                        listeEcExamen: data,
                        loadingEcExamen: false,
                    });
                    //console.log(data)
                }
                else {
                    this.setState({
                        loadingEcExamen: false,
                    });
                }
                
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loadingEcExamen: false,
                });
            });
    };
    importNote = (examen) => {
        //console.log(examen)
        this.setState({
            examen: examen,
            importNoteDialog: true,
        });
    };
    telechargerFichierEtudiant = (idExamen) => {
        this.setState({
            dialogFichierListeEtudiant: true,
            loadingListeEtudiant: true,
        });
        this.notationService
            .getFichierNotation(idExamen)
            .then((data) => {
                this.setState({
                    fichierNotation: data,
                    loadingListeEtudiant: false,
                });
                //console.log(data)
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loadingListeEtudiant: false,
                    erreurRecupFichier: true,
                });
            });
    };
    onFileChange = (event) => {
        let file = event.target.files[0];
        if (file) {
            this.setState({ nomFichier: file.name });
            const reader = new FileReader();
            reader.onload = (readerEvt) => {
                let binaryString = readerEvt.target.result;
                let fichier = btoa(binaryString);
                this.setState({ fichier: fichier });
                this.setState({ username: "ygalpen@gmail.com" });
                this.setState({ dateFichier: "21-03-2021" });
            };
            reader.readAsBinaryString(file);
        }
    };
    saveImportFichier = () => {
        let idDepartement = null;
        let idExamen = this.state.examen.idExamen;
        this.state.departement
            ? (idDepartement = this.state.departement.idDepartementEtab)
            : (idDepartement = this.state.token.code_departement);
        let donneeFichier = {
            nomFichier: this.state.nomFichier,
            fichier: this.state.fichier,
            dateFichier: this.state.dateFichier,
            username: this.state.username,
        };
        this.setState({ loadingChargement: true });
        this.notationService
            .chargementFichier(donneeFichier, idExamen, idDepartement)
            .then((data) => {
                this.setState({
                    loadingChargement: false,
                    bool: true,
                    submitted: true,
                });
            })
            .catch((error) => {
                this.notify.echec(error.response.data.message);
                console.log(error);
                this.setState({
                    loadingChargement: false,
                    bool: false,
                    submitted: true,
                });
            });
    };

    convertToCSV = (fichier) => {
        let buff = new Buffer(fichier, "base64");
        let base64ToStringNew = buff.toString("ascii");
        return base64ToStringNew;
    };

    exportCSVFile = (file, fileTitle) => {
        let jsonObject = JSON.stringify(file);
        let csv = this.convertToCSV(jsonObject);
        let exportedFilename = fileTitle || "export.csv";
        let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) {
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilename);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };
    consulterListe = (idExamen) => {
        this.setState({ notationDialog: true, loadingNotation: true });
        this.notationService
            .getListNotation(idExamen)
            .then((data) => {
                this.setState({ listeNotation: data, loadingNotation: false });
            })
            .catch((erreur) => {
                console.log(erreur);
                this.setState({ loadingNotation: false });
            });
    };
    listeNoteExamen = (rowData) => {
        let idExamen = rowData.idExamen;

        this.setState({
            notationListDialog: true,
            loadingNotationListe: true,
        });

        this.notationService
            .getListNotation(parseInt(idExamen))
            .then((data) => {
                this.setState({
                    notationList: data,
                    examen: rowData,
                    loadingNotationListe: false,
                });
            })
            .catch((error) => {
                console.log(error);
                this.notify.echec(error.response.data.message);

                this.setState({
                    loadingNotationListe: false,
                    notationListDialog: false,
                });
            });
    };
    hideDialog = () => {
        this.setState({
            submitted: false,
            examenDialog: false,
        });
    };
    hideSessionDialog = () => {
        this.setState({
            sessionDialog: false,
        });
    };
    hideListeExamenDialog = () => {
        this.setState({
            listeExamenDialog: false,
        });
    };
    hideListeEcExamenDialog = () => {
        this.setState({
            listeEcExamenDialog: false,
        });
    };
    hideImportNoteDialog = () => {
        this.setState({
            importNoteDialog: false,
            submitted: false,
            bool: false,
        });
    };
    hideDialogFichierListeEtudiant = () => {
        this.setState({
            dialogFichierListeEtudiant: false,
        });
    };
    hideNotationDialog = () => {
        this.setState({
            notationDialog: false,
        });
    };
    hideListNotationDialog = () => {
        this.setState({
            notationListDialog: false,
        });
    };
    actionBodyTemplateListeExamen = (rowData) => {
        return (
            <React.Fragment>
                {this.state.session && !this.state.session.estDelibere && !this.state.verifeMinistere && (
                    <>
                        {" "}
                        <Button
                            type="button"
                            icon="pi pi-file"
                            className="p-button-success"
                            style={{ marginRight: ".5em" }}
                            title="Importer des données"
                            onClick={() => this.importNote(rowData)}
                            disabled={this.state.session.estDelibere}
                        />{" "}
                    </>
                )}
                <>
                    <Button
                        type="button"
                        icon="pi pi-list"
                        className="p-button-warning"
                        style={{ marginRight: ".5em" }}
                        title="Consulter les notes"
                        onClick={() => this.listeNoteExamen(rowData)}
                    />
                </>
            </React.Fragment>
        );
    };
    actionBodyTemplateSession = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-list"
                    className="p-button-warning"
                    style={{ marginRight: ".5em" }}
                    title="Liste des examens"
                    onClick={() => this.listeExamen(rowData)}
                />
            </React.Fragment>
        );
    };
    allowValide = (allowValide) => {
        if (allowValide)
            return (
                <Button
                    label="Valider"
                    icon="pi pi-check"
                    className="p-button-text"
                    onClick={() => this.saveNotation(true)}
                />
            );
        else return "";
    };
    titreDatatable = (examen, listeNotation) => {
        if (examen)
            return `Liste de notation de l'examen ${examen.libelleExamen} - (${listeNotation.length} étudiants)`;
        else return "Aucune notation disponible !";
    };
    titreDatatableDialog = (examen, listeNotation) => {
        if (examen)
            return `Notation de l'examen ${examen.libelleExamen} - (${listeNotation.length} étudiants)`;
        else return "Notation examen";
    };
    actionBodyTemplateEc = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    type="button"
                    icon="pi pi-list"
                    className="p-button-warning"
                    style={{ marginRight: ".5em" }}
                    title="Liste des examens"
                    onClick={() => this.listeEcExamen(rowData)}
                />
            </React.Fragment>
        );
    };

    ListeEcEnseignant = () => {
        this.setState({ submittedEns: true });
        if (
            this.state.token &&
            this.state.departement &&
            this.state.annee &&
            this.state.semestre
        ) {
            let matricule = this.state.token.matricule;
            let idDepartement = this.state.departement.idDepartementEtab;
            let idAnnee = this.state.annee.idAnneeAcademique;
            let idSemestre = this.state.semestre.idSemestre;

            this.setState({ loading: true });

            this.enseignantService
                .getEnseignant(matricule)
                .then((ens) => {
                    console.log(ens);
                    this.enseignantService
                        .getEcEnseignant(
                            ens.idEnseignant,
                            idDepartement,
                            idAnnee,
                            idSemestre
                        )
                        .then((data) => {
                            this.setState({ listeEc: data, loading: false });
                        })
                        .catch((error) => {
                            this.setState({ loading: false });
                            console.log(error);
                            this.notify.echec(error.response.data.message);
                        });
                })
                .catch((error) => {
                    this.setState({ loading: false });
                    console.log(error);
                });

            /*this.enseignantService.getEcEnseignant(idEnseignant, idDepartement, idAnnee, idSemestre).then(data => {
                this.setState({listeEc : data, loading: false})
            })
            .catch(error => {
                this.setState({loading : false})
                console.log(error)
            })*/
        }
    };
    onValueChange = (e, field) => {
        const val = (e.target && e.target.value) || "";
        if (field === "iesr") {
            let iesr = this.state.iesr;
            iesr = val;
            this.setState({ iesr });
            this.loadDepartement(iesr.idIesr);
        } else if (field === "annee") {
            let annee = this.state.annee;
            annee = val;
            this.setState({ annee });
        } else if (field === "semestre") {
            let semestre = this.state.semestre;
            semestre = val;
            this.setState({ semestre });
        } else if (field === "departement") {
            let departement = this.state.departement;
            departement = val;
            this.setState({ departement });
        } else {
            console.log("griffe de gredey !!!");
        }
    };
    onValueChangeMinistere = (e, field) => {
        const val = (e.target && e.target.value) || "";
        if (field === "iesr") {
            let iesr = this.state.iesr;
            iesr = val;
            this.setState({ iesr });
            this.loadEtabMinistere(iesr.idIesr);
        } else if(field === 'ufr'){
            let etablissement = this.state.etablissement;
            etablissement = val;
            this.setState({ etablissement });
            this.loadDepMinistere(etablissement.idEtablissement);
        }
        else if (field === "annee") {
            let annee = this.state.annee;
            annee = val;
            this.setState({ annee });
        } else if (field === "semestre") {
            let semestre = this.state.semestre;
            semestre = val;
            this.setState({ semestre });
        } else if (field === "departement") {
            let departement = this.state.departement;
            departement = val;
            this.setState({ departement });
        } else {
            console.log("marque de success !!!");
        }
    }
    onDepartementChange = (e) => {
        const val = (e.target && e.target.value) || "";
        this.setState({ departement: val });
        this.setState({ loading: true });
        this.sessionService
            .getSessionDepartement(val.idDepartementEtab)
            .then((data) => {
                //On filtre uniquement les sessions qui sont ouvert
                let listeSessionOuvert = data.data.filter((curentSession) => {
                    return curentSession.etatSession === "OUVERT";
                });
                this.setState({
                    listeSession: listeSessionOuvert,
                    loading: false,
                });
            })
            .catch((error) => {
                console.log(error);
                this.notify.echec(error.response.data.message);
                this.setState({ loading: false });
            });
    };

    loadDepartement = (idIesr) => {
        this.departementService
            .getListeDepartement(idIesr)
            .then((data) => {
                this.setState({ listeDepartement: data });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    loadEtabMinistere = (idIesr) => {
        this.cohorteServices.getListeEtabUniv(idIesr).then(data => {
            //console.log(data)
            this.setState({listeEtablissement : data})
        })
    }
    loadDepMinistere = (idEtab) => {
        this.cohorteServices.getListeDepartementEtab(idEtab).then(data => {
            //console.log(data)
            this.setState({listeDepartement : data.departementEtabDtos})
        })
    }
    listeSessionMinistere = () => {
        this.setState({loading : true})
        this.sessionService.getSessionDepartement(this.state.departement.idDepartementEtab).then(data => {
            let listeSessionOuvert = data.data.filter((curentSession) => {
                return curentSession.etatSession === "OUVERT";
            });
            this.setState({
                listeSession: listeSessionOuvert,
                loading: false,
            });
        })
        .catch((error) => {
            console.log(error);
            this.notify.echec(error.response.data.message);
            this.setState({ loading: false });
        });
        
    }
    render() {
        let actionHeader = <Button type="button" icon="pi pi-cog" />;
        const header = (
            <div className="table-header p-col-3">
                <span className="p-input-icon-left">
                    <InputText
                        type="search"
                        onInput={(e) =>
                            this.setState({ globalFilter: e.target.value })
                        }
                        placeholder="Recherche globale..."
                    />
                </span>
            </div>
        );

        const dialogFichierListeEtudiantFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideDialogFichierListeEtudiant}
                />
            </React.Fragment>
        );
        const listeExamenDialogFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={
                        this.state.listeExamenDialog
                            ? this.hideListeExamenDialog
                            : this.hideListeEcExamenDialog
                    }
                />
            </React.Fragment>
        );
        const importNoteDialogFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideImportNoteDialog}
                />
            </React.Fragment>
        );
        const notationDialogFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideNotationDialog}
                />
            </React.Fragment>
        );
        const listNotationFooter = (
            <React.Fragment>
                <Button
                    label="Quitter"
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideListNotationDialog}
                />
            </React.Fragment>
        );

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        {
                            <ToastContainer
                                position="top-right"
                                autoClose={2000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                        }

                        {this.state.loading ? (
                            <div className="p-grid">
                                <div className="p-col-6">
                                    {this.spinner.loadingSpinner(
                                        "spin",
                                        "black"
                                    )}
                                </div>
                            </div>
                        ) : this.state.verifeEnseignant ? (
                            <div className="">
                                <div className="p-grid p-col-12">
                                    <div className="p-col-3">
                                        <Dropdown
                                            optionLabel="libelleIesr"
                                            optionValue="idIesr"
                                            value={this.state.iesr}
                                            options={this.state.listeIesr}
                                            onChange={(e) => {
                                                this.onValueChange(e, "iesr");
                                            }}
                                            required
                                            autoFocus
                                            className={classNames({
                                                "p-invalid":
                                                    this.state.submitted,
                                            })}
                                            placeholder="Selectionner l'université"
                                        />
                                        {this.state.submittedEns &&
                                            !this.state.iesr && (
                                                <small
                                                    className="p-invalid"
                                                    style={{ color: "red" }}
                                                >
                                                    Ce champs est obligatoire.
                                                </small>
                                            )}
                                    </div>
                                    <div className="p-col-3">
                                        <Dropdown
                                            optionLabel="lbDepartementEtab"
                                            optionValue="idDepartementEtab"
                                            value={this.state.departement}
                                            options={
                                                this.state.listeDepartement
                                            }
                                            onChange={(e) => {
                                                this.onValueChange(
                                                    e,
                                                    "departement"
                                                );
                                            }}
                                            required
                                            autoFocus
                                            className={classNames({
                                                "p-invalid":
                                                    this.state.submitted,
                                            })}
                                            placeholder="Selectionner le département"
                                        />
                                        {this.state.submittedEns &&
                                            !this.state.departement && (
                                                <small
                                                    className="p-invalid"
                                                    style={{ color: "red" }}
                                                >
                                                    Ce champs est obligatoire.
                                                </small>
                                            )}
                                    </div>
                                    <div className="p-col-2">
                                        <Dropdown
                                            optionLabel="libelleAnnee"
                                            optionValue="idAnneeAcademique"
                                            value={this.state.annee}
                                            options={this.state.listeAnnee}
                                            onChange={(e) => {
                                                this.onValueChange(e, "annee");
                                            }}
                                            required
                                            autoFocus
                                            className={classNames({
                                                "p-invalid":
                                                    this.state.submitted,
                                            })}
                                            placeholder="Année académique"
                                        />
                                        {this.state.submittedEns &&
                                            !this.state.annee && (
                                                <small
                                                    className="p-invalid"
                                                    style={{ color: "red" }}
                                                >
                                                    Ce champs est obligatoire
                                                </small>
                                            )}
                                    </div>
                                    <div className="p-col-2">
                                        <Dropdown
                                            optionLabel="libelleSemestre"
                                            optionValue="idSemestre"
                                            value={this.state.semestre}
                                            options={this.state.listeSemestre}
                                            onChange={(e) => {
                                                this.onValueChange(
                                                    e,
                                                    "semestre"
                                                );
                                            }}
                                            required
                                            autoFocus
                                            className={classNames({
                                                "p-invalid":
                                                    this.state.submitted,
                                            })}
                                            placeholder="Selectionner un semestre"
                                        />
                                        {this.state.submittedEns &&
                                            !this.state.semestre && (
                                                <small
                                                    className="p-invalid"
                                                    style={{ color: "red" }}
                                                >
                                                    Ce champs est obligatoire.
                                                </small>
                                            )}
                                    </div>
                                    <div className="p-col-2">
                                        <Button
                                            label="Liste EC"
                                            onClick={() => {
                                                this.ListeEcEnseignant();
                                            }}
                                            className="p-button-text"
                                        />
                                    </div>
                                </div>
                                {header}
                                <DataTable
                                    ref={(el) => (this.dt = el)}
                                    value={this.state.listeEc}
                                    selectionMode="single"
                                    header={`Liste des élements constitutifs ${this.state.listeEc.length}`}
                                    paginator={true}
                                    rows={10}
                                    globalFilter={this.state.globalFilter}
                                    filterMatchMode="contains"
                                    responsive={true}
                                    selection={this.state.dataTableSelection1}
                                    onSelectionChange={(event) =>
                                        this.setState({
                                            dataTableSelection1: event.value,
                                        })
                                    }
                                >
                                    <Column
                                        field="codeEc"
                                        header="Code EC"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Rechercher code EC"
                                    />
                                    <Column
                                        field="libelleEc"
                                        header="Libelle EC"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="rechercher libellé EC"
                                    />
                                    <Column
                                        field="creditEc"
                                        header="Crédit EC"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="rechercher un crédit EC"
                                    />
                                    <Column
                                        header={actionHeader}
                                        body={this.actionBodyTemplateEc}
                                        style={{
                                            textAlign: "center",
                                            width: "8em",
                                        }}
                                    />
                                </DataTable>
                            </div>
                        ) : this.state.verifeDept ? (
                            <>
                                {header}
                                <DataTable
                                    ref={(el) => (this.dt = el)}
                                    value={this.state.listeSession}
                                    selectionMode="single"
                                    header={`Liste des sessions ${this.state.listeSession.length}`}
                                    paginator={true}
                                    rows={10}
                                    globalFilter={
                                        this.state.globalFilterSession
                                    }
                                    filterMatchMode="contains"
                                    responsive={true}
                                    selection={this.state.dataTableSelection1}
                                    onSelectionChange={(event) =>
                                        this.setState({
                                            dataTableSelection1: event.value,
                                        })
                                    }
                                >
                                    <Column
                                        field="parcoursFiliere.libelleParcours"
                                        header="Parcours"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Rechercher un parcours"
                                    />
                                    <Column
                                        field="anneeSemestre.libelleAnneeAcademique"
                                        header="Année accadémique"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="rechercher une année"
                                    />
                                    <Column
                                        field="anneeSemestre.libelleSemestre"
                                        header="Semestre"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="rechercher un semestre"
                                    />
                                    <Column
                                        field="typeSession.libelleTypeSession"
                                        header="Session"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="recherhcher une session"
                                    />
                                    <Column
                                        field="etatSession"
                                        header="Etat de la session"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="recherhcher un état"
                                    />
                                    <Column
                                        header={actionHeader}
                                        body={this.actionBodyTemplateSession}
                                        style={{
                                            textAlign: "center",
                                            width: "8em",
                                        }}
                                    />
                                </DataTable>
                            </>
                        ) : this.state.verifeEtab ? (
                            <>
                                {header}
                                <div className="form-grid">
                                    <div className="p-col-3">
                                        <Dropdown
                                            optionLabel="lbDepartementEtab"
                                            value={this.state.departement}
                                            options={
                                                this.state.listeDepartement
                                            }
                                            onChange={(e) => {
                                                this.onDepartementChange(e);
                                            }}
                                            required
                                            autoFocus
                                            placeholder="Selectionner un departement"
                                        />
                                    </div>
                                </div>

                                <DataTable
                                    ref={(el) => (this.dt = el)}
                                    value={this.state.listeSession}
                                    selectionMode="single"
                                    header={`Liste des sessions (${this.state.listeSession.length})`}
                                    paginator={true}
                                    rows={10}
                                    globalFilter={
                                        this.state.globalFilterSession
                                    }
                                    filterMatchMode="contains"
                                    responsive={true}
                                    selection={this.state.dataTableSelection1}
                                    onSelectionChange={(event) =>
                                        this.setState({
                                            dataTableSelection1: event.value,
                                        })
                                    }
                                >
                                    <Column
                                        field="parcoursFiliere.libelleParcours"
                                        header="Parcours"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Rechercher un parcours"
                                    />
                                    <Column
                                        field="anneeSemestre.libelleAnneeAcademique"
                                        header="Année accadémique"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="rechercher une année"
                                    />
                                    <Column
                                        field="anneeSemestre.libelleSemestre"
                                        header="Semestre"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="rechercher un semestre"
                                    />
                                    <Column
                                        field="typeSession.libelleTypeSession"
                                        header="Session"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="recherhcher une session"
                                    />
                                    <Column
                                        field="etatSession"
                                        header="Etat de la session"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="recherhcher un état"
                                    />
                                    <Column
                                        header={actionHeader}
                                        body={this.actionBodyTemplateSession}
                                        style={{
                                            textAlign: "center",
                                            width: "8em",
                                        }}
                                    />
                                </DataTable>
                            </>
                        ) : this.state.verifeMinistere ? (
                            <>
                                {header}
                                <div className="p-grid p-col-12">
                                    <div className="p-col-2">
                                        <Dropdown
                                            optionLabel="libelleIesr"
                                            value={this.state.iesr}
                                            options={
                                                this.state.listeIesr
                                            }
                                            onChange={(e) => {
                                                this.onValueChangeMinistere(e, 'iesr');
                                            }}
                                            required
                                            autoFocus
                                            placeholder="Selectionner un IESR"
                                        />
                                    </div>
                                    <div className="p-col-2">
                                        <Dropdown
                                            optionLabel="nom"
                                            value={this.state.etablissement}
                                            options={
                                                this.state.listeEtablissement
                                            }
                                            onChange={(e) => {
                                                this.onValueChangeMinistere(e, 'ufr');
                                            }}
                                            required
                                            autoFocus
                                            placeholder="Selectionner un établissement"
                                        />
                                    </div>
                                    <div className="p-col-3">
                                        <Dropdown
                                            optionLabel="lbDepartementEtab"
                                            value={this.state.departement}
                                            options={
                                                this.state.listeDepartement
                                            }
                                            onChange={(e) => {
                                                this.onValueChangeMinistere(e, 'departement');
                                            }}
                                            required
                                            autoFocus
                                            placeholder="Selectionner un departement"
                                        />
                                    </div>
                                    <div className="p-col-2">
                                        <Dropdown
                                            optionLabel="libelleAnnee"
                                            optionValue="idAnneeAcademique"
                                            value={this.state.annee}
                                            options={this.state.listeAnnee}
                                            onChange={(e) => {
                                                this.onValueChangeMinistere(e, "annee");
                                            }}
                                            required
                                            autoFocus
                                            className={classNames({
                                                "p-invalid":
                                                    this.state.submitted,
                                            })}
                                            placeholder="Année académique"
                                        />
                                        
                                    </div>
                                    <div className="p-col-2">
                                        <Button
                                            label="Liste session"
                                            onClick={() => {
                                                this.listeSessionMinistere();
                                            }}
                                            className="p-button-text"
                                        />
                                    </div>
                                </div>
                                <DataTable
                                    ref={(el) => (this.dt = el)}
                                    value={this.state.listeSession}
                                    selectionMode="single"
                                    header={`Liste des sessions (${this.state.listeSession.length})`}
                                    paginator={true}
                                    rows={10}
                                    globalFilter={
                                        this.state.globalFilterSession
                                    }
                                    filterMatchMode="contains"
                                    responsive={true}
                                    selection={this.state.dataTableSelection1}
                                    onSelectionChange={(event) =>
                                        this.setState({
                                            dataTableSelection1: event.value,
                                        })
                                    }
                                >
                                    <Column
                                        field="parcoursFiliere.libelleParcours"
                                        header="Parcours"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Rechercher un parcours"
                                    />
                                    <Column
                                        field="anneeSemestre.libelleAnneeAcademique"
                                        header="Année accadémique"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="rechercher une année"
                                    />
                                    <Column
                                        field="anneeSemestre.libelleSemestre"
                                        header="Semestre"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="rechercher un semestre"
                                    />
                                    <Column
                                        field="typeSession.libelleTypeSession"
                                        header="Session"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="recherhcher une session"
                                    />
                                    <Column
                                        field="etatSession"
                                        header="Etat de la session"
                                        filterMatchMode="contains"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="recherhcher un état"
                                    />
                                    <Column
                                        header={actionHeader}
                                        body={this.actionBodyTemplateSession}
                                        style={{
                                            textAlign: "center",
                                            width: "8em",
                                        }}
                                    />
                                </DataTable>
                            </>
                        ): (
                            ""
                        )}
                    </div>
                </div>
                <Dialog
                    visible={this.state.listeExamenDialog}
                    style={{ width: "80%" }}
                    header={`Liste des examens ( ${this.state.listeExamen.length} examen(s) )`}
                    modal
                    className="p-fluid"
                    footer={listeExamenDialogFooter}
                    onHide={this.hideListeExamenDialog}
                >
                    <DataTable
                        ref={(el) => (this.dt = el)}
                        value={this.state.listeExamen}
                        selectionMode="single"
                        header="Liste des examens"
                        paginator={true}
                        rows={5}
                        globalFilter={this.state.globalFilterListeExamen}
                        filterMatchMode="contains"
                        responsive={true}
                        selection={this.state.dataTableSelection1}
                        onSelectionChange={(event) =>
                            this.setState({ dataTableSelection1: event.value })
                        }
                    >
                        <Column
                            field="elementConstitutif.libelleElementConstitutif"
                            header="Element constitutif"
                            filterMatchMode="contains"
                            sortable={true}
                            filter={true}
                            filterPlaceholder="Rechercher un parcours"
                        />
                        <Column
                            field="dateExamen"
                            header="Date de composition"
                            filterMatchMode="contains"
                            sortable={true}
                            filter={true}
                            filterPlaceholder="rechercher une date"
                        />
                        <Column
                            field="heureExamen"
                            header="Heure de composition"
                            filterMatchMode="contains"
                            sortable={true}
                            filter={true}
                            filterPlaceholder="rechercher une heure"
                        />
                        <Column
                            field="dureeExamen"
                            header="Durée de l'examen"
                            filterMatchMode="contains"
                            sortable={true}
                            filter={true}
                            filterPlaceholder="rechercher une durée"
                        />
                        <Column
                            field="observationExamen"
                            header="Observation"
                            filterMatchMode="contains"
                            sortable={true}
                            filter={true}
                            filterPlaceholder="recherhcher un lieu"
                        />
                        <Column
                            field="paramNote.valParam"
                            header="Taux de notation"
                            filterMatchMode="contains"
                            sortable={true}
                            filter={true}
                            filterPlaceholder="recherhcher un taux de notation"
                        />
                        <Column
                            header={actionHeader}
                            body={this.actionBodyTemplateListeExamen}
                            style={{ textAlign: "center", width: "8em" }}
                        />
                    </DataTable>
                </Dialog>
                <Dialog
                    visible={this.state.importNoteDialog}
                    style={{ width: "80%" }}
                    header="Import de données"
                    modal
                    className="p-fluid"
                    footer={importNoteDialogFooter}
                    onHide={this.hideImportNoteDialog}
                >
                    <div>
                        <div
                            className="card p-lg-10"
                            style={{ marginLeft: "10%" }}
                        >
                            {this.state.examen ? (
                                <h6 style={{ textAlign: "center" }}>
                                    Page de notation examen{" "}
                                    {
                                        this.state.examen.elementConstitutif
                                            .libelleElementConstitutif
                                    }{" "}
                                    (
                                    {
                                        this.state.examen.typeActivite
                                            .libelleTypeActivite
                                    }
                                    ){" "}
                                    {
                                        this.state.examen.session.typeSession
                                            .libelleTypeSession
                                    }{" "}
                                    {
                                        this.state.examen.session.anneeSemestre
                                            .libelleAnneeAcademique
                                    }
                                </h6>
                            ) : (
                                ""
                            )}
                        </div>
                        <div
                            className="p-lg-10"
                            style={{ marginLeft: "10%", textAlign: "center" }}
                        >
                            Chargement par fichier CSV
                        </div>
                        <div
                            className="card p-lg-10"
                            style={{ marginLeft: "10%" }}
                        >
                            <div className="p-lg-12">
                                <h3
                                    style={{
                                        textDecoration: "underline",
                                        textAlign: "center",
                                    }}
                                >
                                    <Link
                                        onClick={() => {
                                            this.telechargerFichierEtudiant(
                                                this.state.examen.idExamen
                                            );
                                        }}
                                    >
                                        Cliquer ici pour telecharger la liste
                                        des étudiants pour notation
                                    </Link>
                                </h3>
                            </div>
                            <div
                                className="p-lg-12 p-grid"
                                style={{ marginTop: 10, marginLeft: "10%" }}
                            >
                                <div
                                    className="p-lg-12 p-grid"
                                    style={{ marginLeft: "0%" }}
                                >
                                    <div className="p-lg-6">
                                        <input
                                            type="file"
                                            style={{ marginLeft: "0%" }}
                                            onChange={(e) => {
                                                this.onFileChange(e);
                                            }}
                                        />
                                    </div>
                                    <div
                                        className="p-lg-4"
                                        style={{ marginTop: -20 }}
                                    >
                                        <Button
                                            style={{ marginLeft: "0%" }}
                                            label="Valider le chargement"
                                            onClick={this.saveImportFichier}
                                        />
                                    </div>
                                </div>
                                {this.state.loadingChargement ? (
                                    <div className="p-grid">
                                        <div className="p-col-6">
                                            {this.spinner.loadingSpinner(
                                                "spin",
                                                "black"
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="p-grid p-lg-12">
                                        {this.state.submitted &&
                                            this.state.bool && (
                                                <span
                                                    style={{ color: "green" }}
                                                >
                                                    Chargement réussi !
                                                </span>
                                            ) && <br /> && (
                                                <div className="p-grid p-lg-4">
                                                    <Button
                                                        onClick={() => {
                                                            this.consulterListe(
                                                                this.state
                                                                    .examen
                                                                    .idExamen
                                                            );
                                                        }}
                                                        label="Consulter les notes chargées"
                                                    />
                                                </div>
                                            )}
                                        {this.state.submitted &&
                                            !this.state.bool && (
                                                <span style={{ color: "red" }}>
                                                    Echec de chargement !
                                                </span>
                                            )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog
                    visible={this.state.dialogFichierListeEtudiant}
                    style={{ width: "30%" }}
                    header="Recupération de fichier"
                    modal
                    className="p-fluid"
                    footer={dialogFichierListeEtudiantFooter}
                    onHide={this.hideDialogFichierListeEtudiant}
                >
                    <div className="card p-lg-10" style={{ marginLeft: "10%" }}>
                        <div
                            className="p-lg-12 p-grid"
                            style={{ marginTop: 10, marginLeft: "10%" }}
                        >
                            {this.state.loadingListeEtudiant ? (
                                <div
                                    className="p-grid"
                                    style={{ marginTop: 80, marginLeft: "25%" }}
                                >
                                    <div className="p-col-12">
                                        {this.spinner.loadingSpinner(
                                            "spin",
                                            "black"
                                        )}
                                    </div>
                                </div>
                            ) : this.state.erreurRecupFichier ? (
                                <div
                                    className="p-grid"
                                    style={{ marginLeft: "10%" }}
                                >
                                    <div className="p-col-12">
                                        <p
                                            style={{
                                                color: "red",
                                                textAlign: "center",
                                            }}
                                        >
                                            Echec de l'opération, réessayez !{" "}
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className="p-grid"
                                    style={{ marginLeft: "10%" }}
                                >
                                    <div className="p-col-12">
                                        <Link
                                            onClick={() => {
                                                this.exportCSVFile(
                                                    this.state.fichierNotation
                                                        .fichier,
                                                    this.state.fichierNotation
                                                        .nomFichier
                                                );
                                            }}
                                        >
                                            <img src={logoCsv} />
                                            Telecharger le fichier
                                        </Link>
                                        {/*<a href={this.state.fichierNotation} target="blank" style={{textDecoration : "underline", color : "green", textAlign : "center"}} ><img src={logoCsv}/>Telecharger le fichier </a>*/}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Dialog>
                <Dialog
                    visible={this.state.notationDialog}
                    style={{ width: "80%" }}
                    header={this.titreDatatableDialog(
                        this.state.examen,
                        this.state.listeNotation
                    )}
                    modal
                    className="p-fluid"
                    footer={notationDialogFooter}
                    onHide={this.hideNotationDialog}
                >
                    {this.state.loadingNotation ? (
                        <div className="p-grid">
                            <div className="p-col-6">
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                    ) : (
                        <div className="p-grid">
                            <div className="p-col-12">
                                <DataTable
                                    ref={(el) => (this.dt = el)}
                                    value={this.state.listeNotation}
                                    selectionMode="single"
                                    header={this.titreDatatable(
                                        this.state.examen,
                                        this.state.listeNotation
                                    )}
                                    paginator={true}
                                    rows={10000}
                                    globalFilter={
                                        this.state.globalFilterNotation
                                    }
                                    filterMatchMode="contains"
                                    responsive={true}
                                    selection={this.state.dataTableSelection1}
                                    onSelectionChange={(event) =>
                                        this.setState({
                                            dataTableSelection1: event.value,
                                        })
                                    }
                                >
                                    <Column
                                        field="etudiant.ine"
                                        header="INE Etudiant"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="INE Etudiant"
                                    />
                                    <Column
                                        field="etudiant.nom"
                                        header="Nom étudiant"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Nom étudiant"
                                    />
                                    <Column
                                        field="etudiant.prenom"
                                        header="Prénom(s) étudiant"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Prénom(s) étudiant"
                                    />
                                    <Column
                                        field="note"
                                        header="Note obtenue"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="Note obtenue"
                                    />
                                    <Column
                                        field="observationNotation"
                                        header="Observation note"
                                        sortable={true}
                                        filter={true}
                                        filterPlaceholder="observation note"
                                    />
                                </DataTable>
                            </div>
                            <div className="p-col-8"></div>
                        </div>
                    )}
                </Dialog>

                <Dialog
                    visible={this.state.notationListDialog}
                    style={{ width: "95%" }}
                    modal
                    className="p-fluid"
                    header={this.titreDatatableDialog(
                        this.state.examen,
                        this.state.notationList
                    )}
                    footer={listNotationFooter}
                    onHide={this.hideListNotationDialog}
                >
                    {this.state.loadingNotationListe ? (
                        <div className="p-grid">
                            <div className="p-col-6"></div>
                            <div className="p-col-6">
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                    ) : (
                        <DataTable
                            ref={(el) => (this.dt = el)}
                            value={this.state.notationList}
                            selectionMode="single"
                            header={this.titreDatatableDialog(
                                this.state.examen,
                                this.state.notationList
                            )}
                            paginator={true}
                            rows={10000}
                            globalFilter={this.state.globalFilter}
                            responsive={true}
                            selection={this.state.dataTableSelection1}
                            onSelectionChange={(event) =>
                                this.setState({
                                    dataTableSelection1: event.value,
                                })
                            }
                        >
                            <Column
                                field="etudiant.ine"
                                header="INE Etudiant"
                                sortable={true}
                                filter={true}
                                filterPlaceholder="INE Etudiant"
                            />
                            <Column
                                field="etudiant.nom"
                                header="Nom étudiant"
                                sortable={true}
                                filter={true}
                                filterPlaceholder="Nom étudiant"
                            />
                            <Column
                                field="etudiant.prenom"
                                header="Prénom(s) étudiant"
                                sortable={true}
                                filter={true}
                                filterPlaceholder="Prénom(s) étudiant"
                            />
                            <Column
                                field="note"
                                header="Note obtenue"
                                sortable={true}
                                filter={true}
                                filterPlaceholder="Note obtenue"
                            />
                            <Column
                                field="observationNotation"
                                header="Observation note"
                                sortable={true}
                                filter={true}
                                filterPlaceholder="observation note"
                            />
                        </DataTable>
                    )}
                </Dialog>
                <Dialog
                    visible={this.state.listeEcExamenDialog}
                    style={{ width: "80%" }}
                    header={`Liste des examens`}
                    modal
                    className="p-fluid"
                    footer={listeExamenDialogFooter}
                    onHide={this.hideListeEcExamenDialog}
                >
                    {this.state.loadingEcExamen ? (
                        <div className="p-grid">
                            <div className="p-col-6">
                                {this.spinner.loadingSpinner("spin", "black")}
                            </div>
                        </div>
                    ) : (
                        <React.Fragment>
                            {header}
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.state.listeEcExamen}
                                selectionMode="single"
                                header={`Liste des examens (${this.state.listeEcExamen.length})`}
                                paginator={true}
                                rows={5}
                                globalFilter={this.state.globalFilter}
                                filterMatchMode="contains"
                                responsive={true}
                                selection={this.state.dataTableSelection1}
                                onSelectionChange={(event) =>
                                    this.setState({
                                        dataTableSelection1: event.value,
                                    })
                                }
                            >
                                <Column
                                    field="session.libelleSession"
                                    header="Session"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="rechercher une session"
                                />
                                <Column
                                    field="elementConstitutif.libelleElementConstitutif"
                                    header="Libellé EC"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="Rechercher un Ec"
                                />
                                <Column
                                    field="typeActivite.libelleTypeActivite"
                                    header="Type activité"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="rechercher type activité"
                                />
                                <Column
                                    field="dateExamen"
                                    header="Date de composition"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="rechercher une date"
                                />
                                <Column
                                    field="heureExamen"
                                    header="Heure de composition"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="rechercher une heure"
                                />
                                <Column
                                    field="dureeExamen"
                                    header="Durée de l'examen"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="rechercher une durée"
                                />
                                <Column
                                    field="observationExamen"
                                    header="Observation"
                                    filterMatchMode="contains"
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="recherhcher un lieu"
                                />
                                <Column
                                    header={actionHeader}
                                    body={this.actionBodyTemplateListeExamen}
                                    style={{
                                        textAlign: "center",
                                        width: "8em",
                                    }}
                                />
                            </DataTable>
                        </React.Fragment>
                    )}
                </Dialog>
            </div>
        );
    }
}

export default ImportNoteEnseignantResp;
