import axios from 'axios';
import { AdresseIP } from '../../components/AdresseIP';

export class ParcoursUEService {

    constructor(){
        this.adresse = new AdresseIP()
    }
   getAll() {
       
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/parcours-unite-enseignement"
       return axios.get(url).then(res => res.data)
   }
   
   create(newParcoursUE) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/parcours-unite-enseignement"
       return axios.post(url,newParcoursUE)
                   .then(res => res.data);
   }
   update(idParcours, idSemestre ,newParcoursUE) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/parcours-unite-enseignement/"+idParcours+"/"+idSemestre
       return axios.put(url, newParcoursUE)
                   .then(res => res.data);
   }
   delete(idParcoursUE) {
       const url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/parcours-unite-enseignement/"
       return axios.patch(url+idParcoursUE)
                   .then(res => res.data)
   }
   get(idParcours, idSemestre) {

    let url 
    if(idSemestre){
        url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/parcours-unite-enseignement?idParcours="+idParcours+"&idSemestre="+idSemestre
    }else{
        url = this.adresse.getAdresseIP() +"/service/api/campusfaso-lmd/campus-faso/parcours-unite-enseignement?idParcours="+idParcours
    }

    return axios.get(url)
                .then(res => res.data)
    }

}